import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetMemberEventById } from '../hooks/boardEventQueries';
import EventEditDraft from './EventEditDraft';
import EventEditPublished from './EventEditPublished';

const EventDetailPage = () => {
    const { id } = useParams<{ id: string }>();

    const { data } = useGetMemberEventById(+id);

    if (!data?.published_at) {
        return <EventEditDraft />;
    }

    return <EventEditPublished />;
};

export default EventDetailPage;
