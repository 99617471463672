import ContentEventApi from '@app/api/public/ContentEventApi';
import { useMutation, useQueryClient } from 'react-query';
import { memberEventQueryKeys } from './memberEventQueries';

type Variables = { id: number; notify: boolean };

const usePublishEvent = () => {
    const client = useQueryClient();
    return useMutation(
        (varables: Variables) => {
            return ContentEventApi.publishEvent(varables);
        },
        {
            onSettled: () => {
                client.invalidateQueries(memberEventQueryKeys.all);
            },
        }
    );
};

export default usePublishEvent;
