import UsersApi from '@app/api/public/UsersApi';
import * as EmailValidator from 'email-validator';
import { useMutation } from 'react-query';

const resetPasswordRequest = async (email: string) => {
    if (!EmailValidator.validate(email)) {
        throw new Error('not_mail');
    }

    const response = await UsersApi.resetPasswordRequest(email);
    if (response && response.status === 'error') {
        throw new Error(response.message);
    }
    return true;
};

const useSendPasswordResetInstructions = () => {
    return useMutation((email: string) => resetPasswordRequest(email));
};

export default useSendPasswordResetInstructions;
