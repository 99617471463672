import React from 'react';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { Loading } from '../../../components/Loading/Loading';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import useGetInvoiceInformation from '../hooks/useGetInvoiceInformation';
import InvoiceForm from './components/InvoiceForms';

const InvoiceSettingsPage = () => {
    const { data: invoiceInformation } = useGetInvoiceInformation();

    if (!invoiceInformation) return <Loading />;

    return (
        <CardWithDivider
            topArea={<CardTitle>{L('invoice_information_description')}</CardTitle>}
            mainArea={<InvoiceForm invoiceInformation={invoiceInformation} />}
        />
    );
};

export default InvoiceSettingsPage;
