import { useQuery } from 'react-query';
import ServicePartnersApi from '@app/api/public/ServicePartnersApi';

export const servicePartnersQueryKeys = {
    all: ['servicePartners'],
    documents: () => [...servicePartnersQueryKeys.all, 'documents'],
    documentsPartnerId: (partnerId?: string) => [...servicePartnersQueryKeys.documents(), { partnerId }],
    list: () => [...servicePartnersQueryKeys.all, 'list'],
    byId: (id?: string) => [...servicePartnersQueryKeys.all, { id }],
    categories: () => [...servicePartnersQueryKeys.all, 'categories'],
    categoriesById: (partnerId: number) => [...servicePartnersQueryKeys.categories(), { partnerId }],
};

const getServicePrtners = async () => {
    return await ServicePartnersApi.list();
};

const useGetServicePartners = () => {
    return useQuery(servicePartnersQueryKeys.list(), () => getServicePrtners());
};

const getServicePartnerById = async (id: number) => {
    return await ServicePartnersApi.getbyid(id);
};

const useGetServicePartnerById = (id: string) => {
    return useQuery(servicePartnersQueryKeys.byId(id), () => getServicePartnerById(+id), {
        enabled: !!id,
    });
};

const useGetServicePartnerDocumentsById = (id?: string | number) => {
    return useQuery(
        servicePartnersQueryKeys.documentsPartnerId(id?.toString()),
        async () => {
            if (!id) return;
            return await ServicePartnersApi.listDocuments(+id);
        },
        {
            enabled: !!id,
        }
    );
};

export { useGetServicePartners, useGetServicePartnerById, useGetServicePartnerDocumentsById };
