import React from 'react';
import { Route } from 'react-router-dom';
import MemberEventPage from './MemberEventPage';

export const routes = [
    <Route key="1" path="/event/:id" component={MemberEventPage} />,
    <Route key="0" path="/event" component={MemberEventPage} />,
];

export const navigation = { name: 'member_content_calendar', link: '/event', icon: 'Calendar', parent: 'Members' };
