import React from 'react';
import styled from '@emotion/styled';
import { L } from '../../../../lib/i18n';
import { useTheme } from 'emotion-theming';
import { SiteTheme } from '@app/api/models/PublicSite';
import useGetProjectInfo from '../../../../hooks/useGetProjectInfo';

const Container = styled.div(({ theme }: { theme: SiteTheme['theme'] }) => {
    return {
        paddingTop: '2rem',
        paddingBottom: '2rem',
        backgroundColor: theme.colors.footerBackground,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: '-5px',
    };
});
const Info = styled.span(({ theme }: { theme: SiteTheme['theme'] }) => {
    return {
        margin: '0.5rem',
        fontSize: '0.8rem',
        color: theme.colors.footerText,
    };
});

const PreviewFooter = () => {
    const { data: projectInfo } = useGetProjectInfo();
    const theme = useTheme<SiteTheme['theme']>();

    return (
        <Container theme={theme}>
            {projectInfo && (
                <Info theme={theme}>
                    {L('street_name')}: {projectInfo.public_site_address.adressLine1},{' '}
                    {projectInfo.public_site_address.postalCode} {projectInfo.public_site_address.city}
                </Info>
            )}
            {projectInfo && (
                <Info theme={theme}>
                    {L('email')}: {projectInfo.notification_email}
                </Info>
            )}
        </Container>
    );
};

export default PreviewFooter;
