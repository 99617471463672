import React from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import sv from 'dayjs/locale/sv';
import relativeTime from 'dayjs/plugin/relativeTime';
import { StyleColumn } from '../../../types/ColumnType';
import { colors } from '../../../components/Shared/Style/colors';
import { Contract, ContractType } from '@app/api/models/Contracts';
import { ServicePartner } from '@app/api/models/ServicePartners';
import { L } from '../../../lib/i18n';

dayjs.locale(sv);
dayjs.extend(relativeTime);

const getOpposite = (color: string) => {
    const splitByParas = color.split(/[()]+/);
    if (splitByParas.length !== 3) return colors.white;
    const rgbDigits = splitByParas[1].split(', ');
    if (splitByParas.length < 3) return colors.white;
    const [red, green, blue] = rgbDigits.map((digits) => Number(digits));
    if (red * 0.299 + green * 0.587 + blue * 0.114 >= 200) {
        return colors.black;
    }
    return colors.white;
};

const Container = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maring: 'auto',
});
const NameContainer = styled(Container)({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    margin: 0,
});
const Span = styled.span(({ isBefore }: { isBefore?: boolean }) => {
    return {
        display: 'flex',
        justifyContent: 'center',
        color: isBefore ? colors.statusError : 'inherit',
    };
});
const DaysLeft = styled.span(({ isBefore }: { isBefore?: boolean }) => {
    return {
        color: isBefore ? colors.statusError : 'inherit',
        fontSize: '10px',
    };
});
const NameSpan = styled.span({
    textAlign: 'left',
    paddingLeft: '1rem',
});
const StatusSpan = styled(Span)(({ statusColor, textColor }: { statusColor: string; textColor: string }) => {
    return {
        display: 'initial',
        backgroundColor: statusColor,
        width: '8rem',
        padding: '0.5rem',
        borderRadius: '15px',
        color: textColor,
        textAlign: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };
});
const DateWrapper = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
});

const ColorBox = styled.div(({ boxcolor }: { boxcolor?: string }) => {
    return {
        backgroundColor: boxcolor,
        height: '60px',
        width: '6px',
    };
});

export const COLUMNS: StyleColumn<Contract & { contract_types: ContractType; service_partner: ServicePartner }>[] = [
    {
        Header: () => {
            return <Span>{L('contractor')}</Span>;
        },
        id: 'contractor',
        accessor: (value) => value.service_partner.name,
        width: 200,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const contract = row.original;
            return (
                <NameContainer>
                    <ColorBox boxcolor={contract.contract_types?.color_code} />
                    <NameSpan>{contract?.service_partner.name}</NameSpan>
                </NameContainer>
            );
        },
    },
    {
        Header: () => {
            return <Span>{L('name')}</Span>;
        },
        id: 'name',
        accessor: (originalRow: Contract) => {
            return originalRow.name;
        },
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const contract = row.original as Contract;
            return contract.name;
        },
    },
    {
        Header: () => {
            return <Span>{L('end_of_contract')}</Span>;
        },
        id: 'end_of_contract',
        accessor: (originalRow: Contract) => {
            if (originalRow.contract_length?.end_date) {
                return dayjs(originalRow.end_date).valueOf();
            }
            if (originalRow.contract_length?.length_months) {
                return dayjs(originalRow.start_date)
                    .add(+originalRow.contract_length.length_months, 'months')
                    .valueOf();
            }
            return Infinity;
        },
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const contract = row.original;
            return (
                <Container>
                    {contract.contract_length?.end_date && (
                        <DateWrapper>
                            <Span>{dayjs(contract.end_date).format('YYYY-MM-DD')}</Span>
                            <DaysLeft isBefore={dayjs(contract.end_date).isBefore(dayjs())}>
                                {dayjs(contract.end_date).isValid() ? dayjs(Date.now()).to(contract.end_date) : ''}
                            </DaysLeft>
                        </DateWrapper>
                    )}
                    {contract.contract_length?.length_months && (
                        <Span>{contract.contract_length.length_months} månader </Span>
                    )}
                    {contract.contract_length?.until_further_notice && <Span>Tillsvidare</Span>}
                </Container>
            );
        },
    },
    {
        Header: () => {
            return <Span>{L('type')}</Span>;
        },
        id: 'type',
        accessor: (value) => value.contract_types?.name,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },

        Cell: ({ row }) => {
            const contract = row.original;
            return (
                <Container>
                    <StatusSpan
                        statusColor={contract.contract_types?.color_code}
                        textColor={getOpposite(contract.contract_types?.color_code)}
                    >
                        {contract.contract_types?.name}
                    </StatusSpan>
                </Container>
            );
        },
    },
    {
        Header: () => {
            return <Span>{L('surveillance_date')}</Span>;
        },
        id: 'surveillance_date',
        accessor: (value) => value.notification_date,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },

        Cell: ({ row }) => {
            const contract = row.original;
            return (
                <Container>
                    {dayjs(contract.notification_date).isValid()
                        ? dayjs(contract.notification_date).format('YYYY-MM-DD')
                        : ''}
                </Container>
            );
        },
    },
    {
        Header: () => {
            return <Span>{L('archived')}</Span>;
        },
        id: 'archived_at',
        accessor: (value) => value.archived_at,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },

        Cell: ({ row }) => {
            const contract = row.original;
            return (
                <Container>
                    {dayjs(contract.archived_at).isValid() ? dayjs(contract.archived_at).format('YYYY-MM-DD') : ''}
                </Container>
            );
        },
    },
];
