import { L } from '../../../lib/i18n';
import { Property } from '@app/api/models/Property';
import PropertiesApi from '@app/api/public/PropertiesApi';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { propertiesQueryKeys } from './propertyQueries';

const saveProperty = async (property: Partial<Property>) => {
    console.log(property);
    const res = await PropertiesApi.save(property);

    if (res.status === 'error') return Promise.reject(res.message);

    return res.data;
};

const useSaveProperty = () => {
    const queryClient = useQueryClient();
    return useMutation((property: Partial<Property>) => saveProperty(property), {
        onError: (err) => {
            if (err instanceof Error) console.log(err.message);
            /* Shows error message */
            const error = typeof err === 'string' ? err : new Error(L('reset_error')).message;
            const message = error;
            toast.error(`${L(message)}`);
        },
        onSettled: (_data, _error, variables) => {
            variables.id && queryClient.invalidateQueries(propertiesQueryKeys.detail(variables.id));
        },
    });
};

export default useSaveProperty;
