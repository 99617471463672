import React from 'react';
import { Route } from 'react-router-dom';
import ModalContext from '../../components/Modal/Context/ModalContext';
import { ContractPage } from './ContractPage';

export const routes = [
    <Route key="0" path="/contracts">
        <ModalContext>
            <ContractPage />
        </ModalContext>
    </Route>,
];

export const navigation = { name: 'contract', link: '/contracts', icon: 'Contact', parent: 'BoardMembers' };
