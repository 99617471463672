import React, { useState } from 'react';
import styled from '@emotion/styled';
import useGetHelpListItem from '../../../web/areas/help/hooks/useGetHelpListItem';
import { useLocation, Link, Route, Switch } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import Close from '../SliderMenu/Close/Close';
import { L } from '../../lib/i18n';
import HelpIconSvg from '../../components/Icon/question-mark.svg?url';
import useHandleClickOutside from '../../hooks/useHandleClickOutside';
import { RawHtmlHelp } from '../Layout/RawHtmlHelp';

const IconWrapper = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
});

const Icon = styled.h1(({ isOpenModal }: { isOpenModal?: boolean }) => {
    return {
        backgroundColor: isOpenModal ? '#363636' : 'transparent',
        display: 'flex',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundImage: `url(${HelpIconSvg})`,
        backgroundRepeat: 'no-repeat',
        width: '24px',
        height: '24px',
        cursor: 'pointer',
    };
});

const ModalContainer = animated(
    styled.div({
        position: 'fixed',
        top: 0,
        right: 0,
        width: '500px',
        height: '100vh',
        overflowY: 'scroll',
        backgroundColor: '#F7F8FC',
        color: 'black',
        boxShadow: '0 0 35px gray',
        zIndex: 1,
        '& img': {
            width: '100%',
            maxWidth: '100%',
            objectFit: 'cover',
            backgroundPosition: 'center',
        },
        '& iframe': {
            width: '100%',
            maxWidth: '100%',
        },
    })
);

const ModalWrapper = styled.div({
    width: '85%',
    margin: '10px auto',
});

const Title = styled.h1({
    marginTop: '50px',
    fontSize: '1.5rem',
});

const NoHelpText = styled.p({
    fontSize: '1rem',
    marginTop: '50px',
});

const StyledClose = styled(Close)({
    fontWeight: 100,
    top: 10,
    left: 10,
});

const HelpIconRouter = () => {
    return (
        <Switch>
            <Route path="/event">
                <HelpIcon pathNameOverride="/event" />
            </Route>
            <Route path="/news">
                <HelpIcon pathNameOverride="/news" />
            </Route>
            <Route path="/information">
                <HelpIcon pathNameOverride="/information" />
            </Route>
            <Route path="/board-event">
                <HelpIcon pathNameOverride="/board-event" />
            </Route>
            <Route path="/board-news">
                <HelpIcon pathNameOverride="/board-news" />
            </Route>
            <Route path="/board-information">
                <HelpIcon pathNameOverride="/board-information" />
            </Route>
            <Route path="/properties">
                <HelpIcon pathNameOverride="/properties" />
            </Route>
            <Route path="/settings">
                <HelpIcon pathNameOverride="/settings" />
            </Route>
            <Route path="/contacts">
                <HelpIcon pathNameOverride="/contacts" />
            </Route>
            <Route path="/boardmembers">
                <HelpIcon pathNameOverride="/boardmembers" />
            </Route>
            <Route path="/members">
                <HelpIcon pathNameOverride="/members" />
            </Route>
            <Route path="/booking">
                <HelpIcon pathNameOverride="/booking" />
            </Route>
            <Route path="/tickets">
                <HelpIcon pathNameOverride="/tickets" />
            </Route>
            <Route path="/project">
                <HelpIcon pathNameOverride="/project" />
            </Route>
            <Route path="/">
                <HelpIcon />
            </Route>
        </Switch>
    );
};

type Props = {
    pathNameOverride?: string;
};

const HelpIcon = ({ pathNameOverride }: Props) => {
    const { pathname } = useLocation();

    const { data: helpListItem } = useGetHelpListItem(pathNameOverride || pathname);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const slideIn = useSpring({
        transform: isOpenModal ? 'translateX(0px)' : 'translateX(400px)',
        config: { friction: 30 },
    });

    const ref = React.useRef<HTMLDivElement>(null);
    useHandleClickOutside({ ref, clickOutsideFunc: () => setIsOpenModal(false) });

    return (
        <>
            <IconWrapper>
                <div>
                    <Icon isOpenModal={isOpenModal} onClick={() => setIsOpenModal(!isOpenModal)} />
                </div>
            </IconWrapper>
            {isOpenModal && (
                <ModalContainer ref={ref} style={slideIn}>
                    <StyledClose inverted onClose={() => setIsOpenModal(false)} />
                    <ModalWrapper>
                        {helpListItem ? (
                            <>
                                <Title>{helpListItem.title}</Title>
                                <RawHtmlHelp content={helpListItem.description || ''} />
                            </>
                        ) : (
                            <NoHelpText>
                                {L('no_help_text_p1')} <Link to="/help">{L('click_here')}</Link> {L('no_help_text_p2')}
                            </NoHelpText>
                        )}
                    </ModalWrapper>
                </ModalContainer>
            )}
        </>
    );
};

export default HelpIconRouter;
