import React from 'react';
import styled from '@emotion/styled';
import useSaveTheme from '../hooks/useSaveTheme';
import useGetTheme from '../hooks/useGetThemes';
import { SiteTheme } from '@app/api/models/PublicSite';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button } from '../../Buttons/Button';
import { L } from '../../../lib/i18n';
import ThemePreviewBox from '../../../areas/public-site/components/ThemePreviewBox';

const ThemeList = styled.div({
    margin: '3rem',
    display: 'flex',
    justifyContent: 'space-evenly',
});

const Theme = styled.div({
    textAlign: 'center',
});

const ThemeItem = styled.div({
    display: 'flex',
});

const ButtonWrapper = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
});

const InfoWrapper = styled.div(({ theme }: { theme: SiteTheme['theme'] }) => {
    return {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: theme?.fonts?.body?.fontFamily,
        ['h1, h2, h3, h5, h5, h6']: {
            fontFamily: theme?.fonts?.header?.fontFamily,
        },
    };
});

type Props = {
    selectThemePreview: (siteTheme: SiteTheme) => void;
};
const ThemeFeatures = ({ selectThemePreview }: Props) => {
    const { data } = useGetTheme();
    const { mutate: saveTheme, status, reset } = useSaveTheme();
    const [themeState, setThemeState] = useState<SiteTheme[]>([]);

    useEffect(() => {
        if (!data) return;
        setThemeState(data);
    }, [data]);

    const onChangeHandler = (themeId: number) => {
        const Ctheme = [...themeState];
        Ctheme?.forEach((t) => (t.selected = false));
        const siteTheme = themeState?.find((t) => t.id === themeId);
        if (!siteTheme) return;
        reset();
        siteTheme.selected = true;
        selectThemePreview(siteTheme);
        setThemeState([...themeState]);
    };

    const onSaveHandler = () => {
        const selectedTheme = themeState.find((t) => t.selected);
        if (!selectedTheme) return;
        saveTheme({ themeId: selectedTheme.id });
    };

    const SelectThemes = themeState?.map((theme) => (
        <ThemeItem key={theme.id}>
            <InfoWrapper theme={theme.theme}>
                <ThemePreviewBox siteTheme={theme.theme} />
                <span>{L(theme.name)}</span>
                <input
                    style={{ marginTop: '1rem' }}
                    type="radio"
                    checked={theme.selected}
                    name="themeState"
                    onChange={() => onChangeHandler(theme.id)}
                />
            </InfoWrapper>
        </ThemeItem>
    ));

    return (
        <Theme>
            <h1>{L('select_theme')}</h1>
            <ThemeList>{SelectThemes}</ThemeList>
            <ButtonWrapper>
                <Button variant="filledPrimary" type="submit" onClick={onSaveHandler}>
                    {(status === 'idle' || status === 'error') && L('save')}
                    {status === 'loading' && L('saving')}
                    {status === 'success' && L('saved')}
                </Button>
            </ButtonWrapper>
        </Theme>
    );
};

export default ThemeFeatures;
