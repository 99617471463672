import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Icon } from '../../../components/Icon/Icon';
import useSaveType from '../hooks/useSaveType';
import BoxColorPicker from '../../../components/BoxColorPicker/BoxColorPicker';
import { ContractType } from '@app/api/models/Contracts';
import useDeleteType from '../hooks/useDeleteType';
import Input from '../../../components/Forms/Input';
import { useQueryClient } from 'react-query';
import { getContractKeys } from '../hooks/useContract';

const ItemWrapper = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    marginBottom: '1rem',
});
const TrashCanIcon = styled(Icon.TrashCan)({
    cursor: 'pointer',
});
const InvisibleButton = styled.button({
    backgroundColor: 'transparent',
    border: 'none',
    padding: '0px',
});

type Props = {
    item: ContractType;
};

const TypeSettingItem = ({ item }: Props) => {
    const [color, setColor] = useState(item.color_code);
    const [name, setName] = useState(item.name);
    const { mutate: remove } = useDeleteType();
    const { mutate: save } = useSaveType();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (name !== item.name || color !== item.color_code) {
            save({ id: item.id, name, color_code: color });
        }
    }, [color, item, name]);

    useEffect(() => {
        return () => {
            queryClient.invalidateQueries(getContractKeys.list());
        };
    }, []);

    return (
        <ItemWrapper key={item.id}>
            <BoxColorPicker initialColor={item.color_code || ''} getValue={(value) => setColor(value)} />
            <Input style={{ width: '100%' }} onChange={(e) => setName(e.target.value)} value={name} />
            <InvisibleButton onClick={() => remove(item.id)}>
                <TrashCanIcon />
            </InvisibleButton>
        </ItemWrapper>
    );
};

export default TypeSettingItem;
