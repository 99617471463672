import { PropertyFilter } from '@app/api/models/Property';
import styled from '@emotion/styled';
import React, { useMemo, useState } from 'react';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { Loading } from '../../../components/Loading/Loading';
import { desktop } from '../../../components/MediaQuery/breakpoints';
import Table from '../../../components/Table/ReactTable/Table';
import { COLUMNS, COLUMNSMOBILE } from './Columns';
import FilterArea from './FilterArea';
import TopArea from './TopArea';
import { useGetPropertiesList } from '../hooks/propertyQueries';
import useReactTable from '../../../components/Table/ReactTable/useReactTable';

const MobileView = styled.div({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});

const DesktopView = styled.div({
    display: 'none',
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'block',
    },
});

const PropertiesListPage = () => {
    const { data: properties } = useGetPropertiesList();
    const [filter, setFilter] = useState<PropertyFilter>({
        confirmed_at: '',
    });

    const filteredData = useMemo(() => {
        return (
            properties?.filter((property) => {
                if (!filter.confirmed_at) return true;
                return property.users?.some((user) => {
                    if (filter.confirmed_at === 'Konto bekräftat') return !!user.confirmed_at;
                    if (filter.confirmed_at === 'Ej bekräftat') return !user.confirmed_at;
                });
            }) || []
        );
    }, [filter, properties]);

    const useTable = useReactTable({
        columns: COLUMNS,
        data: filteredData,
    });

    const useTableMobile = useReactTable({
        columns: COLUMNSMOBILE,
        data: filteredData,
    });
    const Filter = (
        <FilterArea
            filter={filter}
            setFilter={setFilter}
            globalFilter={useTable.state.globalFilter}
            setGlobalFilter={useTable.setGlobalFilter}
        />
    );

    const FilterMobile = (
        <FilterArea
            filter={filter}
            setFilter={setFilter}
            globalFilter={useTableMobile.state.globalFilter}
            setGlobalFilter={useTableMobile.setGlobalFilter}
        />
    );

    if (!properties) return <Loading />;
    return (
        <>
            <DesktopView>
                <CardWithDivider
                    topArea={<TopArea />}
                    mainArea={
                        <>
                            {Filter}
                            <Table useTable={useTable} />
                        </>
                    }
                />
            </DesktopView>
            <MobileView>
                <CardWithDivider
                    topArea={<TopArea />}
                    mainArea={
                        <>
                            {FilterMobile}
                            <Table useTable={useTableMobile} />
                        </>
                    }
                />
            </MobileView>
        </>
    );
};

export default PropertiesListPage;
