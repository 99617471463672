import React, { MouseEventHandler } from 'react';
import { colors } from '../Shared/Style/colors';
import styled from '@emotion/styled';

const Li = styled.li(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '19px',
    color: theme.colors?.tabInactive,

    boxSizing: 'border-box',
    display: 'inline-block',
    margin: '0px',
    textDecoration: 'none',
    padding: '16px 0px 8px 0px',

    '&:hover': {
        color: theme.colors?.tabActive,
        cursor: 'pointer',
        textDecoration: 'none',
    },
    '&.active': {
        color: theme.colors.tabActive,
        textDecoration: 'none',
        borderBottom: `1px solid ${theme.colors.tabActive}`,
    },
}));

type Props = {
    className?: string;
    onClick?: MouseEventHandler;
};

const SmallTabListItem: React.FC<Props> = ({ children, onClick, className, ...rest }) => {
    return (
        <Li onClick={onClick} className={className} {...rest}>
            {children}
        </Li>
    );
};

export default SmallTabListItem;
