import { MemberFilter } from '@app/api/models/Members/MembersModels';
import React, { useMemo, useState } from 'react';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import { Loading } from '../../../../components/Loading/Loading';
import { desktop } from '../../../../components/MediaQuery/breakpoints';
import TopArea from './TopArea';
import FilterArea from './FilterArea';
import { COLUMNS } from './Columns';
import useTableCheckbox from '../../../members/ListPage/TableProvider';
import { useParams } from 'react-router';
import NewPropertyMemberForm from '../components/NewPropertyMemberForm';
import TableRowSelection from './TableRowSelection';
import { useGetPropertyMembers } from '../../hooks/propertyQueries';
import styled from '@emotion/styled';
import { style } from '../../../../components/Shared/Style';
import * as Dialog from '@radix-ui/react-dialog';

const MobileView = styled.div({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});

const DesktopView = styled.div({
    display: 'none',
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'block',
    },
});

const DialogContent = styled(Dialog.Content)(({ theme }) => ({
    width: '500px',
    placeSelf: 'center',
    backgroundColor: theme.colors.white,
    padding: '2rem',
    borderRadius: style.roundCornerSize.small,
    border: 'none',
}));

const Overlay = styled(Dialog.Overlay)({
    background: 'rgba(0 0 0 / 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'grid',
    placeItems: 'center',
    overflowY: 'auto',
});

const MemberTable = () => {
    const { id } = useParams<{ id: string }>();
    const { data } = useGetPropertyMembers(+id);

    const [filter, setFilter] = useState<MemberFilter>({
        accepted: undefined,
    });

    const filteredData = useMemo(() => {
        return (
            data?.filter((member) => {
                if (!filter.accepted) return true;
                if (filter.accepted === 'Bekräftat') return member.confirmed_at;
                if (filter.accepted === 'Ej bekräftad') return !member.confirmed_at;
            }) || []
        );
    }, [filter, data]);

    const useTable = useTableCheckbox({ data: filteredData, columns: COLUMNS });

    const selectedData = useTable.selectedFlatRows.map((data) => {
        return data.original;
    });

    const [open, setOpen] = React.useState(false);

    if (!data) return <Loading />;

    return (
        <>
            <Dialog.Root modal open={open} onOpenChange={setOpen}>
                <Dialog.Portal>
                    <Overlay>
                        <DialogContent
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <NewPropertyMemberForm handleCloseModal={() => setOpen(false)} />
                        </DialogContent>
                    </Overlay>
                </Dialog.Portal>
            </Dialog.Root>

            <DesktopView>
                <CardWithDivider
                    topArea={
                        <TopArea
                            handleOpenModal={() => setOpen(true)}
                            selectedMembers={selectedData}
                            onSubmit={(value: boolean) => useTable.toggleAllRowsSelected(value)}
                        />
                    }
                    mainArea={
                        <TableRowSelection
                            useTable={useTable}
                            filterArea={<FilterArea filter={filter} setFilter={setFilter} />}
                        />
                    }
                />
            </DesktopView>
            <MobileView>
                <CardWithDivider
                    topArea={
                        <TopArea
                            handleOpenModal={() => setOpen(true)}
                            selectedMembers={selectedData}
                            onSubmit={(value: boolean) => useTable.toggleAllRowsSelected(value)}
                        />
                    }
                    mainArea={
                        <TableRowSelection
                            useTable={useTable}
                            filterArea={<FilterArea filter={filter} setFilter={setFilter} />}
                        />
                    }
                />
            </MobileView>
        </>
    );
};

export default MemberTable;
