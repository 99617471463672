import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetMemberNewsById } from '../hooks/memberNewsQueries';
import NewsEditDraft from './NewsEditDraft';
import NewsEditPublished from './NewsEditPublished';

const NewsDetailPage = () => {
    const { id } = useParams<{ id: string }>();

    const { data } = useGetMemberNewsById(+id);

    if (!data?.published_at) {
        return <NewsEditDraft />;
    }

    return <NewsEditPublished />;
};

export default NewsDetailPage;
