import { useMutation, useQueryClient } from 'react-query';
import customFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import { createValidator } from '../../../lib/validation';
import { useParams } from 'react-router';
import BookingsApi from '@app/api/public/BookingsApi';
import { BookingSlot } from '@app/api/models/Bookings';
import { getbookingResourceSlotsQueryKey } from './useGetBookingResourceSlots';
import createIntervals from '../DetailPage/lib/createIntervals';

dayjs.extend(customFormat);

type Variables = {
    start: string;
    end: string;
    interval: { intervalH: string };
};

const useSaveTimeSlots = () => {
    const queryClient = useQueryClient();
    const { bookingId } = useParams<{ bookingId: string }>();
    return useMutation(
        (variables: Variables) => {
            if (variables.start >= variables.end && variables.end !== '00:00') {
                return Promise.reject({
                    starttime: 'måste vara före sluttid',
                    endtime: 'måste vara efter starttid',
                });
            }

            try {
                const intervals = createIntervals(variables.start, variables.end, variables.interval);

                const slots = intervals.map((interval) => {
                    const slot: Pick<BookingSlot, 'booking_resource_id' | 'name' | 'starttime' | 'endtime'> = {
                        booking_resource_id: +bookingId,
                        starttime: interval.start,
                        endtime: interval.end,
                        name: `${interval.start} - ${interval.end}`,
                    };

                    return slot;
                });

                return BookingsApi.saveSlots(slots);
            } catch (err) {
                return Promise.reject({
                    interval: err.message,
                });
            }
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries([getbookingResourceSlotsQueryKey, bookingId]);
            },
        }
    );
};

export default useSaveTimeSlots;
