import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timeZones from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/sv';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(timeZones);
dayjs.tz.guess();
dayjs.locale('sv');

dayjs.updateLocale('sv', {
    relativeTime: {
        future: 'om %s',
        past: '%s sedan',
        s: '< 1 min',
        m: 'minut',
        mm: '%d minuter',
        h: 'en timme',
        hh: '%d timmar',
        d: 'en dag',
        dd: '%d dagar',
        M: 'en månad',
        MM: '%d månader',
        y: 'ett år',
        yy: '%d år',
    },
});

export function capitalize(value: string) {
    return value.toLowerCase().replace(/(^| )[a-z]/g, (v) => v.toUpperCase());
}

const datetimeFormat = new Intl.DateTimeFormat('sv-SE', {
    weekday: 'long',
    day: '2-digit',
    month: 'long',
    hour: '2-digit',
    minute: '2-digit',
});

export function formatDatetime(date: string | Date) {
    let value = 0;
    if (typeof date === 'string') {
        value = Date.parse(date);
    } else {
        value = +date;
    }
    return datetimeFormat.format(value).charAt(0).toUpperCase() + datetimeFormat.format(value).slice(1);
}

export function formatDateIsoDayjs(date?: Date | string | undefined | null) {
    return dayjs(date || undefined)
        .tz()
        .format('YYYY-MM-DD HH:mm');
}

export function formatDateTimeZone(date?: Date | string | undefined) {
    if (!date || !dayjs(date).isValid()) return '';
    return dayjs(date).tz().format();
}

export function formatInputDateTimeDayjs(date?: Date | string | undefined | dayjs.Dayjs) {
    if (!dayjs(date).isValid()) return '';
    return dayjs(date).tz().format('YYYY-MM-DDTHH:mm');
}

export function formatFeedDate(date: dayjs.ConfigType) {
    if (!dayjs(date).isValid()) return '';
    if (dayjs(date).isSame(dayjs(), 'day')) {
        return dayjs(date).tz().locale('sv').fromNow();
    }
    if (dayjs(date).isSame(dayjs(), 'year')) {
        return dayjs(date).tz().format('D MMMM');
    }
    return dayjs(date).tz().format('D MMMM YYYY');
}

/**
 *
 * @param name Name to check and possibly rename.
 * @param nameList List of names to compare to.
 * @returns Returns the same name, but with a number in parenthasis at the end according to existing names. Returns original name if it wasn't taken.
 */
export const checkNameInList = (name: string, nameList: { name: string }[]) => {
    let newName = name;
    const sameName = nameList.find((f) => f.name === name);

    if (sameName) {
        let keepSearching = true;

        for (let i = 1; keepSearching; i++) {
            const nextPossibleName = `${name} (${i})`;
            const sameName = nameList.find((f) => f.name === nextPossibleName);

            if (!sameName) {
                newName = nextPossibleName;
                keepSearching = false;
            }
        }
    }

    return { isInList: newName !== name, oldName: name, newName };
};
