import { useQuery } from 'react-query';
import AlertsApi from '@app/api/public/AlertsApi';

export type AlertFilter = { propertyId?: number };
export const alertKeys = {
    all: ['alerts'] as const,
    list: (filter?: AlertFilter) => [...alertKeys.all, 'list', filter],
    propertyNames: (id?: number) => [...alertKeys.all, 'propertyNames', { id }],
    detail: (id?: number) => [...alertKeys.all, 'detail', { id }],
    propertFilter: () => [...alertKeys.all, 'propertFilter'],
};

const getAlerts = (filter: AlertFilter) => {
    return AlertsApi.list(filter);
};
const useGetAlerts = (filter: AlertFilter) => {
    return useQuery(alertKeys.list(filter), () => getAlerts(filter));
};

const getAlertById = (alertId?: number) => {
    if (!alertId) return;
    return AlertsApi.id(alertId);
};
const useGetAlertById = (alertId?: number) => {
    return useQuery(alertKeys.detail(alertId), () => getAlertById(alertId), {
        enabled: !!alertId,
    });
};

const getPropertyNames = (id?: number) => {
    if (!id) return [];
    return AlertsApi.getPropertyNames(id);
};

const useGetPropertyNames = (id?: number) => {
    return useQuery(alertKeys.propertyNames(id), () => getPropertyNames(id), { enabled: !!id });
};

const getPropertyFilterList = () => {
    return AlertsApi.getPropertyFilterList();
};

const useGetPropertyFilterList = () => {
    return useQuery(alertKeys.propertFilter(), () => getPropertyFilterList());
};
export { useGetAlerts, useGetPropertyNames, useGetPropertyFilterList, useGetAlertById };
