import * as React from 'react';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';

const Container = styled.div((props: { background: string; width: string; margin: string; padding: any }) => ({
    borderRadius: style.roundCornerSize.small,
    padding: props.padding,
    background: props.background,
    width: props.width,
    display: 'flex',
    flexDirection: 'column',
    margin: `${props.margin} 0px`,
}));

// const Container = styled.div
//     border-radius: ${style.roundCornerSize.small}px;
//     padding: ${style.margin.l}px;
//     background: ${props => props.background};
// `;

interface Props {
    background?: string;
    children?: any;
    width?: string;
    margin?: string;
    padding?: any;
}

export const Card = (props: Props) => {
    return (
        <Container
            background={props.background || ''}
            width={props.width || ''}
            margin={props.margin || ''}
            padding={props.padding || style.margin.l}
            {...props}
        >
            {props.children}
        </Container>
    );
};
