import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import useGetByPageType from '../../../components/Website/hooks/useGetByPageType';
import useUpdatePageListItem from '../../../components/Website/hooks/useUpdatePageListItem';
import useUpdatePublish from '../../../components/Website/hooks/useUpdatePublish';
import { Button } from '../../../components/Buttons/Button';
import StyledSwitch from '../../../components/StyledSwitch/StyledSwitch';
import { L } from '../../../lib/i18n';
import ContactPreview from '../../../components/Website/components/Preview/ContactPreview';
import DocumentsTableById from '../components/DocumentsTableById';
import { ThemeProvider } from 'emotion-theming';
import useThemeProviderState from '../theme/hooks/useThemeProviderState';
import Hero from '../../../components/Website/components/Hero';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import FormHeader from '../../../components/Forms/FormHeader';
import Quill from '../../../components/Quill/Quill';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardWithDividerTopArea from '../../../components/Layout/CardWithDividerTopArea';

const Box = styled.div({
    backgroundColor: 'white',
    borderRadius: '4px',
    marginBottom: '2rem',
});
const TopWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});
const SwitchWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
});

const BottomWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '1rem',
    marginBottom: '2rem',
});
const FileInput = styled.input({
    display: 'none',
});
const ImageLabel = styled.label({
    cursor: 'pointer',
});
const Span = styled.span({
    paddingRight: '0.5rem',
});
const ImageWrapper = styled.div({
    width: '100%',
    marginBottom: '2rem',
});

const Contact = () => {
    const [description, setDescription] = useState('');
    const [title, setTitle] = useState('');
    const [file, setFile] = useState();
    const [imgUrl, setImgUrl] = useState('');
    const { data: contactPageData } = useGetByPageType('contact_page');
    const { mutate: updateContactPageData, status } = useUpdatePageListItem();
    const { mutate: updatePublish } = useUpdatePublish();

    const { theme } = useThemeProviderState();

    useEffect(() => {
        if (!contactPageData) return;
        setDescription(contactPageData.description);
        setTitle(contactPageData.title);
    }, [contactPageData]);

    const handleClick = () => {
        if (!contactPageData) return;
        updatePublish({ ...contactPageData });
    };

    const uploadedImage = (event) => {
        if (!event.target.files) return;
        setFile(event.target.files[0]);
        setImgUrl(URL.createObjectURL(event.target.files[0]));
    };

    const save = () => {
        updateContactPageData({
            publicSiteItem: {
                ...contactPageData,
                description,
                title,
                page_type: 'contact_page',
            },
            file,
        });
    };

    return (
        <CardWithDivider
            topArea={<CardWithDividerTopArea title={L('edit_homepage_content_title')} />}
            mainArea={
                <>
                    <Box>
                        <TopWrapper>
                            <Label title={L('title')}>
                                <Input value={'Kontakt'} readOnly={true} />
                            </Label>
                            {contactPageData?.id && (
                                <SwitchWrapper>
                                    <Span>{L('publish')}</Span>
                                    <StyledSwitch
                                        checked={contactPageData?.published_at ? true : false}
                                        onClick={handleClick}
                                    />
                                </SwitchWrapper>
                            )}
                        </TopWrapper>
                        <Label title={L('content')} />
                        <Quill
                            value={description}
                            onChange={(value) => setDescription(value)}
                            placeholder={L('write_something') + '...'}
                        />
                        {contactPageData?.id && (
                            <>
                                <Label title={L('background_image')}>
                                    <ImageWrapper>
                                        <ImageLabel>
                                            <Hero imgUrl={imgUrl} pageListItem={contactPageData} />
                                            <FileInput
                                                accept=".jpg,.png"
                                                type="file"
                                                onChange={uploadedImage}
                                                id="heroImage"
                                            />
                                        </ImageLabel>
                                    </ImageWrapper>
                                </Label>
                            </>
                        )}
                        <BottomWrapper>
                            <Button variant="selectedMenu" onClick={save}>
                                {status === 'loading' ? `${L('loading')}...` : L('save')}
                            </Button>
                        </BottomWrapper>
                    </Box>

                    {contactPageData?.id && (
                        <>
                            <Box>
                                <DocumentsTableById id={contactPageData.id} />
                            </Box>
                            <Box>
                                <FormHeader>{L('preview')}</FormHeader>
                                <ThemeProvider theme={theme}>
                                    <ContactPreview id={contactPageData.id} />
                                </ThemeProvider>
                            </Box>
                        </>
                    )}
                </>
            }
        />
    );
};

export default Contact;
