import { BookingFilter } from '@app/api/models/Bookings';
import BookingsApi from '@app/api/public/BookingsApi';
import { useQuery } from 'react-query';
export const useGetBookingsListQueryKey = 'useGetBookingsList';

const useGetBookingsList = (id: string, page = 1, filter?: BookingFilter) => {
    return useQuery(
        [useGetBookingsListQueryKey, id, page, filter],
        () => {
            return BookingsApi.listBookings(+id, page, 10, filter);
        },
        { keepPreviousData: true }
    );
};

export default useGetBookingsList;
