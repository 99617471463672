import React from 'react';
import styled from '@emotion/styled';
import Bell from '../../../components/Icon/bell.svg';
import Close from '../../../components/Icon/close.svg';
import { title } from '../../../components/Shared/Style/typography';
import toast from 'react-hot-toast';
import { Button } from '../../../components/Buttons/Button';
import { L } from '../../../lib/i18n';

const BellIcon = styled(Bell)(({ theme }) => ({
    height: '1rem',
    width: '1rem',
    g: {
        stroke: theme.colors.primary,
    },
}));

const DialogText = styled.p({
    ...title,
    margin: '0.875rem 0',
});

const ButtonWrapper = styled.div({
    display: 'flex',
    gap: '1rem',
    width: '100%',
    justifyContent: 'end',
});

const CloseButton = styled.button({
    border: 'none',
    backgroundColor: 'inherit',
    position: 'absolute',
    top: '5px',
    right: '5px',
    cursor: 'pointer',
});

type Args = {
    notify: (notify: boolean) => void;
    type: 'publish' | 'update';
};

export const notificationToast = ({ notify, type }: Args) => {
    toast(
        (t) => (
            <div>
                <DialogText>{L(`content_notify_title_${type}`)}</DialogText>
                <ButtonWrapper>
                    <Button
                        onClick={() => {
                            toast.dismiss(t.id);
                            notify(false);
                        }}
                    >
                        {L(`content_button_${type}`)}
                    </Button>
                    <Button
                        onClick={() => {
                            toast.dismiss(t.id);
                            notify(true);
                        }}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5ch',
                        }}
                    >
                        <BellIcon />
                        <span>{L(`content_button_notify_${type}`)}</span>
                    </Button>
                </ButtonWrapper>
                <CloseButton onClick={() => toast.dismiss(t.id)} aria-label="Close">
                    <Close />
                </CloseButton>
            </div>
        ),
        {
            duration: Infinity,
            id: 'notification-toast',
            style: {
                minWidth: '400px',
            },
        }
    );
};
