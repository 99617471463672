import ContentNewsApi from '@app/api/public/ContentNewsApi';
import { useMutation, useQueryClient } from 'react-query';
import { memberNewsQueryKeys } from './memberNewsQueries';

type Variables = { id: number };

const useSetNewsAsDraft = () => {
    const client = useQueryClient();
    return useMutation(
        (varables: Variables) => {
            return ContentNewsApi.setNewsAsDraft(varables.id);
        },
        {
            onSettled: (data, error, varables) => {
                client.invalidateQueries(memberNewsQueryKeys.lists());
                client.invalidateQueries(memberNewsQueryKeys.detail(varables.id));
            },
        }
    );
};

export default useSetNewsAsDraft;
