import { TicketSettingsByType } from '@app/api/models/Tickets';
import React from 'react';
import styled from '../../../components/lib/styled';
import { L } from '../../../lib/i18n';

const OptionsLableWrapper = styled.div({
    alignItems: 'center',
    display: 'flex',
    gap: '1ch',
    cursor: 'pointer',
});
type Option = {
    label: string;
    value: TicketSettingsByType;
};
const formatOptionLabelColorBox = (option: Option) => {
    if (typeof option.value === 'string') return `${L('create')} "${option.value}"`;
    return (
        <OptionsLableWrapper>
            <div
                style={{
                    width: '15px',
                    height: '15px',
                    backgroundColor: option.value.color_code,
                    borderRadius: '2px',
                }}
            />
            {option.value.name}
        </OptionsLableWrapper>
    );
};

export default formatOptionLabelColorBox;
