import { styled } from '@storybook/theming';
import React, { useState } from 'react';
import { Button } from '../../../../components/Buttons/Button';
import Input from '../../../../components/Forms/Input';
import Label from '../../../../components/Forms/Label';
import { Icon } from '../../../../components/Icon/Icon';
import { style } from '../../../../components/Shared/Style';
import { margin } from '../../../../components/Shared/Style/margin';
import Spinner from '../../../../components/Spinner/Spinner';
import { L } from '../../../../lib/i18n';
import useSaveTimeSlot from '../../hooks/useSaveTimeSlot';
import useValidinterval from '../../hooks/useValidInterval';
import createIntervals from '../lib/createIntervals';

const StyledContainer = styled.div({
    display: 'grid',
    gridTemplateColumns: '300px 300px 300px auto',
    gap: '2rem',
    padding: '20px 0px',
});

const ButtonContainer = styled.div({
    placeSelf: 'end',
    width: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
});

const IntervalWrapper = styled.div({
    display: 'flex',
    gap: '10px',
});

const AddTimeslotInterval = () => {
    const [view, setView] = useState('initial');
    const { mutate, status: timeSlotStatus, error, reset } = useSaveTimeSlot();
    const [startTime, setStartTime] = useState('07:00');
    const [endTime, setEndTime] = useState('16:00');
    const [intervalH, setIntervalH] = useState('1');

    const isIntervalvalid = useValidinterval({ startTime, endTime, intervalH });

    const save = () => {
        if (!isIntervalvalid) return;
        mutate({ start: startTime, end: endTime, interval: { intervalH } });
    };

    if (view === 'addItem') {
        return (
            <StyledContainer>
                <Label
                    style={{ alignSelf: 'end' }}
                    title={L('interval_from')}
                    error={typeof error === 'object' && error?.['starttime']}
                >
                    <Input
                        value={startTime}
                        onChange={(e) => {
                            reset();
                            setStartTime(e.target.value);
                        }}
                        type={'time'}
                    />
                </Label>
                <Label
                    style={{ alignSelf: 'end' }}
                    title={L('interval_to')}
                    error={typeof error === 'object' && error?.['endtime']}
                >
                    <Input
                        value={endTime}
                        onChange={(e) => {
                            reset();

                            setEndTime(e.target.value);
                        }}
                        type={'time'}
                    />
                </Label>
                <Label
                    title={L('choose_interval') + ' ' + L('hours')}
                    error={typeof error === 'object' && error?.['interval']}
                >
                    <Input
                        value={intervalH}
                        onChange={(e) => {
                            reset();
                            if (+e.target.value < 1) {
                                return setIntervalH('1');
                            }
                            if (+e.target.value > 12) {
                                return setIntervalH('12');
                            }
                            setIntervalH(e.target.value);
                        }}
                        max="12"
                        min="1"
                        type={'number'}
                    />
                </Label>

                <ButtonContainer>
                    <Button
                        style={{ display: 'flex', justifyContent: 'center' }}
                        onClick={save}
                        variant={'selectedMenu'}
                        disabled={!isIntervalvalid}
                    >
                        {timeSlotStatus === 'idle' && L('save')}
                        {timeSlotStatus === 'loading' && <Spinner style={{ alignSelf: 'center' }} />}
                        {timeSlotStatus === 'error' && L('save')}
                        {timeSlotStatus === 'success' && L('saved')}
                    </Button>
                    <Button onClick={() => setView('initial')} style={{ marginRight: '0px' }} variant={'selectedMenu'}>
                        {L('cancel')}
                    </Button>
                </ButtonContainer>
            </StyledContainer>
        );
    }

    return <Icon.Add onClick={() => setView('addItem')} style={{ alignSelf: 'flex-end', marginBottom: margin.m }} />;
};

export default AddTimeslotInterval;
