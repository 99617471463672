Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["listMailTempaltes"] = createRpcProxy("ProjectSettingsApi", "listMailTempaltes", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getMailTemplateByName"] = createRpcProxy("ProjectSettingsApi", "getMailTemplateByName", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["saveMailTemplate"] = createRpcProxy("ProjectSettingsApi", "saveMailTemplate", {
  "urlPrefix": "/rpc"
});
o["getTicketEnabled"] = createRpcProxy("ProjectSettingsApi", "getTicketEnabled", {
  "urlPrefix": "/rpc"
});
o["setTicketEnabled"] = createRpcProxy("ProjectSettingsApi", "setTicketEnabled", {
  "urlPrefix": "/rpc"
});
o["getCommunityFeaturePreview"] = createRpcProxy("ProjectSettingsApi", "getCommunityFeaturePreview", {
  "urlPrefix": "/rpc"
});