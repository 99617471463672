import { FontsType, PublicThemeType } from '@app/api/models/PublicSite';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { Button } from '../../../components/Buttons/Button';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import { Card } from '../../../components/Layout/Card';
import { L } from '../../../lib/i18n';
import { colors } from '../lib/publicColors';
import FontInput from './FontInput';
import useGetGoogleApiFonts from './hooks/useGetGoogleApiFonts';
import useGetThemeByProjectId from './hooks/useGetThemeByProjectId';
import useSaveTheme from './hooks/useSaveTheme';

const Form = styled.form({
    display: 'grid',
    gap: '1rem 2rem',
    gridTemplateAreas: `
    "themeName                primary"
    "textColor1      navbarBackground"
    "footerBackground  pageBackground"
    "footerText              FontBody"
    "FontHeader             ........."
    "infoFontLink                    infoFontLink"
    "..........          submitbutton"
    `,
});
const SubmitButton = styled(Button)({
    placeSelf: 'end',
    gridArea: 'submitbutton',
});

const InfoFontLink = styled.a({
    gridArea: 'infoFontLink',
    color: colors.textColor2,
    textDecoration: 'none',
});
type PublicColors = PublicThemeType['colors'];
type PublicHeaderFont = FontsType['header'];
type PublicBodyFont = FontsType['body'];

const CreateThemePage = () => {
    const { data } = useGetThemeByProjectId();
    const [themeName, setThemeName] = useState('');
    const [textColor1, setTextColor1] = useState<PublicColors['textColor1']>(colors.textColor1);
    const [footerText, setFooterText] = useState<PublicColors['footerText']>(colors.footerText);

    const [navbarBackground, setNavbarBackground] = useState<PublicColors['navbarBackground']>(colors.navbarBackground);

    const [pageBackground, setPageBackground] = useState<PublicColors['pageBackground']>(colors.pageBackground);

    const [footerBackground, setFooterBackground] = useState<PublicColors['footerBackground']>(colors.footerBackground);

    const [primary, setPrimary] = useState<PublicColors['primary']>(colors.primary);

    const [headerFont, setHeaderFont] = useState<PublicHeaderFont | undefined>();

    const [bodyFont, setBodyFont] = useState<PublicBodyFont | undefined>();

    const { data: googleFonts } = useGetGoogleApiFonts();

    const { mutate, status, error } = useSaveTheme();

    useEffect(() => {
        if (!data) return;

        if (data?.name) {
            setThemeName(data.name);
        }
        if (data.theme?.colors?.textColor1) {
            setTextColor1(data.theme.colors.textColor1);
        }
        if (data.theme?.colors?.footerText) {
            setFooterText(data.theme.colors.footerText);
        }
        if (data.theme?.colors?.navbarBackground) {
            setNavbarBackground(data.theme.colors.navbarBackground);
        }
        if (data.theme?.colors?.pageBackground) {
            setPageBackground(data.theme.colors.pageBackground);
        }
        if (data.theme?.colors?.primary) {
            setPrimary(data.theme.colors.primary);
        }
        if (data.theme?.colors?.footerBackground) {
            setFooterBackground(data.theme.colors.footerBackground);
        }
        if (data.theme?.fonts?.body) {
            setBodyFont(data.theme.fonts.body);
        }
        if (data.theme?.fonts?.header) {
            setHeaderFont(data.theme.fonts.header);
        }
    }, [data]);
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const colors = {
            textColor1,
            pageBackground,
            footerBackground,
            navbarBackground,
            primary,
            footerText,
        };
        mutate({
            id: data?.id,
            name: themeName,
            theme: {
                colors: {
                    ...colors,
                },
                fonts: {
                    header: { ...headerFont },
                    body: { ...bodyFont },
                },
            },
        });
    };

    const handleTypographyInput = (e: EventTarget & HTMLInputElement, type: 'header' | 'body') => {
        const font = googleFonts?.items.find((item) => item.family === e.value);

        if (!font) return;

        if (type === 'header') {
            setHeaderFont({
                fontFamily: e.value,
                fontData: font,
            });
        }
        if (type === 'body') {
            setBodyFont({
                fontFamily: e.value,
                fontData: font,
            });
        }
    };

    return (
        <Card>
            <Form onSubmit={handleSubmit}>
                <Label style={{ gridArea: 'themeName' }} title={'Temanamn'} error={error?.['name']}>
                    <Input value={themeName} onChange={(e) => setThemeName(e.target.value)} />
                </Label>
                <Label style={{ gridArea: 'textColor1' }} title={'Textfärg'} error={error?.['textColor1']}>
                    <Input type={'color'} value={textColor1} onChange={(e) => setTextColor1(e.target.value)} />
                </Label>
                <Label style={{ gridArea: 'footerText' }} title={L('color_footer_text')} error={error?.['footerText']}>
                    <Input type={'color'} value={footerText} onChange={(e) => setFooterText(e.target.value)} />
                </Label>
                <Label
                    style={{ gridArea: 'navbarBackground' }}
                    title={L('background_color_menu')}
                    error={error?.['navbarBackground']}
                >
                    <Input
                        type={'color'}
                        value={navbarBackground}
                        onChange={(e) => setNavbarBackground(e.target.value)}
                    />
                </Label>
                <Label
                    style={{ gridArea: 'pageBackground' }}
                    title={L('background_color_page')}
                    error={error?.['pageBackground']}
                >
                    <Input type={'color'} value={pageBackground} onChange={(e) => setPageBackground(e.target.value)} />
                </Label>
                <Label style={{ gridArea: 'primary' }} title={'Primary'} error={error?.['primary']}>
                    <Input type={'color'} value={primary} onChange={(e) => setPrimary(e.target.value)} />
                </Label>
                <Label
                    style={{ gridArea: 'footerBackground' }}
                    title={L('background_color_footer')}
                    error={error?.['footerBackground']}
                >
                    <Input
                        type={'color'}
                        value={footerBackground}
                        onChange={(e) => setFooterBackground(e.target.value)}
                    />
                </Label>

                <FontInput
                    error={error?.['fontFamily']}
                    style={{ gridArea: 'FontHeader' }}
                    name={L('font_title')}
                    startValue={headerFont?.fontFamily ? headerFont.fontFamily : ''}
                    handleTypographyInput={(e) => handleTypographyInput(e, 'header')}
                />
                <FontInput
                    error={error?.['fontFamily']}
                    style={{ gridArea: 'FontBody' }}
                    name={L('font_normal_text')}
                    startValue={bodyFont?.fontFamily ? bodyFont.fontFamily : ''}
                    handleTypographyInput={(e) => handleTypographyInput(e, 'body')}
                />
                <SubmitButton variant="filledPrimary" type="submit">
                    {(status === 'idle' || status === 'error') && L('save')}
                    {status === 'loading' && L('saving')}
                    {status === 'success' && L('saved')}
                </SubmitButton>

                <InfoFontLink href="https://fonts.google.com/" target={'_blank'}>
                    {L('font_info')} <strong>fonts.google.com</strong>
                </InfoFontLink>
            </Form>
        </Card>
    );
};

export default CreateThemePage;
