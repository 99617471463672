import React, { HTMLAttributes, useCallback, useState } from 'react';
import { DropEvent, DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { colors } from '../Shared/Style/colors';
import { roundCornerSize } from '../Shared/Style/box';
import UploadSvg from '../Icon/dragAndDropIcon.svg';
import { QueryStatus } from 'react-query';
import { BarLoader } from 'react-spinners';
import toast from 'react-hot-toast';
import { L } from '../../lib/i18n';
import { animated, useSpring } from 'react-spring';
import Cross from '../Icon/e-remove-outline-12.svg';

const StyledConfirm = styled.button({
    fontWeight: 80,
    fontSize: '1rem',
    border: 'none',
    textAlign: 'center',
    pointerEvents: 'all',
    backgroundColor: 'transparent',
    padding: '5px 6px',
    cursor: 'pointer',
    color: 'white',
    zIndex: 9999,
});

const StyledContainer = animated(
    styled.div({
        position: 'absolute',
        left: 8,
        top: 8,
        textAlign: 'center',
        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        borderRadius: '25px',
        zIndex: 9999,
    })
);

const CloseCross = styled(Cross)({
    alignSelf: 'center',
    gridArea: 'icon',
    width: '16px',
    height: '16px',
    '& path': {
        stroke: colors.white,
    },
});

const CrossContainer = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '32px',
});

const StyledDropZone = styled.div({
    backgroundColor: colors.white,
    minWidth: '335px',
    aspectRatio: '2/1',
    borderRadius: roundCornerSize.medium,
    border: `1px dashed ${colors.inputBorder}`,
    display: 'grid',
    placeItems: 'center',
    cursor: 'pointer',
});

const WithImageDropZone = styled(StyledDropZone)({
    position: 'relative',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    padding: '0px',
    overflow: 'hidden',
    placeItems: 'start end',
    '.hover_info': {
        display: 'none',
    },
    '.upload': {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        display: 'grid',
        height: '100%',
    },
    ':hover': {
        placeItems: 'center',
        '.hover_info': {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            display: 'grid',
            height: '100%',
            width: '100%',
        },

        '.with_image': {
            display: 'none',
        },
    },
});

const GridContainer = styled.div({
    display: 'grid',
    placeItems: 'center',
    height: '100%',
    width: '100%',
});
const StyledUploadIcon = styled.div({
    width: 'max-content',
    display: 'flex',
});

const UploadContainer = styled.div({
    display: 'grid',
    gap: '0.5rem',
    position: 'relative',
    placeItems: 'center',
    width: '80%',
});

const ProgressBar = styled.div({
    borderRadius: '8px',
    bottom: '-16px',
    height: '8px',
    backgroundColor: colors.progressBarBackground,
    overflow: 'hidden',
    width: '100%',
    position: 'absolute',
});

const Progress = styled.div(({ theme }) => ({
    backgroundColor: theme.colors.primary,
    width: '100%',
    height: '100%',
    translate: '-100%',
    borderRadius: '8px',
}));

const UploadText = styled.p(({ theme }) => ({
    margin: '0px',
    color: theme.black,
    textOverflow: 'ellipsis',
    fontSize: '1rem',
    '& span': {
        color: theme.primary,
    },
}));

const UploadIcon = () => {
    return (
        <StyledUploadIcon>
            <UploadSvg />
        </StyledUploadIcon>
    );
};

const RemoveCross = ({ remove }: { remove: () => void }) => {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const animationStyle = useSpring({ width: confirmDelete ? 75 : 32 });

    return (
        <StyledContainer style={animationStyle}>
            {confirmDelete ? (
                remove && (
                    <StyledConfirm
                        onClick={(e) => {
                            e.stopPropagation();
                            remove();
                            setConfirmDelete(false);
                        }}
                    >
                        {L('confirm')}
                    </StyledConfirm>
                )
            ) : (
                <CrossContainer
                    onClick={(e) => {
                        e.stopPropagation();
                        setConfirmDelete(true);
                    }}
                >
                    <CloseCross />
                </CrossContainer>
            )}
        </StyledContainer>
    );
};

const UploadInfo = ({ className, children, ...rest }: HTMLAttributes<HTMLDivElement>) => {
    return (
        <GridContainer className={'hover_info ' + className} {...rest}>
            <UploadContainer>
                <UploadIcon />
                <UploadText>
                    <span>Ladda upp</span> eller släpp din fil här
                </UploadText>
                {children}
            </UploadContainer>
        </GridContainer>
    );
};

const WithImage = () => {
    return (
        <UploadContainer
            className={'with_image'}
            style={{
                backgroundColor: colors.white,
                padding: '8px',
                margin: '8px',
                borderRadius: roundCornerSize.medium,
                width: 'max-content',
            }}
        >
            <UploadIcon />
        </UploadContainer>
    );
};

const OnUploadComponent = ({
    acceptedFiles,
    progress,
    className,
    ...rest
}: { acceptedFiles: File[]; progress?: number } & HTMLAttributes<HTMLDivElement>) => {
    return (
        <GridContainer className={'upload ' + className} {...rest}>
            <UploadContainer>
                <UploadIcon />
                <UploadText>{acceptedFiles?.[0]?.name}</UploadText>
                {typeof progress === 'number' ? (
                    <ProgressBar>
                        <Progress
                            style={{
                                translate: `${progress - 100}%`,
                            }}
                        />
                    </ProgressBar>
                ) : (
                    <BarLoader
                        color={colors.primary}
                        cssOverride={{
                            position: 'absolute',
                            bottom: '-16px',
                            width: '100%',
                        }}
                    />
                )}
            </UploadContainer>
        </GridContainer>
    );
};

type OnDrop = <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void;
export type Props = {
    dropzoneOptions?: DropzoneOptions;
    onDrop: OnDrop;
    url: string;
    status?: QueryStatus;
    className?: string;
    remove?: () => void;
};
const ImageDropZone = ({ dropzoneOptions = {}, onDrop, url, status = 'idle', className, remove }: Props) => {
    const onDropCallback = useCallback<
        <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void
    >((...rest) => {
        onDrop(...rest);
    }, []);

    const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
        maxFiles: 1,
        onDrop: onDropCallback,
        onDropRejected(fileRejections) {
            fileRejections.forEach((fileRejection) => {
                fileRejection.errors.forEach((error) => {
                    const errorCode = error.code.replace(/-/g, '_');
                    toast.error(`${fileRejection.file.name} ${L(errorCode)}`);
                });
            });
        },
        accept: {
            'image/*': [],
        },
        ...dropzoneOptions,
        maxSize: 100000000,
    });

    if (url) {
        return (
            <WithImageDropZone
                style={{
                    backgroundImage: `url(${url})`,
                }}
                className={className}
                {...getRootProps()}
            >
                {remove && <RemoveCross remove={remove} />}

                <input {...getInputProps()} />
                {status === 'loading' ? (
                    <OnUploadComponent acceptedFiles={acceptedFiles} />
                ) : (
                    <>
                        <WithImage />
                        <UploadInfo />
                    </>
                )}
            </WithImageDropZone>
        );
    }
    return (
        <StyledDropZone className={className} {...getRootProps()}>
            <input {...getInputProps()} />
            {status === 'loading' ? <OnUploadComponent acceptedFiles={acceptedFiles} /> : <UploadInfo />}
        </StyledDropZone>
    );
};

export default ImageDropZone;
