import BookingsApi from '@app/api/public/BookingsApi';
import { useQuery } from 'react-query';

export const useGetBookinUsersQueryKey = 'useGetBookinUsers';

const useGetBookinUsers = (resourceItemId?: string | number) => {
    return useQuery(
        [useGetBookinUsersQueryKey, resourceItemId],
        () => {
            if (!resourceItemId) return;
            return BookingsApi.listBookingUsers(+resourceItemId);
        },
        {
            enabled: !!resourceItemId,
        }
    );
};

export default useGetBookinUsers;
