import { AddMember } from '@app/api/models/Members';
import MembersApi from '@app/api/public/MembersApi';
import { useMutation } from 'react-query';

const saveImportMembersList = async (members: AddMember[]): Promise<AddMember[]> => {
    return MembersApi.importMembers(members);
};

const useSaveImportMembersList = () => {
    return useMutation((members: AddMember[]) => saveImportMembersList(members));
};

export { useSaveImportMembersList };
