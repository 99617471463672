import { Maintenance, MaintenanceCategory } from '@app/api/models/Maintenances';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React from 'react';
import { Button } from '../../../components/Buttons/Button';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import { colors } from '../../../components/Shared/Style/colors';
import DocumentsTable from '../../../components/Table/DocumentTables/DocumentsTable';
import { L } from '../../../lib/i18n';
import { DocumentDetail } from '@app/api/public/DocumentsApi';
import InputSplit from '../../../components/Forms/InputSplit';
import { QueryStatus, useQueryClient } from 'react-query';
import Spinner from '../../../components/Spinner/Spinner';
import { ServicePartner } from '@app/api/models/ServicePartners';
import { Link } from 'react-router-dom';
import Divider from '../../../components/thread/divider/Divider';
import DropDownCreatable from '../../../components/DropDownCreatable/DropDownCreatable';
import SettingsByType from './SettingsByType';
import Modal from '../../../components/Modal/Modal';
import { useModal } from '../../../components/Modal/Context/ModalContext';
import ServicePartnerForm from '../../service-partner/components/ServicePartnerForm/ServicePartnerForm';
import ServicePartnerModal from '../../service-partner/components/ServicePartnerModal/ServicePartnerModal';
import { servicePartnersQueryKeys } from '../../service-partner/hooks/servicePartnerQueries';

const Wrapper = styled.div({
    width: '100%',
    color: colors.textColor1,
    display: 'grid',
    justifyItems: 'flex-start',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    gridGap: '20px',
});

const StyledInputSplit = styled(InputSplit)({
    '& input': {
        height: '32px',
    },
});

const StyledLink = styled(Link)({
    marginRight: 'auto',
    marginLeft: '0px',
});

const GridSectionMain = styled.div({
    width: '100%',
    display: 'grid',
    justifyContent: 'flex-start',
    gridColumnGap: '20px',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateAreas: `
    "title title          category service_partner"
    "status status          type  type"
    "textarea   textarea   textarea  textarea"
    "plannedDate plannedDate plannedCost plannedCost"
    `,
});

const StyledInput = styled(Input)({
    width: '100%',
    height: '32px',
});

const Textarea = styled.textarea({
    width: '100%',
    borderRadius: '4px',
    backgroundColor: '#FCFDFE',
    border: '1px solid #F0F1F7',
    height: '150px',
    resize: 'none',
    padding: '8px',
    fontSize: '14px',
    '&:focus': {
        outline: 'none',
    },
});

const AddButton = styled(Button)({
    marginRight: 0,
    marginLeft: '1rem',
});
const ButtonWraper = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
});
const DocWrapper = styled.div({
    width: '100%',
});

const StyledLable = styled(Label)({
    '& .title': {
        marginTop: '0px',
    },
});

type Props = {
    categoryList: MaintenanceCategory[] | null | undefined;
    deleteDoc: (variables: { documentId: number; queryId: string }) => void;
    documentItem: DocumentDetail[] | undefined;
    handleChange: (value: Partial<Maintenance>) => void;
    handleRemove: () => void;
    maintenanceItem: Partial<Maintenance> | undefined;
    saveDoc: (variables: { itemId: string; name: string; file: File }) => void;
    statusList: MaintenanceCategory[] | null | undefined;
    typeList: MaintenanceCategory[] | null | undefined;
    status: QueryStatus;
    handleSave: () => void;
    servicePartners: Pick<ServicePartner, 'id' | 'name'>[] | undefined;
};

const EditMaintenanceForm = ({
    deleteDoc,
    documentItem,
    handleChange,
    handleRemove,
    maintenanceItem,
    saveDoc,
    categoryList,
    statusList,
    typeList,
    servicePartners,
    status,
    handleSave,
}: Props) => {
    const { openModal, ToggleIsModalOpen } = useModal();
    const client = useQueryClient();

    const handleCreateNewCatagory = (value: string) => {
        openModal(<SettingsByType settingsType={'category'} initialValue={value} />);
    };
    const handleCreateNewStatus = (value: string) => {
        openModal(<SettingsByType settingsType={'status'} initialValue={value} />);
    };
    const handleCreateNewType = (value: string) => {
        openModal(<SettingsByType settingsType={'type'} initialValue={value} />);
    };
    const handleCreateNewServicePartner = (value: string) => {
        openModal(
            <ServicePartnerModal onClick={(e) => e.stopPropagation()}>
                <ServicePartnerForm
                    style={{
                        gridTemplateColumns: '1fr',
                        width: '100%',
                    }}
                    initialFormData={{ name: value }}
                    submitOptios={{
                        onSuccess: () => {
                            client.invalidateQueries(servicePartnersQueryKeys.list());
                            ToggleIsModalOpen();
                        },
                    }}
                />
            </ServicePartnerModal>
        );
    };

    const categoryListLabel = categoryList?.map((item) => ({ ...item, label: item.name }));
    const typeListLabel = typeList?.map((item) => ({ ...item, label: item.name }));
    const statusListLabel = statusList?.map((item) => ({ ...item, label: item.name }));
    const servicePartnersLabel = servicePartners?.map((item) => ({ ...item, label: item.name }));
    return (
        <>
            <Modal />
            <Wrapper>
                <GridSectionMain>
                    <StyledLable title={'Titel'} style={{ gridArea: 'title' }}>
                        <StyledInput
                            value={maintenanceItem?.name || ''}
                            onChange={(e) => handleChange({ name: e.target.value })}
                        />
                    </StyledLable>
                    <StyledLable title={L('category')} style={{ gridArea: 'category' }}>
                        <DropDownCreatable
                            isClearable
                            value={categoryListLabel?.find(
                                (item) => maintenanceItem?.maintenance_category?.id === item.id
                            )}
                            onChange={(value) =>
                                handleChange({
                                    maintenance_category: value,
                                })
                            }
                            onCreateOption={handleCreateNewCatagory}
                            placeholder={`${L('type_to_create_category')}`}
                            options={categoryListLabel || []}
                        />
                    </StyledLable>
                    <StyledLable title={'Servicepartner'} style={{ gridArea: 'service_partner' }}>
                        <DropDownCreatable
                            isClearable
                            value={servicePartnersLabel?.find(
                                (item) => maintenanceItem?.service_partner_id === item.id
                            )}
                            onChange={(value) => handleChange({ service_partner_id: value?.id })}
                            onCreateOption={handleCreateNewServicePartner}
                            placeholder={`${L('type_to_create_service_partner')}`}
                            options={servicePartnersLabel || []}
                        />
                    </StyledLable>
                    <Label title={'Beskrivning'} style={{ gridArea: 'textarea' }}>
                        <Textarea
                            value={maintenanceItem?.description || ''}
                            onChange={(e) => handleChange({ description: e.target.value })}
                        />
                    </Label>

                    <Label title={'Status'} style={{ gridArea: 'status' }}>
                        <DropDownCreatable
                            isClearable
                            value={statusListLabel?.find((item) => maintenanceItem?.maintenance_status?.id === item.id)}
                            onChange={(value) => {
                                handleChange({
                                    maintenance_status: value,
                                });
                            }}
                            onCreateOption={handleCreateNewStatus}
                            placeholder={`${L('type_to_create_status')}`}
                            options={statusListLabel || []}
                        />
                    </Label>
                    <Label title={'Typ'} style={{ gridArea: 'type' }}>
                        <DropDownCreatable
                            isClearable
                            value={typeListLabel?.find((item) => maintenanceItem?.maintenance_type?.id === item.id)}
                            onChange={(value) =>
                                handleChange({
                                    maintenance_type: value,
                                })
                            }
                            onCreateOption={handleCreateNewType}
                            placeholder={`${L('type_to_create_type')}`}
                            options={typeListLabel || []}
                        />
                    </Label>
                    <Label title={'Planerad kostnad'} style={{ gridArea: 'plannedCost' }}>
                        <StyledInputSplit
                            type={'number'}
                            value={maintenanceItem?.planned_cost ? parseInt(maintenanceItem.planned_cost) : ''}
                            onChange={(e) => handleChange({ planned_cost: e.target.value })}
                            splitInput={'kr'}
                        />
                    </Label>
                    <Label style={{ gridArea: 'plannedDate' }} title={L('planned_date')}>
                        <StyledInput
                            type={'date'}
                            value={
                                maintenanceItem?.planned_date
                                    ? dayjs(maintenanceItem?.planned_date).format('YYYY-MM-DD')
                                    : ''
                            }
                            onChange={(e) => handleChange({ planned_date: e.target.value })}
                        />
                    </Label>
                </GridSectionMain>
                <div style={{ width: '100%' }}>
                    <Divider width={100} margin={'1rem'} />
                </div>
                {documentItem && (
                    <DocWrapper>
                        <DocumentsTable saveDocument={saveDoc} deleteDocument={deleteDoc} list={documentItem} />
                    </DocWrapper>
                )}

                <ButtonWraper>
                    <StyledLink to={`/maintenance/${maintenanceItem?.id}/complete`}>
                        <AddButton variant="filledPrimary" style={{ marginLeft: '0px' }}>
                            {L('complete_maintenance')}
                        </AddButton>
                    </StyledLink>

                    <Button
                        type="button"
                        style={{ marginRight: '1rem' }}
                        onClick={() => handleRemove()}
                        variant="delete"
                    >
                        {L('remove')}
                    </Button>

                    {status === 'idle' && (
                        <Button onClick={() => handleSave()} variant="filledPrimary">
                            {L('save')}
                        </Button>
                    )}
                    {status === 'loading' && (
                        <Button
                            onClick={() => handleSave()}
                            variant="filledPrimary"
                            style={{ display: 'flex', justifyContent: 'center' }}
                        >
                            <Spinner />
                        </Button>
                    )}
                    {status === 'error' && (
                        <Button onClick={() => handleSave()} variant="filledPrimary">
                            {L('error')}
                        </Button>
                    )}
                    {status === 'success' && (
                        <Button onClick={() => handleSave()} variant="filledPrimary">
                            {L('saved')}
                        </Button>
                    )}
                </ButtonWraper>
            </Wrapper>
        </>
    );
};

export default EditMaintenanceForm;
