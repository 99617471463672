import React from 'react';
import styled from '@emotion/styled';
import { Button } from '../../../Buttons/Button';
import { colors } from '../../../../areas/public-site/lib/publicColors';
import { L } from '../../../../lib/i18n';
import { PreviewThemeType } from '../../../../areas/public-site/theme/ThemePage';
import { useTheme } from 'emotion-theming';

const Wrapper = styled.form({
    width: '100%',
    marginTop: '-3rem',
    color: colors.textColor1,
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'center',
    ['@media screen and (min-width: 700px)']: {
        gridGap: '10px',
        marginTop: '2rem',
        marginBottom: '2rem',
    },
    ['@media screen and (min-width: 1024px)']: {
        gridGap: '20px',
    },
});

const GridSection = styled.div({
    width: '100%',
    display: 'grid',
    justifyContent: 'center',
    gridColumnGap: '20px',
    gridTemplateColumns: 'clamp(70%, 80%, 90%)',
    gridTemplateAreas: `
    "nameA"
    "inputA"
    "nameB"
    "inputB"
    `,
    ['@media screen and (min-width: 600px)']: {
        gridTemplateColumns: 'clamp(35%, 40%, 45%) clamp(35%, 40%, 45%)',
        gridTemplateAreas: `
        "nameA nameB"
        "inputA inputB"
        `,
    },
    ['@media screen and (min-width: 1024px)']: {
        gridTemplateColumns: 'clamp(25%, 30%, 35%) clamp(25%, 30%, 35%)',
    },
});

const MessageGridSection = styled.div({
    width: '100%',
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: 'clamp(70%, 80%, 90%)',
    gridTemplateAreas: `
    "nameA"
    "inputA"
    `,
    ['@media screen and (min-width: 600px)']: {
        gridColumnGap: '20px',
        gridTemplateColumns: 'clamp(70%, 80%, 90%)',
    },
    ['@media screen and (min-width: 1024px)']: {
        gridTemplateColumns: 'clamp(50%, 60%, 70%)',
    },
});

const Label = styled.label(({ theme }: { theme: PreviewThemeType }) => {
    return {
        color: theme.colors.textColor1,
        paddingBottom: '5px',
        fontSize: '0.8rem',
        ['@media screen and (min-width: 1024px)']: {
            fontSize: '0.8rem',
        },
    };
});

const Input = styled.input({
    marginTop: '0.2rem',
    marginBottom: '1rem',
    borderRadius: '5px',
    border: '1px solid #DFE0EB',
    padding: '10px',
    height: '30px',
    ['@media screen and (min-width: 1024px)']: { margin: 0 },
    '&:focus': {
        outline: 'none',
    },
    '::placeholder': {
        fontSize: '0.6rem',
    },
});
const MessageInput = styled.textarea({
    marginTop: '0.2rem',
    marginBottom: '1rem',
    borderRadius: '5px',
    border: '1px solid #DFE0EB',
    padding: '10px',
    gridColumn: '1 / 3',
    height: '120px',
    resize: 'none',
    ['@media screen and (min-width: 1024px)']: {
        margin: 0,
    },
    '&:focus': {
        outline: 'none',
    },
    '::placeholder': {
        fontSize: '0.6rem',
        marginTop: '0.5rem',
        marginleft: '0.5rem',
        top: 0,
        left: 0,
    },
});

const StyledButton = styled(Button)({
    ['@media screen and (max-width: 600px)']: {
        marginButtom: '2rem',
    },
});

const ContactForm = () => {
    const theme = useTheme<PreviewThemeType>();

    return (
        <>
            <Wrapper>
                <GridSection>
                    <Label theme={theme} style={{ gridArea: 'nameA' }}>
                        {L('first_name')}
                    </Label>
                    <Input
                        readOnly={true}
                        style={{ gridArea: 'inputA' }}
                        type="text"
                        placeholder="Ange förnamn"
                        name="firstName"
                    />
                    <Label theme={theme} style={{ gridArea: 'nameB' }}>
                        {L('last_name')}
                    </Label>
                    <Input
                        readOnly={true}
                        style={{ gridArea: 'inputB' }}
                        type="text"
                        placeholder="Ange efternamn"
                        name="lastName"
                    />
                </GridSection>
                <GridSection>
                    <Label theme={theme} style={{ gridArea: 'nameA' }}>
                        {L('email')}
                    </Label>
                    <Input
                        readOnly={true}
                        style={{ gridArea: 'inputA' }}
                        type="email"
                        placeholder="Ange e-postadress"
                    />
                    <Label theme={theme} style={{ gridArea: 'nameB' }}>
                        {L('telephone_number')}
                    </Label>
                    <Input
                        readOnly={true}
                        style={{ gridArea: 'inputB' }}
                        type="text"
                        placeholder="Ange telefonnummer"
                    />
                </GridSection>
                <MessageGridSection>
                    <Label theme={theme}>{L('message')}</Label>
                    <MessageInput readOnly={true} placeholder="Ange meddelande" />
                </MessageGridSection>

                <StyledButton variant="selectedMenu" type="submit">
                    {L('send')}
                </StyledButton>
            </Wrapper>
        </>
    );
};

export default ContactForm;
