import styled from '@emotion/styled';
import React, { TextareaHTMLAttributes, useEffect, useRef, useState } from 'react';
import { style } from '../../Shared/Style';

const Textarea = styled.textarea({
    lineHeight: 'normal',
    width: '100%',
    height: 'auto',
    overflowY: 'scroll',
    outline: 'none',
    resize: 'none',
    wordWrap: 'break-word',
    overflow: 'hidden',
    boxSizing: 'border-box',
    background: style.colors.inputBackground,
    border: `1px solid ${style.colors.inputBorder}`,
    borderRadius: style.roundCornerSize.small,
    padding: style.margin.s,
    fontSize: style.fontSize.seta,
    appearance: 'none',
});

const AutoGrowingTextarea = ({ onChange, ...props }: TextareaHTMLAttributes<HTMLTextAreaElement>) => {
    const [row, setRow] = useState(1);
    const textRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (!textRef.current) return;
        const textArea = textRef.current;
        const textareaLineHeight = 17;
        const minRows = 1;
        const previousRows = textArea.rows;
        textArea.rows = minRows; // reset number of rows in textarea
        const currentRows = ~~(textArea.scrollHeight / textareaLineHeight);

        if (currentRows === previousRows) {
            textArea.rows = currentRows;
        }

        textArea.scrollTop = textArea.scrollHeight;

        setRow(currentRows);
    }, [props.value, textRef.current]);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const textArea = event.target as HTMLTextAreaElement;
        const textareaLineHeight = 17;
        const minRows = 1;
        const previousRows = textArea.rows;
        textArea.rows = minRows; // reset number of rows in textarea
        const currentRows = ~~(textArea.scrollHeight / textareaLineHeight);

        if (currentRows === previousRows) {
            textArea.rows = currentRows;
        }

        textArea.scrollTop = textArea.scrollHeight;

        setRow(currentRows);
        return event;
    };

    return (
        <Textarea
            ref={textRef}
            rows={row}
            onChange={(e) => (onChange ? onChange(handleChange(e)) : handleChange(e))}
            {...props}
        />
    );
};

export default AutoGrowingTextarea;
