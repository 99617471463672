Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["listResources"] = createRpcProxy("BookingsApi", "listResources", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["listSlots"] = createRpcProxy("BookingsApi", "listSlots", {
  "urlPrefix": "/rpc"
});
o["listSlotsTypes"] = createRpcProxy("BookingsApi", "listSlotsTypes", {
  "urlPrefix": "/rpc"
});
o["listResourceItems"] = createRpcProxy("BookingsApi", "listResourceItems", {
  "urlPrefix": "/rpc"
});
o["getResource"] = createRpcProxy("BookingsApi", "getResource", {
  "urlPrefix": "/rpc"
});
o["setBookingAsDraft"] = createRpcProxy("BookingsApi", "setBookingAsDraft", {
  "urlPrefix": "/rpc"
});
o["listBookings"] = createRpcProxy("BookingsApi", "listBookings", {
  "urlPrefix": "/rpc"
});
o["listProperties"] = createRpcProxy("BookingsApi", "listProperties", {
  "urlPrefix": "/rpc"
});
o["listBookingsFromNow"] = createRpcProxy("BookingsApi", "listBookingsFromNow", {
  "urlPrefix": "/rpc"
});
o["listBookingUsers"] = createRpcProxy("BookingsApi", "listBookingUsers", {
  "urlPrefix": "/rpc"
});
o["listUserBookings"] = createRpcProxy("BookingsApi", "listUserBookings", {
  "urlPrefix": "/rpc"
});
o["listBookingDates"] = createRpcProxy("BookingsApi", "listBookingDates", {
  "urlPrefix": "/rpc"
});
o["togglePublish"] = createRpcProxy("BookingsApi", "togglePublish", {
  "urlPrefix": "/rpc"
});
o["toggleNotify"] = createRpcProxy("BookingsApi", "toggleNotify", {
  "urlPrefix": "/rpc"
});
o["saveResourece"] = createRpcProxy("BookingsApi", "saveResourece", {
  "urlPrefix": "/rpc"
});
o["saveSlot"] = createRpcProxy("BookingsApi", "saveSlot", {
  "urlPrefix": "/rpc"
});
o["saveSlots"] = createRpcProxy("BookingsApi", "saveSlots", {
  "urlPrefix": "/rpc"
});
o["saveResourceItem"] = createRpcProxy("BookingsApi", "saveResourceItem", {
  "urlPrefix": "/rpc"
});
o["removeSlot"] = createRpcProxy("BookingsApi", "removeSlot", {
  "urlPrefix": "/rpc"
});
o["removeAllSlots"] = createRpcProxy("BookingsApi", "removeAllSlots", {
  "urlPrefix": "/rpc"
});
o["removeResourceItem"] = createRpcProxy("BookingsApi", "removeResourceItem", {
  "urlPrefix": "/rpc"
});
o["doSlotRangeExist"] = createRpcProxy("BookingsApi", "doSlotRangeExist", {
  "urlPrefix": "/rpc"
});
o["updateDocumentName"] = createRpcProxy("BookingsApi", "updateDocumentName", {
  "urlPrefix": "/rpc"
});
o["uploadDocument"] = createRpcProxy("BookingsApi", "uploadDocument", {
  "urlPrefix": "/rpc"
});
o["listDocuments"] = createRpcProxy("BookingsApi", "listDocuments", {
  "urlPrefix": "/rpc"
});
o["saveBooking"] = createRpcProxy("BookingsApi", "saveBooking", {
  "urlPrefix": "/rpc"
});
o["removeBookingResource"] = createRpcProxy("BookingsApi", "removeBookingResource", {
  "urlPrefix": "/rpc"
});
o["deleteBooking"] = createRpcProxy("BookingsApi", "deleteBooking", {
  "urlPrefix": "/rpc"
});
o["bookingsCsv"] = createRpcProxy("BookingsApi", "bookingsCsv", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["setSortOrder"] = createRpcProxy("BookingsApi", "setSortOrder", {
  "urlPrefix": "/rpc"
});