import { NewFolderDetails } from '@app/api/models/Folders';
import { useMutation, useQueryClient } from 'react-query';
import DocumentsApi, { DocumentInfo, UploadDuplicateResponse, DuplicateAction } from '@app/api/public/DocumentsApi';
import toast from 'react-hot-toast';
import { fileKeys, folderKeys } from '../../queryKeys';
import { L } from '../../../../lib/i18n';

export type UploadedFolder = NewFolderDetails & {
    id: number;
    fullPath: string;
};

type UploadFilesAndFoldersProps = {
    files: DocumentInfo[];
    currentFolderId: number | null;
    duplicateAction?: DuplicateAction;
};

const useUploadFilesAndFolders = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ files, currentFolderId, duplicateAction }: UploadFilesAndFoldersProps) => {
            const res = toast.promise(
                (async () => {
                    const data = await DocumentsApi.uploadFilesAndFolders(files, currentFolderId, duplicateAction);

                    if (data?.message === L('duplicates_found') && data.status === 'fail') {
                        // toast.error(`${L(data.message ?? 'Error uploading files/folders')}`);
                        throw data;
                    }

                    return data;
                })(),
                {
                    loading: L('uploading') + '...',
                    error: L('error_uploading'),
                    success: L('done_uploading') + '!',
                }
            );

            return res;
        },
        onError: (err) => {
            if (
                (err as UploadDuplicateResponse).message === L('duplicates_found') &&
                (err as UploadDuplicateResponse).status !== 'success'
            ) {
                return;
            }

            const errorMessage = typeof err === 'string' ? err : new Error(L('reset_error')).message;
            toast.error(`${L(errorMessage)}`);
        },
        onSettled: () => {
            queryClient.invalidateQueries([folderKeys]);
            queryClient.invalidateQueries([fileKeys]);
        },
    });
};

export default useUploadFilesAndFolders;
