import React from 'react';
import useGetHelpListItems from '../hooks/useGetHelpListItems';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';

const HelpItem = styled(NavLink)({
    border: '2px solid #F0F1F7',
    padding: '40px 10px',
    margin: '5px 0px',
    color: 'black',
    textAlign: 'center',
    backgroundColor: '#FCFDFE',
    transition: '0.4s',
    borderRadius: '3px',
    textDecoration: 'none',
    '&:hover': {
        backgroundColor: '#F7F8FC',
        cursor: 'pointer',
        color: '#86AE43',
    },
    '&.active': {
        border: '1px solid green',
    },
});

const HelpItemGrid = styled.div({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridColumnGap: '20px',
    gridRowGap: '10px',
});

const HelpList = () => {
    const { data: helpListItems } = useGetHelpListItems();

    return (
        <div>
            <HelpItemGrid>
                {helpListItems &&
                    helpListItems.map((helpItem) => (
                        <HelpItem to={`/help/${helpItem.id}`} key={helpItem.id}>
                            {helpItem.title}
                        </HelpItem>
                    ))}
            </HelpItemGrid>
        </div>
    );
};

export default HelpList;
