import { useQuery } from 'react-query';
import HelpApi, { HelpItem } from '@app/api/public/HelpApi';

const getHelpListItems = async () => {
    return await HelpApi.list();
};

export const helpItems = {
    all: ['helpItems'],
    id: (id: HelpItem['id']) => [...helpItems.all, id],
    page_name: (page_name: HelpItem['page_name']) => [...helpItems.all, page_name],
};

const useGetHelpListItems = () => {
    return useQuery([...helpItems.all], getHelpListItems);
};

export default useGetHelpListItems;
