import { useQuery } from 'react-query';
import MaintenancesApi, { MaintenanceFilter } from '@app/api/public/MaintenancesApi';
import { getMaintenanceQueryKey } from './useGetMaintenance';

const getMaintenanceList = async (filter?: MaintenanceFilter | undefined) => {
    return await MaintenancesApi.list(filter);
};

const useGetMaintenanceList = (filter?: MaintenanceFilter | undefined) => {
    return useQuery([getMaintenanceQueryKey, { filter }], () => getMaintenanceList(filter), {
        keepPreviousData: true,
    });
};

export default useGetMaintenanceList;
