import React from 'react';
import HelpList from './components/HelpList';
import HelpItemPage from './Page/HelpItemPage';
import styled from '@emotion/styled';
import { Switch, Route } from 'react-router-dom';
import { L } from '../../lib/i18n';
import { colors } from '../../components/Shared/Style/colors';
import PageTitle from '../../components/typography/PageTitle';

const HelpMainContainer = styled.div({
    padding: '80px',
    backgroundColor: colors.white,
});

export const HelpPage = () => {
    return (
        <>
            <PageTitle>{L('help_header')}</PageTitle>
            <HelpMainContainer>
                <HelpList />
                <Switch>
                    <Route exact path="/help/:id">
                        <HelpItemPage />
                    </Route>
                </Switch>
            </HelpMainContainer>
        </>
    );
};
