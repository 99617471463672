import React from 'react';
import { Route } from 'react-router-dom';
import BoardEventPage from './BoardEventPage';

export const routes = [
    <Route key="1" path="/board-event/:id" component={BoardEventPage} />,
    <Route key="0" path="/board-event" component={BoardEventPage} />,
];

export const navigation = { name: 'board_content_calendar', link: '/board-event', icon: 'Calendar', parent: 'BoardMembers' };
