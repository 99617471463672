import { SaveBookingResource } from '@app/api/models/Bookings';
import BookingsApi from '@app/api/public/BookingsApi';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { L } from '../../../lib/i18n';
import { getBookingResourcesQueryKey } from './useGetBookingResources';

const useSaveNewBookingResource = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (resource: Pick<SaveBookingResource, 'name' | 'booking_resource_slot_type' | 'published'>) => {
            return BookingsApi.saveResourece(resource);
        },
        {
            onMutate: (variables) => {
                const toastId = toast.loading(`${L('saving')} ${variables.name}`);

                return toastId;
            },
            onSuccess: (_data, variables, toastId: string) => {
                toast.success(`${variables.name} ${L('saved')}`, {
                    id: toastId,
                });
            },
            onError: (_error, variables, toastId: string) => {
                toast.success(`${variables.name} ${L('saved')}`, {
                    id: toastId,
                });
            },
            onSettled: () => {
                queryClient.invalidateQueries(getBookingResourcesQueryKey.all);
            },
        }
    );
};

export default useSaveNewBookingResource;
