import BookingsApi from '@app/api/public/BookingsApi';
import { useQuery } from 'react-query';

export const bookingDocumentsListQuerykey = {
    all: ['bookingdocuments'],
    resourceId: (resourceId: number) => [...bookingDocumentsListQuerykey.all, { resourceId }],
};

const getBookingResourceDocuments = async (resourceId: number) => {
    return await BookingsApi.listDocuments(resourceId);
};

const useGetBookingResourceDocuments = (resourceId: number) => {
    return useQuery(bookingDocumentsListQuerykey.resourceId(resourceId), () => getBookingResourceDocuments(resourceId));
};

export default useGetBookingResourceDocuments;
