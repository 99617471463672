import React, { FC, useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';
import { escapeRegex } from '../../lib/regex';

const InputContainer = styled.div({
    position: 'relative',
});

const AutoCompleteList = styled.ul(({ theme }) => {
    return {
        display: 'none',
        position: 'absolute',
        width: '100%',
        overflow: 'auto',
        maxHeight: '200px',

        zIndex: 100,
        listStyle: 'none',
        padding: '0',
        margin: '0',
        background: theme.colors.inputBackground,
        border: `1px solid ${theme.colors.inputBorder}`,
        borderTop: 'none',
        borderBottomLeftRadius: style.roundCornerSize.small,
        borderBottomRightRadius: style.roundCornerSize.small,
    };
});

const AutoCompleteItem = styled.li(({ theme }) => {
    return {
        padding: '0.5rem 0.25rem',

        '&:first-of-type': {
            textShadow: '0 0 0.1px #000',
        },
        '&:hover': {
            background: theme.colors.inputHoverBackground,
        },

        '&:not(:last-of-type)': {
            borderBottom: `1px solid ${theme.colors.inputHoverBackground}`,
        },
    };
});

const InputSearchField = styled.input(({ theme }) => {
    return {
        background: theme.colors.inputBackground,
        border: `1px solid ${theme.colors.inputBorder}`,
        width: '100%',
        height: '42px',
        borderRadius: style.roundCornerSize.small,
        outline: 'none',
        padding: style.margin.s,
        fontSize: style.fontSize.seta,

        '&:focus': {
            [`& + ul`]: {
                display: 'block',
            },
        },
    };
});

type Props = {
    inputArray?: string[];
    getEventOnChange?: (e: EventTarget & HTMLInputElement) => void;
    startValue?: string;
    className?: string;
};

type InputSearchType = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const InputSearch: FC<InputSearchType & Props> = ({
    getEventOnChange,
    startValue = '',
    inputArray = [],
    name,
    ...rest
}) => {
    const [searchFieldValue, setSearchFieldValue] = useState<string>(startValue);
    const [filteredInputArray, setFilteredInputArray] = useState<string[]>(inputArray);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();

        console.log('ref', inputRef.current);
        console.log('change', e.currentTarget);
        setSearchFieldValue(e.target.value);
        getEventOnChange && getEventOnChange(e.currentTarget);
    };

    const handleClick = (item: string) => {
        if (!item) return;
        setSearchFieldValue(item);
    };
    const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key !== 'Enter') return;
        if (!filteredInputArray?.[0]) {
            setSearchFieldValue('');
            e.currentTarget.blur();
            return;
        }
        setSearchFieldValue(filteredInputArray[0]);
        e.currentTarget.blur();
    };

    useEffect(() => {
        if (!searchFieldValue) {
            setFilteredInputArray(inputArray);
        }
        if (inputRef.current) {
            getEventOnChange && getEventOnChange(inputRef.current);
        }
        // Create regexp for filter and escape searchFieldValue to prevent runtime error if user types / \ ! etc
        const searchRegex = new RegExp(`(${escapeRegex(searchFieldValue)})`, 'gmi');
        const filterInputArray = inputArray.filter((inputArrayValue) => {
            if (inputArrayValue.match(searchRegex)) return inputArrayValue;
            return false;
        });
        setFilteredInputArray(filterInputArray);
    }, [searchFieldValue]);

    useEffect(() => {
        setSearchFieldValue(startValue);
    }, [startValue]);

    return (
        <>
            <InputContainer>
                <InputSearchField
                    ref={inputRef}
                    onKeyPress={handleEnter}
                    autoComplete="off"
                    value={searchFieldValue}
                    onChange={handleChange}
                    name={name}
                    id={name}
                    type="text"
                    {...rest}
                />
                <AutoCompleteList>
                    {filteredInputArray.map((item, index) => {
                        return (
                            <AutoCompleteItem onMouseDown={() => handleClick(item)} key={index}>
                                {item}
                            </AutoCompleteItem>
                        );
                    })}
                </AutoCompleteList>
            </InputContainer>
        </>
    );
};

export default InputSearch;
