import BookingsApi from '@app/api/public/BookingsApi';
import { useQuery } from 'react-query';
export const useGetBookingsListQueryKey = 'useGetProperties';

const useGetProperties = () => {
    return useQuery([useGetBookingsListQueryKey], () => {
        return BookingsApi.listProperties();
    });
};

export default useGetProperties;
