import * as React from 'react';
import styled from '@emotion/styled';
import { style } from '../../Shared/Style';
import { colors } from '../../Shared/Style/colors';
import SortArrowUp from '../../Icon/small-triangle-up.svg';
import SortArrowDown from '../../Icon/small-triangle-down.svg';
import { ThemeType } from '@app/api/models/Theme';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    fill: '#9FA2B4',
    marginLeft: style.margin.s,
});

type SortableArrow = {
    activeSort?: 'asc' | 'desc';
};

const StyledSortArrowUp = styled(SortArrowUp)(({ sort, theme }: { sort?: string; theme?: ThemeType }) => {
    return {
        height: '12px',
        width: '12px',
        marginBottom: '-4px',
        fill: sort ? theme?.colors.black : theme?.colors.secondary,
    };
});
const StyledSortArrowDown = styled(SortArrowDown)(({ sort, theme }: { sort?: string; theme?: ThemeType }) => {
    return {
        height: '12px',
        width: '12px',
        fill: sort ? theme?.colors.black : theme?.colors.secondary,
    };
});

const SortArrows = ({ activeSort }: SortableArrow) => {
    return (
        <Container>
            <StyledSortArrowUp sort={activeSort === 'asc' ? 'asc' : undefined} />
            <StyledSortArrowDown sort={activeSort === 'desc' ? 'desc' : undefined} />
        </Container>
    );
};

export default SortArrows;
