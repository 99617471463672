import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '../../components/Icon/Icon';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';

const MenuLink = styled(NavLink)(({ theme }) => {
    return {
        display: 'flex',
        color: theme.colors.sideBarInactive,
        borderLeft: '4px solid transparent',
        alignItems: 'center',
        textDecoration: 'none',
        fontSize: '14px',
        padding: '10px 0px',
        stroke: '#AFAFAF',
        '&.active': {
            backgroundColor: theme.colors.primaryLight,
            '&.active .has-parent': {
                borderLeftColor: theme.colors.primary,
            },
            '&.active.addBorder': {
                borderLeftColor: theme.colors.primary,
            },
        },
    };
});
const InneLink = styled.div(() => {
    return {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '0.5rem',
        borderLeft: '4px solid transparent',
        fontSize: '14px',
        textDecoration: 'none',
        '&.has-parent': {
            marginLeft: '30px',
            paddingLeft: '0px',
        },
    };
});

const Text = styled.div({
    marginLeft: style.margin.m,
});

const IconWrapper = styled.div(() => {
    return {
        '&.hide': {
            display: 'none',
        },
    };
});

export type NavItem = {
    icon?: string;
    exact?: boolean;
    link: string;
    name: string;
    match?: string;
    parent?: string;
};

interface ItemProps {
    item: NavItem;
}

export const MainMenuItem = (props: ItemProps) => {
    const NavIcon = Icon[props.item.icon || ''] || Icon['Binder'];
    return (
        <MenuLink className={props.item.parent ? '' : 'addBorder'} exact={props.item.exact} to={props.item.link}>
            <InneLink className={props.item.parent ? 'has-parent' : ''}>
                <IconWrapper className={props.item.parent ? 'hide' : ''}>
                    <NavIcon />
                </IconWrapper>
                <Text>{props.item.name}</Text>
            </InneLink>
        </MenuLink>
    );
};
