import PublicSiteApi from '@app/api/public/PublicSiteApi';
import { SiteTheme } from '@app/api/models/PublicSite';
import { useMutation, useQueryClient } from 'react-query';

type Variables = { themeId: number };
export const saveTheme = async (variables: Variables) => {
    return PublicSiteApi.saveThemeSetting(variables.themeId);
};

const useSaveTheme = () => {
    const queryClient = useQueryClient();

    return useMutation((variables: Variables) => saveTheme(variables), {
        onSuccess: (data, variables: Variables) => {
            const themesArr = queryClient.getQueryData<SiteTheme[]>('getThemes');
            if (themesArr) {
                const themes = [...themesArr];
                const selectedTheme = themes.find((t) => t.id === variables.themeId);
                if (!selectedTheme) return;
                selectedTheme.selected = true;
                queryClient.setQueryData('getThemes', [...themes]);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries('getThemes');
        },
    });
};

export default useSaveTheme;
