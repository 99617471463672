import { L } from '../../../lib/i18n';
import React from 'react';
import { FileUpload } from '../../tickets/components/FileUpload/FileUpload';
import useGetImportMembersList from '../hooks/useGetImportMembers';
import TableRowSelection from './TableRowSelection';
import { createContext, useState } from 'react';
import { Row } from 'react-table';
import { COLUMNS } from './Columns';
import { Button } from '../../../components/Buttons/Button';
import styled from '@emotion/styled';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { margin } from '../../../components/Shared/Style/margin';

type MembersContextType = {
    upload: boolean;
    setUpload: React.Dispatch<React.SetStateAction<boolean>>;
};

const UploadSelectedButton = styled(Button)({
    placeSelf: 'end',
});
const StyledFileUpload = styled(FileUpload)({
    placeSelf: 'end',
});
const FlexContainer = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: margin.m,
});
const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const StyledLink = styled.a({
    marginLeft: margin.xs,
    marginRight: margin.xs,
    textDecoration: 'underline',
});

export const MembersContext = createContext({} as MembersContextType);

export const MemberImportPage = () => {
    const { mutate: mutateImport, data: dataImport } = useGetImportMembersList();
    const [selected, setSelected] = useState<Row<object>[]>([]);
    const [upload, setUpload] = useState(false);

    return (
        <MembersContext.Provider
            value={{
                upload,
                setUpload,
            }}
        >
            <CardWithDivider
                topArea={
                    <Wrapper>
                        <CardTitle>
                            {L('settings_description_pt1')}
                            <StyledLink href="https://s3.eu-west-1.amazonaws.com/public.ourliving.se/ourliving/importmall_medlemmar.xlsx">
                                {L('import_template')}
                            </StyledLink>
                            {L('settings_description_pt2')}
                        </CardTitle>
                        <div style={{ display: 'flex' }}>
                            <StyledFileUpload custom={'yes'} onFilesSelected={(file) => mutateImport(file[0])}>
                                {L('import_members')}
                            </StyledFileUpload>
                        </div>
                    </Wrapper>
                }
                mainArea={
                    <>
                        {dataImport && (
                            <>
                                <FlexContainer>
                                    <UploadSelectedButton
                                        variant="selectedMenu"
                                        onClick={() => {
                                            setUpload(true);
                                        }}
                                        isLoading={upload}
                                    >
                                        {L('upload_selected_members')} {selected.length} :st
                                    </UploadSelectedButton>
                                </FlexContainer>
                                <TableRowSelection
                                    columns={COLUMNS}
                                    data={dataImport}
                                    getSelectedFlatRows={(selectedFlatRows) => setSelected(selectedFlatRows)}
                                />
                            </>
                        )}
                    </>
                }
            />
        </MembersContext.Provider>
    );
};
