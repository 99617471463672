Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["list"] = createRpcProxy("ContentEventApi", "list", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["get"] = createRpcProxy("ContentEventApi", "get", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["listDocumentGroups"] = createRpcProxy("ContentEventApi", "listDocumentGroups", {
  "urlPrefix": "/rpc"
});
o["saveDocuementGroup"] = createRpcProxy("ContentEventApi", "saveDocuementGroup", {
  "urlPrefix": "/rpc"
});
o["listDocuments"] = createRpcProxy("ContentEventApi", "listDocuments", {
  "urlPrefix": "/rpc"
});
o["saveDocument"] = createRpcProxy("ContentEventApi", "saveDocument", {
  "urlPrefix": "/rpc"
});
o["deleteDocumentFromContentEvent"] = createRpcProxy("ContentEventApi", "deleteDocumentFromContentEvent", {
  "urlPrefix": "/rpc"
});
o["remove"] = createRpcProxy("ContentEventApi", "remove", {
  "urlPrefix": "/rpc"
});
o["listParticipants"] = createRpcProxy("ContentEventApi", "listParticipants", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["updateDocumentName"] = createRpcProxy("ContentEventApi", "updateDocumentName", {
  "urlPrefix": "/rpc"
});
o["resendNotifications"] = createRpcProxy("ContentEventApi", "resendNotifications", {
  "urlPrefix": "/rpc"
});
o["editPublishedEvent"] = createRpcProxy("ContentEventApi", "editPublishedEvent", {
  "urlPrefix": "/rpc"
});
o["createDraft"] = createRpcProxy("ContentEventApi", "createDraft", {
  "urlPrefix": "/rpc"
});
o["saveDraft"] = createRpcProxy("ContentEventApi", "saveDraft", {
  "urlPrefix": "/rpc"
});
o["createEventAndPublish"] = createRpcProxy("ContentEventApi", "createEventAndPublish", {
  "urlPrefix": "/rpc"
});
o["saveAndPublish"] = createRpcProxy("ContentEventApi", "saveAndPublish", {
  "urlPrefix": "/rpc"
});
o["publishEvent"] = createRpcProxy("ContentEventApi", "publishEvent", {
  "urlPrefix": "/rpc"
});
o["setEventAsDraft"] = createRpcProxy("ContentEventApi", "setEventAsDraft", {
  "urlPrefix": "/rpc"
});
o["toggleEventSignup"] = createRpcProxy("ContentEventApi", "toggleEventSignup", {
  "urlPrefix": "/rpc"
});
o["disableSignup"] = createRpcProxy("ContentEventApi", "disableSignup", {
  "urlPrefix": "/rpc"
});
o["removeCoverImage"] = createRpcProxy("ContentEventApi", "removeCoverImage", {
  "urlPrefix": "/rpc"
});