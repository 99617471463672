import ContactsApi from '@app/api/public/ContactsApi';
import { useQuery } from 'react-query';

export const getContactKeys = {
    all: [{ scope: 'contact' }] as const,
    lists: () => [{ ...getContactKeys.all[0], entity: 'list' }] as const,
    list: (search: string | undefined) => [{ ...getContactKeys.lists()[0], search }] as const,
};

export function useGetContacts(search: string | undefined) {
    return useQuery(getContactKeys.list(search), () => ContactsApi.getListWithMails(search), {
        keepPreviousData: true,
    });
}
