import { ThemeType } from '@app/api/models/Theme';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import React, { ComponentProps, ComponentPropsWithoutRef, forwardRef } from 'react';
import ReactQuill from 'react-quill';
import { style } from '../Shared/Style';

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        ['clean'],
    ],
};

const StyledQuill = styled(ReactQuill)(({ themes }: { themes: ThemeType }) => ({
    '.ql-editor': {
        backgroundColor: themes.colors.inputBackground,
        maxHeight: '30vh',
        overflow: 'auto',
    },
    '.ql-blank.ql-blank': {
        backgroundColor: themes.colors.inputBackground,
    },
    '.ql-editor p': {
        margin: '13px 0',
    },
    '.ql-editor p:first-of-type': {
        marginTop: '0',
    },
}));

const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'link', 'image'];

const Quill = forwardRef<ComponentProps<typeof ReactQuill>, ComponentPropsWithoutRef<typeof ReactQuill>>(
    ({ ...rest }, ref) => {
        const themes = useTheme<ThemeType>();
        return (
            <StyledQuill
                ref={ref}
                themes={themes}
                modules={modules}
                formats={formats}
                placeholder="Skriv något här..."
                {...rest}
            />
        );
    }
);

export default Quill;
