import React, { useState } from 'react';
import { L } from '../../../../lib/i18n';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle, { CardSpan } from '../../../../components/typography/CardTitle';
import { useLocalStorage } from 'react-use';
import { useGetContentInformationList } from '../hooks/memberInformationQueries';
import { escapeRegex } from '../../../../lib/regex';
import {
    CardGrid,
    Card,
    CardImage,
    CardTimestamp,
    CardGridTitle,
    CardDescription,
} from '../../../../components/CardGrid/CardGrid';
import useGetSignedUrlById from '../../../../hooks/useGetSignedUrlById';
import { medium } from '../../../../lib/imageSize';
import { Link } from 'react-router-dom';
import { Button } from '../../../../components/Buttons/Button';
import GlobalFilter from '../../../../components/Table/filterComponents/GlobalFilter';
import ToggleVIew from '../../../../components/ToggleView/ToggleVIew';
import styled from '@emotion/styled';
import { convert } from 'html-to-text';
import InformationItemList from './InformationItemList';
import EmptyState from '../../../../components/EmptyState/EmptyState';
import { CardGridImage } from '../../../../components/CardGrid/CardImage';

const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '24px',
    flexDirection: 'row',
    gap: '8px',
});

const InformationPublishedListPage = () => {
    const [view, setView] = useLocalStorage<'list' | 'card'>('informationView', 'list');
    const { data: informationList, status: informationStatus } = useGetContentInformationList('published');
    const [filter, setFilter] = useState('');

    if (informationStatus === 'loading') return <CardTitle>{L(`all_information_published`)}</CardTitle>;

    if (!informationList) return <div>{L('error')} kunde inte hämta nyhetslistan</div>;

    const searchRegexp = new RegExp(`(${escapeRegex(filter)})`, 'gmi');

    const filteredList = informationList.filter((item) => {
        if (!filter) return true;
        const title = item.title.match(searchRegexp);
        const description = item.description.match(searchRegexp);
        return title || description;
    });

    const InformationList =
        view === 'list' ? (
            !filteredList.length ? (
                <EmptyState>
                    <EmptyState.Title>
                        {L('no_search_title')} &quot;{filter}&quot;
                    </EmptyState.Title>
                    <EmptyState.Description>{L('no_search_description')}</EmptyState.Description>
                </EmptyState>
            ) : (
                <InformationItemList isDragDisabled={!!filter} informationList={filteredList} />
            )
        ) : !filteredList.length ? (
            <EmptyState>
                <EmptyState.Title>
                    {L('no_search_title')} &quot;{filter}&quot;
                </EmptyState.Title>
                <EmptyState.Description>{L('no_search_description')}</EmptyState.Description>
            </EmptyState>
        ) : (
            <CardGrid>
                {filteredList.map((item) => (
                    <Link key={item.id} to={`/information/${item.id}`}>
                        <Card>
                            <CardGridImage originalId={item.cover_image_data?.original?.id || ''} />
                            <CardTimestamp date={item.published_at} />
                            <CardGridTitle>{item.title}</CardGridTitle>
                            <CardDescription>{convert(item.description)}</CardDescription>
                        </Card>
                    </Link>
                ))}
            </CardGrid>
        );

    return (
        <CardWithDivider
            topArea={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <CardTitle>
                        {L(`all_information_published`)} <CardSpan>({informationList.length})</CardSpan>
                    </CardTitle>
                    <Link to={`/information/new`}>
                        <Button>{L('information_content')}</Button>
                    </Link>
                </div>
            }
            mainArea={
                <>
                    {!informationList.length ? (
                        <EmptyState>
                            <EmptyState.Title>{L('no_information_title')}</EmptyState.Title>
                            <EmptyState.Description>{L('no_information_description')}</EmptyState.Description>
                            <Link to={`/information/new`}>
                                <EmptyState.Button variant="filledPrimary">
                                    {L('information_content')}
                                </EmptyState.Button>
                            </Link>
                        </EmptyState>
                    ) : (
                        <>
                            <Wrapper>
                                <GlobalFilter placeholder="Sök" filter={filter} setFilter={setFilter} />
                                <ToggleVIew view={view || 'list'} setView={setView} />
                            </Wrapper>
                            {InformationList}
                        </>
                    )}
                </>
            }
        />
    );
};

export default InformationPublishedListPage;
