import { Maintenance } from '@app/api/public/MaintenancesApi';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React from 'react';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import { Card } from '../../../components/Layout/Card';
import { style } from '../../../components/Shared/Style';

type Props = {
    maintenanceItem: Partial<Maintenance> | undefined;
};

const StyledCard = styled(Card)({
    display: 'grid',
    gap: '2rem',
    padding: '1rem',
    backgroundColor: style.colors.white,
    alignContent: 'start',
    alignSelf: 'start',
});

const StyledLable = styled(Label)({
    '& .title': {
        marginTop: '0px',
    },
});

const MaintenanceStatusArea = ({ maintenanceItem }: Props) => {
    return (
        <StyledCard>
            <StyledLable title={'Skapad av'}>
                <Input readOnly={true} value={maintenanceItem?.created_by_name || ''} />
            </StyledLable>
            <Label title={'Senast ändrad av'}>
                <Input readOnly={true} value={maintenanceItem?.updated_by_name || ''} />
            </Label>
            <Label title={'Skapad den'}>
                <Input readOnly={true} value={dayjs(maintenanceItem?.created_at).format('YYYY-MM-DD') || ''} />
            </Label>
            <Label title={'Senast ändrad den'}>
                <Input readOnly={true} value={dayjs(maintenanceItem?.updated_at).format('YYYY-MM-DD HH:mm:ss') || ''} />
            </Label>
        </StyledCard>
    );
};

export default MaintenanceStatusArea;
