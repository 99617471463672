import styled from '@emotion/styled';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import React, { createContext, useContext } from 'react';
import { style } from '../Shared/Style';
import ArrowDown from '../Icon/arrow-down-no-tail.svg';
import ClipLoader from 'react-spinners/ClipLoader';

type Variant =
    | 'primary'
    | 'secondary'
    | 'menu'
    | 'filledPrimary'
    | 'selectedMenu'
    | 'remove'
    | 'delete'
    | 'removeConfirm';

const StyledButton = styled.div(({ theme }) => ({
    padding: '0px',
    backgroundColor: 'inherit',
    color: theme.colors.primary,
    border: `1px solid ${theme.colors.primary}`,
    fontWeight: style.fontWeight.bold,
    minHeight: '35px',
    borderRadius: style.roundCornerSize.small,
    width: 'max-content',
    cursor: 'pointer',
    fontSize: '14px',
    position: 'relative',

    '&.primary': {
        color: theme.colors.primary,

        border: `1px solid ${theme.colors.primary}`,
        '&:hover': {
            boxShadow: `0px 0px 0px 1px ${theme.colors.primary}`,
        },
        '&.disabled, &.disabled &:hover': {
            boxShadow: 'none',
            cursor: 'default',
        },
    },
    '&.secondary': {
        color: theme.colors.secondary,

        border: `1px solid ${theme.colors.secondary}`,

        '&:hover': {
            boxShadow: `0px 0px 0px 1px ${theme.colors.secondary}`,
        },
        '&.disabled, &.disabled &:hover': {
            boxShadow: 'none',
            cursor: 'default',
        },
    },
    '&.menu': {
        color: theme.colors.primary,
        '&.disabled, &.disabled:hover': {
            color: theme.colors.black80,
            backgroundColor: 'inherit',
        },
    },
    '&.filledPrimary': {
        backgroundColor: theme.colors.primary,
        color: theme.colors.white,
        '&:hover, &:active': { backgroundColor: theme.colors.primary50, border: `1px solid ${theme.colors.primary50}` },
    },
    '&.selectedMenu': {
        backgroundColor: theme.colors.primary,
        color: theme.colors.white,
        '&:hover, &:active': { backgroundColor: theme.colors.primary50, border: `1px solid ${theme.colors.primary50}` },
    },
    '&.remove': {
        backgroundColor: theme.colors.black80,
        border: `1px solid ${theme.colors.black80}`,
        color: theme.colors.black,
        '&:hover, &:active': { backgroundColor: theme.colors.black50, border: `1px solid ${theme.colors.black50}` },
    },
    '&.delete': {
        backgroundColor: theme.colors.deleteButton,
        border: `1px solid ${theme.colors.deleteButton}`,
        color: 'white',
        '&:hover, &:active': {
            backgroundColor: theme.colors.deleteButtonHover,
            border: `1px solid ${theme.colors.deleteButtonHover}`,
        },
    },
    '&.removeConfirm': {
        backgroundColor: theme.colors.black80,
        border: `1px solid ${theme.colors.black80}`,
        color: 'white',
        '&:hover, &:active': { backgroundColor: theme.colors.black50, border: `1px solid ${theme.colors.black50}` },
    },

    '&.disabled, &.disabled:hover': {
        color: theme.colors.black80,
        border: '1px solid',
        backgroundColor: 'inherit',
        borderColor: theme.colors.black80,
        cursor: 'default',
    },
}));

const StyledItem = styled(DropdownMenu.Item)(({ theme }) => ({
    '&:focus-visible': {
        outline: `${theme.colors.primary50} auto 1px`,
    },
    '&:hover': {
        outline: `${theme.colors.primary50} auto 1px`,
    },
}));

const ContextItem = styled.li(() => ({
    padding: '1rem 2rem 1rem 1rem ',
    display: 'flex',
    fontSize: '1rem',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    gap: '1rem',
    cursor: 'pointer',
}));

const DropDownContent = styled(DropdownMenu.Content)(({ theme }) => ({
    padding: '0.5rem',
    backgroundColor: theme.colors.white,
    color: theme.colors.textColor2,
    borderRadius: style.roundCornerSize.small,
    boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.1)',
    zIndex: 10000,
}));

const StyledTriggerButton = styled(DropdownMenu.Trigger)(({ theme }) => ({
    backgroundColor: 'inherit',
    border: 'none',
    display: 'grid',
    placeItems: 'center',
    minWidth: '24px',
    minHeight: '35px',
    padding: '0px',
    borderLeft: `1px solid ${theme.colors.primary}`,
    borderRadius: `0px ${style.roundCornerSize.small}px ${style.roundCornerSize.small}px 0px`,
    cursor: 'pointer',
    outline: theme.colors.primary50,
    '&.secondary': {
        borderLeft: `1px solid ${theme.colors.secondary}`,
        '& .arrow-down': {
            path: {
                stroke: theme.colors.secondary,
            },
        },
    },
    '&.filledPrimary': {
        borderLeft: `1px solid ${theme.colors.white}`,
        '& .arrow-down': {
            path: {
                stroke: theme.colors.white,
            },
        },
    },

    '&.selectedMenu': {
        borderLeft: `1px solid ${theme.colors.white}`,
        '& .arrow-down': {
            path: {
                stroke: theme.colors.white,
            },
        },
    },
    '&.remove': {
        borderLeft: `1px solid ${theme.colors.black}`,
        '& .arrow-down': {
            path: {
                stroke: theme.colors.black,
            },
        },
    },
    '&.removeConfirm': {
        borderLeft: `1px solid ${theme.colors.white}`,
        '& .arrow-down': {
            path: {
                stroke: theme.colors.white,
            },
        },
    },
    '&.delete': {
        borderLeft: `1px solid ${theme.colors.white}`,
        '& .arrow-down': {
            path: {
                stroke: theme.colors.white,
            },
        },
    },

    '&:disabled, &:disabled:hover': {
        borderLeft: `1px solid ${theme.colors.black80}`,
        '& .arrow-down': {
            path: {
                stroke: theme.colors.black80,
            },
        },
    },
}));

const Wrapper = styled.div(({ theme }) => ({
    padding: '6px 6px',
}));

const ArrowDownIcon = styled(ArrowDown)(({ theme }) => ({
    width: '12px',
    height: '12px',
    path: {
        stroke: theme.colors.primary,
    },
}));

const DropdownMenuArrow = styled(DropdownMenu.Arrow)(({ theme }) => ({
    fill: theme.colors.white,
}));
const ButtonContent = styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));
const ButtonContext = createContext<{
    variant: Variant;
    disabled?: boolean;
    isLoading?: boolean;
} | null>(null);

const useButtonContext = () => {
    const context = useContext(ButtonContext);
    if (!context) {
        throw new Error('DropdownButton.Button must be used within DropdownButton');
    }
    return context;
};
const Button = function ({ children, className, ...rest }: React.ComponentProps<typeof StyledButton>) {
    const { variant, disabled, isLoading } = useButtonContext();
    const classNameVariant = ` ${variant} ${disabled ? 'disabled' : ''}`;

    if (isLoading) {
        return (
            <StyledButton role={'button'} {...rest} onClick={undefined} className={className + ' disabled'}>
                <div
                    style={{
                        display: 'grid',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        placeItems: 'center',
                    }}
                >
                    <ClipLoader size={16} speedMultiplier={0.8} color={style.colors.black80} />
                </div>
                <ButtonContent>
                    <Wrapper style={{ visibility: 'hidden' }}>{children}</Wrapper>

                    <StyledTriggerButton className={classNameVariant} disabled={true}>
                        <ArrowDownIcon className="arrow-down" viewBox="0 0 24 24" />
                    </StyledTriggerButton>
                </ButtonContent>
            </StyledButton>
        );
    }

    return (
        <StyledButton role={'button'} {...rest} className={className + classNameVariant}>
            <ButtonContent>
                <Wrapper>{children}</Wrapper>

                <StyledTriggerButton className={classNameVariant} disabled={disabled}>
                    <ArrowDownIcon className="arrow-down" viewBox="0 0 24 24" />
                </StyledTriggerButton>
            </ButtonContent>
        </StyledButton>
    );
};

type DropdownButtonProps<TKey extends React.Key> = {
    variant?: Variant;
    disabled?: boolean;
    isLoading?: boolean;
    optionId: TKey;
    setOptionId: (value: TKey) => void;
    options: {
        id: TKey;
        dropdownItem: React.ReactNode;
        button: React.ReactNode;
    }[];
} & Omit<React.ComponentProps<typeof Button>, 'children'>;

const DropdownButton = function <TKey extends React.Key>({
    variant = 'primary',
    disabled,
    optionId,
    setOptionId,
    isLoading,
    options,
}: DropdownButtonProps<TKey>) {
    const selectedOption = options.find((option) => option.id === optionId);
    const button = selectedOption ? selectedOption.button : options?.[0].button;
    return (
        <DropdownMenu.Root>
            <ButtonContext.Provider
                value={{
                    variant,
                    disabled,
                    isLoading,
                }}
            >
                {button}
            </ButtonContext.Provider>

            <DropdownMenu.Portal>
                <DropDownContent style={{ zIndex: 9999 }}>
                    {options.map((option) => {
                        return (
                            <StyledItem
                                key={option.id}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setOptionId(option.id);
                                }}
                            >
                                {option.dropdownItem}
                            </StyledItem>
                        );
                    })}

                    <DropdownMenuArrow />
                </DropDownContent>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
};

const DropdownItem = function ({ children, ...rest }: React.ComponentProps<typeof ContextItem>) {
    return <ContextItem {...rest}>{children}</ContextItem>;
};
DropdownButton.DropdownItem = DropdownItem;
DropdownButton.Button = Button;

export default DropdownButton;
