import { L } from '../../../lib/i18n';
import * as React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import BigNavItem from '../../../components/BigTabItem/BigTabListItemNav';
import { ContactListPage } from '../ListPage/ContactListPage';
import { ContactGeneralPage } from '../DetailPage/ContactGeneralPage';
import PageTitle from '../../../components/typography/PageTitle';
import BigTabNav from '../../../components/BigTabItem/BigTabNav';
import { useGetContactById } from '../hooks/useGetContacts';

const ContactNav = () => {
    const { id } = useParams<{ id: string }>();

    const { data: contact } = useGetContactById(id);
    return (
        <>
            <div style={{ borderRight: 'solid 1px gray' }} />
            <BigNavItem exact to={`/contacts/${id}`}>
                {contact?.name || contact?.email}
            </BigNavItem>
        </>
    );
};

export const ContactsPage = () => {
    return (
        <>
            <PageTitle>{L('contacts')}</PageTitle>
            <BigTabNav>
                <BigNavItem exact to={`/contacts`}>
                    {L('all_contacts')}
                </BigNavItem>
                <Switch>
                    <Route exact path={'/contacts/new'} />

                    <Route exact path={'/contacts/:id'}>
                        <ContactNav />
                    </Route>
                </Switch>
            </BigTabNav>
            <Switch>
                <Route exact path={'/contacts'}>
                    <ContactListPage />
                </Route>
                <Route exact path={'/contacts/new'}>
                    <ContactListPage />
                </Route>

                <Route exact path={'/contacts/:id'}>
                    <ContactGeneralPage />
                </Route>
            </Switch>
        </>
    );
};
