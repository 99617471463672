import styled from '@emotion/styled';
import React from 'react';
import { desktop } from '../../../components/MediaQuery/breakpoints';
import Table from '../../../components/Table/ReactTable/FilterTable';
import { COLUMNS, COLUMNSMOBILE } from './Columns';

const MobileView = styled.div({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});

const DesktopView = styled.div({
    display: 'none',
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'block',
    },
});

const TableQuery = ({ data }) => {
    return (
        <>
            <DesktopView>
                <Table columns={COLUMNS} data={data} />
            </DesktopView>
            <MobileView>
                <Table columns={COLUMNSMOBILE} data={data} />
            </MobileView>
        </>
    );
};

export default TableQuery;
