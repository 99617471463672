import styled from '@emotion/styled';

export default styled.dialog(({ theme }) => ({
    padding: '22px 32px 22px 32px',
    backgroundColor: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.divider}`,
    boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.1)',
    maxWidth: '600px',
    width: '100%',
}));
