import { useQuery } from 'react-query';
import PublicSiteApi from '@app/api/public/PublicSiteApi';

export const getQueryPageListItems = {
    all: ['getQueryPageListItems'],
    id: (id?: string) => [...getQueryPageListItems.all, { id }],
};

const getPageListItems = async () => {
    return await PublicSiteApi.list();
};

const useGetPageListItems = () => {
    return useQuery(getQueryPageListItems.all, getPageListItems);
};

export default useGetPageListItems;
