import { Membership } from '@app/api/models/Members/MembersModels';
import BoardMembersApi from '@app/api/public/BoardMembersApi';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { L } from '../../../lib/i18n';
import { getBoardMemberListQueryKey } from './useGetBoardMembers';

const inviteBoardMember = async (membershipId: Membership['id']) => {
    return await BoardMembersApi.invite(membershipId);
};

const useInviteBoardMember = () => {
    const queryClient = useQueryClient();
    return useMutation((membershipId: Membership['id']) => inviteBoardMember(membershipId), {
        onError: (err) => {
            if (err instanceof Error) {
                toast.error(`${L(err.message)}`);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries([getBoardMemberListQueryKey]);
        },
    });
};

export default useInviteBoardMember;
