import { ContentNewsItem } from '@app/api/models/ContentNews/ContentNewsModels';
import BoardContentNewsApi from '@app/api/public/BoardContentNewsApi';
import { useMutation, useQueryClient } from 'react-query';
import { boardNewsQueryKeys } from './boardNewsQueries';

type Variables = { id: ContentNewsItem['id'] };

const useRemoveNews = () => {
    const client = useQueryClient();
    return useMutation(
        (varables: Variables) => {
            return BoardContentNewsApi.remove(varables.id);
        },
        {
            onSettled: () => {
                client.invalidateQueries(boardNewsQueryKeys.all);
            },
        }
    );
};

export default useRemoveNews;
