import TicketsApi from '@app/api/public/TicketsApi';
import { useMutation, useQueryClient } from 'react-query';
import { ticketQueryKeys } from './ticketQueries';

type ArchiveTicket = { id: number };
const useArchiveTicket = () => {
    const client = useQueryClient();
    return useMutation((variables: ArchiveTicket) => TicketsApi.archiveTicket(variables.id), {
        onSettled: (response, error, varables) => {
            if (varables.id) {
                client.invalidateQueries(ticketQueryKeys.id(varables.id.toString()));
            }
            client.invalidateQueries(ticketQueryKeys.list());
        },
    });
};

export default useArchiveTicket;
