import * as React from 'react';
import { L } from '../../../lib/i18n';
import styled from '@emotion/styled';
import { style } from '../../../components/Shared/Style';
import { Loading } from '../../../components/Loading/Loading';
import { Button } from '../../../components/Buttons/Button';
import useGetBookingResources, { getBookingResourcesQueryKey } from '../hooks/useGetBookingResources';
import { useState } from 'react';
import Input from '../../../components/Forms/Input';
import useSaveNewBookingResource from '../hooks/useSaveNewBookingResource';
import { colors } from '../../../components/Shared/Style/colors';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle, { CardSpan } from '../../../components/typography/CardTitle';
import BookingCard from './BookingCard';
import { useModal } from '../../../components/Modal/Context/ModalContext';
import Modal from '../../../components/Modal/Modal';
import Label from '../../../components/Forms/Label';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import H2 from '../../../components/typography/H2';
import { Icon } from '../../../components/Icon/Icon';
import { useLocalStorage } from 'react-use';
import { Link, Route, Switch } from 'react-router-dom';
import Feed from '../../../components/Feed/Feed';
import GlobalFilter from '../../../components/Table/filterComponents/GlobalFilter';
import ToggleVIew from '../../../components/ToggleView/ToggleVIew';
import { escapeRegex } from '../../../lib/regex';
import EmptyState from '../../../components/EmptyState/EmptyState';
import { tableHeader, title, tooltip } from '../../../components/Shared/Style/typography';
import { formatDateIsoDayjs } from '@app/shared/lib/formatting';
import { Pagination } from '../../notifications/components/Pagination';
import useQueryParams from '../../../hooks/useQueryParams';
import BookingsApi from '@app/api/public/BookingsApi';
import { BookingResource } from '@app/api/models/Bookings';
import {
    Card,
    CardDescription,
    CardGrid,
    CardGridTitle,
    CardImage,
    CardTimestamp,
} from '../../../components/CardGrid/CardGrid';
import useGetSignedUrlById from '../../../hooks/useGetSignedUrlById';
import { medium } from '../../../lib/imageSize';
import { convert } from 'html-to-text';
import Clock from '../../../components/Icon/clock.svg';
import CalendarDay from '../../../components/Icon/calendar-day.svg';
import BookingItemList from './BookingItemList';
import { string } from 'zod';
import useGetBookingsByResourceId from '../hooks/useGetBookingsByResourceId';
import InvisibleLinkWrapper from '../../../components/Link/InvisibleLinkWrapper';
import NewBookingsResourceForm from '../Components/NewBookingsResourceForm';
import BookingItemDraftList from './BookingItemDraftList';
import { CardGridImage } from '../../../components/CardGrid/CardImage';

//#region css
const templateColumns = '1fr 0.4fr 0.4fr' as const;

const Headers = styled.div(() => ({
    margin: '0px',
    display: 'grid',
    gridTemplateColumns: templateColumns,
    columnGap: '2rem',
    alignItems: 'baseline',
    padding: '0px 0.5rem',
}));

const ListTitle = styled.span({
    ...title,
});

const ListTimeStamp = styled.span({
    ...tooltip,
});

const HeaderText = styled.span({
    ...tableHeader,
});

const StyledCard = styled.div({
    backgroundColor: colors.white,
    padding: '2rem',
    borderRadius: style.roundCornerSize.medium,
    justifySelf: 'center',
    alignSelf: 'center',
    minWidth: '400px',
});

const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '24px',
    flexDirection: 'row',
    gap: '8px',
});

const FeedListItem = styled(Link)({
    borderBottom: `1px solid ${style.colors.divider}`,
    padding: '1rem 0.5rem',
    display: 'grid',
    gap: '2rem',
    alignItems: 'center',
    gridTemplateColumns: templateColumns,
    '&:first-of-type': {
        borderTop: `1px solid ${style.colors.divider}`,
    },
});

const StyledFeed = styled(Feed)({
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fill, minmax(300px, 1fr) )',
    gap: '2rem',
});

const FlexWrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
});

const ValueWrapper = styled.div({
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
});

const SubmtButtonWrapper = styled.div({
    display: 'flex',
    justifyContent: 'end',
    marginTop: '2rem',
});

const StyledClock = styled(Clock)({
    height: '21px',
    width: '21px',
});
const StyledCalendarDay = styled(CalendarDay)({
    height: '21px',
    width: '21px',
});

const StyledCardDescription = styled(CardDescription)({
    height: '8rem',
    marginBottom: '1.5rem',
    overflow: 'hidden',
});

const SvgWrapper = styled.div({
    marginTop: '1rem',
    gap: '1rem',
    alignItems: 'center',
    display: 'flex',
});

//#endregion

export const BookingDraftListPage = () => {
    const [view, setView] = useLocalStorage<'list' | 'card'>('bookingsView', 'list');
    const [filter, setFilter] = useState('');
    const bookingResource = useGetBookingResources('draft');

    if (bookingResource.status === 'success') {
        const searchRegexp = new RegExp(`(${escapeRegex(filter)})`, 'gmi');

        const filteredList = bookingResource.data.filter((item) => {
            if (!filter) return true;
            const title = item.name.match(searchRegexp);
            const type = L(item.booking_resource_slot_type.name).match(searchRegexp);
            return title || type;
        });

        const BookingsList =
            view === 'list' ? (
                !filteredList.length ? (
                    <EmptyState>
                        <EmptyState.Title>
                            {L('no_search_title')} &quot;{filter}&quot;
                        </EmptyState.Title>
                        <EmptyState.Description>{L('no_search_description')}</EmptyState.Description>
                    </EmptyState>
                ) : (
                    <BookingItemDraftList isDragDisabled={!!filter} bookingList={filteredList} />
                )
            ) : !filteredList.length ? (
                <EmptyState>
                    <EmptyState.Title>
                        {L('no_search_title')} &quot;{filter}&quot;
                    </EmptyState.Title>
                    <EmptyState.Description>{L('no_search_description')}</EmptyState.Description>
                </EmptyState>
            ) : (
                <CardGrid>
                    {filteredList.map((item) => {
                        return (
                            <Link key={item.id} to={`/booking/${item.id}`}>
                                <Card>
                                    <CardGridImage originalId={item.image_data?.id || ''} />
                                    <CardGridTitle>
                                        <SvgWrapper>
                                            {item.booking_resource_slot_type.name === 'slot' ? (
                                                <StyledClock />
                                            ) : (
                                                <StyledCalendarDay />
                                            )}
                                            {item.name}
                                        </SvgWrapper>
                                    </CardGridTitle>
                                    <StyledCardDescription>{convert(item.short_description)}</StyledCardDescription>
                                    <CardDescription style={{ fontStyle: 'italic' }}>
                                        {item.bookings_from_now} {L('incoming_bookings')}
                                    </CardDescription>
                                </Card>
                            </Link>
                        );
                    })}
                </CardGrid>
            );

        return (
            <>
                <CardWithDivider
                    topArea={
                        <FlexWrapper>
                            <CardTitle>
                                {L('all_bookings_draft')} <CardSpan>({bookingResource.data.length})</CardSpan>
                            </CardTitle>
                            <InvisibleLinkWrapper to={'/booking/draft/new'}>
                                <Button>{L('new_booking_resource')}</Button>
                            </InvisibleLinkWrapper>
                            <Switch>
                                <Route path={'/booking/draft/new'}>
                                    <NewBookingsResourceForm type="draft" />
                                </Route>
                            </Switch>
                        </FlexWrapper>
                    }
                    mainArea={
                        <>
                            {!bookingResource.data.length ? (
                                <EmptyState>
                                    <EmptyState.Title>{L('no_booking_draft_title')}</EmptyState.Title>
                                    <EmptyState.Description>{L('no_booking_draft_description')}</EmptyState.Description>
                                    <Link to={`/booking`}>
                                        <EmptyState.Button variant="filledPrimary">
                                            {L('no_bookings_draft_button')}
                                        </EmptyState.Button>
                                    </Link>
                                </EmptyState>
                            ) : (
                                <>
                                    <Wrapper>
                                        <GlobalFilter placeholder="Sök" filter={filter} setFilter={setFilter} />
                                        <ToggleVIew view={view || 'list'} setView={setView} />
                                    </Wrapper>
                                    {BookingsList}
                                </>
                            )}
                        </>
                    }
                />
            </>
        );
    }

    if (bookingResource.status === 'loading') return <Loading />;

    if (bookingResource.status === 'error') return <div>Kunde ej hämta BokningsResurser</div>;
};
