import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import BigTabListItem from '../../../components/BigTabItem/BigTabListItem';
import BigNavItem from '../../../components/BigTabItem/BigTabListItemNav';
import BigTabNav from '../../../components/BigTabItem/BigTabNav';
import PageTitle from '../../../components/typography/PageTitle';
import { L } from '../../../lib/i18n';
import CreateNewInformationPage from './CreateNewInformationPage/CreateNewInformation';
import InformationDetailPage from './DetailPage/InformationDetailPage';
import { useGetMemberInformationById } from './hooks/memberInformationQueries';
import InformationDraftListpage from './ListPage/InformationDraftListPage';
import InformationPublishedListPage from './ListPage/InformationPublishedListPage';

const MemberInformationPage = () => {
    const { id } = useParams<{ id: string }>();
    const { data } = useGetMemberInformationById(+id);

    return (
        <>
            <PageTitle>{L('member_information_title')}</PageTitle>
            <BigTabNav>
                <BigNavItem exact to={'/information'}>
                    {L('all_published')}
                </BigNavItem>
                <BigNavItem exact to={'/information/draft'}>
                    {L('draft')}
                </BigNavItem>
                <Switch>
                    <Route exact path={'/information/draft'} />
                    <Route exact path={'/information/new'}>
                        <div style={{ borderRight: 'solid 1px gray' }} />
                        <BigTabListItem className="active">{L('new_information_page')}</BigTabListItem>
                    </Route>
                    <Route path={'/information/:id'}>
                        <div style={{ borderRight: 'solid 1px gray' }} />
                        <BigNavItem exact to={`/information/${data?.id}`}>
                            {data?.title}
                        </BigNavItem>
                    </Route>
                </Switch>
            </BigTabNav>
            <Switch>
                <Route path={'/information/new'}>
                    <CreateNewInformationPage />
                </Route>
                <Route exact path={'/information/draft'}>
                    <InformationDraftListpage />
                </Route>
                <Route path={'/information/:id'}>
                    <InformationDetailPage />
                </Route>
                <Route exact path={'/information'}>
                    <InformationPublishedListPage />
                </Route>
            </Switch>
        </>
    );
};

export default MemberInformationPage;
