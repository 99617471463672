import React from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';
import PageTitle from '../../components/typography/PageTitle';
import DocumentContent from './components/DocumentContent';
import { L } from '../../lib/i18n';

export const DocumentPage = () => {
    return (
        <>
            <PageTitle>{L('documents')}</PageTitle>

            <Routes>
                <Route path={'/document'}>
                    <Route index element={<DocumentContent />} />
                    <Route path={':folderPath/*'} element={<DocumentContent />} />
                </Route>
            </Routes>
        </>
    );
};
