import styled from '@emotion/styled';
import React from 'react';
import { DocumentDetail } from '@app/api/public/DocumentsApi';

const StyledDocumentRow = styled.a({
    textDecoration: 'none',
    display: 'block',
    fontSize: '12px',
    cursor: 'pointer',
    color: 'inherit',
});

const PublicDocumentFileColumn = ({ document }: { document: DocumentDetail }) => {
    const url = document?.document_data?.public_url;
    const fileName = document?.document_data.original.metadata.filename;
    return (
        <StyledDocumentRow href={url} target="_blank" rel="noreferrer">
            {fileName}
        </StyledDocumentRow>
    );
};

export default PublicDocumentFileColumn;
