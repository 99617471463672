import React, { DetailedHTMLProps, LiHTMLAttributes, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { FileDetails, FolderDetails } from '@app/api/models/Folders';
import { Icon, IconFileType, Input, colors, MoreMenu } from '@ourliving/ourliving-ui';
import ListItemOptions from './ListItemOptions';
import ItemLink from './ItemLink';
import { L } from '../../../../lib/i18n';
import Dialog from '../../../../components/Dialog/Dialog';
import { showSize } from './helpers/showSize';

type DragStyleProps = {
    selected?: boolean;
    draggedOver?: boolean;
    type?: FolderDetails['type'] | FileDetails['type'];
    currentlyDragging?: boolean;
};

const ListItemBorderRadius = '10px';

export const StyledListItem = styled.li(({ currentlyDragging }: DragStyleProps) => {
    return {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 2rem',
        borderRadius: ListItemBorderRadius,
        position: 'relative',
        overflow: 'hidden',

        '&:hover': {
            '.list-item-checkbox-wrapper': {
                opacity: '1',
            },
            '.list-item-background': {
                backgroundColor: currentlyDragging
                    ? colors.brandColor.brandLightGreen
                    : colors.backgroundColor.lynxWhite,
                opacity: 1,
            },
        },
    };
});

export const ListItemBackground = styled.div(({ selected, draggedOver, type, currentlyDragging }: DragStyleProps) => {
    return {
        backgroundColor: currentlyDragging
            ? colors.brandColor.brandLightGreen
            : draggedOver && type === 'folder'
            ? colors.brandColor.brandLightGreen
            : selected
            ? colors.brandColor.brandLightGreen
            : colors.backgroundColor.white,
        opacity: draggedOver || currentlyDragging ? 1 : selected ? 0.6 : 1,
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 1,
        pointerEvents: 'none',
        userSelect: 'none',
        cursor: type === 'file' ? 'not-allowed' : 'default',
        borderRadius: ListItemBorderRadius,
        border: `1px solid ${draggedOver && !currentlyDragging ? colors.brandColor.brandGreen : 'none'}`,
    };
});

export const ListItemIconWrapper = styled.div({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    pointerEvents: 'none',
});

export const CheckboxWrapper = styled.div({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: '0',
    userSelect: 'none',
    pointerEvents: 'none',
});

const Checkbox = styled(Input.Checkbox)({
    cursor: 'pointer',
});

export const ListItemText = styled.div({
    display: 'flex',
    alignItems: 'center',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    userSelect: 'none',
    pointerEvents: 'none',
});

const ListItemControlsContainer = styled.div({
    pointerEvents: 'all',
    borderRadius: '10px',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '*': {
        pointerEvents: 'all',
    },

    '&:hover': {
        backgroundColor: colors.uiColor.almostBrandGreen + '80',
    },
});

export type MoreMenuOptions = 'rename' | 'delete';

export type SelectItem = FileDetails | FolderDetails;

type ListItemProps = {
    item: FolderDetails | FileDetails;
    onRenameSuccess: () => void;
    onDeleteSuccess: () => void;
    draggedItem: (item: FolderDetails | FileDetails | null) => void;
    targetItem: (item: FolderDetails) => void;
    onItemSelect: (item: SelectItem) => void;
    onItemUnselect: (item: SelectItem) => void;
    currentlyDragging: boolean;
    isSelected: boolean;
} & DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>;

const ListItem: React.FC<ListItemProps> = ({
    item,
    onRenameSuccess,
    onDeleteSuccess,
    draggedItem,
    targetItem,
    onItemSelect,
    onItemUnselect,
    currentlyDragging,
    isSelected,
}) => {
    const [showMoreMenu, setShowMoreMenu] = useState(false);
    const [popup, setPopup] = useState<false | MoreMenuOptions>(false);
    const [showPopup, setShowPopup] = useState(false);

    const handleDrag = (item: FolderDetails | FileDetails | null) => draggedItem(item);

    const handleDrop = () => {
        if (item.type !== 'folder') return;
        targetItem({
            id: item.id,
            type: 'folder',
            name: item.name,
            project_id: item.project_id,
            parent_id: item.parent_id,
        });
    };

    const [draggedOver, setDraggedOver] = useState(false);
    const [selected, setSelected] = useState(isSelected);

    useEffect(() => {
        if (selected) {
            onItemSelect(item);
        } else {
            onItemUnselect(item);
        }
    }, [selected]);

    useEffect(() => {
        setSelected(isSelected);
    }, [isSelected]);

    return (
        <StyledListItem
            draggable={'true'}
            onDragStart={() => handleDrag(item)}
            onDragEnd={() => handleDrag(null)}
            onDrop={() => {
                handleDrop();
                setDraggedOver(false);
            }}
            currentlyDragging={currentlyDragging}
        >
            <ListItemBackground
                draggable={'false'}
                className="list-item-background"
                selected={selected}
                draggedOver={draggedOver}
                type={item.type}
                currentlyDragging={currentlyDragging}
            />
            <ItemLink
                item={item}
                draggable={'false'}
                onDragEnter={() => setDraggedOver(true)}
                onDragLeave={() => setDraggedOver(false)}
            >
                <CheckboxWrapper
                    style={selected ? { opacity: '1' } : {}}
                    className="list-item-checkbox-wrapper"
                    onClick={(e) => e.stopPropagation()}
                >
                    <Checkbox
                        checked={selected}
                        onChange={(e) => {
                            setSelected(e.target.checked);
                            if (selected) {
                                onItemSelect(item);
                            } else {
                                onItemUnselect(item);
                            }
                        }}
                    />
                </CheckboxWrapper>
                <ListItemIconWrapper onDragOver={(e) => e.preventDefault()}>
                    {item.type === 'file' ? (
                        <IconFileType
                            fileType={item.document_data.original.metadata.mime_type}
                            width={24}
                            height={24}
                        />
                    ) : (
                        <Icon.Folder width={24} height={24} />
                    )}
                </ListItemIconWrapper>

                <ListItemText>{item.name}</ListItemText>

                <ListItemText>
                    {String(item.created_at).slice(0, String(item.created_at).lastIndexOf(':'))}
                </ListItemText>

                <ListItemText>{item.created_by_name}</ListItemText>

                <ListItemText>
                    {item.type === 'file' ? showSize(item.document_data?.original?.metadata?.size || 0) : ''}
                </ListItemText>

                <ListItemText>
                    {item.type === 'file'
                        ? item.document_data.original.id.slice(item.document_data.original.id.lastIndexOf('.') + 1)
                        : ''}
                </ListItemText>
            </ItemLink>

            <ListItemControlsContainer>
                <MoreMenu
                    modal={false}
                    open={showMoreMenu || showPopup}
                    onOpenChange={(b) => {
                        setShowMoreMenu(b);
                    }}
                >
                    <MoreMenu.MenuItem
                        onClick={() => {
                            setPopup('rename');
                            setShowPopup(true);
                        }}
                    >
                        {L('rename')}
                    </MoreMenu.MenuItem>
                    <MoreMenu.MenuItem
                        onClick={() => {
                            setPopup('delete');
                            setShowPopup(true);
                        }}
                    >
                        {L('delete')}
                    </MoreMenu.MenuItem>
                    {showPopup && (
                        <Dialog
                            open={true}
                            onOpenChange={(b) => {
                                setShowPopup(b);
                                setShowMoreMenu(b);
                            }}
                        >
                            <ListItemOptions
                                popup={popup}
                                item={item}
                                onDeleteSuccess={() => onDeleteSuccess()}
                                onRenameSuccess={() => onRenameSuccess()}
                                onClose={() => {
                                    setShowMoreMenu(false);
                                    setPopup(false);
                                    setShowPopup(false);
                                }}
                            />
                        </Dialog>
                    )}
                </MoreMenu>
            </ListItemControlsContainer>
        </StyledListItem>
    );
};

export default ListItem;
