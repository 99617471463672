import { Contact } from '@app/api/models/Contacts';
import ContactsApi from '@app/api/public/ContactsApi';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { L } from '../../../lib/i18n';
import { getContactListQueryKey } from './useGetContacts';

const saveContact = (variables: Partial<Contact> & { file?: File }) => {
    return ContactsApi.save(variables);
};

const useSaveContact = () => {
    const queryClient = useQueryClient();

    return useMutation((variables: Partial<Contact> & { file?: File }) => saveContact(variables), {
        onError: (err) => {
            /* Shows error message */
            const error = typeof err === 'string' ? err : new Error(L('reset_error')).message;
            const message = error;
            toast.error(`${L(message)}`);
        },
        onSettled: () => {
            queryClient.invalidateQueries([getContactListQueryKey]);
        },
    });
};

export default useSaveContact;
