import React, { HTMLAttributes, useEffect } from 'react';
import { DocumentDetail } from '@app/api/models/Documents';
import styled from '@emotion/styled';
import { colors } from '../Shared/Style/colors';
import { useGetSignedUrlById } from '../../hooks/useGetSignedUrlById';
import FileTypeIcon from '../Icon/FileTypeIcon';
import * as mime from 'mime-types';

type props = {
    document?: DocumentDetail;
};

const StyledFileIcon = styled(FileTypeIcon)({
    width: '1rem',
    height: '1rem',
});

const P = styled.p(() => {
    return {
        fontSize: '1rem',
        color: colors.textColor2,
        textDecoration: 'none',
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        '&:hover': {
            cursor: 'pointer',
        },
    };
});

const StyledA = styled.a({
    display: 'grid',
    alignItems: 'center',
    gap: '0.4rem',
    gridTemplateColumns: 'max-content auto',
    textDecoration: 'none',
});

const DocumentItem: React.FC<props & HTMLAttributes<HTMLAnchorElement>> = ({ document, ...rest }) => {
    const { data: url } = useGetSignedUrlById(document?.document_data.original.id, '800x800');

    if (!url) {
        return null;
    }
    const name = document?.name || document?.document_data?.original.id;
    const fileType = typeof name === 'string' ? name.split('.').pop() : '';

    return (
        <StyledA href={url} target="_blank" {...rest}>
            <StyledFileIcon fileType={fileType} />
            <P>{name}</P>
        </StyledA>
    );
};

export default DocumentItem;
