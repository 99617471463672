Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["list"] = createRpcProxy("ServicePartnersApi", "list", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getbyid"] = createRpcProxy("ServicePartnersApi", "getbyid", {
  "urlPrefix": "/rpc"
});
o["save"] = createRpcProxy("ServicePartnersApi", "save", {
  "urlPrefix": "/rpc"
});
o["remove"] = createRpcProxy("ServicePartnersApi", "remove", {
  "urlPrefix": "/rpc"
});
o["saveServicePartnerCategory"] = createRpcProxy("ServicePartnersApi", "saveServicePartnerCategory", {
  "urlPrefix": "/rpc"
});
o["listServicePartnerCategories"] = createRpcProxy("ServicePartnersApi", "listServicePartnerCategories", {
  "urlPrefix": "/rpc"
});
o["listServiceByCategoryId"] = createRpcProxy("ServicePartnersApi", "listServiceByCategoryId", {
  "urlPrefix": "/rpc"
});
o["deleteServicePartnerCategory"] = createRpcProxy("ServicePartnersApi", "deleteServicePartnerCategory", {
  "urlPrefix": "/rpc"
});
o["saveDocument"] = createRpcProxy("ServicePartnersApi", "saveDocument", {
  "urlPrefix": "/rpc"
});
o["updateDocumentName"] = createRpcProxy("ServicePartnersApi", "updateDocumentName", {
  "urlPrefix": "/rpc"
});
o["listDocuments"] = createRpcProxy("ServicePartnersApi", "listDocuments", {
  "urlPrefix": "/rpc"
});
o["deleteDocumentFromServicePartner"] = createRpcProxy("ServicePartnersApi", "deleteDocumentFromServicePartner", {
  "urlPrefix": "/rpc"
});