import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import NoticeTitle from '../components/NoticeTitle';
import NoticeLabel from '../components/NoticeLabel';
import NoticeDescription from '../components/NoticeDescription';

import * as Icon from 'react-feather';
import { NotificationType } from '@app/api/models/CommunityNotifications';
import styled from '@emotion/styled';
import { style } from '../../../components/Shared/Style';
import NoticeDate from './NoticeDate';
import dayjs from 'dayjs';
import sv from 'dayjs/locale/sv';

const Border = styled.div(({ theme }) => {
    return {
        backgroundColor: theme.colors.alertBackground,
        padding: '16px',
        width: '100%',
        borderRadius: '8px',
        display: 'grid',
        whiteSpace: 'pre-line',
        gridTemplateColumns: 'min-content auto min-content',
        gridTemplateAreas: `
    "icon title label"
    "icon description label"
    "icon date label"
    `,
        columnGap: '1rem',
        rowGap: '0.5 rem',
    };
});

const IconDiv = styled.div(({ theme }) => {
    return {
        cursor: 'pointer',
        gridArea: 'icon',
        placeSelf: 'center',
        '& span': {
            color: theme?.colors?.textColor2,
        },
        svg: {
            height: '24px',
            width: '24px',
        },
    };
});

const MailNotice: FC<NotificationType & { isCollapsed: boolean }> = ({
    title,
    body,
    isCollapsed,
    link,
    read,
    created_at,
}) => {
    const notificationDate = dayjs(created_at).isValid() ? dayjs(Date.now()).to(created_at) : '';
    return (
        <Link to={link ?? `/`}>
            <Border style={{ backgroundColor: read ? style.colors.background : style.colors.alertBackground }}>
                <IconDiv>
                    <Icon.Mail />
                </IconDiv>

                <NoticeTitle isCollapsed={isCollapsed} description={title} />
                <NoticeDescription description={body} isCollapsed={isCollapsed} />
                <NoticeLabel type={'show_mail'} backgroundColor={style.colors.primary} />
                <NoticeDate when={notificationDate} />
            </Border>
        </Link>
    );
};

export default MailNotice;
