import { ServicePartner } from '@app/api/public/ServicePartnersApi';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { MutateOptions } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '../../../../components/Buttons/Button';
import Input from '../../../../components/Forms/Input';
import Label from '../../../../components/Forms/Label';
import { TextAreaInput } from '../../../../components/Forms/TextArea';
import Spinner from '../../../../components/Spinner/Spinner';
import H2 from '../../../../components/typography/H2';
import { L } from '../../../../lib/i18n';
import useRemoveServicePartner from '../../hooks/useRemoveServicePartner';
import useSaveServicePartner from '../../hooks/useSaveServicePartner';

const Form = styled.form({
    display: 'grid',
    marginBottom: '2rem',
    marginRight: '2rem',
    gridTemplateColumns: `1fr 1fr`,
    columnGap: '2rem',
});

const StyledTextArea = styled(TextAreaInput)({
    resize: 'vertical',
});

export type ServicePartnerFormProps = {
    initialFormData?: Partial<ServicePartner>;
    submitOptios?: MutateOptions<ServicePartner, unknown, Partial<ServicePartner>>;
} & React.FormHTMLAttributes<HTMLFormElement>;

const ServicePartnerForm = ({ initialFormData, submitOptios, ...rest }: ServicePartnerFormProps) => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const [confirmRemove, setConfirmRemove] = useState(false);
    const { mutate: remove } = useRemoveServicePartner();

    const [servicePartner, setServicePartner] = useState<Pick<ServicePartner, 'name' | 'description'>>({
        name: '',
        description: '',
    });
    const [contact, setContact] = useState({
        name: '',
        email: '',
        phone_number: '',
    });
    const [adress, setAdress] = useState({
        addressLine1: '',
        addressLine2: '',
        postalCode: '',
        city: '',
        country: '',
    });

    useEffect(() => {
        setServicePartner({
            name: initialFormData?.name || '',
            description: initialFormData?.description || '',
        });
        setContact({
            name: initialFormData?.contact?.name || '',
            email: initialFormData?.contact?.email || '',
            phone_number: initialFormData?.contact?.phone_number || '',
        });
        setAdress({
            addressLine1: initialFormData?.address?.addressLine1 || '',
            addressLine2: initialFormData?.address?.addressLine2 || '',
            postalCode: initialFormData?.address?.postalCode || '',
            city: initialFormData?.address?.city || '',
            country: initialFormData?.address?.country || '',
        });
    }, [initialFormData]);
    const saveServicePartner = useSaveServicePartner();

    const handleOnChange = (value: Partial<ServicePartner>) => {
        setServicePartner({ ...servicePartner, ...value });
    };

    const handleContactOnChange = (value: Partial<ServicePartner['contact']>) => {
        setContact({ ...contact, ...value });
    };

    const handleAdressOnChange = (value: Partial<ServicePartner['address']>) => {
        setAdress({ ...adress, ...value });
    };

    const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        saveServicePartner.mutate(
            { ...initialFormData, ...servicePartner, address: { ...adress }, contact: { ...contact } },
            {
                onSuccess: (data) => {
                    if (!id) {
                        history.push(`/service-partner/${data.id}`);
                    }
                },
                ...submitOptios,
            }
        );
    };

    const removeButton = confirmRemove ? (
        <Button
            type="button"
            onClick={() =>
                remove(
                    { id: initialFormData?.id },
                    {
                        onSuccess: () => {
                            history.push('/service-partner');
                        },
                    }
                )
            }
            variant="delete"
        >
            {L('remove')}
        </Button>
    ) : (
        <Button type="button" onClick={() => setConfirmRemove(true)}>
            {L('remove')}
        </Button>
    );

    return (
        <Form {...rest} onSubmit={handleOnSubmit}>
            <H2
                style={{
                    gridColumn: '1 / -1',
                }}
            >
                {L('service_partner_info')}
            </H2>
            {/* General */}
            <Label title={L('service_partner_name')}>
                <Input
                    value={servicePartner.name}
                    onChange={(e) => handleOnChange({ name: e.target.value })}
                    required
                />
            </Label>

            {/* Adress */}
            <Label title={L('address_1')}>
                <Input
                    value={adress?.addressLine1}
                    onChange={(e) => handleAdressOnChange({ addressLine1: e.target.value })}
                />
            </Label>
            <Label title={L('address_2')}>
                <Input
                    value={adress?.addressLine2}
                    onChange={(e) => handleAdressOnChange({ addressLine2: e.target.value })}
                />
            </Label>
            <Label title={L('postalCode')}>
                <Input
                    value={adress?.postalCode}
                    onChange={(e) => handleAdressOnChange({ postalCode: e.target.value })}
                />
            </Label>

            <Label title={L('country')}>
                <Input value={adress?.country} onChange={(e) => handleAdressOnChange({ country: e.target.value })} />
            </Label>
            <Label title={L('city')}>
                <Input value={adress?.city} onChange={(e) => handleAdressOnChange({ city: e.target.value })} />
            </Label>
            <Label title={L('description')} style={{ gridColumn: '1 / -1' }}>
                <StyledTextArea
                    value={servicePartner.description}
                    onChange={(e) => handleOnChange({ description: e.target.value })}
                />
            </Label>

            {/* Contact */}
            <H2
                style={{
                    gridColumn: '1 / -1',
                    marginTop: '2rem',
                }}
            >
                {L('contact_person')}
            </H2>
            <Label title={L('contact_name')}>
                <Input value={contact?.name} onChange={(e) => handleContactOnChange({ name: e.target.value })} />
            </Label>

            <Label title={L('telephone_number')}>
                <Input
                    value={contact?.phone_number}
                    onChange={(e) => handleContactOnChange({ phone_number: e.target.value })}
                />
            </Label>

            <Label title={L('contact_mail')}>
                <Input
                    type={'email'}
                    value={contact?.email}
                    onChange={(e) => handleContactOnChange({ email: e.target.value })}
                />
            </Label>

            <div
                style={{
                    gridColumn: '1 / -1',
                    display: 'flex',
                    gap: '2rem',
                    justifyContent: 'end',
                    marginTop: '1.5rem',
                    width: '100%',
                }}
            >
                {initialFormData?.id && removeButton}
                {saveServicePartner.status === 'idle' && (
                    <Button type={'submit'} variant="filledPrimary">
                        {L('save')}
                    </Button>
                )}
                {saveServicePartner.status === 'loading' && (
                    <Button variant="filledPrimary" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Spinner />
                    </Button>
                )}
                {saveServicePartner.status === 'error' && (
                    <Button type={'submit'} variant="filledPrimary">
                        {L('save')}
                    </Button>
                )}
                {saveServicePartner.status === 'success' && (
                    <Button type={'submit'} variant="filledPrimary">
                        {L('saved')}
                    </Button>
                )}
            </div>
        </Form>
    );
};

export default ServicePartnerForm;
