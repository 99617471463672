import React from 'react';
import { Route } from 'react-router-dom';
import BookingPage from './ListPage/BookingPage';

export const routes = [
    <Route key="1" path="/booking/:id" component={BookingPage} />,
    <Route key="0" path="/booking" component={BookingPage} />,
];

export const navigation = { name: 'bookings', link: '/booking', icon: 'Booking', parent: 'Members' };
