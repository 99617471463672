import { useQuery } from 'react-query';
import PublicSiteApi from '@app/api/public/PublicSiteApi';
import { useParams } from 'react-router-dom';
import { getPublicSiteDocumentsList } from '../../../areas/public-site/hooks/useGetDocumentsById';

const getDocuments = async (id: number) => {
    return await PublicSiteApi.listDocuments(id);
};

const useGetDocuments = () => {
    const { id } = useParams<{ id: string }>();
    return useQuery(getPublicSiteDocumentsList.list(+id), () => getDocuments(+id));
};

export default useGetDocuments;
