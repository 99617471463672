import { AlterPriorityType } from '@app/api/public/AlertsApi';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React from 'react';
import { useState } from 'react';
import { Button } from '../../../components/Buttons/Button';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import { TextAreaInput } from '../../../components/Forms/TextArea';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import Spinner from '../../../components/Spinner/Spinner';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import useCreateAlert, { CreateAlertType } from './useCreateAlert';
import useGetProperties from '../../../hooks/useGetProperties';
import AlertCard from '../components/AlertCard';
import MultiSelect from '../../../components/Dropdown/MultiSelect';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router';

const Container = styled.div({
    display: 'grid',
    gridTemplateColumns: '350px 1fr',
    gap: '2rem',
});
const Title = styled.h2({
    fontSize: '1rem',
    marginBottom: '1rem',
});

const StyledForm = styled.form({
    display: 'grid',
    gridTemplateAreas: `
    "title title"
    "properties priority"
    "description description"
    "from to"
    "button button"
    `,
    gap: '0px 1rem',
});

const SaveButton = styled(Button)({
    gridArea: 'button',
    justifySelf: 'end',
    marginTop: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
});

const priorities: AlterPriorityType[] = ['low', 'medium', 'high'];

const NewAlert = () => {
    const [newAlert, setNewAlert] = useState<CreateAlertType>({
        title: 'titel',
        priority: 'low',
        description: 'information',
        publish_from_date: dayjs().format('YYYY-MM-DDThh:mm'),
        publish_to_date: dayjs().add(1, 'day').format('YYYY-MM-DDThh:mm'),
    });

    const [selectedProperties, setSeclectedProperties] = useState<{ value: number; label: string }[]>([]);

    const handleChange = (key: keyof CreateAlertType, value: string) => {
        setNewAlert((prev) => ({ ...prev, [key]: value }));
    };

    const handlePropertyChange = (values: { value: number; label: string }[]) => {
        setSeclectedProperties(values);
    };
    const { data: properies } = useGetProperties();
    const { mutate, status } = useCreateAlert();

    const history = useHistory();

    const handleSave = () => {
        const propertyIds = selectedProperties.length
            ? selectedProperties.map((property) => property.value)
            : properies?.map((property) => property.id);
        if (!propertyIds?.length) return toast.error('Inga bostäder valda');
        mutate(
            {
                ...newAlert,
                propertyIds,
            },
            {
                onSuccess: () => {
                    history.push('/alerts');
                },
            }
        );
    };

    const propteryOptions = properies
        ? [...properies?.map((property) => ({ value: property.id, label: property.name }))]
        : [];

    const propertyNames = selectedProperties.length
        ? selectedProperties?.map((property) => property.label)
        : properies?.map((property) => property.name);

    return (
        <CardWithDivider
            topArea={<CardTitle>{L('alert_new_titel')}</CardTitle>}
            mainArea={
                <Container>
                    <div>
                        <Title>{L('alert_preview')}</Title>
                        <AlertCard
                            description={newAlert.description}
                            priority={newAlert.priority}
                            publish_from_date={newAlert.publish_from_date}
                            publish_to_date={newAlert.publish_to_date}
                            title={newAlert.title}
                            propertyNames={propertyNames || []}
                        />
                    </div>

                    <div>
                        <Title>{L('alert_create_title')}</Title>
                        <StyledForm>
                            <Label style={{ gridArea: 'title' }} title={L('title')}>
                                <Input value={newAlert.title} onChange={(e) => handleChange('title', e.target.value)} />
                            </Label>
                            <Label style={{ gridArea: 'description' }} title={L('information')}>
                                <TextAreaInput
                                    value={newAlert.description}
                                    onChange={(e) => handleChange('description', e.target.value)}
                                />
                            </Label>
                            <Label style={{ gridArea: 'priority' }} title={L('priority')}>
                                <Dropdown
                                    value={newAlert.priority}
                                    onChange={(value) => handleChange('priority', value)}
                                    options={priorities.map((prio) => ({ value: prio, label: L(prio) }))}
                                />
                            </Label>
                            <Label style={{ gridArea: 'properties' }} title={L('properties')}>
                                <MultiSelect
                                    value={selectedProperties}
                                    onChange={(value: { value: number; label: string }[]) => {
                                        console.log(value);
                                        handlePropertyChange(value);
                                    }}
                                    placeholder={
                                        selectedProperties.length === 0 ||
                                        selectedProperties.length === properies?.length
                                            ? L('all_properies_selected')
                                            : `${selectedProperties?.[0].label} ${
                                                  selectedProperties.length - 1 > 0
                                                      ? `+${selectedProperties.length - 1}`
                                                      : ''
                                              }`
                                    }
                                    isMulti={true}
                                    options={propteryOptions}
                                />
                            </Label>
                            <Label style={{ gridArea: 'from' }} title={L('publish_from')}>
                                <Input
                                    value={newAlert.publish_from_date}
                                    type={'datetime-local'}
                                    onChange={(e) => handleChange('publish_from_date', e.target.value)}
                                />
                            </Label>
                            <Label style={{ gridArea: 'to' }} title={L('publish_to')}>
                                <Input
                                    value={newAlert.publish_to_date}
                                    type={'datetime-local'}
                                    onChange={(e) => handleChange('publish_to_date', e.target.value)}
                                />
                            </Label>

                            <SaveButton onClick={handleSave} disabled={status === 'loading'}>
                                {status === 'loading' && <Spinner />}
                                {status !== 'loading' && L('save')}
                            </SaveButton>
                        </StyledForm>
                    </div>
                </Container>
            }
        />
    );
};

export default NewAlert;
