import React, { useEffect, useState } from 'react';
import { style } from '../Shared/Style';
import styled from '@emotion/styled';

const TextAreaInputField = styled.textarea(({ theme }) => ({
    background: theme.colors.inputBackground,
    border: `1px solid ${theme.colors.inputBorder}`,
    width: '100%',
    borderRadius: style.roundCornerSize.small,
    outline: 'none',
    padding: style.margin.s,
    fontSize: style.fontSize.seta,
    resize: 'vertical',
    '&:read-only': {
        color: `${theme.colors.inputPlaceholderColor}`,
        cursor: 'default',
        backgroundColor: theme.colors.inputDisabled,
    },
}));

type Props = React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;

export const TextAreaInput = ({ ...rest }: Props) => {
    return <TextAreaInputField rows={10} {...rest} />;
};

export default TextAreaInput;
