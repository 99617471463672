import React, { useState } from 'react';
import styled from '@emotion/styled';
import Input from '../../../components/Forms/Input';
import { Button } from '../../../components/Buttons/Button';
import { L } from '../../../lib/i18n';
import { isEqual } from 'lodash';
import BoxColorPicker from '../../../components/BoxColorPicker/BoxColorPicker';
import { TicketSettingsByType, TicketSettingType } from '@app/api/models/Tickets';
import useSaveSettingByType from '../hooks/useSaveSettingByType';
import useRemoveSettingByType from '../hooks/useRemoveSettingByType';

const Save = styled(Button)({
    height: '100%',
    margin: '0px',
});

const GridWrapper = styled.div({
    width: '100%',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'min-content auto min-content',

    gap: '1rem',
});

const RemoveButton = styled(Button)({
    height: '100%',
});

type Props = {
    item: TicketSettingsByType;
    type: TicketSettingType;
};

const SettingItem = ({ item, type, ...rest }: Props) => {
    const [color, setColor] = useState(item.color_code);
    const [name, setName] = useState(item.name || '');
    const [confirmRemove, setConfirmRemove] = useState(false);
    const { mutate: remove } = useRemoveSettingByType();
    const { mutate: save, status: saveStatus } = useSaveSettingByType();

    const edited = !isEqual({ name: item.name, color: item.color_code }, { name, color });

    const handleSave = () => {
        if (!edited) return;
        save({ setting: { name: name, color_code: color, id: item.id }, type });
    };

    const removeButton = confirmRemove ? (
        <RemoveButton onClick={() => remove({ settingId: item.id, type: type })} variant="delete">
            {L('remove')}
        </RemoveButton>
    ) : (
        <RemoveButton onClick={() => setConfirmRemove(true)}>{L('remove')}</RemoveButton>
    );
    return (
        <GridWrapper {...rest}>
            <BoxColorPicker initialColor={item.color_code || ''} getValue={(value) => setColor(value)} />

            <Input onChange={(e) => setName(e.target.value)} value={name} />
            <div>
                {edited ? (
                    <Save variant="selectedMenu" onClick={handleSave} disabled={saveStatus === 'loading'}>
                        {saveStatus === 'loading' ? L('saving') : L(`save`)}
                    </Save>
                ) : (
                    removeButton
                )}
            </div>
        </GridWrapper>
    );
};

export default SettingItem;
