import { useMutation, useQueryClient } from 'react-query';
import ContractsApi from '@app/api/public/ContractsApi';
import { getContractKeys } from './useContract';

export type Variables = {
    documentId: string;
    queryId: string;
};

const deleteContractDocument = async (variables: Variables) => {
    return ContractsApi.deleteDocumentFromContract(variables.documentId, variables.queryId);
};

const useDeleteContractDocument = () => {
    const queryClient = useQueryClient();
    return useMutation((variables: Variables) => deleteContractDocument(variables), {
        onSettled: () => {
            queryClient.invalidateQueries(getContractKeys.documentLists());
        },
    });
};

export default useDeleteContractDocument;
