import React, { FC } from 'react';
import styled from '@emotion/styled';
import { colors } from '../../../components/Shared/Style/colors';
import { roundCornerSize } from '../../../components/Shared/Style/box';
import { HTMLAttributes } from 'react';

const Container = styled.div({
    maxWidth: '800px',
    placeSelf: 'center',
    backgroundColor: colors.white,
    borderRadius: roundCornerSize.medium,
    padding: '2rem',
});

const SettingsModal: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...rest }) => {
    return <Container {...rest}>{children}</Container>;
};

export default SettingsModal;
