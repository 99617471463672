import React from 'react';
import { L } from '../../../../lib/i18n';
import UpdateFolderForm from '../forms/UpdateFolderForm';
import DeleteFolder from '../DeleteFolder';
import toast from 'react-hot-toast';
import { FileDetails, FolderDetails } from '@app/api/models/Folders';
import UpdateFileForm from '../forms/UpdateFileForm';
import DeleteFile from '../DeleteFile';
import { MoreMenuOptions } from './ListItem';

type Props = {
    popup: MoreMenuOptions | false;
    item: FolderDetails | FileDetails;
    onRenameSuccess: () => void;
    onDeleteSuccess: () => void;
    onClose: () => void;
};

const ListItemOptions = ({ popup, item, onRenameSuccess, onDeleteSuccess, onClose }: Props) => {
    return (
        <>
            {popup === 'rename' &&
                ((item.type === 'folder' && (
                    <UpdateFolderForm
                        item={item as FolderDetails}
                        onCancel={() => {
                            onClose();
                        }}
                        options={{
                            onSuccess: () => {
                                onRenameSuccess();
                                toast.success(L('folder_updated'));
                                onClose();
                            },
                            onError: () => {
                                toast.error(L('folder_updated_fail'));
                            },
                        }}
                    />
                )) ||
                    (item.type === 'file' && (
                        <UpdateFileForm
                            item={item}
                            onCancel={() => {
                                onClose();
                            }}
                            options={{
                                onSuccess: () => {
                                    onRenameSuccess();
                                    toast.success(L('file_updated'));
                                    onClose();
                                },
                            }}
                        />
                    )))}
            {popup === 'delete' &&
                ((item.type === 'folder' && (
                    <DeleteFolder
                        item={item}
                        onCancel={() => {
                            onClose();
                        }}
                        options={{
                            onSuccess: () => {
                                onDeleteSuccess();
                                onClose();
                            },
                        }}
                    />
                )) ||
                    (item.type === 'file' && (
                        <DeleteFile
                            item={item}
                            onCancel={() => {
                                onClose();
                            }}
                            options={{
                                onSuccess: () => {
                                    onDeleteSuccess();
                                    onClose();
                                },
                            }}
                        />
                    )))}
        </>
    );
};

export default ListItemOptions;
