import React, { FC } from 'react';
import styled from '../../../components/lib/styled';
import { style } from '../../../components/Shared/Style';
import { L } from '../../../lib/i18n';
type Props = {
    type: string;
    className?: string;
    fontSize?: string;
    backgroundColor: string;
};

const Label = styled.div({
    gridArea: 'label',
    display: 'grid',
    placeSelf: 'center',
    color: '#FFFCFC',
    fontSize: style.fontSize.delta,
    padding: '6px 16px',
});

const Text = styled.span({
    placeSelf: 'center',
    width: 'max-content',
});

const NoticeLabel: FC<Props> = ({ type, backgroundColor, ...rest }) => {
    return (
        <Label style={{ backgroundColor }} {...rest}>
            <Text>{L(type || '')}</Text>
        </Label>
    );
};

export default NoticeLabel;
