import { FileDetails, FolderDetails } from '@app/api/models/Folders';
import { SortBy, SortDirection } from '../DocumentList';

export const sortDocumentList = (
    sortBy: SortBy,
    sortDirection: SortDirection,
    files: FileDetails[],
    folders: FolderDetails[]
) => {
    if (sortBy === 'name') {
        if (sortDirection == 'asc') {
            return [
                ...folders.concat().sort((a, b) => a.name.localeCompare(b.name)),
                ...files.concat().sort((a, b) => a.name.localeCompare(b.name)),
            ];
        } else if (sortDirection == 'desc') {
            return [
                ...folders.concat().sort((a, b) => b.name.localeCompare(a.name)),
                ...files.concat().sort((a, b) => b.name.localeCompare(a.name)),
            ];
        } else return [...folders, ...files];
    }

    if (sortBy === 'created_at') {
        if (sortDirection == 'asc') {
            return [
                ...folders
                    .concat()
                    .sort(
                        (a, b) => new Date(String(a.created_at)).getTime() - new Date(String(b.created_at)).getTime()
                    ),
                ...files
                    .concat()
                    .sort(
                        (a, b) => new Date(String(a.created_at)).getTime() - new Date(String(b.created_at)).getTime()
                    ),
            ];
        } else if (sortDirection == 'desc') {
            return [
                ...folders
                    .concat()
                    .sort(
                        (a, b) => new Date(String(b.created_at)).getTime() - new Date(String(a.created_at)).getTime()
                    ),
                ...files
                    .concat()
                    .sort(
                        (a, b) => new Date(String(b.created_at)).getTime() - new Date(String(a.created_at)).getTime()
                    ),
            ];
        } else return [...folders, ...files];
    }

    if (sortBy === 'created_by') {
        if (sortDirection == 'asc') {
            return [
                ...folders.concat().sort((a, b) => String(a.created_by_name).localeCompare(String(b.created_by_name))),
                ...files.concat().sort((a, b) => String(a.created_by_name).localeCompare(String(b.created_by_name))),
            ];
        } else if (sortDirection == 'desc') {
            return [
                ...folders.concat().sort((a, b) => String(b.created_by_name).localeCompare(String(a.created_by_name))),
                ...files.concat().sort((a, b) => String(b.created_by_name).localeCompare(String(a.created_by_name))),
            ];
        } else return [...folders, ...files];
    }

    if (sortBy === 'size') {
        if (sortDirection == 'asc') {
            return [
                ...folders,
                ...files
                    .concat()
                    .sort((a, b) => a.document_data.original.metadata.size - b.document_data.original.metadata.size),
            ];
        } else if (sortDirection == 'desc') {
            return [
                ...folders,
                ...files
                    .concat()
                    .sort((a, b) => b.document_data.original.metadata.size - a.document_data.original.metadata.size),
            ];
        } else return [...folders, ...files];
    }

    if (sortBy === 'type') {
        if (sortDirection == 'asc') {
            return [
                ...folders,
                ...files
                    .concat()
                    .sort((a, b) =>
                        String(a.document_data.original.metadata.mime_type).localeCompare(
                            String(b.document_data.original.metadata.mime_type)
                        )
                    ),
            ];
        } else if (sortDirection == 'desc') {
            return [
                ...folders,
                ...files
                    .concat()
                    .sort((a, b) =>
                        String(b.document_data.original.metadata.mime_type).localeCompare(
                            String(a.document_data.original.metadata.mime_type)
                        )
                    ),
            ];
        } else return [...folders, ...files];
    }

    return [...folders, ...files];
};
