import React from 'react';
import Quill from '../../../components/Quill/Quill';
import { Button } from '../../../components/Buttons/Button';
import { L } from '../../../lib/i18n';
import Attachments from '../components/Attachments/Attachments';
import { style } from '../../../components/Shared/Style';
import MailInputWithChips from '../components/MailInput/MailInputWithChips';
import SquareAdd from '../../../components/Buttons/SquareAdd';
import ErrorAndInputWrapper from '../../../components/Forms/ErrorAndInputWrapper';
import Label from '../../../components/Forms/Label';
import styled from '@emotion/styled';
import Input from '../../../components/Forms/Input';
import { FieldErrors } from 'react-hook-form';
import { mail_messages } from '@app/api/models/Mail/MailModels';

export type FormValues = {
    subject: string;
    recipientsInput: string;
    recipients: string[];
};

const RestyledInput = styled(Input)({
    width: '100%',
    '@media screen and (min-width: 1024px)': {
        width: '75%',
    },
    '@media screen and (min-width: 1440px)': {
        width: '50%',
    },
});

const InputAndButtonWrapper = styled.div({
    display: 'flex',
    alignItems: 'start',
    columnGap: '0.6rem',
});

type Props = {
    recipientsInput: string;
    recipients: string[];
    memoizedRecipients: { value: string; id: string }[];
    subjectValue: string;
    handleMailSubmit: () => void;
    handleSetRecipients: (recipients: string[]) => void;
    handleSetRecipientsInput: (input: string) => void;
    handleSetShowContact: () => void;
    handleSetEditorValue: (value: string) => void;
    handleSetSubjectInput: (value: string) => void;
    errors: FieldErrors<FormValues>;
    draftMail?: mail_messages | null | undefined;
    editorValue: string;
};

const MailForm = ({
    recipientsInput,
    recipients,
    subjectValue,
    memoizedRecipients,
    handleMailSubmit,
    handleSetRecipients,
    handleSetRecipientsInput,
    handleSetShowContact,
    handleSetEditorValue,
    handleSetSubjectInput,
    errors,
    draftMail,
    editorValue,
}: Props) => {
    return (
        <form noValidate onSubmit={handleMailSubmit}>
            <Label title={L('mail_to')}>
                <ErrorAndInputWrapper
                    errorMsg={
                        errors?.recipients?.message
                            ? L('error_required')
                            : errors?.recipientsInput?.type === 'alreadyExists'
                            ? L(errors?.recipientsInput?.message ?? '')
                            : ''
                    }
                >
                    <InputAndButtonWrapper>
                        <MailInputWithChips
                            recipientsInput={recipientsInput}
                            recipients={recipients}
                            memoizedRecipients={memoizedRecipients}
                            handleSetRecipients={handleSetRecipients}
                            handleSetRecipientsInput={handleSetRecipientsInput}
                            style={errors?.recipients || errors?.recipientsInput ? { borderColor: '#ff4545' } : {}}
                        />
                        <SquareAdd
                            onClick={(e) => {
                                e.preventDefault();
                                handleSetShowContact();
                            }}
                        />
                    </InputAndButtonWrapper>
                </ErrorAndInputWrapper>
            </Label>
            <Label title={L('subject')} style={{ marginBottom: style.margin.m }}>
                <ErrorAndInputWrapper errorMsg={errors?.subject?.message ? L('error_required') : ''}>
                    <RestyledInput
                        name={L('subject')}
                        value={subjectValue}
                        onChange={(e) => {
                            handleSetSubjectInput(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') e.preventDefault();
                        }}
                        style={errors?.subject ? { borderColor: '#ff4545' } : {}}
                    />
                </ErrorAndInputWrapper>
            </Label>
            <Quill value={editorValue} onChange={handleSetEditorValue} />
            <Attachments
                mail={{
                    from: '',
                    recipients,
                    subject: subjectValue,
                    html: editorValue,
                    text: '',
                    replyTo: '',
                }}
                draftId={draftMail?.id ?? 0}
                uploadedAttachments={draftMail?.attachments}
            />
            <Button type="submit" style={{ marginTop: style.margin.m }}>
                {L('send')}
            </Button>
        </form>
    );
};

export default MailForm;
