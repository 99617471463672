import React from 'react';
import { L } from '../../../lib/i18n';
import { Loading } from '../../../components/Loading/Loading';
import styled from '@emotion/styled';
import { Button } from '../../../components/Buttons/Button';
import TableQuery from './TableQuery';
import useGetContacts from '../hooks/useGetContacts';
import NewContactForm from '../NewContactForm/NewContactForm';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { Route, Switch } from 'react-router-dom';
import InvisibleLinkWrapper from '../../../components/Link/InvisibleLinkWrapper';

//#region CSS

const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

//#endregion

export const ContactListPage = () => {
    const { data: contacts } = useGetContacts();

    if (!contacts) return <Loading />;

    return (
        <CardWithDivider
            topArea={
                <Wrapper>
                    <CardTitle>{L('all_contacts')}</CardTitle>
                    <InvisibleLinkWrapper to={'/contacts/new'}>
                        <Button>{L('new_contact')}</Button>
                    </InvisibleLinkWrapper>

                    <Switch>
                        <Route path={'/contacts/new'}>
                            <NewContactForm />
                        </Route>
                    </Switch>
                </Wrapper>
            }
            mainArea={<TableQuery data={contacts} />}
        />
    );
};
