Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["list"] = createRpcProxy("BoardContentEventApi", "list", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["get"] = createRpcProxy("BoardContentEventApi", "get", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["listDocumentGroups"] = createRpcProxy("BoardContentEventApi", "listDocumentGroups", {
  "urlPrefix": "/rpc"
});
o["saveDocuementGroup"] = createRpcProxy("BoardContentEventApi", "saveDocuementGroup", {
  "urlPrefix": "/rpc"
});
o["listDocuments"] = createRpcProxy("BoardContentEventApi", "listDocuments", {
  "urlPrefix": "/rpc"
});
o["saveDocument"] = createRpcProxy("BoardContentEventApi", "saveDocument", {
  "urlPrefix": "/rpc"
});
o["deleteDocumentFromContentEvent"] = createRpcProxy("BoardContentEventApi", "deleteDocumentFromContentEvent", {
  "urlPrefix": "/rpc"
});
o["remove"] = createRpcProxy("BoardContentEventApi", "remove", {
  "urlPrefix": "/rpc"
});
o["listParticipants"] = createRpcProxy("BoardContentEventApi", "listParticipants", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["updateDocumentName"] = createRpcProxy("BoardContentEventApi", "updateDocumentName", {
  "urlPrefix": "/rpc"
});
o["resendNotifications"] = createRpcProxy("BoardContentEventApi", "resendNotifications", {
  "urlPrefix": "/rpc"
});
o["editPublishedEvent"] = createRpcProxy("BoardContentEventApi", "editPublishedEvent", {
  "urlPrefix": "/rpc"
});
o["createDraft"] = createRpcProxy("BoardContentEventApi", "createDraft", {
  "urlPrefix": "/rpc"
});
o["saveDraft"] = createRpcProxy("BoardContentEventApi", "saveDraft", {
  "urlPrefix": "/rpc"
});
o["createEventAndPublish"] = createRpcProxy("BoardContentEventApi", "createEventAndPublish", {
  "urlPrefix": "/rpc"
});
o["saveAndPublish"] = createRpcProxy("BoardContentEventApi", "saveAndPublish", {
  "urlPrefix": "/rpc"
});
o["publishEvent"] = createRpcProxy("BoardContentEventApi", "publishEvent", {
  "urlPrefix": "/rpc"
});
o["setEventAsDraft"] = createRpcProxy("BoardContentEventApi", "setEventAsDraft", {
  "urlPrefix": "/rpc"
});
o["toggleEventSignup"] = createRpcProxy("BoardContentEventApi", "toggleEventSignup", {
  "urlPrefix": "/rpc"
});
o["disableSignup"] = createRpcProxy("BoardContentEventApi", "disableSignup", {
  "urlPrefix": "/rpc"
});
o["removeCoverImage"] = createRpcProxy("BoardContentEventApi", "removeCoverImage", {
  "urlPrefix": "/rpc"
});