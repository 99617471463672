import BoardMembersApi from '@app/api/public/BoardMembersApi';
import { useQuery } from 'react-query';
import { L } from '../../../lib/i18n';

export const getrolesListQueryKey = 'getRolesKey';

const getSubRoles = () => {
    return BoardMembersApi.listBoardMemberSubRole();
};

const useGetSubRoles = () => {
    return useQuery([getrolesListQueryKey], () => getSubRoles());
};

const getRoles = () => {
    const roles = ['brf_admin', 'board_member'];
    return roles.map((role) => {
        return { label: L(role), value: role };
    });
};

const useGetRoles = () => {
    return getRoles();
};

export { useGetRoles, useGetSubRoles as default };
