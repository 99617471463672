import { useQueryClient, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import PublicSiteApi from '@app/api/public/PublicSiteApi';
import { PublicSiteInformationItem } from '@app/api/models/PublicSite';
import { getQueryPageListItems } from './useGetPageListItems';

const deletePageListItem = async (id: number) => {
    PublicSiteApi.remove(id);
};

const useDeletePageListItem = () => {
    const queryClient = useQueryClient();

    const history = useHistory();

    return useMutation((id: number) => deletePageListItem(id), {
        onSuccess: (_data, id) => {
            const menuArr = queryClient.getQueryData<PublicSiteInformationItem[]>(getQueryPageListItems.all);
            if (!menuArr) return;
            const updatedArr = menuArr.filter((publicSiteInformationItem) => +publicSiteInformationItem.id !== +id);
            queryClient.setQueriesData(getQueryPageListItems.all, updatedArr);
            history.push('/public-site');
        },
    });
};

export default useDeletePageListItem;
