import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetMemberInformationById } from '../hooks/memberInformationQueries';
import InformationEditDraft from './InformationEditDraft';
import InformationEditPublished from './InformationEditPublished';

const InformationDetailPage = () => {
    const { id } = useParams<{ id: string }>();

    const { data } = useGetMemberInformationById(+id);

    if (!data?.published_at) {
        return <InformationEditDraft />;
    }

    return <InformationEditPublished />;
};

export default InformationDetailPage;
