import React, { FC } from 'react';
import parse, { attributesToProps, HTMLReactParserOptions, Element, domToReact } from 'html-react-parser';
import P from '../typography/P';
import H1 from '../typography/H1';
import H2 from '../typography/H2';
import H3 from '../typography/H3';

const options: HTMLReactParserOptions = {
    replace: (domNode) => {
        if (domNode instanceof Element && domNode.attribs) {
            const props = attributesToProps(domNode.attribs);
            const jsx = domToReact(domNode.children, options);
            if (domNode.name === 'h1') return <H1 {...props}>{jsx}</H1>;
            if (domNode.name === 'h2') return <H2 {...props}>{jsx}</H2>;
            if (domNode.name === 'h3') return <H3 {...props}>{jsx}</H3>;
            if (domNode.name === 'p') return <P {...props}>{jsx}</P>;
            if (domNode.name === 'span') return <>{jsx}</>;
            if (domNode.name === 'o:p') return <>{jsx}</>;
            if (domNode.name === 'html') return <>{jsx}</>;
            if (domNode.name === 'head') return <>{jsx}</>;
            if (domNode.name === 'meta') return <>{jsx}</>;
            if (domNode.name === 'body') return <>{jsx}</>;
        }
    },
};

export const RawHtml: FC<{ content: string }> = ({ content, children, ...rest }) => {
    return (
        <>
            {parse(content.replace(/&nbsp;/gim, ' '), options)}
            {children}
        </>
    );
};
