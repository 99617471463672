import React, { FC } from 'react';
import { useTheme } from 'emotion-theming';
import { SiteTheme } from '@app/api/models/PublicSite';
import styled from '@emotion/styled';

const Font = styled.div(({ theme }: { theme: SiteTheme['theme'] }) => {
    return {
        fontFamily: theme.fonts.body.fontFamily,
        ['h1, h2, h3, h5, h5, h6']: {
            fontFamily: theme.fonts.header.fontFamily,
        },
    };
});
const FontWrapper: FC = ({ children }) => {
    const theme = useTheme<SiteTheme['theme']>();
    return <Font theme={theme}>{children}</Font>;
};

export default FontWrapper;
