import React, { FC } from 'react';
import styled from '@emotion/styled';
import { style } from '../../Shared/Style';
import { Icon } from '../../Icon/Icon';
import { Colors } from '../../Shared/Style/colors';
import { useTheme } from 'emotion-theming';

const StyledRemoveButton = styled.button(() => {
    return {
        background: 'transparent',
        border: 'none',
        padding: '0.15rem',
        '&:hover': {
            cursor: 'pointer',
        },
    };
});

const RemoveButtonLable = styled.p(({ textcolor }: props) => {
    return {
        color: textcolor ? style.colors[textcolor] : style.colors.secondary,
        margin: 0,
        marginTop: '0.5rem',
    };
});

const RemoveIcon = styled(Icon.remove)(({ arrowcolor, circlecolor }: props) => {
    return {
        display: 'block',

        '& svg': {
            width: '100%',
        },

        '& .nc-icon-wrapper': {
            'path:first-of-type': {
                // Circle
                fill: circlecolor ? style.colors[circlecolor] : style.colors.statusError,
            },

            'path:last-of-type': {
                // Arrow
                fill: arrowcolor ? style.colors[arrowcolor] : style.colors.white,
            },
        },
        'button:disabled &': {
            'path:first-of-type': {
                // Circle
                fill: circlecolor ? style.colors[circlecolor] : style.colors.primary,
            },

            'path:last-of-type': {
                // Arrow
                fill: arrowcolor ? style.colors[arrowcolor] : style.colors.white,
            },
        },
    };
});

type props = {
    label?: string;
    className?: string;
    onClick?: () => void;
    circlecolor?: Colors;
    arrowcolor?: Colors;
    textcolor?: Colors;
    type?: 'button' | 'submit' | 'reset' | undefined;
    disabled?: true | false;
};
const RemoveButton: FC<props> = ({
    textcolor,
    arrowcolor,
    circlecolor,
    className,
    label,
    type = 'submit',
    onClick,
    ...rest
}) => {
    return (
        <StyledRemoveButton onClick={onClick} className={className} type={type} {...rest}>
            <RemoveIcon arrowcolor={arrowcolor} circlecolor={circlecolor} />
            {label && <RemoveButtonLable textcolor={textcolor}>{label}</RemoveButtonLable>}
        </StyledRemoveButton>
    );
};

export default RemoveButton;
