import React from 'react';
import styled from '@emotion/styled';
import FileTypeIcon from '../../../../components/Icon/FileTypeIcon';
import { colors } from '../../../../components/Shared/Style/colors';

const P = styled.p(() => {
    return {
        fontSize: '1rem',
        padding: '0px',
        color: colors.textColor2,
        textDecoration: 'none',
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'start',

        '&:hover': {
            cursor: 'pointer',
        },
    };
});

const StyledWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    position: 'relative',
    textDecoration: 'none',
    padding: '0.5rem',
    ['svg']: {
        width: 40,
        height: 40,
    },
});

type DocumentItemProps = {
    fileType: string;
    name: string;
};
const AttachmentItem: React.FC<DocumentItemProps & React.HTMLAttributes<HTMLDivElement>> = ({
    fileType,
    name,
    children,
    ...rest
}) => {
    return (
        <StyledWrapper {...rest}>
            <FileTypeIcon fileType={fileType} />

            <P>{name}</P>
            {children}
        </StyledWrapper>
    );
};

export default AttachmentItem;
