import React from 'react';
import { useGetTicketMessages } from '../hooks/ticketQueries';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import Message from './Message';
import whoisCreator from '../lib/whoIsCreator';
import { L } from '../../../lib/i18n';
import MessageDivider from '../../../components/Message/MessageDivider';
import { colors } from '../../../components/Shared/Style/colors';
import { style } from '../../../components/Shared/Style';
import styled from '@emotion/styled';

const ChatWrapper = styled.div({
    display: 'flex',
    gap: '1rem',
    height: '60vh',
    maxHeight: '60vh',
    overflowY: 'auto',
    maxWidth: '100%',
    flexDirection: 'column-reverse',
    padding: '1rem 0.4rem',
    backgroundColor: colors.inputBackground,
    borderRadius: style.roundCornerSize.small,
});

dayjs.extend(isBetween);

type Props = {
    messages: NonNullable<ReturnType<typeof useGetTicketMessages>['data']>;
    boardMemberIds: number[];
    profileId?: number;
    lastRead: string;
};
const CustomerMessages = ({ profileId, boardMemberIds, messages, lastRead }: Props) => {
    return (
        <ChatWrapper>
            {messages?.map((message, index, messages) => {
                const nextMessage = messages[index + 1];
                const isLastReadBetween = dayjs(lastRead).isBetween(message.created_at, nextMessage?.created_at);

                if (isLastReadBetween && nextMessage) {
                    return (
                        <React.Fragment key={message.id}>
                            <Message
                                key={message.id}
                                message={message}
                                messageSender={whoisCreator(message.created_by, profileId, boardMemberIds)}
                            />
                            <MessageDivider>{L('last_viewed')}</MessageDivider>
                        </React.Fragment>
                    );
                }
                return (
                    <Message
                        key={message.id}
                        messageSender={whoisCreator(message.created_by, profileId, boardMemberIds)}
                        message={message}
                    />
                );
            })}
        </ChatWrapper>
    );
};

export default CustomerMessages;
