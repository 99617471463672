import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { QueryKey, UseMutateFunction, useQueryClient } from 'react-query';

type DraggableItem = {
    sort: number;
    id: number;
};

type Params = {
    queryKey: QueryKey;
    updateSortOrder: UseMutateFunction<
        number,
        unknown,
        {
            id: number;
            sort: number;
        }[],
        unknown
    >;
    reverseArray?: boolean;
    isFiltering?: boolean;
};

const useDragEndDnd = <T extends DraggableItem>({ queryKey, updateSortOrder, reverseArray = false }: Params) => {
    const client = useQueryClient();

    const dragEnd = (event: DragEndEvent) => {
        const oldQueryData = client.getQueryData<T[]>(queryKey);

        const { active, over } = event;
        if (!oldQueryData) return;
        const activeItem = oldQueryData.find((item) => item.id === active.id);
        const overItem = oldQueryData.find((item) => item.id === over?.id);
        if (!activeItem || !overItem) return;
        if (activeItem !== overItem) {
            const oldIndex = oldQueryData?.indexOf(activeItem);
            const newIndex = oldQueryData?.indexOf(overItem);
            const newArray = arrayMove(oldQueryData, oldIndex, newIndex);
            client.setQueryData(queryKey, () => newArray);
            if (reverseArray) newArray.reverse();
            const newSortOrder = newArray.map((item, index) => ({ sort: index, id: item.id }));
            return newSortOrder;
        }
    };

    return (event: DragEndEvent) => {
        const sortedArray = dragEnd(event);
        if (sortedArray) return updateSortOrder(sortedArray);
    };
};

export default useDragEndDnd;
