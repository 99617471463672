import styled from '@emotion/styled';
import React from 'react';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import { colors } from '../../../components/Shared/Style/colors';
import { L } from '../../../lib/i18n';
import Dialog from '../../../components/Dialog/Dialog';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useHistory } from 'react-router-dom';
import useSaveNewBookingResource from '../hooks/useSaveNewBookingResource';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { Icon } from '../../../components/Icon/Icon';
import H2 from '../../../components/typography/H2';
import DropdownButton from '../../../components/Buttons/DropdownButton';
import { useLocalStorage } from 'react-use';
import { Button } from '../../../components/Buttons/Button';

const ValueWrapper = styled.div({
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
});

export const BookingSchema = z.object({
    name: z.string().min(1),
    booking_resource_slot_type: z.literal(1).or(z.literal(2)),
});

const ButtonWrapper = styled.div({
    display: 'grid',
    justifyContent: 'end',
    marginTop: '1rem',
});

type Props = {
    type: 'draft' | 'published';
};

const NewBookingsResourceForm = ({ type }: Props) => {
    const { mutate: saveNewResource } = useSaveNewBookingResource();

    const history = useHistory();

    const { register, handleSubmit, control, reset } = useForm<z.infer<typeof BookingSchema>>({
        resolver: zodResolver(BookingSchema),
        defaultValues: {
            name: '',
            booking_resource_slot_type: 1,
        },
    });

    const submit = (data: z.infer<typeof BookingSchema>) => {
        return saveNewResource(
            {
                name: data.name,
                booking_resource_slot_type: { id: data.booking_resource_slot_type },
                published: false,
            },
            {
                onSuccess: (data) => {
                    if (data?.id) {
                        history.push(`/booking/${data.id}`);
                    } else {
                        history.push('/booking/draft');
                    }
                },
            }
        );
    };

    return (
        <>
            <Dialog
                open={true}
                content={{
                    style: {
                        width: '400px',
                        padding: '2rem',
                        backgroundColor: colors.white,
                    },
                }}
                onOpenChange={(open) => {
                    if (!open) type === 'published' ? history.push('/booking') : history.push('/booking/draft');
                }}
            >
                <form>
                    <H2>
                        {L('new')} {L('booking_resource')}
                    </H2>
                    <Label title={L('booking_resource_name')}>
                        <Input
                            {...register('name', {
                                required: true,
                            })}
                        />
                    </Label>
                    <Label title={L('booking_resource_type')}>
                        <Controller
                            name="booking_resource_slot_type"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <Dropdown
                                        {...field}
                                        options={[
                                            {
                                                label: (
                                                    <ValueWrapper>
                                                        <span>
                                                            <Icon.calendarDay />
                                                        </span>
                                                        {L('day')}
                                                    </ValueWrapper>
                                                ),
                                                value: 1,
                                            },
                                            {
                                                label: (
                                                    <ValueWrapper>
                                                        <span>
                                                            <Icon.clock />
                                                        </span>
                                                        {L('slot')}
                                                    </ValueWrapper>
                                                ),
                                                value: 2,
                                            },
                                        ]}
                                    />
                                );
                            }}
                        />
                    </Label>
                    <ButtonWrapper>
                        <Button variant="filledPrimary" onClick={handleSubmit((formValues) => submit(formValues))}>
                            {L('save_to_draft')}
                        </Button>
                    </ButtonWrapper>
                </form>
            </Dialog>
        </>
    );
};

export default NewBookingsResourceForm;
