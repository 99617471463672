import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import { alertKeys, useGetAlertById } from '../hooks/alertsQuery';
import EditAlert from './EditAlert';
import useRemoveAlert from './useRemoveAlert';

const TopAreaWrapper = styled.div({
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
});

const EditAlertPage = () => {
    const { id } = useParams<{ id: string }>();
    const { data: alert } = useGetAlertById(+id);
    const history = useHistory();

    const [confirmed, setConfiremd] = useState(false);
    const client = useQueryClient();

    const { mutate: removeAlert } = useRemoveAlert();

    const handleRemove = () => {
        if (!id) return;
        removeAlert(
            { alertId: +id },
            {
                onSuccess: () => {
                    client.invalidateQueries(alertKeys.all);
                    history.push('/alerts');
                },
            }
        );
    };

    useEffect(() => {
        if (!confirmed) return;

        const confirmTimer = setTimeout(() => setConfiremd(false), 4000);

        return () => {
            clearTimeout(confirmTimer);
        };
    }, [confirmed]);

    return (
        <CardWithDivider
            topArea={
                <TopAreaWrapper>
                    <CardTitle>{L('edit_alert')}</CardTitle>
                    {confirmed ? (
                        <Button onClick={handleRemove} variant="delete">
                            {L('delete')}
                        </Button>
                    ) : (
                        <Button onClick={() => setConfiremd(true)} variant="removeConfirm">
                            {L('delete')}
                        </Button>
                    )}
                </TopAreaWrapper>
            }
            mainArea={<>{alert && <EditAlert alert={alert} />}</>}
        />
    );
};

export default EditAlertPage;
