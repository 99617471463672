import styled from '@emotion/styled';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '../../../../components/Buttons/Button';
import { DisplayField } from '../../../../components/DisplayField/DisplayField';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import { Loading } from '../../../../components/Loading/Loading';
import { style } from '../../../../components/Shared/Style';
import { Avatar } from '@ourliving/ourliving-ui';
import CardTitle from '../../../../components/typography/CardTitle';
import { useGetSignedUrlById } from '../../../../hooks/useGetSignedUrlById';
import { L } from '../../../../lib/i18n';
import { DatetimeWorklog } from '../../../tickets/components/Datetime/DateTimeWorklog';
import { useGetMemberById } from '../../hooks/useGetMemberList';
import useInviteMember from '../../hooks/useInviteMember';
import InviteMailStatus from './InviteMailStatus';

const GridArea = styled.div({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '2rem',
});

const ButtonContainer = styled.div({
    width: 'auto',
    paddingTop: style.margin.s,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
});

const MemberExtraInformationPage = () => {
    const { id } = useParams<{ id: string }>();
    const { data: member } = useGetMemberById(id);

    const { data: avatarUrl } = useGetSignedUrlById(member?.profile_image_data?.original?.id);

    if (!member) return <Loading />;

    return (
        <CardWithDivider
            topArea={<CardTitle>{L('boardmember_extra_info')}</CardTitle>}
            mainArea={
                <>
                    <Avatar src={avatarUrl} size="large" readonly={true} name={member.name} />
                    <GridArea>
                        <DisplayField title="Namn">{member.name}</DisplayField>
                        <DisplayField title="Tel">{member.phone_number}</DisplayField>
                        <DisplayField title="Skapad">{<DatetimeWorklog date={member.created_at || ''} />}</DisplayField>
                        <DisplayField title="Senaste login">
                            {<DatetimeWorklog date={member.last_login_at || ''} />}
                        </DisplayField>
                        <DisplayField title="Inbjudan skickad">
                            {<DatetimeWorklog date={member.invited_at || ''} />}
                        </DisplayField>
                        {member.account_confirmed_at ? (
                            <DisplayField title="Konto bekräftat">
                                <DatetimeWorklog date={member.account_confirmed_at || ''} />
                            </DisplayField>
                        ) : (
                            <InviteMailStatus memberId={member.id} />
                        )}
                    </GridArea>
                </>
            }
        />
    );
};

export default MemberExtraInformationPage;
