import ProjectsApi from '@app/api/public/ProjectsApi';
import { useMutation, useQueryClient } from 'react-query';
import { projectImagesKey } from './useGetProjectImages';

type SaveDocumentVariables = {
    name: string;
    file: File;
    sort?: number;
};

const useSaveProjectImages = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (variables: SaveDocumentVariables) => {
            return ProjectsApi.saveDocument(variables.name, variables.file, variables.sort);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(projectImagesKey.all);
            },
        }
    );
};

export { useSaveProjectImages };
