import React, { useState } from 'react';
import { L } from '../../../../lib/i18n';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle, { CardSpan } from '../../../../components/typography/CardTitle';
import { useLocalStorage } from 'react-use';
import { boardNewsQueryKeys, useGetContentNewsList } from '../hooks/boardNewsQueries';
import { escapeRegex } from '../../../../lib/regex';
import {
    CardGrid,
    Card,
    CardImage,
    CardTimestamp,
    CardGridTitle,
    CardDescription,
} from '../../../../components/CardGrid/CardGrid';
import useGetSignedUrlById from '../../../../hooks/useGetSignedUrlById';
import { medium } from '../../../../lib/imageSize';
import { Link } from 'react-router-dom';
import { Button } from '../../../../components/Buttons/Button';
import GlobalFilter from '../../../../components/Table/filterComponents/GlobalFilter';
import ToggleVIew from '../../../../components/ToggleView/ToggleVIew';
import styled from '@emotion/styled';
import { style } from '../../../../components/Shared/Style';
import usePublishNews from '../hooks/usePublishNews';
import { useQueryClient } from 'react-query';
import { ContentNewsItem } from '@app/api/models/ContentNews/ContentNewsModels';
import toast from 'react-hot-toast';
import { tableHeader, title, tooltip } from '../../../../components/Shared/Style/typography';
import { formatDateIsoDayjs } from '@app/shared/lib/formatting';
import { convert } from 'html-to-text';
import DropdownButton from '../../../../components/Buttons/DropdownButton';
import Feed from '../../../../components/Feed/Feed';
import useQueryParams from '../../../../hooks/useQueryParams';
import { Pagination } from '../../../notifications/components/Pagination';
import BoardContentNewsApi from '@app/api/public/BoardContentNewsApi';
import EmptyState from '../../../../components/EmptyState/EmptyState';
import { CardGridImage } from '../../../../components/CardGrid/CardImage';

const templateColumns = '1fr 0.4fr 0.4fr minmax(190px, max-content)' as const;

const Headers = styled.div(() => ({
    margin: '0px',
    display: 'grid',
    gridTemplateColumns: templateColumns,
    columnGap: '2rem',
    alignItems: 'baseline',
    padding: '0px 0.5rem',
}));

const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '24px',
    flexDirection: 'row',
    gap: '8px',
});

const FeedListItem = styled(Link)({
    borderBottom: `1px solid ${style.colors.divider}`,
    padding: '1rem 0.5rem',
    display: 'grid',
    gap: '2rem',
    alignItems: 'center',
    gridTemplateColumns: templateColumns,
    '&:first-of-type': {
        borderTop: `1px solid ${style.colors.divider}`,
    },
});

const StyledDropdownButton = styled(DropdownButton.Button)({
    justifySelf: 'end',
});

const ListTitle = styled.span({
    ...title,
});

const ListTimeStamp = styled.span({
    ...tooltip,
});

const HeaderText = styled.span({
    ...tableHeader,
});

const StyledFeed = styled(Feed)({
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fill, minmax(300px, 1fr) )',
    gap: '2rem',
});

const NewsDraftListpage = () => {
    const queryClient = useQueryClient();
    const [view, setView] = useLocalStorage<'list' | 'card'>('newsView', 'list');
    const [localSubmitButtonState, setSubmitButtonState] = useLocalStorage<'publish' | 'publish_and_notify'>(
        'boardNewsViewSubmitState',
        'publish_and_notify'
    );
    const submitButtonState = localSubmitButtonState || 'publish_and_notify';
    const { getQueryString, updateQueryString } = useQueryParams();
    const page = getQueryString('page', '');
    const { data: newsList, status: newsStatus } = useGetContentNewsList('draft', Number(page));
    const [filter, setFilter] = useState('');

    const { mutate: publishNews } = usePublishNews();

    if (newsStatus === 'loading') return <CardTitle>{L(`all_news_draft`)}</CardTitle>;

    if (!newsList) return <div>{L('error')} kunde inte hämta nyhetslistan</div>;

    const searchRegexp = new RegExp(`(${escapeRegex(filter)})`, 'gmi');

    const filteredList = newsList.items.filter((item) => {
        if (!filter) return true;
        const title = item.title.match(searchRegexp);
        const description = item.description.match(searchRegexp);
        return title || description;
    });

    const onPublish = (news: ContentNewsItem, notify = false) => {
        if (!news.published_from) return toast.error(L('error_no_publiced_from'));
        publishNews(
            { id: news.id, notify },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(boardNewsQueryKeys.lists());
                },
            }
        );
    };

    const NewsList =
        view === 'list' ? (
            !filteredList.length ? (
                <EmptyState>
                    <EmptyState.Title>
                        {L('no_search_title')} &quot;{filter}&quot;
                    </EmptyState.Title>
                    <EmptyState.Description>{L('no_search_description')}</EmptyState.Description>
                </EmptyState>
            ) : (
                <div>
                    <Headers>
                        <HeaderText>{L('title')}</HeaderText>
                        <HeaderText>{L('publish_from')}</HeaderText>
                        <HeaderText>{L('publish_to')}</HeaderText>
                        <Button style={{ visibility: 'hidden' }}>{L('publish')}</Button>
                    </Headers>

                    {filteredList &&
                        filteredList.map((news) => (
                            <FeedListItem key={news.id} to={`/board-news/${news.id}`}>
                                <ListTitle>{news.title}</ListTitle>
                                <ListTimeStamp>{formatDateIsoDayjs(news.published_from)}</ListTimeStamp>
                                <ListTimeStamp>
                                    {news.published_to && formatDateIsoDayjs(news.published_to)}
                                </ListTimeStamp>
                                <DropdownButton
                                    optionId={submitButtonState}
                                    setOptionId={(value) => setSubmitButtonState(value)}
                                    options={[
                                        {
                                            id: 'publish',
                                            button: (
                                                <StyledDropdownButton onClick={() => onPublish(news)}>
                                                    {L('publish')}
                                                </StyledDropdownButton>
                                            ),
                                            dropdownItem: (
                                                <DropdownButton.DropdownItem>
                                                    {L('publish')}
                                                </DropdownButton.DropdownItem>
                                            ),
                                        },
                                        {
                                            id: 'publish_and_notify',
                                            button: (
                                                <StyledDropdownButton onClick={() => onPublish(news, true)}>
                                                    {L('publish_and_notify')}
                                                </StyledDropdownButton>
                                            ),
                                            dropdownItem: (
                                                <DropdownButton.DropdownItem>
                                                    {L('publish_and_notify')}
                                                </DropdownButton.DropdownItem>
                                            ),
                                        },
                                    ]}
                                />
                            </FeedListItem>
                        ))}
                    <div style={{ paddingTop: '1rem' }}>
                        <Pagination
                            page={newsList}
                            onChangePage={(newPage) => updateQueryString('page', String(newPage))}
                        />
                    </div>
                </div>
            )
        ) : !filteredList.length ? (
            <EmptyState>
                <EmptyState.Title>
                    {L('no_search_title')} &quot;{filter}&quot;
                </EmptyState.Title>
                <EmptyState.Description>{L('no_search_description')}</EmptyState.Description>
            </EmptyState>
        ) : (
            <StyledFeed
                feedFilter={() => true}
                threshold={500}
                cacheKey={boardNewsQueryKeys.infiniteList('draft', Number(page))}
                asyncFn={({ pageParam }) => BoardContentNewsApi.list('draft', pageParam)}
                emptyResponse={L('no_feed_items')}
            >
                {(item: ContentNewsItem) => {
                    return (
                        <Link key={item.id} to={`/board-news/${item.id}`}>
                            <Card>
                                <CardGridImage originalId={item.cover_image_data?.original?.id || ''} />
                                <CardTimestamp date={item.published_from} />
                                <CardGridTitle>{item.title}</CardGridTitle>
                                <CardDescription>{convert(item.description)}</CardDescription>
                            </Card>
                        </Link>
                    );
                }}
            </StyledFeed>
        );

    return (
        <CardWithDivider
            topArea={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <CardTitle>
                        {L(`all_news_draft`)} <CardSpan>({newsList.totalItems})</CardSpan>
                    </CardTitle>
                    <Link to={`/board-news/new`}>
                        <Button>{L('news_content')}</Button>
                    </Link>
                </div>
            }
            mainArea={
                <>
                    {!newsList.totalItems ? (
                        <EmptyState>
                            <EmptyState.Title>{L('no_news_draft_title')}</EmptyState.Title>
                            <EmptyState.Description>{L('no_news_draft_description')}</EmptyState.Description>
                            <Link to={`/board-news`}>
                                <EmptyState.Button variant="filledPrimary">
                                    {L('no_news_draft_button')}
                                </EmptyState.Button>
                            </Link>
                        </EmptyState>
                    ) : (
                        <>
                            <Wrapper>
                                <GlobalFilter placeholder="Sök" filter={filter} setFilter={setFilter} />
                                <ToggleVIew view={view || 'list'} setView={setView} />
                            </Wrapper>
                            {NewsList}
                        </>
                    )}
                </>
            }
        />
    );
};

export default NewsDraftListpage;
