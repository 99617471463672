import * as React from 'react';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';
import Hamburger from '../Icon/menu-8.svg';
import HelpIcon from '../Help/HelpIcon';
import useGetProjectInfo from '../../hooks/useGetProjectInfo';
import { AvatarMenu } from '../Menu/AvatarMenu';
import { Avatar } from '@ourliving/ourliving-ui';
import useGetProfile from '../../hooks/useGetProfile';
import useGetSignedUrlById from '../../hooks/useGetSignedUrlById';
import { small } from '../../lib/imageSize';
import { ProfilePage } from '../../areas/profile/ProfilePage';
import NotificationBell from '../../areas/notifications/NotificationBell';
import { useHistory } from 'react-router-dom';
interface BaseCssProps {
    children?: any;
    theme?: any;
}

interface MyProps extends BaseCssProps {
    background?: string;
    title?: string;
}

const Container = styled.div<MyProps>((props) => ({
    background: style.colors.pageBackground,
    paddingBottom: '1rem',
    display: 'flex',
    alignItems: 'center',
    gridArea: 'header',
    justifyContent: 'end',
    gap: '1rem',
}));

const Icon = styled(Hamburger)({
    cursor: 'pointer',
    height: '25px',
    width: '25px',
    '& path': {
        stroke: 'white',
    },
    ['@media screen and (min-width: 1024px)']: {
        display: 'none',
    },
});

const Project = styled.span({
    color: style.colors.textColor1,
    fontSize: '1rem',
    textTransform: 'uppercase',
});

const FlexWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
});
export const Header = ({ ...rest }) => {
    const { data } = useGetProjectInfo();
    const profileQuery = useGetProfile();
    const { data: signedUrl } = useGetSignedUrlById(
        profileQuery?.data?.profile_image_data?.document_data?.original?.id,
        small
    );
    const history = useHistory();

    return (
        <Container {...rest}>
            <Project>{data?.name}</Project>
            <FlexWrapper>
                <HelpIcon />
                <AvatarMenu
                    avatar={
                        <Avatar
                            src={signedUrl}
                            size="small"
                            readonly={true}
                            name={profileQuery.data?.name || profileQuery.data?.email}
                        />
                    }
                >
                    <ProfilePage />
                </AvatarMenu>
            </FlexWrapper>
            <NotificationBell
                color={'primary'}
                to={{ pathname: '/notificationsCenter', state: history.location.pathname }}
            />
        </Container>
    );
};
