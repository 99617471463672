import { useQuery } from 'react-query';
import Contracts from '@app/api/public/ContractsApi';

export type StatusOption = {
    id: number;
    name: string;
    color_code?: string | undefined;
};

export const getContractKeys = {
    all: [{ scope: 'contract' }] as const,
    list: () => [{ ...getContractKeys.all[0], entity: 'list' }] as const,
    listArchived: () => [{ ...getContractKeys.all[0], entity: 'listArchived' }] as const,
    types: () => [{ ...getContractKeys.all[0], entitiy: 'types' }] as const,
    documentLists: () => [{ ...getContractKeys.all[0], entity: 'document' }] as const,
    documentsList: (id: string | undefined) => [{ ...getContractKeys.documentLists()[0], id }] as const,
    getByIds: () => [{ ...getContractKeys.all[0], entity: 'getbyid' }] as const,
    getById: (id: number) => [{ ...getContractKeys.getByIds()[0], id }] as const,
};

export function useGetContractsArchived() {
    return useQuery(getContractKeys.listArchived(), () => Contracts.list({ isArchived: true }));
}

export function useGetContracts() {
    return useQuery(getContractKeys.list(), () => Contracts.list({ isArchived: false }));
}

export function useGetTypes() {
    return useQuery(getContractKeys.types(), () => Contracts.listTypes(), {
        select: (data) =>
            data.map((contract) => ({ id: +contract.id, name: contract.name, color_code: contract.color_code })),
    });
}

export function useGetContractById(id: number) {
    return useQuery(getContractKeys.getById(id), () => (id ? Contracts.id(id) : null));
}
