import React, { ReactNode } from 'react';
import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { colors } from '../Shared/Style/colors';
import Divider from '../thread/divider/Divider';

const Container = styled.div({
    backgroundColor: 'white',
    borderRadius: '4px',
    border: `1px solid ${colors.divider}`,
    boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.1)',
});

const TopArea = styled.section({
    padding: '22px 32px 22px 32px',
});
const MainArea = styled.main({
    padding: '22px 32px 22px 32px',
});

const MainAreaMobile = styled.main({
    padding: '22px 0 22px 0',
    '@media screen and (min-width: 700px)': {
        padding: '22px 32px 22px 32px',
    },
});

type Props = {
    mainArea: ReactNode;
    topArea?: ReactNode;
    mobileNoPadding?: boolean;
} & HTMLAttributes<HTMLDivElement>;
const CardWithDivider = ({ mainArea, topArea, mobileNoPadding, ...rest }: Props) => {
    return (
        <Container {...rest}>
            {topArea && (
                <>
                    <TopArea className="top">{topArea}</TopArea>
                    <Divider width={100} margin={'0px'} />
                </>
            )}
            {mobileNoPadding ? (
                <MainAreaMobile className="main">{mainArea}</MainAreaMobile>
            ) : (
                <MainArea className="main">{mainArea}</MainArea>
            )}
        </Container>
    );
};

export default CardWithDivider;
