import React from 'react';
import { PageBackground } from '../../components/Layout/PageBackground';
import { margin } from '../../components/Shared/Style/margin';
import PushNotificationsSettings from './Components/PushNotificationsSettings';
import { desktop } from '../../components/MediaQuery/breakpoints';
import styled from '@emotion/styled';
import H1 from '../../components/typography/H1';
import { L } from '../../lib/i18n';

const StyledPage = styled(PageBackground)({
    minHeight: '100vh',
    width: 'auto',
    height: 'auto',
    display: 'block',
});

const ContentWrapper = styled.div({
    [`@media screen and (min-width: ${desktop}px)`]: { padding: 0 },
    padding: margin.m,
    width: '100%',
    height: '100%',
});

const StyledH1 = styled(H1)({
    [`@media screen and (min-width: ${desktop}px)`]: {
        margin: '0px',
    },
    placeSelf: 'start',
});

export const Notifications = () => {
    return (
        <StyledPage>
            <ContentWrapper>
                <StyledH1>{L('notifications')}</StyledH1>
                <PushNotificationsSettings />
            </ContentWrapper>
        </StyledPage>
    );
};
