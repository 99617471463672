import './lib/rpc';
import 'regenerator-runtime/runtime';
import ReactDOM from 'react-dom/client';
import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider, useQueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Router } from 'react-router-dom';
import { Routes } from './navigation';
import { history } from './lib/routing';
import { ThemeProvider } from 'emotion-theming';
import { style } from './components/Shared/Style';
import { Toaster } from 'react-hot-toast';
import { Global, css } from '@emotion/core';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { ThemeType } from '@app/api/models/Theme';
import { colors } from './components/Shared/Style/colors';
import useGetTheme from './components/Shared/hooks/useGetTheme';
import { useI18Theme } from './lib/useI18Theme';
import io from 'socket.io-client';
import { CompatRouter } from 'react-router-dom-v5-compat';
import * as Sentry from '@sentry/react';
import sentryConfig from './sentry/sentryConfig';

dayjs.extend(timezone);
dayjs.tz.setDefault(dayjs.tz.guess());

export const socket = io('/', {
    withCredentials: true,
});

const App = () => {
    const config = sentryConfig();
    Sentry.init({
        dsn: config.SENTRY_DSN,
        environment: config.SENTRY_ENVIRONMENT,
        tracesSampleRate: 0.1,
    });

    useI18Theme();

    const queryClient = useQueryClient();

    const { data: themeData } = useGetTheme();
    const [theme, setTheme] = useState<ThemeType>({
        colors: style.colors,
    });

    useEffect(() => {
        if (!themeData) return;
        setTheme({
            colors: { ...style.colors, ...themeData.colors },
            globalCss: themeData.globalCss,
        });
    }, [themeData]);

    useEffect(() => {
        socket.connect();
        const invalidateQueries = (queries: string[]) => {
            console.log('queries:', queries);
            queries.forEach((queryKey) => {
                queryClient.invalidateQueries({ queryKey });
            });
        };
        socket.on('invalidate_queries', invalidateQueries);

        return () => {
            socket.off('invalidate_queries', invalidateQueries);
        };
    }, [socket.connected]);

    return (
        <>
            <Global
                styles={css`
                    @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
                    body {
                        margin: 0;
                        background-color: ${colors.pageBackground};
                        font-family: DM sans;
                        box-sizing: border-box;
                        min-width: 320px;
                    }
                    body * {
                        box-sizing: inherit;
                        font-family: inherit;
                        font-size: inherit;
                    }
                    ul {
                        padding: 0;
                        margin: 0;
                    }
                    li {
                        list-style-type: none;
                    }

                    a {
                        text-decoration: none;
                        color: inherit;
                    }
                `}
            />
            <Global
                styles={css`
                    ${theme?.globalCss}
                `}
            />
            <ThemeProvider theme={theme}>
                <div>
                    <Toaster />
                </div>
                <Routes />
            </ThemeProvider>
        </>
    );
};

const queryClient = new QueryClient({});

const AppProviders = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <Router history={history}>
                <CompatRouter>
                    <App />
                </CompatRouter>
            </Router>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
};
const startApp = () => {
    const app = document.createElement('div');
    document.title = 'Community';
    document.body.appendChild(app);
    const meta = document.createElement('meta');
    meta.name = 'viewport';

    meta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
    document.getElementsByTagName('head')[0].appendChild(meta);

    ReactDOM.createRoot(app!).render(<AppProviders />);
};

startApp();

// prepareI18n().then(() => {
//     const app = document.createElement('div');
//     document.title = 'Community';
//     document.body.appendChild(app);
//     const meta = document.createElement('meta');
//     meta.name = 'viewport';

//     meta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
//     document.getElementsByTagName('head')[0].appendChild(meta);

//     ReactDOM.render(<AppProviders />, app);
// });
