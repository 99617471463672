import React from 'react';
import { MemberDetail } from '@app/api/models/Members';
import styled from '@emotion/styled';
import { colors } from '../../../components/Shared/Style/colors';
import { StyleColumn } from '../../../types/ColumnType';
import { L } from '../../../lib/i18n';

const Span = styled.span({
    textAlign: 'left',
});

export const COLUMNS: StyleColumn<MemberDetail>[] = [
    {
        Header: 'Email',
        accessor: 'email',
        width: 200,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const member = row.original;
            return <Span>{member.email}</Span>;
        },
    },
    {
        Header: 'Namn',
        accessor: 'name',
        width: 200,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const member = row.original;
            return member.name ? (
                <Span>{member.name}</Span>
            ) : (
                <Span style={{ color: colors.statusError }}>{L('name_missing')}</Span>
            );
        },
    },
];
