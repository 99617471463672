import React from 'react';
import styled from '../lib/styled';

const List = styled.ul({
    padding: '0 0.5rem',
    width: '100%',
    listStyle: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem 1.5rem',
});

type Props = {
    className?: string;
};
const TabList: React.FC<Props> = ({ children, className }) => {
    return <List className={className}>{children}</List>;
};

export default TabList;
