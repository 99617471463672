import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React from 'react';

const Time = styled.time(({ theme }) => ({
    fontSize: '14px',
    color: theme.colors.black80,
}));

type Props = {
    date: dayjs.ConfigType;
};

const FormatFeedDate: React.FC<Props> = ({ date }) => {
    if (dayjs(date).isSame(dayjs(), 'day')) {
        return <Time>{dayjs(date).tz().fromNow()}</Time>;
    }
    if (dayjs(date).isSame(dayjs(), 'year')) {
        return <Time>{dayjs(date).tz().format('D MMMM')} </Time>;
    }
    return <Time>{dayjs(date).tz().format('D MMMM YYYY')} </Time>;
};

export default FormatFeedDate;
