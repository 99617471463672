import React from 'react';
import styled from '@emotion/styled';
import useGetDocuments from '../../hooks/useGetDocuments';
import DocumentList from '../DocumentList';
import PreviewFooter from './PreviewFooter';
import PreviewNavbar from './PreviewNavbar';
import PreviewText from './PreviewText';
import PreviewHero from './PreviewHero';
import PreviewVerticalLine from './PreviewVerticalLine';
import useGetPageListItem from '../../hooks/useGetPageListItem';
import { useTheme } from 'emotion-theming';
import { PreviewThemeType } from '../../../../areas/public-site/theme/ThemePage';

const Container = styled.div(({ theme }: { theme: PreviewThemeType }) => {
    return {
        height: '700px',
        overflowY: 'scroll',
        backgroundColor: theme.colors.pageBackground,
    };
});

const GridContainer = styled.div({
    display: 'grid',
    gridTemplateColumns: '3fr 0.1fr 0.9fr',
    gridTemplateAreas: `
    "Main VerticalLine Documents"
    `,
});

const PreviewPage = () => {
    const { data: documents } = useGetDocuments();
    const { data: pageListItem } = useGetPageListItem();
    const theme = useTheme<PreviewThemeType>();
    return (
        <Container theme={theme}>
            <PreviewNavbar />
            {pageListItem && <PreviewHero pageListItem={pageListItem} />}
            {documents && documents.length > 0 ? (
                <>
                    <GridContainer>
                        <PreviewText />
                        <PreviewVerticalLine />
                        <DocumentList />
                    </GridContainer>
                </>
            ) : (
                <PreviewText />
            )}
            <PreviewFooter />
        </Container>
    );
};

export default PreviewPage;
