import React from 'react';
import styled from '@emotion/styled';
import DocumentListById from '../DocumentListById';
import PreviewFooter from './PreviewFooter';
import PreviewNavbar from './PreviewNavbar';
import PreviewHero from './PreviewHero';
import { useTheme } from 'emotion-theming';
import { PreviewThemeType } from '../../../../areas/public-site/theme/ThemePage';
import useGetByPageType from '../../hooks/useGetByPageType';
import PreviewContactText from './PreviewContactText';
import ContactForm from './ContactForm';
import useGetDocumentsById from '../../../../areas/public-site/hooks/useGetDocumentsById';
import { colors } from '../../../../areas/public-site/lib/publicColors';

const Container = styled.div(({ theme }: { theme: PreviewThemeType }) => {
    return {
        height: '700px',
        overflowY: 'scroll',
        backgroundColor: theme.colors.pageBackground,
    };
});

const GridContainer = styled.div({
    display: 'grid',
    gridTemplateAreas: `
    "Main ContactForm"
    `,
});
const DocumentWrapper = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2rem',
});
const HorizontalLine = styled.hr({
    color: colors.textColor1,
    display: 'block',
    width: '90%',
    margin: 'auto',
});

const PreviewPage = ({ id }: { id: number }) => {
    const { data: documents } = useGetDocumentsById(id);
    const { data: contactPageData } = useGetByPageType('contact_page');

    console.log(documents?.length);

    const theme = useTheme<PreviewThemeType>();
    return (
        <Container theme={theme}>
            <PreviewNavbar />
            {contactPageData && <PreviewHero pageListItem={contactPageData} />}
            {documents && documents.length > 0 ? (
                <>
                    <GridContainer>
                        <PreviewContactText />
                        <ContactForm />
                    </GridContainer>
                    <HorizontalLine />
                    <DocumentWrapper>
                        <DocumentListById id={id} />
                    </DocumentWrapper>
                </>
            ) : (
                <>
                    <GridContainer>
                        <PreviewContactText />
                        <ContactForm />
                    </GridContainer>
                </>
            )}
            <PreviewFooter />
        </Container>
    );
};

export default PreviewPage;
