import React, { HTMLAttributes } from 'react';
import useGetSignedUrlById from '../../hooks/useGetSignedUrlById';
import DocumentDropdown from './DocumentDropdown/DocumentDropdown';
import mime from 'mime';
import DocumentDropdownWithGroup from './DocumentDropdown/DocumentDropdownWithGroup';

type props = {
    documentDetail: {
        originalId: string;
        filename: string;
        name: string;
        groupId?: number;
    };
    deleteDocument?: () => void;
    editDocumentName?: (name: string) => void;
    editDocumentGroup?: (groupId: number) => void;
} & HTMLAttributes<HTMLDivElement>;
const Document = ({ documentDetail: { originalId, filename, name, groupId }, editDocumentGroup, ...rest }: props) => {
    const { data: url } = useGetSignedUrlById(originalId);
    const fileType = mime.getType(filename);
    return groupId ? (
        <DocumentDropdownWithGroup
            name={name}
            url={url}
            fileType={fileType || ''}
            groupId={groupId}
            editDocumentGroup={editDocumentGroup}
            {...rest}
        />
    ) : (
        <DocumentDropdown name={name} url={url} fileType={fileType || ''} {...rest} />
    );
};

export default Document;
