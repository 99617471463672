import { useQueryClient, useMutation } from 'react-query';
import MaintenancesApi from '@app/api/public/MaintenancesApi';
import { useParams } from 'react-router';

type Variables = { documentId: number; queryId: string };

export const deleteMaintenanceDocument = async (documentId: number, maintenanceId: number) => {
    return await MaintenancesApi.deleteDocumentFromMaintenance(documentId, maintenanceId);
};

const useDeleteMaintenanceDocument = () => {
    const queryClient = useQueryClient();
    const params = useParams<{ id: string }>();
    return useMutation((variables: Variables) => deleteMaintenanceDocument(+variables.documentId, +params.id), {
        onSuccess: (data, variables) => {
            queryClient.setQueryData(['getMaintenanceDocumentsList', variables.queryId], [data]);
        },
        onSettled: (_data, _error, variables) => {
            queryClient.invalidateQueries(['getMaintenanceDocumentsList', variables.queryId]);
            queryClient.invalidateQueries(['getDocumentsList']);
        },
    });
};

export default useDeleteMaintenanceDocument;
