import * as React from 'react';
import { useModal } from '../../../../components/Modal/Context/ModalContext';
import Modal from '../../../../components/Modal/Modal';
import NewPropertyMemberForm from '../components/NewPropertyMemberForm';
import TableQuery from './TableQuery';

export const PropertiesUserListPage = () => {
    return (
        <>
            <TableQuery />
        </>
    );
};

export default PropertiesUserListPage;
