import styled from '@emotion/styled';
import React from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import { Button } from '../../../../components/Buttons/Button';
import Input from '../../../../components/Forms/Input';
import Label from '../../../../components/Forms/Label';
import { Loading } from '../../../../components/Loading/Loading';
import { L } from '../../../../lib/i18n';
import useGetMemberList from '../../../members/hooks/useGetMemberList';
import useGetProperties from '../../../members/hooks/useGetProperties';
import useSaveNewMember from '../../hooks/useSaveNewMember';
import DropdownButton from '../../../../components/Buttons/DropdownButton';
import { useLocalStorage } from 'react-use';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import ErrorAndInputWrapper from '../../../../components/Forms/ErrorAndInputWrapper';
import { useGetUserNameByEmail } from '../../hooks/propertyQueries';

const ButtonWrapper = styled.div({
    padding: '1.5rem 0px 0px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    bottom: '1rem',
});

const NewPropertyMemberForm = ({ handleCloseModal }: { handleCloseModal: () => void }) => {
    const { data: properties } = useGetProperties();
    const { mutate: saveNewMember, isLoading } = useSaveNewMember();
    const { id: propertyId } = useParams<{ id: string }>();

    const [localSubmitButtonState, setSubmitButtonState] = useLocalStorage<'save' | 'save_and_invite'>(
        'newPropertyMember',
        'save_and_invite'
    );
    const submitButtonState = localSubmitButtonState || 'save_and_invite';

    const saveNewMemberSchema = z.object({
        email: z.string().email({ message: L('provide_valid_email') }),
        name: z.string().min(1, { message: L('required') }),
    });

    type SaveNewMemberEvent = { member: z.infer<typeof saveNewMemberSchema> } & { propertyId: number };

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        control,
    } = useForm({
        resolver: zodResolver(saveNewMemberSchema),
        values: {
            email: '',
            name: '',
        },
    });

    const { data: name, status: nameStatus } = useGetUserNameByEmail(watch('email'));

    React.useEffect(() => {
        if (!name?.name) return;
        setValue('name', name.name);
    }, [name?.name]);

    const submit = (data: SaveNewMemberEvent['member'], type: 'save' | 'save_and_invite') => {
        if (type === 'save') {
            return saveNewMember(
                {
                    member: {
                        ...data,
                        propertyId: Number(propertyId),
                    },
                },
                {
                    onSuccess: () => {
                        toast.success(L('saved_member'));
                        handleCloseModal();
                    },
                }
            );
        } else if (type === 'save_and_invite')
            return saveNewMember(
                {
                    member: {
                        ...data,
                        propertyId: Number(propertyId),
                    },
                    sendInviteMail: true,
                },
                {
                    onSuccess: () => {
                        toast.success(L('saved_member_and_invite_successful'));
                        handleCloseModal();
                    },
                }
            );
    };

    if (!properties) return <Loading />;

    const canEditName = () => {
        const email = watch('email');
        const emailIsValid = z.string().email().safeParse(email).success;
        const emailHasName = !!name?.name;

        return emailIsValid && !emailHasName;
    };

    const canSubmit = () => {
        const email = watch('email');
        const name = watch('name');
        return z.string().email().safeParse(email).success && !!name;
    };

    return (
        <>
            <ErrorAndInputWrapper errorMsg={errors.email?.message ?? ''}>
                <Label title={L('member_account') + '*'}>
                    <Controller
                        control={control}
                        name="email"
                        render={({ field }) => {
                            return (
                                <Input
                                    {...field}
                                    style={errors?.email ? { borderColor: '#ff4545' } : {}}
                                    type="email"
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setValue('name', '');
                                    }}
                                    required
                                />
                            );
                        }}
                    />
                </Label>
            </ErrorAndInputWrapper>

            <ErrorAndInputWrapper errorMsg={errors.name?.message ?? ''}>
                <Label title={L('name') + '*'}>
                    <Input
                        style={errors?.name ? { borderColor: '#ff4545' } : {}}
                        disabled={!canEditName()}
                        {...register('name', { required: true })}
                    />
                </Label>
            </ErrorAndInputWrapper>

            <ButtonWrapper>
                <Button type="button" variant="primary" onClick={() => handleCloseModal()}>
                    {L('cancel')}
                </Button>
                <DropdownButton
                    variant="filledPrimary"
                    setOptionId={(state) => {
                        setSubmitButtonState(state);
                    }}
                    isLoading={nameStatus === 'loading' || isLoading}
                    disabled={!canSubmit() || nameStatus !== 'success'}
                    optionId={submitButtonState}
                    options={[
                        {
                            dropdownItem: <DropdownButton.DropdownItem>{L('add_member')}</DropdownButton.DropdownItem>,
                            id: 'save',
                            button: (
                                <DropdownButton.Button
                                    onClick={handleSubmit((formValues) => submit({ ...formValues }, 'save'))}
                                >
                                    {L('add_member')}
                                </DropdownButton.Button>
                            ),
                        },
                        {
                            dropdownItem: (
                                <DropdownButton.DropdownItem>{L('save_member_and_invite')}</DropdownButton.DropdownItem>
                            ),
                            id: 'save_and_invite',
                            button: (
                                <DropdownButton.Button
                                    onClick={handleSubmit((formValues) => submit({ ...formValues }, 'save_and_invite'))}
                                >
                                    {L('save_member_and_invite')}
                                </DropdownButton.Button>
                            ),
                        },
                    ]}
                />
            </ButtonWrapper>
        </>
    );
};

export default NewPropertyMemberForm;
