import { ProjectInvoice } from '@app/api/models/Projects';
import styled from '@emotion/styled';
import React from 'react';
import Input from '../../../../components/Forms/Input';
import Label from '../../../../components/Forms/Label';
import { style } from '../../../../components/Shared/Style';
import { L } from '../../../../lib/i18n';
import { DatetimeWorklog } from '../../../tickets/components/Datetime/DateTimeWorklog';

const InvoiceContainer = styled.div({
    width: '100%',
    display: 'grid',
    alignSelf: 'center',
    paddingTop: style.margin.m,
    paddingBottom: style.margin.l,
    [`@media screen and (min-width: ${650}px)`]: {
        gridTemplateColumns: '1fr 1fr',
        gap: `2rem`,
    },
});

const GridWrapper = styled.div({
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gap: `10rem`,
});

const InvoiceForm = (p: { invoiceInformation: ProjectInvoice }) => {
    return (
        <GridWrapper>
            <InvoiceContainer>
                <Label title="Organisationsnamn">
                    <Input name="org_name" value={p.invoiceInformation.org_name || ''} disabled={true} />
                </Label>
                <Label title="Organisationsnummer">
                    <Input name="org_number" value={p.invoiceInformation.org_number || ''} disabled={true} />
                </Label>
                <Label title="Faktueringsadress">
                    <Input name="invoice_address" value={p.invoiceInformation.invoice_address || ''} disabled={true} />
                </Label>
                <Label title={L('telephone_number')}>
                    <Input name="phone_number" value={p.invoiceInformation.phone_number || ''} disabled={true} />
                </Label>
                <Label title="postnummer">
                    <Input name="invoice_zip" value={p.invoiceInformation.invoice_zip || ''} disabled={true} />
                </Label>
                <Label title="postort">
                    <Input name="invoice_city" value={p.invoiceInformation.invoice_city || ''} disabled={true} />
                </Label>
                <Label title="Elektronisk fakturering">
                    <Input name="invoice_info" value={p.invoiceInformation.invoice_info || ''} disabled={true} />
                </Label>
                <Label title="Abonnemangsvillkor">
                    <Input
                        name="subscription_info"
                        value={p.invoiceInformation.subscription_info || ''}
                        disabled={true}
                    />
                </Label>
                <Label title="startdatum">
                    <Input name="start_date" value={p.invoiceInformation.start_date || ''} disabled={true} />
                </Label>
                <Label title="Huvudförfallodatum">
                    <Input name="expire_date" value={p.invoiceInformation.expire_date || ''} disabled={true} />
                </Label>
            </InvoiceContainer>
            <div />
        </GridWrapper>
    );
};

export default InvoiceForm;
