import { useQueryClient, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import PublicSiteApi from '@app/api/public/PublicSiteApi';
import { PublicSiteInformationItem } from '@app/api/models/PublicSite';
import { getQueryPageListItems } from './useGetPageListItems';

type Variables = { newPage: Partial<PublicSiteInformationItem>; file?: File };

export const savePageListItem = async (newPage: Partial<PublicSiteInformationItem>, file?: File) => {
    return PublicSiteApi.save(newPage, false, file);
};

const useSavePageListItem = () => {
    const queryClient = useQueryClient();

    const history = useHistory();

    return useMutation((variables: Variables) => savePageListItem(variables.newPage, variables.file), {
        onSuccess: (newPage) => {
            const menuArr = queryClient.getQueryData<PublicSiteInformationItem[]>(getQueryPageListItems.all);
            if (menuArr) {
                queryClient.setQueryData(getQueryPageListItems.all, [...menuArr, newPage]);
            }
            history.push(`/public-site/${newPage.id}`);
        },
        onSettled: () => {
            queryClient.invalidateQueries(getQueryPageListItems.all);
        },
    });
};

export default useSavePageListItem;
