import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import BigTabListItem from '../../../components/BigTabItem/BigTabListItem';
import BigNavItem from '../../../components/BigTabItem/BigTabListItemNav';
import BigTabNav from '../../../components/BigTabItem/BigTabNav';
import PageTitle from '../../../components/typography/PageTitle';
import { L } from '../../../lib/i18n';
import CreateNewNewsPage from './CreateNewNewsPage/CreateNewNews';
import NewsDetailPage from './DetailPage/NewsDetailPage';
import { useGetBoardNewsById } from './hooks/boardNewsQueries';
import NewsDraftListpage from './ListPage/NewsDraftListPage';
import NewsPublishedListpage from './ListPage/NewsPublishedListPage';

const BoardNewsPage = () => {
    const { id } = useParams<{ id: string }>();
    const { data } = useGetBoardNewsById(+id);

    return (
        <>
            <PageTitle>{L('board_news_title')}</PageTitle>
            <BigTabNav>
                <BigNavItem exact to={'/board-news'}>
                    {L('all_published')}
                </BigNavItem>
                <BigNavItem exact to={'/board-news/draft'}>
                    {L('draft')}
                </BigNavItem>
                <Switch>
                    <Route exact path={'/board-news/draft'} />
                    <Route exact path={'/board-news/new'}>
                        <div style={{ borderRight: 'solid 1px gray' }} />
                        <BigTabListItem className="active">{L('new_news_page')}</BigTabListItem>
                    </Route>
                    <Route path={'/board-news/:id'}>
                        <div style={{ borderRight: 'solid 1px gray' }} />
                        <BigNavItem exact to={`/board-news/${data?.id}`}>
                            {data?.title}
                        </BigNavItem>
                    </Route>
                </Switch>
            </BigTabNav>
            <Switch>
                <Route path={'/board-news/new'}>
                    <CreateNewNewsPage />
                </Route>
                <Route exact path={'/board-news/draft'}>
                    <NewsDraftListpage />
                </Route>
                <Route path={'/board-news/:id'}>
                    <NewsDetailPage />
                </Route>
                <Route exact path={'/board-news'}>
                    <NewsPublishedListpage />
                </Route>
            </Switch>
        </>
    );
};

export default BoardNewsPage;
