import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';
import { L } from '../../../lib/i18n';

const AddNewServicePartnerButton: FC = ({ ...rest }) => {
    return (
        <Link {...rest} to={`/service-partner/add`}>
            <Button variant="selectedMenu" style={{ margin: '0px' }}>
                {L('add_service_partner')}
            </Button>
        </Link>
    );
};

export default AddNewServicePartnerButton;
