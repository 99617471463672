import React, { FC } from 'react';
import styled from '@emotion/styled';
import { PublicSiteInformationItem } from '@app/api/models/PublicSite';
import EditHeroImage from './EditHeroImage';
import { colors } from '../../Shared/Style/colors';

const HeroImage = styled.div((props: { url?: string | null }) => ({
    height: '30vh',
    width: '100%',
    display: 'flex',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url(${props.url})`,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    border: props.url ? '0px solid' : '1px solid',
    borderColor: colors.black80,
    borderRadius: '4px',
}));

type Props = {
    pageListItem: PublicSiteInformationItem;
    imgUrl: string;
};

const Hero: FC<Props> = ({ pageListItem, imgUrl }) => {
    return (
        <HeroImage url={imgUrl || (pageListItem && pageListItem.cover_image_data?.original?.id)}>
            <EditHeroImage />
        </HeroImage>
    );
};

export default Hero;
