import { observable } from 'mobx';
import UsersApi, { User } from '@app/api/public/UsersApi';
import { Project } from '@app/api/models/Projects';

class LoginStoreClass {
    @observable state: '' | 'error' | 'projects' | 'loading' | 'failed' | 'logged-in' = '';
    @observable user?: User;
    @observable projects: { id: string; name: string }[];
    @observable selectedProjectId: number;
    @observable isImpersonated: boolean;
    @observable isBoardMember: boolean;

    async verifyLogin() {
        this.setLoginState('loading');
        const user = await UsersApi.verifyLogin();
        if (user) {
            this.user = user;
            this.setLoginState('logged-in');
            this.isImpersonated = this.user.isImpersonated;
        } else {
            this.setLoginState('');
        }
    }

    async multiLogin(project: Project) {
        this.selectedProjectId = project.id;
        await UsersApi.setCookie(project);
        this.setLoginState('logged-in');
    }

    async login(username: string, password: string) {
        this.setLoginState('loading');
        this.user = undefined;
        try {
            const user = await UsersApi.login(username, password);

            if (!user) return this.setLoginState('failed');
            UsersApi.updateSignInAt(user.id);

            this.user = user;

            if (!user.hasMultipleProjects) return this.setLoginState('logged-in');

            if (user.hasMultipleProjects) {
                this.projects = await UsersApi.getProjects();
                return this.setLoginState('projects');
            }

            if (username || password) return this.setLoginState('failed');

            this.setLoginState('');
        } catch (ex) {
            console.error(ex);
            this.setLoginState('error');
        }
    }

    async setLoginState(loginState: LoginStoreClass['state']) {
        this.state = loginState;
    }
}

export const LoginStore = new LoginStoreClass();
