import React, { FC, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router';
import DocumentsArea from './DocumentsArea';
import ReactSelect from '../Dropdown/ReactSelect';
import FileDropZone from '../FileDropZone/FileDropZone';
import Label from '../Forms/Label';
import { Card } from '../Layout/Card';
import { style } from '../Shared/Style';
import H2 from '../typography/H2';
import { L } from '../../lib/i18n';
import DocumentItem from './DocumentDropdown/DocumentItem';
import { Button } from '../Buttons/Button';
import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useGetDocumentGroups from '../../areas/documents/DocumentsGroups/hooks/useGetSettingsDocumentGroups';

const StyledCard = styled(Card)({
    display: 'grid',
    backgroundColor: style.colors.white,
    alignContent: 'start',
    padding: '0',
});

const StyledH2 = styled(H2)({
    fontSize: '1rem',
    margin: '0.5rem 0rem 0rem',
});

const StyledDialog = styled.dialog(({ theme }) => ({
    padding: '22px 32px 22px 32px',
    backgroundColor: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.divider}`,
    boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.1)',
    maxWidth: '600px',
    width: '100%',
    overflow: 'visible',
}));

const Wraper = styled.div({
    display: 'flex',
    gap: '1rem',
    justifyContent: 'end',
});

const DocGroupList = styled.ul({
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
});

type DocumentType = {
    id: number;
    originalId: string;
    filename: string;
    name: string;
    content_document_group_id: number;
    sort: number;
};

type SaveDocumentinput = { file: File; documentGroupId: number; name: string };

type Props = {
    saveDocument: (input: SaveDocumentinput) => Promise<unknown>;
    getDocuments: () => Promise<DocumentType[]>;
    documentQueryKey: readonly unknown[];
    removeDocument: (documentId: number) => Promise<unknown>;
    renameDocument: (documentId: number, newName: string) => Promise<unknown>;
    regroupDocument: (documentId: number, newGroup: number) => Promise<unknown>;
};

const GroupDocumentsArea: FC<React.HTMLAttributes<HTMLDivElement> & Props> = ({
    saveDocument,
    getDocuments,
    documentQueryKey,
    renameDocument,
    regroupDocument,
    removeDocument,
    ...rest
}) => {
    const { data: documentGroups } = useGetDocumentGroups();
    const queryClient = useQueryClient();

    const { data: documents } = useQuery({
        queryFn: () => {
            return getDocuments();
        },
        queryKey: documentQueryKey,
    });

    const { mutate: saveDocumentMutation } = useMutation(
        (input: SaveDocumentinput) => {
            return saveDocument(input);
        },
        {
            onMutate: (input) => {
                const toastId = toast.loading(`${L('saving')} ${input.name}`);
                return toastId;
            },
            onError: (_error, input, toastId: string) => {
                toast.error(`${L('save_error')}`, {
                    id: toastId,
                });
            },
            onSuccess: (_data, input, toastId: string) => {
                toast.success(`${input.name} ${L('saved')}`, {
                    id: toastId,
                });
            },

            onSettled: () => {
                queryClient.invalidateQueries(documentQueryKey);
            },
        }
    );

    const dialogRef = useRef<HTMLDialogElement>(null);

    const [files, setFiles] = useState<File[]>([]);

    const { getInputProps, getRootProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            setFiles((prev) => [
                ...prev,
                ...acceptedFiles.filter((acceptedFile) => !prev.map((file) => file.name).includes(acceptedFile.name)),
            ]);

            dialogRef.current?.showModal();
        },
        onDropRejected(fileRejections) {
            fileRejections.forEach((fileRejection) => {
                fileRejection.errors.forEach((error) => {
                    const errorCode = error.code.replace(/-/g, '_');
                    toast.error(`${fileRejection.file.name} ${L(errorCode)}`);
                });
            });
        },
        maxSize: 100000000,
    });

    const [selectedGroup, setSelectedGroup] = useState<number>();

    const options = documentGroups?.map((group) => ({
        value: group.id,
        label: group.name,
    }));
    return (
        <>
            <StyledDialog ref={dialogRef}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (!selectedGroup) return toast.error(L('no_document_group_selected'));

                        files.map((file) => {
                            saveDocumentMutation({
                                file: file,
                                documentGroupId: selectedGroup,
                                name: file.name,
                            });
                        });
                        dialogRef.current?.close();

                        setSelectedGroup(undefined);
                        setFiles([]);
                    }}
                >
                    <Label title={L('select_document_group')}>
                        <ReactSelect
                            placeholder={L('document_group_input')}
                            value={options?.find((group) => group.value === selectedGroup) ?? null}
                            options={options}
                            onChange={(value) => setSelectedGroup(value?.value)}
                        />
                    </Label>

                    <div style={{ paddingTop: '1rem' }}>
                        {files.map((file) => {
                            return <DocumentItem fileType={file.type} name={file.name} key={file.name} />;
                        })}
                    </div>
                    <Wraper>
                        <Button
                            type={'button'}
                            onClick={() => {
                                dialogRef.current?.close();
                                setSelectedGroup(undefined);
                                setFiles([]);
                            }}
                        >
                            {L('cancel')}
                        </Button>
                        <Button disabled={!selectedGroup} variant="filledPrimary">
                            {L('save')}
                        </Button>
                    </Wraper>
                </form>
            </StyledDialog>
            <StyledCard {...rest}>
                <FileDropZone getRootProps={getRootProps} getInputProps={getInputProps} />
                <DocGroupList className="docItems">
                    {documentGroups?.map((group) => {
                        const groupedDocuments = documents
                            ?.map((doc) => ({
                                id: doc.id,
                                originalId: doc.originalId,
                                filename: doc.filename,
                                name: doc.name,
                                groupId: doc.content_document_group_id,
                                sort: doc.sort,
                            }))
                            .filter((doc) => doc.groupId === group.id);
                        if (!groupedDocuments?.length) {
                            return null;
                        }
                        return (
                            <React.Fragment key={group.id + group.name}>
                                <StyledH2>{group.name}</StyledH2>
                                <DocumentsArea
                                    sortable={true}
                                    documentQueryKey={documentQueryKey}
                                    documents={groupedDocuments}
                                    removeDocument={removeDocument}
                                    renameDocument={renameDocument}
                                    regroupDocument={regroupDocument}
                                />
                            </React.Fragment>
                        );
                    })}
                </DocGroupList>
            </StyledCard>
        </>
    );
};

export default GroupDocumentsArea;
