import { useQuery } from 'react-query';
import MaintenancesApi from '@app/api/public/MaintenancesApi';
import { MaintenanceCategory, SettingsType } from '@app/api/models/Maintenances';

export const getSettingsListByTypeKey = 'getSettingsListByType';

const getSettingsListByType = async (settingstype: SettingsType) => {
    return await MaintenancesApi.getSettingListByType(settingstype);
};

const useGetSettingsListByType = (settingstype: SettingsType) => {
    return useQuery([getSettingsListByTypeKey, settingstype], () => getSettingsListByType(settingstype));
};

export default useGetSettingsListByType;
