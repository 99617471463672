import React from 'react';
import { Icon } from '../Icon/Icon';
type Props = {
    view: 'list' | 'card';
    setView: (value: 'list' | 'card') => void;
};

const ToggleVIew = ({ view, setView }: Props) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {view === 'card' ? (
                <Icon.listView
                    size="medium"
                    onClick={() => setView('list')}
                    style={{ placeSelf: 'end', cursor: 'pointer' }}
                />
            ) : (
                <Icon.gridView
                    size="medium"
                    onClick={() => setView('card')}
                    style={{ placeSelf: 'end', cursor: 'pointer' }}
                />
            )}
        </div>
    );
};

export default ToggleVIew;
