import { Dimension } from '@app/api/lib/resize';
import MailApi from '@app/api/public/MailApi';
import { useQuery } from 'react-query';
import mime from 'mime';

const useGetSignedAttachmentByIdQueryKey = 'useGetSignedAttachmentById';

const getSignedUrl = (id?: string, dimension?: Dimension) => {
    if (!id) return Promise.reject('no id');
    // return MailApi.getSignedAttachmentUrl(`${dimension ? dimension + '/' : ''}${id}`);
    return MailApi.getSignedAttachmentUrl(id);
};

const useGetSignedAttachmentById = (id?: string, dimension?: Dimension) => {
    const mimeType = mime.getType(id || '');

    const imageDimension = mimeType?.includes('image') ? dimension : undefined;

    return useQuery(
        [useGetSignedAttachmentByIdQueryKey, `${imageDimension ? imageDimension + '/' : ''}${id}`],
        () => getSignedUrl(id, imageDimension),
        {
            enabled: !!id,
            keepPreviousData: true,
        }
    );
};

export default useGetSignedAttachmentById;
