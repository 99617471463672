import styled from '@emotion/styled';
import React, { HTMLAttributes } from 'react';
import { NavLink } from 'react-router-dom';
import { style } from '../../../components/Shared/Style';
import { colors } from '../../../components/Shared/Style/colors';
import { p, tooltip } from '../../../components/Shared/Style/typography';
import useGetProjectInfo from '../../../hooks/useGetProjectInfo';
import { L } from '../../../lib/i18n';
import { useGetTickets, useGetSettingsByType } from '../hooks/ticketQueries';
import formatChatDate from '../lib/formatChatDate';

const TicketCardContent = styled.div({
    display: 'grid',
    gap: '0.5rem',
    gridTemplateAreas: `
        "created_date created_date"
        "ticketno status"
        "title title"
        "assignee unreadMessages"
    `,
});

const TicketCard = styled(NavLink)<{ color?: string }>(({ color, theme }) => ({
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    textDecoration: 'none',
    borderRadius: style.roundCornerSize.small,
    padding: style.margin.m,
    borderLeft: color ? `6px solid ${color}` : `6px solid ${theme.colors.white}`,
    boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    '&.active, &:hover': {
        boxShadow: `0px 0px 0px 1px ${theme.colors.tabActive}`,
    },
}));

const Description = styled.p({
    ...p,
    paddingBottom: style.margin.m,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',
    textOverflow: 'ellipsis',
});

const Assignee = styled.span(({ theme }) => ({
    ...tooltip,
    alignSelf: 'end',
    color: theme.colors.secondary,
}));

const Status = styled.span({
    ...tooltip,
    alignSelf: 'end',
    alignItems: 'center',
    gridArea: 'status',
    justifySelf: 'end',
    display: 'flex',
    gap: '1ch',
});

const TicketNo = styled.span({
    ...tooltip,
    gridArea: 'ticketno',
});

const CreatedDate = styled.span({
    ...tooltip,
    gridArea: 'created_date',
    textTransform: 'capitalize',
});

const UnreadMessages = styled.div(({ theme }) => ({
    ...tooltip,
    color: theme.colors.white,
    borderRadius: '50%',
    height: '18px',
    width: '18px',
    backgroundColor: theme.colors.notificationCount,
    display: 'grid',
    placeItems: 'center',
}));

const Pill = styled.span<{ backgroundColor?: string }>(({ backgroundColor, theme }) => ({
    backgroundColor: backgroundColor || theme.colors.white,
    height: '15px',
    width: '15px',
    color: theme.colors.black,
    borderRadius: '2px',
}));

type Ticket = NonNullable<ReturnType<typeof useGetTickets>['data']>[0];

type TicketListItemProps = Ticket & Omit<HTMLAttributes<HTMLDivElement>, 'id'>;

const TicketListItem = ({
    ticket_priority_id,
    ticket_status_id,
    ticket_no,
    created_at,
    title,
    assigned_name,
    newentries,
    id,
}: TicketListItemProps) => {
    const { data: priorities } = useGetSettingsByType('priority');
    const { data: statuses } = useGetSettingsByType('status');
    const { data: projectInfo } = useGetProjectInfo();

    const status = statuses?.find((status) => status.id === ticket_status_id);
    const priority = priorities?.find((priority) => priority.id === ticket_priority_id);
    return (
        <TicketCard to={`/tickets/${id}`} color={priority?.color_code || colors.primary}>
            <TicketCardContent>
                <CreatedDate>{formatChatDate(created_at)}</CreatedDate>
                <TicketNo>{`#${projectInfo?.id} - ${ticket_no}`}</TicketNo>
                <Status>
                    {L(`ticket_status_${status?.name}`) || L('new')}
                    <Pill backgroundColor={status?.color_code || colors.primary} />
                </Status>
                <Description style={{ gridArea: 'title' }}>{title}</Description>
                <Assignee style={{ gridArea: 'assignee' }}>{assigned_name}</Assignee>
                {!!newentries && (
                    <UnreadMessages style={{ gridArea: 'unreadMessages', justifySelf: 'end' }}>
                        <span>{newentries}</span>
                    </UnreadMessages>
                )}
            </TicketCardContent>
        </TicketCard>
    );
};

export default TicketListItem;
