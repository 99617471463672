import MembersApi, { ListingMember } from '@app/api/public/MembersApi';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import z from 'zod';
import { propertiesQueryKeys } from './propertyQueries';

const errorSchema = z.object({ email: z.string(), status: z.string() }).array();

const removeMembers = async ({ memberArray }: { memberArray: ListingMember[] }) => {
    const response = await Promise.all(
        memberArray.map(async (member) => {
            try {
                const response = await MembersApi.remove(member.id);

                if (response === 0) return { email: member.email, status: 'unknown' };
                return { email: member.email, status: 'success' };
            } catch (error) {
                return { email: member.email, status: 'unknown' };
            }
        })
    );
    //throw new Error(`Inbjudan kunde inte skickas till ${response.email}`);
    const errors = response.find((item) => item.status === 'error' || item.status === 'unknown');
    if (errors) throw response;
};
const useRemoveCheckedMembers = () => {
    const queryClient = useQueryClient();
    return useMutation(removeMembers, {
        onSuccess: () => {
            toast.success('Medlemmar borttagna');
        },
        onError: (err) => {
            console.log(err);

            const validatedError = errorSchema.safeParse(err);
            /* Shows error message  */
            if (validatedError.success) {
                const errors = validatedError.data.filter(
                    (item) => item.status === 'error' || item.status === 'unknown'
                );
                errors.map((item) => {
                    toast.error(`Det gick inte att ta bort ${item.email}`);
                });
                const success = validatedError.data.filter((item) => item.status === 'success');
                success.length && toast.success(`${success.length} medlemmar borttagna`);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(propertiesQueryKeys.all);
        },
    });
};

export default useRemoveCheckedMembers;
