import { useQuery } from 'react-query';
import PublicSiteApi from '@app/api/public/PublicSiteApi';
import { useParams } from 'react-router-dom';
import { getQueryPageListItems } from './useGetPageListItems';

const getPageListItem = async (id: string) => {
    const r = await PublicSiteApi.get(id);
    return r;
};

const useGetPageListItem = () => {
    const { id } = useParams<{ id: string }>();
    return useQuery(getQueryPageListItems.id(id), () => getPageListItem(id));
};

export default useGetPageListItem;
