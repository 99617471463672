import { useQueryClient, useMutation } from 'react-query';
import PublicSiteApi from '@app/api/public/PublicSiteApi';
import { PublicSiteInformationItem } from '@app/api/models/PublicSite';
import { getQueryPageListItems } from './useGetPageListItems';

export const updatePublish = async (informationItem: PublicSiteInformationItem) => {
    return PublicSiteApi.save(informationItem, informationItem.published_at ? false : true);
};

const useUpdatePublish = () => {
    const queryClient = useQueryClient();

    return useMutation((informationItem: PublicSiteInformationItem) => updatePublish(informationItem), {
        onMutate: (informationItem) => {
            queryClient.cancelQueries(getQueryPageListItems.id(informationItem.id.toString()));
            const copyChangePublish = { ...informationItem };
            copyChangePublish.published_at = informationItem.published_at ? null : new Date().toISOString();
            queryClient.setQueryData(getQueryPageListItems.id(informationItem.id.toString()), copyChangePublish);
            if (informationItem.page_type === 'contact_page') {
                queryClient.setQueryData(getQueryPageListItems.id('contact_page'), copyChangePublish);
            }
        },
        onSettled: (_data, _error, informationItem) => {
            if (informationItem.page_type === 'contact_page') {
                queryClient.invalidateQueries(getQueryPageListItems.id('contact_page'));
            }
            queryClient.invalidateQueries(getQueryPageListItems.all);
        },
    });
};

export default useUpdatePublish;
