import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { PreviewThemeType } from '../../../../areas/public-site/theme/ThemePage';

const Line = styled.hr(({ theme }: { theme: PreviewThemeType }) => {
    return {
        display: 'block',
        height: '85%',
        margin: 'auto',
        color: theme.colors.textColor2,
    };
});

const PreviewVerticalLine = ({ ...rest }) => {
    const theme = useTheme<PreviewThemeType>();

    return <Line theme={theme} {...rest} />;
};

export default PreviewVerticalLine;
