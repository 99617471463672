Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["list"] = createRpcProxy("MembersApi", "list", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["listByPropertyId"] = createRpcProxy("MembersApi", "listByPropertyId", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getbyid"] = createRpcProxy("MembersApi", "getbyid", {
  "urlPrefix": "/rpc"
});
o["getMember"] = createRpcProxy("MembersApi", "getMember", {
  "urlPrefix": "/rpc"
});
o["listProperties"] = createRpcProxy("MembersApi", "listProperties", {
  "urlPrefix": "/rpc"
});
o["remove"] = createRpcProxy("MembersApi", "remove", {
  "urlPrefix": "/rpc"
});
o["newMember"] = createRpcProxy("MembersApi", "newMember", {
  "urlPrefix": "/rpc"
});
o["importMembers"] = createRpcProxy("MembersApi", "importMembers", {
  "urlPrefix": "/rpc"
});
o["inviteMember"] = createRpcProxy("MembersApi", "inviteMember", {
  "urlPrefix": "/rpc"
});
o["listPropertiesForUser"] = createRpcProxy("MembersApi", "listPropertiesForUser", {
  "urlPrefix": "/rpc"
});
o["getInviteMailStatus"] = createRpcProxy("MembersApi", "getInviteMailStatus", {
  "urlPrefix": "/rpc"
});
o["getUsersNameByEmail"] = createRpcProxy("MembersApi", "getUsersNameByEmail", {
  "urlPrefix": "/rpc"
});
o["exportMemberInfo"] = createRpcProxy("MembersApi", "exportMemberInfo", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});