import styled from '@emotion/styled';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Button } from '../../../components/Buttons/Button';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import { Loading } from '../../../components/Loading/Loading';
import { useModal } from '../../../components/Modal/Context/ModalContext';
import { style } from '../../../components/Shared/Style';
import { colors } from '../../../components/Shared/Style/colors';
import Spinner from '../../../components/Spinner/Spinner';
import { L } from '../../../lib/i18n';
import useGetProperties from '../hooks/useGetProperties';
import useSaveNewMember from '../hooks/useSaveNewMember';
import useGetMemberList from '../hooks/useGetMemberList';

const ButtonWrapper = styled.div({
    padding: '1.5rem 0px 0px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    bottom: '1rem',
});

const Form = styled.form({
    width: '400px',
    placeSelf: 'center',
    backgroundColor: colors.white,
    padding: '2rem',
    borderRadius: style.roundCornerSize.small,
});

const NewMemberForm = () => {
    const { ToggleIsModalOpen } = useModal();
    const { data: properties } = useGetProperties();
    const { mutate, status, reset } = useSaveNewMember();
    const { data: users } = useGetMemberList();

    const [member, setMember] = useState<{
        email: string;
        name: string;
        propertyId: number | undefined;
        nameEnabled: boolean;
    }>({
        email: '',
        name: '',
        propertyId: undefined,
        nameEnabled: true,
    });

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, name: 'email' | 'name' | 'property') => {
        setMember({ ...member, [name]: e.target.value });
        //Try to get member
        if (name === 'email') {
            if (validateEmail(e.target.value)) {
                const user = users?.filter((user) => user.email === e.target.value);
                user && user.length > 0
                    ? setMember({ ...member, nameEnabled: false, [name]: e.target.value, name: user[0].name })
                    : setMember({ ...member, nameEnabled: true, [name]: e.target.value, name: '' });
            } else {
                setMember({ ...member, nameEnabled: true, [name]: e.target.value, name: '' });
            }
        }
    };

    const onChange = (value) => {
        setMember({ ...member, propertyId: value });
    };

    const options = properties?.map((property) => {
        return { label: property.name, value: property.id };
    });

    if (!properties) return <Loading />;

    return (
        <Form
            onClick={(e) => {
                e.stopPropagation();
            }}
            onSubmit={(e) => {
                e.preventDefault();
                if (member.name && member.email) {
                    mutate(member, {
                        onSuccess: () => {
                            toast.success(`${L('member')} ${member.email} ${L('saved')}`);
                            ToggleIsModalOpen();
                        },
                    });
                }
            }}
        >
            <Label title={L('member_account') + '*'}>
                <Input
                    placeholder={L('email')}
                    name="email"
                    value={member.email}
                    onChange={(e) => {
                        reset();
                        handleChange(e, 'email');
                    }}
                />
            </Label>
            <Label title={L('name')}>
                <Input
                    disabled={member.nameEnabled ? false : true}
                    placeholder={L('name')}
                    name="name"
                    value={member.name}
                    onChange={(e) => {
                        reset();
                        handleChange(e, 'name');
                    }}
                />
            </Label>
            <Label title={L('property')}>
                <Dropdown
                    name="property"
                    value={member.propertyId}
                    options={options || []}
                    onChange={(value) => {
                        reset();
                        onChange(value);
                    }}
                />
            </Label>

            <ButtonWrapper>
                <Button type="button" onClick={() => ToggleIsModalOpen()} variant="primary">
                    {L('cancel')}
                </Button>

                {status === 'idle' && (
                    <Button
                        disabled={member.email && member.name ? false : true}
                        type={'submit'}
                        variant="filledPrimary"
                    >
                        {L('save')}
                    </Button>
                )}
                {status === 'loading' && (
                    <Button variant="filledPrimary" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Spinner />
                    </Button>
                )}
                {status === 'error' && (
                    <Button type={'submit'} variant="filledPrimary">
                        {L('error')}
                    </Button>
                )}
                {status === 'success' && (
                    <Button type={'submit'} variant="filledPrimary">
                        {L('saved')}
                    </Button>
                )}
            </ButtonWrapper>
        </Form>
    );
};

export default NewMemberForm;
