import { ResponderProvided, DropResult } from 'react-beautiful-dnd';
import { useQueryClient, useMutation, QueryKey } from 'react-query';

type UseDragVariables = { result: DropResult; provided: ResponderProvided };

type DragableItem = {
    sort: number;
};

export const sortList = <T extends DragableItem>(result: DropResult, listItems: T[]) => {
    if (!result || !result.destination) return;
    const items = [...listItems];

    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    return {
        items,
    };
};

const dragEnd = <T extends DragableItem>({ result }: UseDragVariables, listItems: T[], reverseSortOrder: boolean) => {
    if (!listItems) return;
    const sortedList = sortList(result, listItems);
    if (!sortedList) return;
    if (reverseSortOrder) {
        sortedList.items.reverse();
    }
    const sortedArray = sortedList.items.map((item, index) => {
        return { ...item, sort: index };
    });
    return sortedArray;
};

const useDragEnd = <T extends DragableItem>({
    queryKey,
    updateSortOrder,
    dragableItems,
    reverseSortOrder = false,
}: {
    queryKey: QueryKey;
    dragableItems: T[];
    updateSortOrder: (sortArray: DragableItem[]) => Promise<T[]>;
    reverseSortOrder?: boolean;
}) => {
    const queryClient = useQueryClient();
    return useMutation(
        async (useDragVariables: UseDragVariables) => {
            const sortedArray = dragEnd(useDragVariables, dragableItems, reverseSortOrder);
            if (!sortedArray) return;
            return await updateSortOrder(sortedArray);
        },
        {
            onMutate: ({ result }) => {
                queryClient.cancelQueries(queryKey);
                const contentItems = queryClient.getQueryData<T[]>(queryKey);
                // Sätter ny ordning
                if (!result.destination || !contentItems) return;
                const sortedList = sortList(result, contentItems);
                sortedList && queryClient.setQueryData(queryKey, sortedList.items);
            },
            onSettled: () => {
                queryClient.invalidateQueries(queryKey);
            },
            onError: (error) => {
                console.log(error);
            },
        }
    );
};

export default useDragEnd;
