import styled from '@emotion/styled';
import React, { FC } from 'react';

const StyledH1 = styled.h1(({ theme }) => ({
    color: theme?.colors?.textColor2,
    overflowWrap: 'anywhere',
    fontSize: '1.5rem',
    margin: '0 0 1.5rem 0',
    fontWeight: 'bold',
}));

type props = {
    title: string;
};
const FeedTitle = ({ title }: props) => {
    return (
        <div style={{ display: 'grid', gap: '0.5rem', marginTop: '1.5rem' }}>
            <StyledH1>{title}</StyledH1>
        </div>
    );
};

export default FeedTitle;
