Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["list"] = createRpcProxy("AlertsApi", "list", {
  "urlPrefix": "/rpc"
});
o["getPropertyNames"] = createRpcProxy("AlertsApi", "getPropertyNames", {
  "urlPrefix": "/rpc"
});
o["getPropertyFilterList"] = createRpcProxy("AlertsApi", "getPropertyFilterList", {
  "urlPrefix": "/rpc"
});
o["id"] = createRpcProxy("AlertsApi", "id", {
  "urlPrefix": "/rpc"
});
o["save"] = createRpcProxy("AlertsApi", "save", {
  "urlPrefix": "/rpc"
});
o["remove"] = createRpcProxy("AlertsApi", "remove", {
  "urlPrefix": "/rpc"
});
o["saveProperties"] = createRpcProxy("AlertsApi", "saveProperties", {
  "urlPrefix": "/rpc"
});