import ContractsApi from '@app/api/public/ContractsApi';
import { useQueryClient, useMutation } from 'react-query';
import { getContractKeys } from './useContract';
import type { SaveContract } from '@app/api/models/Contracts';

const handleReturn = (
    data:
        | { status: 'error'; message: string }
        | { status: 'success'; data: any }
        | { status: string; message?: string; data?: any }
) => {
    if (data.status === 'error') {
        throw new Error(data.message);
    } else if (data.status === 'success') {
        return data.data;
    }
    return undefined;
};

const saveContract = async (contract: Partial<SaveContract>, dateChanged?: boolean) => {
    return ContractsApi.save(contract, dateChanged);
};

const useSaveContract = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async ({ contract, dateChanged }: { contract: Partial<SaveContract>; dateChanged?: boolean }) =>
            handleReturn(await saveContract(contract, dateChanged)),
        {
            onSettled: () => {
                queryClient.invalidateQueries(getContractKeys.list());
                queryClient.invalidateQueries(getContractKeys.listArchived());
            },
        }
    );
};

export default useSaveContract;
