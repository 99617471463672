import React from 'react';
import styled from '@emotion/styled';
import useGetHelpListItemById from '../hooks/useGetHelpListItemById';
import { RawHtmlHelp } from '../../../components/Layout/RawHtmlHelp';

const ItemWrapper = styled.div({
    marginTop: '20px',
    '& img': {
        width: '100%',
        maxWidth: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
    },
    '& iframe': {
        width: '700px',
        height: '450px',
    },
});

const Title = styled.h1({
    fontSize: '1.4rem',
});

const Description = styled.div({
    fontWeight: 400,

    a: {
        color: 'blue',
        textDecoration: 'underline',
    },
});

const HelpItem = () => {
    const { data: helpItem } = useGetHelpListItemById();

    if (!helpItem) return null;

    return (
        <ItemWrapper>
            <Title>{helpItem.title}</Title>
            <RawHtmlHelp content={helpItem.description || ''} />
        </ItemWrapper>
    );
};

export default HelpItem;
