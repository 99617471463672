import React from 'react';
import styled from '@emotion/styled';
import { Maintenance } from '@app/api/models/Maintenances';
import dayjs from 'dayjs';
import sv from 'dayjs/locale/sv';
import relativeTime from 'dayjs/plugin/relativeTime';
import { StyleColumn } from '../../../types/ColumnType';
import { L } from '../../../lib/i18n';
import { colors } from '../../../components/Shared/Style/colors';
import InvisibleLinkWrapper from '../../../components/Link/InvisibleLinkWrapper';

dayjs.locale(sv);
dayjs.extend(relativeTime);

const Container = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maring: 'auto',
});
const CostContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'end',
    maring: 'auto',
});
const NameContainer = styled(Container)({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    margin: 0,
});
const Span = styled.span(({ isBefore }: { isBefore?: boolean }) => {
    return {
        display: 'flex',
        justifyContent: 'center',
        color: isBefore ? colors.statusError : 'inherit',
    };
});
const NameSpan = styled.span({
    textAlign: 'left',
    paddingLeft: '1rem',
});
const StatusSpan = styled(Span)(({ statusColor }: { statusColor: string }) => {
    return {
        backgroundColor: statusColor,
        width: '8rem',
        padding: '4px 8px 4px 8px',
        borderRadius: '15px',
        color: 'white',
    };
});
const PlanedCost = styled.span({
    display: 'flex',
    justifyContent: 'center',
    fontSize: '10px',
});

const ColorBox = styled.div(({ boxcolor }: { boxcolor: string }) => {
    return {
        backgroundColor: boxcolor,
        height: '60px',
        width: '6px',
    };
});

export const COLUMNS: StyleColumn<Maintenance>[] = [
    {
        Header: 'Åtgärd',
        accessor: 'name',
        width: 200,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const maintenance = row.original as Maintenance;
            return (
                <NameContainer>
                    <ColorBox
                        boxcolor={
                            maintenance.completed_date
                                ? colors.maintenance_completed_status
                                : maintenance.maintenance_status.color_code || ''
                        }
                    />
                    <NameSpan>{maintenance.name}</NameSpan>
                </NameContainer>
            );
        },
    },
    {
        Header: 'Servicepartner',
        accessor: (originalRow: Maintenance) => {
            return originalRow.service_partner_name;
        },
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const maintenance = row.original as Maintenance;
            return (
                <Container>
                    <InvisibleLinkWrapper to={`/service-partner/${maintenance.service_partner_id}`}>
                        {maintenance.service_partner_name}
                    </InvisibleLinkWrapper>
                </Container>
            );
        },
    },
    {
        Header: 'year',
        accessor: (originalRow: Maintenance) => {
            return dayjs(originalRow.planned_date).format('YYYY-MM-DD');
        },
    },
    {
        Header: 'Kategori',
        accessor: (data) => data.maintenance_category.name,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
    },
    {
        Header: 'Total kostnad',
        accessor: (originalRow: Maintenance) => {
            return originalRow.completed_cost ? originalRow.completed_cost : originalRow.planned_cost;
        },
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },

        Cell: ({ row }) => {
            const maintenance = row.original as Maintenance;
            return (
                <CostContainer>
                    {maintenance.completed_cost ? (
                        <>
                            <Span>
                                {new Intl.NumberFormat('sv-SE', {
                                    style: 'currency',
                                    currency: 'SEK',
                                    maximumFractionDigits: 0,
                                }).format(+maintenance.completed_cost)}
                            </Span>
                            {maintenance.planned_cost && (
                                <PlanedCost>
                                    {L('planned_cost')}:{' '}
                                    {new Intl.NumberFormat('sv-SE', {
                                        style: 'currency',
                                        currency: 'SEK',
                                        maximumFractionDigits: 0,
                                    }).format(+maintenance.planned_cost)}
                                </PlanedCost>
                            )}
                        </>
                    ) : (
                        maintenance.planned_cost && (
                            <Span>
                                {new Intl.NumberFormat('sv-SE', {
                                    style: 'currency',
                                    currency: 'SEK',
                                    maximumFractionDigits: 0,
                                }).format(+maintenance.planned_cost)}
                            </Span>
                        )
                    )}
                </CostContainer>
            );
        },
    },
    {
        Header: 'Planerat datum',
        accessor: (originalRow: Maintenance) => {
            return originalRow.completed_date
                ? dayjs(originalRow.completed_date).valueOf()
                : dayjs(originalRow.planned_date).valueOf();
        },
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const maintenance = row.original as Maintenance;
            return (
                <Container>
                    {!maintenance.completed_date && (
                        <Span isBefore={dayjs(maintenance.planned_date).isBefore(dayjs())}>
                            {dayjs(maintenance.planned_date).isValid()
                                ? dayjs(Date.now()).to(maintenance.planned_date)
                                : ''}
                        </Span>
                    )}
                </Container>
            );
        },
    },
    {
        Header: 'Status',
        accessor: (data) => data.maintenance_status.name,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },

        Cell: ({ row }) => {
            const maintenance = row.original as Maintenance;
            return (
                <Container>
                    <StatusSpan
                        statusColor={
                            maintenance.completed_date
                                ? colors.maintenance_completed_status
                                : maintenance.maintenance_status.color_code
                        }
                    >
                        {maintenance.completed_date
                            ? L('maintenance_status_completed')
                            : maintenance.maintenance_status.name}
                    </StatusSpan>
                </Container>
            );
        },
    },
];
