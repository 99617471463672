import { SaveTicketSetting, TicketSettingType } from '@app/api/models/Tickets';
import TicketsApi from '@app/api/public/TicketsApi';
import { useMutation, useQueryClient } from 'react-query';
import { ticketQueryKeys } from './ticketQueries';

type variables = {
    setting: SaveTicketSetting;
    type: TicketSettingType;
};
const useSaveSettingByType = () => {
    const client = useQueryClient();
    return useMutation((variables: variables) => TicketsApi.saveSettingByType(variables.type, variables.setting), {
        onSuccess: (data, variables) => {
            client.invalidateQueries(ticketQueryKeys[variables.type]());
        },
    });
};

export default useSaveSettingByType;
