import React, { FC } from 'react';
import { Colors, colors } from '../Shared/Style/colors';
import { HTMLAttributes } from 'react';
import styled from '@emotion/styled';

const StyledP = styled.p((props: { color?: Colors | string }) => {
    return {
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: '1rem',
        lineHeight: '150%',
        color: (props.color && colors[props.color]) || colors.textColor2,
    };
});

type Props = {
    className?: string;
    color?: Colors | string;
    onClick?: () => void;
} & HTMLAttributes<HTMLParagraphElement>;
const P: FC<Props> = ({ children, ...rest }) => {
    return <StyledP {...rest}>{children}</StyledP>;
};

export default P;
