import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import Input from '../../../components/Forms/Input';
import Textarea from '../../../components/Forms/TextArea';
import Label from '../../../components/Forms/Label';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import useGetProjectInfo from '../../../hooks/useGetProjectInfo';
import { L } from '../../../lib/i18n';
import {
    useGetInventoriesForSpace,
    useGetSettingsByType,
    useGetSpacesForProperty,
    useGetTicket,
} from '../hooks/ticketQueries';
import DocumentClick from '../../../components/document/DocumentDropdown/DocumentDropdown';
import { useHistory } from 'react-router-dom';
import { useDocumentDetails } from '../../../hooks/useGetDocumentDetail';
import checkUnsavedData from '../../../lib/helpers/checkUnsavedData';
import DropDownCreatable from '../../../components/DropDownCreatable/DropDownCreatable';
import { SaveTicket, TicketSettingType } from '@app/api/models/Tickets';
import useGetBoardMembers from '../../board-members/hooks/useGetBoardMembers';
import { useDropzone } from 'react-dropzone';
import FileDropZone from '../../../components/FileDropZone/FileDropZone';
import ReactSelect from '../../../components/Dropdown/ReactSelect';
import toast from 'react-hot-toast';
import useSaveTicket from '../hooks/useSaveTicket';
import InputSplit from '../../../components/Forms/InputSplit';
import * as Dialog from '@radix-ui/react-dialog';
import { Button } from '../../../components/Buttons/Button';
import useGetSignedUrlById from '../../../hooks/useGetSignedUrlById';
import useArchiveTicket from '../hooks/useArchiveTicket';
import formatOptionLabelColorBox from './formatOptionsLabel';
import SettingsByType from './SettingsByType';
import useGetProperties from '../../../hooks/useGetProperties';

type Ticket = Partial<SaveTicket>;

const Overlay = styled(Dialog.Overlay)({
    background: 'rgba(0 0 0 / 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'grid',
    placeItems: 'center',
    overflowY: 'auto',
});

const Content = styled(Dialog.Content)({
    minWidth: 300,
});

const Form = styled.form({
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    gap: '2rem',
});

const ButtonWrapper = styled.div({
    gridColumn: '1 / -1',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
});
const Wrapper = styled.div({});

type DocumentUrl = {
    id: string;
} & Omit<React.ComponentProps<typeof DocumentClick>, 'url'>;
const DocumentUrlProvider = ({ id, ...rest }: DocumentUrl) => {
    const { data } = useGetSignedUrlById(id, '1920x0');

    return <DocumentClick {...rest} url={data} />;
};

type TicketData = ReturnType<typeof useGetTicket>['data'];
type Props = {
    readOnly?: boolean;
    data?: TicketData;
};

const handleError = (isError?: boolean) => {
    return isError ? L('error_required') : undefined;
};
const EditTicket = ({ readOnly = false, data }: Props) => {
    const [confirmArchive, setConfirmArchive] = useState(false);
    const { data: project } = useGetProjectInfo();
    const history = useHistory();

    const [ticket, setTicket] = React.useState<Partial<SaveTicket>>({ ...data });
    const [errors, setErrors] = useState<Partial<Record<keyof SaveTicket, boolean>>>({});

    const handleChange = <T extends keyof Ticket>(key: T, value: Ticket[T]) => {
        if (readOnly) return;
        setErrors({});
        setTicket((prev) => ({ ...prev, [key]: value }));
    };

    useEffect(() => {
        if (!data) return;
        setTicket({ ...data });
    }, [data]);

    // Handle Properties
    const { data: properies } = useGetProperties();

    // // Handle Spaces
    const { data: spaces } = useGetSpacesForProperty(ticket.property_id?.toString());
    const [selectedSpace, setSeletedSpace] = React.useState<number | null>();
    // // Handle inventory
    const { data: inventories } = useGetInventoriesForSpace(selectedSpace?.toString());

    useEffect(() => {
        if (!data) return;
        setSeletedSpace(data.location_id || null);
    }, [data]);

    // Handle assignee
    const { data: boardMember } = useGetBoardMembers();
    // Handle Priority
    const { data: priorities } = useGetSettingsByType('priority');
    const priorityOptions = priorities?.map((priority) => ({ label: priority.name, value: priority })) || [];

    // Handle status
    const { data: statuses } = useGetSettingsByType('status');
    const statusOptions = statuses?.map((status) => ({ label: status.name, value: status })) || [];
    // Handle files
    const documentsQueries = useDocumentDetails(ticket?.attachment_ids);
    const savedDocuments = documentsQueries.map((query) => {
        if (!query.data) return null;
        const { name, id, document_data } = query.data;
        const attachments = ticket.attachment_ids || [];
        return (
            <DocumentUrlProvider
                key={id}
                id={document_data.original.id}
                name={name}
                fileType={document_data.original.metadata.mime_type}
                deleteDocument={
                    readOnly
                        ? undefined
                        : () => {
                              setTicket((prev) => ({
                                  ...prev,
                                  attachment_ids: [...attachments?.filter((item) => item !== id.toString())],
                              }));
                          }
                }
            />
        );
    });
    const [files, setFiles] = React.useState<File[]>([]);
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        disabled: readOnly,
    });

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            const newFiles = acceptedFiles.filter(
                (acceptedFile) => !files.find((file) => file.name === acceptedFile.name)
            );
            setFiles((prev) => [...prev, ...newFiles]);
        }
    }, [acceptedFiles]);

    const localFiles = files.map((file) => {
        return (
            <DocumentClick
                key={file.name}
                name={file.name}
                fileType={file.type}
                deleteDocument={
                    readOnly
                        ? undefined
                        : () => {
                              setFiles((prev) => [...prev].filter((item) => item.name !== file.name));
                          }
                }
            />
        );
    });

    const { mutate: saveTicket } = useSaveTicket();
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (
            !ticket.title ||
            !ticket.description ||
            !ticket.property_id ||
            !ticket.assigned_id ||
            !ticket.ticket_priority_id ||
            !ticket.ticket_status_id
        ) {
            setErrors({
                title: !ticket.title,
                description: !ticket.description,
                property_id: !ticket.property_id,
                assigned_id: !ticket.assigned_id,
                ticket_priority_id: !ticket.ticket_priority_id,
                ticket_status_id: !ticket.ticket_status_id,
            });
            return;
        }

        saveTicket(
            {
                ticket: {
                    ...ticket,
                    title: ticket.title,
                    description: ticket.description,
                    property_id: ticket.property_id,
                },
                files,
            },
            {
                onSuccess: (data, vaiables) => {
                    setFiles([]);
                    toast.success(`${L('ticket_saved')}`);

                    if (!vaiables.ticket.id && data?.id) {
                        history.push(`/tickets/${data.id}`);
                    }
                },
            }
        );
    };

    const { push } = useHistory();

    const { mutate: archive } = useArchiveTicket();
    const archiveTicket = () => {
        if (!ticket.id) return;
        archive(
            {
                id: ticket.id,
            },
            {
                onSuccess: (data) => {
                    if (data.archived_at) {
                        toast.success(`${L('ticket_archived')}`);
                    } else {
                        toast.success(`${L('ticket_unarchived')}`);
                    }
                    push('/tickets');
                },
            }
        );
    };

    const handleCancel = () => {
        const dataHasChanged = checkUnsavedData({
            originalData: data || {},
            changedData: ticket,
            buttonText: L('proceed_without_saving'),
            fn: () => {
                push('/tickets');
            },
        });
        if (!dataHasChanged) {
            push('/tickets');
        }
    };

    const propertyOptions = properies?.map((property) => ({ label: property.name, value: property.id })) || [];
    const assigneeOptions = boardMember?.map((p) => ({ label: p.name, value: p.user_id })) || [];
    const spacesOptions = spaces?.map((p) => ({ label: p.name, value: p.id })) || [];
    const inventoriesOptions = inventories?.map((p) => ({ label: p.name, value: p.id })) || [];
    const costumerCostOptions = [
        { label: L('residence'), value: true },
        { label: project?.name, value: false },
    ];

    const [open, setOpen] = useState<React.ReactNode | undefined>();
    const handleOnCreate = (type: TicketSettingType, value: string) => {
        setOpen(<SettingsByType type={type} initialValue={value} />);
    };

    const ticketCardTitle = data?.ticket_no
        ? `#${project?.id} - ${data?.ticket_no} ${L('ticket_information')}`
        : `#${project?.id} - ${L('new_ticket')}`;

    const property = data ? (
        <ReactSelect isDisabled placeholder={data.property_name} />
    ) : (
        <ReactSelect
            placeholder={L('select_property')}
            isClearable
            value={propertyOptions.find((p) => p.value === ticket.property_id) ?? null}
            onChange={(e) => {
                setSeletedSpace(undefined);
                handleChange('property_id', e?.value);
            }}
            options={propertyOptions}
        />
    );

    const archiveButton = confirmArchive ? (
        <Button onClick={() => archiveTicket()} variant={readOnly ? 'selectedMenu' : 'delete'}>
            {readOnly ? L('unarchive_ticket') : L('archive_ticket')}
        </Button>
    ) : (
        <Button
            disabled={!data}
            style={{ visibility: data ? 'visible' : 'hidden' }}
            onClick={() => setConfirmArchive(true)}
        >
            {readOnly ? L('unarchive_ticket') : L('archive_ticket')}
        </Button>
    );

    return (
        <>
            <Dialog.Root
                open={!!open}
                onOpenChange={(e) => {
                    if (!e) setOpen(undefined);
                }}
            >
                <Dialog.Portal>
                    <Overlay>
                        <Content>{open}</Content>
                    </Overlay>
                </Dialog.Portal>
            </Dialog.Root>

            <CardWithDivider
                topArea={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <CardTitle>{ticketCardTitle}</CardTitle>
                        {archiveButton}
                    </div>
                }
                mainArea={
                    <Wrapper>
                        <Form onSubmit={handleSubmit}>
                            <div>
                                <Label title={L('title')} error={handleError(errors.title)}>
                                    <Input
                                        readOnly={readOnly}
                                        value={ticket.title || ''}
                                        onChange={(e) => handleChange('title', e.target.value)}
                                        required
                                    />
                                </Label>

                                <Label title={L('description')} error={handleError(errors.description)}>
                                    <Textarea
                                        readOnly={readOnly}
                                        value={ticket.description?.plain_text || ''}
                                        onChange={(e) => handleChange('description', { plain_text: e.target.value })}
                                        required
                                    />
                                </Label>
                                <Label title={L('attachments')}>
                                    <FileDropZone
                                        getRootProps={getRootProps}
                                        getInputProps={getInputProps}
                                        disabled={readOnly}
                                    />
                                    {savedDocuments}
                                </Label>
                                {localFiles.length > 0 && <Label title={L('un_saved_files')}>{localFiles}</Label>}
                            </div>
                            <div>
                                <Label title={L('property')} error={handleError(errors.property_id)}>
                                    {property}
                                </Label>
                                <Label title={L('space')}>
                                    <ReactSelect
                                        isDisabled={readOnly}
                                        value={spacesOptions.find((p) => p.value === selectedSpace) ?? null}
                                        isClearable
                                        noOptionsMessage={() =>
                                            spacesOptions.find((p) => p.value === selectedSpace)
                                                ? `${L('no_spaces')}`
                                                : `${L('no_property_selected')}`
                                        }
                                        onChange={(e) => {
                                            handleChange('inventory_id', undefined);
                                            setSeletedSpace(e?.value);
                                        }}
                                        options={spacesOptions}
                                        placeholder={L('select_space')}
                                    />
                                </Label>
                                <Label title={L('item')}>
                                    <ReactSelect
                                        isDisabled={readOnly}
                                        placeholder={L('select_item')}
                                        noOptionsMessage={() =>
                                            spacesOptions.find((p) => p.value === selectedSpace)
                                                ? `${L('no_items')}`
                                                : `${L('no_space_selected')}`
                                        }
                                        isClearable
                                        value={inventoriesOptions.find((p) => p.value === ticket.inventory_id) ?? null}
                                        onChange={(e) => handleChange('inventory_id', e?.value)}
                                        options={inventoriesOptions}
                                    />
                                </Label>
                                <Label title={L('assignee')} error={handleError(errors.assigned_id)}>
                                    <ReactSelect
                                        isDisabled={readOnly}
                                        placeholder={L('select_assignee')}
                                        value={assigneeOptions.find((p) => p.value === ticket.assigned_id) ?? null}
                                        isClearable
                                        onChange={(e) => handleChange('assigned_id', e?.value)}
                                        options={assigneeOptions}
                                    />
                                </Label>
                                <Label title={L('priority')} error={handleError(errors.ticket_priority_id)}>
                                    <DropDownCreatable
                                        placeholder={L('select_priority')}
                                        isDisabled={readOnly}
                                        isClearable
                                        value={
                                            priorityOptions.find(
                                                (option) => option.value.id === ticket.ticket_priority_id
                                            ) ?? null
                                        }
                                        options={priorityOptions}
                                        onChange={(e) => handleChange('ticket_priority_id', e?.value.id)}
                                        formatOptionLabel={formatOptionLabelColorBox}
                                        onCreateOption={(e) => {
                                            handleOnCreate('priority', e);
                                        }}
                                    />
                                </Label>
                                <Label title={L('status')} error={handleError(errors.ticket_status_id)}>
                                    <DropDownCreatable
                                        placeholder={L('select_status')}
                                        isClearable
                                        isDisabled={readOnly}
                                        value={
                                            statusOptions.find(
                                                (option) => option.value.id === ticket.ticket_status_id
                                            ) ?? null
                                        }
                                        options={statusOptions}
                                        onChange={(e) => handleChange('ticket_status_id', e?.value.id)}
                                        formatOptionLabel={formatOptionLabelColorBox}
                                        onCreateOption={(e) => {
                                            handleOnCreate('status', e);
                                        }}
                                    />
                                </Label>
                                <Label title={L('cost')}>
                                    <InputSplit
                                        readOnly={readOnly}
                                        value={ticket.ticket_cost || ''}
                                        onChange={(e) => handleChange('ticket_cost', e.target.value)}
                                        splitInput={'kr'}
                                        type={'number'}
                                    />
                                </Label>
                                {!!ticket.ticket_cost && (
                                    <Label title={L('who_pays')}>
                                        <ReactSelect
                                            isDisabled={readOnly}
                                            value={costumerCostOptions.find(
                                                (p) => !!p?.value === !!ticket?.is_ticket_customer_cost
                                            )}
                                            onChange={(e) => handleChange('is_ticket_customer_cost', e?.value)}
                                            options={costumerCostOptions}
                                        />
                                    </Label>
                                )}
                            </div>
                            {!readOnly && (
                                <ButtonWrapper>
                                    <Button type={'button'} onClick={handleCancel} variant="primary">
                                        {L('cancel')}
                                    </Button>
                                    <Button variant="filledPrimary">{L('save')}</Button>
                                </ButtonWrapper>
                            )}
                        </Form>
                    </Wrapper>
                }
            />
        </>
    );
};

export default EditTicket;
