import MembersApi from '@app/api/public/MembersApi';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { L } from '../../../lib/i18n';
import { getMemberListQueryKey } from './useGetMemberList';

type Member = { email: string; name: string; propertyId?: number };

const saveNewMember = async (member: Member) => {
    const res = await MembersApi.newMember(member.email, member.name, member.propertyId);

    if (!res) return Promise.reject(L('reset_error'));

    if (res?.['message']) {
        const message = res['message'] as string;
        return Promise.reject(L(message));
    }

    return res;
};
const useSaveNewMember = () => {
    const queryClient = useQueryClient();
    return useMutation((member: Member) => saveNewMember(member), {
        onError: (err) => {
            /* Shows error message */
            const error = typeof err === 'string' ? err : new Error(L('reset_error')).message;
            const message = error;
            toast(`❌ ${L(message)}`);
        },
        onSettled: () => {
            queryClient.invalidateQueries([getMemberListQueryKey]);
        },
    });
};

export default useSaveNewMember;
