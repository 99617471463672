import { useQuery } from 'react-query';
import ProfilesApi from '@app/api/public/ProfilesApi';

export const getUserByIdQueryKey = 'useGetUserOnProject';
const useGetUsersFromProject = () => {
    return useQuery([getUserByIdQueryKey], () => {
        return ProfilesApi.getUsersFromProject();
    });
};

export default useGetUsersFromProject;
