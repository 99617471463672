import React, { ReactNode, useMemo } from 'react';
import { Column, useTable, useGlobalFilter, useSortBy, useRowSelect, useFlexLayout, Row } from 'react-table';
import '../table.css';
import styled from '@emotion/styled';
import { Button } from '../../../components/Buttons/Button';
import { Link } from 'react-router-dom';
import SortArrows from '../../../components/Table/sortArrows/SortArrows';
import { colors } from '../../../components/Shared/Style/colors';
import { Maintenance } from '@app/api/models/Maintenances';
import dayjs from 'dayjs';
import YearGrouping from './YearGrouping';
import { L } from '../../../lib/i18n';
import GlobalFilter from '../../../components/Table/filterComponents/GlobalFilter';

const Container = styled.div({
    width: '100%',
    marginBottom: '2rem',
});
const StyledTabel = styled.div({
    '.thead': {
        '.tr': {
            borderBottom: `1px solid ${colors.black50}`,
            paddingBottom: '10px',
        },
    },
    '.tbody': {
        backgroundColor: colors.pageBackground,
    },
});

const FlexContainer = styled.div({
    display: 'flex',
    gap: '',
    alignItems: 'center',
});

const NoResult = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
});
const FilterWrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '22px',
});
const ButtonWrapper = styled(Link)({});

const getStyles = (
    props,
    style: {
        justifyContent?: string;
        alignItems?: string;
        display?: string;
    }
) => [
    props,
    {
        style: {
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            display: 'flex',
            ...style,
        },
    },
];
const headerProps = (props, { column }) => getStyles(props, column.style);

const cellProps = (props, { cell }) => getStyles(props, cell.column.style);

type Props = {
    Data: Record<string, unknown>[] | undefined;
    Columns: Column<object>[];
    filterArea?: ReactNode;
    edit?: boolean;
    search?: boolean;
};

const MaintenanceTable = ({ Data, Columns, filterArea, search = true, edit = true }: Props) => {
    const columns = useMemo<readonly Column<object>[]>(() => Columns, [Data, Columns]);
    const data = useMemo(() => (Data ? Data : []), [Data]);
    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: {
                hiddenColumns: ['year'],
            },
        },
        useGlobalFilter,
        useSortBy,
        useRowSelect,
        useFlexLayout,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    ...columns,
                    {
                        style: {
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                        id: 'selection',
                        Cell: ({ row }) => {
                            const maintenance = row.original as Maintenance;
                            return edit ? (
                                <ButtonWrapper to={`/maintenance/${maintenance.id}/edit`}>
                                    <Button>{L('edit_maintenance')}</Button>
                                </ButtonWrapper>
                            ) : null;
                        },
                    },
                ];
            });
        },
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    ...columns,
                    {
                        style: {
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                        id: 'complete',
                        Cell: ({ row }) => {
                            const maintenance = row.original as Maintenance;
                            return edit ? (
                                <ButtonWrapper to={`/maintenance/${maintenance.id}/complete`}>
                                    <Button>{L('complete')}</Button>
                                </ButtonWrapper>
                            ) : null;
                        },
                    },
                ];
            });
        }
    );

    const { getTableProps, rows, headerGroups, prepareRow, state, setGlobalFilter } = tableInstance;

    const { globalFilter } = state;

    const areas = useMemo(() => {
        const yearsObj: { [year: string]: Row<object>[] } = {};
        rows.forEach((row) => {
            const original = row.original as Maintenance;
            const year = dayjs(original.completed_date || original.planned_date || Date.now()).format('YYYY');
            const yearsObjValue = yearsObj[year];
            if (yearsObjValue) {
                return (yearsObj[year] = [...yearsObj[year], row]);
            }
            yearsObj[year] = [row];
        });

        return yearsObj;
    }, [rows]);

    return (
        <Container>
            {search && (
                <FilterWrapper>
                    {filterArea}
                    <GlobalFilter
                        placeholder={L('search_maintenance')}
                        filter={globalFilter}
                        setFilter={setGlobalFilter}
                    />
                </FilterWrapper>
            )}
            <StyledTabel {...getTableProps()}>
                <div className="thead">
                    {headerGroups.map((headerGroup) => {
                        return (
                            <div
                                {...headerGroup.getHeaderGroupProps({
                                    // style: { paddingRight: '15px' },
                                })}
                                className="tr"
                                key={headerGroup.getFooterGroupProps().key}
                            >
                                {headerGroup.headers.map((column) => (
                                    <div
                                        {...column.getHeaderProps(column.getSortByToggleProps(headerProps))}
                                        className="th"
                                        key={column.getHeaderProps().key}
                                    >
                                        <FlexContainer>
                                            {column.render('Header')}
                                            {column.canSort && (
                                                <SortArrows
                                                    activeSort={
                                                        column.isSorted
                                                            ? column.isSortedDesc
                                                                ? 'desc'
                                                                : 'asc'
                                                            : undefined
                                                    }
                                                />
                                            )}
                                        </FlexContainer>
                                    </div>
                                ))}
                            </div>
                        );
                    })}
                </div>
                <div className="tbody">
                    {Object.entries(areas).map(([year, rows], i) => {
                        return (
                            <YearGrouping
                                cellProps={cellProps}
                                prepareRow={prepareRow}
                                rows={rows}
                                year={year}
                                key={year}
                                initialExpanded={i === 0}
                            />
                        );
                    })}
                </div>
            </StyledTabel>
            {rows.length ? null : (
                <NoResult>
                    <h3>Vi kan inte hitta det du söker.</h3>
                </NoResult>
            )}
        </Container>
    );
};

export default MaintenanceTable;
