import MembersApi, { MemberDetail } from '@app/api/public/MembersApi';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { L } from '../../../lib/i18n';
import { getMemberListQueryKey } from './useGetMemberList';

const inviteMember = async ({ membershipId }: { membershipId: MemberDetail['id'] }) => {
    if (membershipId) {
        const response = await MembersApi.inviteMember(membershipId);
        if (response.status === 'error') throw new Error(response.message);
        return response;
    } else {
        throw new Error('no_property_for_member');
    }
};
const useInviteMember = () => {
    const queryClient = useQueryClient();

    return useMutation(inviteMember, {
        onSuccess: () => {
            toast.success(`${L('invite_sent')}`);
        },
        onError: (err) => {
            console.log(err);
            /* Shows error message */
            const error = err instanceof Error ? L(`${err.message}`) : L('reset_error').message;
            const message = error;
            toast.error(`${message}`);
        },
        onSettled: () => {
            queryClient.invalidateQueries([getMemberListQueryKey]);
        },
    });
};

export default useInviteMember;
