import { BookingResource } from '@app/api/models/Bookings';
import BookingsApi from '@app/api/public/BookingsApi';
import { useQuery } from 'react-query';
import { getBookingResourcesQueryKey } from './useGetBookingResources';


const getBookingResourceById = (id: BookingResource['id']) => {
    return BookingsApi.getResource(id);
};

const useGetBookingResourceById = (id: number) => {
    return useQuery(getBookingResourcesQueryKey.id(id), () => getBookingResourceById(id));
};

export default useGetBookingResourceById;
