const findAndReplaceNestedObjects = (key: string, value: unknown, obj: Record<string, unknown>) => {
    key.split('.').reduce((acc, currName, index, nameArray) => {
        if (index === nameArray.length - 1) {
            acc[currName] = value;
        } else {
            return acc[currName];
        }
    }, obj);
};

export default findAndReplaceNestedObjects;
