import styled from '@emotion/styled';
import React from 'react';
import { Button } from '../../../components/Buttons/Button';
import { margin } from '../../../components/Shared/Style/margin';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import useInviteCheckedMembers from '../hooks/useInviteCheckedMembers';
import Spinner from '../../../components/Spinner/Spinner';
import useRenderCsv from '../hooks/useRenderCsv';
import { downloadFile } from '../../../lib/download';
import { ListingMember } from '@app/api/public/MembersApi';

const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const TopArea = ({
    toggleIsModalOpen,
    selectedMembers,
    onSubmit,
}: {
    toggleIsModalOpen: () => void;
    selectedMembers: ListingMember[];
    onSubmit: (value: boolean) => void;
}) => {
    const { mutate: inviteAllCheckedMembers } = useInviteCheckedMembers();

    const { mutate: csvFile, status } = useRenderCsv();

    const inviteChecked = () => {
        inviteAllCheckedMembers({ memberArray: selectedMembers });
        onSubmit(false);
    };

    const onDownload = async () => {
        csvFile(
            {},
            {
                onSuccess: (data) => {
                    downloadFile(data.name, data);
                },
            }
        );
    };

    return (
        <Wrapper>
            <CardTitle>{L('all_members_description')}</CardTitle>
            <div style={{ display: 'flex' }}>
                {selectedMembers.length === 0 ? (
                    <Button
                        style={{ marginRight: margin.s }}
                        disabled
                        variant="secondary"
                        onClick={() => inviteChecked()}
                    >
                        {L('invite_all_checked')}
                    </Button>
                ) : (
                    <Button style={{ marginRight: margin.s }} variant="primary" onClick={() => inviteChecked()}>
                        {L('invite_all_checked')}
                    </Button>
                )}
                <Button onClick={onDownload} style={{ marginRight: margin.s }} variant={'primary'}>
                    <span
                        style={{
                            visibility: status === 'loading' ? 'hidden' : 'inherit',
                        }}
                    >
                        {L('export_members')}
                    </span>
                </Button>

                <Button onClick={toggleIsModalOpen}>
                    {L('new')} {L('member').toLowerCase()}
                </Button>
            </div>
        </Wrapper>
    );
};

export default TopArea;
