import styled from '@emotion/styled';
import React from 'react';
import ContractCard from './ContractCard';
import { useGetContracts } from '../hooks/useContract';

const Container = styled.div({
    width: '100%',
});

const FirstLine = styled.div({
    borderTop: '1px solid grey',
});

const NoContracts = styled.p({
    marginLeft: '0.5rem',
});

const Contracts = () => {
    const { data: contracts } = useGetContracts();

    if (!contracts) return null;

    return (
        <Container>
            {contracts?.length <= 0 && <NoContracts>Här var det tomt.</NoContracts>}
            {contracts?.length > 0 && <FirstLine />}
            {contracts?.map((contract, i) => (
                <ContractCard key={i} contract={contract} />
            ))}
        </Container>
    );
};

export default Contracts;
