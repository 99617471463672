import TicketsApi from '@app/api/public/TicketsApi';
import { useQuery } from 'react-query';

export const TicketSettingsQueryKey = {
    all: ['ticketSettings'],
};

export const useTicketSettingQuery = () => {
    return useQuery({
        queryKey: TicketSettingsQueryKey.all,
        queryFn: () => {
            return TicketsApi.getProjectServiceData();
        },
    });
};
