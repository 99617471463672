import React, { useState } from 'react';
import { L } from '../../../../lib/i18n';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle, { CardSpan } from '../../../../components/typography/CardTitle';
import { useLocalStorage } from 'react-use';
import { memberInformationQueryKeys, useGetContentInformationList } from '../hooks/memberInformationQueries';
import { escapeRegex } from '../../../../lib/regex';
import {
    CardGrid,
    Card,
    CardImage,
    CardGridTitle,
    CardDescription,
    CardTimestamp,
} from '../../../../components/CardGrid/CardGrid';
import useGetSignedUrlById from '../../../../hooks/useGetSignedUrlById';
import { medium } from '../../../../lib/imageSize';
import { Link } from 'react-router-dom';
import { Button } from '../../../../components/Buttons/Button';
import GlobalFilter from '../../../../components/Table/filterComponents/GlobalFilter';
import ToggleVIew from '../../../../components/ToggleView/ToggleVIew';
import styled from '@emotion/styled';
import { style } from '../../../../components/Shared/Style';
import usePublishInformation from '../hooks/usePublishInformation';
import { useQueryClient } from 'react-query';
import { ContentInformationItem } from '@app/api/models/ContentInformation/ContentInformationModels';
import { tableHeader, title } from '../../../../components/Shared/Style/typography';
import { convert } from 'html-to-text';
import DropdownButton from '../../../../components/Buttons/DropdownButton';
import EmptyState from '../../../../components/EmptyState/EmptyState';
import { CardGridImage } from '../../../../components/CardGrid/CardImage';

const templateColumns = '1fr max-content' as const;

const Headers = styled.div(() => ({
    margin: '0px',
    display: 'grid',
    gridTemplateColumns: templateColumns,
    columnGap: '2rem',
    alignItems: 'baseline',
    padding: '0px 0.5rem',
}));

const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '24px',
    flexDirection: 'row',
    gap: '8px',
});

const FeedListItem = styled(Link)({
    borderBottom: `1px solid ${style.colors.divider}`,
    padding: '1rem 0.5rem',
    display: 'grid',
    gap: '2rem',
    alignItems: 'center',
    gridTemplateColumns: templateColumns,
    '&:first-of-type': {
        borderTop: `1px solid ${style.colors.divider}`,
    },
});

const StyledDropdownButton = styled(DropdownButton.Button)({
    justifySelf: 'end',
});

const ListTitle = styled.span({
    ...title,
});

const HeaderText = styled.span({
    ...tableHeader,
});

const InformationDraftListpage = () => {
    const queryClient = useQueryClient();
    const [view, setView] = useLocalStorage<'list' | 'card'>('informationView', 'list');
    const [localSubmitButtonState, setSubmitButtonState] = useLocalStorage<'publish' | 'publish_and_notify'>(
        'informationViewSubmitState',
        'publish_and_notify'
    );
    const submitButtonState = localSubmitButtonState || 'publish_and_notify';
    const { data: informationList, status: informationStatus } = useGetContentInformationList('draft');
    const [filter, setFilter] = useState('');

    const { mutate: publishInformation } = usePublishInformation();

    if (informationStatus === 'loading') return <CardTitle>{L(`all_information_draft`)}</CardTitle>;

    if (!informationList) return <div>{L('error')} kunde inte hämta nyhetslistan</div>;

    const searchRegexp = new RegExp(`(${escapeRegex(filter)})`, 'gmi');

    const filteredList = informationList.filter((item) => {
        if (!filter) return true;
        const title = item.title.match(searchRegexp);
        const description = item.description.match(searchRegexp);
        return title || description;
    });

    const onPublish = (information: ContentInformationItem, notify = false) => {
        publishInformation(
            { id: information.id, notify },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(memberInformationQueryKeys.lists());
                },
            }
        );
    };

    const InformationList =
        view === 'list' ? (
            !filteredList.length ? (
                <EmptyState>
                    <EmptyState.Title>
                        {L('no_search_title')} &quot;{filter}&quot;
                    </EmptyState.Title>
                    <EmptyState.Description>{L('no_search_description')}</EmptyState.Description>
                </EmptyState>
            ) : (
                <div>
                    <Headers>
                        <HeaderText>{L('title')}</HeaderText>
                        <Button style={{ visibility: 'hidden' }}>{L('publish')}</Button>
                    </Headers>
                    {filteredList &&
                        filteredList.map((information) => (
                            <FeedListItem key={information.id} to={`/information/${information.id}`}>
                                <ListTitle>{information.title}</ListTitle>
                                <DropdownButton
                                    optionId={submitButtonState}
                                    setOptionId={(value) => setSubmitButtonState(value)}
                                    options={[
                                        {
                                            id: 'publish',
                                            button: (
                                                <StyledDropdownButton onClick={() => onPublish(information)}>
                                                    {L('publish')}
                                                </StyledDropdownButton>
                                            ),
                                            dropdownItem: (
                                                <DropdownButton.DropdownItem>
                                                    {L('publish')}
                                                </DropdownButton.DropdownItem>
                                            ),
                                        },
                                        {
                                            id: 'publish_and_notify',
                                            button: (
                                                <StyledDropdownButton onClick={() => onPublish(information, true)}>
                                                    {L('publish_and_notify')}
                                                </StyledDropdownButton>
                                            ),
                                            dropdownItem: (
                                                <DropdownButton.DropdownItem>
                                                    {L('publish_and_notify')}
                                                </DropdownButton.DropdownItem>
                                            ),
                                        },
                                    ]}
                                />
                            </FeedListItem>
                        ))}
                </div>
            )
        ) : !filteredList.length ? (
            <EmptyState>
                <EmptyState.Title>
                    {L('no_search_title')} &quot;{filter}&quot;
                </EmptyState.Title>
                <EmptyState.Description>{L('no_search_description')}</EmptyState.Description>
            </EmptyState>
        ) : (
            <CardGrid>
                {filteredList.map((item) => (
                    <Link key={item.id} to={`/information/${item.id}`}>
                        <Card>
                            <CardGridImage originalId={item.cover_image_data?.original?.id || ''} />
                            <CardTimestamp date={''} />
                            <CardGridTitle>{item.title}</CardGridTitle>
                            <CardDescription>{convert(item.description)}</CardDescription>
                        </Card>
                    </Link>
                ))}
            </CardGrid>
        );

    return (
        <CardWithDivider
            topArea={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <CardTitle>
                        {L(`all_information_draft`)} <CardSpan>({informationList.length})</CardSpan>
                    </CardTitle>
                    <Link to={`/information/new`}>
                        <Button>{L('information_content')}</Button>
                    </Link>
                </div>
            }
            mainArea={
                <>
                    {!informationList.length ? (
                        <EmptyState>
                            <EmptyState.Title>{L('no_information_draft_title')}</EmptyState.Title>
                            <EmptyState.Description>{L('no_information_draft_description')}</EmptyState.Description>
                            <Link to={`/information`}>
                                <EmptyState.Button variant="filledPrimary">
                                    {L('no_information_draft_button')}
                                </EmptyState.Button>
                            </Link>
                        </EmptyState>
                    ) : (
                        <>
                            <Wrapper>
                                <GlobalFilter placeholder="Sök" filter={filter} setFilter={setFilter} />
                                <ToggleVIew view={view || 'list'} setView={setView} />
                            </Wrapper>
                            {InformationList}
                        </>
                    )}
                </>
            }
        />
    );
};

export default InformationDraftListpage;
