import { useQueryClient, useMutation } from 'react-query';
import ServicePartnerApi from '@app/api/public/ServicePartnersApi';
import { servicePartnersQueryKeys } from './servicePartnerQueries';
import toast from 'react-hot-toast';
import { L } from '../../../lib/i18n';

type Variables = { itemId: string; name: string; file: File };

export const SaveServicePartnerDocument = async (itemId: number, name: string, file: File) => {
    return ServicePartnerApi.saveDocument(itemId, name, file);
};

const useSaveServicePartnerDocument = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (variables: Variables) => SaveServicePartnerDocument(+variables.itemId, variables.name, variables.file),
        {
            onMutate: (file) => {
                const toastId = toast.loading(`${L('saving')} ${file.name}`);
                return toastId;
            },
            onError: (_error, file, toastId: string) => {
                toast.error(`${L('save_error')}`, {
                    id: toastId,
                });
            },
            onSuccess: (_data, file, toastId: string) => {
                toast.success(`${file.name} ${L('saved')}`, {
                    id: toastId,
                });
            },

            onSettled: (data, error, variables) => {
                queryClient.invalidateQueries(servicePartnersQueryKeys.documentsPartnerId(variables.itemId));
            },
        }
    );
};

export default useSaveServicePartnerDocument;
