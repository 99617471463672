import BoardMembersApi, { NewBoardMember } from '@app/api/public/BoardMembersApi';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { L } from '../../../lib/i18n';
import { getBoardMemberListQueryKey } from './useGetBoardMembers';

const createBooardMember = async (boardMember: NewBoardMember, invite = false) => {
    const res = await BoardMembersApi.createFromEmail(boardMember);
    if (res.status === 'error') return Promise.reject(res.message);
    if (invite && res.data) await BoardMembersApi.invite(res.data?.id);
    return res.data;
};

const useCreateBoardMember = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (boardMember: NewBoardMember & { invite?: boolean }) => createBooardMember(boardMember, boardMember.invite),
        {
            onError: (err) => {
                if (err instanceof Error) console.log(err.message);
                /* Shows error message */
                const error = typeof err === 'string' ? err : new Error(L('reset_error')).message;
                const message = error;
                toast.error(`${L(message)}`);
            },
            onSettled: () => {
                queryClient.invalidateQueries([getBoardMemberListQueryKey]);
            },
        }
    );
};

export default useCreateBoardMember;
