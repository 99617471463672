import { useQuery } from 'react-query';
import NotificationsApi from '@app/api/public/NotificationsApi';

export const getNotificationKeys = {
    all: [{ scope: 'notifications' }] as const,
    lists: () => [{ ...getNotificationKeys.all[0], entity: 'list' }] as const,
    list: (search: string, page: number, type: string | undefined) =>
        [{ ...getNotificationKeys.lists()[0], search, page, type }] as const,
    counts: () => [{ ...getNotificationKeys.all[0], entitiy: 'count' }] as const,
    notificationTypes: () => [{ ...getNotificationKeys.all[0], entitiy: 'notificationtypes' }] as const,
};

export const useGetNotificationBellCount = () => {
    return useQuery(getNotificationKeys.counts(), () => NotificationsApi.getNotificationCount());
};

export const useNotificationList = (search: string, page: number, type: string | undefined = undefined) => {
    return useQuery(
        getNotificationKeys.list(search, page, type),
        async () => await NotificationsApi.list(search, page, type),
        {
            keepPreviousData: true,
        }
    );
};

export const useGetNotificationTypes = () => {
    return useQuery(getNotificationKeys.notificationTypes(), () => NotificationsApi.getNotificationTypes());
};
