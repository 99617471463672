import React, { useEffect, useState } from 'react';
import { L } from '../../../../lib/i18n';
import styled from '@emotion/styled';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import BoardContentInformationApi from '@app/api/public/BoardContentInformationApi';
import { Button } from '../../../../components/Buttons/Button';
import { useHistory, useParams } from 'react-router-dom';
import useRemoveInformation from '../hooks/useRemoveInformation';
import { boardInformationQueryKeys, useGetBoardInformationById } from '../hooks/boardInformationQueries';
import toast from 'react-hot-toast';
import ConfirmationButton from '../../../../components/Buttons/ConfirmationButton';
import { margin } from '../../../../components/Shared/Style/margin';
import StatusArea from '../../../../components/StatusArea/StatusArea';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { style } from '../../../../components/Shared/Style';
import { useMutation, useQueryClient } from 'react-query';
import useGetSignedUrlById from '../../../../hooks/useGetSignedUrlById';
import Label from '../../../../components/Forms/Label';
import ImageDropZone from '../../../../components/ImageDropZone/ImageDropZone';
import Input from '../../../../components/Forms/Input';
import triggerDirtyFieldsToats from '../../../../components/Forms/DirtyFieldsToast';
import Quill from '../../../../components/Quill/Quill';
import useSetInformationAsDraft from '../hooks/useSetInformationAsDraft';
import GroupDocumentsArea from '../../../../components/document/GroupDocumentsArea';
import { notificationToast } from '../../notificationToast/notificationToast';
import { DocumentDetail } from '@app/api/models/Documents';

const StyledCardWithDivider = styled(CardWithDivider)({
    width: 'fit-content',
});

const GridContainer = styled.form(({ theme }) => ({
    display: 'grid',
    gap: '1rem',
    columnGap: '2rem',
    backgroundColor: theme.colors.white,
    alignItems: 'start',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateAreas: `
    "img          img               img"
    "from         to                ..."
    "title        title             title"
    "description  description description"
    "buttons      buttons         buttons"
    `,
    paddingBottom: '0px',
    maxWidth: '600px',
}));

const EditInformationContainer = styled.div({
    display: 'grid',
    gap: '2rem',
    gridTemplateColumns: 'auto auto',
    gridTemplateAreas: `
    "editArea statusArea"
    `,
});

const StyledImageDropZone = styled(ImageDropZone)({
    aspectRatio: '4/3',
});

const ButtonContainer = styled.div({
    width: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: `${margin.s}px`,
});

const FlexWrapper = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    alignItems: 'start',

    '& > *': {
        flexBasis: '600px',
        flexGrow: 1,
    },
});

const informationFormPublishSchema = z.object({
    title: z.string().min(1, { message: L('required') }),
    description: z.string().min(1, { message: L('required') }),
});

type EditPublishedInformation = {
    information: z.infer<typeof informationFormPublishSchema> & {
        id: number;
        cover_image_data?: DocumentDetail['document_data'] | null;
    };
    image?: File;
    notify: boolean;
};

const saveNewInformation = (variables: EditPublishedInformation) => {
    return BoardContentInformationApi.editPublishedInformation(variables);
};

const InformationEditPublished = () => {
    const { id } = useParams<{ id: string }>();

    const { mutate: remove } = useRemoveInformation();

    const { data: boardInfo } = useGetBoardInformationById(+id);

    const [data, setData] = useState(boardInfo);

    useEffect(() => {
        setData(boardInfo);
    }, [boardInfo]);

    const history = useHistory();

    const queryClient = useQueryClient();
    const { data: signedUrl } = useGetSignedUrlById(data?.cover_image_data?.original?.id, undefined, false);

    const [imgFile, setImgFile] = useState<File | null>(null);
    const [imgUrl, setImgUrl] = useState<string>('');

    const { mutate: editPublishedInformation } = useMutation(
        (variables: EditPublishedInformation) => saveNewInformation(variables),
        {
            onMutate: () => {
                toast.loading(L('loading'), {
                    id: 'send-notification',
                });
            },
            onSuccess: () => {
                setImgFile(null);
                setImgUrl('');
            },
            onSettled: () => {
                queryClient.invalidateQueries(boardInformationQueryKeys.detail(+id));
                queryClient.invalidateQueries(boardInformationQueryKeys.lists());
            },
        }
    );

    const onUpload = (files: File[]) => {
        setImgFile(files[0]);
        setImgUrl(URL.createObjectURL(files[0]));
    };

    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isDirty },
    } = useForm({
        resolver: zodResolver(informationFormPublishSchema),
        values: {
            title: data?.title || '',
            description: data?.description || '',
        },
    });

    const submit = (formData: EditPublishedInformation['information']) => {
        const publish = (notify: boolean) => {
            return editPublishedInformation(
                {
                    information: {
                        id: +id,
                        description: formData.description,
                        title: formData.title,
                        cover_image_data: data?.cover_image_data,
                    },
                    image: imgFile ?? undefined,
                    notify,
                },
                {
                    onSuccess: () => {
                        toast.success(L('information_updated'));
                        setImgFile(null);
                    },
                    onSettled: () => {
                        toast.dismiss('send-notification');
                    },
                }
            );
        };

        return notificationToast({
            notify: (notify) => publish(notify),
            type: 'update',
        });
    };

    const onRemove = () => {
        if (!data) return;
        remove(
            { id: data.id },
            {
                onSuccess: () => {
                    toast.success(`${data.title} ${L('removed')}`);
                    history.push('/board-information');
                },
            }
        );
    };
    const { mutate } = useSetInformationAsDraft();
    const moveToDraft = () => {
        if (isDirty)
            return triggerDirtyFieldsToats({
                continueButtonText: L('move_to_draft'),
                continueFn: () => {
                    mutate({ id: +id });
                },
            });
        mutate({ id: +id });
    };

    return (
        <FlexWrapper>
            <StyledCardWithDivider
                topArea={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <CardTitle>{L('edit_information')}</CardTitle>
                        <ButtonContainer>
                            <ConfirmationButton props={{ onConfirm: onRemove }} />
                            <Button onClick={moveToDraft}>{L('move_to_draft')}</Button>
                        </ButtonContainer>
                    </div>
                }
                mainArea={
                    <EditInformationContainer>
                        <GridContainer onSubmit={handleSubmit(submit)}>
                            <div style={{ gridArea: 'img' }}>
                                <Label title={L('image')} />
                                <StyledImageDropZone
                                    dropzoneOptions={{}}
                                    onDrop={onUpload}
                                    url={imgUrl || signedUrl || ''}
                                    remove={() => {
                                        if (imgUrl || imgFile) {
                                            setImgUrl('');
                                            setImgFile(null);
                                            return;
                                        }
                                        if (!data?.cover_image_data) return;
                                        const mutatedData = { ...data, cover_image_data: null };
                                        setData(mutatedData);
                                    }}
                                />
                            </div>

                            <Label
                                style={{ gridArea: 'title' }}
                                htmlFor="title"
                                title={`${L('title')}*`}
                                error={errors?.title?.message}
                            >
                                <Input {...register('title', { required: true })} />
                            </Label>

                            <div style={{ gridArea: 'description' }}>
                                <Label title={`${L('description')}*`} error={errors?.description?.message} />
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) => {
                                        return <Quill {...field} placeholder="Skriv något här..." />;
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    placeSelf: 'end',
                                    whiteSpace: 'nowrap',
                                    display: 'flex',
                                    gap: style.margin.m,
                                    gridArea: 'buttons',
                                }}
                            >
                                <Button
                                    type="button"
                                    variant="primary"
                                    onClick={() => {
                                        if (isDirty) {
                                            return triggerDirtyFieldsToats({
                                                continueButtonText: L('continue_without_saving'),
                                                continueFn: () => {
                                                    history.push('/board-information');
                                                },
                                            });
                                        }
                                        history.push('/board-information');
                                    }}
                                    role="link"
                                >
                                    {L('cancel')}
                                </Button>

                                <Button variant="filledPrimary">{L('save')}</Button>
                            </div>
                        </GridContainer>
                        <StatusArea
                            status={{
                                created_by_name: data?.created_by_name,
                                created_by_email: data?.created_by_email,
                                notified_at: { value: data?.notified_at },
                                created_at: data?.created_at,
                            }}
                            style={{ alignSelf: 'start', gridArea: 'statusArea' }}
                        />
                    </EditInformationContainer>
                }
            />

            <CardWithDivider
                style={{ width: '100%' }}
                topArea={<CardTitle>{L('documents')}</CardTitle>}
                mainArea={
                    <GroupDocumentsArea
                        documentQueryKey={boardInformationQueryKeys.document(+id)}
                        getDocuments={async () => {
                            return (await BoardContentInformationApi.listDocuments(+id)).map((doc) => ({
                                id: doc.id,
                                originalId: doc.document_data.original.id,
                                filename: doc.document_data.original.metadata.filename,
                                name: doc.name,
                                content_document_group_id: doc.content_document_group_id,
                                sort: doc.sort,
                            }));
                        }}
                        removeDocument={(docId) =>
                            BoardContentInformationApi.deleteDocumentFromContentInformation(docId)
                        }
                        renameDocument={(docId, name) => BoardContentInformationApi.updateDocumentName(docId, name)}
                        saveDocument={({ documentGroupId, file, name }) =>
                            BoardContentInformationApi.saveDocument(+id, name, file, documentGroupId)
                        }
                        regroupDocument={(docId, groupId) =>
                            BoardContentInformationApi.updateDocumentGroup(docId, groupId)
                        }
                    />
                }
            />
        </FlexWrapper>
    );
};

export default InformationEditPublished;
