import BookingsApi from '@app/api/public/BookingsApi';
import { useQuery } from 'react-query';

export const getUserBookingsQueryKey = 'useGetUserBookings';
const useGetUserBookings = ({ userId, resourceId }: { userId?: string; resourceId?: number }) => {
    return useQuery(
        [getUserBookingsQueryKey, { userId, resourceId }],
        () => {
            if (!userId || !resourceId) return;
            return BookingsApi.listUserBookings(userId, resourceId);
        },
        {
            enabled: !!userId && !!resourceId,
        }
    );
};

export default useGetUserBookings;
