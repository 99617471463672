import React, { useState } from 'react';
import Article from './Article';
import Main from './Main';
import styled from '@emotion/styled';
import FormatFeedDate from './FeedTimeFormat';
import FeedTitle from './FeedTitle';
import { BlogItem as BlogItemType } from '@app/api/models/Blog';

const H1 = styled.h1({
    overflowWrap: 'break-word',
    fontSize: '1.5rem',
    margin: '1.5rem, 0',
});

const Info = styled(H1)({
    fontSize: '1rem',
    display: 'flex',
    margin: '0px',
    justifyContent: 'space-between',
    alignItems: 'start',
});

const TimeAndBoard = styled.div({
    display: 'flex',
    flexDirection: 'column',
});

const TitleWrapper = styled.div({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const Img = styled.img({
    marginRight: '0.5rem',
    height: '1rem',
    width: '1rem',
});

const Divider = styled.div(({ theme }) => ({
    borderBottom: `1px solid ${theme.colors.divider}`,
    margin: '1.5rem 0px',
}));

const ProjectName = styled.span(({ theme }) => ({
    fontsize: '0.8rem',
    fontWeight: 500,
    color: theme.colors.textColor2,
}));

const BlogItem: React.FC<BlogItemType & { isHighlighted?: boolean }> = ({
    title,
    description,
    published_at,
    isHighlighted,
}) => {
    const [collapsed, setCollapsed] = useState(true);

    return (
        <Article isHighlighted={isHighlighted}>
            <Info>
                <TitleWrapper>
                    <Img
                        src="https://s3.eu-west-1.amazonaws.com/public.ourliving.se/ourliving/ourliving-icon.png"
                        alt="ourliving"
                        width="30px"
                    />
                    <ProjectName>OurLiving</ProjectName>
                </TitleWrapper>
                <TimeAndBoard>
                    <FormatFeedDate date={published_at} />
                </TimeAndBoard>
            </Info>
            <Divider />
            {title && <FeedTitle title={title} />}
            <Main onClick={() => setCollapsed((prev) => !prev)} collapse={collapsed}>
                {description}
            </Main>
        </Article>
    );
};

export default BlogItem;
