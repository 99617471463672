import { Maintenance } from '@app/api/public/MaintenancesApi';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';
import Input from '../../../components/Forms/Input';
import InputSplit from '../../../components/Forms/InputSplit';
import Label from '../../../components/Forms/Label';
import Spinner from '../../../components/Spinner/Spinner';
import { L } from '../../../lib/i18n';
import useCompleteMaintenance from '../hooks/useCompleteMaintenance';
import MaintenaceRow from './MaintenaceRow';

const GridSectionCompleted = styled.form({
    width: '100%',
    display: 'grid',
    justifyContent: 'flex-start',
    gridColumnGap: '2rem',
    marginTop: '2rem',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateAreas: `
    "completedCost completedDate"
    "comment             comment"
    "ButtonWraper   ButtonWraper"
    `,
});

const ButtonWraper = styled.div({
    gridArea: 'ButtonWraper',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    alignItems: 'center',
    width: '100%',
    margin: '40px 0px 0px 0px',
});

const Textarea = styled.textarea({
    width: '100%',
    borderRadius: '4px',
    backgroundColor: '#FCFDFE',
    border: '1px solid #F0F1F7',
    height: '150px',
    resize: 'none',
    padding: '8px',
    fontSize: '14px',
    '&:focus': {
        outline: 'none',
    },
});

const StyledInput = styled(Input)({
    width: '100%',
    height: '32px',
});

const StyledInputSplit = styled(InputSplit)({
    '& input': {
        height: '32px',
    },
});

type Props = {
    maintenanceItem: Partial<Maintenance> | undefined;
    handleChange: (value: Partial<Maintenance>) => void;
};

const CompleteMaintenanceForm = ({ maintenanceItem, handleChange }: Props) => {
    const { status, mutate: save, error, reset } = useCompleteMaintenance();
    const history = useHistory();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!maintenanceItem) return;
        save({ ...maintenanceItem }, { onSuccess: () => history.push('/maintenance') });
    };

    const handleError = (error: unknown, name) => {
        if (typeof error === 'object') {
            return error?.[name];
        }
    };

    return (
        <div>
            {maintenanceItem && <MaintenaceRow maintenance={maintenanceItem} />}
            <GridSectionCompleted onSubmit={(e) => handleSubmit(e)}>
                <Label
                    title={'Total kostnad'}
                    error={handleError(error, 'completed_cost')}
                    style={{ gridArea: 'completedCost' }}
                >
                    <StyledInputSplit
                        type={'number'}
                        value={maintenanceItem?.completed_cost ? parseInt(maintenanceItem.completed_cost) : ''}
                        onChange={(e) => {
                            reset();
                            handleChange({ completed_cost: e.target.value });
                        }}
                        splitInput={'kr'}
                    />
                </Label>
                <Label
                    title={'Slutgiltligt datum'}
                    error={handleError(error, 'completed_date')}
                    style={{ gridArea: 'completedDate' }}
                >
                    <StyledInput
                        type={'date'}
                        value={
                            maintenanceItem?.completed_date
                                ? dayjs(maintenanceItem?.completed_date).format('YYYY-MM-DD')
                                : ''
                        }
                        onChange={(e) => {
                            reset();
                            handleChange({ completed_date: e.target.value });
                        }}
                    />
                </Label>
                <Label title={'Kommentar'} style={{ gridArea: 'comment' }}>
                    <Textarea
                        value={maintenanceItem?.comment || ''}
                        onChange={(e) => handleChange({ comment: e.target.value })}
                    />
                </Label>

                <ButtonWraper>
                    <Button onClick={() => history.goBack()} type="button" variant="filledPrimary">
                        {L('back')}
                    </Button>

                    {status === 'idle' && (
                        <Button type={'submit'} variant="filledPrimary">
                            {L('complete_maintenance')}
                        </Button>
                    )}
                    {status === 'loading' && (
                        <Button variant="filledPrimary" style={{ display: 'flex', justifyContent: 'center' }}>
                            <Spinner />
                        </Button>
                    )}
                    {status === 'error' && (
                        <Button type={'submit'} variant="filledPrimary">
                            {L('complete_maintenance')}
                        </Button>
                    )}
                    {status === 'success' && (
                        <Button type={'submit'} variant="filledPrimary">
                            {L('saved')}
                        </Button>
                    )}
                </ButtonWraper>
            </GridSectionCompleted>
        </div>
    );
};

export default CompleteMaintenanceForm;
