import BookingsApi from '@app/api/public/BookingsApi';
import { useQuery } from 'react-query';

export const getbookingResourceSlotsQueryKey = 'getbookingResourceSlots';
const getbookingResourceSlots = (id: number) => {
    return BookingsApi.listSlots(id);
};

const useGetBookingResourceSlots = (id: number) => {
    return useQuery([getbookingResourceSlotsQueryKey, id], () => getbookingResourceSlots(id));
};

export default useGetBookingResourceSlots;
