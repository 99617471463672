import { MemberFilter } from '@app/api/models/Members/MembersModels';
import styled from '@emotion/styled';
import React from 'react';
import { Dropdown } from '../../../../components/Dropdown/Dropdown';
import Filters from '../../../../components/Filters/Filters';
import { Loading } from '../../../../components/Loading/Loading';
import { L } from '../../../../lib/i18n';
import useGetProperties from '../../../members/hooks/useGetProperties';

const StyledDropdown = styled(Dropdown)({
    marginRight: '1rem',
});

const StyledFilters = styled(Filters)({
    marginTop: '1rem',
});

type Props = {
    setFilter: React.Dispatch<React.SetStateAction<MemberFilter>>;
    filter: MemberFilter;
};

const FilterArea: React.FC<Props> = ({ setFilter, filter }) => {
    const { data: properties } = useGetProperties();

    if (!properties) return <Loading />;

    return (
        <StyledFilters>
            <StyledDropdown
                isClearable
                label={'Status'}
                onChange={(value) => setFilter({ ...filter, accepted: value })}
                value={filter.accepted}
                options={
                    [
                        { value: 'Bekräftat', label: 'Bekräftat' },
                        { value: 'Ej bekräftad', label: 'Ej bekräftad' },
                    ]?.map((accepted) => {
                        return {
                            value: accepted.value,
                            label: L(`${accepted.label}`),
                        };
                    }) || []
                }
            />
        </StyledFilters>
    );
};

export default FilterArea;
