import * as React from 'react';
import { L } from '../../../lib/i18n';
import styled from '@emotion/styled';
import { style } from '../../../components/Shared/Style';
import { Loading } from '../../../components/Loading/Loading';
import { Button } from '../../../components/Buttons/Button';
import useGetBookingResources, { getBookingResourcesQueryKey } from '../hooks/useGetBookingResources';
import { useState } from 'react';
import Input from '../../../components/Forms/Input';
import useSaveNewBookingResource from '../hooks/useSaveNewBookingResource';
import { colors } from '../../../components/Shared/Style/colors';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle, { CardSpan } from '../../../components/typography/CardTitle';
import BookingCard from './BookingCard';
import { useModal } from '../../../components/Modal/Context/ModalContext';
import Modal from '../../../components/Modal/Modal';
import Label from '../../../components/Forms/Label';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import H2 from '../../../components/typography/H2';
import { Icon } from '../../../components/Icon/Icon';
import { useLocalStorage } from 'react-use';
import { Link, Route, Switch } from 'react-router-dom';
import Feed from '../../../components/Feed/Feed';
import GlobalFilter from '../../../components/Table/filterComponents/GlobalFilter';
import ToggleVIew from '../../../components/ToggleView/ToggleVIew';
import { escapeRegex } from '../../../lib/regex';
import EmptyState from '../../../components/EmptyState/EmptyState';
import { tableHeader, title, tooltip } from '../../../components/Shared/Style/typography';
import { formatDateIsoDayjs } from '@app/shared/lib/formatting';
import { Pagination } from '../../notifications/components/Pagination';
import useQueryParams from '../../../hooks/useQueryParams';
import BookingsApi from '@app/api/public/BookingsApi';
import { BookingResource } from '@app/api/models/Bookings';
import { Card, CardDescription, CardGrid, CardGridTitle, CardImage } from '../../../components/CardGrid/CardGrid';
import useGetSignedUrlById from '../../../hooks/useGetSignedUrlById';
import { medium } from '../../../lib/imageSize';
import { convert } from 'html-to-text';
import Clock from '../../../components/Icon/clock.svg';
import CalendarDay from '../../../components/Icon/calendar-day.svg';
import BookingItemList from './BookingItemList';
import InvisibleLinkWrapper from '../../../components/Link/InvisibleLinkWrapper';
import NewBookingsResourceForm from '../Components/NewBookingsResourceForm';
import { CardGridImage } from '../../../components/CardGrid/CardImage';

const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '24px',
    flexDirection: 'row',
    gap: '8px',
});

const FlexWrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
});

const StyledClock = styled(Clock)({
    height: '21px',
    width: '21px',
});
const StyledCalendarDay = styled(CalendarDay)({
    height: '21px',
    width: '21px',
});

const StyledCardDescription = styled(CardDescription)({
    height: '8rem',
    marginBottom: '1.5rem',
    overflow: 'hidden',
});

const SvgWrapper = styled.div({
    marginTop: '1rem',
    gap: '1rem',
    alignItems: 'center',
    display: 'flex',
});

//#endregion

export const BookingListPage = () => {
    const [view, setView] = useLocalStorage<'list' | 'card'>('bookingsView', 'list');
    const [filter, setFilter] = useState('');
    const bookingResource = useGetBookingResources('published');

    if (bookingResource.status === 'success') {
        const searchRegexp = new RegExp(`(${escapeRegex(filter)})`, 'gmi');

        const filteredList = bookingResource.data.filter((item) => {
            if (!filter) return true;
            const title = item.name.match(searchRegexp);
            const type = L(item.booking_resource_slot_type.name).match(searchRegexp);
            return title || type;
        });

        const BookingsList =
            view === 'list' ? (
                !filteredList.length ? (
                    <EmptyState>
                        <EmptyState.Title>
                            {L('no_search_title')} &quot;{filter}&quot;
                        </EmptyState.Title>
                        <EmptyState.Description>{L('no_search_description')}</EmptyState.Description>
                    </EmptyState>
                ) : (
                    <BookingItemList isDragDisabled={!!filter} bookingList={filteredList} />
                )
            ) : !filteredList.length ? (
                <EmptyState>
                    <EmptyState.Title>
                        {L('no_search_title')} &quot;{filter}&quot;
                    </EmptyState.Title>
                    <EmptyState.Description>{L('no_search_description')}</EmptyState.Description>
                </EmptyState>
            ) : (
                <CardGrid>
                    {filteredList.map((item) => {
                        return (
                            <Link key={item.id} to={`/booking/${item.id}`}>
                                <Card>
                                    <CardGridImage originalId={item.image_data?.id || ''} />
                                    <CardGridTitle>
                                        <SvgWrapper>
                                            {item.booking_resource_slot_type.name === 'slot' ? (
                                                <StyledClock />
                                            ) : (
                                                <StyledCalendarDay />
                                            )}
                                            {item.name}
                                        </SvgWrapper>
                                    </CardGridTitle>
                                    <StyledCardDescription>{convert(item.short_description)}</StyledCardDescription>
                                    <CardDescription style={{ fontStyle: 'italic' }}>
                                        {item.bookings_from_now} {L('incoming_bookings')}
                                    </CardDescription>
                                </Card>
                            </Link>
                        );
                    })}
                </CardGrid>
            );

        return (
            <>
                <CardWithDivider
                    topArea={
                        <FlexWrapper>
                            <CardTitle>
                                {L('all_booking_resources')} <CardSpan>({bookingResource.data.length})</CardSpan>
                            </CardTitle>
                            <InvisibleLinkWrapper to={'/booking/new'}>
                                <Button>{L('new_booking_resource')}</Button>
                            </InvisibleLinkWrapper>
                            <Switch>
                                <Route path={'/booking/new'}>
                                    <NewBookingsResourceForm type="published" />
                                </Route>
                            </Switch>
                        </FlexWrapper>
                    }
                    mainArea={
                        <>
                            {!bookingResource.data.length ? (
                                <EmptyState>
                                    <EmptyState.Title>{L('no_booking_title')}</EmptyState.Title>
                                    <EmptyState.Description>{L('no_booking_description')}</EmptyState.Description>
                                    <Link to={`/booking/new`}>
                                        <EmptyState.Button variant="filledPrimary">
                                            {L('no_booking_button')}
                                        </EmptyState.Button>
                                    </Link>
                                </EmptyState>
                            ) : (
                                <>
                                    <Wrapper>
                                        <GlobalFilter placeholder="Sök" filter={filter} setFilter={setFilter} />
                                        <ToggleVIew view={view || 'list'} setView={setView} />
                                    </Wrapper>
                                    {BookingsList}
                                </>
                            )}
                        </>
                    }
                />
            </>
        );
    }

    if (bookingResource.status === 'error') return <div>Kunde ej hämta BokningsResurser</div>;

    return <Loading />;
};
