import ContentInformationApi from '@app/api/public/ContentInformationApi';
import styled from '@emotion/styled';
import React, { HTMLAttributes, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { Button } from '../../../components/Buttons/Button';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import TrashCan from '../../../components/Icon/themed/TrashCan';
import { L } from '../../../lib/i18n';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { Icon } from '../../../components/Icon/Icon';

const handleReturn = (data: Awaited<ReturnType<typeof ContentInformationApi.removeDocumentgroup>>) => {
    if (data.status === 'error') {
        throw new Error(data.message);
    } else if (data.status === 'success') {
        return data;
    }
    return undefined;
};

const StyledDocumentGroup = styled.div(({ theme }) => {
    return {
        fontSize: '1rem',
        margin: '0px',
        display: 'grid',
        gridTemplateColumns: 'auto 1fr 1fr',
        gap: '0.5rem',
        color: theme.colors.textColor2,
        textDecoration: 'none',
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'start',
        borderBottom: `1px solid ${theme.colors.divider}`,

        '&:hover': {
            cursor: 'pointer',
        },
    };
});

const StyledDialog = styled.dialog(({ theme }) => ({
    padding: '22px 32px 22px 32px',
    backgroundColor: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.divider}`,
    boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.1)',
    maxWidth: '600px',
    width: '100%',
    overflow: 'visible',
}));

const Wrapper = styled.div({
    display: 'flex',
    gap: '0.5rem',
    justifyContent: 'end',
    marginTop: '1rem',
});

const TrashIcon = styled(TrashCan)(() => ({}));

const IconWrapper = styled.span({
    display: 'flex',
    alignItems: 'center',
    '.st0': { fill: '#AFAFAF' },
    cursor: 'grab',
});

type Props = {
    name: string;
    groupId: number;
    documentGroupQueryKey: unknown[];
} & HTMLAttributes<HTMLDivElement>;
const DocumentGroup = ({ name, groupId, documentGroupQueryKey, ...rest }: Props) => {
    const queryClient = useQueryClient();

    const { mutate: removeDocumentGroupMutation } = useMutation(
        async (documentGroup: { name: string; id: number }) => {
            return handleReturn(await ContentInformationApi.removeDocumentgroup(documentGroup.id));
        },
        {
            onMutate: (documentGroup) => {
                const toastId = toast.loading(`${L('removing')} ${documentGroup.name}`);
                return toastId;
            },
            onError: (error, _documentGroup, toastId: string) => {
                if (error instanceof Error && error.message === 'in_use') {
                    toast.error(L('document_group_in_use_err'), {
                        id: toastId,
                    });
                    return;
                }
                toast.error(`${L('remove_error')}`, {
                    id: toastId,
                });
            },
            onSuccess: (_data, documentGroup, toastId: string) => {
                toast.success(`${documentGroup.name} ${L('removed')}`, {
                    id: toastId,
                });
            },

            onSettled: () => {
                queryClient.invalidateQueries(documentGroupQueryKey);
            },
        }
    );

    const { mutate: renameDocumentGroupMutation } = useMutation(
        async ({ groupId, newName }: { groupId: number; newName: string }) => {
            return ContentInformationApi.saveDocuementGroup({
                name: newName,
                id: groupId,
            });
        },
        {
            onMutate: ({ newName }) => {
                const toastId = toast.loading(`${L('saving')} ${newName}`);
                return toastId;
            },
            onError: (_error, _documentGroup, toastId: string) => {
                toast.error(`${L('rename_error')}`, {
                    id: toastId,
                });
            },
            onSuccess: (_data, { newName }, toastId: string) => {
                toast.success(`${newName} ${L('saved')}`, {
                    id: toastId,
                });
            },

            onSettled: () => {
                queryClient.invalidateQueries(documentGroupQueryKey);
            },
        }
    );

    const [groupName, setGroupName] = useState(name);

    const dialogRef = React.useRef<HTMLDialogElement>(null);
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!groupName) return;
        renameDocumentGroupMutation(
            { groupId, newName: groupName },
            {
                onSuccess: () => {
                    dialogRef.current?.close();
                },
            }
        );
    };

    const handleRemove = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        e.stopPropagation();
        removeDocumentGroupMutation({
            id: groupId,
            name: groupName,
        });
    };

    const sortable = useSortable({ id: groupId });
    const { attributes, listeners, setNodeRef, transform, transition } = sortable;

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <>
            <StyledDialog ref={dialogRef}>
                <form onSubmit={onSubmit}>
                    <Label title={L('new_document_group_name')}>
                        <Input value={groupName} onChange={(e) => setGroupName(e.target.value)} />
                    </Label>
                    <Wrapper>
                        <Button
                            type={'button'}
                            onClick={() => {
                                dialogRef.current?.close();
                                setGroupName('');
                            }}
                        >
                            {L('cancel')}
                        </Button>
                        <Button disabled={!groupName} variant="filledPrimary">
                            {L('save')}
                        </Button>
                    </Wrapper>
                </form>
            </StyledDialog>

            <StyledDocumentGroup
                ref={setNodeRef}
                style={style}
                onClick={() => dialogRef.current?.showModal()}
                {...rest}
            >
                <div
                    style={{
                        boxSizing: 'border-box',
                        minWidth: '0px',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        display: 'grid',
                        padding: '1rem 0px',
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    {...attributes}
                    {...listeners}
                >
                    <IconWrapper {...attributes} {...listeners}>
                        <Icon.VerticalMoreIcon
                            size="small"
                            style={{
                                justifySelf: 'left',
                                visibility: 'visible',
                            }}
                        />
                        <Icon.VerticalMoreIcon
                            size="small"
                            style={{
                                justifySelf: 'left',
                                visibility: 'visible',
                                marginLeft: '-6px',
                            }}
                        />
                    </IconWrapper>
                </div>
                <span
                    style={{
                        padding: '1rem 0px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {name}
                </span>
                <span
                    style={{
                        padding: '1rem 0px',
                        justifySelf: 'end',
                    }}
                >
                    <TrashIcon role="button" onClick={handleRemove} />
                </span>
            </StyledDocumentGroup>
        </>
    );
};

export default DocumentGroup;
