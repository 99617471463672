// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1d6eMECJwHrJqr-XgBx5qg {\n  width: 40px;\n  height: 40px;\n  margin: 100px auto;\n  background-color: #333;\n\n  border-radius: 100%;  \n  -webkit-animation: _1sVUmNMvpUqcjZVpbuPR0E 1.0s infinite ease-in-out;\n  animation: _1sVUmNMvpUqcjZVpbuPR0E 1.0s infinite ease-in-out;\n}\n\n@-webkit-keyframes _1sVUmNMvpUqcjZVpbuPR0E {\n  0% { -webkit-transform: scale(0) }\n  100% {\n    -webkit-transform: scale(1.0);\n    opacity: 0;\n  }\n}\n\n@keyframes _1sVUmNMvpUqcjZVpbuPR0E {\n  0% { \n    -webkit-transform: scale(0);\n    transform: scale(0);\n  } 100% {\n    -webkit-transform: scale(1.0);\n    transform: scale(1.0);\n    opacity: 0;\n  }\n}", ""]);
// Exports
exports.locals = {
	"spinner": "_1d6eMECJwHrJqr-XgBx5qg",
	"sk-scaleout": "_1sVUmNMvpUqcjZVpbuPR0E"
};
module.exports = exports;
