import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Row } from 'react-table';
import { colors } from '../../../components/Shared/Style/colors';
import { animated, useSpring } from 'react-spring';
import { useHeight } from '../../../lib/hooks/useheight';
import ArrowDown from '../../../components/Icon/arrow-down-no-tail.svg';
import { Maintenance } from '@app/api/models/Maintenances';

const YearGroupingHeader = styled.div({
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    padding: '1.5rem 1rem',
    backgroundColor: colors.white,
    fontsize: '1rem',
    fontWeight: 600,
    cursor: 'pointer',
});
const YearGroupingWrapper = styled.div({
    position: 'relative',
    backgroundColor: colors.white,
    marginBottom: '1rem',
    boxShadow: `0px 4px 10px -10px rgba(0, 0, 0, 1)`,
});
const YearGroupingRow = styled.div({
    borderTop: `1px solid ${colors.black50}`,
    ':first-of-type': {},
});

const HeaderWrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
});

const AnimatedArrowDown = animated(ArrowDown);

const RowContainer = animated(styled.div({}));

type Props = {
    rows: Row<object>[];
    year: string;
    cellProps: (
        props: any,
        {
            cell,
        }: {
            cell: any;
        }
    ) => any[];
    prepareRow: (row: Row<object>) => void;
    initialExpanded?: boolean;
};

const YearGrouping = ({ rows, year, cellProps, prepareRow, initialExpanded = true, ...rest }: Props) => {
    const [expanded, setExpanded] = useState(initialExpanded);

    const [heightRef, height] = useHeight();

    const rowProps = useSpring({
        opacity: expanded ? 1 : 0,
        height: expanded ? height : 0,
    });

    const arrowProps = useSpring({
        transform: expanded ? 'rotateZ(0deg)' : 'rotateZ(-90deg)',
    });
    const toggleExpanded = () => {
        setExpanded((prev) => !prev);
    };

    const TotalCost = rows
        .map((row) => {
            const original = row.original as Maintenance;

            return original.completed_cost ? +original.completed_cost : +original.planned_cost || 0;
        })
        .reduce((prev, current) => prev + current);

    return (
        <YearGroupingWrapper {...rest}>
            <YearGroupingHeader onClick={toggleExpanded}>
                <HeaderWrapper>
                    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                        <AnimatedArrowDown style={arrowProps} />
                        {year}
                    </div>

                    {new Intl.NumberFormat('sv-SE', {
                        style: 'currency',
                        currency: 'SEK',
                        maximumFractionDigits: 0,
                    }).format(TotalCost)}
                </HeaderWrapper>
            </YearGroupingHeader>
            <animated.div style={rowProps}>
                <RowContainer ref={heightRef} aria-expanded={expanded}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <YearGroupingRow {...row.getRowProps()} className="tr" key={row.getRowProps().key}>
                                {row.cells.map((cell) => {
                                    return (
                                        <div
                                            {...cell.getCellProps(cellProps)}
                                            className="td"
                                            key={cell.getCellProps().key}
                                        >
                                            {cell.render('Cell')}
                                        </div>
                                    );
                                })}
                            </YearGroupingRow>
                        );
                    })}
                </RowContainer>
            </animated.div>
        </YearGroupingWrapper>
    );
};

export default YearGrouping;
