import { InviteBoardMember } from '@app/api/models/BoardMembers/BoardMembersModels';
import BoardMembersApi from '@app/api/public/BoardMembersApi';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { L } from '../../../lib/i18n';
import { getBoardMemberListQueryKey } from './useGetBoardMembers';

const saveNewBoardMember = async (boardMember: InviteBoardMember, invite = false) => {
    const res = await BoardMembersApi.save(boardMember);

    if (invite && res.data) await BoardMembersApi.invite(+res.data?.id);
    if (res.status === 'error') return Promise.reject(res.message);

    return res.data;
};

const useSaveNewBoardMember = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (boardMember: InviteBoardMember & { user_id: number; invite?: boolean }) =>
            saveNewBoardMember(boardMember, boardMember.invite),
        {
            onError: (err) => {
                if (err instanceof Error) console.log(err.message);
                /* Shows error message */
                const error = typeof err === 'string' ? err : new Error(L('reset_error')).message;
                const message = error;
                toast.error(`${L(message)}`);
            },
            onSettled: () => {
                queryClient.invalidateQueries([getBoardMemberListQueryKey]);
            },
        }
    );
};

export default useSaveNewBoardMember;
