import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import StyledSwitch from '../../StyledSwitch';
import useDeletePageListItem from '../hooks/useDeletePageListItem';
import useGetPageListItem from '../hooks/useGetPageListItem';
import useGetDocuments from '../hooks/useGetDocuments';
import useUpdatePageListItem from '../hooks/useUpdatePageListItem';
import useUpdatePublish from '../hooks/useUpdatePublish';
import DocumentsTable from '../../Table/DocumentTables/DocumentsTable';
import { useParams } from 'react-router-dom';
import { Button } from '../../Buttons/Button';

import { L } from '../../../lib/i18n';
import ContentPreview from './Preview/ContentPreview';
import useThemeProviderState from '../../../areas/public-site/theme/hooks/useThemeProviderState';
import { ThemeProvider } from 'emotion-theming';
import Hero from './Hero';
import useSaveDocuments from '../hooks/useSaveDocuments';
import useDeleteDocument from '../hooks/useDeleteDocument';
import Input from '../../Forms/Input';
import Label from '../../Forms/Label';
import FormHeader from '../../Forms/FormHeader';
import Quill from '../../Quill/Quill';
import CardWithDivider from '../../Layout/CardWithDivider';
import CardWithDividerTopArea from '../../Layout/CardWithDividerTopArea';

const Box = styled.div({
    backgroundColor: 'white',
    borderRadius: '4px',
    marginBottom: '2rem',
});
const TopWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});
const SwitchWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
});

const BottomWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '1rem',
    marginBottom: '2rem',
});
const FileInput = styled.input({
    display: 'none',
});
const ImgLabel = styled.label({
    cursor: 'pointer',
});
const Span = styled.span({
    paddingRight: '0.5rem',
});
const ImageWrapper = styled.div({
    width: '100%',
    marginBottom: '2rem',
});
const StyledButton = styled(Button)({
    marginLeft: '1rem',
});

const EditPageItem = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState();
    const [imgUrl, setImgUrl] = useState('');
    const params = useParams<{ id: string }>();
    const { data: pageListItem } = useGetPageListItem();
    const { data: documentItem } = useGetDocuments();
    const { mutate: updatePageListItem, status } = useUpdatePageListItem();
    const { mutate: updatePublish } = useUpdatePublish();
    const { mutate: remove } = useDeletePageListItem();

    const { mutate: saveDocument } = useSaveDocuments();
    const { mutate: deleteDocument } = useDeleteDocument();

    const { theme } = useThemeProviderState();

    useEffect(() => {
        if (!pageListItem) return;
        setTitle(pageListItem.title);
        setDescription(pageListItem.description);
    }, [pageListItem]);

    if (!pageListItem) return null;

    const handleClick = () => {
        updatePublish({ ...pageListItem });
    };

    const uploadedImage = (event) => {
        if (!event.target.files) return;
        setFile(event.target.files[0]);
        setImgUrl(URL.createObjectURL(event.target.files[0]));
    };

    const save = () => {
        updatePageListItem({
            publicSiteItem: { ...pageListItem, title, description },
            file,
        });
    };

    return (
        <CardWithDivider
            topArea={<CardWithDividerTopArea title={L('edit_homepage_content_title')} />}
            mainArea={
                <>
                    <Box>
                        <TopWrapper>
                            <Label title={L('title')}>
                                <Input
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    placeholder={L('title') + '...'}
                                />
                            </Label>
                            <SwitchWrapper>
                                <Span>{L('publish')}</Span>
                                <StyledSwitch
                                    checked={pageListItem.published_at ? true : false}
                                    onClick={handleClick}
                                />
                            </SwitchWrapper>
                        </TopWrapper>
                        <Label title={L('content')} />
                        <Quill
                            value={description}
                            onChange={(value) => setDescription(value)}
                            placeholder={L('write_something')}
                        />
                        <Label title={L('background_image')}>
                            <ImageWrapper>
                                <ImgLabel>
                                    <Hero
                                        imgUrl={imgUrl || pageListItem.cover_image_data?.original?.id || ''}
                                        pageListItem={pageListItem}
                                    />
                                    <FileInput accept=".jpg,.png" type="file" onChange={uploadedImage} id="heroImage" />
                                </ImgLabel>
                            </ImageWrapper>
                        </Label>
                        <BottomWrapper>
                            <StyledButton variant="delete" onClick={() => remove(+params.id)}>
                                {L('delete_page')}
                            </StyledButton>
                            <StyledButton variant="selectedMenu" onClick={save}>
                                {status === 'loading' ? L('loading') + '...' : L('save')}
                            </StyledButton>
                        </BottomWrapper>
                    </Box>
                    <Box>
                        {documentItem && (
                            <DocumentsTable
                                deleteDocument={(doc) => deleteDocument(doc)}
                                saveDocument={(doc) => saveDocument(doc)}
                                list={documentItem}
                            />
                        )}
                    </Box>
                    <Box>
                        <FormHeader>{L('preview')}</FormHeader>
                        <ThemeProvider theme={theme}>
                            <ContentPreview />
                        </ThemeProvider>
                    </Box>
                </>
            }
        />
    );
};

export default EditPageItem;
