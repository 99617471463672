import React from 'react';
import { FileTypeIcon } from '../../Icon/Icon';
import { colors } from '../../Shared/Style/colors';
import styled from '@emotion/styled';

const P = styled.p(() => {
    return {
        fontSize: '1rem',
        padding: '0px',
        color: colors.textColor2,
        textDecoration: 'none',
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'start',

        '&:hover': {
            cursor: 'pointer',
        },
    };
});

const StyledWrapper = styled.div({
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr min-content',
    gap: '1rem',
    position: 'relative',
    textDecoration: 'none',
});

type DocumentItemProps = {
    fileType: string;
    name: string;
};
const DocumentItem: React.FC<DocumentItemProps & React.HTMLAttributes<HTMLDivElement>> = ({
    fileType,
    name,
    children,
    ...rest
}) => {
    return (
        <StyledWrapper {...rest}>
            <div style={{ alignSelf: 'center' }}>
                <FileTypeIcon  fileType={fileType} />
            </div>
            <P>{name}</P>
            {children}
        </StyledWrapper>
    );
};

export default DocumentItem;
