import { AnalyticCostByYear } from '@app/api/models/Maintenances';
import dayjs from 'dayjs';
import React from 'react';
import useGetProjectInfo from '../../../hooks/useGetProjectInfo';
import useGetProjectArea from '../../../lib/hooks/useGetProjectArea';
import useGetAnalyticList from '../hooks/useGetAnalyticList';
import PieGraph from './PieGraph';

const PieGraphCostm2 = () => {
    const { data: analyticList } = useGetAnalyticList();
    const projectInfo = useGetProjectInfo();
    const { data: area } = useGetProjectArea();

    if (!projectInfo) return null;
    if (!analyticList || analyticList.length === 0) return null;

    const m2 = area?.sum;

    if (!m2) {
        return <PieGraph headerPrice={'Kostnad per m²'} headerPie={'Kostnad per kategori'} price={'Boarea saknas'} />;
    }
    const uniqueYears = [...new Set(analyticList.map((item) => item.year)).values()];
    const maxYear = Math.max(...uniqueYears);
    const yearSpan = maxYear - +dayjs().format('YYYY');

    const uniqueCategories = [...new Set(analyticList.map((item) => item.maintenance_category.name)).values()];
    const avrageCostPerm2 =
        analyticList
            .map((analyticCost) =>
                analyticCost.completed_amount && +analyticCost.completed_amount
                    ? +analyticCost.completed_amount
                    : +analyticCost.planned_amount
            )
            .reduce((sum, current) => sum + current) /
        yearSpan /
        m2;

    const Category = class {
        constructor(categories: AnalyticCostByYear[], years: number[]) {
            this.costs = categories.map((catagory) => {
                return catagory.completed_amount && +catagory.completed_amount
                    ? +catagory.completed_amount
                    : +catagory.planned_amount;
            });
            this.name = categories[0].maintenance_category.name || '';
            this.color = categories[0].maintenance_category.color || '';
            this.years = years;
        }
        costs: number[];
        name: string;
        color: string;
        years: number[];

        avrageCostm2() {
            return Math.round(this.costs.reduce((sum, current) => sum + current) / yearSpan / avrageCostPerm2);
        }
    };

    const categories = uniqueCategories.map((categoryName) => {
        const CategoryClass = new Category(
            analyticList.filter((item) => item.maintenance_category.name === categoryName),
            uniqueYears
        );
        return CategoryClass;
    });

    const data = {
        labels: categories.map((category) => category.name),
        datasets: [
            {
                label: 'kostnad per år',
                data: categories.map((category) => category.avrageCostm2()),
                backgroundColor: categories.map((category) => category.color),
            },
        ],
    };

    return (
        <PieGraph
            data={data}
            headerPrice={'Kostnad per m²'}
            headerPie={'Kostnad per kategori'}
            price={`${new Intl.NumberFormat('sv-SE', {
                style: 'currency',
                currency: 'SEK',
                maximumFractionDigits: 0,
            }).format(avrageCostPerm2)}/m²`}
        />
    );
};

export default PieGraphCostm2;
