import { useMutation, useQueryClient } from 'react-query';
import MaintenancesApi, { Maintenance } from '@app/api/public/MaintenancesApi';
import { useHistory } from 'react-router-dom';
import { getMaintenanceQueryKey } from './useGetMaintenance';
import { createValidator, required } from '../../../lib/validation';

type Variables = Partial<Maintenance>;

const rules = createValidator({
    completed_date: [required()],
    completed_cost: [required()],
});

const completeMaintenance = async (variables: Variables) => {
    const { errors, ok } = rules.test(variables);

    if (ok) {
        return await MaintenancesApi.save(variables);
    }
    return Promise.reject(errors);
};

const useCompleteMaintenance = () => {
    const queryClient = useQueryClient();

    return useMutation((variables: Variables) => completeMaintenance(variables), {
        onSuccess: (response) => {
            const maintenanceArr = queryClient.getQueryData<Maintenance[]>(getMaintenanceQueryKey);
            if (maintenanceArr) {
                queryClient.setQueryData(getMaintenanceQueryKey, [...maintenanceArr, response]);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(getMaintenanceQueryKey);
        },
    });
};

export default useCompleteMaintenance;
