import React from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import sv from 'dayjs/locale/sv';
import { L } from '@app/web/lib/i18n';
import { BookingDetail, SaveBookingsVariables } from '@app/api/models/Bookings';
import toast from 'react-hot-toast';
import { UseMutateFunction } from 'react-query';
import ConfirmationButton from '../../../components/Buttons/ConfirmationButton';
dayjs.locale(sv);

const AlignLeftSpan = styled.span({
    textAlign: 'start',
    width: '100%',
});

const StyledButtonWrapper = styled.div({
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
});

type Props = {
    removeBooking: UseMutateFunction<number, unknown, SaveBookingsVariables, unknown>;
    bookingId: string;
};

const bookingListColumns = ({ removeBooking, bookingId }: Props) => {
    return [
        {
            Header: () => L('property'),
            id: 'property',
            accessor: (originalRow: BookingDetail) => {
                return <AlignLeftSpan>{originalRow.property_name}</AlignLeftSpan>;
            },
            disableSortBy: true,
            width: 75,
            style: {
                justifyContent: 'start',
            },
        },
        {
            Header: () => L('user_name'),
            id: 'user_name',
            accessor: (originalRow: BookingDetail) => {
                return <AlignLeftSpan>{originalRow.user_name}</AlignLeftSpan>;
            },
            width: 75,
            style: {
                justifyContent: 'start',
            },
        },
        {
            Header: () => L('email'),
            id: 'email',
            accessor: 'user_email',
            width: 100,
            style: {
                justifyContent: 'start',
            },
        },
        {
            Header: () => L('booking_date'),
            id: 'booking_date',
            accessor: (originalRow: BookingDetail) => {
                return dayjs(originalRow.booking_date).valueOf();
            },
            Cell: ({ value }: { value: number }) => {
                return <AlignLeftSpan>{dayjs(value).format('YYYY-MM-DD')}</AlignLeftSpan>;
            },
            width: 50,
            style: {
                justifyContent: 'start',
            },
        },

        {
            Header: () => L('time'),
            id: 'time',
            accessor: (originalRow: BookingDetail) => {
                return <AlignLeftSpan>{`${originalRow.starttime} - ${originalRow.endtime}`}</AlignLeftSpan>;
            },
            width: 50,
            disableSortBy: true,
            style: {
                justifyContent: 'start',
            },
        },
        {
            Header: () => '',
            id: 'remove',
            accessor: (booking: BookingDetail) => {
                return (
                    <StyledButtonWrapper>
                        <ConfirmationButton
                            props={{
                                onConfirm: () =>
                                    removeBooking(
                                        {
                                            booking_date: dayjs(booking.booking_date).format('YYYY-MM-DD'),
                                            booking_resource_item_id: booking.booking_resource_item_id,
                                            booking_slot_id: booking.booking_slot_id,
                                            resourceId: bookingId,
                                            userId: booking.user_id,
                                        },
                                        {
                                            onSuccess: () => {
                                                toast.success(
                                                    `Bokning av ${booking.user_email} den ${dayjs(
                                                        booking.booking_date
                                                    ).format('YYYY-MM-DD')} är nu avbokad`
                                                );
                                            },
                                        }
                                    ),
                            }}
                        />
                    </StyledButtonWrapper>
                );
            },
            width: 50,
            disableSortBy: true,
        },
    ];
};

export default bookingListColumns;
