import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import Search from '../../components/Forms/Search';
import AddNewServicePartnerButton from './components/AddNewServicePartnerButton';
import ServicePartnerListHandler from './components/ServicePartnerList/ServicePartnerListHandler';
import { BorderDesktop } from '../../components/Layout/BorderDesktop';
import CardWithDivider from '../../components/Layout/CardWithDivider';
import CardTitle from '../../components/typography/CardTitle';
import { L } from '../../lib/i18n';
import PageTitle from '../../components/typography/PageTitle';
import AddServicePartner from './page/AddServicePartner';
import ServicePartnerWrapper from './page/ServicePartnerWrapper';

const ListArea = styled.div({
    width: '100%',
    marginBottom: '60px',
});

const StyledBorder = styled(BorderDesktop)({
    maxHeight: '100%',
});

const StyledServicePartnerListHandler = styled(ServicePartnerListHandler)({
    marginTop: '2.5rem',
});

const StyledSearch = styled(Search)({
    margin: '0px',
});

const StyledAddNewServicePartnerButton = styled(AddNewServicePartnerButton)({
    marginLeft: 'auto',
});

const Card = styled.div({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr min-content 3fr',
    gridTemplateAreas: `
    "aside border main"
    `,
});

const StyledAside = styled.aside({
    height: '80vh',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '2rem',
});

const StyledMain = styled.main({
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '2rem',
});

const TopArea = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});
const SearchButtonWrapper = styled.div({
    display: 'flex',
    gap: '2rem',
});

const ServicePartnerPage: React.FC = () => {
    const [value, setValue] = useState('');

    return (
        <>
            <PageTitle>{L('service_partner')}</PageTitle>
            <CardWithDivider
                topArea={
                    <TopArea>
                        <CardTitle>{L('all_service_partner')}</CardTitle>
                        <SearchButtonWrapper>
                            <StyledAddNewServicePartnerButton />
                        </SearchButtonWrapper>
                    </TopArea>
                }
                mainArea={
                    <Card>
                        <StyledAside>
                            <ListArea>
                                <StyledSearch setValue={setValue} />
                                <StyledServicePartnerListHandler searchValue={value} />
                            </ListArea>
                        </StyledAside>
                        <StyledBorder />
                        <StyledMain>
                            <Switch>
                                <Route path={'/service-partner/add'}>
                                    <AddServicePartner />
                                </Route>
                                <Route path={'/service-partner/:id'}>
                                    <ServicePartnerWrapper />
                                </Route>
                            </Switch>
                        </StyledMain>
                    </Card>
                }
            />
        </>
    );
};

export default ServicePartnerPage;
