import { Contact } from '@app/api/models/Contacts';
import { formatDateIsoDayjs } from '@app/shared/lib/formatting';
import styled from '@emotion/styled';
import React from 'react';
import Label from '../../../components/Forms/Label';
import { L } from '../../../lib/i18n';

type Props = {
    contact: Partial<Contact>;
};

const Status = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'right',
    fontSize: '12px',
});

const ContactsStatusArea = ({ contact }: Props) => {
    return (
        <Status>
            <Label title={L('created_at')}>{formatDateIsoDayjs(contact.created_at || '')}</Label>
            <Label title={L('updated_at')}>{formatDateIsoDayjs(contact.updated_at || '')}</Label>
        </Status>
    );
};

export default ContactsStatusArea;
