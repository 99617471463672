import { Maintenance, MaintenanceCategory } from '@app/api/models/Maintenances';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React from 'react';
import { Button } from '../../../components/Buttons/Button';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import { colors } from '../../../components/Shared/Style/colors';
import { L } from '../../../lib/i18n';
import InputSplit from '../../../components/Forms/InputSplit';
import InvisibleLinkWrapper from '../../../components/Link/InvisibleLinkWrapper';
import { ServicePartner } from '@app/api/public/ServicePartnersApi';
import Modal from '../../../components/Modal/Modal';
import { useModal } from '../../../components/Modal/Context/ModalContext';
import SettingsByType from './SettingsByType';
import DropDownCreatable from '../../../components/DropDownCreatable/DropDownCreatable';
import ServicePartnerModal from '../../service-partner/components/ServicePartnerModal/ServicePartnerModal';
import ServicePartnerForm from '../../service-partner/components/ServicePartnerForm/ServicePartnerForm';
import { useQueryClient } from 'react-query';
import { servicePartnersQueryKeys } from '../../service-partner/hooks/servicePartnerQueries';
import { TextAreaInput } from '../../../components/Forms/TextArea';

const Wrapper = styled.div({
    width: '100%',
    color: colors.textColor1,
    display: 'grid',
    justifyItems: 'flex-start',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    gridGap: '20px',
});

const StyledInputSplit = styled(InputSplit)({
    '& input': {
        height: '32px',
    },
});
const GridSectionMain = styled.div({
    width: '100%',
    display: 'grid',
    justifyContent: 'flex-start',
    gridColumnGap: '20px',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateAreas: `
    "title title          category service_partner"
    "status status          type  type"
    "textarea   textarea   textarea  textarea"
    "plannedDate plannedDate plannedCost plannedCost"
    `,
});

const StyledInput = styled(Input)({
    width: '100%',
    height: '32px',
});

const AddButton = styled(Button)({
    marginRight: 0,
    marginLeft: '1rem',
});
const ButtonWraper = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
});

type Props = {
    categoryList: Readonly<MaintenanceCategory[]> | null | undefined;
    handleChange: (value: Partial<Maintenance>) => void;
    maintenanceItem: Partial<Maintenance> | undefined;
    setView: React.Dispatch<React.SetStateAction<'edit' | 'preview'>>;
    statusList: MaintenanceCategory[] | null | undefined;
    typeList: MaintenanceCategory[] | null | undefined;
    servicePartners: Pick<ServicePartner, 'id' | 'name'>[] | undefined;
};

const AddMaintenanceForm = ({
    categoryList,
    handleChange,
    setView,
    maintenanceItem,
    statusList,
    typeList,
    servicePartners,
}: Props) => {
    const disablePreview = () => {
        if (!maintenanceItem?.name) return true;
        if (!maintenanceItem?.maintenance_category?.id) return true;
        if (!maintenanceItem?.maintenance_status?.id) return true;
        if (!maintenanceItem?.maintenance_type?.id) return true;
        if (!maintenanceItem?.description) return true;
        if (!maintenanceItem?.planned_date) return true;
        if (!maintenanceItem?.planned_cost) return true;
        return false;
    };
    const { openModal, ToggleIsModalOpen } = useModal();
    const client = useQueryClient();

    const handleCreateNewCatagory = (value: string) => {
        openModal(<SettingsByType settingsType={'category'} initialValue={value} />);
    };
    const handleCreateNewStatus = (value: string) => {
        openModal(<SettingsByType settingsType={'status'} initialValue={value} />);
    };
    const handleCreateNewType = (value: string) => {
        openModal(<SettingsByType settingsType={'type'} initialValue={value} />);
    };

    const handleCreateNewServicePartner = (value: string) => {
        openModal(
            <ServicePartnerModal onClick={(e) => e.stopPropagation()}>
                <ServicePartnerForm
                    style={{
                        gridTemplateColumns: '1fr',
                        width: '100%',
                    }}
                    initialFormData={{ name: value }}
                    submitOptios={{
                        onSuccess: () => {
                            client.invalidateQueries(servicePartnersQueryKeys.list());
                            ToggleIsModalOpen();
                        },
                    }}
                />
            </ServicePartnerModal>
        );
    };

    const categoryListLabel = categoryList?.map((item) => ({ ...item, label: item.name }));
    const typeListLabel = typeList?.map((item) => ({ ...item, label: item.name }));
    const statusListLabel = statusList?.map((item) => ({ ...item, label: item.name }));
    const servicePartnersLabel = servicePartners?.map((item) => ({ ...item, label: item.name }));
    return (
        <>
            <Modal />

            <Wrapper>
                <GridSectionMain>
                    <Label title={'Titel'} style={{ gridArea: 'title' }}>
                        <StyledInput
                            value={maintenanceItem?.name || ''}
                            onChange={(e) => handleChange({ name: e.target.value })}
                        />
                    </Label>
                    <Label title={'Kategori'} style={{ gridArea: 'category' }}>
                        <DropDownCreatable
                            isClearable
                            value={categoryListLabel?.find(
                                (item) => maintenanceItem?.maintenance_category?.id === item.id
                            )}
                            onChange={(value) => handleChange({ maintenance_category: value })}
                            onCreateOption={handleCreateNewCatagory}
                            placeholder={`${L('type_to_create_category')}`}
                            options={categoryListLabel || []}
                        />
                    </Label>
                    <Label title={'Servicepartner'} style={{ gridArea: 'service_partner' }}>
                        <DropDownCreatable
                            isClearable
                            value={servicePartnersLabel?.find((item) => {
                                return maintenanceItem?.service_partner_id === item.id;
                            })}
                            onChange={(value) => handleChange({ service_partner_id: value?.id })}
                            onCreateOption={handleCreateNewServicePartner}
                            placeholder={`${L('type_to_create_status')}`}
                            options={servicePartnersLabel || []}
                        />
                    </Label>
                    <Label title={'Beskrivning'} style={{ gridArea: 'textarea' }}>
                        <TextAreaInput
                            value={maintenanceItem?.description || ''}
                            onChange={(e) => handleChange({ description: e.target.value })}
                        />
                    </Label>

                    <Label title={'Status'} style={{ gridArea: 'status' }}>
                        <DropDownCreatable
                            isClearable
                            value={statusListLabel?.find((item) => maintenanceItem?.maintenance_status?.id === item.id)}
                            onChange={(value) => handleChange({ maintenance_status: value })}
                            onCreateOption={handleCreateNewStatus}
                            placeholder={`${L('type_to_create_status')}`}
                            options={statusListLabel || []}
                        />
                    </Label>
                    <Label title={'Typ'} style={{ gridArea: 'type' }}>
                        <DropDownCreatable
                            isClearable
                            value={typeListLabel?.find((item) => maintenanceItem?.maintenance_type?.id === item.id)}
                            onChange={(value) => handleChange({ maintenance_type: value })}
                            onCreateOption={handleCreateNewType}
                            placeholder={`${L('type_to_create_type')}`}
                            options={typeListLabel || []}
                        />
                    </Label>
                    <Label title={'Planerad kostnad'} style={{ gridArea: 'plannedCost' }}>
                        <StyledInputSplit
                            type={'number'}
                            value={maintenanceItem?.planned_cost ? parseInt(maintenanceItem.planned_cost) : ''}
                            onChange={(e) => handleChange({ planned_cost: e.target.value })}
                            splitInput={'kr'}
                        />
                    </Label>
                    <Label style={{ gridArea: 'plannedDate' }} title={L('planned_date')}>
                        <StyledInput
                            type={'date'}
                            value={
                                maintenanceItem?.planned_date
                                    ? dayjs(maintenanceItem?.planned_date).format('YYYY-MM-DD')
                                    : ''
                            }
                            onChange={(e) => handleChange({ planned_date: e.target.value })}
                        />
                    </Label>
                </GridSectionMain>

                <ButtonWraper>
                    <InvisibleLinkWrapper to={'/maintenance/'}>
                        <Button type="button">{L('cancel')}</Button>
                    </InvisibleLinkWrapper>

                    <AddButton onClick={() => setView('preview')} disabled={disablePreview()} variant="selectedMenu">
                        {L('preview')}
                    </AddButton>
                </ButtonWraper>
            </Wrapper>
        </>
    );
};

export default AddMaintenanceForm;
