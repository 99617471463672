import React from 'react';
import css from './Icon.module.css';

interface IIconProps {
    className?: string;
    state?: 'success' | 'failed' | 'spin';
    size?: 'small' | 'large' | 'mSmall' | 'medium' | '100' | 'MmediumMaxHeight' | 'sMedium';
    light?: boolean;
    onClick?: (e: any) => void;
    fill?: string;
    stroke?: string;
    style?: any;
}

export class SvgIcon extends React.Component {
    props: IIconProps & { iconId: string };

    onClick = (e) => {
        e.preventDefault();
        if (this.props.onClick) {
            this.props.onClick(e);
        }
    };

    render() {
        const { fill, iconId, light, state, size, className, ...rest } = this.props;
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const SvgCodeIn = require('!!raw-loader!./' + iconId + '.svg');
        const SvgCode = (SvgCodeIn && SvgCodeIn.default) || SvgCodeIn;
        const classNames = [css.svg];
        if (fill) {
            classNames.push(css.fill);
        }
        if (fill) {
            classNames.push(css.fill);
        }
        if (state) {
            classNames.push(css['state-' + state]);
        }
        if (light) {
            classNames.push(css.light);
        }
        if (className) {
            classNames.push(className);
        }
        if (size) {
            classNames.push(css['size-' + size]);
        }

        if ('onClick' in this.props) {
            classNames.push(css.clickable);
        }
        const resultProps = {
            ...rest,
            className: classNames.join(' '),
            dangerouslySetInnerHTML: { __html: SvgCode },
        };

        if ('onClick' in this.props) {
            return <button tabIndex={0} {...resultProps} onClick={this.onClick} />;
        } else {
            return <span {...resultProps} />;
        }
    }
}

export const Icon = {
    Binder(props: IIconProps) {
        return <SvgIcon {...props} iconId="books" />;
    },
    Dashboard(props: IIconProps) {
        return <SvgIcon {...props} iconId="house-pricing" />;
    },
    Settings(props: IIconProps) {
        return <SvgIcon {...props} iconId="privacy-settings" />;
    },
    Tickets(props: IIconProps) {
        return <SvgIcon {...props} iconId="property-agreement" />;
    },
    MoreVertical(props: IIconProps) {
        return <SvgIcon {...props} iconId="ic_more_vert_18px" />;
    },
    Attachment(props: IIconProps) {
        return <SvgIcon {...props} iconId="attachment" />;
    },
    SortUp(props: IIconProps) {
        return <SvgIcon {...props} iconId="small-triangle-up" />;
    },
    SortDown(props: IIconProps) {
        return <SvgIcon {...props} iconId="small-triangle-down" />;
    },
    SignOut(props: IIconProps) {
        return <SvgIcon {...props} iconId="signout" />;
    },
    Messages(props: IIconProps) {
        return <SvgIcon {...props} iconId="email" />;
    },
    Mail(props: IIconProps) {
        return <SvgIcon {...props} iconId="mail-folded" />;
    },
    ContentCopy(props: IIconProps) {
        return <SvgIcon {...props} iconId="content-copy" />;
    },
    Image(props: IIconProps) {
        return <SvgIcon {...props} iconId="image" />;
    },
    Document(props: IIconProps) {
        return <SvgIcon {...props} iconId="single-folded-content" />;
    },
    TrashCan(props: IIconProps) {
        return <SvgIcon {...props} iconId="trash-simple" />;
    },
    Link(props: IIconProps) {
        return <SvgIcon {...props} iconId="link-72" />;
    },
    Upload(props: IIconProps) {
        return <SvgIcon {...props} iconId="square-upload" />;
    },
    Member(props: IIconProps) {
        return <SvgIcon {...props} iconId="single-02" />;
    },
    BoardMember(props: IIconProps) {
        return <SvgIcon {...props} iconId="boardmember" />;
    },
    Contact(props: IIconProps) {
        return <SvgIcon {...props} iconId="user-frame-32" />;
    },
    News(props: IIconProps) {
        return <SvgIcon {...props} iconId="paper" />;
    },
    Calendar(props: IIconProps) {
        return <SvgIcon {...props} iconId="calendar-date-2" />;
    },
    Information(props: IIconProps) {
        return <SvgIcon {...props} iconId="info" />;
    },
    Property(props: IIconProps) {
        return <SvgIcon {...props} iconId="fav-property" />;
    },
    Profile(props: IIconProps) {
        return <SvgIcon {...props} iconId="circle-08" />;
    },
    Add(props: IIconProps) {
        return <SvgIcon {...props} iconId="c-add" />;
    },
    Maintenance(props: IIconProps) {
        return <SvgIcon {...props} iconId="settings" />;
    },
    Chart(props: IIconProps) {
        return <SvgIcon {...props} iconId="chart-bar" />;
    },
    List(props: IIconProps) {
        return <SvgIcon {...props} iconId="bullet-list" />;
    },
    remove(props: IIconProps) {
        return <SvgIcon {...props} iconId="c-remove" />;
    },
    CirleRightArrow(props: IIconProps) {
        return <SvgIcon {...props} iconId="circle-simple-right" />;
    },
    brfAdmin(props: IIconProps) {
        return <SvgIcon {...props} iconId="law" />;
    },
    members(props: IIconProps) {
        return <SvgIcon {...props} iconId="members" />;
    },
    help(props: IIconProps) {
        return <SvgIcon {...props} iconId="question-mark" />;
    },
    clock(props: IIconProps) {
        return <SvgIcon {...props} iconId="clock" />;
    },
    calendarDay(props: IIconProps) {
        return <SvgIcon {...props} iconId="calendar-day" />;
    },
    warningSign(props: IIconProps) {
        return <SvgIcon {...props} iconId="warning-sign" />;
    },
    cardList(props: IIconProps) {
        return <SvgIcon {...props} iconId="ic_view_module_24px" />;
    },
    VerticalMoreIcon(props: IIconProps) {
        return <SvgIcon {...props} iconId="vertical-dots" />;
    },
    gridToList(props: IIconProps) {
        return <SvgIcon {...props} iconId="list-view" />;
    },
    gridView(props: IIconProps) {
        return <SvgIcon {...props} iconId="grid-view" />;
    },
    listView(props: IIconProps) {
        return <SvgIcon {...props} iconId="list-view" />;
    },

    File(props: IIconProps) {
        return <SvgIcon {...props} iconId="file" />;
    },
    FileCsv(props: IIconProps) {
        return <SvgIcon {...props} iconId="file-csv" />;
    },
    FileHtml(props: IIconProps) {
        return <SvgIcon {...props} iconId="file-html" />;
    },
    FileDocx(props: IIconProps) {
        return <SvgIcon {...props} iconId="file-docx" />;
    },
    FileGif(props: IIconProps) {
        return <SvgIcon {...props} iconId="file-gif" />;
    },
    FilePng(props: IIconProps) {
        return <SvgIcon {...props} iconId="file-png" />;
    },
    FileJpg(props: IIconProps) {
        return <SvgIcon {...props} iconId="file-jpg" />;
    },
    FilePdf(props: IIconProps) {
        return <SvgIcon {...props} iconId="file-pdf" />;
    },
    FileSvg(props: IIconProps) {
        return <SvgIcon {...props} iconId="file-svg" />;
    },
    FileTxt(props: IIconProps) {
        return <SvgIcon {...props} iconId="file-txt" />;
    },
    FileXlsx(props: IIconProps) {
        return <SvgIcon {...props} iconId="file-xlsx" />;
    },
    fileImg(props: IIconProps) {
        return <SvgIcon {...props} iconId="polaroid-photo" />;
    },
    Swap(props: IIconProps) {
        return <SvgIcon {...props} iconId="opposite-directions" />;
    },
    Cross(props: IIconProps) {
        return <SvgIcon {...props} iconId="e-remove" />;
    },
    DownArrow(props: IIconProps) {
        return <SvgIcon {...props} iconId="ctrl-down" />;
    },
    NotificationBell(props: IIconProps) {
        return <SvgIcon {...props} iconId="bell" />;
    },
};

type Props = {
    fileType?: string;
};

export const FileTypeIcon: React.FC<Props> = ({ fileType }) => {
    switch (fileType) {
        case 'application/doc' || 'application/docx':
            return <Icon.FileDocx size="MmediumMaxHeight" />;
        case 'application/xls' || 'application/xlsx':
            return <Icon.FileXlsx size="MmediumMaxHeight" />;
        case 'application/pdf':
            return <Icon.FilePdf size="MmediumMaxHeight" />;
        case 'application/htm' || 'application/html':
            return <Icon.FileHtml size="MmediumMaxHeight" />;
        case 'application/txt':
            return <Icon.FileTxt size="MmediumMaxHeight" />;
        case 'image/svg':
            return <Icon.FileSvg size="MmediumMaxHeight" />;
        case 'image/svg+xml':
            return <Icon.FileSvg size="MmediumMaxHeight" />;
        case 'image/jpg':
        case 'image/jpeg':
        case 'image/png':
        case 'image/PNG':
            return <Icon.fileImg size="MmediumMaxHeight" />;
        case 'image/webp':
            return <Icon.fileImg size="MmediumMaxHeight" />;
        default:
            return <Icon.File size="MmediumMaxHeight" />;
    }
};
