import * as React from 'react';
import { L } from '../../lib/i18n';
import TabList from '../../components/Tabs/TabList';
import { desktop } from '../../components/MediaQuery/breakpoints';
import { style } from '../../components/Shared/Style';
import H1 from '../../components/typography/H1';
import styled from '../../components/lib/styled';
import { PageBackground } from '../../components/Layout/PageBackground';
import SmallTab from '../../components/SmallTab';
import { useGetNotificationTypes, useNotificationList } from './hooks/notificationQueries';
import NotificationHandler from './notificationHandler/NotificationHandler';
import useQueryParams from '../../hooks/useQueryParams';
import GlobalFilter from '../../components/Table/filterComponents/GlobalFilter';
import { Pagination } from './components/Pagination';
import { Loading } from '../../components/Loading/Loading';
import { useParams } from 'react-router-dom';
const StyledMain = styled.main({
    padding: ` 0px ${style.margin.m}px`,
    width: '100%',
    [`@media screen and (min-width: ${desktop}px`]: {
        padding: '0px',
    },
});

const Title = styled(H1)({
    margin: '0px',
});

const StyledTabList = styled(TabList)({
    marginBottom: '24px',
    marginTop: '12px',
});

const NotificationsCenter: React.FC = () => {
    const { getQueryString, updateQueryString } = useQueryParams();
    const search = getQueryString('search', '');
    const page = getQueryString('page', '1');
    const { type } = useParams<{ type: string | undefined }>();
    const { data: notifications } = useNotificationList(search, Number(page), type);
    const { data: types } = useGetNotificationTypes();

    const updateSearch = (newSearch: string) => {
        updateQueryString('search', newSearch);
    };
    const updatePage = (newPage: number) => {
        window.scrollTo(0, 0);
        updateQueryString('page', String(newPage));
    };

    const NotificationTabs = types ? (
        types.map((data) => {
            return (
                <SmallTab key={data.notifiable_type} to={`/notificationsCenter/${data.notifiable_type}`}>
                    {L(data.notifiable_type)}
                </SmallTab>
            );
        })
    ) : (
        <p>Loading</p>
    );
    if (!notifications) return <></>;

    return (
        <PageBackground style={{ padding: '0px 0px 75px 0px' }}>
            <StyledMain>
                <Title style={{ marginBottom: 10 }}>{L('my_notifications')}</Title>
                <GlobalFilter
                    filter={search}
                    setFilter={updateSearch}
                    placeholder={L('search')}
                    fullWidth={true}
                    style={{ height: 48 }}
                />

                <StyledTabList>
                    <SmallTab exact to={'/notificationsCenter'}>
                        {L('my_notifications')}
                    </SmallTab>
                    {NotificationTabs}
                </StyledTabList>
                {notifications?.items ? <NotificationHandler items={notifications.items} /> : <Loading />}
                <Pagination page={notifications} onChangePage={updatePage} />
            </StyledMain>
        </PageBackground>
    );
};

export default NotificationsCenter;
