import React from 'react';
import styled from '@emotion/styled';
import CameraIcon from '../../Icon/love-camera-2.svg';
import { colors } from '../../../areas/public-site/lib/publicColors';

const Container = styled.div({
    padding: '5px',
    borderRadius: '4px',
    backgroundColor: colors.white,
    width: '15vw',
    marginRight: '1rem',
    marginBottom: '1rem',
});
const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
});
const Text = styled.span({
    marginLeft: '0.5rem',
    cursor: 'pointer',
});

const EditHeroImage = () => {
    return (
        <Container>
            <Wrapper>
                <CameraIcon width="20" height="20" />
                <Text>Redigera omslagsbild</Text>
            </Wrapper>
        </Container>
    );
};

export default EditHeroImage;
