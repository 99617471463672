import React, { FC } from 'react';
import styled from '@emotion/styled';
import { NotificationType } from '@app/api/models/CommunityNotifications';
import MailNotice from './MailNotice';
import ContractNotice from './ContractNotice';
import BookingNotice from './BookingsNotice';
import NewTicketNotice from './NewTicketNotice';

const StyledNotificationList = styled.ul({});

type Props = {
    notifications: NotificationType[];
    isCollapsed: boolean;
};

// lägg till Rätt Notification components här
const componentMapper = {
    mail: MailNotice,
    contracts: ContractNotice,
    bookings: BookingNotice,
    new_ticket: NewTicketNotice,
};

const NotificationList: FC<Props> = ({ notifications, isCollapsed, ...rest }) => {
    return (
        <StyledNotificationList {...rest}>
            {notifications.map((notification) => {
                const NotificationComponent = componentMapper[notification.notifiable_type];
                if (!NotificationComponent) {
                    console.log('no notification component for ->', notification);
                    return null;
                }
                return (
                    <li key={notification.id}>
                        <NotificationComponent {...notification} isCollapsed={isCollapsed} />
                    </li>
                );
            })}
        </StyledNotificationList>
    );
};

export default NotificationList;
