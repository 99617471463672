import React, { useState } from 'react';
import { L } from '../../../../lib/i18n';
import styled from '@emotion/styled';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { zodResolver } from '@hookform/resolvers/zod';
import ContentEventApi from '@app/api/public/ContentEventApi';
import toast from 'react-hot-toast';
import Label from '../../../../components/Forms/Label';
import ImageDropZone from '../../../../components/ImageDropZone/ImageDropZone';
import Input from '../../../../components/Forms/Input';
import { formatDateTimeZone, formatInputDateTimeDayjs } from '@app/shared/lib/formatting';
import { style } from '../../../../components/Shared/Style';
import { Button } from '../../../../components/Buttons/Button';
import triggerDirtyFieldsToats from '../../../../components/Forms/DirtyFieldsToast';
import Quill from '../../../../components/Quill/Quill';
import { memberEventQueryKeys } from '../hooks/memberEventQueries';
import DropdownButton from '../../../../components/Buttons/DropdownButton';
import { useLocalStorage } from 'react-use';
import StyledRadixSwitch from '../../../../components/StyledSwitch/StyledRadixSwitch';
import dayjs from 'dayjs';
import { notificationToast } from '../../notificationToast/notificationToast';

const StyledCardWithDivider = styled(CardWithDivider)({
    width: 'fit-content',
});

const GridContainer = styled.div({
    display: 'grid',
    gap: '1rem',
    columnGap: '2rem',
    backgroundColor: style.colors.white,
    alignItems: 'start',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateAreas: `
    "img          img"
    "date         location"
    "signOpen         lastSign"
    "title        title"
    "description  description"
    "buttons      buttons"
    `,
    paddingBottom: '0px',
    maxWidth: '600px',
});

const StyledImageDropZone = styled(ImageDropZone)({
    aspectRatio: '4/3',
});

const ContentSwitch = styled(StyledRadixSwitch)({
    marginTop: '4px',
});

const eventFormSchema = z.object({
    title: z.string().min(1, { message: L('required') }),
    description: z.string().min(1, { message: L('required') }),
    event_date: z.string().min(1, { message: L('required') }),
    register_enabled: z.boolean(),
    location: z.string().min(1, { message: L('required') }),
    last_sign_date: z.string(),
});

type CreateEventDraft = { event: z.infer<typeof eventFormSchema>; image?: File };

const createEventDraft = (variables: CreateEventDraft) => {
    return ContentEventApi.createDraft(variables);
};

type CreateEvent = { event: z.infer<typeof eventFormSchema>; image?: File; notify: boolean };

const createNewEvent = (variables: CreateEvent) => {
    return ContentEventApi.createEventAndPublish(variables);
};

const CreateNewEventPage = () => {
    const history = useHistory();
    const [localSubmitButtonState, setSubmitButtonState] = useLocalStorage<'save_to_draft' | 'save_and_publish'>(
        'createNewEvent',
        'save_and_publish'
    );
    const submitButtonState = localSubmitButtonState || 'save_and_publish';
    const quertyClient = useQueryClient();
    const { mutate: createDraft } = useMutation((variables: CreateEventDraft) => createEventDraft(variables), {
        onSettled: () => {
            quertyClient.invalidateQueries(memberEventQueryKeys.lists());
        },
    });
    const { mutate: createAndPublishEvent } = useMutation((variables: CreateEvent) => createNewEvent(variables), {
        onMutate: () => {
            toast.loading(L('loading'), {
                id: 'send-notification',
            });
        },
        onSettled: () => {
            quertyClient.invalidateQueries(memberEventQueryKeys.lists());
        },
    });

    const [imgFile, setImgFile] = useState<File>();
    const [imgUrl, setImgUrl] = useState<string>('');

    const onUpload = (files: File[]) => {
        setImgFile(files[0]);
        setImgUrl(URL.createObjectURL(files[0]));
    };

    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: { errors, isDirty },
        watch,
    } = useForm({
        resolver: zodResolver(eventFormSchema),
        defaultValues: {
            title: '',
            description: '',
            event_date: '',
            register_enabled: false,
            location: '',
            last_sign_date: '',
        },
    });

    const submit = (data: z.infer<typeof eventFormSchema>, type: 'publish' | 'draft') => {
        if (type === 'publish') {
            const publish = (notify: boolean) => {
                return createAndPublishEvent(
                    {
                        event: {
                            ...data,
                            event_date: formatDateTimeZone(data.event_date),
                            last_sign_date: formatDateTimeZone(data.last_sign_date),
                        },
                        image: imgFile,
                        notify,
                    },
                    {
                        onSuccess: (data) => {
                            toast.success(L('event_created_and_published'));
                            if (data?.id) {
                                history.push(`/event/${data.id}`);
                            }
                        },
                        onSettled: () => {
                            toast.dismiss('send-notification');
                        },
                    }
                );
            };

            return notificationToast({
                notify: (notify) => publish(notify),
                type: 'publish',
            });
        }
        createDraft(
            {
                event: {
                    ...data,
                    event_date: formatDateTimeZone(data.event_date),
                    last_sign_date: formatDateTimeZone(data.last_sign_date),
                },
                image: imgFile,
            },
            {
                onSuccess: (data) => {
                    toast.success(L('event_saved_as_draft'));
                    if (data?.id) {
                        history.push(`/event/${data.id}`);
                    }
                },
            }
        );
    };

    return (
        <StyledCardWithDivider
            topArea={<CardTitle>{L('event_new')}</CardTitle>}
            mainArea={
                <GridContainer>
                    <div style={{ gridArea: 'img' }}>
                        <Label title={L('image')} />
                        <StyledImageDropZone
                            dropzoneOptions={{}}
                            onDrop={onUpload}
                            url={imgUrl || ''}
                            remove={() => {
                                if (imgUrl || imgFile) {
                                    setImgUrl('');
                                    setImgFile(undefined);
                                }
                            }}
                        />
                    </div>

                    <Label
                        style={{ gridArea: 'date' }}
                        htmlFor="event_date"
                        title={`${L('event_date')}*`}
                        error={errors?.event_date?.message}
                    >
                        <Input
                            type="datetime-local"
                            min={formatInputDateTimeDayjs()}
                            {...register('event_date', {
                                onChange() {
                                    if (watch('event_date') < watch('last_sign_date')) {
                                        setValue('last_sign_date', '');
                                    }
                                },
                                required: true,
                            })}
                        />
                    </Label>

                    <Label
                        style={{ gridArea: 'location' }}
                        htmlFor="location"
                        title={`${L('location_event')}*`}
                        error={errors?.location?.message}
                    >
                        <Input {...register('location', { required: true })} />
                    </Label>

                    <Label style={{ gridArea: 'signOpen' }} htmlFor="signOpen" title={`${L('registration_open')}`}>
                        <ContentSwitch
                            onCheckedChange={(value) => {
                                setValue('register_enabled', value);
                            }}
                        />
                    </Label>

                    {watch('register_enabled') && (
                        <Label
                            style={{ gridArea: 'lastSign' }}
                            htmlFor="lastSign"
                            title={`${L('lastSign_event')}*`}
                            error={errors?.location?.message}
                        >
                            <Input
                                type="datetime-local"
                                max={formatInputDateTimeDayjs(watch('event_date'))}
                                min={formatInputDateTimeDayjs(dayjs())}
                                {...register('last_sign_date', {
                                    required: true,
                                    disabled: watch('event_date') ? false : true,
                                })}
                            />
                        </Label>
                    )}

                    <Label
                        style={{ gridArea: 'title' }}
                        htmlFor="title"
                        title={`${L('title')}*`}
                        error={errors?.title?.message}
                    >
                        <Input {...register('title', { required: true })} />
                    </Label>

                    <div style={{ gridArea: 'description' }}>
                        <Label title={`${L('description')}*`} error={errors?.description?.message} />
                        <Controller
                            name="description"
                            control={control}
                            render={({ field }) => {
                                return <Quill {...field} placeholder="Skriv något här..." />;
                            }}
                        />
                    </div>
                    <div
                        style={{
                            placeSelf: 'end',
                            whiteSpace: 'nowrap',
                            display: 'flex',
                            gap: style.margin.m,
                            gridArea: 'buttons',
                        }}
                    >
                        <Button
                            type="button"
                            variant="primary"
                            onClick={() => {
                                if (isDirty) {
                                    return triggerDirtyFieldsToats({
                                        continueButtonText: L('continue_without_saving'),
                                        continueFn: () => {
                                            history.push('/event');
                                        },
                                    });
                                }
                                history.push('/event');
                            }}
                            role="link"
                        >
                            {L('cancel')}
                        </Button>

                        <DropdownButton
                            optionId={submitButtonState}
                            variant={'filledPrimary'}
                            setOptionId={(value) => setSubmitButtonState(value)}
                            options={[
                                {
                                    id: 'save_to_draft',
                                    dropdownItem: (
                                        <DropdownButton.DropdownItem>{L('save_to_draft')}</DropdownButton.DropdownItem>
                                    ),
                                    button: (
                                        <DropdownButton.Button
                                            onClick={handleSubmit((formValues) => submit(formValues, 'draft'))}
                                        >
                                            {L('save_to_draft')}
                                        </DropdownButton.Button>
                                    ),
                                },
                                {
                                    id: 'save_and_publish',
                                    dropdownItem: (
                                        <DropdownButton.DropdownItem>
                                            {L('save_and_publish')}
                                        </DropdownButton.DropdownItem>
                                    ),
                                    button: (
                                        <DropdownButton.Button
                                            onClick={handleSubmit((formValues) => submit(formValues, 'publish'))}
                                        >
                                            {L('save_and_publish')}
                                        </DropdownButton.Button>
                                    ),
                                },
                            ]}
                        />
                    </div>
                </GridContainer>
            }
        />
    );
};

export default CreateNewEventPage;
