import React from 'react';
import { style } from '../Shared/Style';
import styled from '@emotion/styled';
import { variant } from 'styled-system';

interface Props {
    title: string;
    textColor?: any;
    textAlign?: any;
}

interface ColorProps {
    textColor?: any;
    textAlign?: any;
}

const Container = styled.div({
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: style.margin.s,
    flexDirection: 'column',
});

const Title = styled.div<ColorProps>(
    {
        fontWeight: style.fontWeight.bold,
        fontSize: style.fontSize.milli,
        lineHeight: '15px',
        letterSpacing: '0.3px',
        textTransform: 'uppercase',
        color: style.colors.secondary,
        marginBottom: style.margin.s,
        marginTop: style.margin.m,
        display: 'flex',
        justifyContent: 'space-between',
    },
    variant({
        prop: 'textColor',
        variants: {
            1: {
                color: 'black',
            },
            0: {
                color: style.colors.black80,
            },
        },
    }),
    variant({
        prop: 'textAlign',
        variants: {
            center: {
                textAlign: 'center',
            },
            left: {
                textAlign: 'left',
            },
            right: {
                textAlign: 'right',
            },
        },
    })
);

const Description = styled.div(({ theme }) => ({
    width: '100%',
    border: `1px solid ${theme.colors.inputBorder}`,
    borderRadius: style.roundCornerSize.small,
    backgroundColor: theme.colors.inputBackground,
    padding: style.margin.s,
    fontSize: style.fontSize.seta,
    marginTop: style.margin.xs,
    whiteSpace: 'pre',
    overflow: 'auto',
    minHeight: '35px',
}));

export const DisplayField: React.FC<Props> = ({ title, textAlign, textColor, children, ...rest }) => {
    return (
        <Container {...rest}>
            <Title textColor={textColor} textAlign={textAlign}>
                {title}
            </Title>
            <Description>{children}</Description>
        </Container>
    );
};
