import { ContentInformationItem } from '@app/api/models/ContentInformation/ContentInformationModels';
import styled from '@emotion/styled';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import { style } from '../../../../components/Shared/Style';
import { L } from '../../../../lib/i18n';
import useDragEnd from '../../../../hooks/useDragEnd';
import BoardContentInformationApi from '@app/api/public/BoardContentInformationApi';
import { boardInformationQueryKeys } from '../hooks/boardInformationQueries';
import { Icon } from '../../../../components/Icon/Icon';
import { tableHeader, title, tooltip } from '../../../../components/Shared/Style/typography';
import { formatDateIsoDayjs } from '@app/shared/lib/formatting';

const List = styled.ul({
    display: 'grid',
    gridTemplateColumns: '1fr',
    listStyle: 'none',
    margin: '0px',
    padding: '0px',
    alignContent: 'start',
});

const templateColumns = '0.04fr 0.7fr 0.2fr' as const;

const Headers = styled.div(() => ({
    margin: '0px',
    display: 'grid',
    gridTemplateColumns: templateColumns,
    columnGap: '2rem',
    alignItems: 'baseline',
    padding: '0px 0.5rem',
}));

const FeedListItem = styled(Link)({
    borderBottom: `1px solid ${style.colors.divider}`,
    padding: '1rem 0.5rem',
    display: 'grid',
    gap: '2rem',
    alignItems: 'center',
    gridTemplateColumns: templateColumns,
    '&:first-of-type': {
        borderTop: `1px solid ${style.colors.divider}`,
    },
});

const IconWrapper = styled.span({
    display: 'flex',
    alignItems: 'center',
    '.st0': { fill: '#AFAFAF' },
});

const ListTitle = styled.span({
    ...title,
});

const ListTimeStamp = styled.span({
    ...tooltip,
});

const HeaderText = styled.span({
    ...tableHeader,
});
type Props = {
    informationList: ContentInformationItem[];
    isDragDisabled: boolean;
};

const InformationItemList = ({ informationList, isDragDisabled }: Props) => {
    const { mutate: dragEnd } = useDragEnd({
        dragableItems: informationList,
        queryKey: boardInformationQueryKeys.list('published'),
        updateSortOrder: BoardContentInformationApi.setSortOrder,
        reverseSortOrder: true,
    });

    return (
        <DragDropContext onDragEnd={(result, provided) => dragEnd({ result, provided })}>
            <Droppable droppableId="linkItems" direction="vertical">
                {(provided) => (
                    <List className="linkItems" {...provided.droppableProps} ref={provided.innerRef}>
                        <Headers>
                            <div />
                            <HeaderText>{L('title')}</HeaderText>

                            <HeaderText>{L('published')}</HeaderText>
                        </Headers>
                        {informationList &&
                            informationList.map((pageListItem, index) => (
                                <Draggable
                                    isDragDisabled={isDragDisabled}
                                    key={pageListItem.id + pageListItem.title}
                                    draggableId={pageListItem.id + pageListItem.title}
                                    index={index}
                                >
                                    {(provided) => (
                                        <FeedListItem
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            to={`/board-information/${pageListItem.id}`}
                                        >
                                            <IconWrapper>
                                                <Icon.VerticalMoreIcon
                                                    size="small"
                                                    style={{
                                                        justifySelf: 'left',
                                                        visibility: isDragDisabled ? 'hidden' : 'visible',
                                                    }}
                                                />
                                            </IconWrapper>
                                            <ListTitle>{pageListItem.title}</ListTitle>
                                            <ListTimeStamp>
                                                {formatDateIsoDayjs(pageListItem.published_at)}
                                            </ListTimeStamp>
                                        </FeedListItem>
                                    )}
                                </Draggable>
                            ))}
                        {provided.placeholder}
                    </List>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default InformationItemList;
