import React from 'react';

const Map = ({ address }: { address: string }) => {
    return (
        <div>
            <iframe
                width="100%"
                height="200px"
                style={{ border: '0' }}
                allowFullScreen
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyA9yqLJmiqxNCFstrNbyzLWSUiGdEwdM4M
        &q=${address}`}
            />
        </div>
    );
};

export default Map;
