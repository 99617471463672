import React from 'react';
import useUpdateFolder from '../../hooks/folders/useUpdateFolder';
import { FolderDetails, UpdateFolderDetails } from '@app/api/models/Folders';
import { MutateOptions } from 'react-query';
import UpdateItemForm from './UpdateItemForm';

type UpdateFolderFormProps = {
    item: FolderDetails;
    onCancel: () => void;
    options?: MutateOptions<
        {
            readonly status: 'success';
            readonly data: FolderDetails;
            readonly message: string | undefined;
        },
        unknown,
        UpdateFolderDetails,
        unknown
    >;
};

const UpdateFolderForm = ({ item, onCancel, options }: UpdateFolderFormProps) => {
    const { mutate: updateFolder, isLoading: isLoading } = useUpdateFolder();

    const submit = async (data: UpdateFolderDetails) => {
        const folderDetails: UpdateFolderDetails = {
            ...data,
            id: item.id,
            parent_id: item.parent_id,
        };

        updateFolder(folderDetails, options);
    };

    return <UpdateItemForm item={item} onCancel={onCancel} onUpdate={submit} isLoading={isLoading} />;
};

export default UpdateFolderForm;
