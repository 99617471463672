import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Button } from '../../Buttons/Button';
import { TextInput } from '../../Forms/Text';
import { Icon } from '../../Icon/Icon';
import { Table } from '../Table';
import { L } from '../../../lib/i18n';
import { useParams } from 'react-router';

import Label from '../../Forms/Label';
import { DocumentDetail } from '@app/api/public/DocumentsApi';
import { DocumentRow } from './DocumentRow';
import PublicDocumentNameColumn from './PublicDocumentNameColumn';
import FormHeader from '../../Forms/FormHeader';
import PublicDocumentFileColumn from './PublicDocumentFileColumn';
import { UseMutateFunction } from 'react-query/types/react';
import { Variables } from '../../../areas/contract/hooks/useDeleteContractDocument';

const AboveTable = styled.div({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
});
const FileInput = styled.input({
    display: 'none',
});

const UploadWrapper = styled.div({
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    alignItems: 'center',
    gap: '1rem',
});

type Props = {
    list: DocumentDetail[];
    itemId?: string;
    saveDocument: (variables: { itemId: string; name: string; file: File }) => void;
    deleteDocument: UseMutateFunction<number | null, unknown, Variables, unknown>;
    updateDocument: (variables: { document: DocumentDetail; queryId?: string }) => void;
};
function DocumentsTable({ list, itemId, saveDocument, deleteDocument, updateDocument }: Props) {
    const [doc, setDoc] = useState<File>();
    const [isNewDoc, setIsNewDoc] = useState(false);
    const [error, setError] = useState('');
    const [name, setName] = useState('');
    const [active, setActive] = useState(new Set<number>());
    const params = useParams<{ id: string }>();

    const uploadedDocuments = (event) => {
        setDoc(event.target.files[0]);
    };

    const edit = (id: number) => {
        active.has(id)
            ? setActive((prev) => new Set([...prev].filter((res) => res !== id)))
            : setActive((prev) => new Set(prev.add(id)));
    };

    const save = () => {
        setError('');
        if (!doc) return setError('Du måste välja en fil');
        if (name === '') return setError('Du måste fylla i ett namn');
        saveDocument({ itemId: itemId ? itemId : params.id, name: name, file: doc });
        setIsNewDoc(false);
    };

    const saveEditedDoc = async (document: DocumentDetail) => {
        document.name = name;
        updateDocument({ document, queryId: itemId });
        console.log('save edit', document);
        setActive((prev) => new Set([...prev].filter((res) => res !== document.id)));
    };

    const newDoc = [
        {
            name: (
                <Label title={''} error={error}>
                    <TextInput
                        placeholder={L('name_document')}
                        autoFocus
                        onChange={(e) => {
                            setName(e);
                            setError('');
                        }}
                    />
                </Label>
            ),
            fileName: (
                <Label title={''} error={error}>
                    <UploadWrapper>
                        <Icon.Upload style={{ cursor: 'pointer' }} />
                        <Label title={'Max 20MB'} />{' '}
                        <FileInput
                            type="file"
                            onChange={(e) => {
                                uploadedDocuments(e);
                                setError('');
                            }}
                            id="document"
                        />
                    </UploadWrapper>
                </Label>
            ),

            save: (
                <div style={{ display: 'flex', alignItems: 'end', height: '100%' }}>
                    <Button onClick={save}>{L('save')}</Button>
                </div>
            ),
        },
    ];
    const listDocs =
        list &&
        list.map((doc) => ({
            name: active.has(doc.id) ? (
                <TextInput defaultValue={doc.name} onChange={(e) => setName(e)} style={{ marginRight: '2rem' }} />
            ) : doc?.document_data?.public_url ? (
                <PublicDocumentNameColumn document={doc} />
            ) : (
                <DocumentRow document={doc} />
            ),
            fileName: doc?.document_data?.public_url ? (
                <PublicDocumentFileColumn document={doc} />
            ) : (
                <DocumentRow document={doc} />
            ),
            save: active.has(doc.id) && <Button onClick={() => saveEditedDoc(doc)}>{L('save')}</Button>,
            edit: <Icon.Settings onClick={() => edit(doc.id)} />,
            delete: (
                <Icon.TrashCan
                    onClick={() =>
                        deleteDocument({
                            documentId: String(doc.id),
                            queryId: itemId ? itemId.toString() : params.id.toString(),
                        })
                    }
                />
            ),
        }));
    const rowsDocs = isNewDoc ? [...newDoc, ...(listDocs || [])] : [...(listDocs || [])];
    return (
        <>
            <AboveTable>
                <FormHeader>{L('document')}</FormHeader>

                <Icon.Add onClick={() => setIsNewDoc((prev) => !prev)} />
            </AboveTable>
            <Table
                columns={[
                    { key: 'name', header: L('name'), width: '1fr' },
                    // { key: 'created_at', width: '3fr' },
                    { key: 'fileName', width: '1fr' },
                    // { key: 'remove', width: '3fr' },
                    { key: 'save', width: '1fr' },
                    { key: 'edit', width: '0.3fr' },
                    { key: 'delete', width: '0.3fr' },
                ]}
                data={rowsDocs}
            />
        </>
    );
}

export default DocumentsTable;
