import React, { HTMLAttributes } from 'react';
import { DropEvent, DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import UploadSvg from '../../../../components/Icon/dragAndDropIcon.svg';
import { QueryStatus } from 'react-query';
import { BarLoader } from 'react-spinners';
import { roundCornerSize } from '../../../../components/Shared/Style/box';
import { colors } from '../../../../components/Shared/Style/colors';

const StyledDropZone = styled.div({
    backgroundColor: colors.white,
    minWidth: '100px',
    aspectRatio: '1/1',
    borderRadius: roundCornerSize.medium,
    border: `1px dashed ${colors.inputBorder}`,
    display: 'grid',
    placeItems: 'center',
    cursor: 'pointer',
});

const WithImageDropZone = styled(StyledDropZone)({
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    padding: '0px',
    overflow: 'hidden',
    placeItems: 'start end',
    '.hover_info': {
        display: 'none',
    },
    '.upload': {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        display: 'grid',
        height: '100%',
    },
    ':hover': {
        placeItems: 'center',
        '.hover_info': {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            display: 'grid',
            height: '100%',
            width: '100%',
        },

        '.with_image': {
            display: 'none',
        },
    },
});

const GridContainer = styled.div({
    display: 'grid',
    placeItems: 'center',
    height: '100%',
    width: '100%',
});
const StyledUploadIcon = styled.div({
    width: 'max-content',
    display: 'flex',
});

const UploadContainer = styled.div({
    display: 'grid',
    gap: '0.5rem',
    position: 'relative',
    placeItems: 'center',
    width: '80%',
});

const ProgressBar = styled.div(({ theme }) => ({
    borderRadius: '8px',
    bottom: '-16px',
    height: '8px',
    backgroundColor: theme.progressBarBackground,
    overflow: 'hidden',
    width: '100%',
    position: 'absolute',
}));

const Progress = styled.div(({ theme }) => ({
    backgroundColor: theme.primary,
    width: '100%',
    height: '100%',
    translate: '-100%',
    borderRadius: '8px',
}));

const UploadText = styled.p(({ theme }) => ({
    margin: '0px',
    color: theme.black,
    textAlign: 'center',
    textOverflow: 'ellipsis',
    fontSize: '1rem',
    '& span': {
        color: theme.primary,
    },
}));

const UploadIcon = () => {
    return (
        <StyledUploadIcon>
            <UploadSvg />
        </StyledUploadIcon>
    );
};

const UploadInfo = ({ className, children, ...rest }: HTMLAttributes<HTMLDivElement>) => {
    return (
        <GridContainer className={'hover_info ' + className} {...rest}>
            <UploadContainer>
                <UploadIcon />
                <UploadText>
                    <span>Ladda upp</span> eller släpp din fil här
                </UploadText>
                {children}
            </UploadContainer>
        </GridContainer>
    );
};

const WithImage = () => {
    return (
        <UploadContainer
            className={'with_image'}
            style={{
                backgroundColor: colors.white,
                padding: '8px',
                margin: '8px',
                borderRadius: roundCornerSize.medium,
                width: 'max-content',
            }}
        >
            <UploadIcon />
        </UploadContainer>
    );
};

const OnUploadComponent = ({
    acceptedFiles,
    progress,
    className,
    ...rest
}: { acceptedFiles: File[]; progress?: number } & HTMLAttributes<HTMLDivElement>) => {
    return (
        <GridContainer className={'upload ' + className} {...rest}>
            <UploadContainer>
                <UploadIcon />
                <UploadText>{acceptedFiles?.[0]?.name}</UploadText>
                {typeof progress === 'number' ? (
                    <ProgressBar>
                        <Progress
                            style={{
                                translate: `${progress - 100}%`,
                            }}
                        />
                    </ProgressBar>
                ) : (
                    <BarLoader
                        color={colors.primary}
                        cssOverride={{
                            position: 'absolute',
                            bottom: '-16px',
                            width: '100%',
                        }}
                    />
                )}
            </UploadContainer>
        </GridContainer>
    );
};

type OnDrop = <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void;
export type Props = {
    dropzoneOptions?: DropzoneOptions;
    onDrop: OnDrop;
    url?: string;
    status?: QueryStatus;
};
const DropZoneNoText = ({ dropzoneOptions = {}, onDrop, url, status = 'idle' }: Props) => {
    const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
        onDrop,
        ...dropzoneOptions,
    });

    if (url) {
        return (
            <WithImageDropZone
                style={{
                    backgroundImage: `url(${url})`,
                }}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                {status === 'loading' ? (
                    <OnUploadComponent acceptedFiles={acceptedFiles} />
                ) : (
                    <>
                        <WithImage />
                        <UploadInfo />
                    </>
                )}
            </WithImageDropZone>
        );
    }
    return (
        <StyledDropZone {...getRootProps()}>
            <input {...getInputProps()} />
            {status === 'loading' ? <OnUploadComponent acceptedFiles={acceptedFiles} /> : <UploadInfo />}
        </StyledDropZone>
    );
};

export default DropZoneNoText;
