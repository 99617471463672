import { useQuery } from 'react-query';
import I18Api from '@app/api/public/I18nApi';
import { preparei18, spreadNewStringDatas } from './i18n';
import { ProjectType } from '@app/api/models/Projects';
import ProjectsApi from '@app/api/public/ProjectsApi';
import { LoginStore } from '../areas/login/LoginStore';
import { useObserver } from 'mobx-react';

const getI18ProjectTypes = async (projectType?: ProjectType) => {
    if (!projectType) return null;
    return await I18Api.getProjectTypeStrings(projectType);
};

export const useI18Theme = () => {
    useQuery(['geti18String'], () => preparei18());

    useObserver(() => {
        const { data: projectDetail } = useQuery(['getPropertyDetail'], () => ProjectsApi.getbyid(), {
            enabled: LoginStore.state === 'logged-in',
        });

        const { data: i18PropertyTypes } = useQuery(
            ['i18PropertyTypes', projectDetail?.project_type],
            () => getI18ProjectTypes(projectDetail?.project_type as ProjectType),
            {
                enabled: !!projectDetail?.project_type,
            }
        );

        if (i18PropertyTypes) spreadNewStringDatas(i18PropertyTypes);
    });
};
