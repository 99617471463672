import React from 'react';
import RSelect, { GroupBase, Props } from 'react-select';
import * as Icons from 'react-feather';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { ThemeType } from '@app/api/models/Theme';

const Option = styled.span(({ theme }) => ({
    color: theme.colors.primary,
}));
function formatOptionLabel(label, v, meta) {
    if (label && meta && meta.context === 'value') {
        return (
            <>
                {label}: <Option>{v.label}</Option>
            </>
        );
    }
    return v.label;
}

const styles = {
    container: (st) => ({ ...st, fontSize: '80%', height: 32 }),
    control: (st) => ({
        ...st,
        borderRadius: 4,
        minHeight: '32px',
        cursor: 'pointer',
    }),
    indicatorsContainer: (st) => ({ ...st, padding: 0 }),
    indicatorSeparator: () => ({ display: 'none' }),
    placeholder: (st) => ({
        ...st,
        position: 'relative',
        transform: 'inherit',
        cursor: 'pointer',
    }),
    singleValue: (st) => ({
        ...st,
        position: 'relative',
        transform: 'inherit',
        width: '100%',
    }),
    option: (st) => ({
        ...st,
        cursor: 'pointer',
        color: 'hsl(0,0%,20%)',
    }),
    menu: (st) => ({ ...st, minWidth: '12em', backgroundColor: 'white' }),
    input: (st) => ({ ...st, caretColor: 'transparent', maxWidth: 0 }),
};

const components = {
    DropdownIndicator() {
        return <Icons.ChevronDown color="#999" style={{ paddingRight: 3 }} size={16} />;
    },
    ClearIndicator(props) {
        const click = (evt) => {
            props.clearValue();
            evt.stopPropagation();
        };
        return <Icons.X onMouseDown={click} onClick={click} color="#999" style={{ paddingRight: 3 }} size={16} />;
    },
};

const ReactSelect = <Option, isMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
    props: Props<Option, isMulti, Group>
) => {
    const themes = useTheme<ThemeType>();
    return (
        <RSelect
            styles={styles}
            components={components}
            formatOptionLabel={(v, meta) => formatOptionLabel(props.name, v, meta)}
            theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary50: themes.colors.primary50,
                    primary25: themes.colors.primary25,
                    primary: themes.colors.primary25,
                    neutral0: themes.colors.inputBackground,
                },
            })}
            {...props}
        />
    );
};

export default ReactSelect;
