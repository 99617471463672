import { Maintenance } from '@app/api/models/Maintenances';
import dayjs from 'dayjs';

const useGenerateMaintenanceIntevalItems = (
    maintenance?: Partial<Maintenance>,
    endDate?: string,
    intervalDates?: string[]
) => {
    if (!maintenance) return [];
    const item: Partial<Maintenance> = {
        description: maintenance.description,
        maintenance_category: maintenance.maintenance_category,
        maintenance_status: maintenance.maintenance_status,
        maintenance_type: maintenance.maintenance_type,
        maintenance_unit: maintenance.maintenance_unit,
        planned_cost: maintenance.planned_cost,
        planned_date: maintenance.planned_date,
        interval: maintenance.interval,
        name: maintenance.name,
    };
    if (!endDate) return [];
    if (!item) return [];
    if (!item.interval) return [];
    if (!dayjs(item.planned_date).isValid()) return [];

    const newMaintenanceList: Partial<Maintenance>[] = [];

    intervalDates
        ?.filter((date) => dayjs(date).isSameOrBefore(endDate, 'month'))
        .forEach((date) => newMaintenanceList.push({ ...item, planned_date: date }));

    newMaintenanceList.shift();

    return newMaintenanceList;
};

export default useGenerateMaintenanceIntevalItems;
