import React, { FC } from 'react';
import styled from '@emotion/styled';
import { colors, Colors } from '../../Shared/Style/colors';

export type Props = {
    width?: number;
    margin?: string;
    color?: Colors;
};

const StyledDivider = styled.div(({ width = 90, margin = '16px 0px', color }: Props) => {
    return {
        borderTop: `1px solid ${color ? colors[color] : colors.divider}`,
        margin,
        width: `${width}%`,
    };
});

const DividerContainer = styled.div(() => {
    return {
        display: 'grid',
        placeItems: 'center',
    };
});

const Divider: FC<Props> = ({ width, margin, color, ...rest }) => {
    return (
        <DividerContainer {...rest}>
            <StyledDivider margin={margin} width={width} color={color} />
        </DividerContainer>
    );
};

export default Divider;
