import React from 'react';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';
import { L } from '../../lib/i18n';

const Container = styled.div(({ theme }) => ({
    color: theme.colors.secondary,
    fontSize: style.fontSize.seta,
    marginBottom: '22px',
    marginRight: style.margin.m,
    display: 'flex',
    alignItems: 'center',
}));

const Label = styled.div({
    fontSize: style.fontSize.milli,
    fontWeight: style.fontWeight.bold,
    paddingRight: style.margin.m,
    textTransform: 'uppercase',
});

type Props = {
    children: React.ReactNode;
};
const Filters = ({ children, ...rest }: Props) => {
    return (
        <Container {...rest}>
            <Label className="label">{L('filter_by')}: </Label>
            {children}
        </Container>
    );
};

export default Filters;
