import ContactsApi from '@app/api/public/ContactsApi';
import { useQuery } from 'react-query';

export const getContactListQueryKey = 'getContacts';

const getContacts = () => {
    return ContactsApi.getList();
};

const useGetContactList = () => {
    return useQuery([getContactListQueryKey], () => getContacts());
};

const getContactById = (id: string) => {
    return ContactsApi.getbyid(id);
};

const useGetContactById = (id: string) => {
    return useQuery([getContactListQueryKey, id], () => getContactById(id));
};

export { useGetContactById, useGetContactList as default };
