import { Membership } from '@app/api/models/Members';
import BoardMembersApi from '@app/api/public/BoardMembersApi';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { L } from '../../../lib/i18n';
import { getBoardMemberListQueryKey } from './useGetBoardMembers';

const removeNewBoardMember = async (membershipId: Membership['id']) => {
    const res = await BoardMembersApi.removeBoardMember(membershipId);

    if (res.statuts === 'error') return Promise.reject(res.message);

    return res.data;
};

const useRemoveNewBoardMember = () => {
    const queryClient = useQueryClient();
    return useMutation((membershipId: Membership['id']) => removeNewBoardMember(membershipId), {
        onError: (err) => {
            if (err instanceof Error) console.log(err.message);
            /* Shows error message */
            const error = typeof err === 'string' ? err : new Error(L('reset_error')).message;
            const message = error;
            toast.error(`${L(message)}`);
        },
        onSettled: () => {
            queryClient.invalidateQueries([getBoardMemberListQueryKey]);
        },
    });
};

export default useRemoveNewBoardMember;
