import styled from '@emotion/styled';
import React from 'react';
import CardTitle from '../typography/CardTitle';
import H4 from '../typography/H4';
import SpanText from '../typography/SpanText';

type Props = {
    title?: string;
    list?: any[];
};

const Container = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
});

const StyledSpanText = styled(SpanText)({
    marginLeft: '4px',
});

const Buttons = styled.div({});

const CardWithDividerTopArea: React.FC<Props> = ({ title, list, children }) => {
    return (
        <Container>
            <CardTitle>
                {title}
                {list && list?.length > 0 && <StyledSpanText>({list?.length})</StyledSpanText>}
            </CardTitle>
            <Buttons>{children}</Buttons>
        </Container>
    );
};

export default CardWithDividerTopArea;
