import * as React from 'react';
import { MenuData } from './MenuData';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import Logo from '../Logo/Logo';

const DesktopMenu = styled.div(({ theme }) => ({
    display: 'none',
    gridArea: 'nav',
    backgroundColor: theme.colors.sidebarBg,
    ['@media screen and (min-width: 1024px)']: {
        display: 'flex',
        flexDirection: 'column',
        gap: '78px',
    },
}));

const LogoLink = styled(Link)({
    width: '100%',
    display: 'flex',
    marginTop: '28px',
    justifyContent: 'center',
});

const StyledLogo = styled(Logo)({
    width: '80%',
    height: '100%',
    maxHeight: '100px',
    placeSelf: 'center',
});

export const MainMenu = ({ ...rest }) => {
    return (
        <DesktopMenu {...rest}>
            <LogoLink to={'/'}>
                <StyledLogo />
            </LogoLink>
            <MenuData />
        </DesktopMenu>
    );
};
