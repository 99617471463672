import styled from '@emotion/styled';
import React from 'react';
import { DocumentDetail } from '@app/api/public/DocumentsApi';

const StyledDocumentRow = styled.a({
    textDecoration: 'none',
    display: 'block',
    fontSize: '12px',
    cursor: 'pointer',
    color: 'inherit',
});

const PublicDocumentNameColumn = ({ document }: { document: DocumentDetail }) => {
    return (
        <StyledDocumentRow href={document?.document_data?.public_url} target="_blank" rel="noreferrer">
            {document.name}
        </StyledDocumentRow>
    );
};

export default PublicDocumentNameColumn;
