import { useMutation, useQueryClient } from 'react-query';
import DocumentsApi from '@app/api/public/DocumentsApi';
import toast from 'react-hot-toast';
import { fileKeys } from '../../queryKeys';
import { L } from '../../../../lib/i18n';

const useDeleteFile = (fileName?: string) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: DocumentsApi.deleteFile,
        onError: (err) => {
            const errorMessage = typeof err === 'string' ? err : new Error(L('reset_error')).message;
            toast.error(`${L(errorMessage)}`);
        },
        onSettled: () => {
            queryClient.invalidateQueries([fileKeys]);
        },
        onSuccess: () => {
            toast.success(L('file_deleted') + ': `' + fileName + '`');
        },
    });
};

export default useDeleteFile;
