import { colors } from './colors';

export const fontSize = {
    giga: 72 as const,
    mega: 54 as const,
    kilo: 36 as const,
    alpha: 32 as const,
    beta: 28 as const,
    gamma: 24 as const,
    delta: 18 as const,
    epsilon: 16 as const,
    seta: 14 as const,
    centi: 13 as const,
    milli: 12 as const,
    micro: 10 as const,
} as const;

export const fontWeight = {
    thin: 300 as const,
    normal: 400 as const,
    bold: 700 as const,
} as const;

export const lineHeight = {
    normal: 1.5 as const,
    tight: 1.3 as const,
} as const;

export const p = {
    fontSize: '0.8rem',
    margin: '0px',
} as const;

export const tooltip = {
    fontSize: '0.8rem',
    margin: '0px',
} as const;

export const title = {
    fontSize: '1rem',
    margin: '0px',
} as const;

export const label = {
    fontWeight: 700 as const,
    fontSize: '0.75rem' as const,
    letterSpacing: '0.3px' as const,
    textTransform: 'uppercase' as const,
    color: colors.secondary,
} as const;

export const tableHeader = {
    color: colors.secondary,
    padding: '0px 16px 16px 0px',
    fontSize: '1rem',
} as const;
