import React from 'react';
import { style } from '../Shared/Style';
import styled from '@emotion/styled';

const TextInputField = styled.input(({ theme }) => ({
    background: theme.colors.inputBackground,
    border: `1px solid ${theme.colors.inputBorder}`,
    width: '100%',
    height: '42px',
    borderRadius: style.roundCornerSize.small,
    outline: 'none',
    padding: style.margin.s,
    fontSize: style.fontSize.seta,
}));

type Props = {
    name?: string;
    value?: string;
    onChange(value: string, name?: string): void;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const TextInput = ({ name, onChange, ...rest }: Props) => {
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value, name);
    return <TextInputField onChange={onChangeHandler} {...rest} />;
};
