import React from 'react';
import { style } from '../Shared/Style';
import styled from '@emotion/styled';

type Props = {
    title: any;
    children?: any;
    error?: string;
} & React.LabelHTMLAttributes<HTMLLabelElement>;

const Field = styled.label({
    margiBottom: style.margin.ml,
    display: 'block',
});

const Title = styled.div(({ theme }) => ({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: theme.colors.secondary,
    marginBottom: style.margin.s,
    marginTop: style.margin.m,
    display: 'flex',
    justifyContent: 'space-between',
}));

const Description = styled.div({});

const Errors = styled.span({
    color: 'red',
});

export function FormField({ error, title, children, ...rest }: Props) {
    const errorText = error;
    const hasError = errorText !== undefined;

    return (
        <Field {...rest}>
            <Title>
                <span>{title}</span>
                {hasError && <Errors>{errorText}</Errors>}
            </Title>
            <Description>{children}</Description>
        </Field>
    );
}
