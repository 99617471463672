Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["id"] = createRpcProxy("ProfilesApi", "id", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getUsersFromProject"] = createRpcProxy("ProfilesApi", "getUsersFromProject", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["save"] = createRpcProxy("ProfilesApi", "save", {
  "urlPrefix": "/rpc"
});
o["saveProfileImage"] = createRpcProxy("ProfilesApi", "saveProfileImage", {
  "urlPrefix": "/rpc"
});