import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import BigTabListItem from '../../../components/BigTabItem/BigTabListItem';
import BigNavItem from '../../../components/BigTabItem/BigTabListItemNav';
import BigTabNav from '../../../components/BigTabItem/BigTabNav';
import PageTitle from '../../../components/typography/PageTitle';
import { L } from '../../../lib/i18n';
import CreateNewInformationPage from './CreateNewInformationPage/CreateNewInformation';
import InformationDetailPage from './DetailPage/InformationDetailPage';
import { useGetBoardInformationById } from './hooks/boardInformationQueries';
import InformationDraftListpage from './ListPage/InformationDraftListPage';
import InformationPublishedListPage from './ListPage/InformationPublishedListPage';

const BoardInformationPage = () => {
    const { id } = useParams<{ id: string }>();
    const { data } = useGetBoardInformationById(+id);

    return (
        <>
            <PageTitle>{L('board_information_title')}</PageTitle>
            <BigTabNav>
                <BigNavItem exact to={'/board-information'}>
                    {L('all_published')}
                </BigNavItem>
                <BigNavItem exact to={'/board-information/draft'}>
                    {L('draft')}
                </BigNavItem>
                <Switch>
                    <Route exact path={'/board-information/draft'} />
                    <Route exact path={'/board-information/new'}>
                        <div style={{ borderRight: 'solid 1px gray' }} />
                        <BigTabListItem className="active">{L('new_information_page')}</BigTabListItem>
                    </Route>
                    <Route path={'/board-information/:id'}>
                        <div style={{ borderRight: 'solid 1px gray' }} />
                        <BigNavItem exact to={`/board-information/${data?.id}`}>
                            {data?.title}
                        </BigNavItem>
                    </Route>
                </Switch>
            </BigTabNav>
            <Switch>
                <Route path={'/board-information/new'}>
                    <CreateNewInformationPage />
                </Route>
                <Route exact path={'/board-information/draft'}>
                    <InformationDraftListpage />
                </Route>
                <Route path={'/board-information/:id'}>
                    <InformationDetailPage />
                </Route>
                <Route exact path={'/board-information'}>
                    <InformationPublishedListPage />
                </Route>
            </Switch>
        </>
    );
};

export default BoardInformationPage;
