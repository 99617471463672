import { FileDetails } from '@app/api/models/Folders';
import React from 'react';
import useDeleteFile from '../hooks/files/useDeleteFile';
import { MutateOptions } from 'react-query';
import { L } from '../../../lib/i18n';
import DeleteDialog from './DeleteDialog';

type Props = {
    item: FileDetails;
    onCancel: () => void;
    options?: MutateOptions<Error | number, unknown, number, unknown>;
};

const DeleteFile = ({ item, onCancel, options }: Props) => {
    const { mutate: deleteFile, isLoading } = useDeleteFile(item.name);

    return (
        <DeleteDialog
            isLoading={isLoading}
            onDelete={() => deleteFile(item.id, options)}
            onCancel={() => onCancel()}
            title={`${L('are_you_sure_you_want_to_delete')}: '${item.name}' ?`}
        />
    );
};

export default DeleteFile;
