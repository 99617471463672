import React from 'react';
import ReactSelect, { Props } from 'react-select';
import { useObserver } from 'mobx-react';
import { style } from '../Shared/Style';
import styled from '@emotion/styled';
import * as Icons from 'react-feather';
import { useTheme } from 'emotion-theming';
import { ThemeType } from '@app/api/models/Theme';

const Option = styled.span({ color: style.colors.primary });

const styles = {
    container: (st) => ({ ...st, fontSize: '80%', height: 32 }),
    control: (st) => ({
        ...st,
        borderRadius: 4,
        height: '32px',
        minHeight: '32px',
    }),
    indicatorsContainer: (st) => ({ ...st, padding: 0 }),
    indicatorSeparator: () => ({ display: 'none' }),
    placeholder: (st) => ({
        ...st,
        position: 'relative',
        transform: 'inherit',
        cursor: 'pointer',
    }),
    singleValue: (st) => ({
        ...st,
        position: 'relative',
        transform: 'inherit',
    }),
    option: (st) => ({
        ...st,
        cursor: 'pointer',
    }),
    menu: (st) => ({ ...st, minWidth: '12em', backgroundColor: 'white' }),
    input: (st) => ({ ...st, caretColor: 'transparent', maxWidth: 0 }),
};

const components = {
    DropdownIndicator() {
        return <Icons.ChevronDown color="#999" style={{ paddingRight: 3 }} size={16} />;
    },
    ClearIndicator(props) {
        const click = (evt) => {
            props.clearValue();
            evt.stopPropagation();
        };
        return <Icons.X onMouseDown={click} onClick={click} color="#999" style={{ paddingRight: 3 }} size={16} />;
    },
};

const MultiSelect = (props: Props) => {
    const themes = useTheme<ThemeType>();
    return (
        <ReactSelect
            isMulti={true}
            closeMenuOnSelect={false}
            controlShouldRenderValue={false}
            isClearable
            components={components}
            hideSelectedOptions={false}
            value={props.value}
            onChange={props.onChange}
            placeholder={props.placeholder}
            name={props.name}
            options={props.options}
            styles={styles}
            theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary50: themes.colors.primary50,
                    primary25: themes.colors.primary25,
                    primary: themes.colors.primary25,
                    neutral0: themes.colors.inputBackground,
                },
            })}
            {...props}
        />
    );
};

export default MultiSelect;
