import React from 'react';
import { L } from '../../../lib/i18n';
import styled from '@emotion/styled';
import Label from '../../../components/Forms/Label';
import dayjs from 'dayjs';
import useGetUserById from '../../../lib/hooks/useGetUserById';
import { Loading } from '../../../components/Loading/Loading';
import { ProjectDetail } from '@app/api/public/ProjectsApi';
import { formatDateIsoDayjs } from '@app/shared/lib/formatting';

type Props = {
    project: ProjectDetail;
};

const Status = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'right',
    fontSize: '12px',
});

const ProjectStatusArea = ({ project }: Props) => {
    const { data: createdBy } = useGetUserById(+project?.created_by_id);
    const { data: projectOwner } = useGetUserById(+project?.project_owner_id);


    if (!project) return <Loading />;
    return (
        <>
            <Status>
                <Label title={L('created_at')}>
                    {project.created_at && formatDateIsoDayjs(project.created_at)}
                </Label>
                <Label title={L('created_by')}>{createdBy?.name || createdBy?.email || ''}</Label>
                <Label title={L('project_owner')}>{projectOwner?.name || projectOwner?.email || ''}</Label>
                <Label title={L('houses_published')}>
                    {project.published_properties_at && formatDateIsoDayjs(project.published_properties_at)}
                </Label>
                <Label title={L('communication_published')}>
                    {project.published_at &&
                        formatDateIsoDayjs(project.published_at)}
                </Label>
            </Status>
        </>
    );
};

export default ProjectStatusArea;
