import { BookingSlot } from '@app/api/models/Bookings';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React from 'react';
import { useParams } from 'react-router-dom';
import customFormat from 'dayjs/plugin/customParseFormat';
import { Column } from 'react-table';
('');
import { Button } from '../../../../components/Buttons/Button';
import { TextInput } from '../../../../components/Forms/Text';
import { Icon } from '../../../../components/Icon/Icon';
import { margin } from '../../../../components/Shared/Style/margin';
import ReactTable from '../../../../components/Table/ReactTable/FilterTable';
import { L } from '../../../../lib/i18n';
import useGetBookingResourceSlots from '../../hooks/useGetBookingResourceSlots';
import useRemoveTimeSlot from '../../hooks/useRemoveTimeslot';
import AddTimeslotInterval from './AddTimeslotInterval';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';

dayjs.extend(customFormat);
const AboveTable = styled.div({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
});

const TableTitle = styled.span({
    margin: `${margin.m}px 0px`,
});

const Errors = styled.span(({ error }: { error: string }) => {
    return {
        opacity: error ? 1 : 0,
        textAlign: 'end',
        color: 'red',
    };
});

function TimeSlotsTable() {
    const { bookingId } = useParams<{ bookingId: string }>();
    const { data: bookingResourceSlots } = useGetBookingResourceSlots(bookingId);
    const { mutate: removeTimeSlot, error, reset } = useRemoveTimeSlot();

    const slotColumns: Column[] = [
        {
            Header: L('name'),
            accessor: 'name',
        },
        {
            Header: L('starttime'),
            accessor: (originalRow: BookingSlot) => {
                return dayjs(originalRow.starttime, 'HH:mm').valueOf();
            },
            Cell: ({ value }) => {
                return dayjs(value).format('HH:mm');
            },
        },
        {
            Header: L('endtime'),
            accessor: (originalRow: BookingSlot) => {
                return dayjs(originalRow.endtime, 'HH:mm').valueOf();
            },
            Cell: ({ value }) => {
                return dayjs(value).format('HH:mm');
            },
        },
        {
            Header: L('delete'),
            accessor: (originalRow: BookingSlot) => {
                return originalRow.id;
            },
            Cell: ({ value }: { value: string }) => {
                return <Icon.TrashCan onClick={() => removeTimeSlot({ id: value })} />;
            },
            disableSortBy: true,
        },
    ];

    return (
        <CardWithDivider
            style={{ marginTop: '2rem' }}
            topArea={
                <AboveTable>
                    <CardTitle>Timeslots</CardTitle>
                    <AddTimeslotInterval />
                </AboveTable>
            }
            mainArea={
                <>
                    <Errors error={typeof error === 'object' ? L(error?.['message']) : ''}>
                        {typeof error === 'object' ? L(error?.['message']) : ''}
                    </Errors>
                    {bookingResourceSlots && (
                        <ReactTable
                            data={bookingResourceSlots.map((i) => {
                                return { ...i };
                            })}
                            // onClick={() => reset()}
                            columns={slotColumns}
                        />
                    )}
                </>
            }
        />
    );
}

export default TimeSlotsTable;
