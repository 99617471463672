import ProfilesApi from '@app/api/public/ProfilesApi';
import { useMutation, useQueryClient } from 'react-query';
import { profileQueryKey } from '../../../hooks/useGetProfile';
import { signedUrlByIdQueryKeys } from '../../../hooks/useGetSignedUrlById';

type Variables = { file: File };
const uploadProfileImage = async (data: Variables) => {
    const profile = ProfilesApi.saveProfileImage(data.file);
    if (!profile) return Promise.reject('Kunde inte ladda upp Avatar');
    return Promise.resolve(profile);
};

const useUploadProfileImage = () => {
    const queryClient = useQueryClient();
    return useMutation((data: Variables) => uploadProfileImage(data), {
        onSettled: () => {
            queryClient.invalidateQueries([signedUrlByIdQueryKeys]);
            queryClient.invalidateQueries([profileQueryKey]);
        },
    });
};

export default useUploadProfileImage;
