import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React from 'react';
import { NavLink } from 'react-router-dom';
import useGetByPageType from '../../../components/Website/hooks/useGetByPageType';
import useGetProjectInfo from '../../../hooks/useGetProjectInfo';
import { L } from '../../../lib/i18n';
import { colors } from '../../../components/Shared/Style/colors';
import VerticalMoreIcon from '../../../components/Icon/vertical-dots.svg';

const StyledNavItem = styled.li({});
const StyledNavLink = styled(NavLink)(({ theme }) => ({
    padding: '10px 20px',
    borderLeft: '6px solid',
    borderColor: 'gray',
    width: '100%',
    marginBottom: '1rem',
    backgroundColor: 'white',
    cursor: 'pointer',
    borderRadius: '5px',
    listStyle: 'none',
    display: 'block',
    boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.1)',
    '&.published': {
        borderColor: '#29CC97',
    },
    '&.active': {
        boxShadow: `0 0 3px ${theme.colors.primary}`,
    },
    textDecoration: 'none',
    color: 'black',
}));
const SmallInfo = styled.div({
    fontSize: '0.8rem',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
});

const Wrapper = styled.div({
    marginLeft: '1rem',
});

const FlexWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
});

const IconWrapper = styled.div({
    width: '1rem',
    opacity: 0,
});

const StaticHomePageMenuItem = () => {
    const { data: contactPage } = useGetByPageType('contact_page');
    const { data: projectInfo } = useGetProjectInfo();
    return (
        <StyledNavItem onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
            <StyledNavLink to={`/public-site/contact-page`} className={contactPage?.published_at ? 'published' : ''}>
                <FlexWrapper>
                    <IconWrapper>
                        <VerticalMoreIcon />
                    </IconWrapper>
                    <Wrapper>
                        <h1>{L('contact')}</h1>
                        <SmallInfo>
                            <span>
                                {L('created_by')} <b>{projectInfo?.name}</b>
                            </span>
                            <span>
                                {L('last_edited')}{' '}
                                <b>
                                    {contactPage
                                        ? dayjs(contactPage.updated_at).format('YYYY-MM-D')
                                        : L('never_edited')}
                                </b>
                            </span>
                        </SmallInfo>
                    </Wrapper>
                </FlexWrapper>
            </StyledNavLink>
        </StyledNavItem>
    );
};

export default StaticHomePageMenuItem;
