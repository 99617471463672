import AlertsApi from '@app/api/public/AlertsApi';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { L } from '../../../lib/i18n';

export type RemoveAlert = { alertId: number };

const removeAlert = ({ alertId }: RemoveAlert) => {
    return AlertsApi.remove(alertId);
};

const useRemoveAlert = () => {
    return useMutation(({ alertId }: RemoveAlert) => removeAlert({ alertId }), {
        onMutate: () => {
            const id = toast.loading('Tar bort viktigt meddelande');
            return id;
        },
        onSuccess: (_data, _variables, id: string) => {
            toast.success('Tar bort viktigt meddelande borttaget', { id });
        },
        onError: (_error, _variables, id: string) => {
            toast.error(L('error'), { id });
        },
    });
};

export default useRemoveAlert;
