import MembersApi from '@app/api/public/MembersApi';
import { useQuery } from 'react-query';
import { User } from '@app/api/public/UsersApi';

export const getPropertiesForMemberQueryKey = 'getPropertiesForMember';

const getPropertiesForMember = (userId?: User['id']) => {
    if (!userId) return null;
    return MembersApi.listPropertiesForUser(userId);
};

const useGetPropertiesForMember = (userId?: User['id']) => {
    return useQuery([getPropertiesForMemberQueryKey, userId], () => getPropertiesForMember(userId), {
        enabled: !!userId,
    });
};

export default useGetPropertiesForMember;
