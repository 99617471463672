import * as React from 'react';
import { BookingFilter } from '@app/api/models/Bookings';
import styled from '@emotion/styled';
import { L } from '../../../lib/i18n';
import { useParams } from 'react-router';
import useGetBookinUsers from '../hooks/useGetBookingUsers';
import ReactSelect from '../../../components/Dropdown/ReactSelect';
import useGetProperties from '../hooks/useGetProperties';
import Filters from '../../../components/Filters/Filters';
import Input from '../../../components/Forms/Input';
import StyledCross from '../Components/StyledCross';
import { style } from '../../../components/Shared/Style';

const Container = styled.div({ paddingRight: '10px' });

type Props = {
    onChange: React.Dispatch<React.SetStateAction<Partial<BookingFilter>>>;
    filter: BookingFilter;
};

const Label = styled.div({
    fontSize: style.fontSize.milli,
    fontWeight: style.fontWeight.bold,
    paddingRight: style.margin.m,
    textTransform: 'uppercase',
});

const StyledFilters = styled(Filters)({
    marginBottom: '0px',
});

export const BookingFilterPanel = (props: Props) => {
    const { bookingId } = useParams<{ bookingId: string }>();
    const { data: users } = useGetBookinUsers(bookingId);
    const { data: properties } = useGetProperties();

    const userValue = users?.find((user) => user.user_id === props.filter.user_id);
    const propertyValue = properties?.find(
        (property) => props.filter.property_id && property.id === +props.filter.property_id
    );

    const clearAll = () => {
        props.onChange({ user_id: '', booking_date_end: '', booking_date_start: '', property_id: '' });
    };

    return (
        <StyledFilters>
            <Container>
                <ReactSelect
                    isClearable
                    value={
                        userValue
                            ? {
                                  value: userValue.user_id,
                                  label: userValue.user_name,
                              }
                            : ''
                    }
                    onChange={(value) => props.onChange({ ...props.filter, user_id: value?.value })}
                    placeholder={L('user_id')}
                    options={
                        users?.map((user) => {
                            return { label: user.user_name, value: user.user_id };
                        }) || []
                    }
                />
            </Container>
            <Container>
                <ReactSelect
                    isClearable
                    value={
                        propertyValue
                            ? {
                                  value: propertyValue.id,
                                  label: propertyValue.name,
                              }
                            : ''
                    }
                    onChange={(value) => props.onChange({ ...props.filter, property_id: value?.value.toString() })}
                    placeholder={L('property')}
                    options={
                        properties?.map((property) => {
                            return { label: property.name, value: property.id };
                        }) || []
                    }
                />
            </Container>
            <Container>
                <Input
                    type="date"
                    value={props.filter.booking_date_start ? props.filter.booking_date_start : ''}
                    onChange={(e) => {
                        const endDate =
                            props.filter.booking_date_end && props.filter?.booking_date_end < e.target.value
                                ? ''
                                : props.filter.booking_date_end;
                        props.onChange({
                            ...props.filter,
                            booking_date_start: e.target.value,
                            booking_date_end: endDate,
                        });
                    }}
                    placeholder={L('booking_date_start')}
                />
            </Container>
            <Container>
                <Input
                    type="date"
                    value={props.filter.booking_date_end ? props.filter.booking_date_end : ''}
                    min={props.filter.booking_date_start}
                    onChange={(e) => {
                        props.onChange({ ...props.filter, booking_date_end: e.target.value });
                    }}
                    placeholder={L('booking_date_end')}
                />
            </Container>
            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => clearAll()}>
                <StyledCross />
                <Label>{L('clear_filter')}</Label>
            </div>
        </StyledFilters>
    );
};
