import styled from '@emotion/styled';
import React from 'react';
import { DocumentDetail } from '@app/api/public/DocumentsApi';
import { useGetSignedUrlById } from '../../../hooks/useGetSignedUrlById';

const StyledDocumentRow = styled.a({
    textDecoration: 'none',
    display: 'block',
    fontSize: '12px',
    cursor: 'pointer',
    color: 'inherit',
});

export function DocumentRow({ document }: { document: DocumentDetail }) {
    const { data: url } = useGetSignedUrlById(+document.document_data?.original?.id);
    return (
        <StyledDocumentRow href={url} target="_blank" rel="noreferrer">
            {document.name}
        </StyledDocumentRow>
    );
}
