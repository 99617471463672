import { useQuery } from 'react-query';
import ProjectsApi from '@app/api/public/ProjectsApi';

export const projectImagesKey = {
    all: ['imageInfo'],
    list: () => [...projectImagesKey.all, 'list'],
};

export const useGetProjectImages = () => {
    return useQuery(projectImagesKey.list(), ProjectsApi.getImages, { keepPreviousData: true });
};

export default useGetProjectImages;
