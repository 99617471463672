import React, { createContext, FC, useContext, useState } from 'react';

type ModalContextType = {
    isModalOpen: boolean;
    ToggleIsModalOpen: () => void;
    openModal: (children: React.ReactNode) => void;
    injectedChildren: React.ReactNode;
};

const ModalContext = createContext({} as ModalContextType);

const ModalProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [injectedChildren, setInjectedChildren] = useState<React.ReactNode>();

    const ToggleIsModalOpen = () => {
        setIsModalOpen((prev) => !prev);
    };

    const openModal = (value: React.ReactNode) => {
        setInjectedChildren(value);
        setIsModalOpen(true);
    };

    const modalValue = {
        isModalOpen,
        ToggleIsModalOpen,
        openModal,
        injectedChildren,
    };

    return <ModalContext.Provider value={modalValue}>{children}</ModalContext.Provider>;
};

const useModal = () => {
    return useContext(ModalContext);
};

export { useModal, ModalContext, ModalProvider as default };
