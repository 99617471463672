import { useQueryClient, useMutation } from 'react-query';
import ContractsApi from '@app/api/public/ContractsApi';
import { getContractKeys } from './useContract';

type Variables = { itemId: string; name: string; file: File };

const saveContractDocuments = async (itemId: number, name: string, file: File) => {
    return ContractsApi.saveDocument(itemId, name, file);
};

const useSaveContractDocuments = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (variables: Variables) => saveContractDocuments(+variables.itemId, variables.name, variables.file),
        {
            onSettled: (_data, _error, variables) => {
                queryClient.invalidateQueries(getContractKeys.all);
            },
        }
    );
};

export const saveEditedDocuments = async (document) => {
    return await ContractsApi.updateDocumentName(document);
};

export default useSaveContractDocuments;
