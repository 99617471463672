import React from 'react';

import styled from '../../../components/lib/styled';

import NotificationList from '../components/NotificationList';
import { NotificationType } from '@app/api/models/CommunityNotifications';

const StyledNotificationList = styled(NotificationList)({
    '& li': { marginBottom: '21px' },
});

const NotificationHandler = ({ items }: { items: NotificationType[] }) => {
    return <StyledNotificationList isCollapsed={false} notifications={items || []} />;
};

export default NotificationHandler;
