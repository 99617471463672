import React, { useState } from 'react';
import { Button } from '../../../components/Buttons/Button';
import { L } from '../../../lib/i18n';
import styled from '@emotion/styled';
import { useGetAlerts, useGetPropertyFilterList } from '../hooks/alertsQuery';
import CardTitle from '../../../components/typography/CardTitle';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { Link } from 'react-router-dom';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import AlertCardHandler from './AlertCardHandler';
import Filters from '../../../components/Filters/Filters';

const Container = styled.div({
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fill, minmax(350px, 1fr) )',
    gap: '2rem',
});

const TopAreaWrapper = styled.div({
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
});

export const AlertListPage = () => {
    const { data: propertyFilterList } = useGetPropertyFilterList();
    const [properyId, setProperyId] = useState();
    const propertyOptions = propertyFilterList?.map((property) => ({
        label: property.name,
        value: property.property_id,
    }));

    const { data } = useGetAlerts({ propertyId: properyId });

    const Cards = data?.map((alert) => {
        return <AlertCardHandler elips {...alert} key={alert.id} />;
    });

    return (
        <CardWithDivider
            topArea={
                <TopAreaWrapper>
                    <CardTitle>{L('all_alerts')}</CardTitle>
                    <Link to={'/alerts/new'}>
                        <Button>{L('alert_create_title')}</Button>
                    </Link>
                </TopAreaWrapper>
            }
            mainArea={
                <>
                    <Filters>
                        <Dropdown
                            value={properyId}
                            label={L('property')}
                            onChange={(value) => {
                                setProperyId(value);
                            }}
                            options={
                                propertyOptions
                                    ? [{ value: undefined, label: L('all_properties') }, ...propertyOptions]
                                    : [{ value: undefined, label: L('all_properties') }]
                            }
                        />
                    </Filters>

                    <Container>{Cards}</Container>
                </>
            }
        />
    );
};
