import React, { useEffect, useMemo, useState } from 'react';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { TopAreaWrapper } from './MailPage';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router';
import { MailMessage } from '@app/api/lib/mail';
import { useSendMail } from '../hooks/mailQueries';
import Contacts from '../components/Contacts';
import useSaveMail from '../hooks/useSaveMail';
import * as EmailValidator from 'email-validator';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import MailForm from '../components/MailForm';

const unique = (array: string[]) => {
    const set = new Set(array);
    return [...set];
};

const mailSchema = z.object({
    subject: z.string().min(1, { message: L('required') }),
    recipients: z.array(z.string()).refine((recipients) => {
        return recipients.some((recipient) => EmailValidator.validate(recipient));
    }),
});

type FormValues = {
    subject: string;
    recipientsInput: string;
    recipients: string[];
};

export default function AddMailPage() {
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        clearErrors,
        formState: { errors },
    } = useForm<FormValues>({
        resolver: zodResolver(mailSchema),
        defaultValues: {
            recipientsInput: '',
            subject: '',
            recipients: [],
        },
    });

    //Will be defined if it's a specific mail
    const sendMail = useSendMail();

    const [editorValue, setEditorValue] = useState<string>('');
    const [showContacts, setShowContacts] = useState(false);
    const history = useHistory();

    const subjectValue = watch('subject');
    const recipientsInput = watch('recipientsInput');
    const recipients = watch('recipients');
    const memoizedRecipients = useMemo(() => {
        return recipients.map((recipient) => {
            return {
                value: recipient,
                id: self.crypto.randomUUID() as string,
            };
        });
    }, [recipients]);

    const handleSetEditorValue = (value: string) => {
        setEditorValue(value);
    };
    const handleSetRecipients = (recipients: string[]) => {
        setValue('recipients', recipients);
    };
    const handleSetRecipientsInput = (input: string) => {
        setValue('recipientsInput', input);
    };
    const handleSetSubjectInput = (input: string) => {
        setValue('subject', input);
    };
    const handleSetShowContact = (): void => {
        setShowContacts(true);
    };

    useEffect(() => {
        register('recipientsInput');
        register('subject', { required: true });
        register('recipients', { required: true });
    }, [register]);

    useEffect(() => {
        clearErrors('subject');
    }, [subjectValue]);

    useEffect(() => {
        clearErrors(['recipients', 'recipientsInput']);
    }, [recipientsInput, recipients]);

    const { draftMail } = useSaveMail(subjectValue, editorValue, recipients, undefined);

    useEffect(() => {
        if (draftMail) {
            setValue('subject', draftMail?.subject);
            setEditorValue(draftMail?.message);
            handleSetRecipients(draftMail.sent_to ?? []);
        }
    }, [draftMail?.subject, draftMail?.message, draftMail?.sent_to]);

    const mailSubmit: SubmitHandler<FormValues> = async () => {
        const validRecipients = unique(recipients.filter((recipient) => EmailValidator.validate(recipient)));

        const mailToSend: MailMessage = {
            from: '',
            recipients: validRecipients,
            subject: subjectValue,
            html: editorValue,
            text: '',
            replyTo: '',
        };

        sendMail.mutate(
            { mail: mailToSend, attachments: draftMail?.attachments ?? [], draftId: draftMail?.id },
            {
                onSuccess: () => {
                    history.push('/mail');
                    toast.success(L('mail_sent'));
                },
                onError: () => {
                    toast.error(L('mail_error'));
                },
            }
        );
    };

    const handleSubmitForm = handleSubmit(mailSubmit);

    const saveSelectedContacts = (selected: { [key: string]: boolean }) => {
        const selectedMails: string[] = [];
        for (const [mail, isSelected] of Object.entries(selected)) {
            if (isSelected) {
                selectedMails.push(mail);
            }
        }
        handleSetRecipients([...recipients, ...selectedMails]);
        setShowContacts(false);
    };

    return (
        <>
            <Contacts close={() => setShowContacts(false)} show={showContacts} save={saveSelectedContacts} />
            <CardWithDivider
                topArea={
                    <TopAreaWrapper>
                        <CardTitle>{L('new_mail')}</CardTitle>
                    </TopAreaWrapper>
                }
                mainArea={
                    <MailForm
                        errors={errors}
                        handleMailSubmit={handleSubmitForm}
                        handleSetRecipientsInput={handleSetRecipientsInput}
                        handleSetShowContact={handleSetShowContact}
                        handleSetRecipients={handleSetRecipients}
                        handleSetEditorValue={handleSetEditorValue}
                        handleSetSubjectInput={handleSetSubjectInput}
                        recipients={recipients}
                        recipientsInput={recipientsInput}
                        subjectValue={subjectValue}
                        draftMail={draftMail}
                        editorValue={editorValue}
                        memoizedRecipients={memoizedRecipients}
                    />
                }
            />
        </>
    );
}
