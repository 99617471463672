import { formatDateIsoDayjs } from '@app/shared/lib/formatting';
import styled from '@emotion/styled';
import React from 'react';
import { L } from '../../lib/i18n';
import Label from '../Forms/Label';
import { fontSize, fontWeight } from '../Shared/Style/typography';

type Props = {
    status?: {
        created_at?: string | null;
        created_by_name?: string | null;
        notified_at?: { value?: string | null };
        created_by_email?: string | null;
    };
};

const StyledCard = styled.div({
    display: 'grid',
    alignContent: 'start',
});

const StyledLabel = styled(Label)({
    margin: '0px',
    '& .title': {
        margin: '16px 0px 2px 0px',
    },
});

const Info = styled.p(({ theme }) => ({
    fontWeight: fontWeight.thin,
    fontSize: fontSize.milli,
    color: theme.colors.textColor2,
    margin: 0,
    minHeight: '20px',
}));

const StatusArea = ({ status, ...rest }: Props & React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <StyledCard {...rest}>
            <StyledLabel title={L('created_at')} />
            <Info>{status?.created_at && formatDateIsoDayjs(status?.created_at || '')}</Info>
            <StyledLabel title={L('created_by')} />
            <Info>{status?.created_by_name || status?.created_by_email || ''}</Info>
            {status?.notified_at && (
                <>
                    <StyledLabel title={L('notice_sent')} />
                    <Info>{status?.notified_at.value && formatDateIsoDayjs(status?.notified_at.value || '')}</Info>
                </>
            )}
        </StyledCard>
    );
};

export default StatusArea;
