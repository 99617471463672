import React, { useState, useEffect } from 'react';
import * as css from './Loading.module.css';

// export const Loading = () => {
//     console.log('loading');
//     return <div className={css.spinner} />;
// };
export const Loading = () => {
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        const t = setTimeout(() => setVisible(true), 300);
        return () => clearTimeout(t);
    }, []);
    if (!visible) return null;
    return <div className={css.spinner} />;
};
