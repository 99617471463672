import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { L } from '../../../lib/i18n';
import useUpdatePageListItem from '../../../components/Website/hooks/useUpdatePageListItem';
import { Button } from '../../../components/Buttons/Button';
import useGetByPageType from '../../../components/Website/hooks/useGetByPageType';
import HomePreview from '../../../components/Website/components/Preview/HomePreview';
import DocumentsTableById from '../components/DocumentsTableById';
import useThemeProviderState from '../theme/hooks/useThemeProviderState';
import { ThemeProvider } from 'emotion-theming';
import Hero from '../../../components/Website/components/Hero';
import Label from '../../../components/Forms/Label';
import useGetProjectInfo from '../../../hooks/useGetProjectInfo';
import useSavePublicAdress from '../hooks/useSavePublicAdress';
import Input from '../../../components/Forms/Input';
import FormHeader from '../../../components/Forms/FormHeader';
import { Address } from '@app/api/models/Projects';
import Quill from '../../../components/Quill/Quill';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardWithDividerTopArea from '../../../components/Layout/CardWithDividerTopArea';

const Box = styled.article({
    backgroundColor: 'white',
    borderRadius: '4px',
    marginBottom: '2rem',
});
const TopWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const BottomWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '1rem',
    marginBottom: '2rem',
});
const FileInput = styled.input({
    display: 'none',
});
const ImgLabel = styled.label({
    cursor: 'pointer',
});
const ImageWrapper = styled.div({
    width: '100%',
    marginBottom: '2rem',
});
const Error = styled.h3({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: '#8c1007',
});

const Homepage = () => {
    const [description, setDescription] = useState('');
    const { data: projectInfo } = useGetProjectInfo();
    const [file, setFile] = useState<undefined | File>();
    const [imgUrl, setImgUrl] = useState('');
    const [publicAddress, setPublicAddress] = useState<Address>({
        addressLine1: '',
        city: '',
        postalCode: '',
        country: '',
    });

    const [errorMessage, setErrorMessage] = useState(false);
    const { data: homePageData } = useGetByPageType('home_page');
    const { mutate: updatePageListItem, status } = useUpdatePageListItem();
    const { theme } = useThemeProviderState();
    const { mutate: savePublicAdress, status: publicAddressStatus } = useSavePublicAdress();

    useEffect(() => {
        if (!homePageData) return;
        setDescription(homePageData?.description || '');
    }, [homePageData]);

    useEffect(() => {
        if (!projectInfo || !projectInfo.public_site_address) return;
        const pa = projectInfo.public_site_address;
        setPublicAddress((publicAddress) => ({
            ...publicAddress,
            ...pa,
        }));
    }, [projectInfo?.public_site_address]);

    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        setPublicAddress((publicAddress) => ({
            ...publicAddress,
            [name]: value,
        }));
    };

    const uploadedImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        setFile(event.target.files[0]);
        setImgUrl(URL.createObjectURL(event.target.files[0]));
    };

    const handlePublicAddressSave = () => {
        savePublicAdress(publicAddress);
    };

    const save = () => {
        if (description.length > 12) {
            updatePageListItem({
                publicSiteItem: {
                    ...homePageData,
                    description,
                    page_type: 'home_page',
                    published_at: new Date().toISOString(),
                },
                file: file,
            });
        } else {
            setErrorMessage(true);
        }
    };

    return (
        <CardWithDivider
            topArea={<CardWithDividerTopArea title={L('edit_homepage_content_title')} />}
            mainArea={
                <>
                    <Box>
                        <TopWrapper>
                            <Label title={L('title')}>
                                <Input readOnly={true} defaultValue={L('homepage')} />
                            </Label>
                        </TopWrapper>
                        <Label title={L('content')} />
                        <Quill
                            value={description}
                            onChange={(value) => {
                                setDescription(value);
                                setErrorMessage(false);
                            }}
                            placeholder="Skriv något här..."
                        />

                        {homePageData?.id && (
                            <>
                                <Label title={L('background_image')}>
                                    <ImageWrapper>
                                        <ImgLabel>
                                            <Hero imgUrl={imgUrl} pageListItem={homePageData} />
                                            <FileInput
                                                accept=".jpg,.png"
                                                type="file"
                                                onChange={uploadedImage}
                                                id="heroImage"
                                            />
                                        </ImgLabel>
                                    </ImageWrapper>
                                </Label>
                            </>
                        )}
                        <BottomWrapper>
                            <Button variant="selectedMenu" onClick={save}>
                                {status === 'loading' ? L('loading') + '...' : L('save')}
                            </Button>
                        </BottomWrapper>
                        {errorMessage ? <Error>{L('fill_in_description')}</Error> : <Error />}
                    </Box>
                    <Box>
                        <FormHeader>{L('address_googlemaps')}</FormHeader>
                        <div style={{ columnGap: '2rem', display: 'flex', flexWrap: 'wrap' }}>
                            <Label title={L('address')}>
                                <Input
                                    value={publicAddress?.addressLine1}
                                    onChange={handleAddressChange}
                                    name="addressLine1"
                                />
                            </Label>
                            <Label title={L('city')}>
                                <Input value={publicAddress?.city} onChange={handleAddressChange} name="city" />
                            </Label>
                            <Label title={L('postalCode')}>
                                <Input
                                    value={publicAddress.postalCode}
                                    onChange={handleAddressChange}
                                    name="postalCode"
                                />
                            </Label>
                            <Label title={L('country')}>
                                <Input value={publicAddress.country} onChange={handleAddressChange} name="country" />
                            </Label>
                        </div>

                        <BottomWrapper>
                            <Button variant="selectedMenu" onClick={handlePublicAddressSave}>
                                {publicAddressStatus === 'idle' && L('save')}
                                {publicAddressStatus === 'loading' && L('loading')}
                                {publicAddressStatus === 'success' && L('saved')}
                                {publicAddressStatus === 'error' && L('error')}
                            </Button>
                        </BottomWrapper>
                    </Box>
                    {homePageData?.id && (
                        <>
                            <Box>
                                <DocumentsTableById id={+homePageData.id} />
                            </Box>
                            <Box>
                                <FormHeader>{L('preview')}</FormHeader>
                                <ThemeProvider theme={theme}>
                                    <HomePreview id={+homePageData.id} />
                                </ThemeProvider>
                            </Box>
                        </>
                    )}
                </>
            }
        />
    );
};

export default Homepage;
