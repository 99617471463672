import UsersApi, { User } from '@app/api/public/UsersApi';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { L } from '../../../lib/i18n';
import { getMemberListQueryKey } from './useGetMemberList';

const updateMember = async (user: Pick<User, 'id' | 'name' | 'email'>) => {
    const res = await UsersApi.save(user);

    if (!res) return Promise.reject(L('reset_error'));

    if (res?.['message']) {
        const message = res['message'] as string;
        return Promise.reject(L(message));
    }

    return res as User;
};

const useUpdateMember = () => {
    const queryClient = useQueryClient();
    return useMutation((user: Pick<User, 'id' | 'name' | 'email'>) => updateMember(user), {
        onError: (err) => {
            /* Shows error message */
            const error = typeof err === 'string' ? err : new Error(L('reset_error')).message;
            const message = error;
            toast.error(`${L(message)}`);
        },
        onSettled: () => {
            queryClient.invalidateQueries([getMemberListQueryKey]);
        },
    });
};

export default useUpdateMember;
