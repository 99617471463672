import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Page } from './components/Layout/Page';
import ErrorNoticehandler from './components/notice/ErrorNoticehandler';
import * as login from './areas/login/navigation';
import PrivateRoutes from './PrivateRoutes';
import { LoginPage } from './areas/login/LoginPage';

export const Routes = () => {
    return (
        <Switch>
            {login.routes}
            <LoginPage>
                <ErrorNoticehandler>
                    <Page>
                        <PrivateRoutes />
                    </Page>
                </ErrorNoticehandler>
            </LoginPage>
            <Route>
                <div>404 not found</div>
            </Route>
        </Switch>
    );
};
