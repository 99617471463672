import { ContentEventItem } from '@app/api/models/ContentEvent/ContentEventModels';
import ContentEventApi from '@app/api/public/ContentEventApi';
import { useMutation, useQueryClient } from 'react-query';
import { memberEventQueryKeys } from './memberEventQueries';

type Variables = { id: ContentEventItem['id'] };

const useRemoveEvent = () => {
    const client = useQueryClient();
    return useMutation(
        (varables: Variables) => {
            return ContentEventApi.remove(varables.id);
        },
        {
            onSettled: () => {
                client.invalidateQueries(memberEventQueryKeys.all);
            },
        }
    );
};

export default useRemoveEvent;
