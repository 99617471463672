// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3R4_cD4Csidif22rHznMee {\n  vertical-align: middle;\n  font-size: 0;\n}\n._3R4_cD4Csidif22rHznMee > svg {\n  width: 21px;\n  height: auto;\n  vertical-align: middle;\n}\n._3R4_cD4Csidif22rHznMee._3VOBv8ynx0ZSQIm3XyOO6l > svg {\n    width: 24px;\n}\n._3R4_cD4Csidif22rHznMee._2OYcy5yErdIUqLyx8Z2jvW > svg {\n    width: 28px;\n}\n._3R4_cD4Csidif22rHznMee._23fjLrGx_b3w3OZaxU0vKS > svg {\n  width: 12px;\n}\n._3R4_cD4Csidif22rHznMee._2dmOtR55k3kO-_j20VldUz > svg {\n  width: 16px;\n}\n._3R4_cD4Csidif22rHznMee._2_hg_KA8A6OH7vVcjW1X4W > svg {\n  width: 28px;\n  max-height: 28px;\n}\n._3R4_cD4Csidif22rHznMee._2beknMQ673hq3dC8GWOqJ7 > svg {\n  width: 48px;\n}\n._3R4_cD4Csidif22rHznMee._3weMR9i3hejPfuuY5smXlv > svg {\n    width: 100px;\n}\n.DuJW9NGHUGK7oTGNM2Rar {\n  cursor: pointer;\n  border: 0;\n  background: inherit;\n}\n/*a:hover .svg > svg * { fill: rgba(49, 53, 59, 0.7); }*/\n\n._3R4_cD4Csidif22rHznMee [fill='#111111'] {\n  fill: #86AE43;\n  /* fill: #86AE43; */\n}\n._3R4_cD4Csidif22rHznMee [stroke='#111111'] {\n  stroke: #86AE43;\n  /* fill: #86AE43; */\n\n}\n/* .svg [data-color='color-2'] {\n  fill: #263143;\n} */\n\n/* .svg.light [stroke='#111111'] {\n  stroke: white;\n}\n.svg.light [fill='#111111'] {\n  fill: white;\n} */\n/* .svg.light [data-color='color-2'] {\n  fill: #eeeeee;\n} */\n\n/*\n:global(.sidebar) .svg :global(.nc-icon-wrapper) path {\n  fill: white !important;\n}\n*/\n/* \n.state-success [fill='#111111'] {\n  fill: green;\n}\n\n.state-failed [fill='#111111'] {\n  fill: red;\n} */\n\n._1rE1Cj3F-r4qFkS3qBY21_ svg {\n  animation: _2TvQbSA7vGscGUIYMWhlW3 3000ms linear infinite;\n}\n\n@keyframes _2TvQbSA7vGscGUIYMWhlW3 {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"svg": "_3R4_cD4Csidif22rHznMee",
	"size-mSmall": "_3VOBv8ynx0ZSQIm3XyOO6l",
	"size-medium": "_2OYcy5yErdIUqLyx8Z2jvW",
	"size-small": "_23fjLrGx_b3w3OZaxU0vKS",
	"size-sMedium": "_2dmOtR55k3kO-_j20VldUz",
	"size-MmediumMaxHeight": "_2_hg_KA8A6OH7vVcjW1X4W",
	"size-large": "_2beknMQ673hq3dC8GWOqJ7",
	"size-100": "_3weMR9i3hejPfuuY5smXlv",
	"clickable": "DuJW9NGHUGK7oTGNM2Rar",
	"state-spin": "_1rE1Cj3F-r4qFkS3qBY21_",
	"spin": "_2TvQbSA7vGscGUIYMWhlW3"
};
module.exports = exports;
