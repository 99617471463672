import UsersApi from '@app/api/public/UsersApi';
import { useMutation } from 'react-query';
import { createValidator, required } from '../../../lib/validation';

const forcedRules = createValidator({
    oldPassword: [required()],
    newPassword: [required()],
    newRepeatPassword: [required()],
});

type Variables = { oldPassword: string; newPassword: string; newRepeatPassword: string };

const changePassword = async (passwords: Variables) => {
    const validation = forcedRules.test(passwords);

    const errors = validation.errors;
    if (!validation.ok) return Promise.reject(errors);

    const response = await UsersApi.changePassword(
        passwords.oldPassword,
        passwords.newRepeatPassword,
        passwords.newPassword
    );
    if (response.status === 'error') {
        return Promise.reject(response.message);
    }
    if (response.status === 'success') {
        return response.data;
    }
};

const useChangePassword = () => {
    return useMutation((profile: Variables) => changePassword(profile));
};

export default useChangePassword;
