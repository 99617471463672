import React from 'react';
import styled from '@emotion/styled';
import useGetByPageType from '../../hooks/useGetByPageType';
import { PreviewThemeType } from '../../../../areas/public-site/theme/ThemePage';
import { useTheme } from 'emotion-theming';

const TextWrapper = styled.div(({ theme }: { theme: PreviewThemeType }) => {
    return {
        margin: 'auto',
        paddingTop: '2rem',
        paddingBottom: '2rem',
        width: '70%',
        color: theme.colors.textColor1,
        '*': {
            backgroundColor: `${theme.colors.pageBackground} !important`,
        },
    };
});
const PreviewText = ({ ...rest }) => {
    const { data: contactPageData } = useGetByPageType('contact_page');
    const theme = useTheme<PreviewThemeType>();

    return (
        <TextWrapper theme={theme} {...rest}>
            <p dangerouslySetInnerHTML={{ __html: contactPageData?.description || '' }} />
        </TextWrapper>
    );
};

export default PreviewText;
