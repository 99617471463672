import React, { useEffect, useState } from 'react';
import { L } from '../../../../lib/i18n';
import styled from '@emotion/styled';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import ContentInformationApi from '@app/api/public/ContentInformationApi';
import { Button } from '../../../../components/Buttons/Button';
import { useHistory, useParams } from 'react-router-dom';
import useRemoveInformation from '../hooks/useRemoveInformation';
import { memberInformationQueryKeys, useGetMemberInformationById } from '../hooks/memberInformationQueries';
import toast from 'react-hot-toast';
import ConfirmationButton from '../../../../components/Buttons/ConfirmationButton';
import StatusArea from '../../../../components/StatusArea/StatusArea';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { style } from '../../../../components/Shared/Style';
import { useMutation, useQueryClient } from 'react-query';
import useGetSignedUrlById from '../../../../hooks/useGetSignedUrlById';
import Label from '../../../../components/Forms/Label';
import ImageDropZone from '../../../../components/ImageDropZone/ImageDropZone';
import Input from '../../../../components/Forms/Input';
import triggerDirtyFieldsToats from '../../../../components/Forms/DirtyFieldsToast';
import Quill from '../../../../components/Quill/Quill';
import GroupDocumentsArea from '../../../../components/document/GroupDocumentsArea';
import DropdownButton from '../../../../components/Buttons/DropdownButton';
import { useLocalStorage } from 'react-use';
import { notificationToast } from '../../notificationToast/notificationToast';
import { DocumentDetail } from '@app/api/models/Documents';

const StyledCardWithDivider = styled(CardWithDivider)({
    width: 'fit-content',
});

const GridContainer = styled.div(({ theme }) => ({
    display: 'grid',
    gap: '1rem',
    columnGap: '2rem',
    backgroundColor: theme.colors.white,
    alignItems: 'start',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateAreas: `
    "img          img               img"
    "from         to                ..."
    "title        title             title"
    "description  description description"
    "buttons      buttons         buttons"
    `,
    paddingBottom: '0px',
    maxWidth: '600px',
}));

const EditInformationContainer = styled.div({
    display: 'grid',
    gap: '4rem',
    gridTemplateColumns: 'auto auto',
    gridTemplateAreas: `
    "editArea statusArea"
    `,
});

const FlexWrapper = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    alignItems: 'start',

    '& > *': {
        flexBasis: '600px',
        flexGrow: 1,
    },
});

const StyledImageDropZone = styled(ImageDropZone)({
    aspectRatio: '4/3',
});

const informationFormDraftSchema = z.object({
    title: z.string().min(1, { message: L('required') }),
    description: z.string().min(1, { message: L('required') }),
});

type EditDraftInformation = {
    information: z.infer<typeof informationFormDraftSchema> & {
        id: number;
        cover_image_data?: DocumentDetail['document_data'] | null;
    };
    image?: File;
};

const saveInformationDraft = (variables: EditDraftInformation) => {
    return ContentInformationApi.saveDraft(variables);
};

type PublishInformation = {
    information: z.infer<typeof informationFormDraftSchema> & {
        id: number;
        cover_image_data?: DocumentDetail['document_data'] | null;
    };
    image?: File;
    notify: boolean;
};

const publishInformation = (variables: PublishInformation) => {
    return ContentInformationApi.saveAndPublish(variables);
};
const InformationEditPublished = () => {
    const { id } = useParams<{ id: string }>();
    const [localSubmitButtonState, setSubmitButtonState] = useLocalStorage<'save_draft' | 'save_and_publish'>(
        'editInformationDraft',
        'save_and_publish'
    );
    const submitButtonState = localSubmitButtonState || 'save_and_publish';
    const { mutate: remove } = useRemoveInformation();

    const { data: memberInfo } = useGetMemberInformationById(+id);

    const [data, setData] = useState(memberInfo);

    useEffect(() => {
        setData(memberInfo);
    }, [memberInfo]);

    const history = useHistory();

    const queryClient = useQueryClient();
    const { data: signedUrl } = useGetSignedUrlById(data?.cover_image_data?.original?.id, undefined, false);

    const [imgFile, setImgFile] = useState<File>();
    const [imgUrl, setImgUrl] = useState<string>('');

    const { mutate: saveDraft } = useMutation((variables: EditDraftInformation) => saveInformationDraft(variables), {
        onSuccess: () => {
            setImgFile(undefined);
            setImgUrl('');
        },
        onSettled: () => {
            queryClient.invalidateQueries(memberInformationQueryKeys.detail(+id));
            queryClient.invalidateQueries(memberInformationQueryKeys.list('draft'));
        },
    });
    const { mutate: saveAndPublishInformation } = useMutation(
        (variables: PublishInformation) => publishInformation(variables),
        {
            onMutate: () => {
                toast.loading(L('loading'), {
                    id: 'send-notification',
                });
            },
            onSuccess: () => {
                setImgFile(undefined);
                setImgUrl('');
            },
            onSettled: () => {
                queryClient.invalidateQueries(memberInformationQueryKeys.detail(+id));
                queryClient.invalidateQueries(memberInformationQueryKeys.list('published'));
            },
        }
    );

    const onUpload = (files: File[]) => {
        setImgFile(files[0]);
        setImgUrl(URL.createObjectURL(files[0]));
    };

    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isDirty },
    } = useForm({
        resolver: zodResolver(informationFormDraftSchema),
        values: {
            title: data?.title || '',
            description: data?.description || '',
        },
    });

    const submit = (formData: EditDraftInformation['information'], type: 'publish' | 'draft', notify = false) => {
        if (type === 'publish') {
            const publish = (notify: boolean) => {
                return saveAndPublishInformation(
                    {
                        information: {
                            ...formData,
                            cover_image_data: data?.cover_image_data,
                        },
                        image: imgFile,
                        notify,
                    },
                    {
                        onSuccess: () => {
                            toast.success(L('information_saved_and_published'));
                        },
                        onSettled: () => {
                            toast.dismiss('send-notification');
                        },
                    }
                );
            };

            return notificationToast({
                notify: (notify) => publish(notify),
                type: 'publish',
            });
        }

        saveDraft(
            {
                information: {
                    ...formData,
                    cover_image_data: data?.cover_image_data,
                },
                image: imgFile,
            },
            {
                onSuccess: () => {
                    toast.success(L('information_updated'));
                },
            }
        );
    };

    const onRemove = () => {
        if (!data) return;
        remove(
            { id: data.id },
            {
                onSuccess: () => {
                    toast.success(`${data.title} ${L('removed')}`);
                    history.push('/information');
                },
            }
        );
    };

    return (
        <FlexWrapper>
            <StyledCardWithDivider
                topArea={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <CardTitle>{L('edit_information_in_draft')}</CardTitle>
                        <ConfirmationButton props={{ onConfirm: onRemove }} />
                    </div>
                }
                mainArea={
                    <EditInformationContainer>
                        <GridContainer>
                            <div style={{ gridArea: 'img' }}>
                                <Label title={L('image')} />
                                <StyledImageDropZone
                                    dropzoneOptions={{}}
                                    onDrop={onUpload}
                                    url={imgUrl || signedUrl || ''}
                                    remove={() => {
                                        if (imgUrl || imgFile) {
                                            setImgUrl('');
                                            setImgFile(undefined);
                                            return;
                                        }
                                        if (!data?.cover_image_data) return;
                                        const mutatedData = { ...data, cover_image_data: null };
                                        setData(mutatedData);
                                    }}
                                />
                            </div>

                            <Label
                                style={{ gridArea: 'title' }}
                                htmlFor="title"
                                title={`${L('title')}*`}
                                error={errors?.title?.message}
                            >
                                <Input {...register('title', { required: true })} />
                            </Label>

                            <div style={{ gridArea: 'description' }}>
                                <Label title={`${L('description')}*`} error={errors?.description?.message} />
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) => {
                                        return <Quill {...field} placeholder="Skriv något här..." />;
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    placeSelf: 'end',
                                    whiteSpace: 'nowrap',
                                    display: 'flex',
                                    gap: style.margin.m,
                                    gridArea: 'buttons',
                                }}
                            >
                                <Button
                                    type="button"
                                    variant="primary"
                                    onClick={() => {
                                        if (isDirty) {
                                            return triggerDirtyFieldsToats({
                                                continueButtonText: L('continue_without_saving'),
                                                continueFn: () => {
                                                    history.push('/information');
                                                },
                                            });
                                        }
                                        history.push('/information');
                                    }}
                                    role="link"
                                >
                                    {L('cancel')}
                                </Button>
                                <DropdownButton
                                    variant="filledPrimary"
                                    setOptionId={(state) => {
                                        setSubmitButtonState(state);
                                    }}
                                    optionId={submitButtonState}
                                    options={[
                                        {
                                            dropdownItem: (
                                                <DropdownButton.DropdownItem>
                                                    {L('save_draft')}
                                                </DropdownButton.DropdownItem>
                                            ),
                                            id: 'save_draft',
                                            button: (
                                                <DropdownButton.Button
                                                    onClick={handleSubmit((formValues) =>
                                                        submit({ ...formValues, id: +id }, 'draft')
                                                    )}
                                                >
                                                    {L('save_draft')}
                                                </DropdownButton.Button>
                                            ),
                                        },
                                        {
                                            dropdownItem: (
                                                <DropdownButton.DropdownItem>
                                                    {L('save_and_publish')}
                                                </DropdownButton.DropdownItem>
                                            ),
                                            id: 'save_and_publish',
                                            button: (
                                                <DropdownButton.Button
                                                    onClick={handleSubmit((formValues) =>
                                                        submit({ ...formValues, id: +id }, 'publish')
                                                    )}
                                                >
                                                    {L('save_and_publish')}
                                                </DropdownButton.Button>
                                            ),
                                        },
                                    ]}
                                />
                            </div>
                        </GridContainer>
                        <StatusArea
                            status={{
                                created_by_name: data?.created_by_name,
                                created_by_email: data?.created_by_email,
                                notified_at: { value: data?.notified_at },
                                created_at: data?.created_at,
                            }}
                            style={{ alignSelf: 'start', gridArea: 'statusArea' }}
                        />
                    </EditInformationContainer>
                }
            />

            <CardWithDivider
                style={{ width: '100%' }}
                topArea={<CardTitle>{L('documents')}</CardTitle>}
                mainArea={
                    <GroupDocumentsArea
                        documentQueryKey={memberInformationQueryKeys.document(+id)}
                        getDocuments={async () => {
                            return (await ContentInformationApi.listDocuments(+id)).map((doc) => ({
                                id: doc.id,
                                originalId: doc.document_data.original.id,
                                filename: doc.document_data.original.metadata.filename,
                                name: doc.name,
                                content_document_group_id: doc.content_document_group_id,
                                sort: doc.sort,
                            }));
                        }}
                        removeDocument={(docId) => ContentInformationApi.deleteDocumentFromContentInformation(docId)}
                        renameDocument={(docId, name) => ContentInformationApi.updateDocumentName(docId, name)}
                        saveDocument={({ documentGroupId, file, name }) =>
                            ContentInformationApi.saveDocument(+id, name, file, documentGroupId)
                        }
                        regroupDocument={(docId, groupId) => ContentInformationApi.updateDocumentGroup(docId, groupId)}
                    />
                }
            />
        </FlexWrapper>
    );
};

export default InformationEditPublished;
