Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["getList"] = createRpcProxy("TicketsApi", "getList", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["archiveTicket"] = createRpcProxy("TicketsApi", "archiveTicket", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getArchive"] = createRpcProxy("TicketsApi", "getArchive", {
  "urlPrefix": "/rpc"
});
o["getbyid"] = createRpcProxy("TicketsApi", "getbyid", {
  "urlPrefix": "/rpc"
});
o["getEvents"] = createRpcProxy("TicketsApi", "getEvents", {
  "urlPrefix": "/rpc"
});
o["markAsRead"] = createRpcProxy("TicketsApi", "markAsRead", {
  "urlPrefix": "/rpc"
});
o["saveEvent"] = createRpcProxy("TicketsApi", "saveEvent", {
  "urlPrefix": "/rpc"
});
o["addAttachmentsToTicket"] = createRpcProxy("TicketsApi", "addAttachmentsToTicket", {
  "urlPrefix": "/rpc"
});
o["setLastReadThread"] = createRpcProxy("TicketsApi", "setLastReadThread", {
  "urlPrefix": "/rpc"
});
o["createNewTicket"] = createRpcProxy("TicketsApi", "createNewTicket", {
  "urlPrefix": "/rpc"
});
o["saveTicket"] = createRpcProxy("TicketsApi", "saveTicket", {
  "urlPrefix": "/rpc"
});
o["getAssignees"] = createRpcProxy("TicketsApi", "getAssignees", {
  "urlPrefix": "/rpc"
});
o["getProperties"] = createRpcProxy("TicketsApi", "getProperties", {
  "urlPrefix": "/rpc"
});
o["getSpacesForProperty"] = createRpcProxy("TicketsApi", "getSpacesForProperty", {
  "urlPrefix": "/rpc"
});
o["getInventories"] = createRpcProxy("TicketsApi", "getInventories", {
  "urlPrefix": "/rpc"
});
o["getMessages"] = createRpcProxy("TicketsApi", "getMessages", {
  "urlPrefix": "/rpc"
});
o["addAttachemntsToMessage"] = createRpcProxy("TicketsApi", "addAttachemntsToMessage", {
  "urlPrefix": "/rpc"
});
o["saveMessage"] = createRpcProxy("TicketsApi", "saveMessage", {
  "urlPrefix": "/rpc"
});
o["getNotes"] = createRpcProxy("TicketsApi", "getNotes", {
  "urlPrefix": "/rpc"
});
o["removeNote"] = createRpcProxy("TicketsApi", "removeNote", {
  "urlPrefix": "/rpc"
});
o["addAttachemntsToNote"] = createRpcProxy("TicketsApi", "addAttachemntsToNote", {
  "urlPrefix": "/rpc"
});
o["saveNote"] = createRpcProxy("TicketsApi", "saveNote", {
  "urlPrefix": "/rpc"
});
o["previewMail"] = createRpcProxy("TicketsApi", "previewMail", {
  "urlPrefix": "/rpc"
});
o["getSettingsByType"] = createRpcProxy("TicketsApi", "getSettingsByType", {
  "urlPrefix": "/rpc"
});
o["saveSettingByType"] = createRpcProxy("TicketsApi", "saveSettingByType", {
  "urlPrefix": "/rpc"
});
o["removeSettingByType"] = createRpcProxy("TicketsApi", "removeSettingByType", {
  "urlPrefix": "/rpc"
});
o["getProjectServiceData"] = createRpcProxy("TicketsApi", "getProjectServiceData", {
  "urlPrefix": "/rpc"
});
o["saveServiceInformation"] = createRpcProxy("TicketsApi", "saveServiceInformation", {
  "urlPrefix": "/rpc"
});
o["saveServiceResponse"] = createRpcProxy("TicketsApi", "saveServiceResponse", {
  "urlPrefix": "/rpc"
});
o["addServiceEmail"] = createRpcProxy("TicketsApi", "addServiceEmail", {
  "urlPrefix": "/rpc"
});
o["removeServiceEmail"] = createRpcProxy("TicketsApi", "removeServiceEmail", {
  "urlPrefix": "/rpc"
});