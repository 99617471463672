import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import StyledCalendar from '../../../../components/Calendar/Calendar';
import { fontSize } from '../../../../components/Shared/Style/typography';
import { colors } from '../../../../components/Shared/Style/colors';
import useGetBookingResourceById from '../../hooks/useGetBookingResourceById';
import useGetSignedUrlById from '../../../../hooks/useGetSignedUrlById';
import useGetBookingsByResourceId from '../../hooks/useGetBookingsByResourceId';
import useSaveBooking from '../../hooks/useSaveBooking';
import useRemoveBooking from '../../hooks/useRemoveBooking';
import { Loading } from '../../../../components/Loading/Loading';
import Bookbutton from '../../Daybookings/Bookbutton';
import Dialog from '../../../../components/Dialog/Dialog';
import ReactSelect from '../../../../components/Dropdown/ReactSelect';
import useGetUsersFromProject from '../../../../lib/hooks/useGetUsersFromProject';
import { L } from '../../../../lib/i18n';
import H2 from '../../../../components/typography/H2';

const Calendar = styled(StyledCalendar)({
    '.react-calendar__month-view__days': {
        display: 'grid !important',
        gridTemplateColumns: 'repeat(7, 1fr)',
        gap: '3px',
    },

    '.react-calendar__month-view__days__day': {
        maxWidth: '100% !important',
        aspectRatio: '1',
        borderRadius: '50%',
        padding: '0px',
        margin: '0px',
    },

    '.react-calendar__month-view__days__day.blocked': {
        maxWidth: '100% !important',
        border: 'none',
        // borderRadius: '0',
        padding: '0px',
        margin: '0px',
    },

    ['@supports not (aspect-ratio: 1)']: {
        '.react-calendar__month-view__days__day': {
            maxWidth: '100% !important',
            minHeight: '43px',
            borderRadius: '50%',
            padding: '0px',
            margin: '0px',
        },
    },
});

const AboveContent = styled.div({
    margin: '0px 16px 8px 16px',
    alignSelf: 'start',
    display: 'flex',
    columnGap: '15px',
    rowGap: '10px',
    flexWrap: 'wrap',
});

const Legend = styled.div({
    borderRadius: '50%',
    alignSelf: 'center',
    display: 'flex',
    gap: '5px',
});

const LegendText = styled.span({
    alignSelf: 'center',
    justifySelf: 'left',
    fontSize: fontSize.micro,
});

const LegendCircle = styled.div({
    width: '15px',
    height: '15px',
    borderRadius: '50%',
});
const StripedLegend = styled(LegendCircle)({
    background: `repeating-linear-gradient(
        45deg,
        ${colors.black10},
        ${colors.black10} 2px,
        ${colors.black30} 2px,
        ${colors.black30} 4px
      )`,
});

const Select = styled(ReactSelect)({
    '#react-select-2-input': {
        caretColor: 'black',
        position: 'absolute',
        left: 10,
        top: 6,
    },
    marginBottom: '1rem',
});

const DropdownLabel = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: fontSize.milli,
});

type Props = {
    bookingId: string;
};

const BookingsCalendarForm = ({ bookingId }: Props) => {
    const { data: bookingResource, status } = useGetBookingResourceById(+bookingId);
    const { data: documents, status: documentStatus } = useGetSignedUrlById(bookingId);
    const history = useHistory();

    const { data: bookings, status: bookingStatus } = useGetBookingsByResourceId(+bookingId, false);
    const { mutate: saveBooking } = useSaveBooking();
    const { mutate: removeBooking } = useRemoveBooking();

    const [date, setDate] = useState<Date | undefined>(undefined);

    const [selectedUser, setSelectedUser] = useState<
        | {
              label: string;
              value: string;
          }
        | undefined
    >();

    const { data: users } = useGetUsersFromProject();

    const user = users?.find((user) => user.id === selectedUser?.value);

    const options =
        users?.map((user) => ({
            value: [user.id, user.name, user.email, user.property_name],
            label: (
                <DropdownLabel>
                    <span>{user.name || user.email} </span>
                    <span> {user.property_name} </span>
                </DropdownLabel>
            ),
        })) || [];

    if (status === 'loading' || documentStatus === 'loading' || bookingStatus === 'loading') return <Loading />;
    if (!bookingResource || !documents) return <div>Kunde inte hitta kalendern</div>;
    if (!bookings) return <div>Kunde inte hämta bokningar</div>;

    const notify = (text) =>
        toast.success(
            `Du har ${text} ${bookingResource.name} den ${dayjs(date).format('YYYY/MM/DD')} till ${
                user?.name || user?.email
            } `
        );

    const save = async () => {
        if (!date || !user) return;
        saveBooking(
            {
                booking_date: dayjs(date).format('YYYY-MM-DD'),
                booking_resource_item_id: bookingResource.booking_resource_items[0].id,
                booking_slot_id: bookingResource.booking_slots[0].id,
                resourceId: bookingId,
                userId: user.id,
            },
            {
                onSuccess: () => {
                    notify('bokat');
                    setDate(undefined);
                },
            }
        );
    };

    const remove = async () => {
        if (!date || !user) return;
        removeBooking(
            {
                booking_date: dayjs(date).format('YYYY-MM-DD'),
                booking_resource_item_id: bookingResource.booking_resource_items[0].id,
                booking_slot_id: bookingResource.booking_slots[0].id,
                resourceId: bookingId,
                userId: user.id,
            },
            {
                onSuccess: () => {
                    notify('avbokat');
                },
            }
        );
    };

    const clearDates = () => {
        setDate(undefined);
        const active = document.querySelector('.react-calendar__tile--active');
        if (active) {
            active.classList.remove('react-calendar__tile--active');
        }
    };

    return (
        <>
            <Dialog
                open={true}
                content={{
                    style: {
                        width: '400px',
                        padding: '2rem',
                        backgroundColor: colors.white,
                    },
                }}
                onOpenChange={(open) => {
                    if (!open) history.push(`/booking/${bookingId}`);
                }}
            >
                <H2>
                    {L('new_booking')} {bookingResource.name}
                </H2>
                <Select
                    isClearable
                    value={options.find((option) => selectedUser && option.value === selectedUser[0])}
                    onChange={(option: { value: string; label: string } | null) => {
                        setSelectedUser({ value: option?.value[0] || '', label: option?.label || '' });
                    }}
                    options={options}
                    placeholder={L('book_for_user')}
                />
                <AboveContent>
                    <Legend>
                        <LegendCircle style={{ backgroundColor: colors.tabActive }} />
                        <LegendText>Vald boendes bokning </LegendText>
                    </Legend>
                    <Legend>
                        <LegendCircle style={{ border: 'solid 2px #33525E' }} />
                        <LegendText>Vald dag </LegendText>
                    </Legend>
                    <Legend>
                        <LegendCircle style={{ backgroundColor: colors.black30 }} />
                        <LegendText>Bokad </LegendText>
                    </Legend>
                    <Legend>
                        <StripedLegend />
                        <LegendText>Blockerad</LegendText>
                    </Legend>
                </AboveContent>
                <Calendar
                    minDate={new Date()}
                    onActiveStartDateChange={clearDates}
                    tileClassName={({ date }) => {
                        const d = date.getDay();

                        const booked = bookings?.find(
                            (item) => new Date(item.booking_date).toDateString() === new Date(date).toDateString()
                        );
                        if (user && booked?.user_id === +user.id) return 'myBookings';
                        else if (booked && d === 6) return 'booked-saturday';
                        else if (booked) return 'booked';
                        else if (d === 6 && date > Date.now()) return 'saturday';
                        else return '';
                    }}
                    onChange={setDate}
                    value={date}
                />

                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        padding: '1rem',
                    }}
                >
                    <Bookbutton date={date} save={save} remove={remove} selectedUser={user} bookingId={+bookingId} />
                </div>
            </Dialog>
        </>
    );
};

export default BookingsCalendarForm;
