import React, { useEffect, useRef, useState } from 'react';
import useGetFolderListByParentId from '../hooks/folders/useGetFolderListByParentId';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import useGetProjectInfo from '../../../hooks/useGetProjectInfo';
import DocumentList from './documentList/DocumentList';
import { L } from '../../../lib/i18n';
import { FileWithPath, useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import useGetFilesListByFolderId from '../hooks/files/useGetFilesByParentId';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom-v5-compat';
import FolderBreadcrumbs from './FolderBreadcrumbs';
import DocumentControls from './controls/DocumentControls';
import { Button, DropdownButton, Dropzone, Input } from '@ourliving/ourliving-ui';
import useUploadFilesAndFolders from '../hooks/both/useUploadFilesAndFolders';
import { DocumentInfo, UploadDuplicateResponse, FolderInfo } from '@app/api/public/DocumentsApi';
import DuplicateDialog from './dropzone/DuplicateDialog';
import styled from '@emotion/styled';
import { DocumentsSearchQuery } from '@app/api/models/Documents';
import useAddSearchParams from '../hooks/useAddSearchParams';

const CardHeader = styled.div({
    display: 'grid',
    gridTemplateColumns: 'auto minmax(11rem, 1fr)',
    gap: '1rem',
    paddingBottom: '1rem',
});

const BreadCrumbsWrapper = styled.div({
    gridColumn: '1 / 3',
    gridRow: '1 / 2',
});

const SearchWrapper = styled.div({
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
    gridColumn: '1 / 2',
    gridRow: '3 / 4',
    height: 'fit-content',
});

const Form = styled.form({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
});

const ControlsWrapper = styled.div({
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: '1rem',
    gridColumn: '1 / 2',
    gridRow: '4 / 5',
});

const DropzoneWrapper = styled.div({
    gridRow: '2 / 5',
    gridColumn: '2 / 3',
    '& > div': {
        maxWidth: '30rem',
        marginLeft: 'auto',
    },
    div: {
        height: 'fit-content',
    },
    b: {
        display: 'none',
    },
});

const DropdownWrapper = styled.div({
    height: '100%',
    '& > div': {
        borderTopLeftRadius: '0',
        borderBottomLeftRadius: '0',
        height: '100%',
        '& > div': {
            height: '100%',
            '& > div': {
                alignSelf: 'center',
            },
        },
    },
});

const DocumentContent = () => {
    const navigate = useNavigate();
    const { folderPath: firstPath, '*': splat } = useParams();

    // search
    const [searchParams] = useSearchParams();
    const { addSearchParams } = useAddSearchParams();
    const [query, setQuery] = useState<string>(searchParams.get('q') || '');
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [selectedDropdown, setSelectedDropdown] = React.useState<DocumentsSearchQuery['where']>(
        searchParams.get('where') == 'all' || searchParams.get('where') == 'current'
            ? (searchParams.get('where') as DocumentsSearchQuery['where'])
            : 'all'
    );

    const folderPath = firstPath ? `/${firstPath}${splat ? `/${splat}` : ''}` : '';
    const currentFolderId = Number(folderPath?.slice(folderPath?.lastIndexOf('/') + 1) ?? null) || null;

    const { data: projectInfo } = useGetProjectInfo();

    // folders
    const {
        data: folders,
        refetch: refetchFolders,
        isLoading: isLoadingFolders,
        isError: isErrorFolders,
        error: errorGettingFolder,
    } = useGetFolderListByParentId(currentFolderId ?? undefined);

    if (errorGettingFolder) {
        navigate('/document', { replace: true });
    }

    // files
    const {
        data: files,
        refetch: refetchFiles,
        isLoading: isLoadingFiles,
    } = useGetFilesListByFolderId(currentFolderId, { query, where: selectedDropdown });
    const [showItem, setShowItem] = useState(false);
    const [activeFiles, setActiveFiles] = useState<DocumentInfo[] | null>(null);
    const [activeFolders, setActiveFolders] = useState<FolderInfo[] | null>(null);
    const { mutate: uploadFilesAndFolders } = useUploadFilesAndFolders();

    const showDuplicateDialog = (files: DocumentInfo[], folders: FolderInfo[]) => {
        setShowItem(true);
        setActiveFiles(files);
        setActiveFolders(folders);
    };

    const craftDocumentInfo = (file: FileWithPath, currentFolderId: number | null) => {
        const mimeTypeStartIndex = file.name.lastIndexOf('.');
        const nameWithoutFileExtension = file.name.slice(0, mimeTypeStartIndex);
        const fileExtension = file.name.slice(mimeTypeStartIndex + 1);

        const payload: DocumentInfo = {
            file,
            folderId: currentFolderId,
            metaData: {
                path: file.path,
                size: file.size,
                lastModified: file.lastModified,
                fileExtension,
                nameWithoutFileExtension,
            },
        };

        return payload;
    };

    const { getInputProps, getRootProps } = useDropzone({
        onDrop: async (acceptedFiles: FileWithPath[]) =>
            uploadFilesAndFolders(
                {
                    files: acceptedFiles.map((f) => craftDocumentInfo(f, currentFolderId)),
                    currentFolderId,
                },
                {
                    onSettled: (data, err) => {
                        if (
                            (err as UploadDuplicateResponse)?.status === 'fail' &&
                            (err as UploadDuplicateResponse).message === L('duplicates_found')
                        ) {
                            return showDuplicateDialog(
                                (err as UploadDuplicateResponse).data.files,
                                (err as UploadDuplicateResponse).data.folders
                            );
                        }

                        if (data?.status !== 'success') return;

                        refetchFolders();
                        refetchFiles();
                    },
                }
            ),
        onDropRejected(fileRejections) {
            fileRejections.forEach((fileRejection) => {
                fileRejection.errors.forEach((error) => {
                    const errorCode = error.code.replace(/-/g, '_');
                    toast.error(`${fileRejection.file.name} ${L(errorCode)}`);
                });
            });
        },
        maxSize: 100000000,
    });

    useEffect(() => {
        setQuery(searchParams.get('q') || '');
        const where: DocumentsSearchQuery['where'] | any = searchParams.get('where');
        if (where == 'all' || where == 'current') {
            setSelectedDropdown(where);
        } else {
            setSelectedDropdown('all');
        }
    }, [searchParams.get('q'), searchParams.get('where')]);

    useEffect(() => {
        refetchFiles();
    }, [query]);

    const handleSubmit = () => {
        setQuery(searchInputRef.current?.value || '');
        if (selectedDropdown == 'current') {
            addSearchParams({
                q: searchInputRef.current?.value || '',
                where: selectedDropdown,
            });
        } else {
            navigate(`/document?q=${searchInputRef.current?.value || ''}&where=${selectedDropdown}`);
        }
    };

    const dropdownOptions: {
        id: DocumentsSearchQuery['where'];
        dropdownItem: React.ReactNode;
        button: React.ReactNode;
    }[] = [
        {
            id: 'all',
            dropdownItem: <DropdownButton.DropdownItem>{L('documents_search_all')}</DropdownButton.DropdownItem>,
            button: (
                <DropdownButton.Button
                    onClick={() => {
                        handleSubmit();
                    }}
                >
                    {L('documents_search_all')}
                </DropdownButton.Button>
            ),
        },
        {
            id: 'current',
            dropdownItem: <DropdownButton.DropdownItem>{L('documents_search_current')}</DropdownButton.DropdownItem>,
            button: (
                <DropdownButton.Button
                    onClick={() => {
                        handleSubmit();
                    }}
                >
                    {L('documents_search_current')}
                </DropdownButton.Button>
            ),
        },
    ];

    const [showSelectDeleteButton, setShowSelectDeleteButton] = useState(true);
    const [showDeleteSelectedDialog, setShowDeleteSelectedDialog] = useState(false);

    useEffect(() => {
        setShowDeleteSelectedDialog(false);
    }, [location.pathname]);

    return (
        <>
            {showItem && activeFolders && activeFiles && folders && (
                <DuplicateDialog
                    onClose={() => setShowItem(false)}
                    files={activeFiles}
                    folders={activeFolders}
                    onReplace={() =>
                        uploadFilesAndFolders(
                            { files: activeFiles, currentFolderId, duplicateAction: 'replace' },
                            {
                                onSettled: () => {
                                    refetchFolders();
                                    refetchFiles();
                                },
                            }
                        )
                    }
                    onSkip={() =>
                        uploadFilesAndFolders(
                            { files: activeFiles, currentFolderId, duplicateAction: 'skip' },
                            {
                                onSettled: () => {
                                    refetchFolders();
                                    refetchFiles();
                                },
                            }
                        )
                    }
                />
            )}

            <CardWithDivider
                mainArea={
                    <>
                        <CardHeader>
                            <BreadCrumbsWrapper>
                                <FolderBreadcrumbs locationFolderPath={folderPath} />
                            </BreadCrumbsWrapper>

                            <SearchWrapper>
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                >
                                    <Input.Search
                                        defaultValue={searchParams.get('q') || ''}
                                        style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }}
                                        disabled={isErrorFolders || isLoadingFiles || isLoadingFolders}
                                        placeholder={L('search')}
                                        icon={{ position: 'left', variant: { builtInIcon: 'search' } }}
                                        maxWidth={20}
                                        ref={searchInputRef}
                                    />
                                    <DropdownWrapper>
                                        <DropdownButton
                                            setOptionId={(value) =>
                                                addSearchParams({
                                                    q: query,
                                                    where: value,
                                                })
                                            }
                                            options={dropdownOptions}
                                            optionId={selectedDropdown}
                                            style={{ borderRadius: '0' }}
                                            className="dropdown-button-documents"
                                        />
                                    </DropdownWrapper>
                                </Form>
                            </SearchWrapper>

                            <ControlsWrapper>
                                <Button
                                    variant="danger"
                                    disabled={!showSelectDeleteButton}
                                    onClick={() => setShowDeleteSelectedDialog(true)}
                                >
                                    {L('remove_selected')}
                                </Button>

                                <DocumentControls
                                    disableControls={isErrorFolders || isLoadingFiles || isLoadingFolders}
                                    currentFolderId={currentFolderId}
                                    getInputProps={getInputProps}
                                    refetchFolders={refetchFolders}
                                    projectInfo={projectInfo}
                                />
                            </ControlsWrapper>

                            <DropzoneWrapper>
                                <Dropzone multiple maxSize={0} inputProps={getInputProps} rootProps={getRootProps} />
                            </DropzoneWrapper>
                        </CardHeader>

                        <DocumentList
                            showSelectDelete={(bool) => setShowSelectDeleteButton(bool)}
                            showDeleteSelectedDialog={showDeleteSelectedDialog}
                            hideDeleteSelectedDialog={(b) => setShowDeleteSelectedDialog(b)}
                            refetch={() => {
                                refetchFiles();
                                refetchFolders();
                            }}
                            searchQuery={query}
                            folderPath={folderPath}
                            files={files ?? []}
                            folders={query ? [] : folders?.data ?? []}
                            onDeleteSuccess={() => {
                                refetchFolders();
                                refetchFiles();
                            }}
                            onRenameSuccess={() => {
                                refetchFolders();
                                refetchFiles();
                            }}
                            isLoading={isLoadingFiles || isLoadingFolders}
                        />
                    </>
                }
            />
        </>
    );
};

export default DocumentContent;
