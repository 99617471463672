import styled from '@emotion/styled';
import React from 'react';
import { fontSize } from '../Shared/Style/typography';

const Wrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.2rem',
    flexGrow: 1,
});

const Error = styled.span({
    color: 'red',
    fontSize: fontSize.milli,
});

const ErrorAndInputWrapper = ({
    children,
    errorMsg,
    isMinHeight = true,
}: {
    children: React.ReactNode;
    errorMsg: string;
    isMinHeight?: boolean;
}) => {
    return (
        <Wrapper style={{ minHeight: isMinHeight ? '52px' : '' }}>
            {children}

            <Error>{errorMsg}</Error>
        </Wrapper>
    );
};

export default ErrorAndInputWrapper;
