import { L } from '../../../lib/i18n';
import * as React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import ModalProvider from '../../../components/Modal/Context/ModalContext';
import { BoardMembersDetailPage } from '../DetailPage/BoardMembersGeneralPage';
import { BoardMembersListPage } from '../ListPage/BoardMembersListPage';
import BigNavItem from '../../../components/BigTabItem/BigTabListItemNav';
import PageTitle from '../../../components/typography/PageTitle';
import BigTabNav from '../../../components/BigTabItem/BigTabNav';
import { useGetBoardMemberById } from '../hooks/useGetBoardMembers';

export const BoardMembersPage = () => {
    const { id } = useParams<{ id: string }>();

    const { data: boardMember } = useGetBoardMemberById(+id);
    return (
        <>
            <Switch>
                <Route exact path={'/boardmembers'}>
                    <PageTitle>{L('all_boardmembers')}</PageTitle>
                </Route>
                <Route path={'/boardmembers/:id'}>
                    <PageTitle>{L('boardmember')}</PageTitle>

                    <BigTabNav>
                        <BigNavItem exact to={'/boardmembers'}>
                            {L('all_boardmembers')}
                        </BigNavItem>
                        <div style={{ borderRight: 'solid 1px gray' }} />
                        <BigNavItem exact to={`/boardmembers/${id}`}>
                            {boardMember?.name || boardMember?.email}
                        </BigNavItem>
                    </BigTabNav>
                </Route>
            </Switch>
            <Switch>
                <Route exact path={'/boardmembers'}>
                    <ModalProvider>
                        <BigTabNav>
                            <BigNavItem exact to={'/boardmembers'}>
                                {L('all_boardmembers')}
                            </BigNavItem>
                        </BigTabNav>
                        <BoardMembersListPage />
                    </ModalProvider>
                </Route>

                <Route exact path={'/boardmembers/:id'}>
                    <BoardMembersDetailPage />
                </Route>
            </Switch>
        </>
    );
};
