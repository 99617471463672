import React from 'react';
import { isEqual } from 'lodash';
import toast from 'react-hot-toast';
import { Button } from '../../components/Buttons/Button';
import { L } from '../i18n';

const checkUnsavedData = ({
    fn,
    originalData,
    changedData,
    buttonText,
}: {
    fn: () => void;
    originalData: Record<string, any>;
    changedData: Record<string, any>;
    buttonText: string;
}) => {
    if (!isEqual(originalData, changedData)) {
        toast(
            (t) => (
                <div>
                    <p>{L('unsaved_data')}</p>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            onClick={() => {
                                fn();
                                toast.dismiss(t.id);
                            }}
                        >
                            {buttonText}
                        </Button>
                        <Button onClick={() => toast.dismiss(t.id)}>{L('cancel')}</Button>
                    </div>
                </div>
            ),
            { duration: Infinity }
        );
        return true;
    }
    return false;
};
export default checkUnsavedData;
