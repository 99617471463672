Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["getList"] = createRpcProxy("ContactsApi", "getList", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getListWithMails"] = createRpcProxy("ContactsApi", "getListWithMails", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getbyid"] = createRpcProxy("ContactsApi", "getbyid", {
  "urlPrefix": "/rpc"
});
o["save"] = createRpcProxy("ContactsApi", "save", {
  "urlPrefix": "/rpc"
});
o["remove"] = createRpcProxy("ContactsApi", "remove", {
  "urlPrefix": "/rpc"
});