import React from 'react';
import { useQueryErrorResetBoundary } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorNotice from './ErrorNotice';
import { useHistory } from 'react-router';

const ErrorNoticehandler: React.FC = ({ children }) => {
    const { reset } = useQueryErrorResetBoundary();
    const history = useHistory();
    return (
        <ErrorBoundary
            onReset={() => {
                reset();
                history.push('/');
            }}
            fallbackRender={({ resetErrorBoundary, error }) => {
                console.error(error);
                return <ErrorNotice text={'något gick fel ' + error.message} onClick={() => resetErrorBoundary()} />;
            }}
        >
            {children}
        </ErrorBoundary>
    );
};

export default ErrorNoticehandler;
