import React, { useEffect, useRef, useState } from 'react';
import { L } from '../../../../lib/i18n';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { createPortal } from 'react-dom';
import { Button } from '../../../../components/Buttons/Button';
import AttachmentItem from './AttachmentItem';
import ButtonWrapper from '../styled/ButtonWrapper';
import DropDownContent from '../styled/dropdown/DropDownContent';
import StyledItem from '../styled/dropdown/StyledItem';
import ContextItem from '../styled/dropdown/ContextItem';
import StyledTrigger from '../styled/dropdown/StyledTrigger';
import { Delete, Edit } from 'react-feather';
import styled from '@emotion/styled';
import ImageAttachment from './ImageAttachment';
import { Copy, Download } from 'react-feather';
import toast from 'react-hot-toast';

const DeleteIcon = styled(Delete)({
    height: '1.3rem',
    width: '1.3rem',
});

const EditIcon = styled(Edit)({
    height: '1.3rem',
    width: '1.3rem',
});

type Props = {
    deleteDocument?: (id: string) => void;
    name: string;
    fileType: string;
    fileCategory: string;
    file?: File;
    premadeUrl?: string;
    id: string;
};

const Lightbox = styled.dialog(({ theme }) => ({
    padding: '22px 32px 22px 32px',
    backgroundColor: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.divider}`,
    boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.1)',
    maxWidth: '90vw',
    maxHeight: '90vh',
}));
const DownloadIcon = styled(Download)({
    height: '1.3rem',
    width: '1.3rem',
});

const LightboxImage = styled.img({
    width: 'auto',
    height: 'auto',
    padding: '0.5rem',
});

const AttachmentDropdown = ({ name, fileType, deleteDocument, fileCategory, file, premadeUrl, id, ...rest }: Props) => {
    const dialogRef = useRef<HTMLDialogElement>(null);
    const previewRef = useRef<HTMLDialogElement>(null);

    const CopyIcon = styled(Copy)({
        height: '1.3rem',
        width: '1.3rem',
    });

    const handlePreview = () => {
        previewRef.current?.showModal();
    };

    const handleCopy = async () => {
        if (!premadeUrl) return;
        await navigator?.clipboard?.writeText(premadeUrl);
        toast(`url ${L('copied_to_clipboard')}`);
    };
    const handleDownload = async () => {
        if (!premadeUrl) return;

        window.open(premadeUrl);
    };

    const [url, setUrl] = useState('');

    useEffect(() => {
        if (!premadeUrl && file) {
            setUrl(URL.createObjectURL(file));
        }
        return () => {
            // To avoid memory leak
            URL.revokeObjectURL(url);
        };
    }, [file]);

    return (
        <>
            {createPortal(
                <Lightbox ref={previewRef}>
                    <div>
                        <div style={{ maxWidth: '80%', margin: 'auto' }}>
                            <LightboxImage
                                style={{ width: '100%', height: 'auto' }}
                                src={premadeUrl ?? url}
                                alt={name}
                            />
                        </div>
                        <div>
                            <ButtonWrapper>
                                <Button type="button" onClick={() => previewRef.current?.close()}>
                                    {L('close')}
                                </Button>
                            </ButtonWrapper>
                        </div>
                    </div>
                </Lightbox>,
                document.body
            )}
            <DropdownMenu.Root>
                <StyledTrigger style={{ minWidth: '100%', maxWidth: '100%' }} {...rest}>
                    {fileCategory === 'image' ? (
                        <ImageAttachment url={premadeUrl ?? url ?? ''} />
                    ) : (
                        <AttachmentItem name={name} fileType={fileType} className={'document'} />
                    )}
                </StyledTrigger>

                <DropdownMenu.Portal>
                    <DropDownContent sideOffset={1}>
                        {fileCategory.includes('image') && (
                            <StyledItem onClick={handlePreview}>
                                <ContextItem>
                                    <CopyIcon />
                                    <span>{L('preview')}</span>
                                </ContextItem>
                            </StyledItem>
                        )}

                        {premadeUrl && navigator?.clipboard && (
                            <>
                                <StyledItem onClick={() => handleCopy()}>
                                    <ContextItem>
                                        <CopyIcon />
                                        <span>{L('copy')}</span>
                                    </ContextItem>
                                </StyledItem>
                                <StyledItem onClick={handleDownload}>
                                    <ContextItem>
                                        <DownloadIcon />
                                        <span>{L('downloadCapitalised')}</span>
                                    </ContextItem>
                                </StyledItem>
                            </>
                        )}

                        {file && (
                            <StyledItem onClick={() => dialogRef.current?.showModal()}>
                                <ContextItem>
                                    <EditIcon />

                                    <span>{L('edit_name')}</span>
                                </ContextItem>
                            </StyledItem>
                        )}

                        {deleteDocument && (
                            <>
                                {' '}
                                <StyledItem onClick={() => deleteDocument(id)}>
                                    <ContextItem>
                                        <DeleteIcon />
                                        <span>{L('remove')}</span>
                                    </ContextItem>
                                </StyledItem>
                            </>
                        )}
                    </DropDownContent>
                </DropdownMenu.Portal>
            </DropdownMenu.Root>
        </>
    );
};

export default AttachmentDropdown;
