import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import MaintenanceTablePage from './page/MaintenanceTablePage';
import { L } from '../../lib/i18n';
import EditMaintenancePage from './page/EditMaintenancePage';
import AnalysPage from './page/AnalysPage';
import AddMaintenacePage from './page/AddMaintenancePage';
import PageTitle from '../../components/typography/PageTitle';
import BigNavItem from '../../components/BigTabItem/BigTabListItemNav';
import BigTabNav from '../../components/BigTabItem/BigTabNav';
import ModalProvider from '../../components/Modal/Context/ModalContext';
import useGetMaintenance from './hooks/useGetMaintenance';

export const MaintenancePage = () => {
    const match = useRouteMatch<{id: string}>('/maintenance/:id');
    const id = match?.params.id;
    const { data: maintenanceData } = useGetMaintenance(id);
    return (
        <>
            <PageTitle>{L('maintenance')}</PageTitle>
            <BigTabNav>
                <BigNavItem exact to={`/maintenance`}>
                    {L('all_maintenance')}
                </BigNavItem>
                <BigNavItem to={'/maintenance/analys'}>Årliga kostnader</BigNavItem>
                <Switch>
                    <Route path={'/maintenance/:id/edit'}>
                        <div style={{ borderRight: 'solid 1px gray' }} />
                        <BigNavItem exact to={`/maintenance/${id}/edit`}>
                            {maintenanceData?.name}
                        </BigNavItem>
                    </Route>
                    <Route path={'/maintenance/add'}>
                        <div style={{ borderRight: 'solid 1px gray' }} />
                        <BigNavItem exact to={`/maintenance/add`}>
                            {L('new_maintenance')}
                        </BigNavItem>
                    </Route>
                </Switch>
            </BigTabNav>
            <Switch>
                <Route exact path="/maintenance">
                    <ModalProvider>
                        <MaintenanceTablePage />
                    </ModalProvider>
                </Route>

                <Route exact path="/maintenance/add">
                    <ModalProvider>
                        <AddMaintenacePage />
                    </ModalProvider>
                </Route>
                <Route exact path="/maintenance/analys">
                    <AnalysPage />
                </Route>
                <Route path="/maintenance/:id">
                    <ModalProvider>
                        <EditMaintenancePage />
                    </ModalProvider>
                </Route>
            </Switch>
        </>
    );
};
