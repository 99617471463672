import * as React from 'react';
import { Avatar } from '@ourliving/ourliving-ui';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { colors } from '../../../../components/Shared/Style/colors';
import Title from '../../../../components/thread/title/Title';
import Arrow from '../../../../components/thread/arrow/Arrow';
import Divider from '../../../../components/thread/divider/Divider';

export interface ItemProps {
    id: string | number;
    name: string;
    city: string;
}

const ContactContainer = styled(Link)({
    display: 'grid',
    gridTemplateAreas: `
    "avatar title arrow"
    "avatar company arrow"
    "divider divider divider"
    `,
    textDecoration: 'none',
    color: colors.textColor2,
    gridTemplateColumns: 'min-content auto min-content',
    columnGap: '1rem',
});

const GridAvatar = styled(Avatar)({
    gridArea: 'avatar',
    justifySelf: 'start',
    alignSelf: 'start',
});

const GridName = styled(Title)({
    gridArea: 'title',
    placeSelf: 'stretch',
});

const GridCompany = styled.span({
    fontSize: '14px',
    lineHeight: '16px',
    color: colors.black80,
    gridArea: 'company',
    placeSelf: 'stretch',
});

const GridArrow = styled(Arrow)({
    gridArea: 'arrow',
    justifySelf: 'end',
    alignSelf: 'center',
});

const StyledDivider = styled(Divider)({
    gridArea: 'divider',
    marginTop: '0.5rem',
});

const ServicePartnerListItem: React.FC<ItemProps> = ({ id, name, city }) => {
    return (
        <li>
            <ContactContainer to={`/service-partner/${id}`}>
                <GridAvatar name={name} />
                <GridName>{name}</GridName>
                <GridCompany>{city}</GridCompany>
                <GridArrow />
                <StyledDivider width={100} />
            </ContactContainer>
        </li>
    );
};

export default ServicePartnerListItem;
