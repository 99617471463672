import styled from '@emotion/styled';
import React from 'react';
import { colors } from '../Shared/Style/colors';
import { fontSize } from '../Shared/Style/typography';

const Text = styled.span({
    color: colors.black80,
    fontSize: fontSize.seta,
});

type Props = {
    children: React.ReactNode;
};

const SpanText = ({ children, ...rest }: Props) => {
    return <Text {...rest}>{children}</Text>;
};

export default SpanText;
