import I18nApi from '@app/api/public/I18nApi';
let i18 = {};

export const preparei18 = async () => {
    i18 = await I18nApi.strings();
};

export const spreadNewStringDatas = (newStringDatas: Record<string, string>) => {
    i18 = { ...i18, ...newStringDatas };
};

export const L = (key: string) => {
    return i18[key] || key;
};
