import React, { FC } from 'react';
import styled from '@emotion/styled';
import { PublicSiteInformationItem } from '@app/api/models/PublicSite';
import { colors } from '../../../../areas/public-site/lib/publicColors';

const HeroImage = styled.div((props: { url?: string | null }) => ({
    height: '30vh',
    width: '100%',
    display: 'flex',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url(${props.url})`,
    justifyContent: 'center',
    alignItems: 'center',
}));
const StyledTitle = styled.h1({
    color: colors.white,
    display: 'flex',
    margin: 'auto',
    fontSize: 'clamp(1.5rem, 2rem, 2.5rem)',
    backgroundColor: `${colors.heroTitleBackground}CC`,
    padding: '1rem 2rem',
    justifyContent: 'center',
    alignItems: 'center',
});

type Props = { pageListItem: PublicSiteInformationItem };

const Hero: FC<Props> = ({ pageListItem }) => {
    return (
        <>
            <HeroImage url={pageListItem && pageListItem.cover_image_data?.original?.id}>
                {pageListItem.title && <StyledTitle>{pageListItem.title}</StyledTitle>}
            </HeroImage>
        </>
    );
};

export default Hero;
