Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["getbyid"] = createRpcProxy("ProjectsApi", "getbyid", {
  "urlPrefix": "/rpc"
});
o["getByPropertyId"] = createRpcProxy("ProjectsApi", "getByPropertyId", {
  "urlPrefix": "/rpc"
});
o["getProjectArea"] = createRpcProxy("ProjectsApi", "getProjectArea", {
  "urlPrefix": "/rpc"
});
o["getProperies"] = createRpcProxy("ProjectsApi", "getProperies", {
  "urlPrefix": "/rpc"
});
o["getInvoiceInfromation"] = createRpcProxy("ProjectsApi", "getInvoiceInfromation", {
  "urlPrefix": "/rpc"
});
o["savePublicAddress"] = createRpcProxy("ProjectsApi", "savePublicAddress", {
  "urlPrefix": "/rpc"
});
o["saveDocument"] = createRpcProxy("ProjectsApi", "saveDocument", {
  "urlPrefix": "/rpc"
});
o["updateSort"] = createRpcProxy("ProjectsApi", "updateSort", {
  "urlPrefix": "/rpc"
});
o["deleteDocument"] = createRpcProxy("ProjectsApi", "deleteDocument", {
  "urlPrefix": "/rpc"
});
o["getImages"] = createRpcProxy("ProjectsApi", "getImages", {
  "urlPrefix": "/rpc"
});
o["updateEmail"] = createRpcProxy("ProjectsApi", "updateEmail", {
  "urlPrefix": "/rpc",
  httpMethod: 'post'
});