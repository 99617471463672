import React from 'react';
import { L } from '../../../../lib/i18n';
import Input from '../../../../components/Forms/Input';
import styled from '@emotion/styled';
import { style } from '../../../../components/Shared/Style';

type Props = {
    recipients: string[];
    recipientsInput: string;
    handleSetRecipients: (recipients: string[]) => void;
    handleSetRecipientsInput: (input: string) => void;
};

const InputWrapper = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    flexGrow: 1,
});

const RestyledInput = styled(Input)({
    borderRadius: style.roundCornerSize.small,
    border: 'none',
    padding: 0,
    height: '22px',
    paddingLeft: style.margin.xs,
});

const AddMailRecipients = ({ recipients, recipientsInput, handleSetRecipients, handleSetRecipientsInput }: Props) => {
    return (
        <InputWrapper>
            <RestyledInput
                type="email"
                name={L('mail_to')}
                value={recipientsInput}
                onChange={(e) => {
                    handleSetRecipientsInput(e.target.value);
                }}
                onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        if (recipientsInput.trim().length === 0) {
                            // Wont work if I don't include one space
                            handleSetRecipientsInput('');
                            return;
                        }
                        handleSetRecipients([...recipients, recipientsInput]);
                        handleSetRecipientsInput('');
                    }
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                }}
                onBlur={() => {
                    if (recipientsInput.trim().length === 0) {
                        // Wont work if I don't include one space
                        handleSetRecipientsInput('');
                        return;
                    }
                    handleSetRecipients([...recipients, recipientsInput]);
                    handleSetRecipientsInput('');
                }}
            />
        </InputWrapper>
    );
};

export default AddMailRecipients;
