import React from 'react';
import useUpdateFile from '../../hooks/files/useUpdateFile';
import { FileDetails, UpdateFileDetails } from '@app/api/models/Folders';
import { MutateOptions } from 'react-query';
import UpdateItemForm from './UpdateItemForm';

type UpdateFileFormProps = {
    item: FileDetails;
    onCancel: () => void;
    options?:
        | MutateOptions<
              | {
                    readonly status: 'success';
                    readonly data: FileDetails;
                    readonly message?: undefined;
                }
              | {
                    readonly status: 'success';
                    readonly data: FileDetails;
                    readonly message: string | undefined;
                },
              unknown,
              UpdateFileDetails,
              unknown
          >
        | undefined;
};

const UpdateFileForm = ({ item, onCancel, options }: UpdateFileFormProps) => {
    const { mutate: updateFile, isLoading: isLoading } = useUpdateFile();

    const submit = async (data: Partial<UpdateFileDetails>) => {
        const fileDetails: UpdateFileDetails = {
            data: item.data,
            folder_id: item.folder_id,
            created_at: item.created_at,
            updated_at: item.updated_at,
            id: item.id,
            document_data: item.document_data,
            document_subtype: item.document_subtype,
            document_type: item.document_type,
            document_type_name: item.document_type_name,
            documentable_id: item.documentable_id,
            documentable_type: item.documentable_type,
            name: item.name,
            signed_url: item.signed_url,
            sort: item.sort,
            ...data,
        };

        updateFile(fileDetails, options);
    };

    return <UpdateItemForm item={item} onCancel={onCancel} onUpdate={submit} isLoading={isLoading} />;
};

export default UpdateFileForm;
