import { ProfileDetail } from '@app/api/models/Profile';
import styled from '@emotion/styled';
import React, { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { colors } from '../../../../components/Shared/Style/colors';
import Spinner from '../../../../components/Spinner/Spinner';
import { L } from '../../../../lib/i18n';
import { useTimeSlotCalendar } from '../../context/useTimeSlots';
import useGetBookableItemsForCell from '../../hooks/useGetBookableItemsForCell';
import useGetBookingResourceById from '../../hooks/useGetBookingResourceById';
import useGetUserBookedItemsForCell from '../../hooks/useGetUserBookedItemsForCell';
import useRemoveBooking from '../../hooks/useRemoveBooking';
import useSaveBooking from '../../hooks/useSaveBooking';

const AddbookingCell = styled.button(({ booked }: { booked: boolean; viewState: ViewState }) => {
    return {
        display: 'grid',
        gridTemplateAreas: `
            "time"
            "action"
        `,
        gridTemplateColumns: '1fr',
        width: '100%',
        height: '100%',
        minHeight: '35px',
        color: booked ? colors.white : colors.black,
        textAlign: 'center',
        placeItems: 'center',
        backgroundColor: booked ? colors.tabActive : colors.white,
        borderRadius: '4px',
        border: booked ? `1px solid ${colors.tabActive}` : `2px solid ${colors.black}`,
        cursor: 'pointer',
        '&:disabled': {
            cursor: 'default',
            color: colors.black80,
            border: `1px solid ${colors.timeSlotBorder}`,
            backgroundColor: colors.timeSlotBorder,
        },
    };
});

const Time = styled.span({
    paddingTop: '8px',
    gridArea: 'time',
    alignSelf: 'start',
    textAlign: 'center',
});

const ActionText = styled.span({
    padding: '8px',
    gridArea: 'action',
    alignSelf: 'start',
    textAlign: 'center',
    fontWeight: 400,
});

type ViewState = 'saveBooking' | 'removeBooking';
type Props = {
    bookingId: number;
    cellId: number;
    isAfter?: boolean;
    time: string;
    selectedUser: ProfileDetail;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
const ScrollToTag = styled.a({
    height: ' 0px',
    width: '0px',
    opacity: 0,
    cursor: 'none',
});

const TimeSlotBookingHandler: FC<Props> = ({ children, selectedUser, cellId, time, isAfter, bookingId, ...rest }) => {
    const { data: resourceData } = useGetBookingResourceById(bookingId);

    const bookedItems = useGetUserBookedItemsForCell({ cellId, userId: selectedUser.id, bookingId });
    const availableResourceItems = useGetBookableItemsForCell(cellId, bookingId);

    const booked = !!bookedItems?.[0];

    const [viewState] = useState<ViewState>(booked ? 'removeBooking' : 'saveBooking');

    const { dayjsState, reset } = useTimeSlotCalendar();

    const { mutate: removeBooking, status: removeStatus } = useRemoveBooking();
    const { mutate: saveBooking, status: saveStatus } = useSaveBooking();

    useEffect(() => {
        if (saveStatus === 'success') {
            reset();
        }
        if (removeStatus === 'success') {
            reset();
        }
    }, [removeStatus, saveStatus]);

    if (!resourceData) return null;

    const notify = (bookingState) =>
        toast.success(
            `Du har ${bookingState} ${resourceData.name} den ${dayjsState.format('YYYY/MM/DD')} ${time} till ${
                selectedUser?.name || selectedUser?.email
            }`
        );

    const handleClick = () => {
        if (viewState === 'saveBooking') {
            const item = availableResourceItems?.[0];

            if (!item) return;
            saveBooking(
                {
                    booking_date: dayjsState.format('YYYY-MM-DD'),
                    booking_resource_item_id: item.id,
                    booking_slot_id: cellId,
                    userId: selectedUser.id,
                    resourceId: bookingId.toString(),
                },
                {
                    onSuccess: () => {
                        notify('bokat');
                    },
                }
            );
            return;
        }
        if (viewState === 'removeBooking') {
            const item = bookedItems?.[0];

            if (!item) return;
            removeBooking(
                {
                    booking_date: item.booking_date,
                    booking_resource_item_id: item.booking_resource_item_id,
                    booking_slot_id: cellId,
                    userId: selectedUser.id,
                    resourceId: bookingId.toString(),
                },
                {
                    onSuccess: () => {
                        notify('avbokat');
                    },
                }
            );
            return;
        }
    };

    return (
        <>
            <ScrollToTag href={time} />

            <AddbookingCell
                {...rest}
                viewState={viewState}
                booked={booked}
                disabled={isAfter}
                onClick={() => handleClick()}
            >
                <Time>{time}</Time>
                {viewState === 'saveBooking' && (
                    <ActionText>
                        {saveStatus === 'loading' ? <Spinner /> : `${L('book')} ${resourceData.name}`}
                    </ActionText>
                )}
                {viewState === 'removeBooking' && (
                    <ActionText>
                        {removeStatus === 'loading' ? <Spinner /> : `${L('cancel_booking')} ${resourceData.name}`}
                    </ActionText>
                )}
            </AddbookingCell>
        </>
    );
};

export default TimeSlotBookingHandler;
