import styled from '@emotion/styled';
import React from 'react';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import Filters from '../../../components/Filters/Filters';
import { Loading } from '../../../components/Loading/Loading';
import { L } from '../../../lib/i18n';
import GlobalFilter from '../../../components/Table/filterComponents/GlobalFilter';
import { tooltip } from '../../../components/Shared/Style/typography';
import { PropertyFilter } from '@app/api/models/Property';
import { useGetPropertiesList } from '../hooks/propertyQueries';

const StyledDropdown = styled(Dropdown)({
    marginRight: '1rem',
});

const Statistic = styled.p(({ theme }) => ({
    ...tooltip,
    color: theme.colors.textColor1,
}));

type Props = {
    setFilter: React.Dispatch<React.SetStateAction<PropertyFilter>>;
    filter: PropertyFilter;
    setGlobalFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
    globalFilter: string;
};

const FilterArea: React.FC<Props> = ({ setFilter, filter, globalFilter, setGlobalFilter }) => {
    const { data: properties } = useGetPropertiesList();

    if (!properties) return <Loading />;

    return (
        <div
            style={{
                justifyContent: 'space-between',
                alignItems: 'start',
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                paddingBottom: '2rem',
            }}
        >
            <Filters>
                <StyledDropdown
                    isClearable
                    label={'Alla'}
                    onChange={(value) => setFilter({ ...filter, confirmed_at: value })}
                    value={filter.confirmed_at}
                    options={
                        [
                            { value: 'Konto bekräftat', label: 'Konto bekräftat' },
                            { value: 'Ej bekräftat', label: 'Ej bekräftat' },
                        ]?.map((accepted) => {
                            return {
                                value: accepted.value,
                                label: L(`${accepted.label}`),
                            };
                        }) || []
                    }
                />
            </Filters>

            <div style={{ display: 'flex', gap: '1rem', alignItems: 'start', justifyContent: 'space-between' }}>
                <div>
                    <Statistic>
                        {L('properties')}: {properties?.length || 0}
                    </Statistic>
                </div>
                <GlobalFilter placeholder="Sök" filter={globalFilter} setFilter={setGlobalFilter} />
            </div>
        </div>
    );
};

export default FilterArea;
