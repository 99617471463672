import React from 'react';
import { useParams } from 'react-router-dom';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { Loading } from '../../../components/Loading/Loading';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import useGetBookingResourceById from '../hooks/useGetBookingResourceById';
import { FullDayBookingDetailPage } from './FullDayBookingDetailPage';
import { FullDayBookingDraftDetailPage } from './FullDayBookingDraftDetailPage';
import { TimeSlotsBookingDetailPage } from './TimeSlotsBookingDetailPage';
import { TimeSlotsBookingDraftDetailPage } from './TimeSlotsBookingDraftDetailPage';

export const BookingDetailPage = () => {
    const { bookingId } = useParams<{ bookingId: string }>();
    const { data, status } = useGetBookingResourceById(+bookingId);

    if (status === 'loading')
        return <CardWithDivider topArea={<CardTitle>{L('booking_slot_settings')}</CardTitle>} mainArea={<Loading />} />;

    if (status === 'success' && data) {
        if (data.booking_resource_slot_type.name === 'slot') {
            console.log(data.published);
            if (data.published) return <TimeSlotsBookingDetailPage />;
            return <TimeSlotsBookingDraftDetailPage />;
        }
        if (data.published) return <FullDayBookingDetailPage />;
        return <FullDayBookingDraftDetailPage />;
    }

    return (
        <CardWithDivider
            topArea={<CardTitle>{L('booking_slot_settings')}</CardTitle>}
            mainArea={<div>{L('no_booking_resource')}</div>}
        />
    );
};
