import React from 'react';
import Map from './Map';
import useGetAddress from './hooks/useGetAddress';

const AddressCard = () => {
    const { data: projectInfo } = useGetAddress();
    if (!projectInfo) return null;
    const urlAddress = `${projectInfo.address.addressLine1?.replaceAll(
        ' ',
        '+'
    )},${projectInfo.address.city?.replaceAll(' ', '+')}`;

    if (!urlAddress) return null;

    return <Map address={urlAddress} />;
};

export default AddressCard;
