Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["getNotificationCount"] = createRpcProxy("NotificationsApi", "getNotificationCount", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["list"] = createRpcProxy("NotificationsApi", "list", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getNotificationTypes"] = createRpcProxy("NotificationsApi", "getNotificationTypes", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});