import React from 'react';
import UsersApi from '@app/api/public/UsersApi';
import { initializeApp } from 'firebase/app';
import { getMessaging, isSupported, onMessage, getToken } from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';
import getFirebaseConfig from './getConfigJson';
import PushNotificationApi from '@app/api/public/PushNotificationApi';
import toast from 'react-hot-toast';
import { L } from '../lib/i18n';
import * as Icon from 'react-feather';

const FbConfig = getFirebaseConfig();

const firebaseConfig = {
    apiKey: FbConfig ? FbConfig.apiKey : 'AIzaSyBIQ1OYaqx3yMuN9YxlxWv3a7eyqSQwN_Y',
    authDomain: FbConfig ? FbConfig.authDomain : 'ourliving-develop.firebaseapp.com',
    projectId: FbConfig ? FbConfig.projectId : 'ourliving-develop',
    storageBucket: FbConfig ? FbConfig.storageBucket : 'ourliving-develop.appspot.com',
    messagingSenderId: FbConfig ? FbConfig.messagingSenderId : '945120490009',
    appId: FbConfig ? FbConfig.appId : '1:945120490009:web:d1189af61c0b833338f088',
    measurementId: FbConfig ? FbConfig.measurementId : 'G-LX5WSCRBFY',
};

// init firebase
const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

const analytics = getAnalytics(app);

isSupported().then((res) => {
    if (res) {
        // Handle incoming messages. Called when:
        // - a message is received while the app has focus
        // - the user clicks on an app notification created by a service worker
        //   `messaging.onBackgroundMessage` handler.
        onMessage(messaging, (payload) => {
            toast.success(`${payload.notification?.body}`, {
                position: 'top-right',
                icon: <Icon.Mail />,
            });
            // ...
        });
    }
});

const handleGetToken = () => {
    isSupported().then(async (res) => {
        if ('serviceWorker' in navigator && 'PushManager' in window && res) {
            try {
                const currentToken = await getToken(messaging, {
                    vapidKey:
                        FbConfig?.vapidKey ||
                        'BKbYBN7OyaDaXzJVwbCW5isA1iBWD0cpv6A3dXsEo2s4SYCzu21Ijha9Bhv8pxPldR9mu8hX-EGyl4DGg4PUShU',
                });
                if (!currentToken) throw new Error('No token');
                const loggedIn = await UsersApi.verifyLogin();
                if (!loggedIn) return;
                await PushNotificationApi.saveToken(currentToken);
            } catch (err) {}
        }
    });
};

export const requestPermission = async () => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
        const permisson = await Notification.requestPermission();
        if (permisson === 'granted') {
            handleGetToken();
        }
    }
};
export { app as default, handleGetToken, analytics, messaging, isSupported };
