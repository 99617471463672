import { useQueryClient, useMutation } from 'react-query';
import PublicSiteApi from '@app/api/public/PublicSiteApi';
import { getPublicSiteDocumentsList } from '../../../areas/public-site/hooks/useGetDocumentsById';

type Variables = { itemId: string; name: string; file: File };

export const saveDocuments = async (itemId: number, name: string, file: File) => {
    return PublicSiteApi.saveDocument(itemId, name, file);
};

const useSaveDocuments = () => {
    const queryClient = useQueryClient();
    return useMutation((variables: Variables) => saveDocuments(+variables.itemId, variables.name, variables.file), {
        onSettled: (data) => {
            queryClient.invalidateQueries(getPublicSiteDocumentsList.list(data?.documentable_id));
        },
    });
};

export const saveEditedDocuments = async (document) => {
    return await PublicSiteApi.saveEditDocument(document);
};

export default useSaveDocuments;
