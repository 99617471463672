import ContentNewsApi from '@app/api/public/ContentNewsApi';
import { useMutation, useQueryClient } from 'react-query';
import { memberNewsQueryKeys } from './memberNewsQueries';

type Variables = { id: number; notify: boolean };

const usePublishNews = () => {
    const client = useQueryClient();
    return useMutation(
        (varables: Variables) => {
            return ContentNewsApi.publishNews(varables);
        },
        {
            onSettled: () => {
                client.invalidateQueries(memberNewsQueryKeys.all);
            },
        }
    );
};

export default usePublishNews;
