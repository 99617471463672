import React, { useEffect, useState } from 'react';
import { L } from '../../../../lib/i18n';
import styled from '@emotion/styled';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import ContentEventApi from '@app/api/public/ContentEventApi';
import { Button } from '../../../../components/Buttons/Button';
import { useHistory, useParams } from 'react-router-dom';
import useRemoveEvent from '../hooks/useRemoveEvent';
import { memberEventQueryKeys, useGetMemberEventById } from '../hooks/memberEventQueries';
import toast from 'react-hot-toast';
import ConfirmationButton from '../../../../components/Buttons/ConfirmationButton';
import DocumentsAreaWithUpload from '../../../../components/document/DocumentsAreaWithUpload';
import StatusArea from '../../../../components/StatusArea/StatusArea';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { style } from '../../../../components/Shared/Style';
import { useMutation, useQueryClient } from 'react-query';
import useGetSignedUrlById from '../../../../hooks/useGetSignedUrlById';
import { formatDateTimeZone, formatInputDateTimeDayjs } from '@app/shared/lib/formatting';
import Label from '../../../../components/Forms/Label';
import ImageDropZone from '../../../../components/ImageDropZone/ImageDropZone';
import Input from '../../../../components/Forms/Input';
import triggerDirtyFieldsToats from '../../../../components/Forms/DirtyFieldsToast';
import Quill from '../../../../components/Quill/Quill';
import EventAttendeesArea from './EventAttendeesArea';
import { useLocalStorage } from 'react-use';
import DropdownButton from '../../../../components/Buttons/DropdownButton';
import StyledRadixSwitch from '../../../../components/StyledSwitch/StyledRadixSwitch';
import dayjs from 'dayjs';
import { notificationToast } from '../../notificationToast/notificationToast';
import { DocumentDetail } from '@app/api/models/Documents';

const StyledCardWithDivider = styled(CardWithDivider)({
    width: 'fit-content',
});

const GridContainer = styled.div(({ theme }) => ({
    display: 'grid',
    gap: '1rem',
    columnGap: '2rem',
    backgroundColor: theme.colors.white,
    alignItems: 'start',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateAreas: `
    "img          img"
    "date         location"
    "signOpen         lastSign"
    "title        title"
    "description  description"
    "buttons      buttons"
    `,
    paddingBottom: '0px',
    maxWidth: '600px',
}));

const EditEventContainer = styled.div({
    display: 'grid',
    gap: '4rem',
    gridTemplateColumns: 'auto auto',
    gridTemplateAreas: `
    "editArea statusArea"
    `,
});

const StyledImageDropZone = styled(ImageDropZone)({
    aspectRatio: '4/3',
});

const FlexWrapper = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    alignItems: 'start',

    '& > *': {
        flexBasis: '600px',
        flexGrow: 1,
    },
});

const ContentSwitch = styled(StyledRadixSwitch)({
    marginTop: '4px',
});

const eventFormDraftSchema = z.object({
    title: z.string().min(1, { message: L('required') }),
    description: z.string().min(1, { message: L('required') }),
    event_date: z.string().min(1, { message: L('required') }),
    register_enabled: z.boolean(),
    location: z.string().min(1, { message: L('required') }),
    last_sign_date: z.string(),
});

type EditDraftEvent = {
    event: z.infer<typeof eventFormDraftSchema> & {
        id: number;
        cover_image_data?: DocumentDetail['document_data'] | null;
    };
    image?: File;
};

const saveEventDraft = (variables: EditDraftEvent) => {
    return ContentEventApi.saveDraft(variables);
};
type PublishDraftEvent = {
    event: z.infer<typeof eventFormDraftSchema> & {
        id: number;

        cover_image_data?: DocumentDetail['document_data'] | null;
    };
    image?: File;
    notify: boolean;
};

const publishEvent = (variables: PublishDraftEvent) => {
    return ContentEventApi.saveAndPublish(variables);
};

const EventEditPublished = () => {
    const { id } = useParams<{ id: string }>();
    const [localSubmitButtonState, setSubmitButtonState] = useLocalStorage<'save_draft' | 'save_and_publish'>(
        'editEventDraft',
        'save_and_publish'
    );
    const submitButtonState = localSubmitButtonState || 'save_and_publish';
    const { mutate: remove } = useRemoveEvent();

    const { data: memberEvent } = useGetMemberEventById(+id);

    const [data, setData] = useState(memberEvent);

    useEffect(() => {
        setData(memberEvent);
    }, [memberEvent]);

    const history = useHistory();

    const queryClient = useQueryClient();
    const { data: signedUrl } = useGetSignedUrlById(data?.cover_image_data?.original?.id, undefined, false);

    const [imgFile, setImgFile] = useState<File>();
    const [imgUrl, setImgUrl] = useState<string>('');

    const { mutate: saveDraft } = useMutation((variables: EditDraftEvent) => saveEventDraft(variables), {
        onSuccess: () => {
            setImgFile(undefined);
            setImgUrl('');
        },
        onSettled: () => {
            queryClient.invalidateQueries(memberEventQueryKeys.detail(+id));
            queryClient.invalidateQueries(memberEventQueryKeys.lists());
        },
    });

    const { mutate: saveAndPublishEvent } = useMutation((variables: PublishDraftEvent) => publishEvent(variables), {
        onMutate: () => {
            toast.loading(L('loading'), {
                id: 'send-notification',
            });
        },
        onSuccess: () => {
            setImgFile(undefined);
            setImgUrl('');
        },
        onSettled: () => {
            queryClient.invalidateQueries(memberEventQueryKeys.detail(+id));
            queryClient.invalidateQueries(memberEventQueryKeys.lists());
        },
    });

    const { mutate: toggleEventSignup } = useMutation({
        mutationFn: ({ id }: { id: number }) => ContentEventApi.toggleEventSignup(id),
        onSettled: () => {
            queryClient.invalidateQueries(memberEventQueryKeys.detail(+id));
            queryClient.invalidateQueries(memberEventQueryKeys.lists());
        },
    });

    const onUpload = (files: File[]) => {
        setImgFile(files[0]);
        setImgUrl(URL.createObjectURL(files[0]));
    };

    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isDirty },
        watch,
        setValue,
    } = useForm({
        resolver: zodResolver(eventFormDraftSchema),
        values: {
            title: data?.title || '',
            description: data?.description || '',
            event_date: formatInputDateTimeDayjs(data?.event_date || ''),
            register_enabled: data?.register_enabled || false,
            location: data?.location || '',
            last_sign_date: formatInputDateTimeDayjs(data?.last_sign_date || ''),
        },
    });

    const submit = (formData: EditDraftEvent['event'], type: 'publish' | 'draft') => {
        if (type === 'publish') {
            const publish = (notify: boolean) => {
                saveAndPublishEvent(
                    {
                        event: {
                            ...formData,
                            event_date: formatDateTimeZone(formData.event_date),
                            last_sign_date: formatDateTimeZone(formData.last_sign_date),
                            cover_image_data: data?.cover_image_data,
                        },
                        image: imgFile,
                        notify,
                    },
                    {
                        onSuccess: () => {
                            toast.success(L('event_saved_and_published'));
                        },
                        onSettled: () => {
                            toast.dismiss('send-notification');
                        },
                    }
                );
            };

            return notificationToast({
                notify: (notify) => publish(notify),
                type: 'publish',
            });
        }

        saveDraft(
            {
                event: {
                    ...formData,
                    event_date: formatDateTimeZone(formData.event_date),
                    last_sign_date: formatDateTimeZone(formData.last_sign_date),
                    cover_image_data: data?.cover_image_data,
                },
                image: imgFile,
            },
            {
                onSuccess: () => {
                    toast.success(L('event_updated'));
                },
            }
        );
    };

    const onRemove = () => {
        if (!data) return;
        remove(
            { id: data.id },
            {
                onSuccess: () => {
                    toast.success(`${data.title} ${L('removed')}`);
                    history.push('/event');
                },
            }
        );
    };

    return (
        <FlexWrapper>
            <StyledCardWithDivider
                topArea={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <CardTitle>{L('edit_event_in_draft')}</CardTitle>
                        <ConfirmationButton props={{ onConfirm: onRemove }} />
                    </div>
                }
                mainArea={
                    <EditEventContainer>
                        <GridContainer>
                            <div style={{ gridArea: 'img' }}>
                                <Label title={L('image')} />
                                <StyledImageDropZone
                                    dropzoneOptions={{}}
                                    onDrop={onUpload}
                                    url={imgUrl || signedUrl || ''}
                                    remove={() => {
                                        if (imgUrl || imgFile) {
                                            setImgUrl('');
                                            setImgFile(undefined);
                                            return;
                                        }
                                        if (!data?.cover_image_data) return;
                                        const mutatedData = { ...data, cover_image_data: null };
                                        setData(mutatedData);
                                    }}
                                />
                            </div>

                            <Label
                                style={{ gridArea: 'date' }}
                                htmlFor="event_date"
                                title={`${L('event_date')}*`}
                                error={errors?.event_date?.message}
                            >
                                <Input
                                    type="datetime-local"
                                    min={formatInputDateTimeDayjs()}
                                    {...register('event_date', {
                                        onChange() {
                                            if (watch('event_date') < watch('last_sign_date')) {
                                                setValue('last_sign_date', '');
                                            }
                                        },
                                        required: true,
                                    })}
                                />
                            </Label>

                            <Label
                                style={{ gridArea: 'location' }}
                                htmlFor="location"
                                title={`${L('location_event')}*`}
                                error={errors?.location?.message}
                            >
                                <Input {...register('location', { required: true })} />
                            </Label>

                            <Label
                                style={{ gridArea: 'signOpen' }}
                                htmlFor="signOpen"
                                title={`${L('registration_open')}*`}
                                error={errors?.location?.message}
                            >
                                <ContentSwitch
                                    checked={data?.register_enabled}
                                    onCheckedChange={() => toggleEventSignup({ id: +id })}
                                />
                            </Label>

                            {data?.register_enabled && (
                                <Label
                                    style={{ gridArea: 'lastSign' }}
                                    htmlFor="lastSign"
                                    title={`${L('lastSign_event')}*`}
                                    error={errors?.location?.message}
                                >
                                    <Input
                                        type="datetime-local"
                                        max={formatInputDateTimeDayjs(watch('event_date'))}
                                        min={formatInputDateTimeDayjs(dayjs())}
                                        {...register('last_sign_date', {
                                            required: true,
                                            disabled: watch('event_date') ? false : true,
                                        })}
                                    />
                                </Label>
                            )}

                            <Label
                                style={{ gridArea: 'title' }}
                                htmlFor="title"
                                title={`${L('title')}*`}
                                error={errors?.title?.message}
                            >
                                <Input {...register('title', { required: true })} />
                            </Label>

                            <div style={{ gridArea: 'description' }}>
                                <Label title={`${L('description')}*`} error={errors?.description?.message} />
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) => {
                                        return <Quill {...field} placeholder="Skriv något här..." />;
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    placeSelf: 'end',
                                    whiteSpace: 'nowrap',
                                    display: 'flex',
                                    gap: style.margin.m,
                                    gridArea: 'buttons',
                                }}
                            >
                                <Button
                                    type="button"
                                    variant="primary"
                                    onClick={() => {
                                        if (isDirty) {
                                            return triggerDirtyFieldsToats({
                                                continueButtonText: L('continue_without_saving'),
                                                continueFn: () => {
                                                    history.push('/event');
                                                },
                                            });
                                        }
                                        history.push('/event');
                                    }}
                                    role="link"
                                >
                                    {L('cancel')}
                                </Button>

                                <DropdownButton
                                    variant="filledPrimary"
                                    setOptionId={(state) => {
                                        setSubmitButtonState(state);
                                    }}
                                    optionId={submitButtonState}
                                    options={[
                                        {
                                            dropdownItem: (
                                                <DropdownButton.DropdownItem>
                                                    {L('save_draft')}
                                                </DropdownButton.DropdownItem>
                                            ),
                                            id: 'save_draft',
                                            button: (
                                                <DropdownButton.Button
                                                    onClick={handleSubmit((formValues) =>
                                                        submit({ ...formValues, id: +id }, 'draft')
                                                    )}
                                                >
                                                    {L('save_draft')}
                                                </DropdownButton.Button>
                                            ),
                                        },
                                        {
                                            dropdownItem: (
                                                <DropdownButton.DropdownItem>
                                                    {L('save_and_publish')}
                                                </DropdownButton.DropdownItem>
                                            ),
                                            id: 'save_and_publish',
                                            button: (
                                                <DropdownButton.Button
                                                    onClick={handleSubmit((formValues) =>
                                                        submit({ ...formValues, id: +id }, 'publish')
                                                    )}
                                                >
                                                    {L('save_and_publish')}
                                                </DropdownButton.Button>
                                            ),
                                        },
                                    ]}
                                />
                            </div>
                        </GridContainer>
                        <StatusArea
                            status={{
                                created_by_name: data?.created_by_name,
                                created_by_email: data?.created_by_email,
                                notified_at: { value: data?.notified_at },
                                created_at: data?.created_at,
                            }}
                            style={{ alignSelf: 'start', gridArea: 'statusArea' }}
                        />
                    </EditEventContainer>
                }
            />

            <EventAttendeesArea />

            <DocumentsAreaWithUpload
                documentQueryKey={memberEventQueryKeys.document(+id)}
                getDocuments={async () =>
                    (await ContentEventApi.listDocuments(+id)).map((doc) => ({
                        filename: doc.document_data.original.metadata.filename,
                        id: doc.id,
                        name: doc.name,
                        originalId: doc.document_data.original.id,
                    }))
                }
                removeDocument={ContentEventApi.deleteDocumentFromContentEvent}
                renameDocument={ContentEventApi.updateDocumentName}
                saveDocument={(file) => ContentEventApi.saveDocument(+id, file.name, file)}
            />
        </FlexWrapper>
    );
};

export default EventEditPublished;
