import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../Shared/Style/colors';
import VarticalDots from '../Icon/vertical-dots.svg';
import { HTMLAttributes } from 'react';
import { ReactNode } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';

const MoreVerticalNav = styled.div({
    position: 'relative',
});

const MoreNavContainer = styled.nav({
    position: 'absolute',
    top: '120%',
    right: '-5px',
    left: 'auto',
    width: '200px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.white,
    border: `1px solid ${colors.divider}`,
    padding: '8px 0px',
    borderRadius: '8px',
    visibility: 'hidden',
    zIndex: '300',
    '&.expanded': {
        visibility: 'visible',
    },
    '&::after': {
        content: "''",
        top: '-14px',
        right: '10px',
        border: '7px solid transparent',
        borderBottomColor: colors.white,
        position: 'absolute',
    },
    '&::before': {
        position: 'absolute',
        content: "''",
        top: '-16px',
        right: '9px',
        border: '8px solid transparent',
        borderBottomColor: colors.divider,
    },
});

const MoreVerticalIcon = styled(VarticalDots)({
    height: '16px',
    width: '16px',
});

const MoreButton = styled.button({
    border: 'none',
    background: 'inherit',
    padding: '8px 4px',
    cursor: 'pointer',
});

type Props = {
    children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const HorizontalDotsNav = ({ children, ...rest }: Props) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const navRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navRef.current && !navRef.current.contains(event.target)) {
                setIsExpanded(false);
            }
        };
        // Bind the event listener
        document.addEventListener('click', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('click', handleClickOutside);
        };
    }, [navRef]);

    return (
        <MoreVerticalNav {...rest} ref={navRef}>
            <MoreButton onClick={() => setIsExpanded((prev) => !prev)}>
                <MoreVerticalIcon />
            </MoreButton>
            <MoreNavContainer onClick={() => setIsExpanded(false)} className={isExpanded ? 'expanded' : ''}>
                {children}
            </MoreNavContainer>
        </MoreVerticalNav>
    );
};

export default HorizontalDotsNav;
