import React, { FC, useEffect, useState } from 'react';
import ListItem, { CheckboxWrapper, SelectItem } from './ListItem';
import { FileDetails, FolderDetails } from '@app/api/models/Folders';
import styled from '@emotion/styled';
import Spinner from '../../../../components/Spinner/Spinner';
import { L } from '../../../../lib/i18n';
import ToParentListItem from './ToParentListItem';
import { Icon, Input, colors, typography } from '@ourliving/ourliving-ui';
import { sortDocumentList } from './helpers/sortDocumentList';
import { useLocalStorage } from 'react-use';
import { useLocation } from 'react-router-dom-v5-compat';
import useMoveItems from '../../hooks/both/useMoveItems';
import useDeleteFilesAndFolders from '../../hooks/both/useDeleteFilesAndFolders';
import DeleteDialog from '../DeleteDialog';
import Dialog from '../../../../components/Dialog/Dialog';
import {
    UpdateDuplicateResponse,
    UpdateDuplicateResponseFile,
    UpdateDuplicateResponseFolder,
} from '@app/api/public/DocumentsApi';
import DuplicateDialog from '../dropzone/DuplicateDialog';

const Wrapper = styled.div({
    maxHeight: '50vh',
    overflowY: 'auto',
});

const SListHeader = styled.div({
    userSelect: 'none',
    borderBottom: '1px solid #CCC',
    color: 'black',
    fontWeight: 'bold',
    width: '100%',
    height: 'fit-content',
    padding: '0.3rem',
    paddingTop: '1rem',
    paddingLeft: '0.3rem',
    display: 'grid',
    gridTemplateColumns:
        '2rem 2rem minmax(15rem,1fr) minmax(9rem,15rem) minmax(9rem,15rem) minmax(5rem,10rem) minmax(2rem,4rem) 1rem',
    gap: '1rem',
});

const ListHeaderText = styled.div({
    color: colors.textColor.bellBlack,
    ...typography.bodyBold1,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: '0.2rem',
});

const SContentWrapper = styled.div({
    margin: 0,
    padding: 0,
    position: 'relative',
    minHeight: '40vh',
});

const SContentLoading = styled.div({
    margin: 0,
    padding: 0,
    position: 'absolute',
    zIndex: 1,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(230, 230, 230, 0.5)',
});

const SLoadingText = styled.span({
    paddingRight: '.5rem',
    fontSize: '1.2rem',
});

const SList = styled.ul({
    position: 'relative',
    zIndex: 0,
    marginTop: '0.5rem',
});

const SEmpty = styled.div({
    width: '100%',
    textAlign: 'center',
    paddingTop: '2rem',
});

const SearchResult = styled.li({
    textAlign: 'center',
});

export type SortBy = undefined | 'name' | 'created_at' | 'created_by' | 'size' | 'type';
export type SortDirection = 'default' | 'asc' | 'desc';

type DocumentListProps = {
    folderPath?: string;
    files: FileDetails[];
    folders: FolderDetails[];
    onRenameSuccess: () => void;
    onDeleteSuccess: () => void;
    isLoading: boolean;
    refetch: () => void;
    searchQuery?: string;
    showSelectDelete: (boolean: boolean) => void;
    showDeleteSelectedDialog: boolean;
    hideDeleteSelectedDialog: (bool: boolean) => void;
};

const DocumentList: FC<DocumentListProps> = ({
    folderPath,
    files,
    folders,
    onRenameSuccess,
    onDeleteSuccess,
    isLoading,
    refetch,
    searchQuery = '',
    showSelectDelete,
    showDeleteSelectedDialog,
    hideDeleteSelectedDialog,
}) => {
    const [combinedData, setCombinedData] = useState<(FolderDetails | FileDetails)[] | null>(null);
    const [sortBy, setSortBy] = useLocalStorage<SortBy>('documents_sort_by', undefined);
    const [sortDirection, setSortDirection] = useLocalStorage<SortDirection>('documents_sort_direction', 'default');
    const url = useLocation().pathname;

    useEffect(() => {
        folders.forEach((folder) => (folder.type = 'folder'));
        files.forEach((file) => (file.type = 'file'));
        setCombinedData([...folders, ...files]);
    }, [folders, files]);

    // sorting
    const handleSortArrows = (sortDirection: SortDirection) => {
        switch (sortDirection) {
            case 'default':
                return <></>;
            case 'asc':
                return <Icon.AngleUp />;
            case 'desc':
                return <Icon.AngleDown />;
        }
    };

    const changeSorting = (by: SortBy) => {
        if (by == sortBy) {
            switch (sortDirection) {
                case 'default':
                    setSortBy(by);
                    setSortDirection('asc');
                    break;
                case 'asc':
                    setSortBy(by);
                    setSortDirection('desc');
                    break;
                default:
                    setSortBy(by);
                    setSortDirection('default');
                    break;
            }
        } else {
            setSortBy(by);
            setSortDirection('asc');
        }
    };

    useEffect(() => {
        setCombinedData(sortDocumentList(sortBy, sortDirection || 'default', files, folders));
    }, [sortBy, sortDirection, files, folders]);

    // selection
    const [allSelected, setAllSelected] = useState(false);
    const [selectedItems, setSelectedItems] = useState<SelectItem[]>([]);
    const { mutate: deleteSelectedItems, isLoading: isLoadingDeleteSelectedItems } = useDeleteFilesAndFolders();

    useEffect(() => {
        if (selectedItems.length === combinedData?.length && combinedData.length !== 0) {
            setAllSelected(true);
        } else {
            setAllSelected(false);
        }
    }, [selectedItems, selectedItems.length, combinedData, combinedData?.length]);

    // display delete button
    useEffect(() => {
        if (selectedItems.length) {
            showSelectDelete(true);
        } else {
            showSelectDelete(false);
        }
    }, [selectedItems]);

    const handleSelectAll = (isChecked: boolean) => {
        setAllSelected(isChecked);
        if (isChecked) {
            setSelectedItems(combinedData?.map((item) => ({ ...item })) || []);
        } else {
            setSelectedItems([]);
        }
    };

    // reset selection when combinedData or url changes
    const resetSelection = () => {
        setAllSelected(false);
        setSelectedItems([]);
    };
    useEffect(() => {
        resetSelection();
    }, [url]);

    // move file/folder (drag & drop)
    const [draggingItem, setDraggingItem] = useState<FileDetails | FolderDetails | null>(null);
    const [targetItem, setTargetItem] = useState<Partial<FolderDetails> | null>(null);
    const { mutate: moveItems, isLoading: moveItemsLoading } = useMoveItems();

    const [showItem, setShowItem] = useState(false);
    const [activeItems, setActiveItems] = useState<{
        files: UpdateDuplicateResponseFile[];
        folders: UpdateDuplicateResponseFolder[];
    } | null>(null);

    const showDuplicateDialog = (files: UpdateDuplicateResponseFile[], folders: UpdateDuplicateResponseFolder[]) => {
        setActiveItems({ files, folders });
        setShowItem(true);
    };

    useEffect(() => {
        if (!draggingItem || !targetItem || targetItem.id === draggingItem.id) return;

        // if dragging a item that is NOT selected
        if (!selectedItems.some((i) => i.id == draggingItem.id && i.type == draggingItem.type)) {
            // update IF file
            if (draggingItem.type === 'file') {
                moveItems(
                    { items: [draggingItem], newParentId: targetItem.id ?? null },
                    {
                        onSettled(_data, err) {
                            if (
                                (err as UpdateDuplicateResponse)?.status === 'fail' &&
                                (err as UpdateDuplicateResponse).message === L('duplicates_found')
                            ) {
                                return showDuplicateDialog(
                                    (err as UpdateDuplicateResponse).data.files,
                                    (err as UpdateDuplicateResponse).data.folders
                                );
                            }

                            setSelectedItems((prevItems) =>
                                prevItems.filter((i) => !(i.id === draggingItem.id && i.type === draggingItem.type))
                            );
                            resetSelection();
                            refetch();
                        },
                    }
                );
            }
            // update IF folder
            if (draggingItem.type === 'folder') {
                moveItems(
                    { items: [draggingItem], newParentId: targetItem.id ?? null },
                    {
                        onSettled(_data, err) {
                            if (
                                (err as UpdateDuplicateResponse)?.status === 'fail' &&
                                (err as UpdateDuplicateResponse).message === L('duplicates_found')
                            ) {
                                return showDuplicateDialog(
                                    (err as UpdateDuplicateResponse).data.files,
                                    (err as UpdateDuplicateResponse).data.folders
                                );
                            }

                            setSelectedItems((prevItems) =>
                                prevItems.filter((i) => !(i.id === draggingItem.id && i.type === draggingItem.type))
                            );
                            resetSelection();
                            refetch();
                        },
                    }
                );
            }
        } else {
            // if dragging a item that IS selected (also drags & updates other selected items)
            if (selectedItems.find((i) => i.id === targetItem.id && targetItem.type === 'folder')) return;

            moveItems(
                { items: selectedItems, newParentId: targetItem.id ?? null },
                {
                    onSettled(_data, err) {
                        if (
                            (err as UpdateDuplicateResponse)?.status === 'fail' &&
                            (err as UpdateDuplicateResponse).message === L('duplicates_found')
                        ) {
                            return showDuplicateDialog(
                                (err as UpdateDuplicateResponse).data.files,
                                (err as UpdateDuplicateResponse).data.folders
                            );
                        }

                        resetSelection();
                        refetch();
                    },
                }
            );
        }

        setDraggingItem(null);
    }, [targetItem]);

    return (
        <Wrapper>
            {showItem && activeItems && (
                <DuplicateDialog
                    onClose={() => setShowItem(false)}
                    files={activeItems.files}
                    folders={activeItems.folders}
                    onReplace={() =>
                        moveItems(
                            {
                                items: [...activeItems.files, ...activeItems.folders],
                                newParentId: targetItem?.id ?? null,
                                duplicateAction: 'replace',
                            },
                            {
                                onSettled: () => {
                                    resetSelection();
                                    refetch();
                                },
                            }
                        )
                    }
                    onSkip={() =>
                        moveItems(
                            {
                                items: [...activeItems.files, ...activeItems.folders],
                                newParentId: targetItem?.id ?? null,
                                duplicateAction: 'skip',
                            },
                            {
                                onSettled: () => {
                                    // DEN RESETTAR INTE SELECTED ITEMS SOM MAN SKIPPAR.
                                    resetSelection();
                                    refetch();
                                },
                            }
                        )
                    }
                />
            )}

            {showDeleteSelectedDialog && (
                <Dialog open={showDeleteSelectedDialog} onOpenChange={() => hideDeleteSelectedDialog(false)}>
                    <DeleteDialog
                        onDelete={() => {
                            deleteSelectedItems(selectedItems, {
                                onSuccess: () => {
                                    hideDeleteSelectedDialog(false);
                                    refetch();
                                    setSelectedItems([]);
                                },
                            });
                        }}
                        onCancel={() => hideDeleteSelectedDialog(false)}
                        isLoading={isLoadingDeleteSelectedItems}
                        title={L('delete_selected_items')}
                        warningMessage={L('delete_selected_items_warning')}
                    />
                </Dialog>
            )}

            <SListHeader>
                <ListHeaderText
                    // allows checkbox to function normally
                    onClick={(e) => e.stopPropagation()}
                    style={{ pointerEvents: 'all', zIndex: 0 }}
                >
                    <CheckboxWrapper style={{ opacity: '1', pointerEvents: 'all' }}>
                        <Input.Checkbox checked={allSelected} onChange={(e) => handleSelectAll(e.target.checked)} />
                    </CheckboxWrapper>
                </ListHeaderText>
                <ListHeaderText />
                <ListHeaderText onClick={() => changeSorting('name')}>
                    {L('name')} {sortBy === 'name' ? handleSortArrows(sortDirection || 'default') : <></>}
                </ListHeaderText>
                <ListHeaderText onClick={() => changeSorting('created_at')}>
                    {L('created_at')} {sortBy === 'created_at' ? handleSortArrows(sortDirection || 'default') : <></>}
                </ListHeaderText>
                <ListHeaderText onClick={() => changeSorting('created_by')}>
                    {L('created_by')} {sortBy === 'created_by' ? handleSortArrows(sortDirection || 'default') : <></>}
                </ListHeaderText>
                <ListHeaderText onClick={() => changeSorting('size')}>
                    {L('size')} {sortBy === 'size' ? handleSortArrows(sortDirection || 'default') : <></>}
                </ListHeaderText>
                <ListHeaderText onClick={() => changeSorting('type')}>
                    {L('type')} {sortBy === 'type' ? handleSortArrows(sortDirection || 'default') : <></>}
                </ListHeaderText>
            </SListHeader>
            <SContentWrapper>
                {isLoading ||
                    (moveItemsLoading && (
                        <SContentLoading>
                            <SLoadingText>{L('loading')}...</SLoadingText> <Spinner />
                        </SContentLoading>
                    ))}

                {(combinedData || searchQuery) && (
                    <SList>
                        {searchQuery && (
                            <SearchResult>
                                {L('documents_search_results')}: <i>&quot;{searchQuery}&quot;</i>
                            </SearchResult>
                        )}
                        <ToParentListItem
                            folderPath={searchQuery ? ' ' : folderPath || ''}
                            targetItem={(item) => {
                                if (item.id === 0) item.id = undefined;
                                setTargetItem(item);
                            }}
                            searchMode={!!searchQuery}
                        />

                        {combinedData &&
                            combinedData.map((item) => (
                                <ListItem
                                    isSelected={
                                        allSelected ||
                                        selectedItems.find((i) => i.id === item.id && i.type === item.type)
                                            ? true
                                            : false
                                    }
                                    onItemSelect={(item) => {
                                        if (!selectedItems.some((i) => i.id === item.id && i.type === item.type)) {
                                            setSelectedItems((prevItems) => [...prevItems, item]);
                                        }
                                    }}
                                    onItemUnselect={(item) => {
                                        setSelectedItems((prevItems) =>
                                            prevItems.filter((i) => !(i.id === item.id && i.type === item.type))
                                        );
                                    }}
                                    draggedItem={(item) => setDraggingItem(item)}
                                    targetItem={(item) => setTargetItem(item)}
                                    currentlyDragging={
                                        (draggingItem && draggingItem?.id === item.id) ||
                                        (draggingItem &&
                                            selectedItems.some((i) => i.id === item.id && i.type === item.type) &&
                                            selectedItems.some(
                                                (i) => i.id === draggingItem.id && i.type === draggingItem.type
                                            ))
                                            ? true
                                            : false
                                    }
                                    key={item.id}
                                    item={item}
                                    onRenameSuccess={onRenameSuccess}
                                    onDeleteSuccess={onDeleteSuccess}
                                />
                            ))}
                        {combinedData && !combinedData.length && !isLoading && (
                            <>
                                {searchQuery ? (
                                    <SEmpty>
                                        <Icon.CQuestion width={'2.5rem'} />
                                        <p>{L('no_results')}</p>
                                    </SEmpty>
                                ) : (
                                    <SEmpty>
                                        <Icon.File width={'2.5rem'} />
                                        <p>{L('no_documents')}</p>
                                    </SEmpty>
                                )}
                            </>
                        )}
                    </SList>
                )}
            </SContentWrapper>
        </Wrapper>
    );
};

export default DocumentList;
