import React from 'react';
import styled from '@emotion/styled';
import DocumentListById from '../DocumentListById';
import PreviewFooter from './PreviewFooter';
import PreviewNavbar from './PreviewNavbar';
import PreviewHero from './PreviewHero';
import PreviewVerticalLine from './PreviewVerticalLine';
import { useTheme } from 'emotion-theming';
import { PreviewThemeType } from '../../../../areas/public-site/theme/ThemePage';
import useGetByPageType from '../../hooks/useGetByPageType';
import PreviewHomeText from './PreviewHomeText';
import AddressCard from './AddressCard';
import useGetDocumentsById from '../../../../areas/public-site/hooks/useGetDocumentsById';

const Container = styled.div(({ theme }: { theme: PreviewThemeType }) => {
    return {
        height: '700px',
        overflowY: 'scroll',
        backgroundColor: theme.colors.pageBackground,
    };
});

const GridContainer = styled.div({
    display: 'grid',
    gridTemplateColumns: '3fr 0.1fr 0.9fr',
    gridTemplateAreas: `
    "Main VerticalLine Documents"
    `,
});

const PreviewPage = ({ id }: { id: number }) => {
    const { data: documents } = useGetDocumentsById(id);
    const { data: homePageData } = useGetByPageType('home_page');

    const theme = useTheme<PreviewThemeType>();
    return (
        <Container theme={theme}>
            <PreviewNavbar />
            {homePageData && <PreviewHero pageListItem={homePageData} />}
            {documents && documents.length > 0 ? (
                <>
                    <GridContainer>
                        <PreviewHomeText />
                        <PreviewVerticalLine />
                        <DocumentListById id={id} />
                    </GridContainer>
                </>
            ) : (
                <PreviewHomeText />
            )}
            <AddressCard />
            <PreviewFooter />
        </Container>
    );
};

export default PreviewPage;
