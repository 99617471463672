import { MemberFilter } from '@app/api/models/Members';
import styled from '@emotion/styled';
import React from 'react';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import Filters from '../../../components/Filters/Filters';
import { Loading } from '../../../components/Loading/Loading';
import { L } from '../../../lib/i18n';
import useGetProperties from '../hooks/useGetProperties';
import GlobalFilter from '../../../components/Table/filterComponents/GlobalFilter';
import { tooltip } from '../../../components/Shared/Style/typography';
import useGetMemberList from '../hooks/useGetMemberList';

const StyledDropdown = styled(Dropdown)({
    marginRight: '1rem',
});

const StyledFilters = styled(Filters)({
    marginTop: '0rem',
});

const Statistic = styled.p(({ theme }) => ({
    ...tooltip,
    color: theme.colors.textColor1,
}));

type Props = {
    setFilter: React.Dispatch<React.SetStateAction<MemberFilter>>;
    filter: MemberFilter;
    setGlobalFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
    globalFilter: string;
};

const FilterArea: React.FC<Props> = ({ setFilter, filter, globalFilter, setGlobalFilter }) => {
    const { data: properties } = useGetProperties();
    const { data: members } = useGetMemberList();

    if (!properties) return <Loading />;
    const totalMembers = members?.length || 0;
    const accepted = members?.filter((m) => m.confirmed_at).length || 0;
    const percent = (accepted / totalMembers) * 100;

    return (
        <div
            style={{
                justifyContent: 'space-between',
                alignItems: 'start',
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                paddingBottom: '2rem',
            }}
        >
            <StyledFilters>
                <StyledDropdown
                    isClearable
                    label={'Bostad'}
                    onChange={(value) => setFilter({ ...filter, property: value })}
                    value={filter.property}
                    options={
                        properties?.map((property) => {
                            return {
                                value: property.id,
                                label: property.name,
                            };
                        }) || []
                    }
                />
                <StyledDropdown
                    isClearable
                    label={'Status'}
                    onChange={(value) => setFilter({ ...filter, accepted: value })}
                    value={filter.accepted}
                    options={
                        [
                            { value: 'Bekräftat', label: 'Bekräftat' },
                            { value: 'Inbjuden', label: 'Inbjuden' },
                            { value: 'Ej inbjuden', label: 'Ej inbjuden' },
                        ]?.map((accepted) => {
                            return {
                                value: accepted.value,
                                label: L(`${accepted.label}`),
                            };
                        }) || []
                    }
                />
            </StyledFilters>

            <div style={{ display: 'flex', gap: '1rem', alignItems: 'start', justifyContent: 'space-between' }}>
                <div>
                    <Statistic>
                        {L('total_members')}: {totalMembers}
                    </Statistic>
                    <Statistic>
                        {L('accepted_members')}: {accepted} ({Math.round(percent) || 0}%)
                    </Statistic>
                </div>
                <GlobalFilter placeholder="Sök" filter={globalFilter} setFilter={setGlobalFilter} />
            </div>
        </div>
    );
};

export default FilterArea;
