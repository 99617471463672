import styled from '@emotion/styled';
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { Loading } from '../../../components/Loading/Loading';
import { style } from '../../../components/Shared/Style';
import CardTitle from '../../../components/typography/CardTitle';
import useGetProfile from '../../../hooks/useGetProfile';
import { L } from '../../../lib/i18n';
import { useGetTicketMessages, useGetTicket } from '../hooks/ticketQueries';
import useSaveMessage from '../hooks/useSaveMessage';
import AutoGrowingTextarea from '../../../components/Forms/AutoGrowingTextArea';
import AttachmentIcon from '../../../components/Icon/attachment.svg';
import DocumentClick from '../../../components/document/DocumentDropdown/DocumentDropdown';
import useGetBoardMembers from '../../board-members/hooks/useGetBoardMembers';
import useSetLastReadThread from '../hooks/useSetLastReadThread';
import CustomerMessages from './CustomerMessages';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import useGetProjectInfo from '../../../hooks/useGetProjectInfo';

dayjs.extend(isBetween);

const InvisibleTextArea = styled(AutoGrowingTextarea)({
    backgroundColor: 'transparent',
    border: 'none',
    padding: '0px',
});
const FakeInput = styled.div(({ theme }) => ({
    background: theme.colors.inputBackground,
    border: `1px solid ${theme.colors.inputBorder}`,
    width: '100%',
    borderRadius: style.roundCornerSize.small,
    outline: 'none',
    padding: style.margin.s,
    fontSize: style.fontSize.seta,
    resize: 'vertical',
    '&:read-only': {
        color: theme.colors.inputPlaceholderColor,
        cursor: 'default',
    },
}));

const StyledAttachmentIcon = styled(AttachmentIcon)({
    height: '24px',
    width: '24px',
});

const CustomerCommunication = ({ readOnly }: { readOnly?: boolean }) => {
    const { id } = useParams<{ id: string }>();
    const { data: profile } = useGetProfile();
    const { data: project } = useGetProjectInfo();

    const { data: ticket } = useGetTicket(id);
    const [message, setMessage] = React.useState('');
    const { data } = useGetTicketMessages();
    const { mutate, status } = useSaveMessage();
    const [files, setFiles] = React.useState<File[]>([]);
    const { mutate: setLastReadThread } = useSetLastReadThread();
    const { data: boardMembers } = useGetBoardMembers();
    const boardMembersIds = boardMembers?.map((member) => member.user_id) ?? [];

    const ticketCardTitle = ticket?.ticket_no;
    const lastReadRef = useRef('');

    useEffect(() => {
        if (lastReadRef.current) return;
        if (!ticket?.residence_last_read) return;
        lastReadRef.current = ticket?.residence_last_read;
    }, [ticket]);
    useEffect(() => {
        setLastReadThread({ ticketId: +id, threadType: 'residence' });
        return () => {
            setLastReadThread({ ticketId: +id, threadType: 'residence' });
        };
    }, [id]);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        mutate(
            {
                message: {
                    text: {
                        plain_text: message,
                    },
                },
                files: files,
                ticketId: +id,
                thread_type: 'residence',
            },
            {
                onSuccess: () => {
                    setMessage('');
                    setFiles([]);
                },
            }
        );
    };

    const Documents = files.map((file) => {
        const url = URL.createObjectURL(file);
        const handleDelete = () => {
            setFiles(files.filter((item) => item.name !== file.name));
        };
        return (
            <DocumentClick
                key={file.name}
                fileType={file.type}
                url={url}
                name={file.name}
                deleteDocument={handleDelete}
            />
        );
    });

    const handleAddAttachments = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const eventFiles = Array.from(e.target.files);
            const newFiles = files.filter((file) => !eventFiles.some((item) => item.name === file.name));
            setFiles([...newFiles, ...eventFiles]);
        }
    };

    const Chat = profile?.id ? (
        <CustomerMessages
            boardMemberIds={boardMembersIds}
            lastRead={lastReadRef.current}
            messages={data || []}
            profileId={profile.id}
        />
    ) : (
        <Loading />
    );
    return (
        <CardWithDivider
            topArea={
                <CardTitle>
                    {`#${project?.id} - ${ticketCardTitle}`} {L('customer_communication')}
                </CardTitle>
            }
            mainArea={
                <div>
                    {Chat}
                    {!readOnly && (
                        <form onSubmit={onSubmit} style={{ display: 'grid', gap: '0.5rem', marginTop: '0.5rem' }}>
                            <FakeInput>
                                <InvisibleTextArea
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    placeholder={L('chat_placeholder')}
                                />
                                {Documents}
                            </FakeInput>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <label style={{ cursor: 'pointer' }}>
                                    <input type={'file'} style={{ display: 'none' }} onChange={handleAddAttachments} />
                                    <StyledAttachmentIcon />
                                </label>
                                <Button
                                    disabled={!message && !files.length}
                                    variant="filledPrimary"
                                    isLoading={status === 'loading'}
                                    style={{ placeSelf: 'end' }}
                                >
                                    {L('send')}
                                </Button>
                            </div>
                        </form>
                    )}
                </div>
            }
        />
    );
};

export default CustomerCommunication;
