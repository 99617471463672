import React from 'react';
import { Route } from 'react-router-dom';
import { MembersPage } from './page/MembersPage';

export const routes = [
    <Route key="1" path="/members/:id" component={MembersPage} />,
    <Route key="0" path="/members" component={MembersPage} />,
];

export const navigation = { name: 'members', link: '/members', icon: 'Member', parent: 'Members' };
