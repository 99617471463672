import ContentNewsApi from '@app/api/public/ContentNewsApi';
import { useQuery } from 'react-query';

export const memberNewsQueryKeys = {
    all: [{ scope: 'memberNews' }] as const,
    lists: () => [{ ...memberNewsQueryKeys.all[0], entity: 'list' }] as const,
    list: (type: 'draft' | 'published', page: number) => [{ ...memberNewsQueryKeys.lists()[0], type, page }] as const,
    infiniteLists: () => [{ ...memberNewsQueryKeys.all[0], entity: 'infiniteList' }] as const,
    infiniteList: (type: 'draft' | 'published', page: number) =>
        [{ ...memberNewsQueryKeys.infiniteLists()[0], type, page }] as const,
    details: () => [{ ...memberNewsQueryKeys.all[0], entity: 'detail' }] as const,
    detail: (id: number) => [{ ...memberNewsQueryKeys.details()[0], id }] as const,
    documents: () => [{ ...memberNewsQueryKeys.all[0], entity: 'document' }] as const,
    document: (id: number) => [{ ...memberNewsQueryKeys.documents()[0], id }] as const,
};

const getPageListItems = async (type: 'draft' | 'published', page: number) => {
    return await ContentNewsApi.list(type, page);
};

const useGetContentNewsList = (type: 'draft' | 'published', page: number) => {
    return useQuery({
        queryFn: ({ queryKey }) => {
            const { type } = queryKey[0];
            return getPageListItems(type, page);
        },
        queryKey: memberNewsQueryKeys.list(type, page),
    });
};

const getContentNews = (id: number) => {
    return ContentNewsApi.get(id);
};

const useGetMemberNewsById = (id: number) => {
    return useQuery({
        queryFn: ({ queryKey }) => getContentNews(queryKey[0].id),
        queryKey: memberNewsQueryKeys.detail(id),
        enabled: !!id,
    });
};

export { useGetContentNewsList, useGetMemberNewsById };
