import { useEffect } from 'react';
import { useGetOutgoingMail, useSaveDraft } from './mailQueries';
import { MailMessage } from '@app/api/lib/mail';
import toast from 'react-hot-toast';
import { L } from '../../../lib/i18n';
import { useHistory } from 'react-router';
import { useQueryClient } from 'react-query';
import { getMailKeys } from '@app/shared/queryKeys/mail';

export default function useSaveMail(
    subject: string,
    editorValue: string | undefined,
    recipients: string[],
    reply_to_id: number | undefined
) {
    const saveDraft = useSaveDraft();
    const searchParams = new URLSearchParams(location.search);
    const draftId = searchParams.get('draftid');
    const queryClient = useQueryClient();
    const history = useHistory();

    const { data: draftMail, isLoading } = useGetOutgoingMail(Number(draftId));
    useEffect(() => {
        const interval = setInterval(() => {
            if (
                draftMail?.subject &&
                draftMail?.message &&
                subject === draftMail?.subject &&
                editorValue === draftMail?.message &&
                recipients?.length === draftMail?.sent_to?.length
            ) {
                return;
            }

            if (editorValue?.length && editorValue && editorValue?.length > 0 && recipients?.length > 0) {
                const mailToSave: MailMessage = {
                    from: 'Mejlfunktion',
                    recipients: recipients ?? [],
                    subject: subject,
                    html: editorValue,
                    text: '',
                    replyTo: '',
                };
                saveDraft.mutate(
                    { mail: mailToSave, existingId: draftMail?.id ?? null, reply_to_id },
                    {
                        onSuccess: (res) => {
                            queryClient.invalidateQueries(getMailKeys.getByIds());

                            //Save in params
                            searchParams.set('draftid', String(res?.id));
                            history.push({ search: searchParams.toString() });
                        },
                        onError: () => {
                            toast.error(L('mail_error'));
                        },
                    }
                );
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [subject, editorValue, draftMail, recipients.length]);
    return { draftMail, isLoading };
}
