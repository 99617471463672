import { ContentNewsItem } from '@app/api/models/ContentNews/ContentNewsModels';
import ContentNewsApi from '@app/api/public/ContentNewsApi';
import { useMutation, useQueryClient } from 'react-query';
import { memberNewsQueryKeys } from './memberNewsQueries';

type Variables = { id: ContentNewsItem['id'] };

const useRemoveNews = () => {
    const client = useQueryClient();
    return useMutation(
        (varables: Variables) => {
            return ContentNewsApi.remove(varables.id);
        },
        {
            onSettled: () => {
                client.invalidateQueries(memberNewsQueryKeys.all);
            },
        }
    );
};

export default useRemoveNews;
