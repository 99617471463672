import { BoardMemberDetail, BoardMemberSubRoleType, NewBoardMember } from '@app/api/public/BoardMembersApi';
import styled from '@emotion/styled';
import toast from 'react-hot-toast';
import React, { useState } from 'react';
import { Button } from '../../../components/Buttons/Button';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import Label from '../../../components/Forms/Label';
import { L } from '../../../lib/i18n';
import { UseMutateFunction } from 'react-query';
import { useModal } from '../../../components/Modal/Context/ModalContext';
import Input from '../../../components/Forms/Input';
import useGetMemberList from '../../members/hooks/useGetMemberList';
import { useLocalStorage } from 'react-use';
import DropdownButton from '../../../components/Buttons/DropdownButton';

type Props = {
    createBoardMember: UseMutateFunction<BoardMemberDetail | undefined, unknown, NewBoardMember & {}, unknown>;
    roles: {
        label: any;
        value: string;
    }[];
    subRoles: BoardMemberSubRoleType[];
    status: 'error' | 'success' | 'idle' | 'loading';
    resetCreate: () => void;
    projectType?: string;
};

const ButtonWrapper = styled.div({
    padding: '1rem 0px 0px 0px',
    display: 'flex',
    gap: '1rem',
    right: '2rem',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: '1rem',
});

const Form = styled.form({});

function NewMember({ createBoardMember, roles, subRoles, status, resetCreate, projectType }: Props) {
    const { data: users } = useGetMemberList();
    const [boardMember, setBoardMember] = useState<NewBoardMember>({
        name: '',
        email: '',
        phone_number: '',
        role: '',
        sub_role: '',
        nameEnabled: true,
    });

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>, name: 'email') => {
        setBoardMember({ ...boardMember, [name]: e.target.value });
        if (name === 'email') {
            if (validateEmail(e.target.value)) {
                const user = users?.filter((user) => user.email.toLowerCase() === e.target.value.toLowerCase());
                user && user.length > 0
                    ? setBoardMember({ ...boardMember, nameEnabled: false, [name]: e.target.value, name: user[0].name })
                    : setBoardMember({ ...boardMember, nameEnabled: true, [name]: e.target.value, name: '' });
            } else {
                setBoardMember({ ...boardMember, nameEnabled: true, [name]: e.target.value, name: '' });
            }
        }
    };

    const [localSubmitButtonState, setSubmitButtonState] = useLocalStorage<'save' | 'save_and_invite'>(
        'newPropertyMember',
        'save_and_invite'
    );
    const submitButtonState = localSubmitButtonState || 'save_and_invite';

    const { ToggleIsModalOpen } = useModal();

    const submit = (type: 'save' | 'save_and_invite') => {
        if (!boardMember) return;
        const bm = {
            name: boardMember?.name,
            email: boardMember?.email,
            role: boardMember?.role,
            sub_role: boardMember?.sub_role,
            phone_number: boardMember?.phone_number,
            nameEnabled: boardMember?.nameEnabled,
            invite: type === 'save' ? false : true,
        };
        if (type === 'save') {
            if (bm.email && bm.name && bm.role) {
                createBoardMember(bm, {
                    onSuccess: () => {
                        toast.success(`${L('member')} ${bm.email} ${L('saved')}`);
                        ToggleIsModalOpen();
                    },
                });
            }
        } else if (type === 'save_and_invite') {
            if (bm.email && bm.name && bm.role) {
                createBoardMember(bm, {
                    onSuccess: () => {
                        toast.success(`${L('member')} ${bm.email} ${L('saved')}`);
                        ToggleIsModalOpen();
                    },
                });
            }
        }
    };

    return (
        <Form>
            <Label title={`${L('member_account')}*`}>
                <Input
                    placeholder={L('email')}
                    name="email"
                    type="email"
                    value={boardMember.email}
                    onChange={(e) => {
                        resetCreate();
                        handleEmailChange(e, 'email');
                    }}
                />
            </Label>
            <Label title={`${L('name')}*`}>
                <Input
                    disabled={boardMember.nameEnabled ? false : true}
                    placeholder={L('name')}
                    name="name"
                    value={boardMember.name}
                    onChange={(e) => {
                        resetCreate();
                        const name = e.target.value;
                        setBoardMember((prev) => ({ ...prev, name: name }));
                    }}
                />
            </Label>
            <Label title={`${L('boardmember_role')}*`}>
                <Dropdown
                    placeholder={L('boardmember_role')}
                    name={'role'}
                    options={roles}
                    value={boardMember.role}
                    onChange={(value) => {
                        resetCreate();
                        setBoardMember((prev) => ({ ...prev, role: value }));
                    }}
                />
            </Label>
            {projectType === 'housing_society' && (
                <Label title={`${L('boardmember_subrole')}*`}>
                    <Dropdown
                        placeholder={L('boardmember_subrole')}
                        name={'sub_role'}
                        options={
                            subRoles?.map((subRole) => {
                                return {
                                    value: subRole,
                                    label: L(`${subRole}`),
                                };
                            }) || []
                        }
                        value={boardMember.sub_role}
                        onChange={(value) => {
                            resetCreate();
                            setBoardMember((prev) => ({ ...prev, sub_role: value }));
                        }}
                    />
                </Label>
            )}
            <Label title={L('telephone_number')}>
                <Input
                    placeholder={L('phone_number')}
                    name={L('telephone_number')}
                    value={boardMember.phone_number}
                    onChange={(e) => {
                        resetCreate();
                        const phone_number = e.target.value;
                        setBoardMember((prev) => ({ ...prev, phone_number: phone_number }));
                    }}
                />
            </Label>

            <ButtonWrapper>
                <Button type="button" onClick={() => ToggleIsModalOpen()} variant="primary">
                    {L('cancel')}
                </Button>

                <DropdownButton
                    variant="filledPrimary"
                    setOptionId={(state) => {
                        setSubmitButtonState(state);
                    }}
                    disabled={boardMember.name && boardMember.email && boardMember.role ? false : true}
                    optionId={submitButtonState}
                    options={[
                        {
                            dropdownItem: <DropdownButton.DropdownItem>{L('add_admin')}</DropdownButton.DropdownItem>,
                            id: 'save',
                            button: (
                                <DropdownButton.Button onClick={() => submit('save')}>
                                    {L('add_admin')}
                                </DropdownButton.Button>
                            ),
                        },
                        {
                            dropdownItem: (
                                <DropdownButton.DropdownItem>{L('save_admin_and_invite')}</DropdownButton.DropdownItem>
                            ),
                            id: 'save_and_invite',
                            button: (
                                <DropdownButton.Button onClick={() => submit('save_and_invite')}>
                                    {L('save_admin_and_invite')}
                                </DropdownButton.Button>
                            ),
                        },
                    ]}
                />
            </ButtonWrapper>
        </Form>
    );
}

export default NewMember;
