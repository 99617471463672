import React from 'react';
import { ListingMember } from '@app/api/models/Members';
import styled from '@emotion/styled';
import { StyleColumn } from '../../../types/ColumnType';
import { Link } from 'react-router-dom';
import { style } from '../../../components/Shared/Style';
import dayjs from 'dayjs';
import sv from 'dayjs/locale/sv';
import { L } from '../../../lib/i18n';
import { colors } from '../../../components/Shared/Style/colors';
import { margin } from '../../../components/Shared/Style/margin';
dayjs.locale(sv);

const Span = styled.span({
    textAlign: 'left',
    fontSize: '14px',
});

const Properties = styled.div({
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    fontSize: style.fontSize.milli,
    textAlign: 'left',
    gap: '0.5rem',
    'span:not(:last-child):after': {
        content: `","`,
    },
});

const StyledNavLink = styled(Link)({
    textDecoration: 'none',
    color: 'inherit',
});

const StyledP = styled.p({
    color: colors.black80,
    marginTop: margin.s,
    fontSize: '12px',
});

export const COLUMNS: StyleColumn<ListingMember>[] = [
    {
        Header: 'Namn och mail',
        accessor: (value) => [value.email, value.name],
        width: 175,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const member = row.original;
            return (
                <StyledNavLink to={`/members/${member.id}`}>
                    <Span>{member.name}</Span>
                    <StyledP>{member.email}</StyledP>
                    <StyledP>{member.phone_number}</StyledP>
                </StyledNavLink>
            );
        },
    },
    {
        Header: 'Bostad',
        accessor: (value) => (value.property_id, value.property_name),
        width: 225,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const member = row.original;
            return (
                <Properties>
                    <Span key={member.property_id}>{member.property_name}</Span>
                </Properties>
            );
        },
    },
    {
        Header: () => L('account_confirmed'),
        id: 'Accepted',
        accessor: (originalRow: ListingMember) => {
            return dayjs(originalRow.confirmed_at).format('YYYY-MM-DD');
        },
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const member = row.original;
            return <Span>{member.confirmed_at && dayjs(member.confirmed_at).format('YYYY-MM-DD')}</Span>;
        },
    },
    {
        Header: 'Inbjuden',
        accessor: (originalRow: ListingMember) => {
            return dayjs(originalRow.invited_at).valueOf();
        },
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const member = row.original;
            return <Span>{dayjs(member.invited_at).isValid() && dayjs(member.invited_at).format('YYYY-MM-DD')}</Span>;
        },
    },
];

export const COLUMNSMOBILE: StyleColumn<ListingMember>[] = [
    {
        Header: 'Namn och mail',
        accessor: (value) => [value.email, value.name],
        width: 150,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const member = row.original;
            return (
                <StyledNavLink to={`/members/${member.id}`}>
                    <Span>{member.name}</Span>
                    <StyledP>{member.email}</StyledP>
                </StyledNavLink>
            );
        },
    },

    {
        Header: 'Roll',
        accessor: 'role',
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const member = row.original;
            return <Span>{L(member.role)}</Span>;
        },
    },
];
