export const colors = {
    navbarBackground: '#FFFFFF',
    footerText: '#FFFFFF',
    alertBackground: '#E0E0E0',
    alert_low: '#29CC97',
    alert_medium: '#FEC400',
    alert_high: '#EC6666',
    body: '#F8F8F8F7',
    white: '#FFFFFF',
    black: '#252733',
    black90: '#3E3E3E',
    black80: '#AFAFAF',
    black50: '#C5C7CD',
    black30: '#F0F1F7',
    black10: '#FCFDFE',
    divider: '#DFE0EB',
    grey: '#F5F5F5',
    sticky: '#F5F5F5',
    icons: '#000000',
    fixedBarIcons: 'white',
    impersonateBackground: '#DB7463',
    primary: '#86AE43',
    primary50: '#C2D6A1',
    primary25: '#E4EDD6',
    secondary: '#9FA2B4',
    lightGrayText: '#9FA2B4',
    notification: '#F12B2C',
    newcount: '#2EB67D',
    pageBackground: '#F7F8FC',
    errors: '#FF0000',
    inputBackground: '#FCFDFE',
    inputHoverBackground: '#E3F0FE',
    inputBorder: '#F0F1F7',
    statusDone: '#29CC97',
    statusWarning: '#FEC400',
    statusError: '#F12B2C',
    textColor1: '#9DA3B4',
    textColor2: '#444444',
    tabActive: '#4282FF',
    notificationCount: '#4282FF',
    tabInactive: '#9DA3B4',
    listBorderColor: '#979797',
    none: '',
    resourceBackground: '#1947ff',
    resourceTextColor: '#808080',
    iconContrastBackground: '#E6E6FA',
    radialCompleted: '#86ae43',
    radialHalf: '#FFA500',
    orange: '#FFA500',
    lightOrange: '#FFA500',
    avatarBackground: '#76A4AE',
    formPlaceholder: '#4B506D',
    footerBackground: '#262626',
    reportBackground: '#444343',
    heroTitleBackground: '#4d4d4d',
};

export type Colors = keyof typeof colors;
export type ColorsType = typeof colors;

export const colorsKeyArray = Object.keys(colors).map((key) => key);

export const colorSelect = {
    control: {
        type: 'select',
        options: colorsKeyArray,
    },
};
