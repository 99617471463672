import MembersApi from '@app/api/public/MembersApi';
import { useQuery } from 'react-query';

export const getPropertiesQueryKey = 'getPropertiesMemberKey';

const getProperties = () => {
    return MembersApi.listProperties();
};

const useGetProperties = () => {
    return useQuery([getPropertiesQueryKey], () => getProperties());
};

export { useGetProperties as default };
