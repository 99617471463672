import React, { useState } from 'react';
import { Button, Input, Label } from '@ourliving/ourliving-ui';
import useCreateFolder from '../../hooks/folders/useCreateFolder';
import { useForm, SubmitHandler } from 'react-hook-form';
import { L } from '../../../../lib/i18n';
import Dialog from '../../../../components/Dialog/Dialog';
import { ButtonContainer, StyledForm } from './FormStyles';

type Inputs = {
    folder_name: string;
};

type CreateNewFolderProps = {
    disabled?: boolean;
    openFolder: number | null;
    projectId?: number;
    success: () => void;
};

const CreateNewFolder: React.FC<CreateNewFolderProps> = ({ disabled, openFolder, projectId, success }) => {
    const [showForm, setShowForm] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<Inputs>();
    const { mutate: createFolder, isLoading } = useCreateFolder();

    const onSubmit: SubmitHandler<Inputs> = ({ folder_name }) => {
        if (isLoading || !projectId) return;

        createFolder(
            {
                folder: {
                    name: folder_name.trim(),
                    project_id: projectId,
                    parent_id: openFolder ?? undefined,
                    type: 'folder',
                },
            },
            {
                onSuccess: () => {
                    setShowForm(false);
                    success();
                    setValue('folder_name', '');
                },
            }
        );
    };

    return (
        <>
            <Button
                disabled={disabled}
                variant="secondary"
                className="folder-form-button"
                onClick={() => setShowForm(true)}
            >
                {L('new_folder')}
            </Button>

            <Dialog
                onOpenChange={setShowForm}
                open={showForm}
                content={{
                    style: {
                        width: '400px',
                        padding: '2rem',
                    },
                }}
            >
                <StyledForm className="folder-create-form" onSubmit={handleSubmit(onSubmit)}>
                    <Label htmlFor="folder_name" style={{ width: '100%' }}>
                        {L('folder_name')}
                        <Input.Text
                            disabled={disabled}
                            state={errors.folder_name ? 'error' : 'default'}
                            errorMessage={errors.folder_name && L('name_missing')}
                            {...register('folder_name', { required: true })}
                        />
                    </Label>

                    <ButtonContainer>
                        <Button type="button" variant="secondary" onClick={() => setShowForm(false)}>
                            {L('cancel')}
                        </Button>

                        <Button type="submit" disabled={isLoading || disabled}>
                            {isLoading ? `${L('loading')}...` : L('create_folder')}
                        </Button>
                    </ButtonContainer>
                </StyledForm>
            </Dialog>
        </>
    );
};

export default CreateNewFolder;
