import BookingsApi from '@app/api/public/BookingsApi';
import { useQuery } from 'react-query';

export const useGetBookingDatesQueryKey = 'useGetBookingDates';

const useGetBookingDates = (resourceItemId?: string | number) => {
    return useQuery(
        [useGetBookingDatesQueryKey, resourceItemId],
        () => {
            if (!resourceItemId) return;
            return BookingsApi.listBookingDates(+resourceItemId);
        },
        {
            enabled: !!resourceItemId,
        }
    );
};

export default useGetBookingDates;
