import React, { useEffect, useState } from 'react';
import { L } from '../../../../lib/i18n';
import styled from '@emotion/styled';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';

import CardTitle from '../../../../components/typography/CardTitle';
import ContentNewsApi from '@app/api/public/ContentNewsApi';
import { Button } from '../../../../components/Buttons/Button';
import { useHistory, useParams } from 'react-router-dom';
import useRemoveNews from '../hooks/useRemoveNews';
import { memberNewsQueryKeys, useGetMemberNewsById } from '../hooks/memberNewsQueries';
import toast from 'react-hot-toast';
import ConfirmationButton from '../../../../components/Buttons/ConfirmationButton';
import DocumentsAreaWithUpload from '../../../../components/document/DocumentsAreaWithUpload';
import { margin } from '../../../../components/Shared/Style/margin';
import StatusArea from '../../../../components/StatusArea/StatusArea';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { style } from '../../../../components/Shared/Style';
import { useMutation, useQueryClient } from 'react-query';
import useGetSignedUrlById from '../../../../hooks/useGetSignedUrlById';
import { formatDateIsoDayjs, formatDateTimeZone, formatInputDateTimeDayjs } from '@app/shared/lib/formatting';
import Label from '../../../../components/Forms/Label';
import ImageDropZone from '../../../../components/ImageDropZone/ImageDropZone';
import Input from '../../../../components/Forms/Input';
import triggerDirtyFieldsToats from '../../../../components/Forms/DirtyFieldsToast';
import Quill from '../../../../components/Quill/Quill';
import useSetNewsAsDraft from '../hooks/useSetNewsAsDraft';
import { notificationToast } from '../../notificationToast/notificationToast';
import { DocumentDetail } from '@app/api/models/Documents';

const StyledCardWithDivider = styled(CardWithDivider)({
    width: 'fit-content',
});

const GridContainer = styled.form(({ theme }) => ({
    display: 'grid',
    gap: '1rem',
    columnGap: '2rem',
    backgroundColor: theme.colors.white,
    alignItems: 'start',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateAreas: `
    "img          img               img"
    "from         to                ..."
    "title        title             title"
    "description  description       description"
    "reply_mail   reply_mail        reply_mail"
    "buttons      buttons           buttons"
    `,
    paddingBottom: '0px',
    maxWidth: '600px',
}));

const EditNewsContainer = styled.div({
    display: 'grid',
    gap: '2rem',
    gridTemplateColumns: 'auto auto',
    gridTemplateAreas: `
    "editArea statusArea"
    `,
});

const StyledImageDropZone = styled(ImageDropZone)({
    aspectRatio: '4/3',
});

const ButtonContainer = styled.div({
    width: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: `${margin.s}px`,
});

const newsFormPublishSchema = z.object({
    title: z.string().min(1, { message: L('required') }),
    description: z.string().min(1, { message: L('required') }),
    published_from: z.string().min(1, { message: L('required') }),
    published_to: z.string().optional(),
    reply_mail: z.string().email(L('not_valid_email')).or(z.literal('')),
});

const FlexWrapper = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    alignItems: 'start',

    '& > *': {
        flexBasis: '600px',
        flexGrow: 1,
    },
});

type EditPublishedNews = {
    news: z.infer<typeof newsFormPublishSchema> & {
        id?: number;
        cover_image_data?: DocumentDetail['document_data'] | null;
    };
    image?: File;
    notify: boolean;
};

const saveNewNews = (variables: EditPublishedNews) => {
    return ContentNewsApi.editPublishedNews(variables);
};

const NewsEditPublished = () => {
    const { id } = useParams<{ id: string }>();
    const { mutate: remove } = useRemoveNews();

    const { data: memberNews } = useGetMemberNewsById(+id);

    const [data, setData] = useState(memberNews);

    useEffect(() => {
        setData(memberNews);
    }, [memberNews]);

    const history = useHistory();

    const [imgFile, setImgFile] = useState<File | null>(null);
    const [imgUrl, setImgUrl] = useState<string>('');

    const queryClient = useQueryClient();
    const { data: signedUrl } = useGetSignedUrlById(data?.cover_image_data?.original?.id, undefined, false);
    const { mutate: editPublishedNews } = useMutation((variables: EditPublishedNews) => saveNewNews(variables), {
        onMutate: () => {
            toast.loading(L('loading'), {
                id: 'send-notification',
            });
        },
        onSuccess: () => {
            setImgFile(null);
            setImgUrl('');
        },
        onSettled: () => {
            queryClient.invalidateQueries(memberNewsQueryKeys.detail(+id));
            queryClient.invalidateQueries(memberNewsQueryKeys.lists());
        },
    });

    const onUpload = (files: File[]) => {
        setImgFile(files[0]);
        setImgUrl(URL.createObjectURL(files[0]));
    };

    const {
        register,
        handleSubmit,
        control,
        getValues,
        formState: { errors, isDirty },
    } = useForm({
        resolver: zodResolver(newsFormPublishSchema),
        values: {
            title: data?.title || '',
            description: data?.description || '',
            published_from: data?.published_from ? formatDateIsoDayjs(data?.published_from) : '',
            published_to: data?.published_to ? formatDateIsoDayjs(data?.published_to) : '',
            reply_mail: data?.reply_mail || '',
        },
    });

    const submit = (formData: EditPublishedNews['news']) => {
        const publish = (notify: boolean) => {
            editPublishedNews(
                {
                    news: {
                        id: +id,
                        description: formData.description,
                        title: formData.title,
                        published_from: formatDateTimeZone(formData.published_from),
                        published_to: formatDateTimeZone(formData.published_to),
                        reply_mail: formData.reply_mail,
                        cover_image_data: data?.cover_image_data,
                    },
                    image: imgFile ?? undefined,
                    notify,
                },
                {
                    onSuccess: () => {
                        toast.success(L('news_updated'));
                        setImgFile(null);
                    },
                    onSettled: () => {
                        toast.dismiss('send-notification');
                    },
                }
            );
        };

        return notificationToast({
            notify: (notify) => publish(notify),
            type: 'update',
        });
    };

    const onRemove = () => {
        if (!data) return;
        remove(
            { id: data.id },
            {
                onSuccess: () => {
                    toast.success(`${data.title} ${L('removed')}`);
                    history.push('/news');
                },
            }
        );
    };
    const { mutate } = useSetNewsAsDraft();
    const moveToDraft = () => {
        if (isDirty)
            return triggerDirtyFieldsToats({
                continueButtonText: L('move_to_draft'),
                continueFn: () => {
                    mutate({ id: +id });
                },
            });
        mutate({ id: +id });
    };

    return (
        <FlexWrapper>
            <StyledCardWithDivider
                topArea={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <CardTitle>{L('edit_news')}</CardTitle>
                        <ButtonContainer>
                            <ConfirmationButton props={{ onConfirm: onRemove }} />
                            <Button onClick={moveToDraft}>{L('move_to_draft')}</Button>
                        </ButtonContainer>
                    </div>
                }
                mainArea={
                    <EditNewsContainer>
                        <GridContainer onSubmit={handleSubmit(submit)}>
                            <div style={{ gridArea: 'img' }}>
                                <Label title={L('image')} />
                                <StyledImageDropZone
                                    dropzoneOptions={{}}
                                    onDrop={onUpload}
                                    url={imgUrl || signedUrl || ''}
                                    remove={() => {
                                        if (imgUrl || imgFile) {
                                            setImgUrl('');
                                            setImgFile(null);
                                            return;
                                        }
                                        if (!data?.cover_image_data) return;
                                        const mutatedData = { ...data, cover_image_data: null };
                                        setData(mutatedData);
                                    }}
                                />
                            </div>

                            <Label
                                style={{ gridArea: 'from' }}
                                htmlFor="published_from"
                                title={`${L('publish_start')}*`}
                                error={errors?.published_from?.message}
                            >
                                <Input
                                    type="datetime-local"
                                    {...register('published_from', {
                                        min: formatInputDateTimeDayjs(),
                                        required: true,
                                    })}
                                />
                            </Label>

                            <Label
                                style={{ gridArea: 'to' }}
                                htmlFor="published_to"
                                title={L('publish_end')}
                                error={errors?.published_to?.message}
                            >
                                <Input
                                    type="datetime-local"
                                    {...register('published_to', {
                                        min: formatInputDateTimeDayjs(),
                                        disabled: !getValues('published_from').length,
                                    })}
                                />
                            </Label>

                            <Label
                                style={{ gridArea: 'title' }}
                                htmlFor="title"
                                title={`${L('title')}*`}
                                error={errors?.title?.message}
                            >
                                <Input {...register('title', { required: true })} />
                            </Label>

                            <div style={{ gridArea: 'description' }}>
                                <Label title={`${L('description')}*`} error={errors?.description?.message} />
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) => {
                                        return <Quill {...field} placeholder="Skriv något här..." />;
                                    }}
                                />
                            </div>
                            <div style={{ gridArea: 'reply_mail' }}>
                                <Label
                                    htmlFor="reply_mail"
                                    title={`${L('reply_mail')}`}
                                    error={errors?.reply_mail?.message}
                                >
                                    <Input {...register('reply_mail', { required: true })} />
                                </Label>
                            </div>
                            <div
                                style={{
                                    placeSelf: 'end',
                                    whiteSpace: 'nowrap',
                                    display: 'flex',
                                    gap: style.margin.m,
                                    gridArea: 'buttons',
                                }}
                            >
                                <Button
                                    type="button"
                                    variant="primary"
                                    onClick={() => {
                                        if (isDirty) {
                                            return triggerDirtyFieldsToats({
                                                continueButtonText: L('continue_without_saving'),
                                                continueFn: () => {
                                                    history.push('/news');
                                                },
                                            });
                                        }
                                        history.push('/news');
                                    }}
                                    role="link"
                                >
                                    {L('cancel')}
                                </Button>

                                <Button variant="filledPrimary">{L('save')}</Button>
                            </div>
                        </GridContainer>
                        <StatusArea
                            status={{
                                created_by_name: data?.created_by_name,
                                created_by_email: data?.created_by_email,
                                notified_at: { value: data?.notified_at },
                                created_at: data?.created_at,
                            }}
                            style={{ alignSelf: 'start', gridArea: 'statusArea' }}
                        />
                    </EditNewsContainer>
                }
            />

            <DocumentsAreaWithUpload
                documentQueryKey={memberNewsQueryKeys.document(+id)}
                getDocuments={async () =>
                    (await ContentNewsApi.listDocuments(+id)).map((doc) => ({
                        filename: doc.document_data.original.metadata.filename,
                        id: doc.id,
                        name: doc.name,
                        originalId: doc.document_data.original.id,
                    }))
                }
                removeDocument={ContentNewsApi.deleteDocumentFromContentNews}
                renameDocument={ContentNewsApi.updateDocumentName}
                saveDocument={(file) => ContentNewsApi.saveDocument(+id, file.name, file)}
            />
        </FlexWrapper>
    );
};

export default NewsEditPublished;
