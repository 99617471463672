import React, { useEffect, useState } from 'react';
import { L } from '../../../lib/i18n';
import { Loading } from '../../../components/Loading/Loading';
import styled from '@emotion/styled';
import { style } from '../../../components/Shared/Style';
import { Button } from '../../../components/Buttons/Button';
import { useHistory, useParams } from 'react-router-dom';
import { useGetContactById } from '../hooks/useGetContacts';
import useRemoveContact from '../hooks/useRemoveContact';
import toast from 'react-hot-toast';
import { Contact } from '@app/api/models/Contacts';
import useSaveContact from '../hooks/useSaveContact';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardWithDividerTopArea from '../../../components/Layout/CardWithDividerTopArea';
import checkUnsavedData from '../../../lib/helpers/checkUnsavedData';
import { margin } from '../../../components/Shared/Style/margin';
import ContactsEditArea from '../components/ContactsEditArea';
import ContactsStatusArea from '../components/ContactsStatusArea';

const GridContainer = styled.div({
    display: 'grid',
    gap: '10rem',
    margin: '1rem 0',
    '@media screen and (min-width: 1100px)': {
        gridTemplateColumns: '2.5fr 1fr',
    },
});

const ButtonContainer = styled.div({
    width: 'auto',
    paddingTop: style.margin.s,
    display: 'flex',
    justifyContent: 'flex-end',
});

export const ContactGeneralPage = () => {
    const [confirmDelete, setConfirmDelete] = useState(true);
    const [file, setFile] = useState<File>();

    const history = useHistory();

    const { id } = useParams<{ id: string }>();
    const { data: contact } = useGetContactById(id);
    const { mutate: removeContact } = useRemoveContact();
    const { mutate: saveContact } = useSaveContact();
    const [contactState, setContactState] = useState<Partial<Contact>>({});

    useEffect(() => {
        if (!contact) return;
        setContactState({ ...contact });
    }, [contact]);

    const onSwitch = () => {
        if (!contact) {
            return <Loading />;
        }
        const localResource = {
            ...contactState,
        };

        if (
            checkUnsavedData({
                buttonText: L('continue'),
                changedData: localResource,
                originalData: contact,
                fn: () => history.push('/contacts'),
            })
        )
            return;
        history.push('/contacts');
    };

    const save = () => {
        if (!contact) return;
        if (contactState.name) {
            saveContact(
                { ...contactState, file },
                {
                    onSuccess: () => {
                        toast(`✅ ${contact.name} ${L('sparad')}`);
                    },
                }
            );
        }
    };

    const remove = () => {
        if (!contact) return;
        removeContact(
            { id: contact?.id },
            {
                onSuccess: () => {
                    toast(`✅ ${contact.name} ${L('removed')}`);
                    history.push('/contacts');
                },
            }
        );
    };

    if (!contact) return <Loading />;
    return (
        <>
            <CardWithDivider
                topArea={
                    <CardWithDividerTopArea title={L('contacts_header_info') + contactState.name}>
                        {confirmDelete ? (
                            <Button onClick={() => setConfirmDelete(false)} variant={'primary'}>
                                {L('delete')}
                            </Button>
                        ) : (
                            <Button onClick={remove} variant={'delete'}>
                                {L('confirm')}
                            </Button>
                        )}
                    </CardWithDividerTopArea>
                }
                mainArea={
                    <>
                        <GridContainer>
                            <ContactsEditArea setContact={setContactState} contact={contactState} setFile={setFile} />
                            <ContactsStatusArea contact={contactState} />
                        </GridContainer>
                        <ButtonContainer>
                            <Button onClick={onSwitch} style={{ marginRight: margin.s }} variant={'primary'}>
                                {L('cancel')}
                            </Button>
                            <Button
                                disabled={contactState.name ? false : true}
                                onClick={save}
                                style={{ marginRight: '0px' }}
                                variant="selectedMenu"
                            >
                                {L('save')}
                            </Button>
                        </ButtonContainer>
                    </>
                }
            />
        </>
    );
};
