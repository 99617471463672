import AlertsApi, { Alert } from '@app/api/public/AlertsApi';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { L } from '../../../lib/i18n';
import { alertKeys } from '../hooks/alertsQuery';

export type CreateAlertType = Pick<
    Alert,
    'title' | 'description' | 'priority' | 'publish_from_date' | 'publish_to_date'
>;

const createalert = (alert: CreateAlertType & { propertyIds: number[] }) => {
    return AlertsApi.save(alert, alert.propertyIds);
};

const useCreateAlert = () => {
    const client = useQueryClient();
    return useMutation((alert: CreateAlertType & { propertyIds: number[] }) => createalert(alert), {
        onMutate: () => {
            const id = toast.loading('Skapar nytt viktigt meddelande');
            return id;
        },
        onSuccess: (_data, _variables, id: string) => {
            toast.success('Viktigt meddelande skapat', { id });
            client.invalidateQueries(alertKeys.all);
        },
        onError: (_error, _variables, id: string) => {
            toast.success(L('error'), { id });
        },
    });
};

export default useCreateAlert;
