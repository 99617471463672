import { useMutation, useQueryClient } from 'react-query';
import DocumentsApi, { DuplicateAction } from '@app/api/public/DocumentsApi';
import toast from 'react-hot-toast';
import { fileKeys } from '../../queryKeys';
import { L } from '../../../../lib/i18n';
import { SelectItem } from '../../components/documentList/ListItem';

const useMoveItems = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({
            items,
            newParentId,
            duplicateAction,
        }: {
            items: SelectItem[];
            newParentId: number | null;
            duplicateAction?: DuplicateAction;
        }) => {
            const res = toast.promise(
                (async () => {
                    const data = await DocumentsApi.moveItems(items, newParentId, duplicateAction);

                    if (data.status === 'fail') {
                        throw data;
                    }

                    return data;
                })(),
                {
                    loading: L('move_items_loading') + '...',
                    error: L('move_items_error'),
                    success: (res) => {
                        return `${L('move_items_success')} ${res.data.files.length + res.data.folders.length} ${
                            L('folders') + '/' + L('files')
                        }!`;
                    },
                }
            );

            return res;
        },
        onSettled: () => {
            queryClient.invalidateQueries([fileKeys]);
        },
    });
};

export default useMoveItems;
