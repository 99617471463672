import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetTicket } from '../hooks/ticketQueries';
import EditTicket from './EditTicket';

const HandleInitialTicketData = ({ readOnly = false }: { readOnly?: boolean }) => {
    const { id } = useParams<{ id: string }>();
    const { data } = useGetTicket(id);
    return <EditTicket readOnly={readOnly} data={data} />;
};

export default HandleInitialTicketData;
