Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["id"] = createRpcProxy("ContractsApi", "id", {
  "urlPrefix": "/rpc"
});
o["nameById"] = createRpcProxy("ContractsApi", "nameById", {
  "urlPrefix": "/rpc"
});
o["list"] = createRpcProxy("ContractsApi", "list", {
  "urlPrefix": "/rpc"
});
o["saveType"] = createRpcProxy("ContractsApi", "saveType", {
  "urlPrefix": "/rpc"
});
o["listTypes"] = createRpcProxy("ContractsApi", "listTypes", {
  "urlPrefix": "/rpc"
});
o["deleteType"] = createRpcProxy("ContractsApi", "deleteType", {
  "urlPrefix": "/rpc"
});
o["save"] = createRpcProxy("ContractsApi", "save", {
  "urlPrefix": "/rpc"
});
o["delete"] = createRpcProxy("ContractsApi", "delete", {
  "urlPrefix": "/rpc"
});
o["saveDocument"] = createRpcProxy("ContractsApi", "saveDocument", {
  "urlPrefix": "/rpc"
});
o["updateDocumentName"] = createRpcProxy("ContractsApi", "updateDocumentName", {
  "urlPrefix": "/rpc"
});
o["listDocuments"] = createRpcProxy("ContractsApi", "listDocuments", {
  "urlPrefix": "/rpc"
});
o["deleteDocumentFromContract"] = createRpcProxy("ContractsApi", "deleteDocumentFromContract", {
  "urlPrefix": "/rpc"
});
o["archiveContract"] = createRpcProxy("ContractsApi", "archiveContract", {
  "urlPrefix": "/rpc"
});