import * as React from 'react';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';
import { colors } from '../Shared/Style/colors';
import { Button } from '../Buttons/Button';

const StyledNoProject = styled.div({
    padding: '50px',
    textAlign: 'center',
    minHeight: '436px',
    display: 'grid',
    alignContent: 'center',
    gridTemplateRows: '4rem 4rem',
});

const NoProjectTitle = styled.span({
    fontSize: style.fontSize.gamma,
});

const NoProjectDescription = styled.span({
    fontSize: style.fontSize.epsilon,
    color: colors.secondary,
});

interface Props {
    children: React.ReactNode;
}

const EmptyState = (props: Props) => {
    return <StyledNoProject>{props.children}</StyledNoProject>;
};

const Title: React.FC<{ children?: React.ReactNode }> = ({ children, ...rest }) => {
    return <NoProjectTitle {...rest}>{children}</NoProjectTitle>;
};
const Description: React.FC<{ children?: React.ReactNode }> = ({ children, ...rest }) => {
    return <NoProjectDescription {...rest}>{children}</NoProjectDescription>;
};
const EmptyStateButton: React.FC<React.ComponentProps<typeof Button>> = ({ children, ...rest }) => {
    return <Button {...rest}>{children}</Button>;
};

EmptyState.Title = Title;
EmptyState.Description = Description;
EmptyState.Button = EmptyStateButton;

export default EmptyState;
