import DocumentsApi from '@app/api/public/DocumentsApi';
import { useQuery } from 'react-query';
import { folderKeys } from '../../queryKeys';
import toast from 'react-hot-toast';
import { L } from '../../../../lib/i18n';

const useGetFolderListByParentId = (parentId?: number) => {
    return useQuery(
        [...folderKeys.list(parentId)],
        async () => {
            const res = await DocumentsApi.getFoldersByParentId(parentId);

            if (res.status === 'error') {
                throw new Error(res.message).message;
            }

            return res;
        },
        {
            onError: (err) => {
                const errorMessage = typeof err === 'string' ? err : new Error(L('reset_error')).message;
                toast.error(`${L(errorMessage)}`);
            },
        }
    );
};

export default useGetFolderListByParentId;
