import React from 'react';
import styled from '@emotion/styled';
import useGetPageListItems from '../../hooks/useGetPageListItems';
import { colors } from '../../../../areas/public-site/lib/publicColors';
import { L } from '../../../../lib/i18n';
import { useTheme } from 'emotion-theming';
import { PublicThemeType } from '@app/api/models/PublicSite';
import useGetProjectInfo from '../../../../hooks/useGetProjectInfo';

const LinkWrapper = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});
const NavBar = styled.nav(({ theme }: { theme: PublicThemeType }) => {
    return {
        backgroundColor: theme.colors.navbarBackground,
        paddingBottom: '0.5rem',
    };
});
const H1 = styled.h1(({ theme }: { theme: PublicThemeType }) => {
    return {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '1.5rem 1rem 1rem 1rem',
        margin: '0px',
        color: theme.colors.textColor1,
    };
});
const Link = styled.span(({ theme }: { theme: PublicThemeType }) => {
    return {
        margin: '0.5rem',
        color: theme.colors.textColor1,
    };
});
const HR = styled.hr({
    width: '90%',
    color: colors.white,
});

const PreviewNavbar = () => {
    const { data: projectInfo } = useGetProjectInfo();
    const { data: pageListItems } = useGetPageListItems();
    const theme = useTheme<PublicThemeType>();

    return (
        <NavBar theme={theme}>
            <H1 theme={theme}>{projectInfo && projectInfo.name}</H1>
            <HR />
            <LinkWrapper>
                {pageListItems &&
                    pageListItems.map((link) => {
                        return (
                            <Link theme={theme} key={link.id}>
                                {link.title}
                            </Link>
                        );
                    })}
                <Link theme={theme}>{L('contact')}</Link>
            </LinkWrapper>
        </NavBar>
    );
};

export default PreviewNavbar;
