import React, { useEffect, useRef, useState } from 'react';
import DocumentItem from './DocumentItem';
import { style } from '../../Shared/Style';
import toast from 'react-hot-toast';
import styled from '@emotion/styled';
import { L } from '../../../lib/i18n';
import { colors } from '../../Shared/Style/colors';
import TrashCan from '../../Icon/themed/TrashCan';
import Edit from '../../Icon/themed/Edit';
import Folder from '../../Icon/themed/Folder';
import Download from '../../Icon/themed/Download';
import Copy from '../../Icon/themed/Copy';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Label from '../../Forms/Label';
import Input from '../../Forms/Input';
import { Button } from '../../Buttons/Button';
import { createPortal } from 'react-dom';
import ReactSelect from '../../Dropdown/ReactSelect';

const StyledDialog = styled.dialog(({ theme }) => ({
    padding: '22px 32px 22px 32px',
    backgroundColor: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.divider}`,
    boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.1)',
    maxWidth: '600px',
    width: '100%',
    overflow: 'visible',
}));

const DropDownContent = styled(DropdownMenu.Content)({
    padding: '0.5rem',
    backgroundColor: colors.white,
    color: colors.textColor2,
    borderRadius: style.roundCornerSize.small,
    boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.1)',
});

const StyledArrow = styled(DropdownMenu.Arrow)({
    fill: colors.white,
});

const DeleteIcon = styled(TrashCan)({
    height: '1.3rem',
    width: '1.3rem',
});
const EditIcon = styled(Edit)({
    height: '1.3rem',
    width: '1.3rem',
});

const FolderIcon = styled(Folder)({
    height:'1.3rem',
    width:'1.3rem',
})

const DownloadIcon = styled(Download)({
    height: '1.3rem',
    width: '1.3rem',
});

const CopyIcon = styled(Copy)({
    height: '1.3rem',
    width: '1.3rem',
});

const ContextItem = styled.li(() => ({
    padding: '1rem 2rem 1rem 1rem ',
    display: 'flex',
    fontSize: '1rem',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    gap: '1rem',
    cursor: 'pointer',
}));

const StyledItem = styled(DropdownMenu.Item)(({ theme }) => ({
    '&:focus-visible': {
        outline: `${theme.colors.primary50} auto 1px`,
    },
}));

const ButtonWrapper = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '2rem',
    gap: '0.5rem',
});

type Props = {
    deleteDocument?: () => void;
    editDocumentName?: (name: string) => void;
    url?: string;
    name: string;
    fileType: string;
};

const StyledTrigger = styled(DropdownMenu.Trigger)(({ theme }) => ({
    backgroundColor: 'inherit',
    border: 'none',
    padding: '0px',
    margin: '0px',
    display: 'block',
    cursor: 'pointer',
    '&:focus-visible': {
        outline: `${theme.colors.primary50} auto 1px`,
    },
    '&:focus, &:hover': {
        outline: `${theme.colors.primary50} auto 1px`,
    },
}));

const DocumentDropdown = ({
    url,
    name,
    fileType,
    deleteDocument,
    editDocumentName,
    ...rest
}: Props) => {
    const handleCopy = async () => {
        if (!url) return;
        await navigator?.clipboard?.writeText(url);
        toast(`url ${L('copied_to_clipboard')}`);
    };
    const handleDownload = async () => {
        if (!url) return;

        window.open(url);
    };

    const [editName, setEditName] = useState(name);

    useEffect(() => {
        setEditName(name);
    }, [name]);

    const dialogRef = useRef<HTMLDialogElement>(null);

    return (
        <>
            {createPortal(
                <>
                    <StyledDialog ref={dialogRef}>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                dialogRef.current?.close();
                                editDocumentName && editDocumentName(editName);
                            }}
                        >
                            <Label title={L('edit_name')}>
                                <Input value={editName} onChange={(e) => setEditName(e.target.value)} />
                            </Label>
                            <ButtonWrapper>
                                <Button type="button" onClick={() => dialogRef.current?.close()}>
                                    {L('cancel')}
                                </Button>
                                <Button variant={'filledPrimary'}>{L('save')}</Button>
                            </ButtonWrapper>
                        </form>
                    </StyledDialog>
                </>,

                document.body
            )}
            <DropdownMenu.Root>
                <StyledTrigger {...rest}>
                    <DocumentItem name={name} fileType={fileType} className={'document'} />
                </StyledTrigger>

                <DropdownMenu.Portal>
                    <DropDownContent sideOffset={1}>
                        {editDocumentName && (
                            <>
                                <StyledItem onClick={() => dialogRef.current?.showModal()}>
                                    <ContextItem>
                                        <EditIcon />

                                        <span>{L('edit_name')}</span>
                                    </ContextItem>
                                </StyledItem>
                            </>
                        )}
                        {url && navigator?.clipboard && (
                            <>
                                <StyledItem onClick={() => handleCopy()}>
                                    <ContextItem>
                                        <CopyIcon />
                                        <span>{L('copy')}</span>
                                    </ContextItem>
                                </StyledItem>
                                <StyledItem onClick={handleDownload}>
                                    <ContextItem>
                                        <DownloadIcon />
                                        <span>{L('download')}</span>
                                    </ContextItem>
                                </StyledItem>
                            </>
                        )}
                        {deleteDocument && (
                            <StyledItem onClick={() => deleteDocument()}>
                                <ContextItem>
                                    <DeleteIcon />
                                    <span>{L('remove')}</span>
                                </ContextItem>
                            </StyledItem>
                        )}
                        <StyledArrow />
                    </DropDownContent>
                </DropdownMenu.Portal>
            </DropdownMenu.Root>
        </>
    );
};

export default DocumentDropdown;
