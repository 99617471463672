import styled from '@emotion/styled';
import Cross from '../../../components/Icon/e-remove-outline-12.svg';

export default styled(Cross)({
    alignSelf: 'center',
    gridArea: 'icon',
    width: '16px',
    color: '#4B506D66',
    marginRight: '5px',
});
