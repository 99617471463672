import * as React from 'react';
import { L } from '../../lib/i18n';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';
import SortArrows from './sortArrows/SortArrows';

interface TableProps {
    data: any;
    columns: any[];
    children?: any;
}

interface RowProps {
    data: any;
    children?: any;
    columns: any;
}

const TableContainer = styled.div((props: { gridTemplateColumns: string }) => ({
    background: style.colors.white,
    borderRadius: style.roundCornerSize.small,
    display: 'grid',
    gridTemplateColumns: props.gridTemplateColumns,
    padding: style.margin.l,
    textAlign: 'left',
    border: `solid 1px ${style.colors.black30}`,
}));

const TableCell = styled.div({
    borderTop: '1px solid lightgrey',
    paddingBottom: style.margin.m,
    paddingTop: style.margin.m,
});

export const Table = (props: TableProps) => {
    const gd = props.columns.map((c) => c.width || '1fr').join(' ');
    const rows = props.data.map((d, index) => <TableRow key={index} data={d} columns={props.columns} />);

    return (
        <TableContainer gridTemplateColumns={gd}>
            <TableHeader columns={props.columns} />
            {rows}
        </TableContainer>
    );
};

export const TableRow = (props: RowProps) => {
    const cells = props.columns.map((col) => {
        const data = props.data[col.key];
        return <TableCell key={col.key}>{data}</TableCell>;
    });
    return <>{cells}</>;
};

const TableHeaderColumn = styled('div')({
    color: '#9FA2B4',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    fontSize: style.fontSize.centi,
    paddingBottom: style.margin.s,
    textTransform: 'capitalize',
    '&:first-of-type': {
        justifyContent: 'left',
    },
});

interface HeaderColumnProps {
    columns: { arrows: boolean; sortby?: () => void; header: string }[];
    children?: any;
}

export const TableHeader = (props: HeaderColumnProps) => {
    const items = props.columns.map((c, index) => (
        <TableHeaderColumn key={index} onClick={c.sortby}>
            {L(c.header)}
            {c.arrows && <SortArrows />}
        </TableHeaderColumn>
    ));
    return <> {items} </>;
};
