import TicketsApi from '@app/api/public/TicketsApi';
import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Button } from '../../../components/Buttons/Button';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import TextAreaInput from '../../../components/Forms/TextArea';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { Loading } from '../../../components/Loading/Loading';
import CardTitle from '../../../components/typography/CardTitle';
import P from '../../../components/typography/P';
import { L } from '../../../lib/i18n';
import { getErrorMessage } from '../../../utils/getErrorMessage';
import z from 'zod';
import { TicketSettingsQueryKey, useTicketSettingQuery } from '../hooks/TicketSettingsQueries';
import { useGetTicketsAppStatus } from '../hooks/useGetTicketsAppStatus';
import { useSetTicketAppStatus } from '../hooks/useSetTicketAppStatus';
import Quill from '../../../components/Quill/Quill';
import { Controller, useForm } from 'react-hook-form';
import useSaveTicketInformation from '../hooks/useSaveTicketInformation';
import useSaveTicketResponse from '../hooks/useSaveTicketResponse';
import Divider from '../../../components/thread/divider/Divider';

const StyledDialog = styled.dialog(({ theme }) => ({
    padding: '22px 32px 22px 32px',
    backgroundColor: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.divider}`,
    boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.1)',
    maxWidth: '600px',
    width: '100%',
    overflow: 'visible',
}));

const ButtonContainer = styled.div({
    display: 'flex',
    gap: '1rem',
});

const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'end',
    gap: '0.5rem',
    paddingTop: '1.5rem',
});

const useAddTicketEmail = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (ticketEmail: string) => {
            return TicketsApi.addServiceEmail(ticketEmail);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(TicketSettingsQueryKey.all);
        },
    });
};

const useRemoveTicketEmail = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: () => {
            return TicketsApi.removeServiceEmail();
        },
        onSuccess: () => {
            queryClient.invalidateQueries(TicketSettingsQueryKey.all);
        },
    });
};

const TicketEmail = () => {
    const dialogRef = React.useRef<HTMLDialogElement>(null);

    const { handleSubmit: serviceHandleSubmit, control: serviceControl } = useForm({});

    const { handleSubmit: responseHandleSubmit, control: responseControl } = useForm({});

    const { data: ticketSettings } = useTicketSettingQuery();

    const { mutate: saveTicketInformation } = useSaveTicketInformation();

    const { mutate: saveTicketResponse } = useSaveTicketResponse();

    const handleServiceText = (data: { serviceText: string }) => {
        const serviceText = data.serviceText ?? ticketSettings?.service_info;
        saveTicketInformation(serviceText);
    };

    const handleResponseText = (data: { responseText: string }) => {
        const responseText = data.responseText ?? ticketSettings?.service_response;
        saveTicketResponse(responseText);
    };

    const { mutate: removeTicketEmailMutation, status } = useRemoveTicketEmail();

    const handleActivateSystemTicketSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        removeTicketEmailMutation();

        const toastId = toast.loading(L('ticket_email_deactivating'));
        removeTicketEmailMutation(undefined, {
            onSuccess: () => {
                dialogRef.current?.close();
                toast.success(`${L('ticket_email_deactivated')}`, { id: toastId });
            },
            onError: (error) => {
                const message = getErrorMessage(error);
                toast.error(message, { id: toastId });
            },
        });
        dialogRef.current?.close();
    };
    return (
        <>
            <StyledDialog ref={dialogRef}>
                <form onSubmit={handleActivateSystemTicketSubmit}>
                    <P>{L('activate_ticket_system_info')}</P>

                    <Wrapper>
                        <Button type="button" onClick={() => dialogRef.current?.close()}>
                            {L('cancel')}
                        </Button>
                        <Button variant="filledPrimary" isLoading={status === 'loading'}>
                            {L('activate')}
                        </Button>
                    </Wrapper>
                </form>
            </StyledDialog>
            <CardWithDivider
                topArea={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <CardTitle>{L('ticket_setting')}</CardTitle>
                        <Button variant="filledPrimary" onClick={() => dialogRef.current?.showModal()}>
                            {L('deactivate_ticket_email')}
                        </Button>
                    </div>
                }
                mainArea={
                    <div>
                        <form onSubmit={serviceHandleSubmit(handleServiceText)}>
                            <Label title={L('email')}>
                                <Input disabled defaultValue={ticketSettings?.service_email} />
                            </Label>
                            <Label title={L('ticket_information')}>
                                <Controller
                                    name="serviceText"
                                    control={serviceControl}
                                    render={({ field }) => {
                                        return (
                                            <Quill
                                                {...field}
                                                value={field.value ?? ticketSettings?.service_info}
                                                placeholder="Skriv något här..."
                                            />
                                        );
                                    }}
                                />
                            </Label>
                            <Wrapper>
                                <Button variant="filledPrimary">{L('save')}</Button>
                            </Wrapper>
                        </form>
                        <Divider />
                        <form onSubmit={responseHandleSubmit(handleResponseText)}>
                            <Label title={L('ticket_response')}>
                                <Controller
                                    name="responseText"
                                    control={responseControl}
                                    render={({ field }) => {
                                        return (
                                            <Quill
                                                {...field}
                                                value={field.value ?? ticketSettings?.service_response}
                                                placeholder="Skriv något här..."
                                            />
                                        );
                                    }}
                                />
                            </Label>
                            <Wrapper>
                                <Button variant="filledPrimary">{L('save')}</Button>
                            </Wrapper>
                        </form>
                    </div>
                }
            />
        </>
    );
};

const SystemTicket = () => {
    const dialogRef = React.useRef<HTMLDialogElement>(null);
    const { data: ticketSettings } = useTicketSettingQuery();
    const { data: getAppStatus } = useGetTicketsAppStatus();
    const [confirmed, setConfirmed] = React.useState(false);
    const { mutate: saveTicketAppStatus } = useSetTicketAppStatus();

    const [ticketEmail, setTicketEmail] = React.useState('');

    useEffect(() => {
        if (ticketSettings?.service_email) {
            setTicketEmail(ticketSettings.service_email);
        }
    }, [ticketSettings?.service_email, ticketSettings?.service_info]);

    const { handleSubmit: serviceHandleSubmit, control: serviceControl } = useForm({});

    const { handleSubmit: responseHandleSubmit, control: responseControl } = useForm({});

    const { mutate: saveTicketInformation } = useSaveTicketInformation();

    const { mutate: saveTicketResponse } = useSaveTicketResponse();

    const handleServiceText = (data: { serviceText: string }) => {
        const serviceText = data.serviceText ?? ticketSettings?.service_info;
        saveTicketInformation(serviceText);
    };

    const handleResponseText = (data: { responseText: string }) => {
        const responseText = data.responseText ?? ticketSettings?.service_response;
        saveTicketResponse(responseText);
    };

    const { mutate: addTicketEmailMutation, status } = useAddTicketEmail();

    const handleTicketEmailActivateSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const toastId = toast.loading(L('ticket_email_activating'));
        addTicketEmailMutation(ticketEmail, {
            onSuccess: () => {
                dialogRef.current?.close();
                toast.success(`${L('ticket_email_activated')}`, { id: toastId });
            },
            onError: (error) => {
                const message = getErrorMessage(error);
                toast.error(message, { id: toastId });
            },
        });
    };

    const isEmailValid = () => {
        return z.string().email().safeParse(ticketEmail).success;
    };
    return (
        <>
            <StyledDialog ref={dialogRef}>
                <form onSubmit={handleTicketEmailActivateSubmit}>
                    <P>{L('activate_ticket_email_info')}</P>
                    <Label title={L('ticket_email')}>
                        <Input value={ticketEmail} onChange={(e) => setTicketEmail(e.target.value)} />
                    </Label>
                    <Wrapper>
                        <Button type="button" onClick={() => dialogRef.current?.close()}>
                            {L('cancel')}
                        </Button>
                        <Button disabled={!isEmailValid()} variant="filledPrimary">
                            {L('activate')}
                        </Button>
                    </Wrapper>
                </form>
            </StyledDialog>
            <CardWithDivider
                topArea={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <CardTitle>{L('ticket_setting')}</CardTitle>
                        <ButtonContainer>
                            {getAppStatus?.enabled ? (
                                confirmed ? (
                                    <Button
                                        onClick={() => {
                                            setConfirmed(false);
                                            saveTicketAppStatus(false);
                                        }}
                                        variant="delete"
                                    >
                                        {L('delete')}
                                    </Button>
                                ) : (
                                    <Button onClick={() => setConfirmed(true)} variant="primary">
                                        {L('ticket_shown')}
                                    </Button>
                                )
                            ) : (
                                <Button
                                    variant="filledPrimary"
                                    isLoading={status === 'loading'}
                                    onClick={() => saveTicketAppStatus(true)}
                                >
                                    {L('ticket_hidden')}
                                </Button>
                            )}

                            <Button
                                variant="filledPrimary"
                                isLoading={status === 'loading'}
                                onClick={() => dialogRef.current?.showModal()}
                            >
                                {L('activate_ticket_email')}
                            </Button>
                        </ButtonContainer>
                    </div>
                }
                mainArea={
                    <div>
                        <form onSubmit={serviceHandleSubmit(handleServiceText)}>
                            <Label title={L('ticket_information')}>
                                <Controller
                                    name="serviceText"
                                    control={serviceControl}
                                    render={({ field }) => {
                                        return (
                                            <Quill
                                                {...field}
                                                value={field.value ?? ticketSettings?.service_info}
                                                placeholder="Skriv något här..."
                                            />
                                        );
                                    }}
                                />
                            </Label>
                            <Wrapper>
                                <Button variant="filledPrimary">{L('save')}</Button>
                            </Wrapper>
                        </form>
                        <Divider />
                        <form onSubmit={responseHandleSubmit(handleResponseText)}>
                            <Label title={L('ticket_response')}>
                                <Controller
                                    name="responseText"
                                    control={responseControl}
                                    render={({ field }) => {
                                        return (
                                            <Quill
                                                {...field}
                                                value={field.value ?? ticketSettings?.service_response}
                                                placeholder="Skriv något här..."
                                            />
                                        );
                                    }}
                                />
                            </Label>
                            <Wrapper>
                                <Button variant="filledPrimary">{L('save')}</Button>
                            </Wrapper>
                        </form>
                    </div>
                }
            />
        </>
    );
};

const TicketSettingsPage = () => {
    const { data: ticketSettings, status } = useTicketSettingQuery();

    if (status === 'loading') {
        return <Loading />;
    }

    if (status === 'success') {
        return ticketSettings?.service_email ? <TicketEmail /> : <SystemTicket />;
    }

    return (
        <CardWithDivider topArea={<CardTitle>{L('ticket_setting')}</CardTitle>} mainArea={<div>{L('error')}</div>} />
    );
};

export default TicketSettingsPage;
