import MailApi from '@app/api/public/MailApi';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { MailMessage } from '@app/api/lib/mail';
import { FolderTypes, mail_messages } from '@app/api/models/Mail/MailModels';
import { getMailKeys } from '@app/shared/queryKeys/mail';
import toast from 'react-hot-toast';
import { L } from '../../../lib/i18n';
import { useHistory } from 'react-router';

const handleReturn = (
    data:
        | { status: 'error'; message: string }
        | { status: 'success'; data: any }
        | { status: string; message?: string; data?: any }
) => {
    if (data.status === 'error') {
        throw new Error(data.message);
    } else if (data.status === 'success') {
        return data.data;
    }
    return undefined;
};

export function useGetMail(id: number) {
    const queryClient = useQueryClient();
    const mail = useQuery(getMailKeys.getById(id), () => MailApi.getMail(Number(id)), {
        onSuccess: () => {
            queryClient.invalidateQueries(getMailKeys.counts());
        },
    });

    return mail;
}

export function useGetOutgoingMail(id: mail_messages['id']) {
    const queryClient = useQueryClient();
    const mail = useQuery(getMailKeys.getById(id), ({ queryKey }) => MailApi.getOutgoingMail(queryKey[0].id), {
        onSuccess: () => {
            queryClient.invalidateQueries(getMailKeys.counts());
        },
    });

    return mail;
}

export function useGetMails(searchValue: string, folderId: number, page: number) {
    return useQuery(
        getMailKeys.list(searchValue, folderId, page),
        () => MailApi.listMails(searchValue, folderId, page),
        {
            keepPreviousData: true,
        }
    );
}

export function useGetUnreadMailCount(folderId: number) {
    return useQuery(getMailKeys.count(folderId), ({ queryKey }) => MailApi.getUnreadCount(queryKey[0].folderId), {
        keepPreviousData: true,
    });
}

export function useGetFolders(types?: FolderTypes[]) {
    return useQuery(getMailKeys.listFolder(types), () => MailApi.listFolders(types), {
        keepPreviousData: true,
        refetchOnReconnect: false,
    });
}

export function useGetFolderById(folderId: number) {
    return useQuery(getMailKeys.folderById(folderId), () => MailApi.folderById(folderId), {
        keepPreviousData: true,
    });
}

export const useUpdateFolder = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async (params: { id: number; name: string }) =>
            handleReturn(await MailApi.updateFolder(params.id, params.name)),
        {
            onError: (err) => {
                if (err instanceof Error) {
                    toast.error(`${L(err.message)}`);
                }
            },
            onSuccess: (data) => {
                toast.success(L('folder_successfully_renamed'));
                queryClient.invalidateQueries(getMailKeys.listFolders());
                queryClient.invalidateQueries(getMailKeys.folderByIds());
            },
        }
    );
};

export const useRemoveFolder = () => {
    const queryClient = useQueryClient();
    const history = useHistory();
    return useMutation(async (params: { id: number }) => handleReturn(await MailApi.removeFolder(params.id)), {
        onError: (err) => {
            if (err instanceof Error) {
                toast.error(`${L(err.message)}`);
            }
        },
        onSuccess: (data) => {
            toast.success(L('folder_successfully_removed'));
            queryClient.invalidateQueries(getMailKeys.all);
            history.push(`/mail`);
        },
    });
};

export const useSendMail = () => {
    return useMutation(async (params: { mail: MailMessage; attachments: string[]; draftId: number | undefined }) =>
        handleReturn(await MailApi.sendMail(params.mail, params.attachments, params.draftId))
    );
};

export const useSaveDraft = () => {
    return useMutation(async (params: { mail: MailMessage; existingId: number | null; reply_to_id?: number }) =>
        handleReturn(
            await MailApi.saveDraft({
                mail: params.mail,
                existingId: params.existingId,
                reply_to_id: params.reply_to_id,
            })
        )
    );
};

export const useAddFolder = () => {
    return useMutation(async (params: { folderName: string; mailId?: number }) =>
        handleReturn(await MailApi.addFolder(params.folderName, params.mailId))
    );
};

export const useSaveAttachment = () => {
    return useMutation(
        async (params: { mail: MailMessage; draftId: number | undefined; attachments: File[]; from: string }) =>
            handleReturn(
                await MailApi.saveDraftAttachment(params.mail, params.draftId, params.attachments, params.from)
            )
    );
};

export const useDeleteAttachment = () => {
    return useMutation(async (params: { draftId: number; s3key: string }) =>
        handleReturn(await MailApi.deleteAttachment(params.draftId, params.s3key))
    );
};

export const useInitializeMaps = () => {
    const queryClient = useQueryClient();
    return useMutation(async () => handleReturn(await MailApi.initializeMaps()), {
        onError: (err) => {
            if (err instanceof Error) {
                toast.error(`${L(err.message)}`);
            }
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(getMailKeys.all);
        },
    });
};
