import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import BigTabListItem from '../../../components/BigTabItem/BigTabListItem';
import BigNavItem from '../../../components/BigTabItem/BigTabListItemNav';
import BigTabNav from '../../../components/BigTabItem/BigTabNav';
import PageTitle from '../../../components/typography/PageTitle';
import { L } from '../../../lib/i18n';
import CreateNewEventPage from './CreateNewEventPage/CreateNewEvent';
import EventDetailPage from './DetailPage/EventDetailPage';
import { useGetMemberEventById } from './hooks/memberEventQueries';
import EventDraftListpage from './ListPage/EventDraftListPage';
import EventPublishedListPage from './ListPage/EventPublishedListPage';

const MemberEventPage = () => {
    const { id } = useParams<{ id: string }>();
    const { data } = useGetMemberEventById(+id);

    return (
        <>
            <PageTitle>{L('member_event_title')}</PageTitle>
            <BigTabNav>
                <BigNavItem exact to={'/event'}>
                    {L('all_published')}
                </BigNavItem>
                <BigNavItem exact to={'/event/draft'}>
                    {L('draft')}
                </BigNavItem>
                <Switch>
                    <Route exact path={'/event/draft'} />
                    <Route exact path={'/event/new'}>
                        <div style={{ borderRight: 'solid 1px gray' }} />
                        <BigTabListItem className="active">{L('new_event_page')}</BigTabListItem>
                    </Route>
                    <Route path={'/event/:id'}>
                        <div style={{ borderRight: 'solid 1px gray' }} />
                        <BigNavItem exact to={`/event/${data?.id}`}>
                            {data?.title}
                        </BigNavItem>
                    </Route>
                </Switch>
            </BigTabNav>
            <Switch>
                <Route path={'/event/new'}>
                    <CreateNewEventPage />
                </Route>
                <Route exact path={'/event/draft'}>
                    <EventDraftListpage />
                </Route>
                <Route path={'/event/:id'}>
                    <EventDetailPage />
                </Route>
                <Route exact path={'/event'}>
                    <EventPublishedListPage />
                </Route>
            </Switch>
        </>
    );
};

export default MemberEventPage;
