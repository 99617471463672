import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { MaintenanceCategory, MaintenanceFilter, MaintenanceStatus } from '@app/api/models/Maintenances';
import useGetMaintenanceList from '../hooks/useGetMaintenanceList';
import useGetSettingsListByType from '../hooks/useGetSettingsListByType';
import { L } from '../../../lib/i18n';
import { Button } from '../../../components/Buttons/Button';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { Link } from 'react-router-dom';
import { COLUMNS } from '../components/columns';
import MaintenanceTable from '../components/MaintenanceTable';
import CardTitle from '../../../components/typography/CardTitle';
import HorizontalDotsNav from '../../../components/HorizontalDotsNav/HorizontalDotsNav';
import HorizontalDotsButton from '../../../components/HorizontalDotsNav/HorizontalDotsButton';
import Modal from '../../../components/Modal/Modal';
import { useModal } from '../../../components/Modal/Context/ModalContext';
import SettingsByType from '../components/SettingsByType';
import Filters from '../../../components/Filters/Filters';
const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const SearchButtonWrapper = styled.div({
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
});

const StyledDropdown = styled(Dropdown)({
    marginRight: '1rem',
});

const StyledFilters = styled(Filters)({
    margin: '0px',
});

const MaintenanceTablePage = () => {
    const [category, setCategory] = useState<MaintenanceCategory | undefined>();
    const [status, setStatus] = useState<MaintenanceStatus | undefined>();
    const [filter, setFilter] = useState<MaintenanceFilter>({
        categoryId: category?.id,
        statusId: status?.id,
    });
    const { data: filteredData } = useGetMaintenanceList(filter);
    const { data: statusList } = useGetSettingsListByType('status');
    const { data: categoryList } = useGetSettingsListByType('category');
    const { openModal } = useModal();

    useEffect(() => {
        setFilter({
            categoryId: category?.id,
            statusId: status?.id,
        });
    }, [category, status]);

    const statusOptions = useMemo(() => {
        return statusList
            ? [
                  ...statusList.map((status) => {
                      return { name: status.name, id: status.id };
                  }),
                  { name: L('maintenance_status_completed'), id: 'completed' },
              ]
            : [];
    }, [statusList]);

    return (
        <>
            <Modal />

            <CardWithDivider
                topArea={
                    <Wrapper>
                        <CardTitle>{L('all_maintenance')}</CardTitle>
                        <SearchButtonWrapper>
                            <Link to={'/maintenance/add'}>
                                <Button variant={'primary'}>{L('create_maintenance')}</Button>
                            </Link>
                            <HorizontalDotsNav>
                                <HorizontalDotsButton
                                    onClick={() => {
                                        openModal(<SettingsByType settingsType="category" />);
                                    }}
                                >
                                    {L('catergory')}
                                </HorizontalDotsButton>
                                <HorizontalDotsButton
                                    onClick={() => {
                                        openModal(<SettingsByType settingsType="status" />);
                                    }}
                                >
                                    {L('status')}
                                </HorizontalDotsButton>
                                <HorizontalDotsButton
                                    onClick={() => {
                                        openModal(<SettingsByType settingsType="type" />);
                                    }}
                                >
                                    {L('type')}
                                </HorizontalDotsButton>
                            </HorizontalDotsNav>
                        </SearchButtonWrapper>
                    </Wrapper>
                }
                mainArea={
                    <MaintenanceTable
                        filterArea={
                            <StyledFilters>
                                <StyledDropdown
                                    isClearable
                                    label={L('category')}
                                    onChange={(value) => setCategory(value)}
                                    value={category}
                                    options={
                                        categoryList?.map((category) => {
                                            return {
                                                value: category,
                                                label: category.name,
                                            };
                                        }) || []
                                    }
                                />
                                <StyledDropdown
                                    isClearable
                                    label={L('status')}
                                    onChange={(value) => setStatus(value)}
                                    value={status}
                                    options={
                                        statusOptions.map((status) => {
                                            return { value: status, label: status.name };
                                        }) || []
                                    }
                                />
                            </StyledFilters>
                        }
                        Columns={COLUMNS}
                        Data={filteredData}
                    />
                }
            />
        </>
    );
};

export default MaintenanceTablePage;
