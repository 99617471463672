import * as React from 'react';
import { useModal } from '../../../components/Modal/Context/ModalContext';
import Modal from '../../../components/Modal/Modal';
import NewBoardMemberForm from '../NewBoardMember/NewBoardMember';
import TableQuery from './TableQuery';

//#region css

//#endregion

export const BoardMembersListPage = () => {
    const { ToggleIsModalOpen } = useModal();

    return (
        <>
            <TableQuery ToggleIsModalOpen={ToggleIsModalOpen} />
            <Modal>
                <NewBoardMemberForm />
            </Modal>
        </>
    );
};
