import { useMutation, useQueryClient } from 'react-query';
import MaintenancesApi from '@app/api/public/MaintenancesApi';
import { getMaintenanceQueryKey } from './useGetMaintenance';
import { SaveMaintenance } from '@app/api/models/Maintenances';

type Variables = { maintenaces: Partial<SaveMaintenance>[] };

const saveMultipleMaintance = async (variables: Variables) => {
    const resp = await Promise.all(variables.maintenaces.map((maintenace) => MaintenancesApi.save(maintenace)));
    return resp;
};

const useSaveMultipleMaintance = () => {
    const queryClient = useQueryClient();

    return useMutation((variables: Variables) => saveMultipleMaintance(variables), {
        onSettled: () => {
            queryClient.invalidateQueries(getMaintenanceQueryKey);
        },
    });
};

export default useSaveMultipleMaintance;
