import { useMutation, useQueryClient } from 'react-query';
import ServicePartnersApi, { ServicePartner } from '@app/api/public/ServicePartnersApi';
import { servicePartnersQueryKeys } from './servicePartnerQueries';

type Id = Partial<ServicePartner>['id'];

export const removeServicePartner = async (id: Id) => {
    if (!id) return;
    return await ServicePartnersApi.remove(id);
};

const useRemoveServicePartner = () => {
    const queryClient = useQueryClient();
    return useMutation((varables: { id: Id }) => removeServicePartner(varables.id), {
        onSettled: () => {
            queryClient.invalidateQueries(servicePartnersQueryKeys.all);
        },
    });
};

export default useRemoveServicePartner;
