import React, { forwardRef, Ref, useEffect, useRef } from 'react';

type Props = {
    indeterminate?: boolean;
};

const useCombinedRefs = (...refs): React.MutableRefObject<any> => {
    const targetRef = React.useRef();

    React.useEffect(() => {
        refs.forEach((ref) => {
            if (!ref) return;

            if (typeof ref === 'function') {
                ref(targetRef.current);
            } else {
                ref.current = targetRef.current;
            }
        });
    }, [refs]);

    return targetRef;
};

const Checkbox = forwardRef<HTMLInputElement, Props>(({ indeterminate, ...rest }, ref: Ref<HTMLInputElement>) => {
    const defaultRef = useRef(null);
    const resolvedRef = useCombinedRefs(ref, defaultRef);

    useEffect(() => {
        if (resolvedRef?.current) {
            resolvedRef.current.indeterminate = indeterminate;
        }
    }, [resolvedRef, indeterminate]);

    return <input type="checkbox" ref={resolvedRef} {...rest} />;
});

Checkbox.displayName = 'Checkbox';
export default Checkbox;
