import TicketsApi from '@app/api/public/TicketsApi';
import { useMutation, useQueryClient } from 'react-query';
import { ticketQueryKeys } from './ticketQueries';

const useRemoveTicketNote = () => {
    const client = useQueryClient();
    return useMutation((variables: { noteId: number; ticketId: number }) => TicketsApi.removeNote(variables), {
        onSettled: (_data, _error, variables) => {
            client.invalidateQueries(ticketQueryKeys.notes(variables.ticketId.toString()));
        },
    });
};

export default useRemoveTicketNote;
