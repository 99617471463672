import BoardMembersApi from '@app/api/public/BoardMembersApi';
import { useQuery } from 'react-query';

export const getBoardMemberListQueryKey = 'getBoardMemberList';
export const getNonBoardMemberListQueryKey = 'getNonBoardMemberList';

const getBoardMemberList = () => {
    return BoardMembersApi.list();
};

const useGetBoardMemberList = () => {
    return useQuery([getBoardMemberListQueryKey], () => getBoardMemberList());
};

const getNonBoardMemberList = () => {
    return BoardMembersApi.listNonBoardMembers();
};

const useGetNonBoardMemberList = () => {
    return useQuery([getNonBoardMemberListQueryKey], () => getNonBoardMemberList());
};

const getBoardMemberById = (id: number) => {
    return BoardMembersApi.getbyid(id);
};

const useGetBoardMemberById = (id: number) => {
    return useQuery([getBoardMemberListQueryKey, id], () => getBoardMemberById(id));
};

export { useGetBoardMemberById, useGetNonBoardMemberList, useGetBoardMemberList as default };
