import { L } from '../../../lib/i18n';
import * as React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import MembersListPage from '../ListPage/MembersListPage';
import { MembersDetailPage } from '../DetailPage/MemberPage/MembersDetailPage';
import ModalProvider from '../../../components/Modal/Context/ModalContext';
import BigNavItem from '../../../components/BigTabItem/BigTabListItemNav';
import MemberExtraInformationPage from '../DetailPage/ExtraInformationPage/MemberExtraInformationPage';
import PageTitle from '../../../components/typography/PageTitle';
import BigTabNav from '../../../components/BigTabItem/BigTabNav';
import { useGetMemberById } from '../hooks/useGetMemberList';
export const MembersPage = () => {
    const { id } = useParams<{ id: string }>();

    const { data: member } = useGetMemberById(id);

    return (
        <>
            <PageTitle>{L('members')}</PageTitle>
            <BigTabNav>
                <BigNavItem exact to={`/members`}>
                    {L('all_members')}
                </BigNavItem>
                <Switch>
                    <Route path={'/members/import'} />
                    <Route path={'/members/:id'}>
                        <div style={{ borderRight: 'solid 1px gray' }} />
                        <BigNavItem exact to={`/members/${id}`}>
                            {member?.name || member?.email}
                        </BigNavItem>
                    </Route>
                </Switch>
            </BigTabNav>

            <Switch>
                <Route exact path={'/members'}>
                    <ModalProvider>
                        <MembersListPage />
                    </ModalProvider>
                </Route>
                <Route exact path={'/members/:id'}>
                    <MembersDetailPage />
                </Route>
                <Route exact path={'/members/:id/extra'}>
                    <MemberExtraInformationPage />
                </Route>
            </Switch>
        </>
    );
};
