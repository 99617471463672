import React, { useEffect, useMemo, useState } from 'react';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import styled from '@emotion/styled';
import { RawHtml } from '../../../components/Layout/RawHtmlResidence';
import { useHistory, useParams } from 'react-router';
import dayjs from 'dayjs';
import { L } from '../../../lib/i18n';
import { MailMessage } from '@app/api/lib/mail';
import toast from 'react-hot-toast';
import { useGetMail, useSendMail } from '../hooks/mailQueries';
import AttachmentArea from '../components/Attachments/AttachmentArea';
import Divider from '../../../components/thread/divider/Divider';
import H2 from '../../../components/typography/H2';
import { style } from '../../../components/Shared/Style';
import Contacts from '../components/Contacts';
import * as EmailValidator from 'email-validator';
import useSaveMail from '../hooks/useSaveMail';
import { z } from 'zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import MailForm, { FormValues } from '../components/MailForm';

export const TopAreaWrapper = styled.div({
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
});

const unique = (array: string[]) => {
    const set = new Set(array);
    return [...set];
};

export const Reply = styled.div({
    width: '100%',
});
export const ReplyArea = styled.div({
    display: 'flex',
    justifyContent: 'center',
});

export const Message = styled.div({
    marginBottom: style.margin.m,
    marginTop: style.margin.m,
});

const mailSchema = z.object({
    subject: z.string().min(1, { message: L('required') }),
    recipients: z.array(z.string()).refine((recipients) => {
        return recipients.some((recipient) => EmailValidator.validate(recipient));
    }),
});

const MailPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { data } = useGetMail(Number(id));

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        clearErrors,
        formState: { errors },
    } = useForm<FormValues>({
        resolver: zodResolver(mailSchema),
        defaultValues: {
            recipientsInput: '',
            subject: '',
            recipients: [],
        },
    });

    //Will be defined if it's a specific mail
    const sendMail = useSendMail();

    const [editorValue, setEditorValue] = useState<string>('');
    const [showContacts, setShowContacts] = useState(false);
    const history = useHistory();

    const subjectValue = watch('subject');
    const recipientsInput = watch('recipientsInput');
    const recipients = watch('recipients');
    const memoizedRecipients = useMemo(() => {
        return recipients.map((recipient) => {
            return {
                value: recipient,
                id: self.crypto.randomUUID() as string,
            };
        });
    }, [recipients]);

    const handleSetEditorValue = (value: string) => {
        setEditorValue(value);
    };
    const handleSetRecipients = (recipients: string[]) => {
        setValue('recipients', recipients);
    };
    const handleSetRecipientsInput = (input: string) => {
        setValue('recipientsInput', input);
    };
    const handleSetSubjectInput = (input: string) => {
        setValue('subject', input);
    };
    const handleSetShowContact = (): void => {
        setShowContacts(true);
    };

    useEffect(() => {
        register('recipientsInput');
        register('subject', { required: true });
        register('recipients', { required: true });
    }, [register]);

    useEffect(() => {
        clearErrors('subject');
    }, [subjectValue]);

    useEffect(() => {
        clearErrors(['recipients', 'recipientsInput']);
    }, [recipientsInput, recipients]);

    useEffect(() => {
        if (data && data?.created_by_mail && EmailValidator.validate(data.created_by_mail)) {
            handleSetRecipients([data?.created_by_mail]);
        }
    }, [data]);

    const { draftMail } = useSaveMail(subjectValue, editorValue, recipients, data?.id);

    useEffect(() => {
        if (draftMail) {
            setValue('subject', draftMail?.subject);
            setEditorValue(draftMail?.message);
            handleSetRecipients(draftMail.sent_to ?? []);
        }
    }, [draftMail?.subject, draftMail?.message, draftMail?.sent_to]);

    const mailSubmit: SubmitHandler<FormValues> = async () => {
        const validRecipients = unique(recipients.filter((recipient) => EmailValidator.validate(recipient)));

        const mailToSend: MailMessage = {
            from: '',
            recipients: validRecipients,
            subject: subjectValue,
            html: editorValue,
            text: '',
            replyTo: '',
        };

        sendMail.mutate(
            { mail: mailToSend, attachments: draftMail?.attachments ?? [], draftId: draftMail?.id },
            {
                onSuccess: () => {
                    history.push('/mail');
                    toast.success(L('mail_sent'));
                },
                onError: () => {
                    toast.error(L('mail_error'));
                },
            }
        );
    };

    const handleSubmitForm = handleSubmit(mailSubmit);

    const saveSelectedContacts = (selected: { [key: string]: boolean }) => {
        const selectedMails: string[] = [];
        for (const [mail, isSelected] of Object.entries(selected)) {
            if (isSelected) {
                selectedMails.push(mail);
            }
        }
        handleSetRecipients([...recipients, ...selectedMails]);
        setShowContacts(false);
    };

    useEffect(() => {
        if (!draftMail) {
            handleSetSubjectInput(data?.subject ? `Sv: ${data.subject}` : '');
        }
    }, [draftMail, data]);

    return (
        <CardWithDivider
            topArea={
                <TopAreaWrapper>
                    <CardTitle>
                        {data
                            ? data?.created_by_name
                                ? `${data?.created_by_name} (${data.created_by_mail})`
                                : data.created_by_mail
                            : `${L('loading')}...`}
                    </CardTitle>
                    <CardTitle>
                        {data ? dayjs(data?.created_at).format('DD-MM-YYYY HH:mm') : `${L('loading')}...`}
                    </CardTitle>
                </TopAreaWrapper>
            }
            mainArea={
                <>
                    <Contacts close={() => setShowContacts(false)} show={showContacts} save={saveSelectedContacts} />
                    <div>
                        <Message>
                            <div>
                                <h2>{data?.subject}</h2>
                                {data?.message && <RawHtml content={data.message} />}
                            </div>
                        </Message>
                        {data?.attachments && data.attachments?.length > 0 && (
                            <>
                                <Divider width={100} />
                                <H2 style={{ margin: 0 }}>{L('attachments')}</H2>
                                <AttachmentArea attachments={data.attachments} />
                            </>
                        )}
                    </div>
                    <Divider width={100} />
                    <ReplyArea>
                        <Reply>
                            <h2>{L('reply')}</h2>
                            <MailForm
                                errors={errors}
                                handleMailSubmit={handleSubmitForm}
                                handleSetRecipientsInput={handleSetRecipientsInput}
                                handleSetShowContact={handleSetShowContact}
                                handleSetRecipients={handleSetRecipients}
                                handleSetEditorValue={handleSetEditorValue}
                                handleSetSubjectInput={handleSetSubjectInput}
                                recipients={recipients}
                                memoizedRecipients={memoizedRecipients}
                                recipientsInput={recipientsInput}
                                subjectValue={subjectValue}
                                draftMail={draftMail}
                                editorValue={editorValue}
                            />
                        </Reply>
                    </ReplyArea>
                </>
            }
        />
    );
};

export default MailPage;
