Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["list"] = createRpcProxy("ContentNewsApi", "list", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["get"] = createRpcProxy("ContentNewsApi", "get", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["editPublishedNews"] = createRpcProxy("ContentNewsApi", "editPublishedNews", {
  "urlPrefix": "/rpc"
});
o["publishNews"] = createRpcProxy("ContentNewsApi", "publishNews", {
  "urlPrefix": "/rpc"
});
o["setNewsAsDraft"] = createRpcProxy("ContentNewsApi", "setNewsAsDraft", {
  "urlPrefix": "/rpc"
});
o["listDocumentGroups"] = createRpcProxy("ContentNewsApi", "listDocumentGroups", {
  "urlPrefix": "/rpc"
});
o["saveDocuementGroup"] = createRpcProxy("ContentNewsApi", "saveDocuementGroup", {
  "urlPrefix": "/rpc"
});
o["listDocuments"] = createRpcProxy("ContentNewsApi", "listDocuments", {
  "urlPrefix": "/rpc"
});
o["updateDocumentName"] = createRpcProxy("ContentNewsApi", "updateDocumentName", {
  "urlPrefix": "/rpc"
});
o["saveDocument"] = createRpcProxy("ContentNewsApi", "saveDocument", {
  "urlPrefix": "/rpc"
});
o["deleteDocumentFromContentNews"] = createRpcProxy("ContentNewsApi", "deleteDocumentFromContentNews", {
  "urlPrefix": "/rpc"
});
o["resendNotifications"] = createRpcProxy("ContentNewsApi", "resendNotifications", {
  "urlPrefix": "/rpc"
});
o["remove"] = createRpcProxy("ContentNewsApi", "remove", {
  "urlPrefix": "/rpc"
});
o["createDraft"] = createRpcProxy("ContentNewsApi", "createDraft", {
  "urlPrefix": "/rpc"
});
o["saveDraft"] = createRpcProxy("ContentNewsApi", "saveDraft", {
  "urlPrefix": "/rpc"
});
o["createNewsAndPublish"] = createRpcProxy("ContentNewsApi", "createNewsAndPublish", {
  "urlPrefix": "/rpc"
});
o["saveAndPublish"] = createRpcProxy("ContentNewsApi", "saveAndPublish", {
  "urlPrefix": "/rpc"
});
o["removeCoverImage"] = createRpcProxy("ContentNewsApi", "removeCoverImage", {
  "urlPrefix": "/rpc"
});