import { ProfileDetail } from '@app/api/models/Profile';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React from 'react';
import RemoveButton from '../../../components/Forms/RemoveButton/RemoveButton';
import SendButton from '../../../components/Forms/SendButton/SendButton';
import { L } from '../../../lib/i18n';
import useGetUserBookings from '../hooks/useGetUserBookings';

const Send = styled(SendButton)({
    width: '60px',
});

const NoDay = styled(SendButton)({
    width: '60px',
    opacity: 0.3,
    pointerEvents: 'none',
});

const Remove = styled(RemoveButton)({
    width: '60px',
});

type Props = {
    save: () => Promise<void>;
    remove: () => Promise<void>;
    date?: Date;
    selectedUser?: ProfileDetail;
    bookingId?: number;
};
const Bookbutton = ({ save, remove, date, selectedUser, bookingId }: Props) => {
    const { data: userBookings } = useGetUserBookings({
        resourceId: bookingId,
        userId: selectedUser?.id,
    });

    if (!selectedUser) return <span>Välj en boende</span>;
    if (!date) {
        return <NoDay label={L('book')} />;
    }

    const userBooking = userBookings?.find(
        (item) => dayjs(item.booking_date).format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD')
    );

    if (userBooking) {
        return <Remove onClick={remove} label={L('cancel_booking')} />;
    }

    return <Send onClick={save} label={L('book')} />;
};

export default Bookbutton;
