import { BookingDetail } from '@app/api/models/Bookings';
import React from 'react';
import { Card } from '../../../components/Layout/Card';

import ReactTable from '../../../components/Table/ReactTable/FilterTable';
import { IPageOf } from '@app/api/lib/db';
import { useParams } from 'react-router';
import useRemoveBooking from '../hooks/useRemoveBooking';
import bookingListColumns from './Columns';
import { Pagination } from '../../notifications/components/Pagination';

type Props = {
    bookings: IPageOf<BookingDetail>;
    setPage: React.Dispatch<React.SetStateAction<number>>;
};

const BookingListTable = ({ bookings, setPage }: Props) => {
    const { bookingId } = useParams<{ bookingId: string }>();
    const { mutate: removeBooking } = useRemoveBooking();

    return (
        <Card padding="16px" background={'#ffffff'}>
            <ReactTable columns={bookingListColumns({ removeBooking, bookingId })} data={bookings.items} />
            <span style={{ alignSelf: 'flex-center' }}>
                <Pagination page={bookings} onChangePage={setPage} />
            </span>
        </Card>
    );
};

export default BookingListTable;
