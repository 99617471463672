import { useQuery } from 'react-query';
import PublicSiteApi from '@app/api/public/PublicSiteApi';

const getAddress = async () => {
    return await PublicSiteApi.getProjectInfo();
};

const useGetAddress = () => {
    return useQuery('getAddress', () => getAddress());
};

export default useGetAddress;
