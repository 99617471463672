import styled from '@emotion/styled';
import React from 'react';

const StyledWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    position: 'relative',
    textDecoration: 'none',
    padding: '0.5rem',
    height: '100%',
    width: '100%',
});

const StyledImg = styled.img({
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    aspectRatio: '1/1',
});

const ImageAttachment = ({ url }: { url: string }) => {
    return (
        <StyledWrapper>
            <StyledImg src={url} />
        </StyledWrapper>
    );
};

export default ImageAttachment;
