import React, { useState } from 'react';
import { L } from '../../../../lib/i18n';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle, { CardSpan } from '../../../../components/typography/CardTitle';
import { useLocalStorage } from 'react-use';
import { memberEventQueryKeys, useGetContentEventList } from '../hooks/boardEventQueries';
import { escapeRegex } from '../../../../lib/regex';
import {
    CardGrid,
    Card,
    CardImage,
    CardTimestamp,
    CardGridTitle,
    CardDescription,
} from '../../../../components/CardGrid/CardGrid';
import useGetSignedUrlById from '../../../../hooks/useGetSignedUrlById';
import { medium } from '../../../../lib/imageSize';
import { Link } from 'react-router-dom';
import { Button } from '../../../../components/Buttons/Button';
import GlobalFilter from '../../../../components/Table/filterComponents/GlobalFilter';
import ToggleVIew from '../../../../components/ToggleView/ToggleVIew';
import styled from '@emotion/styled';
import { style } from '../../../../components/Shared/Style';
import { tableHeader, title, tooltip } from '../../../../components/Shared/Style/typography';
import { formatDateIsoDayjs } from '@app/shared/lib/formatting';
import { convert } from 'html-to-text';
import Feed from '../../../../components/Feed/Feed';
import useQueryParams from '../../../../hooks/useQueryParams';
import { Pagination } from '../../../notifications/components/Pagination';
import BoardContentEventApi from '@app/api/public/BoardContentEventApi';
import { ContentEventItem } from '@app/api/models/BoardContentEvent';
import EmptyState from '../../../../components/EmptyState/EmptyState';
import { CardGridImage } from '../../../../components/CardGrid/CardImage';

const templateColumns = '1fr 0.4fr 0.4fr 0.2fr' as const;

const Headers = styled.div(() => ({
    margin: '0px',
    display: 'grid',
    gridTemplateColumns: templateColumns,
    columnGap: '2rem',
    alignItems: 'baseline',
    padding: '0px 0.5rem',
}));

const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '24px',
    flexDirection: 'row',
    gap: '8px',
});

const FeedListItem = styled(Link)({
    borderBottom: `1px solid ${style.colors.divider}`,
    padding: '1rem 0.5rem',
    display: 'grid',
    gap: '2rem',
    alignItems: 'center',
    gridTemplateColumns: templateColumns,
    '&:first-of-type': {
        borderTop: `1px solid ${style.colors.divider}`,
    },
});

const ListTitle = styled.span({
    ...title,
});

const ListTimeStamp = styled.span({
    ...tooltip,
});

const HeaderText = styled.span({
    ...tableHeader,
});

const StyledFeed = styled(Feed)({
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fill, minmax(300px, 1fr) )',
    gap: '2rem',
});

const EventPublishedListPage = () => {
    const [view, setView] = useLocalStorage<'list' | 'card'>('eventView', 'list');
    const { getQueryString, updateQueryString } = useQueryParams();
    const page = getQueryString('page', '');
    const { data: eventList, status: eventStatus } = useGetContentEventList('published', Number(page));
    const [filter, setFilter] = useState('');

    if (eventStatus === 'loading') return <CardTitle>{L(`all_event_published`)}</CardTitle>;

    if (!eventList) return <div>{L('error')} kunde inte hämta nyhetslistan</div>;

    const searchRegexp = new RegExp(`(${escapeRegex(filter)})`, 'gmi');

    const filteredList = eventList.items.filter((item) => {
        if (!filter) return true;
        const title = item.title.match(searchRegexp);
        const description = item.description.match(searchRegexp);
        return title || description;
    });

    const EventList =
        view === 'list' ? (
            !filteredList.length ? (
                <EmptyState>
                    <EmptyState.Title>
                        {L('no_search_title')} &quot;{filter}&quot;
                    </EmptyState.Title>
                    <EmptyState.Description>{L('no_search_description')}</EmptyState.Description>
                </EmptyState>
            ) : (
                <div>
                    <Headers>
                        <HeaderText>{L('title')}</HeaderText>
                        <HeaderText>{L('event_date')}</HeaderText>
                        <HeaderText>{L('published_at')}</HeaderText>
                        <HeaderText>{L('participants')}</HeaderText>
                    </Headers>

                    {filteredList &&
                        filteredList.map((event) => (
                            <FeedListItem key={event.id} to={`/board-event/${event.id}`}>
                                <ListTitle>{event.title}</ListTitle>
                                <ListTimeStamp>{formatDateIsoDayjs(event.event_date)}</ListTimeStamp>
                                <ListTimeStamp>{formatDateIsoDayjs(event.published_at)}</ListTimeStamp>
                                <ListTimeStamp>{event.participation_count}</ListTimeStamp>
                            </FeedListItem>
                        ))}
                    <div style={{ paddingTop: '1rem' }}>
                        <Pagination
                            page={eventList}
                            onChangePage={(newPage) => updateQueryString('page', String(newPage))}
                        />
                    </div>
                </div>
            )
        ) : !filteredList.length ? (
            <EmptyState>
                <EmptyState.Title>
                    {L('no_search_title')} &quot;{filter}&quot;
                </EmptyState.Title>
                <EmptyState.Description>{L('no_search_description')}</EmptyState.Description>
            </EmptyState>
        ) : (
            <StyledFeed
                feedFilter={() => true}
                threshold={500}
                cacheKey={memberEventQueryKeys.infiniteList('published', Number(page))}
                asyncFn={({ pageParam }) => BoardContentEventApi.list('published', pageParam)}
                emptyResponse={L('no_feed_items')}
            >
                {(item: ContentEventItem) => {
                    return (
                        <Link key={item.id} to={`/board-event/${item.id}`}>
                            <Card>
                                <CardGridImage originalId={item.cover_image_data?.original?.id || ''} />
                                <CardTimestamp date={''} />
                                <CardGridTitle>{item.title}</CardGridTitle>
                                <CardDescription>{convert(item.description)}</CardDescription>
                            </Card>
                        </Link>
                    );
                }}
            </StyledFeed>
        );

    return (
        <CardWithDivider
            topArea={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <CardTitle>
                        {L(`all_event_published`)} <CardSpan>({eventList.totalItems})</CardSpan>
                    </CardTitle>
                    <Link to={`/board-event/new`}>
                        <Button>{L('event_content')}</Button>
                    </Link>
                </div>
            }
            mainArea={
                <>
                    {!eventList.totalItems ? (
                        <EmptyState>
                            <EmptyState.Title>{L('no_events_title')}</EmptyState.Title>
                            <EmptyState.Description>{L('no_events_description')}</EmptyState.Description>
                            <Link to={`/board-event/new`}>
                                <EmptyState.Button variant="filledPrimary">{L('event_content')}</EmptyState.Button>
                            </Link>
                        </EmptyState>
                    ) : (
                        <>
                            <Wrapper>
                                <GlobalFilter placeholder="Sök" filter={filter} setFilter={setFilter} />
                                <ToggleVIew view={view} setView={setView} />
                            </Wrapper>
                            {EventList}
                        </>
                    )}
                </>
            }
        />
    );
};

export default EventPublishedListPage;
