import React from 'react';
import { Route } from 'react-router-dom';
import { BoardMembersPage } from './Page/BoardMemberPage';

export const routes = [
    <Route key="1" path="/boardmembers/:id" component={BoardMembersPage} />,
    <Route key="0" path="/boardmembers" component={BoardMembersPage} />,
];

export const navigation = { name: 'boardmembers', link: '/boardmembers', icon: 'BoardMember', parent: 'BoardMembers' };
