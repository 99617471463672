import React from 'react';

export function Grid({ children }) {
    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: `repeat(auto-fill,minmax(200px,1fr))`,
                gap: 10,
                padding: 10,
            }}
        >
            {children}
        </div>
    );
}
