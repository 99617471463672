import React, { HTMLAttributes } from 'react';
import mime from 'mime';
import useGetSignedAttachmentById from '../../hooks/useGetSignedAttachmentById';
import AttachmentDropdown from './AttachmentDropdown';

type Props = {
    id: string;
    deleteDocument?: (s3key: string) => void;
} & HTMLAttributes<HTMLDivElement>;
const Attachment = ({ id, deleteDocument }: Props) => {
    const { data: url } = useGetSignedAttachmentById(id, '200x200');
    const mimeType = mime.getType(id || '');
    const fileCategory = mimeType?.split('/')[0] ?? '';

    return (
        <AttachmentDropdown
            name={id.split('/').pop() ?? ''}
            premadeUrl={url ?? ''}
            fileType={mimeType ?? ''}
            fileCategory={fileCategory}
            deleteDocument={deleteDocument}
            id={id}
        />
    );
};

export default Attachment;
