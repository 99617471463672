import { BookingResource } from '@app/api/models/Bookings';
import BookingsApi from '@app/api/public/BookingsApi';
import { useMutation, useQueryClient } from 'react-query';
import { getBookingResourcesQueryKey } from './useGetBookingResources';

const usePublishResource = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (resourceId: BookingResource['id']) => {
            return BookingsApi.togglePublish(resourceId);
        },
        {
            onSettled: (_data, _error, vaiables) => {
                queryClient.invalidateQueries(getBookingResourcesQueryKey.all);
            },
        }
    );
};

export default usePublishResource;
