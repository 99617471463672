import * as React from 'react';
import { Button } from '../../components/Buttons/Button';
import { L } from '../../lib/i18n';
import { style } from '../../components/Shared/Style';
import queryString from 'query-string';
import ReactPasswordStrength from 'react-password-strength';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import Spinner from '../../components/Spinner/Spinner';
import { ChangeCallback, PasswordState } from '../profile/Components/ChangePasswordProfilePage';
import useConfirmOrChangePassword from './hooks/useConfirmOrChangePassword';
import validateToken from './hooks/validateToken';
import Logo from '../../components/Logo/Logo';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import styled from '@emotion/styled';

export interface PasswordToken {
    token: string;
    invitation_token: string;
}

const Header = styled.div({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: style.colors.secondary,
    paddingBottom: style.margin.xs,
});

const StyledLogo = styled(Logo)({
    maxWidth: '300px',
    width: '100%',
    height: '100%',
    maxHeight: '100px',
    placeSelf: 'center',
    backgroundColor: 'inherit',
});

const Error = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: style.margin.l,
});
const ErrorMessage = styled.strong({
    color: 'red',
});

const Background = styled.div({
    width: '100%',
    minHeight: '100vh',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const Sucess = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: style.margin.l,
});
const SuccessMessage = styled.strong({
    color: style.colors.primary,
});

const TextField = styled.div({
    paddingBottom: style.margin.ml,
});

const LoginForm = styled.div({
    width: '90%',
});

const Form = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    padding: '70px 12px 40px 12px',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    borderRadius: style.roundCornerSize.medium,
    flexDirection: 'column',
    width: '378px',
    height: '450px',
    [`@media screen and (min-width: 500px)`]: {
        padding: '70px 12px 40px 12px',
    },
}));

const StyledReactPasswordStrength = styled(ReactPasswordStrength)({
    background: style.colors.inputBackground,
    border: `1px solid ${style.colors.inputBorder}`,
    height: '42px',
    borderRadius: style.roundCornerSize.small,
    outline: 'none',
    fontSize: style.fontSize.seta,
    lineHeight: style.lineHeight.normal,
    '.ReactPasswordStrength-strength-desc': {
        width: '30%',
    },
    '.ReactPasswordStrength-input': {
        padding: `${style.margin.s}px`,
        fontSize: style.fontSize.seta,
        height: '39px',
    },
});

type AwaitedToken =
    | {
          isValid: boolean;
          message: string;
          token?: undefined;
      }
    | {
          isValid: boolean;
          token: string;
          message?: undefined;
      };

export const SetResetPasswordPage = () => {
    const location = useLocation();
    const history = useHistory();
    const { token } = queryString.parse(location.search);
    const [validatedToken, setValidatedToken] = useState<AwaitedToken>();

    const [state, setState] = useState<PasswordState>('');
    const [newPassword, setNewPassword] = useState<ChangeCallback | undefined>();
    const [newRepeatPassword, setNewRepeatPassword] = useState<ChangeCallback | undefined>();
    const [isValid, setIsValid] = useState(false);
    const newPasswordRef = useRef<{ clear: () => void }>();
    const newRepeatPasswordRef = useRef<{ clear: () => void }>();

    const { mutate: changePassword, status: changePasswordStatus } = useConfirmOrChangePassword();

    useEffect(() => {
        const getValidatedToken = async () => {
            const awaitedToken = await validateToken(token, location.pathname);
            setValidatedToken(awaitedToken);
        };
        getValidatedToken();
    }, [token]);

    const handleUpdatePassword = () => {
        setState('');
        if (!newPassword || !newRepeatPassword) return;
        if (newPassword.password !== newRepeatPassword.password) {
            return setState('password_not_matched');
        }
        if (!validatedToken?.isValid || !validatedToken.token) return toast.error(`${validatedToken?.message}`);
        changePassword(
            { newPassword: newPassword.password, token: validatedToken.token },
            {
                onSuccess: () => {
                    toast.success('Ditt nya lösenord är nu satt.');
                    history.push('/');
                },
            }
        );
    };

    useEffect(() => {
        setState('');
        if (!newPassword?.isValid) {
            return setIsValid(false);
        }
        if (!newRepeatPassword?.isValid) {
            return setIsValid(false);
        }

        if (newPassword.password !== newRepeatPassword.password) {
            setState('password_not_matched');
            return setIsValid(false);
        }

        setIsValid(true);
    }, [newPassword, newRepeatPassword]);

    return (
        <Background>
            <Form>
                <StyledLogo />

                <LoginForm>
                    {validatedToken?.message && (
                        <Error>
                            <ErrorMessage>{L(`token_${validatedToken?.message}`)}</ErrorMessage>
                        </Error>
                    )}
                    {state && state !== 'password_changed' && (
                        <Error>
                            <ErrorMessage>{L('reset_' + state)}</ErrorMessage>
                        </Error>
                    )}
                    {state === 'password_changed' && (
                        <Sucess>
                            <SuccessMessage>{L('reset_' + state)}</SuccessMessage>
                        </Sucess>
                    )}

                    <div>
                        <Header>{L('new_password')}</Header>
                        <TextField>
                            <StyledReactPasswordStrength
                                style={{
                                    border: `1px solid ${style.colors.inputBorder}`,
                                }}
                                minLength={5}
                                minScore={2}
                                ref={newPasswordRef}
                                tooShortWord={L('password_short')}
                                changeCallback={(e) => setNewPassword(e)}
                                scoreWords={[
                                    L('password_not_ok'),
                                    L('password_not_ok'),
                                    L('password_ok'),
                                    L('password_strong'),
                                    L('password_great'),
                                ]}
                                inputProps={{ autoComplete: 'off', placeholder: [L('password_required_length')] }}
                            />
                        </TextField>
                    </div>
                    <div>
                        <Header>{L('confirm_password')}</Header>
                        <TextField>
                            <StyledReactPasswordStrength
                                style={{
                                    border: `1px solid ${style.colors.inputBorder}`,
                                }}
                                ref={newRepeatPasswordRef}
                                minLength={6}
                                minScore={2}
                                tooShortWord={L('password_short')}
                                scoreWords={[
                                    L('password_not_ok'),
                                    L('password_not_ok'),
                                    L('password_ok'),
                                    L('password_strong'),
                                    L('password_great'),
                                ]}
                                changeCallback={(e) => setNewRepeatPassword(e)}
                                inputProps={{
                                    autoComplete: 'off',
                                    placeholder: [L('password_required_length')],
                                }}
                            />
                        </TextField>
                    </div>
                    <div style={{ display: 'grid', width: '100%', marginTop: '1rem' }}>
                        {changePasswordStatus === 'idle' && (
                            <Button
                                onClick={handleUpdatePassword}
                                style={{ marginRight: '0px', justifySelf: 'center' }}
                                variant={'selectedMenu'}
                                disabled={!isValid}
                            >
                                {state ? L('reset_' + state) : L('change_password')}
                            </Button>
                        )}
                        {changePasswordStatus === 'loading' && (
                            <Button
                                style={{
                                    marginRight: '0px',
                                    justifySelf: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                                variant={'selectedMenu'}
                                disabled={!isValid}
                            >
                                <Spinner />
                            </Button>
                        )}
                        {changePasswordStatus === 'success' && (
                            <Button
                                onClick={handleUpdatePassword}
                                style={{ marginRight: '0px', justifySelf: 'center' }}
                                variant={'selectedMenu'}
                                disabled={!isValid}
                            >
                                {L('change_password_success')}
                            </Button>
                        )}
                        {changePasswordStatus === 'error' && (
                            <Button
                                onClick={handleUpdatePassword}
                                style={{ marginRight: '0px', justifySelf: 'center' }}
                                variant={'selectedMenu'}
                                disabled={!isValid}
                            >
                                {L('error')}
                            </Button>
                        )}
                    </div>
                </LoginForm>
            </Form>
        </Background>
    );
};
