import React from 'react';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardWithDividerTopArea from '../../../components/Layout/CardWithDividerTopArea';
import { L } from '../../../lib/i18n';
import Images from './Images';

const ProjectImages = () => {
    return (
        <CardWithDivider
            topArea={<CardWithDividerTopArea title={L('project_info_images_description')} />}
            mainArea={<Images />}
        />
    );
};

export default ProjectImages;
