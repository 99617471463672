import DocumentsApi from '@app/api/public/DocumentsApi';
import { useQuery } from 'react-query';
import { fileKeys } from '../../queryKeys';
import { DocumentsSearchQuery } from '@app/api/models/Documents';

const useGetFilesListByFolderId = (folderId: number | null, query?: DocumentsSearchQuery) => {
    return useQuery([...fileKeys.list(folderId ? folderId : undefined, query)], () =>
        DocumentsApi.getFilesByFolderId(folderId, query)
    );
};

export default useGetFilesListByFolderId;
