import { SaveBookingsVariables } from '@app/api/models/Bookings';
import BookingsApi from '@app/api/public/BookingsApi';
import { useMutation, useQueryClient } from 'react-query';
import { useGetBookingDatesQueryKey } from './useGetBookingDates';
import { useGetBookingsByResourceIdQueryKey } from './useGetBookingsByResourceId';
import { useGetBookingsListQueryKey } from './useGetBookingsList';
import { useGetBookinUsersQueryKey } from './useGetBookingUsers';
import { getUserBookingsQueryKey } from './useGetUserBookings';

type Variables = SaveBookingsVariables;

const useRemoveBooking = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (variables: Variables) => {
            return BookingsApi.deleteBooking(variables);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(useGetBookingsByResourceIdQueryKey);
                queryClient.invalidateQueries(getUserBookingsQueryKey);
                queryClient.invalidateQueries(useGetBookingsListQueryKey);
                queryClient.invalidateQueries(useGetBookinUsersQueryKey);
                queryClient.invalidateQueries(useGetBookingDatesQueryKey);
            },
        }
    );
};

export default useRemoveBooking;
