import React, { FC } from 'react';
import styled from '../../../components/lib/styled';

type Props = {
    description: string;
    isCollapsed?: boolean;
    className?: string;
};

const Title = styled.p({
    maxHeight: '3.6em',
    lineHeight: '1.8em',
    wordWrap: 'break-word',
    overflow: 'hidden',
    fontSize: '13px',
    fontWeight: 600,
    gridArea: 'title',
    placeSelf: 'start',
    whiteSpace: 'nowrap',
    margin: '0px',
    textDecoration: 'none',
    maxWidth: '100%',
});

const maxTextLength = 75;

const NoticeTitle: FC<Props> = ({ description }) => {
    const partialText =
        description?.length > maxTextLength ? `${description.substr(0, maxTextLength)}... ` : description;
    return <Title>{partialText}</Title>;
};

export default NoticeTitle;
