import styled from '@emotion/styled';
import React from 'react';
import { style } from '../Shared/Style';
import { colors } from '../Shared/Style/colors';

const Label = styled.div((p: { status?: keyof typeof colors }) => ({
    width: '100%',
    position: 'fixed',
    textAlign: 'center',
    top: 0,
    left: 0,
    backgroundColor: p.status ? style.colors[p.status] : style.colors.alertPrimary,
    padding: style.margin.m,
    borderRadius: style.roundCornerSize.small,
    zIndex: 99,
}));

const Text = styled.span({
    opacity: 0.7,
});

const Close = styled.div({
    position: 'absolute',
    width: '12px',
    height: '12px',
    right: 15,
    top: 15,
    opacity: 0.7,
    cursor: 'pointer',
});

type Props = {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    status?: keyof typeof colors;
    text: string;
};
function ErrorNotice({ status, onClick, text }: Props) {
    return (
        <Label status={status}>
            <Text>{text}</Text>
            <Close onClick={onClick}>x</Close>
        </Label>
    );
}

export default ErrorNotice;
