import styled from 'styled-components';

export const StyledForm = styled.form({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '.5rem',
});

export const ButtonContainer = styled.div({
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%',
});
