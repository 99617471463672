import React from 'react';
import styled from '@emotion/styled';
import { Button } from '../../../components/Buttons/Button';
import useSendOrderMail from '../hooks/useSendOrderMail';
import { L } from '../../../lib/i18n';
import Spinner from '../../../components/Spinner/Spinner';
import { colors } from '../../../components/Shared/Style/colors';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardWithDividerTopArea from '../../../components/Layout/CardWithDividerTopArea';

const Container = styled.div({
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '4px',
    marginBottom: '5rem',
});

const MainWrapper = styled.div({
    margin: '0rem 4rem 0 4rem',
});

const H1 = styled.h1({
    marginTop: '1.5rem',
    marginBottom: '1rem',
    fontWeight: 400,
});

const Success = styled.span(({ theme }) => ({
    color: theme.colors.primary,
}));

const OrderPublicSitePage = () => {
    const { mutate, status } = useSendOrderMail();

    const submit = () => {
        mutate();
    };

    return (
        <CardWithDivider
            topArea={
                <CardWithDividerTopArea title={L('activate_homepage_title')}>
                    {status === 'idle' && (
                        <Button variant="selectedMenu" onClick={submit}>
                            {L('activate_homepage')}
                        </Button>
                    )}
                    {status === 'loading' && (
                        <Button disabled={true} variant="loading" onClick={submit}>
                            <Spinner />
                        </Button>
                    )}
                    {status === 'error' && (
                        <Button variant="selectedMenu" onClick={submit}>
                            {L('reset_error')}
                        </Button>
                    )}
                    {status === 'success' && <Success>{L('order_page_success')} 🎉</Success>}
                </CardWithDividerTopArea>
            }
            mainArea={
                <Container>
                    <MainWrapper>
                        <span>
                            {L('activate_homepage_p1')}
                            <br />
                            <br />
                            {L('activate_homepage_p2')}
                        </span>
                        <br />
                    </MainWrapper>
                </Container>
            }
        />
    );
};

export default OrderPublicSitePage;
