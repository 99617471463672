import { SaveNote } from '@app/api/models/Tickets';
import TicketsApi from '@app/api/public/TicketsApi';
import { useMutation, useQueryClient } from 'react-query';
import { ticketQueryKeys } from './ticketQueries';

const useSaveTicketNote = () => {
    const client = useQueryClient();
    return useMutation(
        (variables: { note: SaveNote; files?: File[] }) => TicketsApi.saveNote(variables.note, variables.files),
        {
            onSettled: (_data, _error, variables) => {
                client.invalidateQueries(ticketQueryKeys.notes(variables.note.ticketId.toString()));
            },
        }
    );
};

export default useSaveTicketNote;
