import { AnalyticCostByYear } from '@app/api/models/Maintenances';
import dayjs from 'dayjs';

const simpleMovingAverage = (prices: number[], window = 5) => {
    if (!prices || prices.length < window) {
        return [];
    }

    let index = window - 1;
    const length = prices.length + 1;

    const simpleMovingAverages: (number | undefined)[] = [];

    while (++index < length) {
        if (index < window) {
            simpleMovingAverages.push(undefined);
            continue;
        }
        const windowSlice = prices.slice(index - window, index);
        const sum = windowSlice.reduce((prev, curr) => prev + curr, 0);
        simpleMovingAverages.push(sum / window);
    }

    return simpleMovingAverages;
};

const getTotalValueForYear = (year: string, analtyicList: AnalyticCostByYear[]) => {
    const reducer = (prev: number, curr: AnalyticCostByYear) => {
        const sum = prev + (+curr.completed_amount || +curr.planned_amount);
        return sum;
    };
    const value = analtyicList.filter((analytic) => +analytic.year === +year).reduce(reducer, 0);

    return value;
};

const getYears = (maxYear: number) => {
    let startYear = +dayjs().format('YYYY') - 1;

    const years: string[] = [];

    while (startYear++ < maxYear) {
        years.push(startYear.toString());
    }

    return years;
};

const cumulativeAvrage = (analtyicList: AnalyticCostByYear[]) => {
    const uniqueYears = [...new Set(analtyicList.map((item) => item.year)).values()];
    const maxYear = Math.max(...uniqueYears);
    const years = getYears(maxYear);

    const pricesEachYear = years.map((year) => getTotalValueForYear(year, analtyicList));

    const prices = pricesEachYear.map(
        (_price, index, priceArr) => priceArr.slice(0, index + 1).reduce((prev, curr) => prev + curr, 0) / (index + 1)
    );
    return prices;
};

export { cumulativeAvrage, simpleMovingAverage as default };
