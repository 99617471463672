import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import useSavePageListItem from '../hooks/useSavePageListItem';
import { L } from '../../../lib/i18n';
import Label from '../../Forms/Label';
import Input from '../../Forms/Input';
import { Button } from '../../Buttons/Button';
import Quill from '../../Quill/Quill';
import CardWithDivider from '../../Layout/CardWithDivider';
import CardWithDividerTopArea from '../../Layout/CardWithDividerTopArea';

const AbortWrapper = styled(Link)({
    textDecoration: 'none',
});

const ButtonWrapper = styled.div({
    display: 'flex',
    gap: '1rem',
    margin: '1rem 0px',
    justifyContent: 'flex-end',
});

const AddPageView = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const { mutate: savePageListItem, status } = useSavePageListItem();

    const save = () => {
        savePageListItem({ newPage: { title, description } });
    };

    return (
        <CardWithDivider
            topArea={<CardWithDividerTopArea title={L('add_new_page_title')} />}
            mainArea={
                <>
                    <Label title={L('title')}>
                        <Input placeholder="Titel..." onChange={(e) => setTitle(e.target.value)} />
                    </Label>
                    <Label title={L('content')} />
                    <Quill onChange={(value) => setDescription(value)} />
                    <ButtonWrapper>
                        {status !== 'loading' && (
                            <AbortWrapper to="/public-site">
                                <Button variant={'filledPrimary'}>{L('cancel')}</Button>
                            </AbortWrapper>
                        )}
                        <Button variant={'filledPrimary'} onClick={save}>
                            {status === 'loading' ? L('save') : L('add_item')}
                        </Button>
                    </ButtonWrapper>
                </>
            }
        />
    );
};

export default AddPageView;
