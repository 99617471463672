import { useMutation, useQueryClient } from 'react-query';
import ContractsApi from '@app/api/public/ContractsApi';
import { getContractKeys } from './useContract';
import toast from 'react-hot-toast';

const handleReturn = (
    data:
        | { status: 'error'; message: string }
        | { status: 'success'; data: any }
        | { status: string; message?: string; data?: any }
) => {
    if (data.status === 'error') {
        throw new Error(data.message);
    } else if (data.status === 'success') {
        return data.data;
    }
    return undefined;
};

const deleteType = async (id: number) => {
    return ContractsApi.deleteType(id);
};

const useDeleteType = () => {
    const queryClient = useQueryClient();
    return useMutation(async (id: number) => handleReturn(await deleteType(id)), {
        onSuccess: () => {
            queryClient.invalidateQueries(getContractKeys.types());
        },
        onError: (error: { message: string }) => {
            toast.error(error.message);
        },
    });
};

export default useDeleteType;
