Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["listMails"] = createRpcProxy("MailApi", "listMails", {
  "urlPrefix": "/rpc"
});
o["getMail"] = createRpcProxy("MailApi", "getMail", {
  "urlPrefix": "/rpc"
});
o["getOutgoingMail"] = createRpcProxy("MailApi", "getOutgoingMail", {
  "urlPrefix": "/rpc"
});
o["getUnreadCount"] = createRpcProxy("MailApi", "getUnreadCount", {
  "urlPrefix": "/rpc"
});
o["listFolders"] = createRpcProxy("MailApi", "listFolders", {
  "urlPrefix": "/rpc"
});
o["folderById"] = createRpcProxy("MailApi", "folderById", {
  "urlPrefix": "/rpc"
});
o["updateMailFolder"] = createRpcProxy("MailApi", "updateMailFolder", {
  "urlPrefix": "/rpc"
});
o["removeMail"] = createRpcProxy("MailApi", "removeMail", {
  "urlPrefix": "/rpc"
});
o["updateRead"] = createRpcProxy("MailApi", "updateRead", {
  "urlPrefix": "/rpc"
});
o["setUnread"] = createRpcProxy("MailApi", "setUnread", {
  "urlPrefix": "/rpc"
});
o["sendMail"] = createRpcProxy("MailApi", "sendMail", {
  "urlPrefix": "/rpc"
});
o["saveDraft"] = createRpcProxy("MailApi", "saveDraft", {
  "urlPrefix": "/rpc"
});
o["addFolder"] = createRpcProxy("MailApi", "addFolder", {
  "urlPrefix": "/rpc"
});
o["updateFolder"] = createRpcProxy("MailApi", "updateFolder", {
  "urlPrefix": "/rpc"
});
o["removeFolder"] = createRpcProxy("MailApi", "removeFolder", {
  "urlPrefix": "/rpc"
});
o["saveDraftAttachment"] = createRpcProxy("MailApi", "saveDraftAttachment", {
  "urlPrefix": "/rpc"
});
o["deleteAttachment"] = createRpcProxy("MailApi", "deleteAttachment", {
  "urlPrefix": "/rpc"
});
o["getSignedAttachmentUrl"] = createRpcProxy("MailApi", "getSignedAttachmentUrl", {
  "urlPrefix": "/rpc"
});
o["initializeMaps"] = createRpcProxy("MailApi", "initializeMaps", {
  "urlPrefix": "/rpc"
});