import { useQueryClient, useMutation } from 'react-query';
import ContractsApi from '@app/api/public/ContractsApi';
import { getContractKeys } from './useContract';

const saveEditedDocument = async (variables: Variables) => {
    return ContractsApi.updateDocumentName(variables.documentName, variables.documentId);
};

type Variables = { documentName: string; documentId: string; queryId: string };

const useSaveEditedDocument = () => {
    const queryClient = useQueryClient();
    return useMutation((variables: Variables) => saveEditedDocument(variables), {
        onSettled: () => {
            queryClient.invalidateQueries(getContractKeys.documentLists());
        },
    });
};

export default useSaveEditedDocument;
