import MembersApi from '@app/api/public/MembersApi';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { L } from '../../../lib/i18n';
import { getMemberListQueryKey } from './useGetMemberList';

const removeMember = async (variables: { id: string }) => {
    return await MembersApi.remove(+variables.id);
};

const useRemoveMember = () => {
    const queryClient = useQueryClient();
    return useMutation((variables: { id: string }) => removeMember(variables), {
        onError: (err) => {
            /* Shows error message */
            const error = typeof err === 'string' ? err : new Error(L('reset_error')).message;
            const message = error;
            toast.error(`${L(message)}`);
        },
        onSettled: () => {
            queryClient.invalidateQueries([getMemberListQueryKey]);
        },
    });
};

export default useRemoveMember;
