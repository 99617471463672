Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["list"] = createRpcProxy("ContentInformationApi", "list", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["get"] = createRpcProxy("ContentInformationApi", "get", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["remove"] = createRpcProxy("ContentInformationApi", "remove", {
  "urlPrefix": "/rpc"
});
o["updateDocumentName"] = createRpcProxy("ContentInformationApi", "updateDocumentName", {
  "urlPrefix": "/rpc"
});
o["updateDocumentGroup"] = createRpcProxy("ContentInformationApi", "updateDocumentGroup", {
  "urlPrefix": "/rpc"
});
o["removeDocumentgroup"] = createRpcProxy("ContentInformationApi", "removeDocumentgroup", {
  "urlPrefix": "/rpc"
});
o["deleteDocumentFromContentInformation"] = createRpcProxy("ContentInformationApi", "deleteDocumentFromContentInformation", {
  "urlPrefix": "/rpc"
});
o["saveDocuementGroup"] = createRpcProxy("ContentInformationApi", "saveDocuementGroup", {
  "urlPrefix": "/rpc"
});
o["listDocumentGroups"] = createRpcProxy("ContentInformationApi", "listDocumentGroups", {
  "urlPrefix": "/rpc"
});
o["saveDocument"] = createRpcProxy("ContentInformationApi", "saveDocument", {
  "urlPrefix": "/rpc"
});
o["listDocuments"] = createRpcProxy("ContentInformationApi", "listDocuments", {
  "urlPrefix": "/rpc"
});
o["resendNotifications"] = createRpcProxy("ContentInformationApi", "resendNotifications", {
  "urlPrefix": "/rpc"
});
o["setSortOrder"] = createRpcProxy("ContentInformationApi", "setSortOrder", {
  "urlPrefix": "/rpc"
});
o["setDocumentSortOrder"] = createRpcProxy("ContentInformationApi", "setDocumentSortOrder", {
  "urlPrefix": "/rpc"
});
o["setDocumentGroupOrder"] = createRpcProxy("ContentInformationApi", "setDocumentGroupOrder", {
  "urlPrefix": "/rpc"
});
o["createDraft"] = createRpcProxy("ContentInformationApi", "createDraft", {
  "urlPrefix": "/rpc"
});
o["saveDraft"] = createRpcProxy("ContentInformationApi", "saveDraft", {
  "urlPrefix": "/rpc"
});
o["createInformationAndPublish"] = createRpcProxy("ContentInformationApi", "createInformationAndPublish", {
  "urlPrefix": "/rpc"
});
o["saveAndPublish"] = createRpcProxy("ContentInformationApi", "saveAndPublish", {
  "urlPrefix": "/rpc"
});
o["editPublishedInformation"] = createRpcProxy("ContentInformationApi", "editPublishedInformation", {
  "urlPrefix": "/rpc"
});
o["publishInformation"] = createRpcProxy("ContentInformationApi", "publishInformation", {
  "urlPrefix": "/rpc"
});
o["setInformationAsDraft"] = createRpcProxy("ContentInformationApi", "setInformationAsDraft", {
  "urlPrefix": "/rpc"
});
o["removeCoverImage"] = createRpcProxy("ContentInformationApi", "removeCoverImage", {
  "urlPrefix": "/rpc"
});