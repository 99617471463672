import React, { useRef } from 'react';
import CreateNewFolder from '../forms/CreateNewFolder';
import styled from 'styled-components';
import { ProjectDetail } from '@app/api/public/ProjectsApi';
import { FolderDetails } from '@app/api/models/Folders';
import { RefetchOptions, RefetchQueryFilters, QueryObserverResult } from 'react-query';
import { Button } from '@ourliving/ourliving-ui';
import { L } from '../../../../lib/i18n';
import { DropzoneInputProps } from 'react-dropzone';

const SButtons = styled.div({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    gap: '1rem',
    width: '100%',
});

const SInputFile = styled.input({
    backgroundColor: 'lightgreen',
    borderRadius: 'inherit',
    position: 'absolute',
    top: '-2px',
    right: '-2px',
    bottom: '-2px',
    left: '-2px',
    opacity: '0',
    cursor: 'pointer',
    display: 'inline-block',

    '&::file-selector-button': { cursor: 'pointer' },
});

type DocumentControlsProps = {
    disableControls?: boolean;
    currentFolderId: number | null;
    projectInfo?: ProjectDetail;
    refetchFolders: <TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined) => Promise<
        QueryObserverResult<
            {
                readonly status: 'success';
                readonly data: FolderDetails[];
                readonly message?: undefined;
            },
            unknown
        >
    >;
    getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
};

const DocumentControls = ({
    disableControls,
    currentFolderId,
    projectInfo,
    refetchFolders,
    getInputProps,
}: DocumentControlsProps) => {
    const insertFileRef = useRef<HTMLInputElement>(null);

    return (
        <SButtons>
            <CreateNewFolder
                disabled={disableControls}
                openFolder={currentFolderId}
                projectId={projectInfo && projectInfo.id}
                success={() => {
                    refetchFolders();
                }}
            />

            <Button disabled={disableControls} style={{ position: 'relative' }}>
                {L('insert_file')}
                <SInputFile
                    disabled={disableControls}
                    {...getInputProps()}
                    style={{ display: 'inline-block' }}
                    ref={insertFileRef}
                />
            </Button>
        </SButtons>
    );
};

export default DocumentControls;
