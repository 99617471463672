import { useQuery } from 'react-query';
import HelpApi, { HelpItem } from '@app/api/public/HelpApi';
import { helpItems } from './useGetHelpListItems';

const getHelpListItem = async (page_name: HelpItem['page_name']) => {
    const r = await HelpApi.get(page_name);
    return r;
};

const useGetHelpListItem = (page_name: string) => {
    return useQuery([...helpItems.page_name(page_name)], () => getHelpListItem(page_name));
};

export default useGetHelpListItem;
