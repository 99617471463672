import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/locale/sv';
import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useGetBookingResourceById from '../hooks/useGetBookingResourceById';

dayjs.locale('sv');
dayjs.extend(isoWeek);

type SelectedDate = {
    date: string;
    id: number;
} | null;
type CalendarContextType = {
    dayjsState: dayjs.Dayjs;
    backButtonDisabled: boolean;
    forwardButtonDisabled: boolean;
    incrementDay: () => void;
    decrementDay: () => void;
    selectedDate: SelectedDate;
    selectDate: (clicked: SelectedDate) => void;
    reset: () => void;
    setDay: (date: string) => void;
};

const TimeSlotCalendarContext = createContext<CalendarContextType>({} as CalendarContextType);

const TimeSlotCalendarProvider: FC = ({ children }) => {
    const { bookingId } = useParams<{ bookingId: string }>();
    const { data: bookingResource } = useGetBookingResourceById(+bookingId);
    const [dayjsState, setDayjsState] = useState(dayjs());
    const [backButtonDisabled, setBackButtonDisaled] = useState(true);
    const [forwardButtonDisabled, setForwardButtonDisaled] = useState(false);
    const [selectedDate, setSelectedDate] = useState<SelectedDate>(null);

    useEffect(() => {
        const isBefore = dayjsState.isBefore(dayjs());
        setBackButtonDisaled(isBefore);

        if (!bookingResource?.booking_window?.quantity || !bookingResource?.booking_window.unit) return;

        const isAfter = dayjsState.isAfter(
            dayjs().add(bookingResource.booking_window.quantity, bookingResource.booking_window.unit)
        );
        setForwardButtonDisaled(isAfter);
    }, [dayjsState, bookingResource]);

    const reset = () => {
        setSelectedDate(null);
    };

    const setDay = (date: string) => {
        const nextDay = dayjs(date);
        setDayjsState(nextDay);
    };

    const incrementDay = () => {
        const nextDay = dayjsState.add(1, 'day');
        reset();
        setDayjsState(nextDay);
    };

    const decrementDay = () => {
        const prevDay = dayjsState.subtract(1, 'day');
        reset();
        setDayjsState(prevDay);
    };

    const selectDate = (clicked: SelectedDate) => {
        setSelectedDate(clicked);
    };
    const contextValues = {
        dayjsState,
        incrementDay,
        decrementDay,
        backButtonDisabled,
        forwardButtonDisabled,
        selectedDate,
        selectDate,
        reset,
        setDay,
    };

    return <TimeSlotCalendarContext.Provider value={contextValues}>{children}</TimeSlotCalendarContext.Provider>;
};

const useTimeSlotCalendar = () => {
    return useContext(TimeSlotCalendarContext);
};

export { TimeSlotCalendarContext, useTimeSlotCalendar, TimeSlotCalendarProvider as default };
