import * as React from 'react';
import { Colors, colors } from '../Shared/Style/colors';
import { style } from '../Shared/Style';
import { ThemeType } from '@app/api/models/Theme';
import styled from '../lib/styled';

const Count = styled.div((props: { color?: Colors; theme: ThemeType }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '22px',
    width: '22px',
    backgroundColor: props.color ? colors[props.color] : props?.theme?.colors?.notificationCount,
    borderRadius: '50%',
    fontSize: style.fontSize.micro,
    color: props.theme?.colors?.white,
    position: 'absolute',
    top: 0,
    right: 0,
}));

type Props = {
    counts?: number;
    color?: Colors;
};

export const NotificationCount: React.FC<Props> = ({ counts, ...rest }) => {
    if (!counts || counts === 0) {
        return null;
    }
    return (
        <Count aria-label={counts > 1 ? `${counts} notifications` : `${counts} notification`} {...rest}>
            {counts}
        </Count>
    );
};
export default NotificationCount;
