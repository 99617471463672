Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["setCookie"] = createRpcProxy("UsersApi", "setCookie", {
  "urlPrefix": "/rpc"
});
o["verifyLogin"] = createRpcProxy("UsersApi", "verifyLogin", {
  "urlPrefix": "/rpc"
});
o["login"] = createRpcProxy("UsersApi", "login", {
  "urlPrefix": "/rpc"
});
o["logout"] = createRpcProxy("UsersApi", "logout", {
  "urlPrefix": "/rpc"
});
o["sendPasswordResetInstructions"] = createRpcProxy("UsersApi", "sendPasswordResetInstructions", {
  "urlPrefix": "/rpc"
});
o["getProjects"] = createRpcProxy("UsersApi", "getProjects", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getUserById"] = createRpcProxy("UsersApi", "getUserById", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["updateSignInAt"] = createRpcProxy("UsersApi", "updateSignInAt", {
  "urlPrefix": "/rpc"
});
o["setPasswordConfirm"] = createRpcProxy("UsersApi", "setPasswordConfirm", {
  "urlPrefix": "/rpc"
});
o["resetPasswordRequest"] = createRpcProxy("UsersApi", "resetPasswordRequest", {
  "urlPrefix": "/rpc"
});
o["validateResetPasswordToken"] = createRpcProxy("UsersApi", "validateResetPasswordToken", {
  "urlPrefix": "/rpc"
});
o["validateConfirmAccountToken"] = createRpcProxy("UsersApi", "validateConfirmAccountToken", {
  "urlPrefix": "/rpc"
});
o["resetPassword"] = createRpcProxy("UsersApi", "resetPassword", {
  "urlPrefix": "/rpc"
});
o["invite"] = createRpcProxy("UsersApi", "invite", {
  "urlPrefix": "/rpc"
});
o["save"] = createRpcProxy("UsersApi", "save", {
  "urlPrefix": "/rpc"
});
o["changePassword"] = createRpcProxy("UsersApi", "changePassword", {
  "urlPrefix": "/rpc"
});