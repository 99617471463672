import styled from '@emotion/styled';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { colors } from '../../../../../components/Shared/Style/colors';
import { style } from '../../../../../components/Shared/Style';

export default styled(DropdownMenu.SubContent)({
    padding: '0.5rem',
    backgroundColor: colors.white,
    color: colors.textColor2,
    borderRadius: style.roundCornerSize.small,
    boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.1)',
});
