import React, { useState } from 'react';
import Dialog from '../../../../components/Dialog/Dialog';
import styled from '@emotion/styled';
import { Button } from '@ourliving/ourliving-ui';
import {
    DocumentInfo,
    FolderInfo,
    UpdateDuplicateResponseFile,
    UpdateDuplicateResponseFolder,
} from '@app/api/public/DocumentsApi';
import { L } from '../../../../lib/i18n';

const ContentWrapper = styled.div({
    padding: '.2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

const SListTitle = styled.p({
    fontWeight: 'bolder',
    margin: '1.5rem 0 0.3rem 0',
});

type DuplicateDialogProps = {
    files: DocumentInfo[] | UpdateDuplicateResponseFile[];
    folders: FolderInfo[] | UpdateDuplicateResponseFolder[];
    onClose: () => void;
    onReplace: () => void;
    onSkip: () => void;
};

const DuplicateDialog = ({ files, folders, onClose, onReplace, onSkip }: DuplicateDialogProps) => {
    const [open, setOpen] = useState(true);

    return (
        <Dialog
            open={open}
            onOpenChange={() => {
                setOpen(!open);
                onClose();
            }}
        >
            <ContentWrapper>
                <div>
                    <p>{L('duplicate_files_and_folders_question')}</p>

                    {!!files.length && (
                        <>
                            <SListTitle>{L('files')}:</SListTitle>

                            <ul>
                                {files
                                    .filter((f) => f.duplicate)
                                    .map((f, i) => {
                                        if ((f as DocumentInfo).metaData) {
                                            return (
                                                <li key={i}>{(f as DocumentInfo).metaData.nameWithoutFileExtension}</li>
                                            );
                                        }

                                        if ((f as UpdateDuplicateResponseFile).name) {
                                            return <li key={i}>{(f as UpdateDuplicateResponseFile).name}</li>;
                                        }
                                    })}
                            </ul>
                        </>
                    )}
                    {!!folders.length && (
                        <>
                            <SListTitle>{L('folders')}:</SListTitle>

                            <ul>
                                {folders
                                    .filter((f) => f.duplicate)
                                    .map((f, i) => {
                                        if ((f as FolderInfo).folder) {
                                            return <li key={i}>{(f as FolderInfo).folder.name}</li>;
                                        }

                                        if ((f as UpdateDuplicateResponseFolder).name) {
                                            return <li key={i}>{(f as UpdateDuplicateResponseFolder).name}</li>;
                                        }
                                    })}
                            </ul>
                        </>
                    )}
                </div>

                <br />

                <div style={{ display: 'flex', justifyContent: 'end', width: '100%', gap: '0.5rem' }}>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            onSkip();
                            setOpen(false);
                            onClose();
                        }}
                    >
                        {L('skip')}
                    </Button>

                    <Button
                        variant="danger"
                        onClick={() => {
                            onReplace();
                            setOpen(false);
                            onClose();
                        }}
                    >
                        {L('replace')}
                    </Button>
                </div>
            </ContentWrapper>
        </Dialog>
    );
};

export default DuplicateDialog;
