import { Button, Input } from '@ourliving/ourliving-ui';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FileDetails, FolderDetails, UpdateFileDetails, UpdateFolderDetails } from '@app/api/models/Folders';
import { ButtonContainer, StyledForm } from './FormStyles';
import { L } from '../../../../lib/i18n';
import toast from 'react-hot-toast';

type UpdateItemFormProps = {
    item: FolderDetails | FileDetails;
    onCancel: () => void;
    onUpdate: (docDetails: UpdateFolderDetails | UpdateFileDetails) => void;
    isLoading: boolean;
};

const UpdateItemForm = ({ item, onCancel, onUpdate, isLoading }: UpdateItemFormProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<UpdateFolderDetails | UpdateFileDetails>();

    const onSubmit: SubmitHandler<UpdateFolderDetails | UpdateFileDetails> = async (data) => {
        const name = data.name.trim();

        if (name === item.name.trim()) {
            return toast.error(L('name_not_changed'));
        }

        onUpdate({ ...data, name });
    };

    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <Input
                label={{ content: `${L('name')}`, for: 'nameInput' }}
                {...register('name', { required: true })}
                defaultValue={item.name}
            />

            {errors.name && <span style={{ color: 'crimson' }}>{errors.name.message || `${L('enter_name')}`}</span>}

            <ButtonContainer>
                <Button type="button" variant="secondary" onClick={onCancel}>
                    {L('cancel')}
                </Button>

                <Button type="submit" disabled={isLoading}>
                    {isLoading ? `${L('loading')}...` : `${L('update')}`}
                </Button>
            </ButtonContainer>
        </StyledForm>
    );
};

export default UpdateItemForm;
