import { useMutation } from 'react-query';
import PublicSiteApi from '@app/api/public/PublicSiteApi';

const sendContactMail = async () => {
    const response = await PublicSiteApi.sendOrderMail();

    if (response.statusCode !== 200) Promise.reject('error');
    return response;
};

const useSendContactMail = () => {
    return useMutation(() => sendContactMail());
};

export default useSendContactMail;
