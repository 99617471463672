import TicketsApi from '@app/api/public/TicketsApi';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { L } from '../../../lib/i18n';
import { TicketSettingsQueryKey } from './TicketSettingsQueries';

const useSaveTicketInformation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (ticketInfo: string) => {
            return TicketsApi.saveServiceInformation(ticketInfo);
        },

        onSuccess: () => {
            toast.success(L('ticket_information_saved'));
            queryClient.invalidateQueries(TicketSettingsQueryKey.all);
        },
    });
};

export default useSaveTicketInformation;
