import React from 'react';
import Pill from './MailPill';
import { margin } from '../../../../components/Shared/Style/margin';
import styled from '@emotion/styled';

type Props = {
    recipients: string[];
    memoizedRecipients: { value: string; id: string }[];
    handleSetRecipients: (recipients: string[]) => void;
    handleSetRecipientsInput?: (input: string) => void;
} & React.HTMLAttributes<HTMLDivElement>;
const PillContainer = styled.div(({ style }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: margin.xs,
    rowGap: margin.xs,
    ...style,
}));

const MailChips = ({ recipients, memoizedRecipients, handleSetRecipients, handleSetRecipientsInput, style }: Props) => {
    return (
        <>
            {recipients.length > 0 && memoizedRecipients.length > 0 && (
                <PillContainer style={style}>
                    {memoizedRecipients.map((recipient) => (
                        <Pill
                            key={recipient.id}
                            handleSetRecipients={handleSetRecipients}
                            handleSetRecipientsInput={handleSetRecipientsInput}
                            recipient={recipient}
                            recipients={memoizedRecipients}
                        />
                    ))}
                </PillContainer>
            )}
        </>
    );
};

export default MailChips;
