import { ResponderProvided, DropResult } from 'react-beautiful-dnd';
import { useQueryClient, useMutation } from 'react-query';
import PublicSiteApi from '@app/api/public/PublicSiteApi';
import { PublicSiteInformationItem } from '@app/api/models/PublicSite';
import useGetPageListItems, { getQueryPageListItems } from './useGetPageListItems';

type UseDragVariables = { result: DropResult; provided: ResponderProvided };

const updateSortOrder = (sortArray: { id: number; sort: number }[]) => {
    return PublicSiteApi.updateSortOrder(sortArray);
};

export const sortList = (result: DropResult, listItems: PublicSiteInformationItem[]) => {
    if (!result || !result.destination) return;
    const items = [...listItems];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    return {
        items,
        reorderedItem,
        index: result.destination.index,
    };
};

const dragEnd = async ({ result, provided }: UseDragVariables, listItems?: PublicSiteInformationItem[]) => {
    if (!listItems) return;
    const sortedList = sortList(result, listItems);
    if (!sortedList) return;
    const sortArray = sortedList.items.map((item, index) => {
        return { id: item.id, sort: index };
    });
    return updateSortOrder(sortArray);
};

const useDragEnd = () => {
    const queryClient = useQueryClient();
    const { data } = useGetPageListItems();
    return useMutation((variables: UseDragVariables) => dragEnd(variables, data), {
        onMutate: ({ result }) => {
            queryClient.cancelQueries('pageListItem');
            const menuArr = queryClient.getQueryData<PublicSiteInformationItem[]>(getQueryPageListItems.all);

            // Sätter ny ordning
            if (!result.destination || !menuArr) return;
            const sortedList = sortList(result, menuArr);
            sortedList && queryClient.setQueryData(getQueryPageListItems.all, sortedList.items);
        },
        onSettled: () => {
            queryClient.invalidateQueries(getQueryPageListItems.all);
        },
    });
};

export default useDragEnd;
