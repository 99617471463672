import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { colors } from '../../../components/Shared/Style/colors';

const Card = styled.div({
    padding: '12px 12px',
    borderBottom: '1px solid #979797',
    cursor: 'pointer',
    ['@media screen and (min-width: 1024px)']: {
        padding: '12px 0px',
    },
});

const InfoWrapper = styled.div(({ color }: { color?: string }) => {
    return {
        borderLeft: `5px solid ${color}`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 10px',
    };
});

const SectionWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

const Supplier = styled.span({
    marginRight: 'auto',
    fontSize: '16px',
});

const Title = styled.span(({ theme }) => ({
    fontSize: '12px',
    color: theme.colors.smallTitle,
    marginRight: 'auto',
}));

const Span = styled.span({
    fontSize: '12px',
});

const DaysLeft = styled.span(({ isBefore }: { isBefore?: boolean }) => {
    return {
        color: isBefore ? colors.statusError : 'inherit',
        fontSize: '10px',
        marginRight: 'auto',
    };
});

const Notification = styled.div(({ theme }) => ({
    width: '5px',
    height: '5px',
    borderRadius: '50%',
    backgroundColor: theme.colors.tabActive,
}));

const ContractCard = ({ contract }) => {
    const history = useHistory();

    return (
        <Card onClick={() => history.push(`/contracts/${contract?.id}`)}>
            <InfoWrapper color={contract.contract_types.color_code}>
                <SectionWrapper>
                    <Supplier>{contract?.service_partner?.name}</Supplier>
                    <Title>{contract?.name}</Title>
                </SectionWrapper>
                <SectionWrapper>
                    {contract.contract_length?.end_date && (
                        <>
                            <Span>{dayjs(contract.end_date).format('YYYY-MM-DD')}</Span>
                            <DaysLeft isBefore={dayjs(contract.end_date).isBefore(dayjs())}>
                                {dayjs(contract.end_date).isValid() ? dayjs(Date.now()).to(contract.end_date) : ''}
                            </DaysLeft>
                        </>
                    )}
                    {contract.contract_length?.length_months && (
                        <Span>{contract.contract_length.length_months} månader </Span>
                    )}
                    {contract.contract_length?.until_further_notice && <Span>Tillsvidare</Span>}
                </SectionWrapper>
                <SectionWrapper>
                    <Notification />
                </SectionWrapper>
            </InfoWrapper>
        </Card>
    );
};

export default ContractCard;
