import React from 'react';
import useGetSignedUrlById from '../../hooks/useGetSignedUrlById';
import { medium } from '../../lib/imageSize';
import { CardImage } from './CardGrid';

export const CardGridImage = ({ originalId }: { originalId: string }) => {
    const { data: signedUrl } = useGetSignedUrlById(originalId, medium);
    return (
        <CardImage
            url={
                signedUrl ||
                'https://s3.eu-west-1.amazonaws.com/public.ourliving.se/ourliving/ourliving-transparent-icon.png'
            }
        />
    );
};
