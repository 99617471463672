import { useLayoutEffect } from 'react';

export const useOnUIEvent = (
    target: EventTarget | React.RefObject<HTMLElement>,
    type: string,
    handler: (evt?: UIEvent) => void,
    initialize: boolean,
    dependencies: any[]
) => {
    useLayoutEffect(() => {
        const tg = ('current' in target ? target.current : target) as EventTarget;

        let animationFrame: number | null;

        const onRedraw = () => {
            animationFrame = null;
        };

        const listener = (evt: UIEvent) => {
            if (animationFrame) return;
            animationFrame = requestAnimationFrame(onRedraw);
            handler(evt);
        };

        tg.addEventListener(type, listener);

        initialize && handler();

        return () => {
            animationFrame && cancelAnimationFrame(animationFrame);
            tg.removeEventListener(type, listener);
        };
    }, dependencies);
};
