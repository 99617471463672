import React, { ReactNode } from 'react';
import { Column, useTable, useSortBy, useFlexLayout, useGlobalFilter, usePagination } from 'react-table';

export type Props<T extends Record<string, unknown>> = {
    data?: T[];
    columns: Column<T>[];
};

const useReactTable = <T extends Record<string, unknown>>({ columns, data }: Props<T>) => {
    const dataMemo = React.useMemo(() => (data ? data : []), [data]);
    const columnsMemo = React.useMemo<readonly Column<T>[]>(() => [...columns], [data, columns]);
    return useTable<T>(
        {
            columns: columnsMemo,
            data: dataMemo,
        },
        useGlobalFilter,
        useSortBy,
        useFlexLayout
    );
};

export default useReactTable;
