import BookingsApi from '@app/api/public/BookingsApi';
import { useQuery } from 'react-query';

export const getBookingResourcesQueryKey = {
    all: [{ scope: 'getBookingResources' }],
    id: (id: number) => [...getBookingResourcesQueryKey.all, { id }],
    lists: () => [{ ...getBookingResourcesQueryKey.all[0], entity: 'list' }],
    list: (type: 'draft' | 'published') => [{ ...getBookingResourcesQueryKey.lists()[0], type }],
} as const;
const getBookingResources = async (type: 'draft' | 'published') => {
    return await BookingsApi.listResources(type);
};

const useGetBookingResources = (type: 'draft' | 'published') => {
    return useQuery({
        queryFn: ({ queryKey }) => {
            const { type } = queryKey[0];
            return getBookingResources(type);
        },
        queryKey: getBookingResourcesQueryKey.list(type),
    });
};

export default useGetBookingResources;
