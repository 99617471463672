import React from 'react';
import styled from '@emotion/styled';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

const Container = styled.div({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    border: '1px solid black',
    padding: '3rem',
    borderRadius: '0 0 4px 4px',
});

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
    labels: ['Okategoriserat', 'Förhållsninghus', 'Bostadshus'],
    datasets: [
        {
            label: '# of Votes',
            data: [16, 5, 2],
            backgroundColor: ['#d6d36f', '#8072db', '#72dbc8'],
        },
    ],
};
export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom' as const,
        },
    },
};

const BoaGraph = () => {
    return (
        <Container>
            <Pie data={data} options={options} />
        </Container>
    );
};

export default BoaGraph;
