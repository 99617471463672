import { useQueryClient, useMutation } from 'react-query';
import MaintenancesApi from '@app/api/public/MaintenancesApi';
import { DocumentDetail } from '@app/api/models/Documents';

type variables = {
    itemId: string;
    name: string;
    file: File;
};

export const saveMaintenanceDocuments = async (itemId: number, name: string, file: File) => {
    return MaintenancesApi.saveDocument(itemId, name, file);
};

const useSaveMaintenanceDocuments = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (variables: variables) => saveMaintenanceDocuments(+variables.itemId, variables.name, variables.file),
        {
            onSuccess: (data, variables) => {
                const arr = queryClient.getQueryData<DocumentDetail[]>('getRows');
                if (arr) {
                    queryClient.setQueryData(
                        ['getMaintenanceDocumentsList', variables.itemId.toString()],
                        [...arr, data]
                    );
                }
            },
            onSettled: (_data, _error, variables) => {
                queryClient.invalidateQueries(['getMaintenanceDocumentsList', variables.itemId.toString()]);
                queryClient.invalidateQueries(['getDocumentsList']);
            },
        }
    );
};

// export const saveEditedDocuments = async (document) => {
//     return await MaintenancesApi.saveEditDocument(document);
// };

export default useSaveMaintenanceDocuments;
