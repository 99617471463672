import styled from '@emotion/styled';
import React, { useMemo, useState } from 'react';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { Loading } from '../../../components/Loading/Loading';
import { useModal } from '../../../components/Modal/Context/ModalContext';
import { desktop } from '../../../components/MediaQuery/breakpoints';
import useGetMemberList from '../hooks/useGetMemberList';
import { COLUMNS } from './Columns';
import TopArea from './TopArea';
import TableRowSelection from './TableRowSelection';
import useTableCheckbox from './TableProvider';
import { MemberFilter } from '@app/api/models/Members/MembersModels';

const MobileView = styled.div({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});

const DesktopView = styled.div({
    display: 'none',
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'block',
    },
});

const MembersTablePage = ({
    ToggleIsModalOpen,
}: {
    ToggleIsModalOpen: ReturnType<typeof useModal>['ToggleIsModalOpen'];
}) => {
    const { data } = useGetMemberList();

    const [filter, setFilter] = useState<MemberFilter>({
        property: '',
        accepted: undefined,
    });

    const filteredData = useMemo(() => {
        return (
            data
                ?.filter((member) => {
                    if (!filter.property) return true;
                    return member.property_id === +filter.property;
                })
                .filter((member) => {
                    if (!filter.accepted) return true;
                    if (filter.accepted === 'Bekräftat') return !!member.confirmed_at && !member.invited_at;
                    if (filter.accepted === 'Inbjuden') return !member.confirmed_at && !!member.invited_at;
                    if (filter.accepted === 'Ej inbjuden') return !member.confirmed_at && !member.invited_at;
                }) || []
        );
    }, [filter, data]);

    const useTable = useTableCheckbox({ data: filteredData, columns: COLUMNS });

    const selectedData = useTable.selectedFlatRows.map((data) => {
        return data.original;
    });

    if (!data) return <Loading />;
    return (
        <>
            <DesktopView>
                <CardWithDivider
                    topArea={
                        <TopArea
                            toggleIsModalOpen={() => ToggleIsModalOpen()}
                            selectedMembers={selectedData}
                            onSubmit={(value: boolean) => useTable.toggleAllRowsSelected(value)}
                        />
                    }
                    mainArea={<TableRowSelection useTable={useTable} filter={filter} setFilter={setFilter} />}
                />
            </DesktopView>
            <MobileView>
                <CardWithDivider
                    topArea={
                        <TopArea
                            toggleIsModalOpen={() => ToggleIsModalOpen()}
                            selectedMembers={selectedData}
                            onSubmit={(value: boolean) => useTable.toggleAllRowsSelected(value)}
                        />
                    }
                    mainArea={<TableRowSelection useTable={useTable} filter={filter} setFilter={setFilter} />}
                />
            </MobileView>
        </>
    );
};

export default MembersTablePage;
