import styled from '@emotion/styled';
import React from 'react';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { Loading } from '../../../components/Loading/Loading';
import CardTitle from '../../../components/typography/CardTitle';
import useGetProjectInfo from '../../../hooks/useGetProjectInfo';
import { L } from '../../../lib/i18n';
import { ProjectInfoArea } from '../components/ProjectEditArea';
import ProjectStatusArea from '../components/ProjectStatusArea';

const GridContainer = styled.div({
    display: 'grid',
    gap: '10rem',
    margin: '1rem 0',
    '@media screen and (min-width: 1100px)': {
        gridTemplateColumns: '2fr 1fr',
    },
});

const ProjectDetailPage = () => {
    const { data: project } = useGetProjectInfo();

    if (!project) return <Loading />;

    return (
        <CardWithDivider
            topArea={<CardTitle>{`${L('project_description')}`}</CardTitle>}
            mainArea={
                <>
                    <GridContainer>
                        <ProjectInfoArea project={project} />
                        <ProjectStatusArea project={project} />
                    </GridContainer>
                </>
            }
        />
    );
};

export default ProjectDetailPage;
