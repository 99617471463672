import { BoardMemberFilter } from '@app/api/public/BoardMembersApi';
import styled from '@emotion/styled';
import React, { useMemo, useState } from 'react';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { Loading } from '../../../components/Loading/Loading';
import { desktop } from '../../../components/MediaQuery/breakpoints';
import Table from '../../../components/Table/ReactTable/FilterTable';
import useGetBoardMemberList from '../hooks/useGetBoardMembers';
import { COLUMNS, COLUMNSMOBILE } from './Columns';
import TopArea from './TopArea';

const MobileView = styled.div({
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'none',
    },
});

const DesktopView = styled.div({
    display: 'none',
    [`@media screen and (min-width: ${desktop}px)`]: {
        display: 'block',
    },
});

const TableQuery = ({ ToggleIsModalOpen }) => {
    const { data } = useGetBoardMemberList();
    const [filter, setFilter] = useState<BoardMemberFilter>({
        property: '',
        role: undefined,
        subRole: undefined,
    });

    const filteredData = useMemo(() => {
        return (
            data
                ?.filter((boardMember) => {
                    if (!filter.property) return true;
                    return boardMember.properties.map((property) => property.id).includes(+filter.property);
                })
                .filter((boardMember) => {
                    if (!filter.role) return true;
                    return boardMember.role === filter.role;
                })
                .filter((boardMember) => {
                    if (!filter.subRole) return true;
                    return boardMember.sub_role === filter.subRole;
                }) || []
        );
    }, [filter, data]);

    if (!data) return <Loading />;
    return (
        <>
            <DesktopView>
                <CardWithDivider
                    topArea={<TopArea ToggleIsModalOpen={ToggleIsModalOpen} totalItems={data?.length} />}
                    mainArea={<Table columns={COLUMNS} data={filteredData} />}
                />
            </DesktopView>
            <MobileView>
                <CardWithDivider
                    topArea={<TopArea ToggleIsModalOpen={ToggleIsModalOpen} totalItems={data?.length} />}
                    mainArea={<Table columns={COLUMNSMOBILE} data={filteredData} />}
                />
            </MobileView>
        </>
    );
};

export default TableQuery;
