import styled from '@emotion/styled';
import * as React from 'react';
import { FC } from 'react';

const PageContainer = styled.div(({ theme }) => {
    return {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: theme?.colors?.pageBackground,
    };
});

export const PageBackground: FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...rest }) => {
    return <PageContainer {...rest}>{children}</PageContainer>;
};
