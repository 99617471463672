import dayjs from 'dayjs';

const useValidIntervalEndDates = (startDate?: string, intervalMonth?: number) => {
    if (!startDate || !intervalMonth) return [];
    if (!dayjs(startDate).isValid()) return [];
    const interval = +intervalMonth;

    const maxYearSpan = 50;

    const start = dayjs(startDate);
    const end = dayjs().add(maxYearSpan, 'year');
    let diff = end.diff(start, 'month');
    let prev = start;

    const validDates: string[] = [];

    while (diff > 0) {
        validDates.push(prev.format());
        prev = prev.add(interval, 'month');
        diff = end.diff(prev, 'month');
    }

    return validDates;
};

export default useValidIntervalEndDates;
