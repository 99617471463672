import BoardContentNewsApi from '@app/api/public/BoardContentNewsApi';
import { useMutation, useQueryClient } from 'react-query';
import { boardNewsQueryKeys } from './boardNewsQueries';

type Variables = { id: number; notify: boolean };

const usePublishNews = () => {
    const client = useQueryClient();
    return useMutation(
        (varables: Variables) => {
            return BoardContentNewsApi.publishNews(varables);
        },
        {
            onSettled: () => {
                client.invalidateQueries(boardNewsQueryKeys.all);
            },
        }
    );
};

export default usePublishNews;
