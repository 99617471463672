import { useTimeSlotCalendar } from '../context/useTimeSlots';
import useGetBookingResourceById from './useGetBookingResourceById';
import useGetBookingsByResourceId from './useGetBookingsByResourceId';

const useGetBookableItemsForCell = (cellId: number, bookingId: number) => {
    const { dayjsState } = useTimeSlotCalendar();
    const { data: resourceData } = useGetBookingResourceById(bookingId);
    const { data: bookings } = useGetBookingsByResourceId(bookingId);

    if (!resourceData) return [];
    const availableResourceItems = resourceData?.booking_resource_items.filter((resourceItem) => {
        const booked = bookings?.find((booking) => {
            if (+booking.booking_slot_id !== +cellId) return false;
            return (
                +booking.booking_resource_item_id === +resourceItem.id &&
                booking.booking_date === dayjsState.format('YYYY-MM-DD')
            );
        });
        return !booked;
    });

    return availableResourceItems;
};

export default useGetBookableItemsForCell;
