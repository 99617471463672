import React from 'react';
import { useParams } from 'react-router-dom';
import { Loading } from '../../../components/Loading/Loading';
import { L } from '../../../lib/i18n';
import { useGetServicePartnerById } from '../hooks/servicePartnerQueries';
import EditServicePartner from './EditServicePartner';

const ServicePartnerWrapper = () => {
    const { id } = useParams<{ id: string }>();
    const { data, status } = useGetServicePartnerById(id);

    if (data) {
        return <EditServicePartner id={id} servicePartnerFormProps={{ initialFormData: data }} />;
    }

    if (status === 'loading') {
        return <Loading />;
    }

    return <div>{L('error_service_partner')}</div>;
};

export default ServicePartnerWrapper;
