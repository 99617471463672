import React, { useEffect } from 'react';
import { L } from '../../lib/i18n';
import { Button } from './Button';

type ConfirmationButtonProps = {
    children?: React.ReactNode;
    props: {
        onConfirm: () => void;
        confirm?: React.ReactNode;
        confirmVariant?: React.ComponentProps<typeof Button>['variant'];
        idleVariant?: React.ComponentProps<typeof Button>['variant'];
    };
} & Omit<React.ComponentProps<typeof Button>, 'children'>;
const ConfirmationButton = ({
    props: { onConfirm, confirm, idleVariant = 'primary', confirmVariant = 'delete' },
    children,
    ...rest
}: ConfirmationButtonProps) => {
    const [isConfirmed, setIsConfirmed] = React.useState(false);
    const ref = React.useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as Node;
            if (target && ref.current && !ref.current.contains(target)) {
                setIsConfirmed(false);
            }
        };
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [ref]);

    return isConfirmed ? (
        <Button
            {...rest}
            ref={ref}
            onClick={() => {
                setIsConfirmed(false);
                onConfirm();
            }}
            variant={confirmVariant}
        >
            {confirm ?? L('confirm')}
        </Button>
    ) : (
        <Button {...rest} onClick={() => setIsConfirmed(true)} variant={idleVariant}>
            {children ?? L('delete')}
        </Button>
    );
};

export default ConfirmationButton;
