import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useState } from 'react';
import { useParams } from 'react-router';
import useGetBookingResourceSlots from './useGetBookingResourceSlots';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const useValidinterval = ({ startTime, endTime, intervalH }: { startTime: string; endTime: string; intervalH }) => {
    const { bookingId } = useParams<{ bookingId: string }>();
    const { data } = useGetBookingResourceSlots(bookingId);

    const intervalValue = +intervalH * 60 * 60;

    const start = dayjs(startTime, 'HH:mm');
    const end = dayjs(endTime, 'HH:mm');
    const startUnix = start.unix();
    const endUnix = end.unix();
    const diff = startUnix - endUnix;
    const remaining = diff % intervalValue;

    if (remaining !== 0) {
        return false;
    }
    if (!data) {
        return false;
    }

    const invalid = data.find((slot) => {
        const slotStartUnix = dayjs(slot.starttime, 'HH:mm').unix();
        const slotEndUnix = dayjs(slot.endtime, 'HH:mm').unix();

        // start between
        if (startUnix > slotStartUnix && startUnix < slotEndUnix) {
            return true;
        }

        if (startUnix === slotStartUnix) {
            return true;
        }

        if (endUnix === slotEndUnix) {
            return true;
        }

        // start between
        if (endUnix > slotStartUnix && endUnix < slotEndUnix) {
            return true;
        }

        return false;
    });

    if (invalid) {
        return false;
    }

    return true;
};

export default useValidinterval;
