import React from 'react';
import ServicePartnerForm, { ServicePartnerFormProps } from '../components/ServicePartnerForm/ServicePartnerForm';
import ServicePartnerDocumentsArea from '../components/ServicePartnerDocumentsArea';

type Props = {
    servicePartnerFormProps?: ServicePartnerFormProps;
    id: string;
};
const EditServicePartner = ({ servicePartnerFormProps, id }: Props) => {
    return (
        <div style={{ paddingBottom: '200px' }}>
            <ServicePartnerForm {...servicePartnerFormProps} id={id} />
            <ServicePartnerDocumentsArea id={id} />
        </div>
    );
};

export default EditServicePartner;
