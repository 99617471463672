import { AddMember } from '@app/api/models/Members';
import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { Column, useTable, useSortBy, useFlexLayout, useRowSelect, Row } from 'react-table';
import { style } from '../../../components/Shared/Style';
import Checkbox from '../../../components/Table/checkbox/Checkbox';
import SortArrows from '../../../components/Table/sortArrows/SortArrows';
import UploadMember from './UploadMember';

const Styles = styled.div(({ theme }) => ({
    /* These styles are suggested for the table fill all available space in its containing element */
    // display: 'block',
    /* These styles are required for a horizontaly scrollable table overflow */
    overflow: 'auto',

    '& .table': {
        borderSpacing: 0,
        '.tr': {
            display: 'flex',
        },
        '.th': {
            color: theme.colors.secondary,
            padding: `0px 16px ${style.margin.m}px 16px`,
            fontSize: style.fontSize.epsilon,

            /* These styles are required for a scrollable body to align with the header properly */
            overflowY: 'auto',
            overflowX: 'hidden',
        },

        '.td': {
            borderTop: `1px solid ${theme.colors.black50}`,
            fontSize: style.fontSize.centi,
            padding: '16px 16px',
            overflowX: 'hidden',
        },

        '.tbody': {
            /* These styles are required for a scrollable table body */
            overflowY: 'auto',
            overflowX: 'hidden',
        },

        '.th, .td': {
            margin: 0,
            /* In this example we use an absolutely position resizer,
       so this is required. */

            position: 'relative',
            ':last-child': {
                paddingRight: '0px',
            },
        },
        '.td .tr div:first-of-type': {
            paddingLeft: '0px',
        },
    },
}));

const getStyles = (
    props,
    style: {
        justifyContent?: string;
        alignItems?: string;
        display?: string;
    }
) => [
    props,
    {
        style: {
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            display: 'flex',
            ...style,
        },
    },
];
const headerProps = (props, { column }) => getStyles(props, column.align);

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

export type Props = {
    columns: Column<object>[];
    data: Record<string, unknown>[];
    getSelectedFlatRows: (selectedFlatRows: Row<object>[]) => void;
};

const FlexContainer = styled.div({
    display: 'flex',
    gap: '',
    alignItems: 'center',
});

const TableRowSelection = ({
    columns,
    data,
    getSelectedFlatRows,
    ...rest
}: Props & React.TableHTMLAttributes<HTMLTableElement>) => {
    const dataMemo = React.useMemo(() => (data ? data : []), [data]);
    const columnsMemo = React.useMemo<readonly Column<object>[]>(() => [...columns], [data, columns]);

    const { getTableProps, headerGroups, rows, prepareRow, selectedFlatRows } = useTable(
        {
            columns: columnsMemo,
            data: dataMemo,
        },
        useSortBy,
        useRowSelect,
        useFlexLayout,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    {
                        id: 'selection',
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <Checkbox {...getToggleAllRowsSelectedProps()} />
                        ),
                        Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
                    },
                    ...columns,
                    {
                        id: 'status',
                        Header: 'Status',
                        style: {
                            justifyContent: 'left',
                            alignItems: 'center',
                        },
                        Cell: ({ row }) => {
                            const member = row.original as AddMember;
                            return <UploadMember {...row.getToggleRowSelectedProps()} member={member} />;
                        },
                    },
                ];
            });
        }
    );

    useEffect(() => {
        getSelectedFlatRows(selectedFlatRows);
    }, [selectedFlatRows]);

    return (
        <Styles>
            <div {...getTableProps()} className="table">
                <div>
                    {headerGroups.map((headerGroup) => (
                        <div className="tr" key={headerGroup.getFooterGroupProps().key}>
                            {headerGroup.headers.map((column) => (
                                <div
                                    {...column.getHeaderProps(column.getSortByToggleProps(headerProps))}
                                    className="th"
                                    key={column.getHeaderProps().key}
                                >
                                    <FlexContainer>
                                        {column.render('Header')}
                                        {column.canSort && (
                                            <SortArrows
                                                activeSort={
                                                    column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : undefined
                                                }
                                            />
                                        )}
                                    </FlexContainer>
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="tbody">
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <div {...row.getRowProps()} className="tr" key={row.getRowProps().key}>
                                {row.cells.map((cell) => {
                                    return (
                                        <div
                                            {...cell.getCellProps(cellProps)}
                                            className="td"
                                            key={cell.getCellProps().key}
                                        >
                                            {cell.render('Cell')}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </Styles>
    );
};

export default TableRowSelection;
