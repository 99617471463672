import React from 'react';
import { FC } from 'react';
import styled from '../../lib/styled';
import Icon from '../opposite-directions.svg';

const ThemedSwap = styled(Icon)(() => {
    return {
        height: '20px',
        width: '20px',
        '& path': {
            strokeWidth: '0.5px',
        },
    };
});
const Swap: FC = ({ ...rest }) => {
    return <ThemedSwap viewBox="0 0 24 24" {...rest} />;
};

export default Swap;
