import React from 'react';
import { Switch } from 'react-router';
import useGetPreviewPagesSettings from './hooks/useGetPreviewPages';

import * as booking from './areas/booking/navigation';
import * as publicSite from './areas/public-site/navigation';
import * as projects from './areas/projects/navigation';
import * as tickets from './areas/tickets/navigation';
import * as members from './areas/members/navigation';
import * as boardMembers from './areas/board-members/navigation';
import * as settings from './areas/settings/navigation';
import * as contacts from './areas/contacts/navigation';
import * as memberNews from './areas/content/member-news/navigation';
import * as memberInformation from './areas/content/member-information/navigation';
import * as memberCalendar from './areas/content/member-event/navigation';
import * as boardEvent from './areas/content/board-event/navigation';
import * as boardNews from './areas/content/board-news/navigation';
import * as boardInformation from './areas/content/board-information/navigation';
import * as maintenances from './areas/maintenances/navigation';
import * as help from './areas/help/navigation';
import * as blog from './areas/blog/navigation';
import * as contract from './areas/contract/navigation';
import * as alerts from './areas/alerts/navigation';
import * as servicePartner from './areas/service-partner/navigation';
import * as properties from './areas/properties/navigation';
import * as profile from './areas/profile/navigation';
import * as mail from './areas/mail/navigation';
import * as notification from './areas/notifications/navigation';
import * as document from './areas/document/navigation';

export const previewMode = [maintenances, publicSite, alerts, mail, document];

const PrivateRoutes = () => {
    const { data: previewSettings } = useGetPreviewPagesSettings();

    return (
        <Switch>
            {settings.routes}
            {blog.routes}
            {profile.routes}
            {boardMembers.routes}
            {projects.routes}
            {properties.routes}
            {boardInformation.routes}
            {boardNews.routes}
            {boardEvent.routes}
            {members.routes}
            {memberInformation.routes}
            {memberNews.routes}
            {memberCalendar.routes}
            {tickets.routes}
            {booking.routes}
            {contract.routes}
            {servicePartner.routes}
            {contacts.routes}
            {help.routes}
            {previewSettings &&
                previewMode.filter((area) => previewSettings.includes(area.navigation.name)).map((area) => area.routes)}
            {notification.routes}
        </Switch>
    );
};

export const previewNavigations = previewMode.map((item) => item.navigation);
export const navigation: { name: string; link: string; icon?: string; parent?: string }[] = [
    boardMembers.navigation,
    projects.navigation,
    properties.navigation,
    boardInformation.navigation,
    boardNews.navigation,
    boardEvent.navigation,
    members.navigation,
    memberInformation.navigation,
    memberNews.navigation,
    memberCalendar.navigation,
    tickets.navigation,
    booking.navigation,
    contacts.navigation,
    contract.navigation,
    servicePartner.navigation,
    help.navigation,
    settings.navigation,
];

export default PrivateRoutes;
