import React, { FC } from 'react';
import styled from '@emotion/styled';
import { colors } from '../../../components/Shared/Style/colors';
import * as AlertsApi from '@app/api/public/AlertsApi';
import { Icon } from '../../../components/Icon/Icon';
import CalendarDay from '../../../components/Icon/calendar-day.svg';
import dayjs from 'dayjs';
import { formatDateIsoDayjs } from '@app/shared/lib/formatting';

type Priority = AlertsApi.AlterPriorityType;
const StyledAlertCard = styled.section<{ priority: Priority }>(({ priority }) => ({
    cursor: 'pointer',
    backgroundColor: colors.alertBackground,
    padding: '24px 16px 10px 16px',
    borderRadius: '8px',
    display: 'grid',

    gridTemplateAreas: `
                "name icon"
                "title icon"
                "description description"
                "date date"
            `,
    gridTemplateColumns: 'auto min-content',
    columnGap: '1rem',
    rowGap: '0.2rem',
    position: 'relative',
    borderLeft: `14px solid ${priority ? colors[`alert_${priority}`] : colors.alert_low}`,
    borderTop: '',
    overflow: 'hidden',
}));

const WarningIcon = styled(Icon.warningSign)({
    gridArea: 'icon',
    alignSelf: 'start',
    justifySelf: 'end',
});

const Title = styled.h2({
    gridArea: 'title',
    fontSize: '14px',
    fontWeight: 'normal',
    margin: '0px',
    marginBottom: '24px',
    padding: '0px',
});
const Name = styled.span({
    gridArea: 'name',
    fontSize: '12px',
    fontWeight: 'normal',
    margin: '0px',
    padding: '0px',
});

const Description = styled.p({
    gridArea: 'description',
    fontSize: '12px',
    fontWeight: 300,
    lineHeight: '20px',
    maxWidth: '32ch',
    marginBottom: '1rem',
    margin: '0',
    color: colors.black90,
    whiteSpace: 'pre-line',
    '&.elips': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
});

const StyledCalendarDay = styled(CalendarDay)({
    height: '21px',
    width: '21px',
});

const DateContainer = styled.div({
    display: 'flex',
    gridArea: 'date',
    fontSize: '12px',
    alignItems: 'center',
    fontWeight: 300,
    gap: '0.5rem',
});

type Props = Pick<AlertsApi.Alert, 'title' | 'description' | 'priority' | 'publish_from_date' | 'publish_to_date'> & {
    propertyNames: string[];
    elips?: boolean;
};

const AlertCard: FC<Props> = ({
    title,
    description,
    publish_from_date,
    publish_to_date,
    propertyNames,
    elips,
    ...rest
}) => {
    const nameObj = propertyNames?.reduce<{ names: string[]; extra: number }>(
        (nameObject, propertyName, index) => {
            if (index > 1) {
                nameObject.extra = nameObject.extra + 1;
                return nameObject;
            }

            nameObject.names = [...nameObject.names, propertyName];
            return nameObject;
        },
        {
            names: [],
            extra: 0,
        }
    );

    const names = nameObj?.names.join(', ');

    const isValidDate = (dates: string[] | null[]) => !dates.map((date) => dayjs(date).isValid()).includes(false);
    return (
        <StyledAlertCard aria-label={`alert ${rest.priority}`} {...rest}>
            <Name>
                {names} {nameObj?.extra ? `+${nameObj?.extra}` : ''}
            </Name>
            <Title>{title}</Title>
            <WarningIcon />
            <Description className={elips ? 'elips' : ''}>{description}</Description>
            <DateContainer>
                <StyledCalendarDay />
                {isValidDate([publish_from_date, publish_to_date])
                    ? `${formatDateIsoDayjs(publish_from_date)} till ${formatDateIsoDayjs(publish_to_date)}`
                    : 'Inget datum'}
            </DateContainer>
        </StyledAlertCard>
    );
};

export default AlertCard;
