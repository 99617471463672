import styled from '@emotion/styled';

export default styled.li(() => ({
    padding: '1rem 2rem 1rem 1rem ',
    display: 'flex',
    fontSize: '1rem',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    gap: '1rem',
    cursor: 'pointer',
}));
