import ContentInformationApi from '@app/api/public/ContentInformationApi';
import { useMutation, useQueryClient } from 'react-query';
import { memberInformationQueryKeys } from './memberInformationQueries';

type Variables = { id: number; notify: boolean };

const usePublishInformation = () => {
    const client = useQueryClient();
    return useMutation(
        (varables: Variables) => {
            return ContentInformationApi.publishInformation(varables);
        },
        {
            onSettled: () => {
                client.invalidateQueries(memberInformationQueryKeys.all);
            },
        }
    );
};

export default usePublishInformation;
