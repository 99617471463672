import styled from '@emotion/styled';
import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { L } from '../../lib/i18n';
import AddContract from './components/AddContract';
import { COLUMNS } from './components/columns';
import { COLUMNS as ARCHIVE_COLUMNS } from './components/archiveColumns';
import Contracts from './components/Contracts';
import EditContract from './components/EditContract';
import PageTitle from '../../components/typography/PageTitle';
import CardWithDivider from '../../components/Layout/CardWithDivider';
import CardTitle from '../../components/typography/CardTitle';
import { Button } from '../../components/Buttons/Button';
import BigTabNav from '../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../components/BigTabItem/BigTabListItemNav';
import Table from './components/Table';
import { useModal } from '../../components/Modal/Context/ModalContext';
import { useGetContractById, useGetContracts, useGetContractsArchived } from './hooks/useContract';
import TypeModal from './components/TypeModal';
import ArchiveTable from './components/ArchiveTable';

const MobileWrapper = styled.div({
    ['@media screen and (min-width: 1024px)']: {
        display: 'none',
    },
});

const DesktopWrapper = styled.div({
    display: 'none',
    ['@media screen and (min-width: 1024px)']: {
        display: 'block',
    },
});

const TitleWrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const ButtonWrapper = styled.div({
    display: 'flex',
    gap: '1rem',
});
export const ContractPage = () => {
    // const { data: contracts } = useGetContracts();
    const contractEditParams = useRouteMatch<{ id: string }>({ path: `/contracts/:id` });
    const contractArchiveParams = useRouteMatch<{ id: string }>({ path: `/contracts/archive/:id` });
    const contractAddParams = useRouteMatch<{ id: string }>({ path: `/contracts/add` });
    const contractId = contractArchiveParams?.params.id ?? contractEditParams?.params.id;
    const { data: contract } = useGetContractById(Number(contractId));
    const { data: activeContracts } = useGetContracts();
    const { data: archivedContracts } = useGetContractsArchived();
    const { ToggleIsModalOpen } = useModal();

    return (
        <>
            <TypeModal />
            <PageTitle>
                {L('contract_header')} {contractArchiveParams?.params.id && <span>- {L('archive')}</span>}
            </PageTitle>
            <BigTabNav>
                <BigNavItem exact to={'/contracts'}>
                    {L('all_contracts')}
                </BigNavItem>
                <BigNavItem exact to={'/contracts/archive'}>
                    {L('archive')}
                </BigNavItem>
                {contract && (
                    <>
                        <div style={{ borderRight: 'solid 1px gray' }} />
                        {contract.archived_at ? (
                            <BigNavItem exact to={`/contracts/archive/${contract?.id}`}>
                                {contract?.name}
                            </BigNavItem>
                        ) : (
                            <BigNavItem exact to={`/contracts/${contract?.id}`}>
                                {contract?.name}
                            </BigNavItem>
                        )}
                    </>
                )}
                {contractAddParams && (
                    <>
                        <div style={{ borderRight: 'solid 1px gray' }} />
                        <BigNavItem exact to={`/contracts/add`}>
                            {L('add_contract')}
                        </BigNavItem>
                    </>
                )}
            </BigTabNav>

            <Switch>
                <Route exact path="/contracts">
                    <CardWithDivider
                        topArea={
                            <TitleWrapper>
                                <CardTitle>{L('all_contracts')}</CardTitle>

                                <ButtonWrapper>
                                    <Link to={'/contracts/add'}>
                                        <Button>{L('add_contract')}</Button>
                                    </Link>
                                    <Button
                                        onClick={() => {
                                            ToggleIsModalOpen();
                                        }}
                                    >
                                        {L('add_type')}
                                    </Button>
                                </ButtonWrapper>
                            </TitleWrapper>
                        }
                        mainArea={
                            <>
                                <MobileWrapper>
                                    <Contracts />
                                </MobileWrapper>
                                <DesktopWrapper>
                                    <Table<NonNullable<typeof activeContracts>[0]>
                                        Columns={COLUMNS}
                                        Data={activeContracts ?? []}
                                    />
                                </DesktopWrapper>
                            </>
                        }
                    />
                </Route>
                <Route exact path="/contracts/archive">
                    <CardWithDivider
                        topArea={
                            <TitleWrapper>
                                <CardTitle>{L('archive')}</CardTitle>
                            </TitleWrapper>
                        }
                        mainArea={
                            <>
                                <MobileWrapper>
                                    <Contracts />
                                </MobileWrapper>
                                <DesktopWrapper>
                                    <ArchiveTable<NonNullable<typeof archivedContracts>[0]>
                                        Columns={ARCHIVE_COLUMNS}
                                        Data={archivedContracts ?? []}
                                    />
                                </DesktopWrapper>
                            </>
                        }
                    />
                </Route>
                <Route exact path="/contracts/archive/:id">
                    <EditContract />
                </Route>
                <Route exact path="/contracts/add">
                    <AddContract />
                </Route>
                <Route exact path="/contracts/:id">
                    <EditContract />
                </Route>
            </Switch>
        </>
    );
};
