import React, { HTMLAttributes, HTMLProps } from 'react';
import styled from '@emotion/styled';
import { Switch } from '@rebass/forms';
import { colors } from '../Shared/Style/colors';

const SSwitch = styled(Switch)(({ theme }) => {
    return {
        cursor: 'pointer',
        borderColor: colors.black,
        '&[aria-checked=true]': {
            backgroundColor: colors.primary,
        },
        '& div': {
            backgroundColor: colors.background,
            borderColor: colors.black,
            borderWidth: '1px',
        },
        ':focus': {
            boxShadow: 'none',
        },
    };
});

type SwitchProps = typeof Switch;
export const StyledSwitch: React.FC<
    SwitchProps & {
        style?: HTMLAttributes<HTMLDivElement>['style'];
        checked?: boolean;
    }
> = ({ ...rest }) => {
    return <SSwitch {...rest} />;
};

export default StyledSwitch;
