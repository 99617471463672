import { useMutation, useQueryClient } from 'react-query';
import ContractsApi, { Contract } from '@app/api/public/ContractsApi';
import { useHistory } from 'react-router-dom';
import { getContractKeys } from './useContract';

const deleteType = async (id: number) => {
    return ContractsApi.delete(id);
};

type Variables = { id: Contract['id'] };
const useDeleteType = () => {
    const queryClient = useQueryClient();
    const history = useHistory();
    return useMutation((variables: Variables) => deleteType(+variables.id), {
        onSettled: () => {
            queryClient.invalidateQueries(getContractKeys.list());
            queryClient.invalidateQueries(getContractKeys.listArchived());
            history.push('/contracts');
        },
    });
};

export default useDeleteType;
