import React from 'react';
import HelpItem from '../components/HelpItem';

const HelpItemPage = () => {
    return (
        <div>
            <HelpItem />
        </div>
    );
};

export default HelpItemPage;
