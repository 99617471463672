Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["saveSetting"] = createRpcProxy("PushNotificationApi", "saveSetting", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["listSettings"] = createRpcProxy("PushNotificationApi", "listSettings", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["saveToken"] = createRpcProxy("PushNotificationApi", "saveToken", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});