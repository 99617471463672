import styled from 'styled-components';
import CardWithDivider from '../Layout/CardWithDivider';
import { roundCornerSize } from '../Shared/Style/box';

const ModalWrapper = styled(CardWithDivider)({
    maxWidth: '500px',
    maxHeight: '80vh',
    placeSelf: 'center',
    overflowY: 'auto',
    overflowX: 'hidden',
    borderRadius: roundCornerSize.medium,
});

export default ModalWrapper;
