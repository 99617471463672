import styled from '@emotion/styled';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

export default styled(DropdownMenu.Trigger)(({ theme }) => ({
    backgroundColor: 'inherit',
    border: 'none',
    padding: '0px',
    margin: '0px',
    display: 'block',
    cursor: 'pointer',
    '&:focus-visible': {
        outline: `${theme.colors.primary50} auto 1px`,
    },
    '&:focus, &:hover': {
        outline: `${theme.colors.primary50} auto 1px`,
    },
}));
