import styled from '@emotion/styled';
import React, { HTMLAttributes } from 'react';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import DocumentsArea from './DocumentsArea';
import FileDropZone from '../FileDropZone/FileDropZone';
import CardWithDivider from '../Layout/CardWithDivider';
import CardTitle from '../typography/CardTitle';
import { L } from '../../lib/i18n';

const StyledCardWithDivider = styled(CardWithDivider)({});

type DocumentType = {
    id: number;
    originalId: string;
    filename: string;
    name: string;
};

type Props = {
    saveDocument: (file: File) => Promise<unknown>;
    getDocuments: () => Promise<DocumentType[]>;
    documentQueryKey: readonly unknown[];
    removeDocument: (documentId: number) => Promise<unknown>;
    renameDocument: (documentId: number, newName: string) => Promise<unknown>;
};

const DocumentsAreaWithUpload = ({
    saveDocument,
    getDocuments,
    documentQueryKey,
    removeDocument,
    renameDocument,
    ...rest
}: Props & HTMLAttributes<HTMLDivElement>) => {
    const queryClient = useQueryClient();

    const { data: documents } = useQuery({
        queryFn: () => {
            return getDocuments();
        },
        queryKey: documentQueryKey,
    });

    const { mutate: saveDocumentMutation } = useMutation(saveDocument, {
        onMutate: (file) => {
            const toastId = toast.loading(`${L('saving')} ${file.name}`);
            return toastId;
        },
        onError: (_error, file, toastId: string) => {
            toast.error(`${L('save_error')}`, {
                id: toastId,
            });
        },
        onSuccess: (_data, file, toastId: string) => {
            toast.success(`${file.name} ${L('saved')}`, {
                id: toastId,
            });
        },

        onSettled: () => {
            queryClient.invalidateQueries(documentQueryKey);
        },
    });

    const { getInputProps, getRootProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            acceptedFiles.map((file) => saveDocumentMutation(file));
        },
        onDropRejected(fileRejections, event) {
            fileRejections.forEach((fileRejection) => {
                fileRejection.errors.forEach((error) => {
                    const errorCode = error.code.replace(/-/g, '_');
                    toast.error(`${fileRejection.file.name} ${L(errorCode)}`);
                });
            });
        },
        maxSize: 100000000,
    });
    return (
        <StyledCardWithDivider
            {...rest}
            topArea={<CardTitle>{L('document')}</CardTitle>}
            mainArea={
                <>
                    <FileDropZone getInputProps={getInputProps} getRootProps={getRootProps} />
                    <DocumentsArea
                        documentQueryKey={documentQueryKey}
                        documents={documents}
                        removeDocument={removeDocument}
                        renameDocument={renameDocument}
                    />
                </>
            }
        />
    );
};

export default DocumentsAreaWithUpload;
