import { TicketFilter } from '@app/api/models/Tickets';
import styled from '@emotion/styled';
import React, { HTMLAttributes } from 'react';
import ReactSelect from '../../../components/Dropdown/ReactSelect';
import Filters from '../../../components/Filters/Filters';
import { L } from '../../../lib/i18n';
import useGetBoardMembers from '../../board-members/hooks/useGetBoardMembers';
import useGetProperties from '../../booking/hooks/useGetProperties';
import { useGetSettingsByType } from '../hooks/ticketQueries';

const StyledFilters = styled(Filters)({
    margin: '0px',
    marginBottom: '1rem',
    gap: '0.5rem',
    '& .label': {
        margin: '0px',
        padding: '0px',
    },
});

type Props = {
    tickets: {
        assigned_id: number | null;
        property_id: number | null;
        ticket_status_id: number | null;
        ticket_priority_id: number | null;
    }[];
    filter: TicketFilter;
    handleFilter: <T extends keyof TicketFilter>(key: T, value: TicketFilter[T]) => void;
} & HTMLAttributes<HTMLDivElement>;

const TicketFilters = ({ tickets, handleFilter, filter, ...rest }: Props) => {
    const { data: boardMembers } = useGetBoardMembers();
    const { data: properies } = useGetProperties();
    const { data: statuses } = useGetSettingsByType('status');
    const { data: priorities } = useGetSettingsByType('priority');

    const assigneeOptions =
        boardMembers
            ?.filter((boardMember) => tickets?.find((ticket) => ticket.assigned_id === boardMember.user_id))
            ?.map((boardMember) => ({ label: boardMember.name, value: boardMember.user_id })) || [];
    const propertyOptions =
        properies
            ?.filter((property) => tickets?.find((ticket) => ticket.property_id === property.id))
            .map((property) => ({ label: property.name, value: property.id })) || [];

    const priorityOptions =
        priorities
            ?.filter((priority) => tickets?.find((ticket) => ticket.ticket_priority_id === priority.id))
            .map((priority) => ({ label: priority.name, value: priority.id })) || [];

    const statusOptions =
        statuses
            ?.filter((status) => tickets?.find((ticket) => ticket.ticket_status_id === status.id))
            .map((status) => ({ label: status.name, value: status.id })) || [];

    return (
        <StyledFilters {...rest}>
            <ReactSelect
                placeholder={L('assignee')}
                isClearable
                value={assigneeOptions?.find((assigneOption) => assigneOption.value === filter?.assignee)}
                options={assigneeOptions}
                onChange={(option) => handleFilter('assignee', option?.value)}
            />
            <ReactSelect
                placeholder={L('properties')}
                isClearable
                value={propertyOptions?.find((propertyOption) => propertyOption.value === filter?.property)}
                onChange={(option) => handleFilter('property', option?.value)}
                options={propertyOptions}
            />
            <ReactSelect
                placeholder={L('status')}
                isClearable
                value={statusOptions?.find((statusOption) => statusOption.value === filter?.status_id)}
                onChange={(option) => handleFilter('status_id', option?.value)}
                options={statusOptions}
            />
            <ReactSelect
                placeholder={L('priority')}
                isClearable
                value={priorityOptions?.find((priorityOption) => priorityOption.value === filter?.priority_id)}
                onChange={(option) => handleFilter('priority_id', option?.value)}
                options={priorityOptions}
            />
        </StyledFilters>
    );
};

export default TicketFilters;
