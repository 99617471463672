import { TicketMessageThread } from '@app/api/models/Tickets';
import TicketsApi from '@app/api/public/TicketsApi';
import { useMutation, useQueryClient } from 'react-query';
import { ticketQueryKeys } from './ticketQueries';
type Variables = { ticketId: number; threadType: TicketMessageThread['ticket_message_thread_type'] };
const useSetLastReadThread = () => {
    const client = useQueryClient();
    return useMutation((variables: Variables) => TicketsApi.setLastReadThread(variables), {
        onSuccess: (data, variables) => {
            client.invalidateQueries(ticketQueryKeys.id(variables.ticketId.toString()));
        },
    });
};

export default useSetLastReadThread;
