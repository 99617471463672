Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["id"] = createRpcProxy("BlogApi", "id", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["list"] = createRpcProxy("BlogApi", "list", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});