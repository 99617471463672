import { L } from '../../../lib/i18n';
import React, { useEffect, useState } from 'react';
import { Loading } from '../../../components/Loading/Loading';
import { Route, Switch, useParams } from 'react-router-dom';
import { BookingFilterPanel } from './BookingFilterPanel';
import { BookingFilter } from '@app/api/models/Bookings';
import useGetBookingsList from '../hooks/useGetBookingsList';
import dayjs from 'dayjs';
import useGetBookingsCsv from '../hooks/useGetBookingsCsv';
import { downloadFile } from '../../../lib/download';
import FileCSV from '../../../components/Icon/file-csv.svg';
import styled from '@emotion/styled';
import Tooltip from '../../../components/Tooltip/Tooltip';
import BookingListTable from './BookingListTable';
import useGetBookingResourceById from '../hooks/useGetBookingResourceById';
import Daybookings from '../Daybookings/Daybookings';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { Button } from '../../../components/Buttons/Button';
import InvisibleLinkWrapper from '../../../components/Link/InvisibleLinkWrapper';
import BookingsCalendarForm from '../DetailPage/components/BookingsCalendarForm';
import BookingsSlotForm from '../DetailPage/components/BookingsSlotForm';
import usePublishResource from '../hooks/usePublishResource';
import Clock from '../../../components/Icon/clock.svg';
import CalendarDay from '../../../components/Icon/calendar-day.svg';
import { colors } from '../../../components/Shared/Style/colors';

const InvisibleButton = styled.button({
    border: 'none',
    backgroundColor: 'inherit',
    padding: '0px',
    margin: '0px',
    height: 'max-content',
    cursor: 'pointer',
});
const FileCSVIcon = styled(FileCSV)({
    height: '30px',
    width: '30px',
});
const Container = styled.div({
    display: 'grid',
    gridTemplateColumns: '1fr',
    '@media screen and (min-width: 1500px)': {
        gridTemplateColumns: 'minmax(280px, 400px) 1fr',
    },
    gap: '1rem',
});

const FlexWrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const FilterColumn = styled.div({
    margin: `0px 0px 0px 10px`,
    display: 'flex',
    justifyContent: 'space-between',
    '@media screen and (min-width: 1500px)': {
        gridColumn: '1 / 3',
    },
});

const SvgWrapper = styled.div({
    gap: '1rem',
    alignItems: 'center',
    display: 'flex',
});

const StyledClock = styled(Clock)({
    height: '21px',
    width: '21px',
});
const StyledCalendarDay = styled(CalendarDay)({
    height: '21px',
    width: '21px',
});

const Span = styled.span({
    textAlign: 'left',
    fontSize: '14px',
});

const StyledP = styled.span({
    display: 'block',
    color: colors.black80,
    margin: '0px',
    fontSize: '12px',
});

export const BookingList = () => {
    const { bookingId } = useParams<{ bookingId: string }>();

    const [filter, setFilter] = useState<Partial<BookingFilter>>({
        booking_date_start: dayjs().format('YYYY-MM-DD'),
    });
    const [page, setPage] = useState(1);

    const { data: bookings, status } = useGetBookingsList(bookingId, page, filter);
    const { data: bookingsCsv } = useGetBookingsCsv(bookingId, filter);
    const { mutate: togglePublish } = usePublishResource();

    const { data: bookingResource } = useGetBookingResourceById(+bookingId);

    useEffect(() => {
        setPage(1);
    }, [filter]);

    const onDownload = async () => {
        const response = bookingsCsv;
        if (response) downloadFile(response.name, response);
    };

    if (status === 'loading') return <Loading />;
    if (!bookings) return <div>Kunde ej hämta bokningar</div>;

    return (
        <CardWithDivider
            topArea={
                <FlexWrapper>
                    <CardTitle style={{ padding: 0 }}>
                        <SvgWrapper>
                            {bookingResource?.booking_resource_slot_type.name === 'slot' ? (
                                <StyledClock />
                            ) : (
                                <StyledCalendarDay />
                            )}
                            <div>
                                <Span>{L('all_bookings')}</Span>
                                <StyledP>{L(`${bookingResource?.booking_resource_slot_type.name}`)}</StyledP>
                            </div>
                        </SvgWrapper>
                    </CardTitle>
                    {bookingResource &&
                        bookingResource.booking_resource_slot_type.name === 'day' &&
                        bookingResource.published && (
                            <>
                                <InvisibleLinkWrapper to={`/booking/${bookingId}/new`}>
                                    <Button>{L('new_booking')}</Button>
                                </InvisibleLinkWrapper>

                                <Switch>
                                    <Route exact path={`/booking/${bookingId}/new`}>
                                        <BookingsCalendarForm bookingId={bookingId} />
                                    </Route>
                                </Switch>
                            </>
                        )}
                    {bookingResource &&
                        bookingResource.booking_resource_slot_type.name === 'slot' &&
                        bookingResource.published && (
                            <>
                                <InvisibleLinkWrapper to={`/booking/${bookingId}/new`}>
                                    <Button>{L('new_booking')}</Button>
                                </InvisibleLinkWrapper>

                                <Switch>
                                    <Route exact path={`/booking/${bookingId}/new`}>
                                        <BookingsSlotForm bookingId={bookingId} />
                                    </Route>
                                </Switch>
                            </>
                        )}
                    {bookingResource && !bookingResource.published && (
                        <>
                            <Button onClick={() => togglePublish(bookingResource.id)}>{L('publish')}</Button>
                        </>
                    )}
                </FlexWrapper>
            }
            mainArea={
                <Container>
                    <FilterColumn>
                        <BookingFilterPanel onChange={setFilter} filter={filter} />
                        <Tooltip position="left" text={L('download_csv')}>
                            <InvisibleButton onClick={onDownload}>
                                <FileCSVIcon viewBox="0 0 64 64" />
                            </InvisibleButton>
                        </Tooltip>
                    </FilterColumn>
                    {bookingResource && bookingResource.booking_resource_slot_type.name === 'slot' ? (
                        <Daybookings filter={filter} setFilter={setFilter} />
                    ) : (
                        <Daybookings filter={filter} setFilter={setFilter} />
                    )}
                    <div>
                        <BookingListTable setPage={setPage} bookings={bookings} />
                    </div>
                </Container>
            }
        />
    );
};
