import React from 'react';
import { NavLink } from 'react-router-dom';
import { Card } from '../../components/Layout/Card';
import styled from '../../components/lib/styled';
import { style } from '../../components/Shared/Style';
import useLogout from '../../hooks/useLogout';
import { L } from '../../lib/i18n';

const StyledNavLinkText = styled(NavLink)({
    color: style.colors.black,
    textDecoration: 'none',
    marginTop: style.margin.m,
    fontSize: style.fontSize.gamma,
    alignSelf: 'start',
    padding: '12px 0px 0px 0px',
});

const Title = styled.div({
    marginTop: style.margin.m,
    alignSelf: 'start',
    fontSize: style.fontSize.gamma,
    cursor: 'pointer',
    padding: '12px 0px 0px 0px',
});

const StyledCard = styled(Card)({
    paddingLeft: '18px',
    height: '100vh',
});

export const ProfilePage = () => {
    const { mutate: logout } = useLogout();

    return (
        <StyledCard>
            <StyledNavLinkText to={'/profile/settings'}>{L('my_profile')}</StyledNavLinkText>
            <StyledNavLinkText to={'/profile/notifications'}>{L('notifications')}</StyledNavLinkText>
            <Title
                onClick={(e) => {
                    e.stopPropagation();
                    logout();
                }}
            >
                {L('log_out')}
            </Title>
        </StyledCard>
    );
};
