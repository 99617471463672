import React from 'react';
import { L } from '../../lib/i18n';
import Document from './Document';
import { DocumentDetail } from '@app/api/public/DocumentsApi';
import { useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { DragDropContext, Draggable, DraggableProvided, Droppable } from 'react-beautiful-dnd';
import useDragEnd from '../../hooks/useDragEnd';
import ContentInformationApi from '@app/api/public/ContentInformationApi';
import styled from 'styled-components';
import { Icon } from '../Icon/Icon';

type DocumentType = {
    id: number;
    originalId: string;
    filename: string;
    name: string;
    groupId?: number;
    sort: number;
};

const DocGroupList = styled.ul({
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
});

const IconWrapper = styled.span({
    display: 'flex',
    alignItems: 'center',
    '.st0': { fill: '#AFAFAF' },
});

const templateColumns = '0.02fr 0.4fr' as const;

const DocumentList = styled.div({
    display: 'grid',
    gap: '1rem',
    alignItems: 'center',
    gridTemplateColumns: templateColumns,
});

type Props<Document extends DocumentType> = {
    documents?: Document[];
    removeDocument: (documentId: number) => Promise<unknown>;
    renameDocument: (documentId: number, newName: string) => Promise<unknown>;
    documentQueryKey: readonly unknown[];
    regroupDocument?: (documentId: number, newGroup: number) => Promise<unknown>;
    sortable?: Boolean;
    provided?: DraggableProvided;
};
const DocumentsArea = <T extends DocumentType>({
    documentQueryKey,
    removeDocument,
    renameDocument,
    regroupDocument,
    documents,
    sortable,
    provided,
}: Props<T>) => {
    const documentQueryKeys = {
        all: documentQueryKey,
    };

    const client = useQueryClient();

    const { mutate: removeDocumentMutation } = useMutation(
        async (documentDetail: Pick<DocumentDetail, 'name' | 'id'>) => {
            return removeDocument(documentDetail.id);
        },
        {
            onMutate: (documentDetail) => {
                const toastId = toast.loading(`${L('removing')} ${documentDetail.name}`);
                return toastId;
            },
            onError: (_error, _documentDetail, toastId: string) => {
                toast.error(`${L('remove_error')}`, {
                    id: toastId,
                });
            },
            onSuccess: (_data, documentDetail, toastId: string) => {
                toast.success(`${documentDetail.name} ${L('removed')}`, {
                    id: toastId,
                });
            },

            onSettled: () => {
                client.invalidateQueries(documentQueryKeys.all);
            },
        }
    );

    const { mutate: renameDocumentMutation } = useMutation(
        async ({ documentId, newName }: { documentId: number; newName: string }) => {
            return renameDocument(documentId, newName);
        },
        {
            onMutate: ({ newName }) => {
                const toastId = toast.loading(`${L('saving')} ${newName}`);
                return toastId;
            },
            onError: (_error, _documentDetail, toastId: string) => {
                toast.error(`${L('rename_error')}`, {
                    id: toastId,
                });
            },
            onSuccess: (_data, { newName }, toastId: string) => {
                toast.success(`${newName} ${L('saved')}`, {
                    id: toastId,
                });
            },

            onSettled: () => {
                client.invalidateQueries(documentQueryKeys.all);
            },
        }
    );

    const { mutate: regroupDocumentMutation } = useMutation(
        async ({ documentId, groupId }: { documentId: number; groupId: number }) => {
            return regroupDocument && regroupDocument(documentId, groupId);
        },
        {
            onError: (_error, _documentDetail) => {
                toast.error(`${L('regroup_error')}`);
            },
            onSuccess: (_data, {}) => {
                toast.success(`${L('regroup_updated')}`);
            },

            onSettled: () => {
                client.invalidateQueries(documentQueryKeys.all);
            },
        }
    );

    const { mutate: dragEnd } = useDragEnd({
        dragableItems: documents || [],
        queryKey: documentQueryKey,
        updateSortOrder: ContentInformationApi.setDocumentSortOrder,
        reverseSortOrder: true,
    });

    if (documents) {
        if (sortable) {
            return (
                <div
                    data-rbd-drag-handle-context-id={provided?.dragHandleProps?.['data-rbd-drag-handle-context-id']}
                    data-rbd-drag-handle-draggable-id="gibberish"
                    style={{
                        // When you set the data-rbd-drag-handle-context-id, RBD applies cursor: grab, so we need to revert that
                        cursor: 'auto',
                    }}
                >
                    <DragDropContext onDragEnd={(result, provided) => dragEnd({ result, provided })}>
                        <Droppable droppableId="documents">
                            {(provided) => (
                                <DocGroupList
                                    className="documents"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {documents?.map((doc, index) => {
                                        return (
                                            <Draggable
                                                key={doc.id + doc.filename}
                                                draggableId={doc.id + doc.filename}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <DocumentList
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <IconWrapper>
                                                            <Icon.VerticalMoreIcon
                                                                size="small"
                                                                style={{
                                                                    justifySelf: 'left',
                                                                    visibility: 'visible',
                                                                }}
                                                            />
                                                            <Icon.VerticalMoreIcon
                                                                size="small"
                                                                style={{
                                                                    justifySelf: 'left',
                                                                    visibility: 'visible',
                                                                    marginLeft: '-6px',
                                                                }}
                                                            />
                                                        </IconWrapper>

                                                        <Document
                                                            key={doc.id}
                                                            documentDetail={{
                                                                filename: doc.filename,
                                                                name: doc.name,
                                                                originalId: doc.originalId,
                                                                groupId: doc.groupId,
                                                            }}
                                                            deleteDocument={() => {
                                                                removeDocumentMutation(doc);
                                                            }}
                                                            editDocumentName={(name) =>
                                                                renameDocumentMutation({
                                                                    newName: name,
                                                                    documentId: doc.id,
                                                                })
                                                            }
                                                            editDocumentGroup={(groupId) =>
                                                                regroupDocumentMutation({
                                                                    documentId: doc.id,
                                                                    groupId: groupId,
                                                                })
                                                            }
                                                        />
                                                    </DocumentList>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </DocGroupList>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            );
        } else {
            const Documents = documents.map((doc) => {
                return (
                    <Document
                        key={doc.id}
                        documentDetail={{
                            filename: doc.filename,
                            name: doc.name,
                            originalId: doc.originalId,
                            groupId: doc.groupId,
                        }}
                        deleteDocument={() => {
                            removeDocumentMutation(doc);
                        }}
                        editDocumentName={(name) =>
                            renameDocumentMutation({
                                newName: name,
                                documentId: doc.id,
                            })
                        }
                        editDocumentGroup={(groupId) =>
                            regroupDocumentMutation({
                                documentId: doc.id,
                                groupId: groupId,
                            })
                        }
                    />
                );
            });

            return <div>{Documents}</div>;
        }
    }

    return null;
};

export default DocumentsArea;
