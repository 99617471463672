import { FileDetails, FolderDetails } from '@app/api/models/Folders';
import React from 'react';
import useDeleteFolder from '../hooks/folders/useDeleteFolder';
import { MutateOptions } from 'react-query';
import DeleteDialog from './DeleteDialog';
import { L } from '../../../lib/i18n';

type Props = {
    item: FolderDetails | FileDetails;
    onCancel: () => void;
    options?: MutateOptions<number, unknown, number, unknown>;
};

const DeleteFolder = ({ item, onCancel, options }: Props) => {
    const { mutate: deleteFolder, isLoading: loadDel } = useDeleteFolder(item.name);

    return (
        <DeleteDialog
            isLoading={loadDel}
            onDelete={() => deleteFolder(item.id, options)}
            onCancel={() => onCancel()}
            title={`${L('are_you_sure_you_want_to_delete')}: '${item.name}'?`}
            warningMessage={L('might_include_other_items')}
        />
    );
};

export default DeleteFolder;
