import * as React from 'react';
import { formatDatetime } from '@app/shared/lib/formatting';
import styled from '@emotion/styled';
import { style } from '../../../../components/Shared/Style';

const Container = styled.div(({ theme }) => ({
    fontSize: style.fontSize.milli,
    lineHeight: '16px',
    letterSpacing: '0.2px',
    color: theme.colors.black,
}));

interface Props {
    date: any;
}

const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export function DatetimeWorklog(props: Props) {
    if (!props.date) {
        return <Container />;
    }
    return <Container>{capitalize(formatDatetime(props.date))}</Container>;
}
