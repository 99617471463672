import styled from '@emotion/styled';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';

const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const TopArea = () => {
    return (
        <Wrapper>
            <CardTitle>{L('all_properties')}</CardTitle>
            {/** Temporärt avstängd */}
            {/* <div style={{ display: 'flex' }}>
                <NavLink to={`/properties/new`}>
                    <Button>{L('new_property')}</Button>
                </NavLink>
            </div> */}
        </Wrapper>
    );
};

export default TopArea;
