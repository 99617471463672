import BoardContentEventApi from '@app/api/public/BoardContentEventApi';
import { useMutation, useQueryClient } from 'react-query';
import { memberEventQueryKeys } from './boardEventQueries';

type Variables = { id: number };

const useSetEventAsDraft = () => {
    const client = useQueryClient();
    return useMutation(
        (varables: Variables) => {
            return BoardContentEventApi.setEventAsDraft(varables.id);
        },
        {
            onSettled: (data, error, varables) => {
                client.invalidateQueries(memberEventQueryKeys.lists());
                client.invalidateQueries(memberEventQueryKeys.detail(varables.id));
            },
        }
    );
};

export default useSetEventAsDraft;
