import { FontType } from './publicFont';

export const parseFontData = (fonts: Partial<FontType>) => {
    const familyname = fonts?.fontFamily;
    const variants = fonts?.fontData?.variants;

    let parsedVariants = '';
    variants?.forEach((variant, index) => {
        if (index === 0) {
            parsedVariants = `${variant}`;
            return;
        }
        parsedVariants = `${parsedVariants},${variant}`;
    });
    return `${familyname}${parsedVariants ? `:${parsedVariants}` : ''}`;
};
