import { useMutation, useQueryClient } from 'react-query';
import MaintenancesApi from '@app/api/public/MaintenancesApi';
import { SettingsType } from '@app/api/models/Maintenanaces';
import { getSettingsListByTypeKey } from './useGetSettingsListByType';
import toast from 'react-hot-toast';
import { L } from '../../../lib/i18n';

type Variables = {
    id: number;
    settingstype: SettingsType;
};

const deleteSettingsListByType = async (variables: Variables) => {
    return MaintenancesApi.deleteSettingByType(variables.id, variables.settingstype);
};

const useDeleteSettingsListByType = () => {
    const queryClient = useQueryClient();
    return useMutation((variables: Variables) => deleteSettingsListByType(variables), {
        onSuccess: (_data, variables) => {
            toast.success(L(`removed_${variables.settingstype}`));

            queryClient.invalidateQueries([getSettingsListByTypeKey, variables.settingstype]);
        },
    });
};

export default useDeleteSettingsListByType;
