import MembersApi from '@app/api/public/MembersApi';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { L } from '../../../lib/i18n';
import { propertiesQueryKeys } from './propertyQueries';

type Member = { email: string; name: string; propertyId?: number };

const saveNewMember = async (member: Member, sendInviteMail?: boolean) => {
    const res = await MembersApi.newMember(member.email, member.name, member.propertyId, sendInviteMail);

    if (!res) return Promise.reject(L('reset_error'));

    if (res?.['message']) {
        const message = res['message'] as string;
        return Promise.reject(L(message));
    }

    return res;
};

const useSaveNewMember = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ member, sendInviteMail }: { member: Member; sendInviteMail?: boolean }) =>
            saveNewMember(member, sendInviteMail),
        {
            onError: (err) => {
                /* Shows error message */
                const error = typeof err === 'string' ? err : new Error(L('reset_error')).message;
                const message = error;
                toast.error(`${L(message)}`);
            },
            onSettled: () => {
                queryClient.invalidateQueries(propertiesQueryKeys.all);
            },
        }
    );
};

export default useSaveNewMember;
