import React, { useState } from 'react';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import styled from '@emotion/styled';
import { Loading } from '../../../components/Loading/Loading';
import GlobalFilter from '../../../components/Table/filterComponents/GlobalFilter';
import useQueryParams from '../../../hooks/useQueryParams';
import MailRow from '../components/MailRow';
import { L } from '../../../lib/i18n';
import { Button } from '../../../components/Buttons/Button';
import { NavLink, useParams } from 'react-router-dom';
import { useGetFolderById, useGetFolders, useGetMails } from '../hooks/mailQueries';
import { Pagination } from '../components/Pagination';
import { RefreshCw } from 'react-feather';
import { colors } from '../../../components/Shared/Style/colors';
import { keyframes } from '@emotion/react';
import * as Dialog from '@radix-ui/react-dialog';
import AddFolder from '../components/AddFolder';

const TopAreaWrapper = styled.div({
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
});

const ItemList = styled.ul({
    display: 'flex',
    flexDirection: 'column',
});

const StyledRefresh = styled(RefreshCw)({
    [':hover']: {
        color: colors.primary,
        cursor: 'pointer',
    },
});

const Overlay = styled(Dialog.Overlay)({
    background: 'rgba(0 0 0 / 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'grid',
    placeItems: 'center',
    overflowY: 'auto',
});

const Content = styled(Dialog.Content)({
    minWidth: 300,
});

const bounce = keyframes`
    from {
      transform: rotate(0deg)
    }
    to {
      transform: rotate(360deg)
     }
`;

const StyledRefreshRotating = styled(RefreshCw)({
    color: colors.primary,
    cursor: 'pointer',
    animation: `${bounce} 1s ease infinite`,
});

const Wrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '24px',
    flexDirection: 'row',
    padding: '0 5px',
    gap: '8px',
    justifyContent: 'space-between',
    '@media screen and (max-width: 600px)': {
        padding: '0 1rem',
    },
});

const DialogContainer = styled.div({
    ['* >']: {
        overflow: 'inherit',
    },
});

const MailPage: React.FC = () => {
    const { id } = useParams<{ id: string; page: string }>();
    const [open, setOpen] = useState<{ mailId?: number; folderName: string } | undefined>();
    const { data: folder } = useGetFolderById(Number(id));
    const { data: folders } = useGetFolders(['received', 'trash']);
    const { getQueryString, updateQueryString } = useQueryParams();
    const searchValue = getQueryString('search', '');
    const page = getQueryString('page', '');

    const { data, isLoading, refetch, isRefetching } = useGetMails(String(searchValue), Number(id), Number(page));
    const [refreshSpinnerShow, setRefreshSpinnerShow] = useState(false);
    const refresh = () => {
        setRefreshSpinnerShow(true);
        refetch();
        setTimeout(() => {
            setRefreshSpinnerShow(false);
        }, 1000);
    };

    const handleOnCreate = (mailId: number, folderName: string) => {
        setOpen({ mailId, folderName });
    };

    return (
        <>
            <DialogContainer>
                <Dialog.Root
                    open={!!open}
                    onOpenChange={(e) => {
                        if (!e) setOpen(undefined);
                    }}
                >
                    <Dialog.Portal>
                        <Overlay>
                            <Content>
                                <AddFolder
                                    mailId={open?.mailId}
                                    folderName={open?.folderName}
                                    onClose={() => setOpen(undefined)}
                                    folderList={folders?.filter((folder) => folder.type === 'received')}
                                />
                            </Content>
                        </Overlay>
                    </Dialog.Portal>
                </Dialog.Root>
            </DialogContainer>

            <CardWithDivider
                topArea={
                    <TopAreaWrapper>
                        <CardTitle>
                            {folder?.editable ? folder?.name : L(String(folder?.name))} - {data?.totalItems} {L('mail')}
                        </CardTitle>
                        <div style={{ display: 'flex', gap: '1rem' }}>
                            <NavLink style={{ display: 'block', alignSelf: 'flex-end' }} to={`/mail/new`}>
                                <Button>{L('new_mail')}</Button>
                            </NavLink>
                            <Button onClick={() => setOpen({ folderName: '' })}>{L('open_folder_options')}</Button>
                        </div>
                    </TopAreaWrapper>
                }
                mainArea={
                    <div>
                        <Wrapper>
                            {isLoading || refreshSpinnerShow || isRefetching ? (
                                <StyledRefreshRotating />
                            ) : (
                                <StyledRefresh onClick={refresh} />
                            )}

                            <GlobalFilter
                                filter={searchValue}
                                setFilter={(newSearch) => updateQueryString('search', String(newSearch))}
                                placeholder="Sök"
                            />
                        </Wrapper>

                        {data && folder ? (
                            <>
                                <ItemList>
                                    {data &&
                                        data.items.length === 0 &&
                                        (searchValue.length > 0 ? (
                                            <p>{L('no_mails_search')}</p>
                                        ) : (
                                            <p>{L('no_mails_yet')}</p>
                                        ))}

                                    {data?.items.map((mail) => (
                                        <MailRow
                                            key={mail.id}
                                            mail={mail}
                                            folder={folder}
                                            folders={folders?.filter((f) => f.id !== folder.id) ?? []}
                                            handleOnCreate={handleOnCreate}
                                        />
                                    ))}
                                </ItemList>
                                <div style={{ paddingTop: '1rem' }}>
                                    <Pagination
                                        page={data}
                                        onChangePage={(newPage) => updateQueryString('page', String(newPage))}
                                    />
                                </div>
                            </>
                        ) : (
                            <Loading />
                        )}
                    </div>
                }
                mobileNoPadding={true}
            />
        </>
    );
};

export default MailPage;
