import MembersApi from '@app/api/public/MembersApi';
import { useQuery } from 'react-query';

export const getInviteMailStatusQueryKey = 'getInviteMailStatus';

const getInviteMailStatus = (id: string) => {
    return MembersApi.getInviteMailStatus(id);
};

const useGetInviteMailStatus = (id: string) => {
    const { data } = useQuery([getInviteMailStatusQueryKey, id], () => getInviteMailStatus(id));

    if (!data) return undefined;

    const eventTypeList = data.map((email) => email.event_type);
    if (eventTypeList.includes('Open')) {
        return 'Open';
    }
    if (eventTypeList.includes('Bounce')) {
        return 'Bounce';
    }
    if (eventTypeList.includes('Complaint')) {
        return 'Complaint';
    }
    if (eventTypeList.includes('Delivery')) {
        return 'Delivery';
    }
    if (eventTypeList.includes('Send')) {
        return 'Send';
    }
};

export default useGetInviteMailStatus;
