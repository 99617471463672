import { useMutation, useQueryClient } from 'react-query';
import DocumentsApi from '@app/api/public/DocumentsApi';
import toast from 'react-hot-toast';
import { fileKeys } from '../../queryKeys';
import { L } from '../../../../lib/i18n';
import { UpdateFileDetails } from '@app/api/models/Folders';

const useUpdateFile = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: UpdateFileDetails) => {
            const res = await DocumentsApi.updateFile(variables);

            if (res.status === 'error') {
                throw new Error(res.message).message;
            }

            return res;
        },
        onError: (err) => {
            const errorMessage = typeof err === 'string' ? err : new Error(L('reset_error')).message;
            toast.error(`${L(errorMessage)}`);
        },
        onSettled: () => {
            queryClient.invalidateQueries([fileKeys]);
        },
        onSuccess: (data) => {
            console.log('SUCCESS!');
            data.message && toast.success(L(data.message));
        },
    });
};

export default useUpdateFile;
