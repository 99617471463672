import { useQueryClient, useMutation } from 'react-query';
import BookingsApi from '@app/api/public/BookingsApi';
import toast from 'react-hot-toast';
import { L } from '../../../lib/i18n';
import { bookingDocumentsListQuerykey } from './useGetBookingResourceDocuments';

type Variables = { resourceId: number; name: string; file: File };

export const saveBookingResourceDocuments = async (variables: Variables) => {
    return BookingsApi.uploadDocument({ file: variables.file, name: variables.name }, variables.resourceId);
};

const useSaveBookingResourceDocument = () => {
    const queryClient = useQueryClient();
    return useMutation((variabes: Variables) => saveBookingResourceDocuments(variabes), {
        onMutate: (file) => {
            const toastId = toast.loading(`${L('saving')} ${file.name}`);
            return toastId;
        },
        onError: (_error, file, toastId: string) => {
            toast.error(`${L('save_error')}`, {
                id: toastId,
            });
        },
        onSuccess: (_data, file, toastId: string) => {
            toast.success(`${file.name} ${L('saved')}`, {
                id: toastId,
            });
        },

        onSettled: (data, error, variables) => {
            queryClient.invalidateQueries(bookingDocumentsListQuerykey.resourceId(+variables.resourceId));
        },
    });
};

export default useSaveBookingResourceDocument;
