import { useQueryClient, useMutation, QueryClient } from 'react-query';
import PublicSiteApi from '@app/api/public/PublicSiteApi';
import { PublicSiteInformationItem } from '@app/api/models/PublicSite';
import { getQueryPageListItems } from './useGetPageListItems';
import { fileNameRegex } from '../../../lib/helpers/trimFileNames';

type Variables = { publicSiteItem: Partial<PublicSiteInformationItem>; file?: File };

export const updatePageListItem = async ({ publicSiteItem, file }: Variables) => {
    let dashedFileName: File;
    if (file) {
        dashedFileName = new File([file], file.name.replaceAll(fileNameRegex, '-'));
    }

    return PublicSiteApi.save(publicSiteItem, publicSiteItem.published_at ? true : false, dashedFileName);
};

const handleInvalidation = (queryClient: QueryClient, publicSiteItem: Partial<PublicSiteInformationItem>) => {
    queryClient.invalidateQueries(getQueryPageListItems.all);
    queryClient.invalidateQueries(getQueryPageListItems.id(publicSiteItem?.id?.toString()));

    if (publicSiteItem?.page_type === 'info_page') return;
    queryClient.invalidateQueries(getQueryPageListItems.id(publicSiteItem.page_type));
};
const useUpdatePageListItem = () => {
    const queryClient = useQueryClient();
    return useMutation((variables: Variables) => updatePageListItem(variables), {
        onMutate: ({ publicSiteItem }) => {
            const publicSiteItems = queryClient.getQueryData<PublicSiteInformationItem[]>(getQueryPageListItems.all);
            if (!publicSiteItems) return;
            if (publicSiteItem.page_type !== 'info_page') return;
            const publicSiteItemsCopy = [...publicSiteItems];
            const ids = publicSiteItemsCopy.map((item) => item.id);
            const index = ids.indexOf(publicSiteItem.id);
            publicSiteItemsCopy.splice(index, 1, publicSiteItem);
            queryClient.setQueryData(getQueryPageListItems.all, publicSiteItemsCopy);
        },
        onSuccess: (data) => {
            queryClient.setQueryData(getQueryPageListItems.id(data.id.toString()), data);
            handleInvalidation(queryClient, data);
        },
        onSettled: (_data, _error, { publicSiteItem }) => {
            handleInvalidation(queryClient, publicSiteItem);
        },
    });
};

export default useUpdatePageListItem;
