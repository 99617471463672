import styled from '@emotion/styled';
import React from 'react';
import { MouseEventHandler } from 'react';

const Li = styled.li(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.125rem',
    letterSpacing: '0.41px',
    color: theme.colors.tabInactive,
    boxSizing: 'border-box',
    display: 'block',
    marginRight: '16px',
    textDecoration: 'none',
    paddingBottom: '0px',

    '&:hover': {
        color: theme.colors.tabActive,
        cursor: 'pointer',
    },
    '&.active': {
        color: theme.colors.tabActive,
        textDecoration: 'none',
    },
}));

type Props = {
    onClick?: MouseEventHandler;
    className?: string;
};

const BigTabListItem: React.FC<Props> = ({ children, onClick, ...rest }) => {
    return (
        <Li onClick={onClick} {...rest}>
            {children}
        </Li>
    );
};

export default BigTabListItem;
