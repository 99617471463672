import { SiteTheme } from '@app/api/models/PublicSite';
import PublicSiteApi from '@app/api/public/PublicSiteApi';
import { useMutation, useQueryClient } from 'react-query';
import { createValidator, required } from '../../../../lib/validation';

type Variables = Pick<SiteTheme, 'theme' | 'name'> & {
    id?: SiteTheme['id'];
};

const nameRules = createValidator({
    name: [required()],
});
const colorsRules = createValidator({
    navbarBackground: [required()],
    primary: [required()],
    footerBackground: [required()],
    pageBackground: [required()],
    textColor1: [required()],
    footerText: [required()],
});
const bodyFontRules = createValidator({
    fontFamily: [required()],
});
const headerFontRules = createValidator({
    fontFamily: [required()],
});
const saveTheme = (variables: Variables) => {
    const name = nameRules.test(variables);
    const colors = colorsRules.test(variables?.theme?.colors);
    const body = bodyFontRules.test(variables?.theme?.fonts?.body);
    const header = headerFontRules.test(variables?.theme?.fonts?.header);

    if (Object.keys(name.errors).length) return Promise.reject(name.errors);
    if (Object.keys(colors.errors).length) return Promise.reject(colors.errors);
    if (Object.keys(body.errors).length) return Promise.reject(body.errors);
    if (Object.keys(header.errors).length) return Promise.reject(header.errors);
    return PublicSiteApi.saveTheme(variables);
};
const useSaveCustomTheme = () => {
    const queryClient = useQueryClient();

    const { error, ...rest } = useMutation(
        (variables: Variables) => {
            return saveTheme(variables);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries('getThemes');
                queryClient.invalidateQueries('getThemeByProjectId');
            },
        }
    );

    if (typeof error === 'object') {
        return { error, ...rest };
    }
    return { error: null, ...rest };
};

export default useSaveCustomTheme;
