import { Property } from '@app/api/models/Property';
import MembersApi from '@app/api/public/MembersApi';
import { useQuery } from 'react-query';
import PropertiesApi from '@app/api/public/PropertiesApi';
import { z } from 'zod';

export const propertiesQueryKeys = {
    all: [{ scope: 'properties' }] as const,
    lists: () => [{ ...propertiesQueryKeys.all[0], entity: 'list' }] as const,
    details: () => [{ ...propertiesQueryKeys.all[0], entity: 'detail' }] as const,
    detail: (id: number) => [{ ...propertiesQueryKeys.details()[0], id }] as const,
    membersLists: () => [{ ...propertiesQueryKeys.all[0], entity: 'membersList' }] as const,
    membersList: (id: number) => [{ ...propertiesQueryKeys.membersLists()[0], id }] as const,
    memberNameByEmails: () => [{ ...propertiesQueryKeys.all[0], entiry: 'email' }] as const,
    memberNameByEmail: (email: string) => [{ ...propertiesQueryKeys.memberNameByEmails()[0], email }] as const,
};

const getPropertyUsersByPropertyId = (id: Property['id']) => {
    return MembersApi.listByPropertyId(+id);
};

export const useGetPropertyMembers = (id: Property['id']) => {
    return useQuery({
        queryFn: ({ queryKey }) => getPropertyUsersByPropertyId(queryKey[0].id),
        queryKey: propertiesQueryKeys.membersList(id),
    });
};

const getProperty = (id?: number) => {
    if (!id) return;
    return PropertiesApi.getbyid(id);
};

export const useGetProperty = (id: number) => {
    return useQuery({
        queryFn: ({ queryKey }) => {
            const id = queryKey[0].id;
            return getProperty(id);
        },
        queryKey: propertiesQueryKeys.detail(id),
    });
};

const getPropertiesList = () => {
    return PropertiesApi.listWithUsers();
};

export const useGetPropertiesList = () => {
    return useQuery({
        queryFn: () => {
            return getPropertiesList();
        },
        queryKey: propertiesQueryKeys.lists(),
    });
};

export const useGetUserNameByEmail = (email: string) => {
    return useQuery({
        queryKey: propertiesQueryKeys.memberNameByEmail(email),
        queryFn: async ({ queryKey }) => {
            const { email } = queryKey[0];
            return MembersApi.getUsersNameByEmail(email);
        },
        enabled: z.string().email().safeParse(email).success,
    });
};
