Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["getlist"] = createRpcProxy("DocumentsApi", "getlist", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["listByDocumentables"] = createRpcProxy("DocumentsApi", "listByDocumentables", {
  "urlPrefix": "/rpc"
});
o["getbyid"] = createRpcProxy("DocumentsApi", "getbyid", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["get"] = createRpcProxy("DocumentsApi", "get", {
  "urlPrefix": "/rpc"
});
o["uploadToAwsStore"] = createRpcProxy("DocumentsApi", "uploadToAwsStore", {
  "urlPrefix": "/rpc"
});
o["uploadFilesToStore"] = createRpcProxy("DocumentsApi", "uploadFilesToStore", {
  "urlPrefix": "/rpc"
});
o["uploadToPublic"] = createRpcProxy("DocumentsApi", "uploadToPublic", {
  "urlPrefix": "/rpc"
});
o["getSignedUrlStore"] = createRpcProxy("DocumentsApi", "getSignedUrlStore", {
  "urlPrefix": "/rpc"
});
o["getSignedUrl"] = createRpcProxy("DocumentsApi", "getSignedUrl", {
  "urlPrefix": "/rpc"
});
o["saveDoc"] = createRpcProxy("DocumentsApi", "saveDoc", {
  "urlPrefix": "/rpc"
});
o["updateDocumentName"] = createRpcProxy("DocumentsApi", "updateDocumentName", {
  "urlPrefix": "/rpc"
});
o["getListPublicFiles"] = createRpcProxy("DocumentsApi", "getListPublicFiles", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["deleteDocument"] = createRpcProxy("DocumentsApi", "deleteDocument", {
  "urlPrefix": "/rpc"
});
o["getFoldersByParentId"] = createRpcProxy("DocumentsApi", "getFoldersByParentId", {
  "urlPrefix": "/rpc"
});
o["getFolderById"] = createRpcProxy("DocumentsApi", "getFolderById", {
  "urlPrefix": "/rpc"
});
o["createFolders"] = createRpcProxy("DocumentsApi", "createFolders", {
  "urlPrefix": "/rpc"
});
o["createFolder"] = createRpcProxy("DocumentsApi", "createFolder", {
  "urlPrefix": "/rpc"
});
o["updateFolder"] = createRpcProxy("DocumentsApi", "updateFolder", {
  "urlPrefix": "/rpc"
});
o["deleteFolder"] = createRpcProxy("DocumentsApi", "deleteFolder", {
  "urlPrefix": "/rpc"
});
o["uploadDocumentFiles"] = createRpcProxy("DocumentsApi", "uploadDocumentFiles", {
  "urlPrefix": "/rpc"
});
o["getFilesByFolderId"] = createRpcProxy("DocumentsApi", "getFilesByFolderId", {
  "urlPrefix": "/rpc"
});
o["updateFile"] = createRpcProxy("DocumentsApi", "updateFile", {
  "urlPrefix": "/rpc"
});
o["moveItems"] = createRpcProxy("DocumentsApi", "moveItems", {
  "urlPrefix": "/rpc"
});
o["deleteFile"] = createRpcProxy("DocumentsApi", "deleteFile", {
  "urlPrefix": "/rpc"
});
o["getS3FileUrl"] = createRpcProxy("DocumentsApi", "getS3FileUrl", {
  "urlPrefix": "/rpc"
});
o["uploadFilesAndFolders"] = createRpcProxy("DocumentsApi", "uploadFilesAndFolders", {
  "urlPrefix": "/rpc"
});
o["deleteFilesAndFolders"] = createRpcProxy("DocumentsApi", "deleteFilesAndFolders", {
  "urlPrefix": "/rpc"
});