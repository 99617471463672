Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["list"] = createRpcProxy("HelpApi", "list", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["get"] = createRpcProxy("HelpApi", "get", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getById"] = createRpcProxy("HelpApi", "getById", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});