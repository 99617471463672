import ContactsApi from '@app/api/public/ContactsApi';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { L } from '../../../lib/i18n';
import { getContactListQueryKey } from './useGetContacts';

const removeContact = (variables: { id: string }) => {
    return ContactsApi.remove(variables.id);
};

const useRemoveContact = () => {
    const queryClient = useQueryClient();
    return useMutation((variables: { id: string }) => removeContact(variables), {
        onError: (err) => {
            /* Shows error message */
            const error = typeof err === 'string' ? err : new Error(L('reset_error')).message;
            const message = error;
            toast(`❌ ${L(message)}`);
        },
        onSettled: () => {
            queryClient.invalidateQueries([getContactListQueryKey]);
        },
    });
};

export default useRemoveContact;
