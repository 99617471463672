import { SiteTheme } from '@app/api/models/PublicSite';
import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div({
    margin: '1rem',
});
const BoxWrapper = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    border: '1px solid black',
    height: '100px',
    width: '100px',
});
const Box = styled.div(({ colorValue }: { colorValue: string }) => {
    return {
        flex: 'calc(50%)',
        backgroundColor: colorValue,
    };
});

type Props = {
    siteTheme: SiteTheme['theme'];
};
const ThemePreviewBox = ({ siteTheme }: Props) => {
    return (
        <Container>
            <BoxWrapper>
                {Object.values(siteTheme?.colors)?.map((colorValue, index) => {
                    if (index > 3) return null;
                    return <Box key={index} colorValue={colorValue} />;
                })}
            </BoxWrapper>
        </Container>
    );
};

export default ThemePreviewBox;
