import React from 'react';
import AddMailRecipients from './AddMailRecipients';
import MailChips from './MailChips';
import { style } from '../../../../components/Shared/Style';
import styled from '@emotion/styled';

type Props = {
    recipients: string[];
    memoizedRecipients: { value: string; id: string }[];
    recipientsInput: string;
    handleSetRecipients: (recipients: string[]) => void;
    handleSetRecipientsInput: (input: string) => void;
};

const MailInputWithChipsContainer = styled.div({
    border: '1px solid hsl(0,0%,80%)',
    borderRadius: '4px',
    display: 'flex',
    flexWrap: 'wrap',
    padding: style.margin.xs,
    columnGap: '0.2rem',
    width: '100%',
    minHeight: '32px',
    alignItems: 'center',
    '@media screen and (min-width: 1024px)': {
        width: '75%',
    },
    '@media screen and (min-width: 1440px)': {
        width: '50%',
    },
});

const MailInputWithChips = ({
    recipients,
    recipientsInput,
    memoizedRecipients,
    handleSetRecipients,
    handleSetRecipientsInput,
    ...rest
}: Props & React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <MailInputWithChipsContainer {...rest}>
            <MailChips
                handleSetRecipients={handleSetRecipients}
                recipients={recipients}
                handleSetRecipientsInput={handleSetRecipientsInput}
                memoizedRecipients={memoizedRecipients}
            />
            <AddMailRecipients
                recipients={recipients}
                handleSetRecipients={handleSetRecipients}
                recipientsInput={recipientsInput}
                handleSetRecipientsInput={handleSetRecipientsInput}
            />
        </MailInputWithChipsContainer>
    );
};

export default MailInputWithChips;
