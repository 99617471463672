import styled from '@emotion/styled';
import React from 'react';
import { style } from '../../../../components/Shared/Style';
import * as EmailValidator from 'email-validator';
import { Icon } from '../../../../components/Icon/Icon';

const PillWrapper = styled.span(({ theme }) => ({
    display: 'flex',
    padding: '0.15rem 0.3rem',
    alignItems: 'center',
    borderRadius: '8px',
    color: style.colors.textColor2,
    fontSize: style.fontSize.milli,
    fontWeight: style.fontWeight.normal,
    textAlign: 'center',
    border: `1px solid ${theme.colors.inputBorder}`,
    columnGap: '0.2rem',
    cursor: 'pointer',
}));

const SetRecipientsButton = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
});

type Props = {
    handleSetRecipients: (recipients: string[]) => void;
    handleSetRecipientsInput?: (input: string) => void;
    recipients: { value: string; id: string }[];
    recipient: { value: string; id: string };
};

const Pill = ({ handleSetRecipients, handleSetRecipientsInput, recipients, recipient }: Props) => {
    const isValidAdress = EmailValidator.validate(recipient.value);
    return (
        <PillWrapper style={!isValidAdress ? { borderColor: '#ff4545', backgroundColor: '#fcedf1' } : {}}>
            <div
                onDoubleClick={() => {
                    if (handleSetRecipientsInput) handleSetRecipientsInput(recipient.value);
                    const filteredArr = recipients.filter((item) => item.id !== recipient.id);
                    const onlyAdressArr = filteredArr.map((item) => item.value);
                    handleSetRecipients(onlyAdressArr);
                }}
            >
                {recipient.value}
            </div>
            <SetRecipientsButton
                role="button"
                onClick={() => {
                    const filteredArr = recipients.filter((item) => item.id !== recipient.id);
                    const onlyAdressArr = filteredArr.map((item) => item.value);
                    handleSetRecipients(onlyAdressArr);
                }}
            >
                <Icon.Cross size="sMedium" />
            </SetRecipientsButton>
        </PillWrapper>
    );
};

export default Pill;
