import {
    BoardMemberDetail,
    BoardMemberSubRoleType,
    InviteBoardMember,
} from '@app/api/models/BoardMembers/BoardMembersModels';
import styled from '@emotion/styled';
import toast from 'react-hot-toast';
import React, { useState } from 'react';
import { Button } from '../../../components/Buttons/Button';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import Label from '../../../components/Forms/Label';
import { L } from '../../../lib/i18n';
import { UseMutateFunction } from 'react-query';
import { useModal } from '../../../components/Modal/Context/ModalContext';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { useLocalStorage } from 'react-use';
import DropdownButton from '../../../components/Buttons/DropdownButton';

type DropdownBoardMembers = {
    valueArray: string[];
    sub_role: string;
    role: string;
    display_incontacts: boolean;
    invite: boolean;
};

type Props = {
    saveBoardMember: UseMutateFunction<
        BoardMemberDetail | undefined,
        unknown,
        InviteBoardMember & {
            user_id: number;
            invite: boolean;
        },
        unknown
    >;
    options:
        | {
              value: (string | number)[];
              label: JSX.Element;
          }[]
        | undefined;
    roles: {
        label: any;
        value: string;
    }[];
    subRoles: BoardMemberSubRoleType[];
    status: 'error' | 'success' | 'idle' | 'loading';
    resetSave: () => void;
    projectType?: string;
};

const ButtonWrapper = styled.div({
    padding: '1rem 0px 0px 0px',
    display: 'flex',
    gap: '1rem',
    right: '2rem',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: '1rem',
});

const Form = styled.form({});

function ExistingMemberForm({ saveBoardMember, options, roles, subRoles, status, resetSave, projectType }: Props) {
    const [boardMember, setBoardMember] = useState<DropdownBoardMembers>({
        valueArray: [],
        role: '',
        sub_role: '',
        display_incontacts: false,
        invite: false,
    });

    const [localSubmitButtonState, setSubmitButtonState] = useLocalStorage<'save' | 'save_and_invite'>(
        'newPropertyMember',
        'save_and_invite'
    );
    const submitButtonState = localSubmitButtonState || 'save_and_invite';

    const { ToggleIsModalOpen } = useModal();

    const submit = (type: 'save' | 'save_and_invite') => {
        if (!boardMember) return;
        const bm = {
            email: boardMember.valueArray[1],
            role: boardMember?.role,
            sub_role: boardMember?.sub_role,
            display_incontacts: boardMember?.display_incontacts,
            user_id: +boardMember.valueArray[2],
            invite: type === 'save' ? false : true,
        };
        if (type === 'save') {
            if (bm.email && bm.role) {
                saveBoardMember(bm, {
                    onSuccess: () => {
                        toast.success(`${L('member')} ${bm.email} ${L('saved')}`);
                        ToggleIsModalOpen();
                    },
                });
            }
        } else if (type === 'save_and_invite') {
            if (bm.email && bm.role) {
                saveBoardMember(bm, {
                    onSuccess: () => {
                        toast.success(`${L('member')} ${bm.email} ${L('saved')}`);
                        ToggleIsModalOpen();
                    },
                });
            }
        }
    };

    return (
        <Form>
            <Label title={L('member_account')}>
                <Dropdown
                    placeholder={L('email')}
                    name="email"
                    options={options || []}
                    value={boardMember.valueArray}
                    onChange={(value) => {
                        resetSave();
                        setBoardMember((prev) => ({ ...prev, valueArray: value }));
                    }}
                />
            </Label>
            <Label title={L('role')}>
                <Dropdown
                    placeholder={L('boardmember_role')}
                    name={'role'}
                    options={roles}
                    value={boardMember.role}
                    onChange={(value) => {
                        resetSave();
                        setBoardMember((prev) => ({ ...prev, role: value }));
                    }}
                />
            </Label>
            {projectType === 'housing_society' && (
                <Label title={L('boardmember_subrole')}>
                    <Dropdown
                        placeholder={L('boardmember_subrole')}
                        name={'sub_role'}
                        options={
                            subRoles?.map((subRole) => {
                                return {
                                    value: subRole,
                                    label: L(`${subRole}`),
                                };
                            }) || []
                        }
                        value={boardMember.sub_role}
                        onChange={(value) => {
                            resetSave();
                            setBoardMember((prev) => ({ ...prev, sub_role: value }));
                        }}
                    />
                </Label>
            )}

            <Label title={L('boardmember_contact')}>
                <Checkbox
                    onChange={(e) => {
                        resetSave();
                        setBoardMember((prev) => ({ ...prev, display_incontacts: e.target.checked }));
                    }}
                    name="contact"
                />
            </Label>

            <ButtonWrapper>
                <Button type="button" onClick={() => ToggleIsModalOpen()} variant="primary">
                    {L('cancel')}
                </Button>
                <DropdownButton
                    variant="filledPrimary"
                    setOptionId={(state) => {
                        setSubmitButtonState(state);
                    }}
                    disabled={boardMember.valueArray.length > 0 && boardMember.role ? false : true}
                    optionId={submitButtonState}
                    options={[
                        {
                            dropdownItem: <DropdownButton.DropdownItem>{L('add_admin')}</DropdownButton.DropdownItem>,
                            id: 'save',
                            button: (
                                <DropdownButton.Button onClick={() => submit('save')}>
                                    {L('add_admin')}
                                </DropdownButton.Button>
                            ),
                        },
                        {
                            dropdownItem: (
                                <DropdownButton.DropdownItem>{L('save_admin_and_invite')}</DropdownButton.DropdownItem>
                            ),
                            id: 'save_and_invite',
                            button: (
                                <DropdownButton.Button onClick={() => submit('save_and_invite')}>
                                    {L('save_admin_and_invite')}
                                </DropdownButton.Button>
                            ),
                        },
                    ]}
                />
            </ButtonWrapper>
        </Form>
    );
}

export default ExistingMemberForm;
