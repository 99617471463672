import { useSearchParams } from 'react-router-dom-v5-compat';

const useAddSearchParams = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const addSearchParams = (data: { [key: string]: string }) => {
        const newSearchParams = new URLSearchParams(searchParams);

        Object.entries(data).forEach(([key, value]) => {
            newSearchParams.set(key, value);
        });

        setSearchParams(newSearchParams);
    };

    return { addSearchParams };
};

export default useAddSearchParams;
