import styled from '@emotion/styled';
import React, { useState, FC, ReactNode } from 'react';
import { animated, useSpring } from 'react-spring';
import { useHeight } from '../../../lib/hooks/useheight';
import { Icon } from '../../../components/Icon/Icon';
import { match } from 'react-router-dom';
import { style } from '../../Shared/Style';
import { margin } from '../../Shared/Style/margin';

const GroupingHeader = styled.div({
    position: 'sticky',
    fontSize:'14px',
    top: 0,
    left: 0,
    right: 0,
    cursor: 'pointer',
});

const GroupingWrapper = styled.div(() => ({
    position: 'relative',
    overflow: 'hidden',
}));

const Title = styled.span({
    marginLeft: style.margin.m,
});

const HeaderWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    width: '100%',
    padding: '10px 0px 10px 13px',
    borderLeft: '4px solid transparent',
    justifyContent:'space-between',
    color: theme.colors.sideBarInactive,
    '&.active': {
        borderLeftColor: theme.colors.primary,
        backgroundColor: theme.colors.primaryLight,
    },
}));

const HeaderElement = styled.div(() => ({
    display: 'flex',
}));

const IconWrapper = styled.div(({ theme }) => {
    return {
        '&.hide': {
            visibility: 'hidden',
        },
    };
});

const RowContainer = animated(
    styled.div({
        display: 'grid',
        fontSize: '13px',
        '& a:first-of-type': {
            marginTop: '10px',
        },
    })
);
type Props = {
    title: ReactNode;
    icon: string;
    match: match<{}> | null;
};

const NavAcordion: FC<Props> = ({ children, title, icon, match, ...rest }) => {
    const NavIcon = Icon[icon] || Icon['Binder'];

    const [expanded, setExpanded] = useState(false);

    const [heightRef, height] = useHeight();

    const rowProps = useSpring({
        opacity: expanded ? 1 : 0,
        height: expanded ? height : 0,
    });

    const toggleExpanded = () => {
        setExpanded((prev) => !prev);
    };

    return (
        <GroupingWrapper className={expanded ? 'active' : ''} {...rest}>
            <GroupingHeader onClick={toggleExpanded}>
                <HeaderWrapper className={!expanded && match ? 'active' : ''}>
                    <HeaderElement>
                        <IconWrapper>
                            <NavIcon />
                        </IconWrapper>
                        <Title>{title}</Title>
                    </HeaderElement>
                    <Icon.DownArrow
                        style={{
                            marginRight: style.margin.m,
                            alignSelf: 'center',
                            justifySelf: 'flex-end',
                            transform: expanded ? 'rotate(180deg) scaleX(-1)' : 'none',
                        }}
                        size="small"
                    />
                </HeaderWrapper>
            </GroupingHeader>
            <animated.div style={rowProps}>
                <RowContainer ref={heightRef} aria-expanded={expanded}>
                    {children}
                </RowContainer>
            </animated.div>
        </GroupingWrapper>
    );
};

export default NavAcordion;
