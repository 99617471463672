import { File } from 'aws-sdk/clients/codecommit';
import { L } from './i18n';

export function required(): ValidationRule {
    return {
        test(value: any) {
            if (!!value) return;
            return L('error_required');
        },
    };
}
export function isFile(fileType?: string[]): ValidationRule {
    return {
        test(file: any) {
            if (!file) return;
            if (file instanceof window.File) {
                if (fileType && fileType.includes(file.type)) return `${L('error_must_be_type')} ${fileType}`;
                return;
            }
            return L('error_file_type');
        },
    };
}

export function requireOneOf(keys: string[]): ValidationRule {
    return {
        test(value: any, model: any) {
            if (keys.filter((k) => !!model[k]).length >= 1) return;
            return L('error_oneisrequired');
        },
    };
}

class ModelValidator {
    rules: ValidationRules;
    constructor(rules: ValidationRules) {
        this.rules = rules;
    }
    test(model: any): ValidationResult {
        const results: ValidationResult = { ok: true, errors: {} };
        for (const rules of Object.entries(this.rules)) {
            const key = rules[0];
            const value = model[key];

            for (const rule of rules[1]) {
                const errorText = rule.test(value, model);
                if (errorText) {
                    results.errors[key] = errorText;
                    results.ok = false;
                    break;
                }
            }
        }
        return results;
    }
}

export interface ValidationRules {
    [key: string]: ValidationRule[];
}

export interface ValidationResult {
    ok: boolean;
    errors: ValidationErrors;
}

export type ValidationErrors = { [key: string]: string };

export interface ValidationRule {
    test(value: any, model: any): string | undefined;
}

export function createValidator(rules: ValidationRules): ModelValidator {
    return new ModelValidator(rules);
}
