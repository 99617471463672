import React from 'react';
import { GroupBase } from 'react-select';
import Creatable, { CreatableProps } from 'react-select/creatable';
import * as Icons from 'react-feather';
import { style } from '../Shared/Style';
import { L } from '../../lib/i18n';
import isEqual from 'lodash/isEqual';
import { useTheme } from 'emotion-theming';
import { ThemeType } from '@app/api/models/Theme';

const styles = {
    container: (st) => ({ ...st, fontSize: '80%', height: 32 }),
    control: (st) => ({
        ...st,
        borderRadius: 4,
        minHeight: '32px',
        cursor: 'pointer',
    }),
    indicatorsContainer: (st) => ({ ...st, padding: 0 }),
    indicatorSeparator: (st) => ({ ...st, display: 'none' }),
    placeholder: (st) => ({
        ...st,
        position: 'relative',
        transform: 'inherit',
        maxWidth: 'calc(100% - 8px)',
        cursor: 'pointer',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    }),
    singleValue: (st) => ({
        ...st,
        position: 'relative',
        transform: 'inherit',
        width: '100%',
    }),
    option: (st) => ({
        ...st,
        cursor: 'pointer',
        color: 'hsl(0,0%,20%)',
    }),
    menu: (st) => ({ ...st, minWidth: '12em', backgroundColor: 'white' }),
};

const customComponents = {
    DropdownIndicator() {
        return <Icons.ChevronDown color="#999" style={{ paddingRight: 3 }} size={16} />;
    },
    ClearIndicator(props) {
        const click = (evt) => {
            props.clearValue();
            evt.stopPropagation();
        };
        return <Icons.X onMouseDown={click} onClick={click} color="#999" style={{ paddingRight: 3 }} size={16} />;
    },
};

function DropDownCreatable<
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>({ components, ...rest }: CreatableProps<Option, IsMulti, Group>) {
    const themes = useTheme<ThemeType>();
    return (
        <Creatable
            components={{ ...customComponents, ...components }}
            styles={styles}
            isOptionSelected={(option, selectedOptions) => {
                return !!selectedOptions.find((selected) => isEqual(option, selected));
            }}
            theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary50: themes.colors.inputBackground,
                    primary25: themes.colors.inputBackground,
                    primary: themes.colors.inputBackground,
                    neutral0: themes.colors.inputBackground,
                },
            })}
            formatCreateLabel={(value) => `${L('create')}: "${value}"`}
            noOptionsMessage={() => `${L('write_to_create_new')}`}
            {...rest}
        />
    );
}

export default DropDownCreatable;
