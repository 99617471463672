import styled from '@emotion/styled';
import React from 'react';
import { Button } from '../../../components/Buttons/Button';
import { colors } from '../../../components/Shared/Style/colors';
import { margin } from '../../../components/Shared/Style/margin';
import { fontSize } from '../../../components/Shared/Style/typography';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';

const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const Count = styled.span({
    color: colors.black80,
    fontSize: fontSize.seta,
    marginLeft: margin.xs,
});

const TopArea = ({ totalItems, ToggleIsModalOpen }) => {
    return (
        <Wrapper>
            <CardTitle>{L('all_board_members_description')}</CardTitle>
            <div>
                <Button style={{ marginRight: margin.s }} onClick={ToggleIsModalOpen}>
                    {L('new')} {L('board_member_content')}
                </Button>
            </div>
        </Wrapper>
    );
};

export default TopArea;
