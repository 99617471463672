import React from 'react';
import toast from 'react-hot-toast';
import { L } from '../../lib/i18n';
import { Button } from '../Buttons/Button';

type Args = {
    continueFn: () => void;
    continueButtonText: string;
};
const triggerDirtyFieldsToats = ({ continueFn, continueButtonText }: Args) =>
    toast(
        (t) => (
            <div>
                <p>{L('unsaved_data')}</p>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        onClick={(e) => {
                            continueFn();
                            toast.dismiss(t.id);
                        }}
                    >
                        {continueButtonText}
                    </Button>
                    <Button
                        variant="filledPrimary"
                        onClick={(e) => {
                            toast.dismiss(t.id);
                        }}
                    >
                        {L('stay')}
                    </Button>
                </div>
            </div>
        ),
        { duration: Infinity, id: 'dirty' }
    );

export default triggerDirtyFieldsToats;
