import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../Shared/Style/colors';
import { style } from '../Shared/Style';

const AddButton = styled.div({
    fontSize: '1.5rem',
    color: 'white',
    backgroundColor: colors.tabActive,
    borderRadius: style.roundCornerSize.small,
    width: '32px',
    height: '32px',
    cursor: 'pointer',
});

const AddButtonWrapper = styled.button({
    display: 'flex',
    justifyContent: 'flex-end',
    textDecoration: 'none',
    margin: '0px',
    padding: '0px',
    border: 'none',
    width: 'max-content',
    backgroundColor: 'inherit',
});
const Plus = styled.span({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const Add = ({
    ...rest
}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
    return (
        <AddButtonWrapper {...rest}>
            <AddButton>
                <Plus>+</Plus>
            </AddButton>
        </AddButtonWrapper>
    );
};

export default Add;
