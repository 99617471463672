import { useMutation, useQueryClient } from 'react-query';
import customFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import { createValidator } from '../../../lib/validation';
import { useParams } from 'react-router';
import BookingsApi from '@app/api/public/BookingsApi';
import { BookingSlot } from '@app/api/models/Bookings';
import { getbookingResourceSlotsQueryKey } from './useGetBookingResourceSlots';
import { stringMap } from 'aws-sdk/clients/backup';

dayjs.extend(customFormat);

type Variables = {
    id: string;
};

const useRemoveTimeSlot = () => {
    const queryClient = useQueryClient();
    const { bookingId } = useParams<{ bookingId: string }>();
    return useMutation(
        async (variables: Variables) => {
            const response = await BookingsApi.removeSlot(variables.id);

            if (response.status === 'error') {
                return Promise.reject({ id: variables.id, message: response.message });
            }

            return response.value;
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries([getbookingResourceSlotsQueryKey, bookingId]);
            },
        }
    );
};

export default useRemoveTimeSlot;
