import styled from '@emotion/styled';
import * as React from 'react';

const CheckArea = styled.input({});

const Checkmark = styled.span({
    position: 'absolute',
    top: 0,
    left: 0,
    height: '15px',
    width: '15px',
    backgroundColor: '#eee',
    borderRadius: '4px',
    '&:after': {
        content: '""',
        position: 'absolute',
        display: 'none',
    },
});

const Container = styled.label({
    display: 'inline-block',
    position: 'relative',
    paddingLeft: '35px',
    marginBottom: '12px',
    cursor: 'pointer',
    fontSize: '22px',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
    [`${CheckArea}`]: {
        position: 'absolute',
        opacity: 0,
        cursor: 'pointer',
        height: '0px',
        width: '0px',
    },
    [`&:hover ${CheckArea} ~ ${Checkmark}`]: {
        backgroundColor: '#ccc',
    },
    [`& ${CheckArea}:checked ~ ${Checkmark}`]: {
        backgroundColor: '#C2D6A1',
    },
    [`& ${CheckArea}:checked ~ ${Checkmark}:after`]: {
        display: 'block',
    },
    [`& ${Checkmark}:after`]: {
        left: '5.4px',
        top: '3px',
        width: '3px',
        height: '6px',
        border: 'solid white',
        borderWidth: '0 2px 2px 0',
        WebkitTransform: 'rotate(45deg)',
        msTransform: 'rotate(45deg)',
        transform: 'rotate(45deg)',
    },
});

interface Props {
    checked?: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
    onClick?: () => void;
    name?: string;
    defaultChecked?: boolean;
}

export const Checkbox = (p: Props) => {
    return (
        <Container>
            <CheckArea
                checked={p.checked}
                defaultChecked={p.defaultChecked}
                name={p.name}
                onChange={p.onChange}
                type="checkbox"
                onClick={p.onClick}
            />
            <Checkmark />
        </Container>
    );
};
