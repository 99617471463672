import React from 'react';
import { Route } from 'react-router-dom';
import MemberInformationPage from './MemberInformationPage';

export const routes = [
    <Route key="1" path="/information/:id" component={MemberInformationPage} />,
    <Route key="0" path="/information" component={MemberInformationPage} />,
];

export const navigation = {
    name: 'member_content_information',
    link: '/information',
    icon: 'Information',
    parent: 'Members',
};
