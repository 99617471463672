import MembersApi from '@app/api/public/MembersApi';
import { useMutation } from 'react-query';

const renderCsv = async ({}) => {
    const r = await MembersApi.exportMemberInfo();
    return r;
};

const useRenderCsv = () => {
    return useMutation(renderCsv);
};

export default useRenderCsv;
