import React, { useState } from 'react';
import styled from '@emotion/styled';
import useDeleteSettingsListByType from '../hooks/useDeleteSettingsListByType';
import useSaveSettingsListByType from '../hooks/useSaveSettingsListByType';
import { MaintenanceCategory } from '@app/api/models/Maintenances';
import BoxColorPicker from '../../../components/BoxColorPicker/BoxColorPicker';
import Input from '../../../components/Forms/Input';
import { Button } from '../../../components/Buttons/Button';
import { L } from '../../../lib/i18n';
import { isEqual } from 'lodash';

const Save = styled(Button)({
    height: '100%',
    margin: '0px',
});

const GridWrapper = styled.div({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'min-content auto min-content',
    '&.type': {
        gridTemplateColumns: 'auto min-content',
    },
    gap: '1rem',
});

const RemoveButton = styled(Button)({
    height: '100%',
});

type Props = {
    item: MaintenanceCategory & { id: number };
    settingsType: 'category' | 'status' | 'unit' | 'type';
};

const CategorySettingItem = ({ item, settingsType, ...rest }: Props) => {
    const [color, setColor] = useState(item.color_code);
    const [name, setName] = useState(item.name || '');
    const [confirmRemove, setConfirmRemove] = useState(false);
    const { mutate: remove } = useDeleteSettingsListByType();
    const { mutate: save, status: saveStatus } = useSaveSettingsListByType(settingsType);

    const edited = !isEqual({ name: item.name, color: item.color_code }, { name, color });

    const handleSave = () => {
        if (!edited) return;
        save({ name, color_code: color, id: item.id });
    };

    const removeButton = confirmRemove ? (
        <RemoveButton onClick={() => remove({ id: item.id, settingstype: settingsType })} variant="delete">
            {L('remove')}
        </RemoveButton>
    ) : (
        <RemoveButton onClick={() => setConfirmRemove(true)}>{L('remove')}</RemoveButton>
    );
    return (
        <GridWrapper className={settingsType} {...rest}>
            {settingsType !== 'type' && (
                <BoxColorPicker initialColor={item.color_code || ''} getValue={(value) => setColor(value)} />
            )}

            <Input onChange={(e) => setName(e.target.value)} value={name} />
            <div>
                {edited ? (
                    <Save variant="selectedMenu" onClick={handleSave} disabled={saveStatus === 'loading'}>
                        {saveStatus === 'loading' ? L('saving') : L(`save`)}
                    </Save>
                ) : (
                    removeButton
                )}
            </div>
        </GridWrapper>
    );
};

export default CategorySettingItem;
