import { useMutation, useQueryClient } from 'react-query';
import ServicePartnersApi, { ServicePartner } from '@app/api/public/ServicePartnersApi';
import { servicePartnersQueryKeys } from './servicePartnerQueries';

type Variables = Partial<ServicePartner>;

export const saveServicePartner = async (variables: Variables) => {
    return await ServicePartnersApi.save(variables);
};

const useSaveServicePartner = () => {
    const queryClient = useQueryClient();
    return useMutation((variables: Variables) => saveServicePartner(variables), {
        onSettled: () => {
            queryClient.invalidateQueries(servicePartnersQueryKeys.all);
        },
    });
};

export default useSaveServicePartner;
