Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["getbyid"] = createRpcProxy("PropertiesApi", "getbyid", {
  "urlPrefix": "/rpc"
});
o["list"] = createRpcProxy("PropertiesApi", "list", {
  "urlPrefix": "/rpc"
});
o["listWithUsers"] = createRpcProxy("PropertiesApi", "listWithUsers", {
  "urlPrefix": "/rpc"
});
o["save"] = createRpcProxy("PropertiesApi", "save", {
  "urlPrefix": "/rpc"
});
o["copy"] = createRpcProxy("PropertiesApi", "copy", {
  "urlPrefix": "/rpc"
});
o["setTransferComplete"] = createRpcProxy("PropertiesApi", "setTransferComplete", {
  "urlPrefix": "/rpc"
});
o["getPropertiesToTransfer"] = createRpcProxy("PropertiesApi", "getPropertiesToTransfer", {
  "urlPrefix": "/rpc"
});
o["scheduleTransferProperty"] = createRpcProxy("PropertiesApi", "scheduleTransferProperty", {
  "urlPrefix": "/rpc"
});