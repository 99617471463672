export const transformUnit = (unit: string) => {
    switch (unit) {
        case 'hour':
            return 'hours';
        case 'day':
            return 'days';
        case 'week':
            return 'weeks';
        case 'month':
            return 'months';
        case 'year':
            return 'year';
        default:
            return '';
    }
};
