import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import Close from './Close/Close';
import { useSpring, animated } from 'react-spring';
import MoreIcon from '../Icon/thin-more.svg';
import { useSliderMenu } from './context/SliderMenuContext';

const MenuWrapper = styled.div(() => ({
    backgroundColor: 'white',
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 99,
    height: '100vh',

    '& a': {
        color: 'black',
        textDecoration: 'none',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '90%',
        'a:first-of-type': {
            padding: `71px 0px 0px 21px`,
        },
    },
}));

const OpenMenuButton = styled.button({
    background: 'none',
    border: 'none',
    marginRight: '5 rem',
    cursor: 'pointer',
    gridArea: 'slider',
    placeSelf: 'end',
});

const Slider = styled.div({
    width: '20px',
});

const AnimatedMenu = animated(MenuWrapper);

type Props = {
    Links?: JSX.Element;
};

const SliderMenu: FC<Props> = ({ Links }) => {
    const { ToggleIsSettingsOpen, isSettingsOpen } = useSliderMenu();
    const expand = useSpring({
        config: { friction: 20, clamp: !isSettingsOpen },
        width: isSettingsOpen ? `40%` : '0%',
        fontWeight: '300',
    });

    return (
        <>
            <OpenMenuButton onClick={ToggleIsSettingsOpen}>
                <Slider>
                    <MoreIcon />
                </Slider>
            </OpenMenuButton>
            <AnimatedMenu style={expand}>
                <Close inverted left={21} top={20} onClose={ToggleIsSettingsOpen} />
                {Links}
            </AnimatedMenu>
        </>
    );
};

export default SliderMenu;
