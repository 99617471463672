import React from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';
import styled from '@emotion/styled';
import Close from '../Icon/close.svg';

const DialogContent = styled(RadixDialog.Content)(({ theme }) => ({
    width: '500px',
    padding: '1rem',
    borderRadius: '5px',
    backgroundColor: theme.colors.background,
    position: 'relative',
}));

const Overlay = styled(RadixDialog.Overlay)({
    background: 'rgba(0 0 0 / 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'grid',
    placeItems: 'center',
    overflowY: 'auto',
});

const CloseButton = styled.button({
    border: 'none',
    backgroundColor: 'inherit',
    position: 'absolute',
    top: '5px',
    right: '5px',
    cursor: 'pointer',
});

type DialogType = RadixDialog.DialogProps & {
    content?: RadixDialog.DialogContentProps & React.RefAttributes<HTMLDivElement>;
    children?: React.ReactNode;
};

const Dialog = ({ children, content, ...rest }: DialogType) => {
    return (
        <RadixDialog.Root {...rest}>
            <RadixDialog.Portal>
                <Overlay>
                    <DialogContent {...content}>
                        {children}
                        <RadixDialog.Close asChild>
                            <CloseButton aria-label="Close">
                                <Close />
                            </CloseButton>
                        </RadixDialog.Close>
                    </DialogContent>
                </Overlay>
            </RadixDialog.Portal>
        </RadixDialog.Root>
    );
};

export default Dialog;
