import ProjectsApi from '@app/api/public/ProjectsApi';
import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { data } from '../areas/maintenances/components/BoaGraph';
import { L } from '../lib/i18n';
import { getErrorMessage } from '../utils/getErrorMessage';

const projectInfoQueryKey = ['getProjectInfo'];

const useGetProjectInfo = () => {
    return useQuery(projectInfoQueryKey, ProjectsApi.getbyid);
};

const updateEmail = async (contact_email: string) => {
    const response = await ProjectsApi.updateEmail(contact_email);
    if (response.data) return data;
    throw new Error(response.message);
};
const useUpdateProjectEmail = () => {
    const queryClient = useQueryClient();
    return useMutation(updateEmail, {
        onSuccess: () => {
            toast.success(L('email_updated'));
        },
        onError: (error) => {
            toast.error(L(getErrorMessage(error)));
        },
        onSettled: () => {
            queryClient.invalidateQueries(projectInfoQueryKey);
        },
    });
};

export { useGetProjectInfo as default, useUpdateProjectEmail };
