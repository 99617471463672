import React from 'react';
import DocumentsApi from '@app/api/public/DocumentsApi';
import { useDropzone } from 'react-dropzone';
import DocumentsArea from '../../../components/document/DocumentsArea';
import FileDropZone from '../../../components/FileDropZone/FileDropZone';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import styled from '../../../components/lib/styled';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';

import toast from 'react-hot-toast';
import { servicePartnersQueryKeys, useGetServicePartnerDocumentsById } from '../hooks/servicePartnerQueries';
import useSaveServicePartnerDocument from '../hooks/useSaveServicePartnerDocument';

const StyledCardWithDivider = styled(CardWithDivider)({
    width: '100%',
});

const ServicePartnerDocumentsArea = ({ id }: { id: string }) => {
    const { data: documents } = useGetServicePartnerDocumentsById(id);
    const { mutate: saveDocument } = useSaveServicePartnerDocument();

    const { getInputProps, getRootProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            acceptedFiles.map((file) =>
                saveDocument({
                    file,
                    name: file.name,
                    itemId: id,
                })
            );
        },
        onDropRejected(fileRejections, event) {
            fileRejections.forEach((fileRejection) => {
                fileRejection.errors.forEach((error) => {
                    const errorCode = error.code.replace(/-/g, '_');
                    toast.error(`${fileRejection.file.name} ${L(errorCode)}`);
                });
            });
        },
        maxSize: 100000000,
    });

    return (
        <StyledCardWithDivider
            st
            topArea={<CardTitle>{L('document')}</CardTitle>}
            mainArea={
                <>
                    <FileDropZone getInputProps={getInputProps} getRootProps={getRootProps} />
                    <DocumentsArea
                        documentQueryKey={servicePartnersQueryKeys.documentsPartnerId(id)}
                        documents={documents?.map((document) => ({
                            id: document.id,
                            originalId: document.document_data.original.id,
                            filename: document.document_data.original.metadata.filename,
                            name: document.name,
                        }))}
                        removeDocument={(id) => {
                            return DocumentsApi.deleteDocument(id);
                        }}
                        renameDocument={(id, newName) => {
                            return DocumentsApi.updateDocumentName(id, newName);
                        }}
                    />
                </>
            }
        />
    );
};

export default ServicePartnerDocumentsArea;
