import { EventParticipant } from '@app/api/models/ContentEvent/ContentEventModels';
import BoardContentEventApi from '@app/api/public/BoardContentEventApi';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import { colors } from '../../../../components/Shared/Style/colors';
import { margin } from '../../../../components/Shared/Style/margin';
import StyledSwitch from '../../../../components/StyledSwitch';
import Table from '../../../../components/Table/ReactTable/FilterTable';
import CardTitle from '../../../../components/typography/CardTitle';
import { L } from '../../../../lib/i18n';
import { StyleColumn } from '../../../../types/ColumnType';
import { memberEventQueryKeys, useGetEventParticipantsById, useGetMemberEventById } from '../hooks/boardEventQueries';
import { formatDateIsoDayjs } from '@app/shared/lib/formatting';

const Attendees = styled.div({
    display: 'flex',
    gap: '1rem',
});

const Span = styled.span({
    textAlign: 'left',
});

const StyledP = styled.p({
    color: colors.black80,
    marginTop: margin.s,
    fontSize: '12px',
});

const FlexWrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const EventAttendeesArea: FC<React.HTMLAttributes<HTMLDivElement>> = ({ ...rest }) => {
    const { id } = useParams<{ id: string }>();
    const { data: participants } = useGetEventParticipantsById(+id);
    const { data: event } = useGetMemberEventById(+id);
    const queryClient = useQueryClient();

    const participantCount = participants?.reduce((sum, participant) => sum + participant.count, 0) ?? 0;
    const participantAmount = participants?.length ?? 0;

    const eventColumns: StyleColumn<EventParticipant>[] = [
        {
            Header: L('email_and_name'),
            accessor: (originalRow) => {
                return (
                    <div>
                        <Span>{originalRow.user_email}</Span>
                        <StyledP>{originalRow.user_name}</StyledP>
                    </div>
                );
            },
        },
        {
            Header: L('amount'),
            accessor: (originalRow) => {
                return originalRow.count;
            },
            style: {
                justifyContent: 'end',
            },
            width: 70,
        },
        {
            Header: L('created'),
            accessor: (originalRow) => {
                return dayjs(originalRow.created_at).valueOf();
            },
            Cell: ({ value }: { value: number }) => {
                return formatDateIsoDayjs(value);
            },
        },
        {
            Header: L('comment'),
            accessor: (originalRow) => {
                return originalRow.comment;
            },
        },
    ];

    return (
        <CardWithDivider
            {...rest}
            topArea={
                <FlexWrapper>
                    <Attendees>
                        <CardTitle>
                            {L('signed')}: {participants && participantAmount}
                        </CardTitle>
                        <CardTitle>
                            {L('participants')}: {participantCount}
                        </CardTitle>
                    </Attendees>
                </FlexWrapper>
            }
            mainArea={
                <>
                    <Table data={participants || []} columns={eventColumns} />
                </>
            }
        />
    );
};

export default EventAttendeesArea;
