import { TicketMessageThread, TicketSettingType } from '@app/api/models/Tickets';
import TicketsApi, { TicketFilter } from '@app/api/public/TicketsApi';
import { useQuery, UseQueryOptions } from 'react-query';
import { useParams } from 'react-router';

type MessageType = TicketMessageThread['ticket_message_thread_type'];
export const ticketQueryKeys = {
    all: ['tickets'],
    list: (filter?: TicketFilter) =>
        filter ? [...ticketQueryKeys.all, 'list', filter] : [...ticketQueryKeys.all, 'list'],
    id: (id?: string) => [...ticketQueryKeys.all, id],
    spaces: (id?: string) => [...ticketQueryKeys.all, id],
    archive: (filter?: TicketFilter) =>
        filter ? [...ticketQueryKeys.all, 'archive', filter] : [...ticketQueryKeys.all, 'archive'],
    inventories: (id?: string) => [...ticketQueryKeys.all, id],
    messages: (messageType: MessageType, ticketId?: string) => [...ticketQueryKeys.all, messageType, ticketId],
    notes: (ticketId?: string) => [...ticketQueryKeys.all, 'notes', ticketId],
    log: (ticketId?: string) => [...ticketQueryKeys.all, 'log', ticketId],
    status: () => [...ticketQueryKeys.all, 'status'],
    priority: () => [...ticketQueryKeys.all, 'priority'],
};

const getById = (id?: string) => {
    if (!id) return;
    return TicketsApi.getbyid(id);
};

const useGetTicket = (id?: string) => {
    return useQuery(ticketQueryKeys.id(id), () => getById(id), {
        enabled: !!id,
    });
};

const getTicketArchive = (filter?: TicketFilter) => {
    return TicketsApi.getArchive(filter);
};

const useGetTicketArchive = (filter?: TicketFilter) => {
    return useQuery(ticketQueryKeys.archive(filter), () => getTicketArchive(filter));
};

const useGetTickets = (filter?: TicketFilter, keepPreviousData?: UseQueryOptions['keepPreviousData']) => {
    return useQuery(ticketQueryKeys.list(filter), () => TicketsApi.getList(filter), {
        keepPreviousData,
    });
};

const getSpacesForProperty = async (id?: string) => {
    if (!id) return;
    return TicketsApi.getSpacesForProperty(+id);
};

const useGetSpacesForProperty = (id?: string) => {
    return useQuery(ticketQueryKeys.spaces(id), () => getSpacesForProperty(id), {
        enabled: !!id,
    });
};

const getInventoriesForProperty = async (id?: string) => {
    if (!id) return;
    return TicketsApi.getInventories(id);
};

const useGetInventoriesForSpace = (id?: string) => {
    return useQuery(ticketQueryKeys.inventories(id), () => getInventoriesForProperty(id), {
        enabled: !!id,
    });
};

const getNotes = async (ticketId?: string) => {
    if (!ticketId) return;
    return TicketsApi.getNotes(ticketId);
};

const useGetTicketNotes = (ticketId?: string) => {
    return useQuery(ticketQueryKeys.notes(ticketId), () => getNotes(ticketId), {
        enabled: !!ticketId,
    });
};
const useGetTicketMessages = (messageType: MessageType = 'residence') => {
    const { id } = useParams<{ id: string }>();
    return useQuery(
        ticketQueryKeys.messages(messageType, id),
        () => {
            return TicketsApi.getMessages(+id, messageType);
        },
        {
            enabled: !!id,
        }
    );
};

const useGetTicketLog = (ticketId: string) => {
    return useQuery(ticketQueryKeys.log(ticketId), () => TicketsApi.getEvents(ticketId), {
        enabled: !!ticketId,
    });
};

const getSettingsByType = (type: TicketSettingType) => {
    return TicketsApi.getSettingsByType(type);
};

const useGetSettingsByType = (type: TicketSettingType) => {
    return useQuery(ticketQueryKeys[type](), () => getSettingsByType(type), {
        enabled: !!type,
    });
};

export {
    useGetTickets,
    useGetTicket,
    useGetSpacesForProperty,
    useGetInventoriesForSpace,
    useGetTicketArchive,
    useGetTicketMessages,
    useGetTicketNotes,
    useGetTicketLog,
    useGetSettingsByType,
};
