import { AddMember } from '@app/api/models/Members';
import Excel from 'exceljs';
import { useMutation } from 'react-query';

const getCellValue = (row: Excel.Row, cellIndex: number) => {
    const cell = row.getCell(cellIndex);

    return cell.text ? cell.text.toString() : '';
};

const getImportMembersList = async (file: File): Promise<AddMember[]> => {
    const wb = new Excel.Workbook();

    const content = await wb.xlsx.load(await file.arrayBuffer());
    const worksheet = content.worksheets[0];
    const rowStartIndex = 4;
    const numberOfRows = worksheet.rowCount - 3;

    const rows = worksheet.getRows(rowStartIndex, numberOfRows) ?? [];

    const members = rows
        .filter((row) => row.hasValues)
        .map((row): AddMember => {
            return {
                email: getCellValue(row, 1),
                name: getCellValue(row, 2),
            };
        });
    return members;
};

const useGetImportMembersList = () => {
    return useMutation((file: File) => getImportMembersList(file));
};

export default useGetImportMembersList;
