import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { Button } from '../../../../components/Buttons/Button';
import Input from '../../../../components/Forms/Input';
import Label from '../../../../components/Forms/Label';
import { useModal } from '../../../../components/Modal/Context/ModalContext';
import { style } from '../../../../components/Shared/Style';
import { colors } from '../../../../components/Shared/Style/colors';
import Spinner from '../../../../components/Spinner/Spinner';
import H1 from '../../../../components/typography/H1';
import { L } from '../../../../lib/i18n';
import useChangePropertyowner from '../../hooks/useChangePropertyOwner';
import * as EmailValidator from 'email-validator';
import { fontSize, lineHeight } from '../../../../components/Shared/Style/typography';
import { Icon } from '../../../../components/Icon/Icon';
import { propertiesQueryKeys } from '../../hooks/propertyQueries';

type TransferPropertyProps = {
    id: number;
    emails: string[];
    transferDate: string;
};

const ButtonWraper = styled.div({
    padding: '1rem 0px 0px 0px',
    display: 'flex',
    gap: '1rem',
    justifyContent: 'flex-end',
});

const ClearOwner = styled.div({
    fontSize: '20px',
    cursor: 'pointer',
});

const LabelWrapper = styled.div({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '8px',
    cursor: 'pointer',
    position: 'relative',
    minWidth: '95%',
});

const MailError = styled.span({
    position: 'absolute',
    right: 8,
    fontSize: fontSize.milli,
    color: colors.errors,
    lineHeight: lineHeight.normal,
    cursor: 'auto',
});

const Form = styled.form({
    width: '500px',
    placeSelf: 'center',
    backgroundColor: colors.white,
    padding: '2rem',
    borderRadius: style.roundCornerSize.small,
});

const TransferProperty = ({ id }) => {
    const { ToggleIsModalOpen } = useModal();
    const { mutate, status } = useChangePropertyowner();
    const queryClient = useQueryClient();
    const [errors, setErrors] = useState({ email: new Map(), date: dayjs().format('YYYY-MM-DD') });

    const [transferProperty, setTransferProperty] = useState<TransferPropertyProps>({
        id: id,
        emails: [''],
        transferDate: dayjs().format('YYYY-MM-DD'),
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTransferProperty({ ...transferProperty, [e.target.name]: e.target.value });
    };

    const addEmail = () => {
        setTransferProperty({ ...transferProperty });
        transferProperty.emails.push('');
    };

    const removeEmail = (index: number) => {
        setTransferProperty({ ...transferProperty });
        transferProperty.emails.splice(index, 1);
    };

    const handleEmails = (e: React.ChangeEvent<any>, index: number) => {
        setTransferProperty({ ...transferProperty });
        transferProperty.emails[index] = e.target.value;
        if (!EmailValidator.validate(transferProperty.emails[index])) errors.email.set(index, 'not_valid_mail');
        if (EmailValidator.validate(transferProperty.emails[index])) errors.email.delete(index);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (transferProperty.transferDate && transferProperty.emails.length > 0 && !errors.email.size && errors.date) {
            mutate(
                { ...transferProperty },
                {
                    onSuccess: (_data, variables) => {
                        toast(`✅ ${L('ongoing_property_transfer')} ${transferProperty.emails} `);
                        queryClient.invalidateQueries(propertiesQueryKeys.all);
                        ToggleIsModalOpen();
                    },
                }
            );
        }
    };

    return (
        <Form
            onClick={(e) => {
                e.stopPropagation();
            }}
            onSubmit={(e) => handleSubmit(e)}
        >
            <H1>{`${L('change_property_owner')}`}</H1>
            <Label title={L('transfer_date')} style={{ width: '95%' }}>
                <Input
                    type="date"
                    name="transferDate"
                    value={transferProperty.transferDate || ''}
                    onChange={(e) => handleChange(e)}
                />
            </Label>
            <Label title={L('new_onwer_transfer')}>
                {transferProperty.emails.map((email, index) => (
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                        <LabelWrapper>
                            <Input
                                required
                                placeholder={L('email')}
                                name="email"
                                value={email || ''}
                                onChange={(e) => handleEmails(e, index)}
                            />
                            {errors.email.get(index) && <MailError>{L(errors.email.get(index))}</MailError>}
                        </LabelWrapper>
                        <ClearOwner onClick={() => removeEmail(index)}>{index > 0 && <Icon.Cross />}</ClearOwner>
                    </div>
                ))}
                <Button type="button" onClick={addEmail}>
                    {L('add_more_members')}
                </Button>
            </Label>

            <ButtonWraper>
                <Button type="button" onClick={() => ToggleIsModalOpen()} variant="primary">
                    {L('cancel')}
                </Button>

                {status === 'idle' && (
                    <Button type={'submit'} variant="filledPrimary">
                        {L('save')}
                    </Button>
                )}
                {status === 'loading' && (
                    <Button variant="filledPrimary" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Spinner />
                    </Button>
                )}
                {status === 'error' && (
                    <Button type={'submit'} variant="filledPrimary">
                        {L('error')}
                    </Button>
                )}
                {status === 'success' && (
                    <Button type={'submit'} variant="filledPrimary">
                        {L('saved')}
                    </Button>
                )}
            </ButtonWraper>
        </Form>
    );
};

export default TransferProperty;
