import styled from '@emotion/styled';
import React, { useState } from 'react';
import { colors } from '../../../components/Shared/Style/colors';
import { L } from '../../../lib/i18n';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import TrashCan from '../../../components/Icon/themed/TrashCan';
import Edit from '../../../components/Icon/themed/Edit';
import { MoreVertical, Move } from 'react-feather';
import { CommunityMailFolderType, mail_messages } from '@app/api/models/Mail/MailModels';
import { useMutation, useQueryClient } from 'react-query';
import MailApi from '@app/api/public/MailApi';
import toast from 'react-hot-toast';
import StyledTrigger from './styled/dropdown/StyledTrigger';
import DropDownContent from './styled/dropdown/DropDownContent';
import StyledItem from './styled/dropdown/StyledItem';
import ContextItem from './styled/dropdown/ContextItem';
import ConfirmDeleteMailToast from './ConfirmDeleteMailToast';
import { getMailKeys } from '@app/shared/queryKeys/mail';
import StyledSubTrigger from './styled/dropdown/StyledSubTrigger';
import DropDownSubContent from './styled/dropdown/DropDownSubContent';
import GlobalFilter from '../../../components/Table/filterComponents/GlobalFilter';
import { Button } from '../../../components/Buttons/Button';
import DropDownCreatable from '../../../components/DropDownCreatable/DropDownCreatable';

const StyledArrow = styled(DropdownMenu.Arrow)({
    fill: colors.white,
});

const DeleteIcon = styled(TrashCan)({
    height: '1.3rem',
    width: '1.3rem',
});
const EditIcon = styled(Edit)({
    height: '1.3rem',
    width: '1.3rem',
});
const ListContainer = styled('div')({
    maxHeight: 300,
    overflowY: 'scroll',
});

const NoResults = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
});

const CreateContainer = styled('div')({
    width: '100%',
});

const DropdownButton = styled(MoreVertical)({
    [':hover']: {
        cursor: 'pointer',
        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
    },
});

const initial = { id: 0, name: 'inbox', editable: false, project_id: 0, role: 'received' };

export default function MailRowDropdown({
    mail,
    folder,
    folders,
    handleOnCreate,
}: {
    mail: mail_messages;
    folder: CommunityMailFolderType;
    folders: CommunityMailFolderType[];
    handleOnCreate: (mailId: number, folderName: string) => void;
}) {
    const queryClient = useQueryClient();
    const [filter, setFilter] = useState('');
    const updateFolder = useMutation(
        (options: { id: number; folder: string; folderId: number }) =>
            MailApi.updateMailFolder(options.id, options.folderId),
        {
            onSuccess: (data, options) => {
                queryClient.invalidateQueries(getMailKeys.lists());
                queryClient.invalidateQueries(getMailKeys.counts());
                toast.success(`${L('successfully_moved_to')} ${L(options.folder)}`);
            },
            onError: (err, options) => {
                toast.error(`${L('failed_to_move')} ${L(options.folder)}`);
            },
        }
    );
    const updateRead = useMutation((options: { id: number; read: boolean }) => MailApi.updateRead(options.id), {
        onSuccess: (data, options) => {
            queryClient.invalidateQueries(getMailKeys.lists());
            queryClient.invalidateQueries(getMailKeys.counts());
            toast.success(options.read ? L('successfully_marked_as_read') : L('successfully_marked_as_unread'));
        },
        onError: (err, options) => {
            toast.error(options.read ? L('failed_to_mark_as_read') : L('failed_to_mark_as_unread'));
        },
    });
    const setUnread = useMutation((options: { id: number }) => MailApi.setUnread(options.id), {
        onSuccess: () => {
            queryClient.invalidateQueries(getMailKeys.lists());
            queryClient.invalidateQueries(getMailKeys.counts());
            toast.success(L('successfully_marked_as_unread'));
        },
        onError: () => {
            toast.error(L('failed_to_mark_as_unread'));
        },
    });
    const removeMail = useMutation((options: { id: number }) => MailApi.removeMail(options.id), {
        onSuccess: () => {
            queryClient.invalidateQueries(getMailKeys.lists());
            queryClient.invalidateQueries(getMailKeys.counts());
            toast.success(L('successfully_removed_mail'));
        },
        onError: () => {
            toast.error(L('failed_to_remove_mail'));
        },
    });

    const moveItem = async (e: React.MouseEvent<HTMLDivElement>, newFolder: string, newFolderId: number) => {
        e.stopPropagation();
        e.preventDefault();
        await updateFolder.mutateAsync({ id: mail.id, folder: newFolder, folderId: newFolderId });
    };

    const removeItem = async (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();

        return ConfirmDeleteMailToast({
            continueFn: async () => await removeMail.mutateAsync({ id: mail.id }),
            continueButtonText: L('confirm'),
        });
    };

    const setRead = async (e: React.MouseEvent<HTMLDivElement>, read: boolean) => {
        e.stopPropagation();
        e.preventDefault();
        await updateRead.mutateAsync({ id: mail.id, read });
    };

    const handleSetUnread = async (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        await setUnread.mutateAsync({ id: mail.id });
    };

    //If it is the main basket we want to just go with the other folders, if not we want to manually add that one
    //Because it is not in the database at all. (it's not really a folder)
    const filteredFolders = (folder.id === 0 ? folders : [initial, ...folders]).filter((folder) =>
        filter.length < 3 ? true : (folder.editable ? folder.name : L(folder.name)).includes(filter)
    );

    return (
        <DropdownMenu.Root>
            <StyledTrigger asChild>
                <DropdownButton role="button" />
            </StyledTrigger>

            <DropdownMenu.Portal>
                <DropDownContent sideOffset={1}>
                    {folder.type === 'trash' && (
                        <StyledItem onClick={(e) => removeItem(e)}>
                            <ContextItem>
                                <DeleteIcon />
                                <span>{L('remove')}</span>
                            </ContextItem>
                        </StyledItem>
                    )}

                    <StyledItem
                        onClick={(e) => {
                            if (mail.read_at) {
                                handleSetUnread(e);
                                return;
                            }
                            setRead(e, !mail.read_at);
                        }}
                    >
                        <ContextItem>
                            <EditIcon />
                            <span>{mail.read_at ? L('mark_unread') : L('mark_read')}</span>
                        </ContextItem>
                    </StyledItem>

                    {(folder.type === 'received' || folder.type === 'trash') && (
                        <DropdownMenu.Sub>
                            <StyledSubTrigger>
                                <ContextItem>
                                    <Move />
                                    <span>{L('move_to')}</span>
                                </ContextItem>
                            </StyledSubTrigger>
                            <DropdownMenu.Portal>
                                <DropDownSubContent sideOffset={2} alignOffset={-5}>
                                    <ContextItem
                                        onClick={(e) => e.stopPropagation()}
                                        onKeyDown={(e) => e.stopPropagation()}
                                    >
                                        <GlobalFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            placeholder={L('search_folders')}
                                        />
                                    </ContextItem>
                                    <DropdownMenu.Separator />
                                    <ListContainer>
                                        {filteredFolders.map((folder) => (
                                            <StyledItem
                                                onClick={(e) => moveItem(e, folder.name, folder.id)}
                                                key={folder.id}
                                            >
                                                <ContextItem>
                                                    <span>{folder.editable ? folder.name : L(folder.name)}</span>
                                                </ContextItem>
                                            </StyledItem>
                                        ))}
                                        {filteredFolders.length === 0 && filter.length >= 3 ? (
                                            <NoResults onClick={(e) => e.stopPropagation()}>
                                                <p>
                                                    {L('no_results_on')} &quot;{filter}&quot;
                                                </p>
                                                <Button onClick={() => setFilter('')}>
                                                    {L('click_to_clear_search')}
                                                </Button>
                                            </NoResults>
                                        ) : (
                                            filteredFolders.length === 0 && <NoResults>{L('no_folders')}</NoResults>
                                        )}
                                    </ListContainer>

                                    <DropdownMenu.Separator />
                                    <ContextItem
                                        onClick={(e) => e.stopPropagation()}
                                        onKeyDown={(e) => e.stopPropagation()}
                                    >
                                        <CreateContainer>
                                            <DropDownCreatable
                                                placeholder={L('create_new_folder')}
                                                isClearable
                                                isDisabled={filteredFolders.length > 20}
                                                onCreateOption={(e) => handleOnCreate(mail.id, e)}
                                            />
                                        </CreateContainer>
                                    </ContextItem>
                                </DropDownSubContent>
                            </DropdownMenu.Portal>
                        </DropdownMenu.Sub>
                    )}

                    <StyledArrow />
                </DropDownContent>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
}
