import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import BigNavItem from '../../../components/BigTabItem/BigTabListItemNav';
import BigTabNav from '../../../components/BigTabItem/BigTabNav';
import PageTitle from '../../../components/typography/PageTitle';
import { L } from '../../../lib/i18n';
import { BookingDetailPage } from '../DetailPage/BookingDetailPage';
import useGetBookingResourceById from '../hooks/useGetBookingResourceById';
import { BookingDraftListPage } from './BookingDraftListPage';
import { BookingList } from './BookingList';
import { BookingListPage } from './BookingListPage';

const BookingPage = () => {
    const { id } = useParams<{ id: string }>();

    const { data: bookingResource } = useGetBookingResourceById(+id);
    return (
        <>
            <PageTitle>{L('bookings')}</PageTitle>
            <BigTabNav>
                <BigNavItem exact to={'/booking'}>
                    {L('all_published')}
                </BigNavItem>
                <BigNavItem exact to={'/booking/draft'}>
                    {L('draft')}
                </BigNavItem>
                <Switch>
                    <Route exact path={'/booking/draft'} />
                    <Route exact path={'/booking/new'} />
                    <Route exact path={'/booking/draft/new'} />
                    <Route path="/booking/:bookingId">
                        <div style={{ borderRight: 'solid 1px gray' }} />
                        <BigNavItem exact to={`/booking/${id}`}>
                            {L(`${bookingResource?.name}`)}
                        </BigNavItem>
                        <BigNavItem exact to={`/booking/${id}/detail`}>
                            {L('booking_resource_settings')}
                        </BigNavItem>
                    </Route>
                </Switch>
            </BigTabNav>
            <Switch>
                <Route exact path="/booking">
                    <BookingListPage />
                </Route>
                <Route exact path={'/booking/new'}>
                    <BookingListPage />
                </Route>
                <Route exact path={'/booking/draft'}>
                    <BookingDraftListPage />
                </Route>
                <Route exact path={'/booking/draft/new'}>
                    <BookingDraftListPage />
                </Route>
                <Route exact path="/booking/:bookingId/detail" component={BookingDetailPage} />
                <Route path="/booking/:bookingId" component={BookingList} />
            </Switch>
        </>
    );
};

export default BookingPage;
