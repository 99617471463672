import React from 'react';
import { Route } from 'react-router-dom';
import { ForgottenPasswordPage } from './ForgottenPasswordPage';
import { SetResetPasswordPage } from './SetResetPasswordPage';

export const routes = [
    <Route key="0" exact path="/forgottenpassword" component={ForgottenPasswordPage} />,
    <Route key="1" exact path="/confirmaccount" component={SetResetPasswordPage} />,
    <Route key="2" exact path="/resetpassword" component={SetResetPasswordPage} />,
];
