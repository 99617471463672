import { useMutation, useQueryClient } from 'react-query';
import customFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import BookingsApi from '@app/api/public/BookingsApi';
import { getBookingResourcesQueryKey } from './useGetBookingResources';
import toast from 'react-hot-toast';
import { L } from '../../../lib/i18n';

dayjs.extend(customFormat);

type Variables = {
    id: number;
};

const useRemoveBookingResource = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async (variables: Variables) => {
            const response = await BookingsApi.removeBookingResource(variables.id);

            if (response.status === 'error') {
                return Promise.reject({ id: variables.id, message: response.message });
            }

            return response?.data;
        },
        {
            onError: () => {
                toast.error(L('bookings_exist'));
            },
            onSuccess: (_data, _variables, toastId: string) => {
                toast.success(`${L('removing_resource_success')}`, {
                    id: toastId,
                });
            },
            onSettled: () => {
                queryClient.invalidateQueries(getBookingResourcesQueryKey.all);
            },
        }
    );
};

export default useRemoveBookingResource;
