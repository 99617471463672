import styled from '@emotion/styled';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

export default styled(DropdownMenu.Item)(({ theme }) => ({
    '&:focus-visible': {
        outline: `${theme.colors.primary50} auto 1px`,
    },
    [':hover']: {
        outline: `${theme.colors.primary50} auto 1px`,
    },
}));
