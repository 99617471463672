import { ListingMember } from '@app/api/public/MembersApi';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Button } from '../../../../components/Buttons/Button';
import { margin } from '../../../../components/Shared/Style/margin';
import CardTitle from '../../../../components/typography/CardTitle';
import { L } from '../../../../lib/i18n';
import useInviteCheckedMembers from '../../hooks/useInvitePropertyUsers';
import useRemoveCheckedMembers from '../../hooks/useRemovePropertyUsers';

const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: margin.s,
});

const LowerCaseSpan = styled.span({
    textTransform: 'lowercase',
});

const TopArea = ({
    selectedMembers,
    onSubmit,
    handleOpenModal,
}: {
    selectedMembers: ListingMember[];
    onSubmit: (value: boolean) => void;
    handleOpenModal: () => void;
}) => {
    const { id } = useParams<{ id: string }>();
    const { mutate: removeAllCheckedMembers } = useRemoveCheckedMembers();
    const { mutate: inviteAllCheckedMembers } = useInviteCheckedMembers(+id);

    const [confirmDelete, setConfirmDelete] = useState(true);

    const removeChecked = () => {
        removeAllCheckedMembers({ memberArray: selectedMembers });
        onSubmit(false);
        setConfirmDelete(true);
    };
    const inviteChecked = () => {
        inviteAllCheckedMembers({ memberArray: selectedMembers });
        onSubmit(false);
    };

    return (
        <Wrapper>
            <CardTitle>{L('all_property_members_description')}</CardTitle>
            <div style={{ display: 'flex' }}>
                {selectedMembers.length === 0 ? (
                    <>
                        <Button
                            style={{ marginRight: margin.s }}
                            disabled
                            variant="secondary"
                            onClick={() => inviteChecked()}
                        >
                            {L('invite_all_checked')}
                        </Button>
                        <Button
                            style={{ marginRight: margin.s }}
                            disabled
                            variant="secondary"
                            onClick={() => removeChecked()}
                        >
                            {L('delete')}
                        </Button>
                    </>
                ) : confirmDelete ? (
                    <>
                        <Button style={{ marginRight: margin.s }} variant="primary" onClick={() => inviteChecked()}>
                            {L('invite_all_checked')}
                        </Button>
                        <Button
                            style={{ marginRight: margin.s }}
                            onClick={() => setConfirmDelete(false)}
                            variant={'primary'}
                        >
                            {L('delete')}
                        </Button>
                    </>
                ) : (
                    <>
                        <Button style={{ marginRight: margin.s }} variant="primary" onClick={() => inviteChecked()}>
                            {L('invite_all_checked')}
                        </Button>
                        <Button style={{ marginRight: margin.s }} onClick={removeChecked} variant={'delete'}>
                            {L('confirm')}
                        </Button>
                    </>
                )}

                <Button onClick={() => handleOpenModal()}>
                    {L('new')} <LowerCaseSpan>{L('member')}</LowerCaseSpan>
                </Button>
            </div>
        </Wrapper>
    );
};

export default TopArea;
