Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["strings"] = createRpcProxy("I18nApi", "strings", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getProjectTypeStrings"] = createRpcProxy("I18nApi", "getProjectTypeStrings", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});