import styled from '@emotion/styled';
import React from 'react';
import { useTimeSlotCalendar } from '../context/useTimeSlots';
import TimeSlotCell from './cell/TimeSlotCell';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ScrollCell from './cell/ScrollCell';
import { Loading } from '../../../components/Loading/Loading';
import useGetBookingResourceSlots from '../hooks/useGetBookingResourceSlots';
import useGetBookingResourceById from '../hooks/useGetBookingResourceById';
import useGetBookingsByResourceId from '../hooks/useGetBookingsByResourceId';
import { ProfileDetail } from '@app/api/models/Profile';
import useGetUserBookings from '../hooks/useGetUserBookings';
import TimeSlotBookingHandler from './cell/TimeSlotBookingHandler';

dayjs.extend(customParseFormat);

const StyledTimeSlotGrid = styled.div(() => {
    return {
        padding: '1rem 0px',
        display: 'grid',
        width: '100%',
        gridTemplateColumns: 'repeat(1, 1fr)',
        gap: '12px',
    };
});

type Props = {
    selectedUser?: ProfileDetail;
    bookingId: number;
};

const TimeSlotGrid = ({ selectedUser, bookingId, ...rest }: Props) => {
    const { dayjsState, selectDate, selectedDate } = useTimeSlotCalendar();

    const { data: bookingSlots, status: bookingSlotsStatus } = useGetBookingResourceSlots(bookingId);
    const { data: userBookings } = useGetUserBookings({ userId: selectedUser?.id, resourceId: bookingId });
    const { data: bookingResource, status } = useGetBookingResourceById(bookingId);

    const { data: bookings, status: bookingsStatus } = useGetBookingsByResourceId(bookingId);

    if (bookingSlotsStatus === 'loading' || bookingsStatus === 'loading') return <Loading />;
    if (!bookingSlots) return <div>Kunde inte hämta boknings slots</div>;
    if (!bookings) return <div>Kunde inte hämta bokningar</div>;
    if (!bookingResource) return <div>Kunde inte hämta bokningsresurs</div>;

    const Slots = bookingSlots?.map((slot) => {
        const cellObj = { date: dayjsState.format('YYYY-MM-DD'), id: slot.id };
        const selected = selectedDate?.date === cellObj.date && +selectedDate.id === +cellObj.id ? 'selected' : '';
        const bookedByUser = userBookings?.find(
            (booking) => +booking.booking_slot_id === +cellObj.id && booking.booking_date === cellObj.date
        );

        const displayTime = `${slot.starttime} - ${slot.endtime}`;

        const fullBooked = bookings.find(
            (booking) => +booking.booking_slot_id === +cellObj.id && booking.booking_date === cellObj.date
        );

        // Prevent date defaulting to next day
        const slotdate = dayjs(
            `${cellObj.date} ${slot.endtime === '00:00' ? '23:59' : slot.endtime}`,
            'YYYY-MM-DD HH:mm'
        );

        const isAfter = dayjs().isAfter(slotdate);

        if ((!bookedByUser && fullBooked) || isAfter) {
            return (
                <ScrollCell key={slot.id} starttime={slot.starttime}>
                    <TimeSlotCell bookedSlot={fullBooked} time={displayTime} disabled={true}>
                        {displayTime}
                    </TimeSlotCell>
                </ScrollCell>
            );
        }

        if (selected && selectedUser) {
            return (
                <ScrollCell key={slot.id} starttime={slot.starttime}>
                    <TimeSlotBookingHandler
                        onClick={() => {
                            selectDate(cellObj);
                        }}
                        selectedUser={selectedUser}
                        cellId={slot.id}
                        isAfter={isAfter}
                        time={displayTime}
                        bookingId={bookingId}
                    />
                </ScrollCell>
            );
        }

        if (bookedByUser) {
            return (
                <ScrollCell key={slot.id} starttime={slot.starttime}>
                    <TimeSlotCell
                        onClick={() => {
                            selectDate(cellObj);
                        }}
                        bookedSlot={bookedByUser}
                        booked={!!bookedByUser}
                        time={displayTime}
                    />
                </ScrollCell>
            );
        }
        return (
            <ScrollCell key={slot.id} starttime={slot.starttime}>
                <TimeSlotCell
                    onClick={() => {
                        selectDate(cellObj);
                    }}
                    bookedSlot={bookedByUser}
                    booked={!!bookedByUser}
                    time={displayTime}
                />
            </ScrollCell>
        );
    });

    return (
        <StyledTimeSlotGrid {...rest}>
            {Slots}
            {/*  */}
            {/*  */}
        </StyledTimeSlotGrid>
    );
};

export default TimeSlotGrid;
