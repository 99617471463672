import styled from '@emotion/styled';
import React from 'react';
import { useParams } from 'react-router-dom';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import useGetProfile from '../../../hooks/useGetProfile';
import useGetProjectInfo from '../../../hooks/useGetProjectInfo';
import { L } from '../../../lib/i18n';
import useGetBoardMembers from '../../board-members/hooks/useGetBoardMembers';
import { useGetTicketLog, useGetSettingsByType, useGetTicket } from '../hooks/ticketQueries';
import whoisCreator from '../lib/whoIsCreator';
import LogItem from './LogItem';
import Message from './Message';

const LogWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
});

const Log = () => {
    const { id } = useParams<{ id: string }>();
    const { data: log } = useGetTicketLog(id);
    const { data } = useGetProjectInfo();
    const { data: profile } = useGetProfile();
    const { data: boardMembers } = useGetBoardMembers();
    const { data: priority } = useGetSettingsByType('priority');
    const { data: status } = useGetSettingsByType('status');
    const { data: ticket } = useGetTicket(id);
    const boardMembersIds = boardMembers?.map((member) => member.user_id) ?? [];
    const LogItems = log?.map((logItem) => {
        if (logItem.type === 'event') {
            const eventItem = logItem.data;
            return (
                <LogItem
                    key={eventItem.id + ' event'}
                    logItem={eventItem}
                    projectName={data?.name || ''}
                    statuses={status || []}
                    priorities={priority || []}
                    logCreator={whoisCreator(eventItem.created_by, profile?.id, boardMembersIds)}
                />
            );
        }
        if (logItem.type === 'message') {
            const message = logItem.data;
            return (
                <Message
                    key={message.id + ' message'}
                    message={message}
                    messageSender={whoisCreator(message.created_by, profile?.id, boardMembersIds)}
                />
            );
        }
    });

    return (
        <CardWithDivider
            topArea={
                <CardTitle>
                    #{data?.id} - {ticket?.ticket_no} {L('ticket_log')}
                </CardTitle>
            }
            mainArea={<LogWrapper>{LogItems}</LogWrapper>}
        />
    );
};

export default Log;
