import UsersApi from '@app/api/public/UsersApi';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { LoginStore } from '../areas/login/LoginStore';

const useLogout = () => {
    const history = useHistory();
    const client = useQueryClient();
    return useMutation(
        async () => {
            const response = await UsersApi.logout();
            return response;
        },
        {
            onSettled: () => {
                LoginStore.setLoginState('');
                client.clear();
                history.push('/');
            },
        }
    );
};

export default useLogout;
