import PropertiesApi from '@app/api/public/PropertiesApi';
import { useMutation, useQueryClient } from 'react-query';
import { propertiesQueryKeys } from './propertyQueries';

type Variables = {
    id: number;
    emails: string[];
    transferDate: string;
};

const sheduleTransferPropertyOwner = (variables: Variables) => {
    return PropertiesApi.scheduleTransferProperty(variables.id, variables.transferDate, variables.emails);
};

const useSaveScheduleTransferPropertyOwner = () => {
    const client = useQueryClient();
    return useMutation(
        [propertiesQueryKeys.lists()],
        (variables: Variables) => sheduleTransferPropertyOwner(variables),
        {}
    );
};

export default useSaveScheduleTransferPropertyOwner;
