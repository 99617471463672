import * as React from 'react';
import { useObserver } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { style } from '../../components/Shared/Style';
import { Button } from '../../components/Buttons/Button';
import { L } from '../../lib/i18n';
import { useState } from 'react';
import { ThemeType } from '@app/api/models/Theme';
import Logo from '../../components/Logo/Logo';
import Label from '../../components/Forms/Label';
import useSendPasswordResetInstructions from './hooks/useSendPasswordResetInstructions';
import styled from '@emotion/styled';

//#region css

const CenterGridDiv = styled.div({
    placeSelf: 'start center',
    width: '100%',
    [`@media screen and (min-width: 378px)`]: {
        width: '378px',
    },
    [`@media screen and (min-width: 500px)`]: {
        placeSelf: 'center',
    },
});

const Error = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});
const ErrorMessage = styled.strong({
    color: 'red',
});

const Card = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    boxShadow:'rgb(0 0 0 / 10%) 0px 1px 14px',
    padding: '50px 12px 40px 12px',
    boxSizing: 'border-box',
    borderRadius: style.roundCornerSize.medium,
    flexDirection: 'column',
    gap:'20px',
    width: '378px',
    height: '450px',
}));

const Sucess = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: style.margin.l,
});
const SuccessMessage = styled.strong({
    color: style.colors.primary,
});

const Background = styled.div(({theme}) => ({
    width: '100%',
    minHeight: '100vh',
    backgroundSize: 'cover',
    backgroundColor: theme.colors.background,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const LoginCard = styled.div({
    width: '90%',
});

const StyledNavLink = styled(NavLink)(({ theme }: { theme?: ThemeType }) => {
    return {
        textDecoration: 'none',
        color: theme?.colors?.primary || style.colors.primary,
        lineHeight: '32px',
        fontSize: style.fontSize.seta,
        fontWeight: style.fontWeight.bold,
    };
});

const StyledLogo = styled(Logo)({
    maxWidth: '300px',
    width: '100%',
    height: '100%',
    maxHeight: '80px',
    placeSelf: 'center',
    backgroundColor: 'inherit',
});

const TextField = styled.div({
    paddingBottom: style.margin.l,
});

const ButtonMenu = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: style.margin.s,
    gap:'5px',
    flexDirection: 'column',
    textAlign: 'center',
});

const LoginInput = styled.input(({ theme }) => ({
    width: '100%',
    background: theme.colors.inputBackground,
    border: `1px solid ${theme.colors.inputBorder}`,
    height: '42px',
    borderRadius: style.roundCornerSize.small,
    outline: 'none',
    padding: `${style.margin.s} ${style.margin.m}`,
    fontSize: style.fontSize.seta,
    paddingLeft: style.margin.s,
}));

//#endregion

export const ForgottenPasswordPage = () => {
    const [email, setEmail] = useState('');

    const { mutate: reset, status, error } = useSendPasswordResetInstructions();

    const sendPasswordResetInstructions = (e) => {
        e.preventDefault();
        reset(email);
    };

    return useObserver(() => {
        return (
            <form onSubmit={sendPasswordResetInstructions}>
            <Background>
                    <Card>
                        <StyledLogo />
                        
                            <LoginCard>
                                {status === 'error' && (
                                    <Error>
                                        <ErrorMessage>{L('reset_' + error?.message)}</ErrorMessage>
                                    </Error>
                                )}
                                {status === 'success' && (
                                    <Sucess>
                                        <SuccessMessage>{L('reset_password_reset')}</SuccessMessage>
                                    </Sucess>
                                )}
                                <Label title={L('email')}>
                                    <TextField>
                                        <LoginInput
                                            name={'email'}
                                            value={email}
                                            onChange={(v) => setEmail(v.target.value)}
                                        />
                                    </TextField>
                                </Label>

                                <ButtonMenu>
                                    <Button variant="selectedMenu" style={{ marginRight: '0px' }} type={'submit'}>
                                        {L('reset_password')}
                                    </Button>
                                    <StyledNavLink to="/">{L('cancel')}</StyledNavLink>
                                </ButtonMenu>
                            </LoginCard>
                        
                    </Card>
            </Background>
            </form>
        );
    });
};
