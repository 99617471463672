import styled from '@emotion/styled';
import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { colors } from '../Shared/Style/colors';

const Cal = styled(Calendar)(({ theme }) => ({
    '&.react-calendar': {
        fontFamily: 'DM sans',
    },
    '.react-calendar__tile--now': {
        background: 'transparent !important',
    },
    '.react-calendar__tile': {
        border: '2px solid transparent',
    },
    '.react-calendar__tile:enabled:hover': {
        background: 'transparent',
        border: `2px solid #33525E`,
        color: 'black',
    },
    '.react-calendar__tile--active': {
        background: 'transparent',
        border: `2px solid #33525E !important`,
        color: 'black',
    },
    '.react-calendar__tile:enabled:focus': {
        background: 'transparent',
        border: `2px solid #33525E`,
        color: 'black',
    },
    '.myBookings': {
        background: `${theme.colors.tabActive} !important`,
        color: 'white',
    },
    '.booked': {
        backgroundColor: `${theme.colors.black30} !important`,
        pointerEvents: 'none',
    },
    '.booked-filter': {
        backgroundColor: `${theme.colors.black30} !important`,
    },
    '.booked-saturday': {
        backgroundColor: `${theme.colors.black30} !important`,
        pointerEvents: 'none',
        color: 'black',
    },
    '.booked-saturday-filter': {
        backgroundColor: `${theme.colors.black30} !important`,
        color: 'black',
    },
    '.react-calendar__month-view__days__day--weekend.saturday': {
        color: 'black',
    },
    '.blocked': {
        background: `repeating-linear-gradient(
            45deg,
            ${theme.colors.black10},
            ${theme.colors.black10} 8px,
            ${theme.colors.black30} 8px,
            ${theme.colors.black30} 16px
            )`,
        pointerEvents: 'none',
    },
    '.react-calendar__tile:disabled': {
        color: colors.black50,
        background: 'none',
    },
}));

function StyledCalendar({ ...rest }) {
    return <Cal locale={'sv-SE'} {...rest} />;
}

export default StyledCalendar;
