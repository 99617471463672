import React, { useState } from 'react';
import { RawHtml } from './RawHtml';
import { L } from '../../lib/i18n';
import { style } from '../Shared/Style';
import SmallTabListItem from '../SmallTabListItem/SmallTabListItem';
import styled from '@emotion/styled';

const StyledSmallTabListItem = styled(SmallTabListItem)({
    margin: '0px',
    marginTop: style.margin.s,
    marginBottom: '1rem',
    paddingTop: '0px',
});

const Main = ({
    children,
    collapse = true,
    onClick,
}: {
    children: string;
    collapse?: boolean;
    onClick?: () => void;
}) => {
    const maxTextLegnth = 300;
    const descriptionText = children;
    const partialText =
        descriptionText.length > maxTextLegnth
            ? `${descriptionText.replace(/<img([\w\W]+?)>/gm, '').substr(0, maxTextLegnth)}... `
            : descriptionText;

    if (descriptionText.length < maxTextLegnth) {
        return (
            <>
                <RawHtml content={descriptionText} />
            </>
        );
    }

    return (
        <>
            {collapse ? <RawHtml content={partialText} /> : <RawHtml content={descriptionText} />}
            {collapse && (
                <StyledSmallTabListItem onClick={onClick} className="active">
                    {L('show_more')}
                </StyledSmallTabListItem>
            )}
        </>
    );
};

export default Main;
