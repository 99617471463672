import React from 'react';
import PageList from '../../components/Website/components/PageList';
import styled from '@emotion/styled';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AddPageView from '../../components/Website/components/AddPageView';
import EditPageItem from '../../components/Website/components/EditPageItem';
import Homepage from './page/Homepage';
import Contact from './page/Contact';
import { L } from '../../lib/i18n';
import OrderPublicSitePage from './settings/OrderPublicSitePage';
import ThemePage from './theme/ThemePage';
import SliderMenuProvider from '../../components/SliderMenu/context/SliderMenuContext';
import PageTitle from '../../components/typography/PageTitle';
import BigTabNav from '../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../components/BigTabItem/BigTabListItemNav';

const GridContainer = styled.div({
    display: 'grid',
    gridTemplateAreas: `
    "PageList Main"
    "ThemeFeatures ThemeFeatures"
    `,
    gap: '32px',
    gridTemplateColumns: '340px 1fr',
});
const Main = styled.main({
    maxWidth: '100%',
});

export const PublicSitePage = () => {
    // used to highlight :id
    const disableOnMatch = useRouteMatch([
        '/public-site/theme/:id',
        '/public-site/add',
        '/public-site/theme',
        '/public-site/order',
    ]);
    return (
        <SliderMenuProvider>
            <PageTitle>{L('public_site_header')}</PageTitle>
            <BigTabNav>
                <BigNavItem
                    to={'/public-site'}
                    isActive={() => {
                        if (disableOnMatch?.isExact) return false;
                        return true;
                    }}
                >
                    {L('all_pages')}
                </BigNavItem>
                <BigNavItem to={'/public-site/theme'}>{L('pick_theme')}</BigNavItem>
                <BigNavItem to={'/public-site/order'}>{L('order_site')}</BigNavItem>
                <BigNavItem to={'/public-site/add'}>{L('add_page')}</BigNavItem>
            </BigTabNav>
            <GridContainer>
                <PageList />
                <Main>
                    <Switch>
                        <Route exact path="/public-site">
                            <Homepage />
                        </Route>
                        <Route exact path="/public-site/contact-page">
                            <Contact />
                        </Route>
                        <Route exact path="/public-site/add">
                            <AddPageView />
                        </Route>
                        <Route exact path="/public-site/order">
                            <OrderPublicSitePage />
                        </Route>
                        <Route path="/public-site/theme">
                            <ThemePage />
                        </Route>
                        <Route exact path="/public-site/:id">
                            <EditPageItem />
                        </Route>
                    </Switch>
                </Main>
            </GridContainer>
        </SliderMenuProvider>
    );
};
