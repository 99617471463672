import React, { useState } from 'react';
import StyledDialog from './styled/StyledDialog';
import ButtonWrapper from './styled/ButtonWrapper';
import { L } from '../../../lib/i18n';
import { Button } from '../../../components/Buttons/Button';
import styled from 'styled-components';
import { useGetContacts } from '../hooks/contactQueries';
import ContactItem from './ContactItem';
import useQueryParams from '../../../hooks/useQueryParams';
import GlobalFilter from '../../../components/Table/filterComponents/GlobalFilter';
import { Loading } from '../../../components/Loading/Loading';
import { createPortal } from 'react-dom';

const DialogWrapper = styled.div({
    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0,0,0,0.1)',
    top: 0,
    left: 0,
});

const List = styled.ul({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    listStyle: 'none',
    maxHeight: '65vh',
    overflowY: 'scroll',
});

const SearchContainer = styled.div({
    display: 'flex',
    justifyContent: 'center',
    padding: '0.5rem',
});

export default function Contacts({
    close,
    show,
    save,
}: {
    close: () => void;
    save: (selected: { [key: string]: boolean }) => void;
    show: boolean;
}) {
    const [selected, setSelected] = useState<{ [key: string]: boolean }>({});
    const { getQueryString, updateQueryString } = useQueryParams();
    const searchValue = getQueryString('search', '');
    const toggleSelected = (id: string) => {
        setSelected((selected) => ({ ...selected, [id]: !selected[id] }));
    };
    const { data } = useGetContacts(searchValue);
    if (!show) return null;
    return createPortal(
        <DialogWrapper>
            <StyledDialog style={{ position: 'absolute', zIndex: 1000, display: 'flex' }}>
                <div style={{ width: '100%' }}>
                    <SearchContainer>
                        <GlobalFilter
                            filter={searchValue}
                            setFilter={(newSearch) => updateQueryString('search', String(newSearch))}
                            placeholder="Sök"
                        />
                    </SearchContainer>

                    <List>
                        {data?.map((contact) => (
                            <ContactItem
                                key={contact.id}
                                contact={contact}
                                selected={selected[contact.email]}
                                toggleSelected={() => toggleSelected(contact.email)}
                            />
                        ))}
                        {!data && <Loading />}
                        {data && data.length === 0 && <li>Inga träffar hittades</li>}
                    </List>
                    <ButtonWrapper>
                        <Button
                            type="button"
                            onClick={() => {
                                close();
                                setSelected({});
                            }}
                        >
                            {L('cancel')}
                        </Button>
                        <Button
                            variant={'filledPrimary'}
                            onClick={() => {
                                save(selected);
                                setSelected({});
                            }}
                        >
                            {L('save')}
                        </Button>
                    </ButtonWrapper>
                </div>
            </StyledDialog>
        </DialogWrapper>,
        document.body
    );
}
