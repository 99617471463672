import styled from '@emotion/styled';
import React from 'react';
import { colors } from '../Shared/Style/colors';

//

const ToolTipText = styled.span({
    position: 'absolute',
    display: 'block',
    visibility: 'hidden',
    width: 'max-content',

    backgroundColor: colors.black80,
    color: '#fff',
    textAlign: 'center',
    borderRadius: '6px',
    padding: '5px 10px',
    zIndex: '1',

    '&.right': {
        left: '110%',
    },
    '&.left': {
        right: '110%',
    },

    '&.right::after': {
        content: '" "',
        position: 'absolute',
        top: '50%',
        right: '100%',
        marginTop: '-5px',
        borderWidth: '5px',
        borderStyle: 'solid',
        borderColor: `transparent ${colors.black80} transparent transparent`,
    },
    '&.left::after': {
        content: '" "',
        position: 'absolute',
        top: '50%',
        left: '100%',
        marginTop: '-5px',
        borderWidth: '5px',
        borderStyle: 'solid',
        borderColor: `transparent transparent transparent ${colors.black80}`,
    },
});

const Container = styled.div({
    position: 'relative',
    fontSize: '0.8rem',
    [`&:hover .tooltip`]: {
        visibility: 'visible',
    },
});

type Props = {
    text: string;
    position?: 'left' | 'right';
};

const Tooltip: React.FC<Props> = ({ text, children, position = 'right' }) => {
    return (
        <Container>
            <ToolTipText className={`tooltip ${position}`}>{text}</ToolTipText>
            {children}
        </Container>
    );
};

export default Tooltip;
