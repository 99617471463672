import { useQuery } from 'react-query';
import MaintenancesApi from '@app/api/public/MaintenancesApi';

import { useParams } from 'react-router-dom';

const getMaintenanceDocuments = async (id: number) => {
    return await MaintenancesApi.listDocuments(id);
};

const useGetMaintenanceDocuments = () => {
    const { id } = useParams<{ id: string }>();
    return useQuery(['getDocumentsList', id], () => getMaintenanceDocuments(+id));
};

export default useGetMaintenanceDocuments;
