import React from 'react';
import styled from '@emotion/styled';
import { useTimeSlotCalendar } from '../context/useTimeSlots';
import ArrowRight from '../../../components/Icon/arrow-right.svg';
import { colors } from '../../../components/Shared/Style/colors';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import useGetBookingResourceById from '../hooks/useGetBookingResourceById';

const NavContainer = styled.div({
    display: 'grid',
    columnGap: '16px',
    gridTemplateColumns: 'min-content 1fr 1fr min-content',
    padding: '0px 8px',
    rowGap: '16px',
    height: '100%',
    width: '100%',
    gridTemplateAreas: `
    "decr date date incr"
    "decr date date   incr"
    `,
});

const NoButtonStyle = styled.button(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, 0)',
    border: 'none',
    cursor: 'pointer',
    alignSelf: 'start',
    '&:disabled': {
        cursor: 'default',
        path: {
            stroke: theme.colors.secondary,
        },
    },
}));

const StyledArrowRight = styled(ArrowRight)({
    height: '30px',
    width: '30px',
    //
    //
});
const StyledArrowLeft = styled(ArrowRight)(({ theme }) => ({
    height: '30px',
    width: '30px',
    transform: 'rotate(180deg)',
    '& path': {
        stroke: theme.colors.black,
    },

    //
    //
}));

const Date = styled.div({ gridArea: 'date', display: 'grid', justifyContent: 'center', position: 'relative' });
const DateSelect = styled.input({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    width: '100%',
    ['&::-webkit-calendar-picker-indicator']: {
        position: 'absolute',
        display: 'block',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
});

const Day = styled.span({
    placeSelf: 'center',
    fontSize: '1.2rem',
});

const DateWrapper = styled.h2({
    fontSize: '1.5rem',
    placeSelf: 'center',
    margin: '0px',
});
const TimeSlotNav = () => {
    const { bookingId } = useParams<{ bookingId: string }>();
    const { dayjsState, decrementDay, incrementDay, backButtonDisabled, forwardButtonDisabled, setDay } =
        useTimeSlotCalendar();
    const { data: bookingResource } = useGetBookingResourceById(+bookingId);

    return (
        <NavContainer>
            <NoButtonStyle disabled={backButtonDisabled} style={{ gridArea: 'decr' }} onClick={decrementDay}>
                <StyledArrowLeft />
            </NoButtonStyle>
            <Date>
                <DateSelect
                    max={
                        bookingResource?.booking_window?.quantity
                            ? dayjs()
                                  .add(bookingResource.booking_window.quantity, bookingResource.booking_window.unit)
                                  .format('YYYY-MM-DD')
                            : undefined
                    }
                    min={dayjs().format('YYYY-MM-DD')}
                    value={dayjsState.format('YYYY-MM-DD')}
                    type={'date'}
                    onChange={(e) => {
                        const value = e.target.value;
                        value === '' ? setDay(dayjs().format('YYYY-MM-DD')) : setDay(value);
                    }}
                />
                <DateWrapper>{dayjsState.format('D MMM, YYYY')}</DateWrapper>
                <Day>{dayjsState.format('dddd')}</Day>
            </Date>
            <NoButtonStyle disabled={forwardButtonDisabled} style={{ gridArea: 'incr' }} onClick={incrementDay}>
                <StyledArrowRight />
            </NoButtonStyle>
        </NavContainer>
    );
};

export default TimeSlotNav;
