import { Alert } from '@app/api/public/AlertsApi';
import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../../components/Shared/Style/colors';
import AlertCard from '../components/AlertCard';
import { useGetPropertyNames } from '../hooks/alertsQuery';

type Props = Alert & { elips?: boolean };

const StyledLink = styled(Link)({
    textDecoration: 'none',
    color: colors.black90,
});
const AlertCardHandler = (alert: Props) => {
    const { data: properties } = useGetPropertyNames(alert.id);
    const propertyNames = properties?.map((property) => property.name);
    return (
        <StyledLink to={`alerts/${alert.id}`}>
            <AlertCard propertyNames={propertyNames || []} {...alert} />
        </StyledLink>
    );
};

export default AlertCardHandler;
