import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const createIntervals = (start: string, end: string, interval: { intervalH: string }) => {
    const startValue = dayjs(start, 'HH:mm').unix();
    const endValue = end === '00:00' ? dayjs(end, 'HH:mm').add(1, 'day').unix() : dayjs(end, 'HH:mm').unix();
    const intervalValue = +interval.intervalH * 60 * 60;

    const diff = endValue - startValue;
    const remaining = diff % intervalValue;

    if (remaining !== 0) {
        throw new Error('intervallet går ej att skapa mellan valda tider');
    }

    let currentTime = dayjs(start, 'HH:mm');
    const intervals: { start: string; end: string }[] = [];

    while (currentTime.unix() < endValue) {
        intervals.push({
            start: dayjs(currentTime.valueOf()).format('HH:mm'),
            end: dayjs(currentTime.valueOf()).add(intervalValue, 's').format('HH:mm'),
        });
        currentTime = currentTime.add(intervalValue, 's');
    }

    return intervals;
};

export default createIntervals;
