import ThemeApi from '@app/api/public/ThemeApi';
import { useEffect } from 'react';
import { QueryClient, useQuery, useQueryClient } from 'react-query';
import { ThemeType } from '../../../../api/models/Theme';
import { LoginStore } from '../../../areas/login/LoginStore';

export const setQueryThemeCache = (queryClient: QueryClient, theme: ThemeType) => {
    queryClient.setQueryData('theme', theme);
};

const getTheme = () => {
    return ThemeApi.getThemeBySuburl();
};

const useGetTheme = () => {
    const queryClient = useQueryClient();
    useEffect(() => {
        queryClient.refetchQueries('theme', { active: true });
    }, [LoginStore.user]);
    const { data, isLoading } = useQuery('theme', () => getTheme());

    return { data, isLoading };
};

export default useGetTheme;
