import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { HTMLAttributes } from 'react';
import { colors } from '../Shared/Style/colors';

const MoreNavButton = styled.button({
    color: colors.textColor2,
    border: 'none',
    backgroundColor: 'inherit',
    textDecoration: 'none',
    padding: '10px 16px',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: colors.background,
    },
});

type DropDownProps = {
    children: ReactNode;
} & HTMLAttributes<HTMLButtonElement>;

const HorizontalDotsButton = ({ children, ...rest }: DropDownProps) => {
    return <MoreNavButton {...rest}>{children}</MoreNavButton>;
};

export default HorizontalDotsButton;
