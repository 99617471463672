import * as React from 'react';
import { L } from '../../../lib/i18n';
import { Loading } from '../../../components/Loading/Loading';
import styled from '@emotion/styled';
import { Button } from '../../../components/Buttons/Button';
import useGetSettingsDocumentGroups, { documentGroupsQueryKeys } from './hooks/useGetSettingsDocumentGroups';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import ContentInformationApi from '@app/api/public/ContentInformationApi';
import DocumentGroup from './DocumentGroup';
import { DndContext, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import useDragEndDnd from '../../../hooks/useDragEndDnd';

//#region CSS

const StyledDialog = styled.dialog(({ theme }) => ({
    padding: '22px 32px 22px 32px',
    backgroundColor: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.divider}`,
    boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.1)',
    maxWidth: '600px',
    width: '100%',
    overflow: 'visible',
}));

const Wrapper = styled.div({
    display: 'flex',
    gap: '0.5rem',
    justifyContent: 'end',
    marginTop: '1rem',
});

//#endregion

export const SettingsDocumentgroups = () => {
    const queryClient = useQueryClient();

    const { mutate: saveNewDocumentGroupMutation } = useMutation(
        (documentGroupname: string) =>
            ContentInformationApi.saveDocuementGroup({
                name: documentGroupname,
            }),
        {
            onMutate: (name) => {
                const toastId = toast.loading(`${L('saving')} ${name}`);
                return toastId;
            },
            onError: (_error, name, toastId: string) => {
                toast.error(`${L('save_error')}`, {
                    id: toastId,
                });
            },
            onSuccess: (_data, name, toastId: string) => {
                toast.success(`${name} ${L('saved')}`, {
                    id: toastId,
                });
            },

            onSettled: () => {
                queryClient.invalidateQueries(documentGroupsQueryKeys.all);
            },
        }
    );

    const { data: documentGroups } = useGetSettingsDocumentGroups();

    const [groupName, setGroupName] = useState('');

    const dialogRef = React.useRef<HTMLDialogElement>(null);
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!groupName) return;
        saveNewDocumentGroupMutation(groupName, {
            onSuccess: () => {
                dialogRef.current?.close();
                setGroupName('');
            },
        });
    };

    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

    const handleDragEnd = useDragEndDnd({
        queryKey: documentGroupsQueryKeys.sortableById(),
        updateSortOrder: ContentInformationApi.setDocumentGroupOrder,
        reverseArray: true,
    });

    if (!documentGroups) {
        return <Loading />;
    }

    return (
        <>
            <StyledDialog ref={dialogRef}>
                <form onSubmit={onSubmit}>
                    <Label title={L('document_group_name')}>
                        <Input value={groupName} onChange={(e) => setGroupName(e.target.value)} />
                    </Label>
                    <Wrapper>
                        <Button
                            type={'button'}
                            onClick={() => {
                                dialogRef.current?.close();
                                setGroupName('');
                            }}
                        >
                            {L('cancel')}
                        </Button>
                        <Button disabled={!groupName} variant="filledPrimary">
                            {L('save')}
                        </Button>
                    </Wrapper>
                </form>
            </StyledDialog>
            <CardWithDivider
                topArea={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <CardTitle>{L('doocument_groups')}</CardTitle>
                        <Button onClick={() => dialogRef.current?.showModal()}>{L('create_document_group')}</Button>
                    </div>
                }
                mainArea={
                    <>
                        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} css>
                            <SortableContext
                                items={documentGroups.map((group) => group.id)}
                                strategy={rectSortingStrategy}
                            >
                                {documentGroups.map((documentGroup) => {
                                    return (
                                        <DocumentGroup
                                            key={documentGroup.id}
                                            groupId={documentGroup.id}
                                            name={documentGroup.name}
                                            documentGroupQueryKey={documentGroupsQueryKeys.all}
                                        />
                                    );
                                })}
                            </SortableContext>
                        </DndContext>
                    </>
                }
            />
        </>
    );
};
