import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button } from '../../../components/Buttons/Button';
import StatusSettingItem from './TypeSettingItem';
import ColorPicker from '../../maintenances/components/ColorPicker';
import useSaveType from '../hooks/useSaveType';
import Input from '../../../components/Forms/Input';
import { useGetTypes } from '../hooks/useContract';
import { L } from '../../../lib/i18n';

const Container = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    flexDirection: 'column',
    width: '100%',
});
const Form = styled.form({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
});
const H1 = styled.h1({
    fontSize: '2rem',
    fontWeight: 100,
});
const StyledInput = styled(Input)({
    marginBottom: '1rem',
});
const AddButton = styled(Button)({
    marginTop: '0.5rem',
});
const ItemContainer = styled.div({
    marginTop: '3rem',
    maxWidth: '100vw',
    width: '100%',
});

const TypeSettings = ({ initial }: { initial?: string }) => {
    const [name, setName] = useState(initial);
    const [color, setColor] = useState('');
    const { data: settingsList } = useGetTypes();
    const { mutate: save } = useSaveType();

    return (
        <Container>
            <H1>{L('settings_for_type')}</H1>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    save({ name, color_code: color });
                    setName('');
                }}
            >
                <StyledInput
                    required={true}
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    placeholder={L('contract_type_placeholder')}
                />
                <ColorPicker getValue={(value) => setColor(value)} />
                <AddButton type="submit" variant="selectedMenu">
                    {L('add')}
                </AddButton>
            </Form>
            <ItemContainer>
                {settingsList?.map((item) => {
                    return <StatusSettingItem key={item.id} item={item} />;
                })}
            </ItemContainer>
        </Container>
    );
};

export default TypeSettings;
