import styled from '@emotion/styled';
import { ThemeProvider } from 'emotion-theming';
import React, { useState } from 'react';
import { Route, Switch, Link, NavLink } from 'react-router-dom';
import { ColorsType } from '../../../../../../community-site/node_modules/@app/web/lib/colors/colors';
import { Button } from '../../../components/Buttons/Button';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardWithDividerTopArea from '../../../components/Layout/CardWithDividerTopArea';
import { colors } from '../../../components/Shared/Style/colors';
import ContactPreview from '../../../components/Website/components/Preview/ContactPreview';
import ContentPreview from '../../../components/Website/components/Preview/ContentPreview';
import FontWrapper from '../../../components/Website/components/Preview/FontWrapper';
import HomePreview from '../../../components/Website/components/Preview/HomePreview';
import ThemeFeatures from '../../../components/Website/components/ThemeFeatures';
import useGetPageListItems from '../../../components/Website/hooks/useGetPageListItems';
import { L } from '../../../lib/i18n';
import CreateThemePage from './CreateThemePage';
import useThemeProviderState from './hooks/useThemeProviderState';

export type PreviewThemeType = {
    colors: ColorsType;
    // fonts: FontsType;
};

const Box = styled.div({
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '4px',
    marginBottom: '2rem',
});

const ButtonWarapper = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px',
});
const StyledNavLink = styled(NavLink)({
    textDecoration: 'none',
    margin: '1rem',
    cursor: 'pointer',
    color: colors.black,
    '&.active': {
        color: `${colors.primary}`,
    },
});

const LinkWrapper = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingBottom: '5rem',
});
const ThemePage = () => {
    const [createTheme, setCreateTheme] = useState(false);
    const { theme, selectTheme } = useThemeProviderState();
    const { data: pageListItems } = useGetPageListItems();

    return (
        <CardWithDivider
            topArea={
                <CardWithDividerTopArea title="Här kan ni ändra utesendet på hemsidan">
                    <ButtonWarapper>
                        <Button
                            variant={createTheme ? 'primary' : 'filledPrimary'}
                            onClick={() => setCreateTheme(false)}
                        >
                            {L('themes')}
                        </Button>
                        <Button
                            variant={createTheme ? 'filledPrimary' : 'primary'}
                            onClick={() => setCreateTheme(true)}
                        >
                            {L('create_theme')}
                        </Button>
                    </ButtonWarapper>
                </CardWithDividerTopArea>
            }
            mainArea={
                <>
                    {createTheme ? <CreateThemePage /> : <ThemeFeatures selectThemePreview={selectTheme} />}
                    <Box>
                        <LinkWrapper>
                            <StyledNavLink exact to={`/public-site/theme`}>
                                {L('homepage')}
                            </StyledNavLink>
                            {pageListItems &&
                                pageListItems.map((pageListItem) => (
                                    <React.Fragment key={pageListItem.id}>
                                        <StyledNavLink exact to={`/public-site/theme/${pageListItem.id}`}>
                                            {pageListItem.title}
                                        </StyledNavLink>
                                    </React.Fragment>
                                ))}
                            <StyledNavLink exact to={`/public-site/theme/contact`}>
                                {L('contact')}
                            </StyledNavLink>
                        </LinkWrapper>
                        <ThemeProvider theme={theme}>
                            <FontWrapper>
                                <Switch>
                                    <Route exact path={'/public-site/theme/'}>
                                        <HomePreview />
                                    </Route>
                                    <Route exact path={'/public-site/theme/contact'}>
                                        <ContactPreview />
                                    </Route>
                                    <Route exact path={'/public-site/theme/:id'}>
                                        <ContentPreview />
                                    </Route>
                                </Switch>
                            </FontWrapper>
                        </ThemeProvider>
                    </Box>
                </>
            }
        />
    );
};

export default ThemePage;
