import { DocumentsSearchQuery } from '@app/api/models/Documents';

export const folderKeys = {
    all: ['folder'] as const,
    lists: () => [...folderKeys.all, 'list'] as const,
    list: (parentId?: number) => [...folderKeys.all, 'list', { parentId: parentId ?? null }] as const,
    details: () => [...folderKeys.all, 'detail'] as const,
    detail: (id: number) => [...folderKeys.details(), { id }] as const,
};

export const fileKeys = {
    all: ['file'] as const,
    lists: () => [...fileKeys.all, 'list'] as const,
    list: (folderId?: number, searchQuery?: DocumentsSearchQuery) =>
        [...fileKeys.all, 'list', { folderId: folderId ?? null, searchQuery }] as const,
    details: () => [...fileKeys.all, 'detail'] as const,
    detail: (id: number | string) => [...fileKeys.details(), { id }] as const,
};
