import React, { HTMLAttributes } from 'react';
import { DropzoneState } from 'react-dropzone';
import styled from 'styled-components';
import { colors } from '../Shared/Style/colors';
import { roundCornerSize } from '../Shared/Style/box';
import UploadSvg from '../Icon/dragAndDropIcon.svg';
import { L } from '../../lib/i18n';

const StyledDropZone = styled.div({
    backgroundColor: colors.white,
    borderRadius: roundCornerSize.small,
    display: 'grid',
    placeItems: 'center',
    cursor: 'pointer',
    border: `1px dashed ${colors.inputBorder}`,
    padding: '2rem 1rem',
    '&[aria-disabled=true]': {
        cursor: 'default',
        opacity: 0.5,
    },
});

const GridContainer = styled.div({
    display: 'grid',
    placeItems: 'center',
    height: '100%',
    width: '100%',
});
const StyledUploadIcon = styled.div({
    width: 'max-content',
    display: 'flex',
});

const UploadContainer = styled.div({
    display: 'grid',
    gap: '0.5rem',
    position: 'relative',
    placeItems: 'center',
    width: '80%',
});

const UploadText = styled.p({
    margin: '0px',
    color: colors.black,
    textOverflow: 'ellipsis',
    fontSize: '1rem',
    '& span': {
        color: colors.primary,
    },
});

const UploadIcon = () => {
    return (
        <StyledUploadIcon>
            <UploadSvg />
        </StyledUploadIcon>
    );
};

const UploadInfo = ({ className, children, ...rest }: HTMLAttributes<HTMLDivElement>) => {
    return (
        <GridContainer className={'hover_info ' + (className || '')} {...rest}>
            <UploadContainer>
                <UploadIcon />
                <UploadText>{L('dropzone_description')}</UploadText>
                {children}
            </UploadContainer>
        </GridContainer>
    );
};

export type Props = Pick<DropzoneState, 'getInputProps' | 'getRootProps'> &
    HTMLAttributes<HTMLDivElement> & { disabled?: boolean };

const FileDropZone = ({ getInputProps, getRootProps, disabled }: Props) => {
    return (
        <StyledDropZone aria-disabled={disabled} {...getRootProps()}>
            <input {...getInputProps()} readOnly={disabled} />
            <UploadInfo>{`${L('max_file_size')} 30 MB`}</UploadInfo>
        </StyledDropZone>
    );
};

export default FileDropZone;
