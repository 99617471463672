import React from 'react';
import styled from '@emotion/styled';
import { navigation, previewNavigations } from '../../PrivateRoutes';
import { MainMenuItem } from './MainMenuItem';
import { L } from '../../lib/i18n';
import { style } from '../Shared/Style';
import NavAcordion from './components/NavAcordion';
import * as blog from '../../areas/blog/navigation';
import { useRouteMatch } from 'react-router';
import useGetPreviewPagesSettings from '../../hooks/useGetPreviewPages';
import useGetProjectInfo from '../../hooks/useGetProjectInfo';

const Container = styled.div(() => {
    return {
        display: 'grid',
        gap: '10px',
        marginLeft: style.margin.xs,
        paddingTop: style.margin.m,
    };
});

type Navigation = {
    name: string;
    link: string;
    icon?: string | undefined;
    parent?: string | undefined;
}[];
const useHandleMenu = (navigation: Navigation) => {
    const { data } = useGetProjectInfo();

    if (data) document.title = data.name;

    const boardmembersMenu = navigation.filter((item) => item.parent === 'BoardMembers');

    const membersMenu = navigation.filter((item) => item.parent === 'Members');

    const items = navigation
        .filter((item) => !item.parent)
        .map((item) => {
            return <MainMenuItem key={item.name} item={{ ...item, name: L(item.name) }} />;
        });

    const boardSubItems = boardmembersMenu.map((subItem) => {
        if (subItem.name === 'boardmembers') {
            return (
                <MainMenuItem
                    key={subItem.name}
                    item={{
                        ...subItem,
                        name: data ? L('board_members') : L(subItem.name),
                    }}
                />
            );
        }
        if (subItem.name === 'project') {
            return (
                <MainMenuItem
                    key={subItem.name}
                    item={{ ...subItem, name: data ? L('project_type') : L(subItem.name) }}
                />
            );
        }
        return <MainMenuItem key={subItem.name} item={{ ...subItem, name: L(subItem.name) }} />;
    });

    const memberSubMenu = membersMenu.map((subItem) => {
        return <MainMenuItem key={subItem.name} item={{ ...subItem, name: L(subItem.name) }} />;
    });
    return {
        boardmembersMenu,
        membersMenu,
        boardSubItems,
        memberSubMenu,
        items,
    };
};

export const MenuData = () => {
    const { data: previewSettings } = useGetPreviewPagesSettings();
    const enabledPreviewNavigations = previewNavigations.filter((nav) => previewSettings?.includes(nav.name));

    const handleMenu = useHandleMenu([...navigation, ...enabledPreviewNavigations]);
    const { boardSubItems, boardmembersMenu, memberSubMenu, membersMenu, items } = handleMenu;

    const membersMatch = useRouteMatch(membersMenu.map((item) => item.link));
    const boardMatch = useRouteMatch(boardmembersMenu.map((item) => item.link));

    return (
        <Container>
            <MainMenuItem item={{ ...blog.navigation, name: L(blog.navigation.name) }} />
            <NavAcordion match={boardMatch} icon={'brfAdmin'} title={L('board')}>
                {boardSubItems}
            </NavAcordion>
            <NavAcordion match={membersMatch} icon={'members'} title={L('member_menu_section')}>
                {memberSubMenu}
            </NavAcordion>
            {items}
        </Container>
    );
};
