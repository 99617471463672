import React, { FormEvent, useState } from 'react';
import styled from '@emotion/styled';
import { Button } from '../../../components/Buttons/Button';
import { L } from '../../../lib/i18n';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { roundCornerSize } from '../../../components/Shared/Style/box';
import { colors } from '../../../components/Shared/Style/colors';
import CardTitle from '../../../components/typography/CardTitle';
import BoxColorPicker from '../../../components/BoxColorPicker/BoxColorPicker';
import { useGetSettingsByType } from '../hooks/ticketQueries';
import { TicketSettingType } from '@app/api/models/Tickets';
import useSaveSettingByType from '../hooks/useSaveSettingByType';
import SettingItem from './SettingItem';

const ModalWrapper = styled(CardWithDivider)({
    maxWidth: '500px',
    maxHeight: '80vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: colors.background,
    borderRadius: roundCornerSize.medium,
});
const Form = styled.form({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
});

const ItemContainer = styled.div({
    display: 'grid',
    gap: '1rem',
});

const GridWrapper = styled.div({
    width: '100%',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'min-content auto min-content',
    gap: '1rem',
});

const StyledLabel = styled(Label)({
    width: '100%',
});
const SettingsByType = ({ initialValue, type }: { initialValue?: string; type: TicketSettingType }) => {
    const [name, setName] = useState(initialValue || '');
    const [color, setColor] = useState('');
    const { data: settingsList } = useGetSettingsByType(type);
    const { mutate: save } = useSaveSettingByType();

    const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        save({
            setting: {
                name,
                color_code: color,
            },
            type,
        });
        setName('');
    };

    return (
        <ModalWrapper
            onClick={(e) => e.stopPropagation()}
            topArea={<CardTitle>{`Inställningar för ${L(type)}`}</CardTitle>}
            mainArea={
                <>
                    <Form onSubmit={handleSave}>
                        <StyledLabel title={L(`create_new_${type}`)}>
                            <GridWrapper>
                                <BoxColorPicker getValue={(value) => setColor(value)} />
                                <Input
                                    required={true}
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    placeholder={L(`label_${type}`)}
                                />
                                <Button type="submit" variant="selectedMenu">
                                    {L(`create`)}
                                </Button>
                            </GridWrapper>
                        </StyledLabel>
                    </Form>

                    <Label title={L(`all_${type}`)} />
                    <ItemContainer>
                        {settingsList?.map((item) => {
                            return <SettingItem key={item.id} item={item} type={type} />;
                        })}
                    </ItemContainer>
                </>
            }
        />
    );
};

export default SettingsByType;
