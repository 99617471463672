import { BookingFilter } from '@app/api/models/Bookings';
import BookingsApi from '@app/api/public/BookingsApi';
import { useQuery } from 'react-query';
const useGetBookingsCsvQueryKey = 'useGetBookingsCsv';

const useGetBookingsCsv = (id: string, filter?: BookingFilter) => {
    return useQuery(
        [useGetBookingsCsvQueryKey, id, filter],
        () => {
            return BookingsApi.bookingsCsv(+id, filter);
        },
        { keepPreviousData: true }
    );
};

export default useGetBookingsCsv;
