Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["getTheme"] = createRpcProxy("ThemeApi", "getTheme", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getThemeById"] = createRpcProxy("ThemeApi", "getThemeById", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getThemeBySuburl"] = createRpcProxy("ThemeApi", "getThemeBySuburl", {
  "urlPrefix": "/rpc"
});
o["getUserAccoutTheme"] = createRpcProxy("ThemeApi", "getUserAccoutTheme", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getCompanies"] = createRpcProxy("ThemeApi", "getCompanies", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["saveTheme"] = createRpcProxy("ThemeApi", "saveTheme", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});