import React from 'react';
import { Switch, Route } from 'react-router-dom';
import BigNavItem from '../../components/BigTabItem/BigTabListItemNav';
import BigTabNav from '../../components/BigTabItem/BigTabNav';
import PageTitle from '../../components/typography/PageTitle';
import { L } from '../../lib/i18n';
import EditAlertPage from './EditAlert/EditAlertPage';
import { AlertListPage } from './ListPage/AlertListPage';
import NewAlert from './NewAlert/NewAlert';

const AlertPage = () => {
    return (
        <>
            <PageTitle>{L('alert')}</PageTitle>
            <BigTabNav>
                <BigNavItem exact to="/alerts">
                    {L('all_alerts')}
                </BigNavItem>
            </BigTabNav>

            <Switch>
                <Route exact path={'/alerts'}>
                    <AlertListPage />
                </Route>
                <Route exact path={'/alerts/new'}>
                    <NewAlert />
                </Route>
                <Route exact path={'/alerts/:id'}>
                    <EditAlertPage />
                </Route>
            </Switch>
        </>
    );
};

export default AlertPage;
