import React, { useState } from 'react';
import { Maintenance } from '@app/api/public/MaintenancesApi';
import { Loading } from '../../../components/Loading/Loading';
import useGenerateMaintenanceIntevalItems from '../hooks/useGenerateMaintenanceIntevalItems';
import useValidIntervalEndDates from '../hooks/useValidIntervalEndDate';
import useGetSettingsListByType from '../hooks/useGetSettingsListByType';
import useSaveMultipleMaintance from '../hooks/useSaveMultipleMaintance';
import { useHistory } from 'react-router-dom';
import AddMaintenanceForm from '../components/AddMaintenanceForm';
import MaintenancePreview from '../components/MaintenancePreview';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import { useGetServicePartners } from '../../service-partner/hooks/servicePartnerQueries';

const AddMaintenacePage = () => {
    const [view, setView] = useState<'edit' | 'preview'>('edit');
    const history = useHistory();
    const { data: categoryList, status: categoryStatus } = useGetSettingsListByType('category');
    const { data: statusList, status: statusListStatus } = useGetSettingsListByType('status');
    const { data: typeList, status: typeStatus } = useGetSettingsListByType('type');

    const [maintenanceItem, setMaintenanceItem] = useState<Partial<Maintenance>>();
    const { mutate: save, status } = useSaveMultipleMaintance();

    const validIntervals = useValidIntervalEndDates(maintenanceItem?.planned_date, maintenanceItem?.interval);
    const [intervalEndDate, setIntervalEndDate] = useState<string | undefined>();
    const maintenanceIntevalItems = useGenerateMaintenanceIntevalItems(
        maintenanceItem,
        intervalEndDate,
        validIntervals
    );
    const { data: servicePartners } = useGetServicePartners();

    if (statusListStatus === 'loading') return <Loading />;
    if (categoryStatus === 'loading') return <Loading />;
    if (typeStatus === 'loading') return <Loading />;

    const handleChange = (value: Partial<Maintenance>) => {
        setMaintenanceItem((prev) => {
            return { ...prev, ...value };
        });
    };

    const handleSave = () => {
        save(
            {
                maintenaces: [{ ...maintenanceItem }, ...maintenanceIntevalItems].map((maintenanceItem) => {
                    return {
                        ...maintenanceItem,
                        planned_date: maintenanceItem?.planned_date ? maintenanceItem.planned_date : undefined,
                        completed_date: maintenanceItem?.completed_date ? maintenanceItem.completed_date : undefined,
                    };
                }),
            },
            { onSuccess: () => history.push(`/maintenance/`) }
        );
    };

    const formProps = {
        categoryList,
        handleChange,
        maintenanceItem,
        setView,
        statusList,
        typeList,
        servicePartners,
    };

    const previewProps = {
        handleChange,
        intervalEndDate,
        maintenanceItem,
        setIntervalEndDate,
        validIntervals,
        setView,
        handleSave,
        status,
        maintenanceIntevalItems,
    };

    return (
        <CardWithDivider
            topArea={<CardTitle>{L('create_maintenance')}</CardTitle>}
            mainArea={
                <>
                    {view === 'edit' && <AddMaintenanceForm {...formProps} />}
                    {view === 'preview' && <MaintenancePreview {...previewProps} />}
                </>
            }
        />
    );
};

export default AddMaintenacePage;
