import React from 'react';
import { PropertiesPage } from './Page/PropertiesPage';
import { Route } from 'react-router-dom';

export const routes = [
    <Route key="1" path="/properties/:id" component={PropertiesPage} />,
    <Route key="0" exact path="/properties" component={PropertiesPage} />,
];

export const navigation = { name: 'properties', link: '/properties', parent: 'BoardMembers' };
