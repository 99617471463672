import { PublicSiteInformationItem } from '@app/api/models/PublicSite';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { colors } from '../../../components/Shared/Style/colors';
import { L } from '../../../lib/i18n';
import VerticalMoreIcon from '../../../components/Icon/vertical-dots.svg';

const StyledNavItem = styled.li({});
const StyledNavLink = styled(NavLink)({
    textDecoration: 'none',
    color: 'black',
    padding: '10px 20px',
    borderLeft: '6px solid',
    borderColor: 'gray',
    width: '100%',
    marginBottom: '1rem',
    backgroundColor: 'white',
    cursor: 'pointer',
    borderRadius: '5px',
    listStyle: 'none',
    boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.1)',
    display: 'block',
    '&.published': {
        borderColor: '#29CC97',
    },
    '&.active': {
        boxShadow: `0 0 3px ${colors.primary}`,
    },
});

const SmallInfo = styled.div({
    fontSize: '0.8rem',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
});

const IconWrapper = styled.div({
    width: '1rem',
});

const FlexWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
});

const Wrapper = styled.div({
    marginLeft: '1rem',
});

type Props = {
    publicSiteInformationItem: Pick<
        PublicSiteInformationItem,
        | 'description'
        | 'id'
        | 'title'
        | 'updated_at'
        | 'sort'
        | 'created_by'
        | 'page_type'
        | 'published_at'
        | 'created_by_name'
    >;
    innerRef: (element?: HTMLElement | null | undefined) => any;
} & React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>;
const NavItem = ({ publicSiteInformationItem, innerRef, ...rest }: Props) => {
    return (
        <StyledNavItem
            onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
            ref={innerRef}
            {...rest}
        >
            <StyledNavLink
                to={`/public-site/${publicSiteInformationItem.id}`}
                className={publicSiteInformationItem.published_at ? 'published' : ''}
            >
                <FlexWrapper>
                    <IconWrapper>
                        <VerticalMoreIcon />
                    </IconWrapper>
                    <Wrapper>
                        <h1>{publicSiteInformationItem.title}</h1>
                        <SmallInfo>
                            <span>
                                {L('created_by')} <b>{publicSiteInformationItem.created_by_name}</b>
                            </span>
                            <span>
                                {L('last_edited')}{' '}
                                <b>{dayjs(publicSiteInformationItem.updated_at).format('YYYY-MM-D')}</b>
                            </span>
                        </SmallInfo>
                    </Wrapper>
                </FlexWrapper>
            </StyledNavLink>
        </StyledNavItem>
    );
};

export default NavItem;
