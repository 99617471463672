import styled from '@emotion/styled';
import React from 'react';
import { Insets, Stylable } from './types';

const Div = styled.div<Insets & { position: any; inverted?: boolean }>(
    ({ top, right, bottom, left, position, inverted }) => ({
        top,
        right,
        bottom,
        left: left,
        position: position ? position : 'absolute',
        width: 32,
        height: 32,
        backgroundColor: inverted ? 'none' : 'rgba(0, 0, 0, 0.25)',
        color: inverted ? 'black' : 'white',
        borderRadius: '50%',
        fontSize: '2.25em',
        textAlign: 'center',
        pointerEvents: 'all',
        cursor: 'pointer',
        zIndex: 10,
    })
);

const Inner = styled.span(() => ({
    lineHeight: 0,
    // verticalAlign: 7,
    fontSize: '2.5rem',
}));

type Props = Insets & {
    onClose: () => void;
    position?: any;
    className?: string;
    inverted?: boolean;
};

const Close: React.FC<Props & Stylable> = ({ className, position, inverted, onClose, ...pos }) => {
    return (
        <Div inverted={inverted} className={className} position={position} onClick={onClose} {...pos}>
            <Inner>&times;</Inner>
        </Div>
    );
};

export default Close;
