import MembersApi from '@app/api/public/MembersApi';
import { useQuery } from 'react-query';

export const getMemberListQueryKey = 'getMemberList';

const getMemberList = () => {
    return MembersApi.list();
};

const useGetMemberList = () => {
    return useQuery([getMemberListQueryKey], () => getMemberList());
};

const getMemberById = (id: string) => {
    return MembersApi.getMember(+id);
};

const useGetMemberById = (id: string) => {
    return useQuery([getMemberListQueryKey, id], () => getMemberById(id), {
        enabled: !!id,
    });
};

export { useGetMemberById, useGetMemberList as default };
