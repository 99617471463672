import React, { TouchEvent } from 'react';
import { DndContext, closestCenter, useSensor, useSensors, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { Grid } from './components/Grid';
import { SortablePhoto } from './components/SortablePhoto';
import { Loading } from '../../Loading/Loading';
import ImageDropZoneNoText from '../../ImageDropZone/ImageDropZoneNoText';
import type { MouseEvent, KeyboardEvent } from 'react';
import {
    MouseSensor as LibMouseSensor,
    KeyboardSensor as LibKeyboardSensor,
    TouchSensor as LibTouchSensor,
} from '@dnd-kit/core';

function shouldHandleEvent(element: HTMLElement | null) {
    let cur = element;

    while (cur) {
        if (cur.dataset && cur.dataset.noDnd) {
            return false;
        }
        cur = cur.parentElement;
    }

    return true;
}

export class MouseSensor extends LibMouseSensor {
    static activators = [
        {
            eventName: 'onMouseDown' as const,
            handler: ({ nativeEvent: event }: MouseEvent) => {
                return shouldHandleEvent(event.target as HTMLElement);
            },
        },
    ];
}

export class TouchSensor extends LibTouchSensor {
    static activators = [
        {
            eventName: 'onTouchStart' as const,
            handler: ({ nativeEvent: event }: TouchEvent) => {
                return shouldHandleEvent(event.target as HTMLElement);
            },
        },
    ];
}

export class KeyboardSensor extends LibKeyboardSensor {
    static activators = [
        {
            eventName: 'onKeyDown' as const,
            handler: ({ nativeEvent: event }: KeyboardEvent<Element>) => {
                return shouldHandleEvent(event.target as HTMLElement);
            },
        },
    ];
}

type Props = {
    images: { url: string; id: number }[];
    onDragEnd: (event: DragEndEvent) => void;
    onUpload?: (files: File[]) => void;
    remove?: (value: number) => void;
};

const DraggableSortGrid = ({ images, onDragEnd, onUpload, remove }: Props) => {
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

    if (!images) return <Loading />;

    return (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd} css>
            <SortableContext items={images.map((r) => r.id)} strategy={rectSortingStrategy}>
                <Grid>
                    {images.map(({ url, id }, index) => (
                        <SortablePhoto remove={remove} id={id} key={url} url={url} index={index} />
                    ))}
                    {onUpload ? <ImageDropZoneNoText onDrop={onUpload} /> : null}
                </Grid>
            </SortableContext>
        </DndContext>
    );
};

export default DraggableSortGrid;
