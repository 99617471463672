import { AddMember } from '@app/api/models/Members';
import React, { useContext, useEffect } from 'react';
import { TableToggleRowsSelectedProps } from 'react-table';
import Spinner from '../../../components/Spinner/Spinner';
import { useSaveImportMembersList } from '../hooks/useSaveImportMember';
import { MembersContext } from './ImportMembersPage';
import CheckIcon from '../../../components/Icon/check-single.svg';
import styled from '@emotion/styled';
import { L } from '../../../lib/i18n';
import { colors } from '../../../components/Shared/Style/colors';

const StyledCheckIcon = styled(CheckIcon)({
    height: '1rem',
    width: '1rem',
});

type Props = {
    member: AddMember;
} & Partial<TableToggleRowsSelectedProps>;

const UploadMember = ({ member, checked }: Props) => {
    const { upload, setUpload } = useContext(MembersContext);
    const { status, mutate: saveImportedMember, error } = useSaveImportMembersList();

    const errorMessage = error instanceof Error ? error.message : '';

    useEffect(() => {
        if (upload && checked && member.name) {
            saveImportedMember([member], { onSettled: () => setUpload(false) });
        }
    }, [upload]);

    if (!member.name) {
        return <span style={{ color: colors.statusError }}>{L('wont_be_uploaded')}</span>;
    }
    return (
        <div>
            {status === 'loading' && <Spinner />}
            {status === 'error' && errorMessage}
            {status === 'success' && <StyledCheckIcon />}
        </div>
    );
};

export default UploadMember;
