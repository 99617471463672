import { BookingResource } from '@app/api/models/Bookings';
import BookingsApi from '@app/api/public/BookingsApi';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { L } from '../../../lib/i18n';
import { getBookingResourcesQueryKey } from './useGetBookingResources';

type Variables = {
    resource: BookingResource;
    file?: File;
};
const useUpdateBookingResource = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (variables: Variables) => {
            return BookingsApi.saveResourece(variables.resource, variables.file);
        },
        {
            onSuccess: () => {
                toast.success(L('booking_updated'));
            },
            onSettled: (_data, _error, variables) => {
                queryClient.invalidateQueries(getBookingResourcesQueryKey.all);
                queryClient.invalidateQueries(getBookingResourcesQueryKey.id(variables.resource.id));
            },
        }
    );
};

export default useUpdateBookingResource;
