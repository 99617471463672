import React, { useRef, useState } from 'react';
import { FolderDetails } from '@app/api/models/Folders';
import { Icon } from '@ourliving/ourliving-ui';
import { StyledListItem, ListItemIconWrapper, ListItemText, ListItemBackground } from './ListItem';
import { Link } from 'react-router-dom-v5-compat';
import styled from '@emotion/styled';
import { L } from '../../../../lib/i18n';
import { ListItemGridColumns } from './ItemLink';

const ListItemLink = styled(Link)({
    gap: '1rem',
    cursor: 'pointer',
    width: '100%',
    display: 'grid',
    ...ListItemGridColumns,
    height: '3rem',
    borderRadius: '10px',
    padding: '0 0.3rem',
    zIndex: 2,
    position: 'relative',

    '*': {
        pointerEvents: 'all',
    },
});

type ListItemProps = {
    folderPath: string;
    targetItem: ({ id, type }: Partial<FolderDetails>) => void;
    searchMode?: boolean;
};

const ToParentListItem: React.FC<ListItemProps> = ({ folderPath, targetItem, searchMode }) => {
    const itemRef = useRef<HTMLLIElement>(null);
    const [draggedOver, setDraggedOver] = useState(false);

    if (!folderPath) return <></>;

    const path = folderPath.split('/');
    path.pop();
    let parentId: undefined | string = path.pop();
    if (isNaN(Number(parentId))) parentId = undefined;

    let linkPath: string | string[] = folderPath.split('/');
    linkPath.pop();
    linkPath = linkPath.join('/');

    const handleDrop = () => {
        if (!itemRef.current) return;
        if (searchMode) {
            targetItem({
                id: undefined,
                type: 'folder',
            });
        } else {
            targetItem({
                id: parentId === undefined ? undefined : Number(parentId),
                type: 'folder',
            });
        }
    };

    return (
        <StyledListItem
            draggable="false"
            onDrop={() => {
                handleDrop();
                setDraggedOver(false);
            }}
            ref={itemRef}
        >
            <ListItemBackground
                draggable={'false'}
                className="list-item-background"
                draggedOver={draggedOver}
                type="folder"
            />

            <ListItemLink
                onDragEnter={() => setDraggedOver(true)}
                onDragLeave={() => setDraggedOver(false)}
                draggable="false"
                to={'/document' + linkPath}
            >
                <ListItemIconWrapper
                    style={{
                        // align properly with other listItems
                        gridColumn: '2/3',
                    }}
                    onDragOver={(e) => e.preventDefault()}
                >
                    {searchMode ? <Icon.Home width={24} height={24} /> : <Icon.Folder width={24} height={24} />}
                </ListItemIconWrapper>

                <ListItemText
                    style={{
                        // to span over all columns after first
                        gridColumn: '3/-1',
                    }}
                >
                    {searchMode ? L('home') : <>../</>}
                </ListItemText>
            </ListItemLink>
        </StyledListItem>
    );
};

export default ToParentListItem;
