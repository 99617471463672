import React, { FC } from 'react';
import FileDocx from '../../../Icon/file-docx.svg';
import FileXlsx from '../../../Icon/file-xlsx.svg';
import FilePdf from '../../../Icon/file-pdf.svg';
import FileHtml from '../../../Icon/file-html.svg';
import FileTxt from '../../../Icon/file-txt.svg';
import FileSvg from '../../../Icon/file-svg.svg';
import FileImg from '../../../Icon/polaroid-photo.svg';
import File from '../../../Icon/file.svg';
import styled from '@emotion/styled';

type Props = {
    fileType?: string;
};

const StyledDocX = styled(FileDocx)(() => ({
    width: '20px',
    height: '20px',
}));
const StyledXlsx = styled(FileXlsx)(() => ({
    width: '20px',
    height: '20px',
}));
const StyledPdf = styled(FilePdf)(() => ({
    width: '20px',
    height: '20px',
}));
const StyledHtml = styled(FileHtml)(() => ({
    width: '20px',
    height: '20px',
}));
const StyledTxt = styled(FileTxt)(() => ({
    width: '20px',
    height: '20px',
}));
const StyledSvg = styled(FileSvg)(() => ({
    width: '20px',
    height: '20px',
}));
const StyledImg = styled(FileImg)(() => ({
    width: '20px',
    height: '20px',
}));
const StyledFile = styled(File)(() => ({
    width: '20px',
    height: '20px',
}));

export const FileTypeIcon: FC<Props> = ({ fileType }) => {
    switch (fileType) {
        case 'doc' || 'docx':
            return <StyledDocX />;
        case 'xls' || 'xlsx':
            return <StyledXlsx />;
        case 'pdf':
            return <StyledPdf />;
        case 'htm' || 'html':
            return <StyledHtml />;
        case 'txt':
            return <StyledTxt />;
        case 'svg':
            return <StyledSvg />;
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'PNG':
        case 'webp':
            return <StyledImg />;
        default:
            return <StyledFile />;
    }
};
