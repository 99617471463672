import React, { ComponentPropsWithoutRef, FC, forwardRef, HTMLAttributes, HTMLProps } from 'react';
import { style } from '../Shared/Style';
import styled from '@emotion/styled';

const StyledInputField = styled.input(({ theme }) => ({
    color: `${theme.colors.inputColor}`,
    border: `1px solid ${theme.colors.inputBorder}`,
    width: '100%',
    height: '32px',
    backgroundColor: theme.colors.inputBackground,
    borderRadius: style.roundCornerSize.small,
    outline: 'none',
    padding: '3px 10px 2px 10px',
    fontSize: '80%',
    '&::placeholder': {
        color: `${theme.colors.inputPlaceholderColor}`,
        fontSize: '0.8rem',
    },
    '&:read-only': {
        color: `${theme.colors.inputPlaceholderColor}`,
        cursor: 'default',
        backgroundColor: theme.colors.inputDisabled,
    },
}));

const Input = forwardRef<HTMLInputElement, ComponentPropsWithoutRef<'input'>>(({ ...rest }, ref) => {
    return <StyledInputField ref={ref} {...rest} />;
});

export default Input;
