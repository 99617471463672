import React, { HTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timeZones from 'dayjs/plugin/timezone';
import { fontSize } from '@app/web/components/Shared/Style/typography';
import { colors } from '@app/web/components/Shared/Style/colors';
import utc from 'dayjs/plugin/utc';
import { RawHtml } from '../Layout/RawHtml';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(timeZones);
dayjs.tz.guess();

dayjs.updateLocale('sv', {
    relativeTime: {
        future: 'om %s',
        past: '%s sedan',
        s: '< 1 min',
        m: 'minut',
        mm: '%d minuter',
        h: 'en timme',
        hh: '%d timmar',
        d: 'en dag',
        dd: '%d dagar',
        M: 'en månad',
        MM: '%d månader',
        y: 'ett år',
        yy: '%d år',
    },
});

const Time = styled.time({
    fontSize: fontSize.seta,
    color: colors.black80,
    textAlign: 'right',
    display: 'block',
    padding: '0.5rem',
});

type TimestampProps = {
    date: dayjs.ConfigType;
};

const formatFeedDate = (date: dayjs.ConfigType) => {
    if (!dayjs(date).isValid()) return '';
    if (dayjs(date).isSame(dayjs(), 'day')) {
        return dayjs(date).tz().locale('sv').fromNow();
    }
    if (dayjs(date).isSame(dayjs(), 'year')) {
        return dayjs(date).tz().format('D MMMM');
    }
    return dayjs(date).tz().format('D MMMM YYYY');
};

const CardTimestamp: React.FC<
    TimestampProps & React.ClassAttributes<HTMLElement> & React.TimeHTMLAttributes<HTMLElement>
> = ({ date, ...rest }) => {
    return <Time {...rest}>{formatFeedDate(date)}</Time>;
};

const Grid = styled.div({
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fill, minmax(300px, 1fr) )',
    gap: '2rem',
});

type Props = HTMLAttributes<HTMLUListElement> & { children: React.ReactNode };

const CardGrid = ({ children }: Props) => {
    return <Grid>{children}</Grid>;
};

// Card
const Card = styled.section({
    overflow: 'hidden',
    boxShadow: '0px 1px 6px rgb(0 0 0 / 15%)',
    borderRadius: style.margin.s,
    aspectRatio: '3 / 4',
    height: '100%',
    paddingBottom: '0.5rem',
});
// CardImage
const CardImage = styled.div<{ url: string }>(({ url, theme }) => ({
    backgroundImage: `url(${url})`,
    width: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '40%',
    backgroundColor: theme.colors.white,
}));
// CardTitle
const CardGridTitle = styled.h2(({ theme }) => ({
    fontSize: '1rem',
    color: theme.colors.black,
    margin: 0,
    padding: '0px 0.5rem 0.5rem 0.5rem',
}));

const CardDivider = styled.div(({ theme }) => ({
    height: '1px',
    backgroundColor: theme.colors.divider,
}));

const CardDescription = styled.p({
    maxHeight: '100%',
    margin: '0.5rem',
    textOverflow: 'ellipsis',
    overflowWrap: 'anywhere',
    whiteSpace: 'pre-wrap',
    color: colors.black80,
    fontSize: fontSize.seta,
});

const StyledParsedHtml = styled.div({
    padding: '0.5rem',
});

const CardParsedHtml = ({ html, ...rest }: { html: string } & HTMLAttributes<HTMLDivElement>) => {
    return (
        <StyledParsedHtml {...rest}>
            <RawHtml content={html} />
        </StyledParsedHtml>
    );
};

// CardDescription
// CardDivider

export { CardGrid, Card, CardImage, CardGridTitle, CardTimestamp, CardDivider, CardDescription, CardParsedHtml };
