import ProjectsApi, { Address } from '@app/api/public/ProjectsApi';
import { useMutation, useQueryClient } from 'react-query';

const useSavePublicAdress = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (variables: Address) => {
            console.log(variables);
            return ProjectsApi.savePublicAddress(variables);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries('getProjectInfo');
            },
        }
    );
};

export default useSavePublicAdress;
