import React from 'react';
import PageTitle from '../../components/typography/PageTitle';
import { L } from '../../lib/i18n';
import Feed from '../../components/Feed/Feed';
import BlogApi from '@app/api/public/BlogApi';
import { BlogItem as BlogItemType } from '@app/api/models/Blog';
import BlogItem from '../../components/Blog/BlogItem';

export const BlogPage = () => {
    return (
        <div>
            <PageTitle>{L('ourliving_news')}</PageTitle>
            <div style={{ maxWidth: '600px' }}>
                <Feed asyncFn={({ pageParam }) => BlogApi.list(pageParam)} cacheKey={['blog']} threshold={500}>
                    {(item: BlogItemType) => {
                        if (!item) return null;

                        return <BlogItem key={item.id} {...item} />;
                    }}
                </Feed>
            </div>
        </div>
    );
};
