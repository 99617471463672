import { useMutation, useQueryClient } from 'react-query';
import MaintenancesApi from '@app/api/public/MaintenancesApi';
import { getMaintenanceQueryKey } from './useGetMaintenance';
import { SaveMaintenance } from '@app/api/models/Maintenances';

type Variables = Partial<SaveMaintenance>;

const saveMaintenance = async (variables: Variables) => {
    return MaintenancesApi.save(variables);
};

const useSaveMaintenance = () => {
    const queryClient = useQueryClient();

    return useMutation((variables: Variables) => saveMaintenance(variables), {
        onSettled: () => {
            queryClient.invalidateQueries(getMaintenanceQueryKey);
        },
    });
};

export default useSaveMaintenance;
