import ContractsApi from '@app/api/public/ContractsApi';
import { useQueryClient, useMutation } from 'react-query';
import { getContractKeys } from './useContract';

const useArchiveContract = () => {
    const queryClient = useQueryClient();

    return useMutation(async (id: number) => ContractsApi.archiveContract(id), {
        onSettled: (_res, _err, id) => {
            queryClient.invalidateQueries(getContractKeys.getById(id));
            queryClient.invalidateQueries(getContractKeys.list());
            queryClient.invalidateQueries(getContractKeys.listArchived());
        },
    });
};

export default useArchiveContract;
