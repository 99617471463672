import React from 'react';
import { Breadcrumbs, Icon } from '@ourliving/ourliving-ui';
import useGetFolderById from '../hooks/folders/useGetFolderById';
import { Link, useLocation } from 'react-router-dom-v5-compat';
import styled from '@emotion/styled';
import { L } from '../../../lib/i18n';

const SBreadcrumbs = styled(Breadcrumbs)({
    marginBottom: '1.5rem',
    overflowX: 'auto',
    overflowY: 'hidden',
    flexWrap: 'nowrap',
    height: 'fit-content',

    '*': {
        whiteSpace: 'nowrap',
    },
});

const ActiveBreadCrumb = styled(Breadcrumbs.ItemActive)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    a: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

const BreadCrumbItem = styled(Breadcrumbs.Item)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const HomeIcon = styled(Icon.Home)({
    marginRight: '0.5rem',
});

type FolderBreadcrumbsProps = {
    locationFolderPath: string;
};

const FolderBreadcrumbs = ({ locationFolderPath }: FolderBreadcrumbsProps) => {
    // array of breadcrumbs
    const breadcrumbs = locationFolderPath
        ?.slice(1)
        .split('/')
        .filter((id) => id !== '')
        .map((id) => Number(id));

    return (
        <SBreadcrumbs style={{ flexWrap: 'nowrap' }}>
            {location.pathname === '/document' ? (
                <ActiveBreadCrumb>
                    <Link to={'/document'}>
                        <HomeIcon height={'1.5rem'} width={'1.5rem'} /> {L('home')}
                    </Link>
                </ActiveBreadCrumb>
            ) : (
                <BreadCrumbItem to={'/document'}>
                    <HomeIcon height={'1.5rem'} width={'1.5rem'} /> {L('home')}
                </BreadCrumbItem>
            )}

            {breadcrumbs.map((breadcrumbId) => (
                <FolderBreadcrumbItem key={breadcrumbId} breadcrumbs={breadcrumbs} folderId={breadcrumbId} />
            ))}
        </SBreadcrumbs>
    );
};

export default FolderBreadcrumbs;

type FolderBreadcrumbItemProps = {
    folderId: number;
    breadcrumbs: number[];
};

const FolderBreadcrumbItem = ({ folderId, breadcrumbs }: FolderBreadcrumbItemProps) => {
    const { data: folder } = useGetFolderById(Number(folderId));

    const location = useLocation();
    const folderName = location.state?.item.name;

    const finalBreadcrumbs = breadcrumbs.slice(0, breadcrumbs.findIndex((value) => value === folderId) + 1);
    const folderPathway = `/${finalBreadcrumbs.join('/')}`;

    if (breadcrumbs[breadcrumbs.length - 1] === folderId) {
        return <Breadcrumbs.ItemActive>{folderName ?? folder?.name}</Breadcrumbs.ItemActive>;
    }
    return (
        <Breadcrumbs.Item to={`/document${folderPathway}`} key={folderId}>
            {folder?.name}
        </Breadcrumbs.Item>
    );
};
