import React from 'react';
import * as Icon from 'react-feather';
import styled from '@emotion/styled';
import { style } from '../../../../components/Shared/Style';

const padding = 9;

interface Props {
    children?: any;
    // onChange?: any;
    onClick?: () => void;
    className?: string;
    custom?: string;
    onFilesSelected: (files: File[]) => void;
    id?: string;
}

const FileLabel = (props) => <label {...props} />;

const Cursor = styled.div({
    cursor: 'pointer',
});

const FileInput = styled.input({
    position: 'absolute',
    width: '0',
    height: '0',
    visibility: 'hidden',
});

const DivButton = styled.div(({ theme }) => ({
    color: theme.colors.primary,
    borderColor: theme.colors.primary,
    background: 'inherit',
    border: '1px solid',
    cursor: 'pointer',
    fontSize: style.fontSize.seta,
    fontWeight: style.fontWeight.bold,
    borderRadius: style.roundCornerSize.small,
    textDecoration: 'none',
    lineHeight: '16px',
    boxSizing: 'border-box',
    outline: 'none',
    padding: `${padding}px`,
    minWidth: '80px',
    width: 'max-content',
}));

export function FileUpload(props: Props) {
    const onChange = (evt) => {
        const files = Array.from(evt.target.files) as File[];
        props.onFilesSelected(files);
    };

    return (
        <FileLabel custom={props.custom} htmlFor={props.id || 'fileUpload'}>
            <FileInput
                id={props.id || 'fileUpload'}
                type="file"
                onClick={(evt) => ((evt.target as HTMLInputElement).value = '')}
                onChange={onChange}
            />
            {props.custom == 'yes' ? (
                <DivButton>{props.children}</DivButton>
            ) : (
                <Cursor>
                    <Icon.Paperclip />
                </Cursor>
            )}
        </FileLabel>
    );
}

// export class FileUpload extends React.Component {
//     props: {
//         onFilesSelected: (files: File[]) => void;
//     };

//     onChange = evt => {
//         const files = Array.from(evt.target.files) as File[];
//         this.props.onFilesSelected(files);
//     };

//     render() {
//         return (
//             <label className={css.upload}>
//                 <input type="file" className={css.input} onChange={this.onChange} />
//                 <div className={css.icon}>
//                     <Hover>
//                         <Icon.Paperclip />
//                     </Hover>
//                 </div>
//             </label>
//         );
//     }
// }
