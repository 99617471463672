import { TicketSettingType } from '@app/api/models/Tickets';
import TicketsApi from '@app/api/public/TicketsApi';
import { useMutation, useQueryClient } from 'react-query';
import { ticketQueryKeys } from './ticketQueries';

type variables = {
    settingId: number;
    type: TicketSettingType;
};
const useRemoveSettingByType = () => {
    const client = useQueryClient();
    return useMutation((variables: variables) => TicketsApi.removeSettingByType(variables.type, variables.settingId), {
        onSuccess: (data, variables) => {
            client.invalidateQueries(ticketQueryKeys[variables.type]());
        },
    });
};

export default useRemoveSettingByType;
