import React, { useState } from 'react';
import styled from '@emotion/styled';
import Label from '../../../components/Forms/Label';
import { Button } from '../../../components/Buttons/Button';
import Input from '../../../components/Forms/Input';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { roundCornerSize } from '../../../components/Shared/Style/box';
import { colors } from '../../../components/Shared/Style/colors';
import { useAddFolder } from '../hooks/mailQueries';
import { useQueryClient } from 'react-query';
import { getMailKeys } from '@app/shared/queryKeys/mail';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router';
import { CommunityMailFolderType } from '@app/api/models/Mail/MailModels';
import FolderItem from './FolderItem';

const ModalWrapper = styled(CardWithDivider)({
    maxWidth: '500px',
    maxHeight: '80vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: colors.background,
    borderRadius: roundCornerSize.medium,
});
const Form = styled.form({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
});

const StyledLabel = styled(Label)({
    width: '100%',
});

const Content = styled('div')({
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
});

const ItemContainer = styled.div({
    display: 'grid',
    gap: '1rem',
});

export default function AddFolder({
    mailId,
    folderName,
    onClose,
    folderList,
}: {
    mailId?: number;
    folderName?: string;
    onClose: () => void;
    folderList?: CommunityMailFolderType[];
}) {
    const queryClient = useQueryClient();
    const [name, setName] = useState(folderName || '');
    const [error, setError] = useState<string | undefined>();
    const { mutate: save } = useAddFolder();
    const history = useHistory();

    const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (name.length < 2 || name.length > 25) {
            return setError(L('name_too_short_or_too_long'));
        }

        save(
            {
                folderName: name,
                mailId,
            },
            {
                onSuccess: (res) => {
                    queryClient.invalidateQueries(getMailKeys.all);
                    toast.success(L('folder_create_success'));
                    setError(undefined);
                    if (mailId && res?.id) {
                        history.push(`/mail/folder/${res?.id}`);
                        onClose();
                    }
                },
                onError: () => {
                    toast.error(L('folder_create_fail'));
                    setError(undefined);
                },
            }
        );
        setName('');
    };

    return (
        <ModalWrapper
            onClick={(e) => e.stopPropagation()}
            topArea={<CardTitle>{L('create_new_folder')}</CardTitle>}
            mainArea={
                <>
                    <Form onSubmit={handleSave}>
                        <StyledLabel title={L(`create_new_folder`)}>
                            <Content>
                                <Input
                                    required={true}
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    placeholder={L(`label_type`)}
                                />
                                {/* <Dialog.Close asChild={true}> */}
                                <Button type="submit" variant="selectedMenu">
                                    {L(`create`)}
                                </Button>
                                {/* </Dialog.Close> */}
                            </Content>
                            {error && <p style={{ color: colors.errors }}>{error}</p>}
                        </StyledLabel>
                    </Form>
                    {!!folderList && !mailId && folderList.length > 0 && (
                        <>
                            <Label title={L(`all_folders`)} />
                            <ItemContainer>
                                {folderList?.map((item) => {
                                    return <FolderItem key={item.id} item={item} />;
                                })}
                            </ItemContainer>
                        </>
                    )}
                </>
            }
        />
    );
}
