import { useQuery } from 'react-query';
import PublicSiteApi from '@app/api/public/PublicSiteApi';
import { PublicSiteInformationItem } from '@app/api/models/PublicSite';
import { getQueryPageListItems } from './useGetPageListItems';

const getByPageType = (pageType: PublicSiteInformationItem['page_type']) => {
    return PublicSiteApi.getByType(pageType);
};

const useGetByPageType = (pageType: PublicSiteInformationItem['page_type']) => {
    return useQuery(getQueryPageListItems.id(pageType), () => getByPageType(pageType));
};

export default useGetByPageType;
