import React from 'react';
import styled, { StyledComponent } from '@emotion/styled';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { Theme } from '@emotion/react';

export const StyledNavLink: StyledComponent<
    NavLinkProps<unknown> &
        React.RefAttributes<HTMLAnchorElement> & {
            theme?: Theme | undefined;
        }
> = styled(NavLink)(({ theme }) => {
    return {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1rem',
        lineHeight: '19px',
        color: theme.colors.tabInactive,

        boxSizing: 'border-box',
        display: 'inline-block',
        marginRight: '16px',
        paddingTop: '16px',
        textDecoration: 'none',
        paddingBottom: '8px',

        '&:hover': {
            color: theme.colors.tabActive,
            cursor: 'pointer',
            textDecoration: 'none',
        },
        '&.active': {
            color: theme.colors.tabActive,
            textDecoration: 'none',
            borderBottom: `1px solid ${theme.colors.tabActive}`,
        },
    };
});

const SmallTab: React.FC<NavLinkProps> = ({ children, ...rest }) => {
    return (
        <li>
            <StyledNavLink {...rest}>{children}</StyledNavLink>
        </li>
    );
};

export default SmallTab;
