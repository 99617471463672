import { useMutation, useQueryClient } from 'react-query';
import DocumentsApi from '@app/api/public/DocumentsApi';
import toast from 'react-hot-toast';
import { fileKeys } from '../../queryKeys';
import { L } from '../../../../lib/i18n';
import { SelectItem } from '../../components/documentList/ListItem';

const useDeleteFilesAndFolders = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (items: SelectItem[]) => {
            const res = toast.promise(
                (async () => {
                    const data = await DocumentsApi.deleteFilesAndFolders(items);
                    console.log(data);
                    return data;
                })(),
                {
                    loading: L('deleting_selected'),
                    error: L('delete_selected_error'),
                    success: ({ deleteCount }) =>
                        `${L('delete_many_response_1')} ${deleteCount} ${L('delete_many_response_2')}`,
                }
            );

            return res;
        },
        onSettled: () => {
            queryClient.invalidateQueries([fileKeys]);
        },
    });
};

export default useDeleteFilesAndFolders;
