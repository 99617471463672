import AlertsApi, { Alert } from '@app/api/public/AlertsApi';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { L } from '../../../lib/i18n';
import { alertKeys } from '../hooks/alertsQuery';

export type EditAlertType = Pick<Alert, 'title' | 'description' | 'priority' | 'publish_from_date' | 'publish_to_date'>;

const editAlert = (alert: EditAlertType & { id: number; propertyIds: number[] }) => {
    return AlertsApi.save(alert, alert.propertyIds);
};

const useEditAlert = () => {
    const client = useQueryClient();
    return useMutation((alert: EditAlertType & { id: number; propertyIds: number[] }) => editAlert(alert), {
        onMutate: () => {
            const id = toast.loading('Redigerar viktigt medlemmar');
            return id;
        },
        onSuccess: (_data, _variables, id: string) => {
            toast.success('Viktigt meddelande redigerat', { id });
            client.invalidateQueries(alertKeys.all);
        },
        onError: (_error, _variables, id: string) => {
            toast.error(L('error'), { id });
        },
    });
};

export default useEditAlert;
