import { SaveBookingsVariables } from '@app/api/models/Bookings';
import BookingsApi from '@app/api/public/BookingsApi';
import { useMutation, useQueryClient } from 'react-query';
import { useGetBookingDatesQueryKey } from './useGetBookingDates';
import { useGetBookingsByResourceIdQueryKey } from './useGetBookingsByResourceId';
import { useGetBookingsListQueryKey } from './useGetBookingsList';
import { useGetBookinUsersQueryKey } from './useGetBookingUsers';
import { getUserBookingsQueryKey } from './useGetUserBookings';

type Variables = SaveBookingsVariables;

const useSaveBooking = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async (variables: Variables) => {
            const res = await BookingsApi.saveBooking(variables);
            return res;
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(useGetBookingsByResourceIdQueryKey);
                queryClient.invalidateQueries(getUserBookingsQueryKey);
                queryClient.invalidateQueries(useGetBookingsListQueryKey);
                queryClient.invalidateQueries(useGetBookinUsersQueryKey);
                queryClient.invalidateQueries(useGetBookingDatesQueryKey);
            },
        }
    );
};

export default useSaveBooking;
