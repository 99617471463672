import React from 'react';
import styled from '@emotion/styled';
import { StyleColumn } from '../../../../types/ColumnType';
import dayjs from 'dayjs';
import sv from 'dayjs/locale/sv';
import { L } from '../../../../lib/i18n';
import { colors } from '../../../../components/Shared/Style/colors';
import { margin } from '../../../../components/Shared/Style/margin';
import { ListingMember } from '@app/api/public/MembersApi';

dayjs.locale(sv);

const Span = styled.span({
    textAlign: 'left',
    fontSize: '14px',
});

const StyledNameDiv = styled.div({
    textDecoration: 'none',
    color: 'inherit',
});

const StyledP = styled.p({
    color: colors.black80,
    marginTop: margin.s,
    fontSize: '12px',
});

export const COLUMNS: StyleColumn<ListingMember>[] = [
    {
        Header: 'Namn och mail',
        accessor: (value) => [value.email, value.name],
        width: 175,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const member = row.original;
            return (
                <StyledNameDiv>
                    <Span>{member.name}</Span>
                    <StyledP>{member.email}</StyledP>
                </StyledNameDiv>
            );
        },
    },
    {
        Header: () => L('account_confirmed'),
        id: 'Accepted',
        accessor: (originalRow: ListingMember) => {
            return dayjs(originalRow.confirmed_at).format('YYYY-MM-DD');
        },
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const member = row.original;
            return <Span>{member.confirmed_at && dayjs(member.confirmed_at).format('YYYY-MM-DD')}</Span>;
        },
    },
    {
        Header: () => L('user_invited_at'),
        id: 'user_invited_at',
        accessor: (originalRow: ListingMember) => {
            return dayjs(originalRow.invited_at).valueOf();
        },
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const member = row.original;
            return <Span>{dayjs(member.invited_at).isValid() && dayjs(member.invited_at).format('YYYY-MM-DD')}</Span>;
        },
    },
];

export const COLUMNSMOBILE: StyleColumn<ListingMember>[] = [
    {
        Header: 'Namn och mail',
        accessor: (value) => [value.email, value.name],
        width: 150,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const member = row.original;
            return (
                <StyledNameDiv>
                    <Span>{member.name}</Span>
                    <StyledP>{member.email}</StyledP>
                </StyledNameDiv>
            );
        },
    },

    {
        Header: 'Roll',
        accessor: 'role',
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const member = row.original;
            return <Span>{L(member.role)}</Span>;
        },
    },
];
