import React from 'react';
import { Route } from 'react-router-dom';
import BoardInformationPage from './BoardInformationPage';

export const routes = [
    <Route key="1" path="/board-information/:id" component={BoardInformationPage} />,
    <Route key="0" path="/board-information" component={BoardInformationPage} />,
];

export const navigation = {
    name: 'board_content_information',
    link: '/board-information',
    icon: 'Information',
    parent: 'BoardMembers',
};
