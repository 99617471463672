import React from 'react';
import styled from '@emotion/styled';
import { HTMLAttributes } from 'react';
import { style } from '../../../components/Shared/Style';
import { p } from '../../../components/Shared/Style/typography';
import Label from '../../../components/Forms/Label';
import { L } from '../../../lib/i18n';
import { useDocumentDetails } from '../../../hooks/useGetDocumentDetail';
import DocumentItem from '../../../components/document/DocumentItem';
import { colors } from '../../../components/Shared/Style/colors';
import { LogEvent } from '@app/api/models/Tickets';
import formatChatDate from '../lib/formatChatDate';
import { useGetSettingsByType } from '../hooks/ticketQueries';

const StyledDocument = styled(DocumentItem)({
    backgroundColor: colors.myBubble,
    borderRadius: style.roundCornerSize.small,
    padding: '0.5rem',
    '& p': {
        margin: '0',
    },
    '&.me': {
        backgroundColor: colors.white,
    },
    '&.collaborator': {
        backgroundColor: colors.white,
    },
});

const StyledLog = styled.div({
    ...p,
    display: 'grid',
    gap: '0.5rem',

    padding: '0.75rem',
    borderRadius: style.roundCornerSize.small,
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)',
    alignSelf: 'start',
    '&.me': {
        alignSelf: 'end',
        backgroundColor: colors.myBubble,
    },
    '&.collaborator': {
        backgroundColor: colors.collaboratorBubble,
    },
});

const StyledSettings = styled.div({
    width: '100%',
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
});
const StyledSettingsColor = styled.div({
    width: '15px',
    height: '15px',
});

const ParseLogContainer = styled.div({
    '&.me': {
        '& .title': {
            color: colors.black,
        },
    },
    '&.collaborator': {
        '& .title': {
            color: colors.black,
        },
    },
});
const LogHeader = styled.div({
    display: 'grid',
    gridTemplateColumns: 'max-content max-content max-content',
    gap: '1ch',
});
type SettingsType = NonNullable<ReturnType<typeof useGetSettingsByType>['data']>;

type ParseLogProps = {
    log: LogEvent['data'];
    projectName: string;
    logCreator: LogCreator;
    statuses: SettingsType;
    priorities: SettingsType;
};

const ParseLog = ({ log, projectName, logCreator, statuses, priorities }: ParseLogProps) => {
    const documentDetalQueries = useDocumentDetails(log?.attachment_ids);
    const Documents = documentDetalQueries?.map((query) => {
        const data = query.data;
        if (!data) return null;
        return <StyledDocument className={logCreator} key={data.id} document={data} />;
    });

    const status = () => {
        if (log.ticket_status_id) {
            const status = statuses.find((s) => s.id === log.ticket_status_id);
            return (
                <StyledSettings>
                    <StyledSettingsColor style={{ backgroundColor: status?.color_code }} />
                    <span>{status?.name}</span>
                </StyledSettings>
            );
        }
        return '-';
    };
    const priority = () => {
        if (log.ticket_priority_id) {
            const priority = priorities.find((s) => s.id === log.ticket_priority_id);
            return (
                <StyledSettings>
                    <StyledSettingsColor style={{ backgroundColor: priority?.color_code }} />
                    <span>{priority?.name}</span>
                </StyledSettings>
            );
        }
        return '-';
    };
    return (
        <ParseLogContainer className={logCreator}>
            {log.hasOwnProperty('title') && <Label title={L('title')}>{log.title || '-'}</Label>}
            {log.description?.plain_text && (
                <Label title={L('description')}>{log.description?.plain_text || '-'}</Label>
            )}
            {log.hasOwnProperty('location_name') && <Label title={L('location')}>{log.location_name || '-'}</Label>}
            {log.hasOwnProperty('inventory_name') && <Label title={L('item')}>{log.inventory_name || '-'}</Label>}
            {log.hasOwnProperty('assigned_name') && <Label title={L('assignee')}>{log.assigned_name || '-'}</Label>}
            {log.hasOwnProperty('ticket_priority_id') && <Label title={L('priority')}>{priority()}</Label>}
            {log.hasOwnProperty('ticket_status_id') && <Label title={L('status')}>{status()}</Label>}
            {log.hasOwnProperty('ticket_cost') && <Label title={L('cost')}>{log.ticket_cost || '-'}</Label>}
            {log.hasOwnProperty('is_ticket_customer_cost') && (
                <Label title={L('who_pays')}>{log.is_ticket_customer_cost ? L('residence') : projectName}</Label>
            )}
            {log.hasOwnProperty('attachment_ids') && <Label title={L('attachments')}>{Documents || '-'}</Label>}
            {log.hasOwnProperty('ticket_no') && <Label title={L('ticket_no')}>{log.ticket_no || '-'}</Label>}
        </ParseLogContainer>
    );
};

type LogCreator = 'me' | 'customer' | 'collaborator';
type LogProps = {
    logItem: LogEvent;
    projectName: string;
    logCreator: LogCreator;
    statuses: SettingsType;
    priorities: SettingsType;
} & HTMLAttributes<HTMLDivElement>;

const logTitle = (
    event_type: LogEvent['event_type'],
    data: LogEvent['data'],
    logCreator: LogCreator,
    created_name: string
) => {
    if (logCreator === 'me') {
        if (event_type === 'remove' && data.hasOwnProperty('archived_at')) {
            return data.archived_at ? L('ticket_archived') : L('ticket_unarchived');
        }

        return `${L(`ticket_${event_type}`)} `;
    }
    if (event_type === 'remove' && data.hasOwnProperty('archived_at')) {
        return data.archived_at
            ? `${L(`ticket_archived_by`)} ${created_name}`
            : `${L(`ticket_unarchived_by`)} ${created_name}`;
    }

    return `${L(`ticket_${event_type}_by`)} ${created_name}`;
};

const LogItem = ({
    logItem: { created_name, updated_at, data, event_type },
    statuses,
    priorities,
    projectName,
    logCreator,
    ...rest
}: LogProps) => {
    const title = logTitle(event_type, data, logCreator, created_name);
    return (
        <StyledLog className={logCreator} {...rest}>
            <LogHeader>
                <span>{title}</span>
                <span>{formatChatDate(updated_at)}</span>
            </LogHeader>
            <ParseLog
                statuses={statuses}
                priorities={priorities}
                logCreator={logCreator}
                log={data}
                projectName={projectName}
            />
        </StyledLog>
    );
};

export default LogItem;
