import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BigNavItem from '../../components/BigTabItem/BigTabListItemNav';
import BigTabNav from '../../components/BigTabItem/BigTabNav';
import PageTitle from '../../components/typography/PageTitle';
import { L } from '../../lib/i18n';
import InvoiceSettingsPage from './InvoiceInformation/InvoiceInformation';
import ProjectDetailPage from './DetailPage/ProjectDetailPage';
import ProjectImages from './ProjectImages/ProjectImages';

const ProjectPage = () => {
    return (
        <>
            <PageTitle>{L('project_type')}</PageTitle>
            <BigTabNav>
                <BigNavItem exact to={`/project`}>
                    {L('general')}
                </BigNavItem>
                <BigNavItem exact to={`/project/images`}>
                    {L('images')}
                </BigNavItem>

                <BigNavItem to={`/project/invoice`}>{L('invoice_information')}</BigNavItem>
            </BigTabNav>
            <Switch>
                <Route exact path={`/project`}>
                    <ProjectDetailPage />
                </Route>
                <Route exact path={`/project/images`}>
                    <ProjectImages />
                </Route>
                <Route exact path={`/project/invoice`}>
                    <InvoiceSettingsPage />
                </Route>
            </Switch>
        </>
    );
};

export default ProjectPage;
