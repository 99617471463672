import { AnalyticCostByYear } from '@app/api/models/Maintenances';
import React from 'react';
import useGetAnalyticList from '../hooks/useGetAnalyticList';
import PieGraph from './PieGraph';

const PieGraphTotalCost = () => {
    const { data: analyticList } = useGetAnalyticList();

    if (!analyticList || analyticList.length === 0) return null;

    const uniqueCategories = [...new Set(analyticList.map((item) => item.maintenance_category.name)).values()];
    const avrageCost = analyticList
        .map((analyticCost) =>
            analyticCost.completed_amount && +analyticCost.completed_amount
                ? +analyticCost.completed_amount
                : +analyticCost.planned_amount
        )
        .reduce((sum, current) => sum + current);

    const Category = class {
        constructor(categories: AnalyticCostByYear[]) {
            this.costs = categories.map((catagory) => {
                return catagory.completed_amount && +catagory.completed_amount
                    ? +catagory.completed_amount
                    : +catagory.planned_amount;
            });
            this.name = categories[0].maintenance_category.name || '';
            this.color = categories[0].maintenance_category.color || '';
        }
        costs: number[];
        name: string;
        color: string;
        totalCost() {
            return Math.round(this.costs.reduce((sum, current) => sum + current));
        }
    };

    const categories = uniqueCategories.map((categoryName) => {
        const CategoryClass = new Category(
            analyticList.filter((item) => item.maintenance_category.name === categoryName)
        );
        return CategoryClass;
    });

    const data = {
        labels: categories.map((category) => category.name),
        datasets: [
            {
                label: 'Total kostnad',
                data: categories.map((category) => category.totalCost()),
                backgroundColor: categories.map((category) => category.color),
            },
        ],
    };

    return (
        <PieGraph
            data={data}
            headerPrice={'Total kostnad'}
            headerPie={'Total Kostnad per kategori'}
            price={new Intl.NumberFormat('sv-SE', {
                style: 'currency',
                currency: 'SEK',
                maximumFractionDigits: 0,
            }).format(avrageCost)}
        />
    );
};

export default PieGraphTotalCost;
