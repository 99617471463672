import React from 'react';
import DocumentsTable from '../../../components/Table/DocumentTables/DocumentsTable';
import useDeleteDocument from '../../../components/Website/hooks/useDeleteDocument';
import useSaveDocuments from '../../../components/Website/hooks/useSaveDocuments';
import useGetDocumentsById from '../hooks/useGetDocumentsById';

const DocumentsTableById = ({ id }: { id: number }) => {
    const { data: documentItem } = useGetDocumentsById(id);

    const { mutate: saveDocument } = useSaveDocuments();
    const { mutate: deleteDocument } = useDeleteDocument();

    if (!documentItem) return null;
    return (
        <DocumentsTable
            itemId={id.toString()}
            list={documentItem}
            deleteDocument={(doc) => deleteDocument(doc)}
            saveDocument={(doc) => saveDocument(doc)}
        />
    );
};

export default DocumentsTableById;
