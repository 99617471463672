import { ContractType } from '@app/api/models/Contracts';
import ContractsApi from '@app/api/public/ContractsApi';
import { useQueryClient, useMutation } from 'react-query';
import { getContractKeys } from './useContract';

const saveType = async (item: Partial<ContractType>) => {
    return ContractsApi.saveType(item);
};

const useSaveContract = () => {
    const queryClient = useQueryClient();

    return useMutation((item: Partial<ContractType>) => saveType(item), {
        onSettled: () => {
            queryClient.invalidateQueries(getContractKeys.types());
        },
    });
};

export default useSaveContract;
