import ContentInformationApi from '@app/api/public/ContentInformationApi';
import { useQuery } from 'react-query';

export const memberInformationQueryKeys = {
    all: [{ scope: 'memberInformation' }] as const,
    lists: () => [{ ...memberInformationQueryKeys.all[0], entity: 'list' }] as const,
    list: (type: 'draft' | 'published') => [{ ...memberInformationQueryKeys.lists()[0], type }] as const,
    details: () => [{ ...memberInformationQueryKeys.all[0], entity: 'detail' }] as const,
    detail: (id: number) => [{ ...memberInformationQueryKeys.details()[0], id }] as const,
    documents: () => [{ ...memberInformationQueryKeys.all[0], entity: 'document' }] as const,
    document: (id: number) => [{ ...memberInformationQueryKeys.documents()[0], id }] as const,
};

const getPageListItems = async (type: 'draft' | 'published') => {
    return await ContentInformationApi.list(type);
};

const useGetContentInformationList = (type: 'draft' | 'published') => {
    return useQuery({
        queryFn: ({ queryKey }) => {
            const { type } = queryKey[0];
            return getPageListItems(type);
        },
        queryKey: memberInformationQueryKeys.list(type),
    });
};

const getContentInformation = (id: number) => {
    return ContentInformationApi.get(id);
};

const useGetMemberInformationById = (id: number) => {
    return useQuery({
        queryFn: ({ queryKey }) => getContentInformation(queryKey[0].id),
        queryKey: memberInformationQueryKeys.detail(id),
        enabled: !!id,
    });
};

export { useGetContentInformationList, useGetMemberInformationById };
