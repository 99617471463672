import ProfilesApi, { ProfileDetail } from '@app/api/public/ProfilesApi';
import { useMutation, useQueryClient } from 'react-query';
import { profileQueryKey } from '../../../hooks/useGetProfile';
import { EditProfileDetail } from '../Components/ProfileSettings';

type Variables = Partial<ProfileDetail>;

const saveProfile = async (profile: Variables) => {
    return await ProfilesApi.save(profile);
};

export const profileFactory = ({
    name,
    phone_number,
    id,
    email,
    addressline1,
    addressline2,
    city,
    country,
    postal_code,
}: EditProfileDetail) => {
    const profile: Partial<ProfileDetail> = {
        name,
        phone_number,
        id,
        email,
        address: { addressline1, addressline2, city, country, postal_code },
    };

    return profile;
};

const useSaveProfile = () => {
    const queryClient = useQueryClient();
    return useMutation((profile: Variables) => saveProfile(profile), {
        onSettled: () => {
            queryClient.invalidateQueries([profileQueryKey]);
        },
    });
};

export default useSaveProfile;
