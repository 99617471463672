import React from 'react';
import styled from 'styled-components';
import { Card } from '../../../../components/Layout/Card';
import { style } from '../../../../components/Shared/Style';
import DropZoneNoText from './DropZoneNoText';
import { useDeleteAttachment, useSaveAttachment } from '../../hooks/mailQueries';
import useGetProjectInfo from '../../../../hooks/useGetProjectInfo';
import AttachmentArea from './AttachmentArea';
import { useQueryClient } from 'react-query';
import { MailMessage } from '@app/api/lib/mail';
import { useHistory } from 'react-router';
import toast from 'react-hot-toast';
import { L } from '../../../../lib/i18n';
import { getMailKeys } from '@app/shared/queryKeys/mail';

const StyledCard = styled(Card)({
    display: 'grid',
    backgroundColor: style.colors.white,
    alignContent: 'start',
    padding: '0',
});

export default function Attachments({
    mail,
    draftId,
    uploadedAttachments,
    ...rest
}: {
    mail: MailMessage;
    draftId: number | undefined;
    uploadedAttachments: string[] | undefined;
}) {
    const { data: project } = useGetProjectInfo();
    const savedAttachment = useSaveAttachment();
    const deleteAttachment = useDeleteAttachment();
    const queryClient = useQueryClient();
    const history = useHistory();
    const searchParams = new URLSearchParams(location.search);

    const deleteDocument = (s3key: string) => {
        if (project && project.ourlivingmail_username && draftId) {
            deleteAttachment.mutate(
                { draftId, s3key },
                {
                    onSuccess: () => {
                        toast.success(L('removed_attachment'));

                        queryClient.invalidateQueries(getMailKeys.getById(draftId));
                    },
                    onError: () => {
                        toast.error(L('error_occured'));
                    },
                }
            );
        }
    };

    const onDropFunction = (acceptedFiles: File[]) => {
        const files: File[] = [];
        acceptedFiles.forEach((file) => {
            //Unique
            if (!files.find((currentFile) => currentFile.name === file.name)) {
                files.push(file);
            }
        });
        if (!project || !project.ourlivingmail_username) {
            return [];
        }
        savedAttachment.mutate(
            { mail, draftId, attachments: files, from: project?.ourlivingmail_username },
            {
                onSuccess: (res) => {
                    if ('draftId' in res && res.draftId && res.draftId !== draftId) {
                        //Set params
                        searchParams.set('draftid', String(res?.draftId));
                        history.push({ search: searchParams.toString() });
                    }
                    //getMailKeys
                    if ('draftId' in res && res.draftId === draftId)
                        queryClient.invalidateQueries(getMailKeys.getById(Number(draftId)));
                    toast.success(L('added_attachment'));
                },
                onError: () => {
                    toast.error(L('error_occured'));
                },
            }
        );
    };
    return (
        <div {...rest}>
            {
                <>
                    <AttachmentArea attachments={uploadedAttachments} deleteDocument={deleteDocument}>
                        <DropZoneNoText
                            onDrop={(acceptedFiles) => {
                                onDropFunction(acceptedFiles);
                            }}
                        />
                        <StyledCard />
                    </AttachmentArea>
                </>
            }
        </div>
    );
}
