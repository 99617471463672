import ProjectSettingsApi from '@app/api/public/ProjectSettingsApi';
import { useQuery } from 'react-query';

export const TicketSettingsQueryKey = {
    all: ['ticketAppStatus'],
};

export const useGetTicketsAppStatus = () => {
    return useQuery({
        queryKey: TicketSettingsQueryKey.all,
        queryFn: () => {
            return ProjectSettingsApi.getTicketEnabled();
        },
    });
};
