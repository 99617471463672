import React from 'react';
import styled from '@emotion/styled';
import { FileTypeIcon } from './Preview/FileTypeIcon';
import { L } from '../../../lib/i18n';
import useGetDocuments from '../hooks/useGetDocuments';
import { PreviewThemeType } from '../../../areas/public-site/theme/ThemePage';
import { useTheme } from 'emotion-theming';

const Container = styled.div({
    display: 'flex',
    margin: 'auto',
    flexDirection: 'column',
    textAlign: 'center',
    marginTop: '2.5rem',
});
const DocItem = styled.a(({ theme }: { theme: PreviewThemeType }) => {
    return {
        display: 'flex',
        alignItems: 'center',
        color: theme.colors.textColor1,
        border: '1px solid',
        borderColor: theme.colors.textColor1,
        borderRadius: '4px',
        padding: '5px',
        fontSize: '0.8rem',
        marginBottom: '0.5rem',
        width: '150px',
    };
});
const Title = styled.span(() => ({
    marginLeft: '0.5rem',
}));
const H1 = styled.a(({ theme }: { theme: PreviewThemeType }) => {
    return {
        color: theme.colors.textColor1,
        marginBottom: '1rem',
    };
});

const DocumentList = () => {
    const { data: documents } = useGetDocuments();
    const theme = useTheme<PreviewThemeType>();

    return (
        <Container>
            <H1 theme={theme}>{L('document')}</H1>
            {documents &&
                documents.map((doc, index) => {
                    return (
                        <DocItem theme={theme} key={doc.id + index}>
                            <FileTypeIcon fileType={doc?.document_data?.medium?.id?.match(/\.([^.]*$)/)?.[1]} />
                            <Title>{doc.name}</Title>
                        </DocItem>
                    );
                })}
        </Container>
    );
};

export default DocumentList;
