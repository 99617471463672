import React from 'react';
import SettingsModal from './SettingsModal';
import TypeSettings from './TypeSettings';
import Modal from '../../../components/Modal/Modal';

export default function TypeModal({ initial }: { initial?: string }) {
    return (
        <Modal>
            <SettingsModal onClick={(e) => e.stopPropagation()}>
                <TypeSettings initial={initial} />
            </SettingsModal>
        </Modal>
    );
}
