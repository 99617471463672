import * as React from 'react';
import { LoginStore } from './LoginStore';
import { useObserver } from 'mobx-react';
import { Button } from '../../components/Buttons/Button';
import { style } from '../../components/Shared/Style';
import styled from '@emotion/styled';
import { Dropdown } from '../../components/Dropdown/Dropdown';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { usePromise } from '../../lib/react';
import { Loading } from '../../components/Loading/Loading';
import Logo from '../../components/Logo/Logo';
import { Project } from '@app/api/models/Projects';
import Label from '../../components/Forms/Label';
import toast from 'react-hot-toast';
import PushPermission from '../../components/PushPermission/PushPermission';
import { handleGetToken, messaging, isSupported } from '../../firebase';
import { L } from '../../lib/i18n';

const Header = styled.div(({ theme }) => ({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: theme.colors.secondary,
    paddingBottom: style.margin.xs,
}));

const Background = styled.div(({ theme }) => ({
    width: '100%',
    minHeight: '100vh',
    backgroundSize: 'cover',
    backgroundColor: theme.colors.background,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const StyledLogo = styled(Logo)({
    maxWidth: '300px',
    width: '100%',
    height: '100%',
    maxHeight: '80px',
    placeSelf: 'center',
    backgroundColor: 'inherit',
});

const TextField = styled.div({
    paddingBottom: style.margin.s,
});

const LoginCard = styled.div({
    width: '90%',
});

const StyledNavLink = styled(NavLink)(({ theme }) => ({
    textDecoration: 'none',
    color: theme.colors.primary,
    lineHeight: '32px',
    fontSize: style.fontSize.seta,
    fontWeight: style.fontWeight.bold,
}));

const LoginInput = styled.input(({ theme }) => ({
    width: '100%',
    background: theme.colors.inputBackground,
    border: `1px solid ${theme.colors.inputBorder}`,
    height: '42px',
    borderRadius: style.roundCornerSize.small,
    outline: 'none',
    padding: `${style.margin.s} ${style.margin.m}`,
    fontSize: style.fontSize.seta,
    paddingLeft: style.margin.s,
}));

const Error = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});
const ErrorMessage = styled.strong({
    color: 'red',
});

const ButtonMenu = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: style.margin.s,
    gap: '5px',
    width: '100%',
    flexDirection: 'column',
    textAlign: 'center',
});

const Card = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    boxShadow: 'rgb(0 0 0 / 10%) 0px 1px 14px',
    padding: '50px 12px 40px 12px',
    boxSizing: 'border-box',
    borderRadius: style.roundCornerSize.medium,
    flexDirection: 'column',
    gap: '20px',
    width: '378px',
    height: '450px',
}));

interface Props {
    children: any;
}

export const LoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [project, setProject] = useState<Project>({} as Project);

    const [ready] = usePromise(async () => {
        if (LoginStore.state === 'projects') return;
        await LoginStore.verifyLogin();
    }, []);

    const login = (evt) => {
        evt.preventDefault();
        LoginStore.login(username, password);
    };

    const multiLogin = (evt) => {
        evt.preventDefault();
        LoginStore.multiLogin(project);
    };

    return useObserver(() => {
        if (!ready) {
            return <Loading />;
        }

        return (
            <form onSubmit={login}>
                <Background>
                    <Card>
                        <StyledLogo />

                        <LoginCard>
                            {LoginStore.state !== 'projects' ? (
                                <>
                                    {(LoginStore.state === 'error' || LoginStore.state === 'failed') && (
                                        <Error>
                                            {LoginStore.state === 'error' && (
                                                <ErrorMessage>{L('login_error')}</ErrorMessage>
                                            )}
                                            {LoginStore.state === 'failed' && (
                                                <ErrorMessage>{L('login_failed')}</ErrorMessage>
                                            )}
                                        </Error>
                                    )}

                                    <Label title={L('email')}>
                                        <TextField>
                                            <LoginInput type="text" onChange={(e) => setUsername(e.target.value)} />
                                        </TextField>
                                    </Label>

                                    <Label title={L('password')}>
                                        <TextField>
                                            <LoginInput type="password" onChange={(e) => setPassword(e.target.value)} />
                                        </TextField>
                                    </Label>
                                    <ButtonMenu>
                                        <Button
                                            type={'submit'}
                                            variant="selectedMenu"
                                            onClick={login}
                                            style={{ marginRight: '0px' }}
                                        >
                                            {L('login')}
                                        </Button>
                                        <StyledNavLink to="/forgottenpassword">{L('forgot_password')}?</StyledNavLink>
                                    </ButtonMenu>
                                </>
                            ) : (
                                <>
                                    <Header>{L('projects')}</Header>
                                    <Dropdown
                                        options={LoginStore.projects.map((p) => ({
                                            label: p.name,
                                            value: p,
                                        }))}
                                        onChange={(value) => setProject(value)}
                                        value={project}
                                    />

                                    <ButtonMenu>
                                        <Button
                                            style={{ marginTop: style.margin.l, width: '100%', marginRight: '0px' }}
                                            variant="selectedMenu"
                                            onClick={multiLogin}
                                        >
                                            {L('continue')}
                                        </Button>
                                    </ButtonMenu>
                                </>
                            )}
                        </LoginCard>
                    </Card>
                </Background>
            </form>
        );
    });
};

export const LoginPage = (props: { children: any }) => {
    return useObserver(() => {
        React.useEffect(() => {
            if ('serviceWorker' in navigator && 'PushManager' in window && 'Notification' in window) {
                if (Notification.permission === 'default') {
                    if (LoginStore.state === 'logged-in') {
                        isSupported()
                            .then((res) => {
                                console.log(res);
                                if (!res) return;
                                toast((toast) => <PushPermission toastId={toast.id} />, {
                                    duration: Infinity,
                                });
                            })
                            .catch((err) => console.log(err));
                    }
                }
                if (Notification.permission === 'granted') {
                    isSupported().then((res) => {
                        if (!res) return;
                        handleGetToken();
                    });
                }
            }
        }, [LoginStore.state]);
        if (LoginStore.state === 'logged-in') {
            return <React.Fragment>{props.children}</React.Fragment>;
        }
        return <LoginForm />;
    });
};
