import { useQuery } from 'react-query';
import MaintenancesApi from '@app/api/public/MaintenancesApi';

const getAnalyticList = async () => {
    return await MaintenancesApi.listAnalyticPlannedCompletedCostByYear();
};

const useGetAnalyticList = () => {
    return useQuery(['getAnalyticList'], () => getAnalyticList());
};

export default useGetAnalyticList;
