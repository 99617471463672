import * as React from 'react';
import styled from '@emotion/styled';
import { style } from '../../../../components/Shared/Style';
import { L } from '../../../../lib/i18n';
import { Button } from '../../../../components/Buttons/Button';
import { useHistory, useParams } from 'react-router-dom';
import { Loading } from '../../../../components/Loading/Loading';
import Label from '../../../../components/Forms/Label';
import { useGetMemberById } from '../../hooks/useGetMemberList';
import { useEffect, useState } from 'react';
import Input from '../../../../components/Forms/Input';
import useUpdateMember from '../../hooks/useUpdateMember';
import Spinner from '../../../../components/Spinner/Spinner';
import toast from 'react-hot-toast';
import useRemoveMember from '../../hooks/useRemoveMember';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import { margin } from '../../../../components/Shared/Style/margin';
import checkUnsavedData from '../../../../lib/helpers/checkUnsavedData';
import useInviteMember from '../../hooks/useInviteMember';
import dayjs from 'dayjs';
import { formatDateIsoDayjs } from '@app/shared/lib/formatting';

//#region css

const GridContainer = styled.div({
    display: 'grid',
    gap: '10rem',
    margin: '1rem 0',
    '@media screen and (min-width: 1100px)': {
        gridTemplateColumns: '2.5fr 1fr',
    },
});

const StyledCard = styled(CardWithDivider)({
    placeSelf: 'start',
    width: `100%`,
    margin: '1rem 1rem 1rem 0px',
    background: style.colors.white,
    minHeight: '25vh',
});

const TextContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '60%',
});

const ButtonContainer = styled.div({
    width: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
});

const ContactInfo = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'right',
    fontSize: '12px',
});

const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

//#endregion

export const MembersDetailPage = () => {
    const { id } = useParams<{ id: string }>();

    const [confirmDelete, setConfirmDelete] = React.useState(true);

    const { data: member, status: memberStatus } = useGetMemberById(id);

    const history = useHistory();

    const [memberState, setMemberState] = useState({ email: '', name: '' });

    const { mutate, status, reset } = useUpdateMember();
    const { mutate: removeMember } = useRemoveMember();
    const { mutate: inviteMember } = useInviteMember();

    useEffect(() => {
        if (!member) return;

        setMemberState({ ...memberState, name: member.name, email: member.email });
    }, [member]);

    if (memberStatus === 'loading') {
        return <Loading />;
    }

    if (memberStatus === 'success' && member) {
        const save = () => {
            mutate(
                { ...memberState, id: +member.user_id },
                {
                    onSuccess: () => {
                        toast.success(`${L('member')} ${memberState.email} ${L('saved')}`);
                    },
                }
            );
        };

        const remove = () => {
            removeMember(
                { id: member.id.toString() },
                {
                    onSuccess: () => {
                        toast.success(`${memberState.email} ${L('removed')}`);
                        history.push('/members');
                    },
                }
            );
        };

        const invite = () => {
            inviteMember({ membershipId: member.id });
        };

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>, name: 'email' | 'name') => {
            setMemberState({ ...memberState, [name]: e.target.value });
            reset();
        };

        const onSwitch = () => {
            const localResource = {
                ...member,
                ...memberState,
            };
            if (
                checkUnsavedData({
                    buttonText: L('continue'),
                    changedData: localResource,
                    originalData: member,
                    fn: () => history.push('/members'),
                })
            )
                return;
            history.push('/members');
        };
        const main = (
            <>
                <GridContainer>
                    <TextContainer>
                        <Label title={L('member_account')} />
                        <Input
                            placeholder={L('email')}
                            name="email"
                            value={memberState.email}
                            onChange={(e) => handleChange(e, 'email')}
                        />
                        <Label title={L('name')} />
                        <Input
                            placeholder={L('name')}
                            name="name"
                            value={memberState.name}
                            onChange={(e) => handleChange(e, 'name')}
                        />
                    </TextContainer>
                    <ContactInfo>
                        <Label title={L('last_login')}>
                            {member.last_login_at ? formatDateIsoDayjs(member.last_login_at) : '-'}
                        </Label>
                        <Label title={L('invite_sent')}>
                            {member.invited_at ? formatDateIsoDayjs(member.invited_at) : '-'}
                        </Label>
                        <Label title={L('account_confirmed')}>
                            {member.account_confirmed_at ? formatDateIsoDayjs(member.account_confirmed_at) : '-'}
                        </Label>

                        {<Label title={L('property')}>{member.property_name}</Label>}
                    </ContactInfo>
                </GridContainer>
                <ButtonContainer>
                    <Button onClick={onSwitch} style={{ marginRight: margin.s }} variant={'primary'}>
                        {L('cancel')}
                    </Button>
                    {status === 'idle' && (
                        <Button
                            disabled={memberState.email && memberState.name ? false : true}
                            onClick={save}
                            variant="filledPrimary"
                        >
                            {L('save')}
                        </Button>
                    )}
                    {status === 'loading' && (
                        <Button variant="filledPrimary" style={{ display: 'flex', justifyContent: 'center' }}>
                            <Spinner />
                        </Button>
                    )}
                    {status === 'error' && (
                        <Button type={'submit'} variant="filledPrimary">
                            {L('error')}
                        </Button>
                    )}
                    {status === 'success' && (
                        <Button onClick={save} style={{ marginRight: '0px' }} variant={'filledPrimary'}>
                            {L('save')}
                        </Button>
                    )}
                </ButtonContainer>
            </>
        );
        return (
            <StyledCard
                mainArea={main}
                topArea={
                    <Wrapper>
                        <CardTitle>{L('member')}</CardTitle>
                        <ButtonContainer>
                            <Button style={{ marginRight: margin.s }} onClick={invite} variant={'primary'}>
                                {L('invite')}
                            </Button>
                            {confirmDelete ? (
                                <Button onClick={() => setConfirmDelete(false)} variant={'primary'}>
                                    {L('delete')}
                                </Button>
                            ) : (
                                <Button onClick={remove} variant={'delete'}>
                                    {L('confirm')}
                                </Button>
                            )}
                        </ButtonContainer>
                    </Wrapper>
                }
            />
        );
    }
    return <div>Kunde inte hämta användare</div>;
};
