import axios from 'axios';
import { useQuery } from 'react-query';

type GoogleFontsType = {
    kind: 'webfonts#webfont';
    items: {
        family: string;
        variants: string[];
        subsets: string[];
        version: string;
        lastModified: string;
        files: {
            [key: string]: string;
        };
        category: string;
        kind: string;
    }[];
};

const getFonts = async () => {
    try {
        const response = await axios.get(
            `https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyD0PWbwc7nkG6dxIZX4eG3TCEUit5q429k&sort=popularity`
        );
        return response.data as GoogleFontsType;
    } catch (err) {
        console.log(err);
    }
};

const useGetGoogleApiFonts = () => useQuery('GoogleFonts', getFonts);

export default useGetGoogleApiFonts;
