Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["list"] = createRpcProxy("BoardMembersApi", "list", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["listNonBoardMembers"] = createRpcProxy("BoardMembersApi", "listNonBoardMembers", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getbyid"] = createRpcProxy("BoardMembersApi", "getbyid", {
  "urlPrefix": "/rpc"
});
o["save"] = createRpcProxy("BoardMembersApi", "save", {
  "urlPrefix": "/rpc"
});
o["createFromEmail"] = createRpcProxy("BoardMembersApi", "createFromEmail", {
  "urlPrefix": "/rpc"
});
o["listBoardMemberSubRole"] = createRpcProxy("BoardMembersApi", "listBoardMemberSubRole", {
  "urlPrefix": "/rpc"
});
o["removeBoardMember"] = createRpcProxy("BoardMembersApi", "removeBoardMember", {
  "urlPrefix": "/rpc"
});
o["listProperties"] = createRpcProxy("BoardMembersApi", "listProperties", {
  "urlPrefix": "/rpc"
});
o["invite"] = createRpcProxy("BoardMembersApi", "invite", {
  "urlPrefix": "/rpc"
});