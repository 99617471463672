import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import { useGetServicePartners } from '../../service-partner/hooks/servicePartnerQueries';
import useSaveContract from '../hooks/useSaveContract';
import { StatusOption, useGetTypes } from '../hooks/useContract';
import { style } from '../../../components/Shared/Style';
import ErrorAndInputWrapper from '../../../components/Forms/ErrorAndInputWrapper';
import DropDownCreatable from '../../../components/DropDownCreatable/DropDownCreatable';
import { useModal } from '../../../components/Modal/Context/ModalContext';
import TypeModal from './TypeModal';
import Dialog from '../../../components/Dialog/Dialog';
import ServicePartnerForm from '../../service-partner/components/ServicePartnerForm/ServicePartnerForm';

const StyledCardWithDivider = styled(CardWithDivider)({
    width: 'fit-content',
});

const LabelWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});
const Textarea = styled.textarea({
    width: '100%',
    borderRadius: '4px',
    backgroundColor: '#FCFDFE',
    border: '1px solid #F0F1F7',
    height: '125px',
    resize: 'none',
    padding: '8px',
    fontSize: '14px',
    '&:focus': {
        outline: 'none',
    },
});

const ButtonWrapper = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
});

const RadioButtonWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    ['@media screen and (min-width: 1024px)']: {
        width: '20%',
    },
});

const RadioLabelWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
});

const GridContainer = styled.div({
    display: 'grid',
    gap: '1rem',
    columnGap: '2rem',
    backgroundColor: style.colors.white,
    alignItems: 'start',
    gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',
    gridTemplateAreas: `
    "contractor  contractor  contractor  statusOption  statusOption  statusOption"
    "name name name ... ... ..."
    "description  description  description description description description"
    "start_date  start_date  surveillance_date  surveillance_date  period_of_notice  period_of_notice"
    "contract-length-title  contract-length-title  contract-length-title  contract-length-title  contract-length-title  contract-length-title"
    "radio-wrapper  radio-wrapper  radio-wrapper  radio-wrapper  radio-wrapper  radio-wrapper"
    "...  ...  ...  ...  buttons buttons"
    `,
    paddingBottom: '0px',
    maxWidth: '600px',
});

type FormValues = {
    name: string;
    description: string;
    start_date: string;
    end_date: string;
    notification_date: string;
    period_of_notice: string;
    length_months: string;
    statusOption: StatusOption;
    servicePartner: { name: string; id: number };
    activeContractLengthOption: 'until_further_notice' | 'months' | 'end_date';
};

const AddContract = () => {
    const { mutate: saveContract } = useSaveContract();
    const { data: servicePartners } = useGetServicePartners();
    const { data: statusOptions } = useGetTypes();
    const { ToggleIsModalOpen } = useModal();
    const [newType, setNewType] = useState('');
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        control,
        formState: { errors },
    } = useForm<FormValues>({
        defaultValues: {
            activeContractLengthOption: 'until_further_notice',
        },
    });

    const statusOptionValue = watch('statusOption');
    const activeContractLengthOptionValue = watch('activeContractLengthOption');
    const servicePartnerValue = watch('servicePartner');

    const history = useHistory();

    const onSubmit: SubmitHandler<FormValues> = (data) => {
        const {
            activeContractLengthOption,
            description,
            end_date,
            length_months,
            name,
            notification_date,
            period_of_notice,
            servicePartner,
            start_date,
            statusOption,
        } = data;

        if (!servicePartner || !statusOption) return;
        console.log(data);

        saveContract(
            {
                contract: {
                    name,
                    description,
                    start_date,
                    period_of_notice: period_of_notice,
                    notification_date: notification_date,
                    service_partner: servicePartner.id,
                    contract_types: {
                        id: statusOption.id,
                        name: statusOption.name,
                        color_code: statusOption.color_code,
                    },
                    contract_length: {
                        end_date: activeContractLengthOption === 'end_date' ? end_date : '',
                        until_further_notice: activeContractLengthOption === 'until_further_notice' ? true : false,
                        length_months: activeContractLengthOption === 'months' ? length_months : '',
                    },
                },
            },
            {
                onSuccess: (data) => {
                    history.push(`/contracts/${data.id}`);
                    toast.success(L('saved_contract'));
                },
                onError: () => {
                    toast.error(L('save_contract_failed'));
                },
            }
        );
    };

    useEffect(() => {
        register('servicePartner', { required: true });
        register('statusOption', { required: true });
    }, [register]);

    const createType = (e: string) => {
        ToggleIsModalOpen();
        setNewType(e);
    };

    const [open, setOpen] = useState(false);

    const [servicePartnerTextInput, setServicePartnerTextInput] = useState('');

    return (
        <>
            <Dialog
                content={{
                    style: {
                        backgroundColor: '#FFFFFF',
                    },
                }}
                open={open}
                onOpenChange={setOpen}
            >
                <ServicePartnerForm
                    style={{ margin: 0 }}
                    submitOptios={{
                        onSuccess: (data) => {
                            toast.success(L('service_partner_added'));
                            setValue('servicePartner', { id: data.id, name: data.name });
                            setOpen(false);
                        },
                        onError: () => {
                            toast.success(L('error_occured'));
                        },
                    }}
                    initialFormData={{ name: servicePartnerTextInput }}
                />
            </Dialog>
            <TypeModal initial={newType} />
            <StyledCardWithDivider
                topArea={<CardTitle>{L('add_contract')}</CardTitle>}
                mainArea={
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <GridContainer>
                            <LabelWrapper style={{ gridArea: 'name' }}>
                                <Label title={`${L('name')}*`} />

                                <ErrorAndInputWrapper
                                    isMinHeight={false}
                                    errorMsg={
                                        errors.name?.type === 'maxLength'
                                            ? `${L('max_characters')} 40`
                                            : errors.name?.type === 'required'
                                            ? L('error_required')
                                            : ''
                                    }
                                >
                                    <Input
                                        style={errors?.name ? { borderColor: '#ff4545' } : {}}
                                        placeholder={L('name')}
                                        type="text"
                                        {...register('name', { required: true, maxLength: 40 })}
                                    />
                                </ErrorAndInputWrapper>
                            </LabelWrapper>

                            <LabelWrapper style={{ gridArea: 'contractor' }}>
                                <Label title={`${L('contractor')}*`} />
                                <ErrorAndInputWrapper
                                    isMinHeight={false}
                                    errorMsg={errors.servicePartner?.type === 'required' ? L('error_required') : ''}
                                >
                                    <DropDownCreatable
                                        onChange={(value) => {
                                            if (!value) return;
                                            setValue('servicePartner', { id: value.value, name: value.label });
                                        }}
                                        value={
                                            servicePartnerValue
                                                ? { label: servicePartnerValue.name, value: servicePartnerValue.id }
                                                : undefined
                                        }
                                        options={
                                            servicePartners?.map((partner) => {
                                                return { value: partner.id, label: (L(partner.name) as string) ?? '' };
                                            }) || []
                                        }
                                        placeholder={L('select')}
                                        css
                                        onCreateOption={(value) => {
                                            setServicePartnerTextInput(value);
                                            setOpen(true);
                                        }}
                                    />
                                </ErrorAndInputWrapper>
                            </LabelWrapper>

                            <LabelWrapper style={{ gridArea: 'statusOption' }}>
                                <Label title={`${L('contract_type')}*`} />
                                <ErrorAndInputWrapper
                                    isMinHeight={false}
                                    errorMsg={errors.statusOption?.type === 'required' ? L('error_required') : ''}
                                >
                                    <DropDownCreatable
                                        placeholder={L('select')}
                                        isClearable
                                        value={
                                            statusOptionValue?.name
                                                ? { value: statusOptionValue, label: L(statusOptionValue?.name) }
                                                : undefined
                                        }
                                        options={
                                            statusOptions?.map((status) => {
                                                return { value: status, label: L(status.name) };
                                            }) || []
                                        }
                                        onChange={(value) =>
                                            setValue('statusOption', {
                                                id: Number(value?.value.id),
                                                name: String(value?.value.name),
                                            })
                                        }
                                        onCreateOption={createType}
                                    />
                                </ErrorAndInputWrapper>
                            </LabelWrapper>

                            <LabelWrapper style={{ gridArea: 'description' }}>
                                <Label title={L('description')} />
                                <Textarea placeholder={L('description')} {...register('description')} />
                            </LabelWrapper>

                            <LabelWrapper style={{ gridArea: 'period_of_notice' }}>
                                <Label title={`${L('notice_period_months')}`} titleVariant="noWhiteSpaceWrap" />
                                <ErrorAndInputWrapper
                                    isMinHeight={false}
                                    errorMsg={
                                        errors.period_of_notice?.type === 'min'
                                            ? L('amount_greater_than_0')
                                            : errors.period_of_notice?.type === 'pattern'
                                            ? errors.period_of_notice.message
                                            : ''
                                    }
                                >
                                    <Input
                                        placeholder={`${L('number_of_months')}`}
                                        type="number"
                                        min={0}
                                        {...register('period_of_notice', {
                                            min: 0,
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message: L('integer_only_error'),
                                            },
                                        })}
                                        onBlur={(e) => e.target.valueAsNumber < 0}
                                        style={errors?.period_of_notice ? { borderColor: '#ff4545' } : {}}
                                    />
                                </ErrorAndInputWrapper>
                            </LabelWrapper>

                            <LabelWrapper style={{ gridArea: 'surveillance_date' }}>
                                <Label title={`${L('surveillance_date')}`} />
                                <ErrorAndInputWrapper
                                    isMinHeight={false}
                                    errorMsg={errors.notification_date?.type === 'required' ? L('error_required') : ''}
                                >
                                    <Input
                                        placeholder={`${L('surveillance_date')}`}
                                        type="date"
                                        {...register('notification_date')}
                                        style={errors?.notification_date ? { borderColor: '#ff4545' } : {}}
                                    />
                                </ErrorAndInputWrapper>
                            </LabelWrapper>
                            <LabelWrapper style={{ gridArea: 'start_date' }}>
                                <Label title={`${L('start_date')}*`} />
                                <ErrorAndInputWrapper
                                    isMinHeight={false}
                                    errorMsg={errors.start_date?.type === 'required' ? L('error_required') : ''}
                                >
                                    <Input
                                        style={errors?.start_date ? { borderColor: '#ff4545' } : {}}
                                        placeholder={`${L('start_date')}*`}
                                        type="date"
                                        {...register('start_date', { required: true })}
                                    />
                                </ErrorAndInputWrapper>
                            </LabelWrapper>

                            <Label style={{ gridArea: 'contract-length-title ' }} title={L('contract_length')} />

                            <RadioButtonWrapper style={{ gridArea: 'radio-wrapper' }}>
                                <RadioLabelWrapper>
                                    <Controller
                                        name={'activeContractLengthOption'}
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                value="until_further_notice"
                                                type="radio"
                                                checked={activeContractLengthOptionValue === 'until_further_notice'}
                                            />
                                        )}
                                    />

                                    <Label title={L('until_further_notice')} />
                                </RadioLabelWrapper>

                                <RadioLabelWrapper>
                                    <Controller
                                        name={'activeContractLengthOption'}
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                value="months"
                                                type="radio"
                                                checked={activeContractLengthOptionValue === 'months'}
                                            />
                                        )}
                                    />
                                    <Label title={L('number_of_months')} titleVariant="noWhiteSpaceWrap" />
                                </RadioLabelWrapper>

                                {activeContractLengthOptionValue === 'months' && (
                                    <ErrorAndInputWrapper
                                        isMinHeight={false}
                                        errorMsg={
                                            errors.length_months?.type === 'min'
                                                ? L('amount_greater_than_0')
                                                : errors.length_months?.type === 'pattern'
                                                ? errors.length_months.message
                                                : errors.length_months?.type === 'required'
                                                ? L('error_required')
                                                : ''
                                        }
                                    >
                                        <Input
                                            style={errors?.length_months ? { borderColor: '#ff4545' } : {}}
                                            min={0}
                                            placeholder={L('number_of_months')}
                                            type="number"
                                            {...register('length_months', {
                                                required: true,
                                                min: 0,
                                                pattern: {
                                                    value: /^[0-9]+$/,
                                                    message: L('integer_only_error'),
                                                },
                                            })}
                                        />
                                    </ErrorAndInputWrapper>
                                )}
                                <RadioLabelWrapper>
                                    <Controller
                                        name={'activeContractLengthOption'}
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="radio"
                                                value={'end_date'}
                                                checked={activeContractLengthOptionValue === 'end_date'}
                                            />
                                        )}
                                    />
                                    <Label title={L('end_date')} />
                                </RadioLabelWrapper>

                                {activeContractLengthOptionValue === 'end_date' && (
                                    <ErrorAndInputWrapper
                                        isMinHeight={false}
                                        errorMsg={errors.end_date?.type === 'required' ? L('error_required') : ''}
                                    >
                                        <Input
                                            style={errors?.end_date ? { borderColor: '#ff4545' } : {}}
                                            placeholder={L('end_date')}
                                            type="date"
                                            {...register('end_date', { required: true })}
                                        />
                                    </ErrorAndInputWrapper>
                                )}
                            </RadioButtonWrapper>
                            <ButtonWrapper style={{ gridArea: 'buttons' }}>
                                <Button variant="selectedMenu" type="submit">
                                    {L('save')}
                                </Button>
                            </ButtonWrapper>
                        </GridContainer>
                    </form>
                }
            />
        </>
    );
};

export default AddContract;
