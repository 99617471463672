import { ProfileDetail } from '@app/api/models/Profile';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import StyledCalendar from '../../../components/Calendar/Calendar';
import { Card } from '../../../components/Layout/Card';
import { Loading } from '../../../components/Loading/Loading';
import { colors } from '../../../components/Shared/Style/colors';
import { margin } from '../../../components/Shared/Style/margin';
import { fontSize } from '../../../components/Shared/Style/typography';
import useGetBookingResourceById from '../hooks/useGetBookingResourceById';
import useGetBookingsByResourceId from '../hooks/useGetBookingsByResourceId';
import dayjs from 'dayjs';
import { useGetSignedUrlById } from '../../../hooks/useGetSignedUrlById';
import { BookedSlot, BookingFilter } from '@app/api/models/Bookings';
import { L } from '../../../lib/i18n';

const Calendar = styled(StyledCalendar)({
    '.react-calendar__month-view__days': {
        display: 'grid !important',
        gridTemplateColumns: 'repeat(7, 1fr)',
        gap: '3px',
    },

    '.react-calendar__month-view__days__day': {
        maxWidth: '100% !important',
        aspectRatio: '1',
        borderRadius: '50%',
        padding: '0px',
        margin: '0px',
    },

    '.react-calendar__month-view__days__day.blocked': {
        maxWidth: '100% !important',
        border: 'none',
        // borderRadius: '0',
        padding: '0px',
        margin: '0px',
    },

    ['@supports not (aspect-ratio: 1)']: {
        '.react-calendar__month-view__days__day': {
            maxWidth: '100% !important',
            minHeight: '43px',
            borderRadius: '50%',
            padding: '0px',
            margin: '0px',
        },
    },
});

const AboveContent = styled.div({
    margin: '0px 16px 8px 16px',
    alignSelf: 'start',
    display: 'flex',
    columnGap: '15px',
    rowGap: '10px',
    flexWrap: 'wrap',
});

const Legend = styled.div({
    borderRadius: '50%',
    alignSelf: 'center',
    display: 'flex',
    gap: '5px',
});

const LegendText = styled.span({
    alignSelf: 'center',
    justifySelf: 'left',
    fontSize: fontSize.micro,
});

const LegendCircle = styled.div({
    width: '15px',
    height: '15px',
    borderRadius: '50%',
});

type Props = {
    selectedUser?: ProfileDetail;
    filter: Partial<BookingFilter>;
    setFilter: React.Dispatch<React.SetStateAction<Partial<BookingFilter>>>;
};

const filterFunction = (bookings: BookedSlot[]) => {
    const bookingsCopy = [...bookings];
    return {
        user: (user_id: number) => {
            return bookingsCopy.filter((booking) => booking.user_id === user_id);
        },
        from_date: (date: string) => {
            return bookingsCopy.filter((booking) => dayjs(booking.booking_date).isSameOrAfter(date));
        },
        end_date: (date: string) => {
            return bookingsCopy.filter((booking) => dayjs(booking.booking_date).isSameOrBefore(date));
        },
        property: (propertyId: number) => {
            return bookingsCopy.filter((booking) => booking.propertyId === propertyId);
        },
    };
};

const DayBookings = ({ filter, setFilter }: Props) => {
    const { bookingId } = useParams<{ bookingId: string }>();

    const { data: bookingResource, status } = useGetBookingResourceById(+bookingId);
    const { data: documents, status: documentStatus } = useGetSignedUrlById(bookingId);

    const { data: bookings, status: bookingStatus } = useGetBookingsByResourceId(+bookingId, false);

    const [date, setDate] = useState<Date | undefined>(undefined);

    const clearDates = () => {
        setDate(undefined);
        const active = document.querySelector('.react-calendar__tile--active');
        if (active) {
            active.classList.remove('react-calendar__tile--active');
        }
    };

    useEffect(() => {
        if (!filter.booking_date_start) clearDates();
    }, [filter.booking_date_start]);

    if (status === 'loading' || documentStatus === 'loading' || bookingStatus === 'loading') return <Loading />;
    if (!bookingResource || !documents) return <div>{L('could_not_find_calendar')}</div>;
    if (!bookings) return <div>{L('could_not_get_bookings')}</div>;

    return (
        <Card background="white" padding={`${margin.m}px`}>
            <AboveContent>
                <Legend>
                    <LegendCircle style={{ backgroundColor: colors.tabActive }} />
                    <LegendText>{L('bookings_in_filter')}</LegendText>
                </Legend>
                <Legend>
                    <LegendCircle style={{ border: 'solid 2px #33525E' }} />
                    <LegendText>{L('selected_day')}</LegendText>
                </Legend>
                <Legend>
                    <LegendCircle style={{ backgroundColor: colors.black30 }} />
                    <LegendText>{L('bookings_outof_filter')} </LegendText>
                </Legend>
            </AboveContent>
            <Calendar
                onActiveStartDateChange={clearDates}
                tileClassName={({ date }: { date: Date }) => {
                    const d = date.getDay();

                    const booked = bookings?.find(
                        (item) => new Date(item.booking_date).toDateString() === new Date(date).toDateString()
                    );

                    let filteredBookings = bookings;

                    if (filter.user_id) {
                        filteredBookings = filterFunction(filteredBookings).user(+filter.user_id);
                    }
                    if (filter.booking_date_start) {
                        filteredBookings = filterFunction(filteredBookings).from_date(filter.booking_date_start);
                    }
                    if (filter.booking_date_end) {
                        filteredBookings = filterFunction(filteredBookings).end_date(filter.booking_date_end);
                    }
                    if (filter.property_id) {
                        filteredBookings = filterFunction(filteredBookings).property(+filter.property_id);
                    }

                    const myBooked = filteredBookings?.find(
                        (item) => new Date(item.booking_date).toDateString() === new Date(date).toDateString()
                    );

                    if (myBooked) return 'myBookings';
                    if (booked && d === 5) return 'booked-saturday-filter';
                    else if (booked) return 'booked-filter';
                    else if (d === 5 && date.valueOf() > Date.now()) return 'saturday';
                    else return '';
                }}
                onChange={(date: Date) => {
                    setDate(date);
                    setFilter((prev) => {
                        return { ...prev, booking_date_start: dayjs(date).format('YYYY-MM-DD') };
                    });
                }}
                value={date}
            />
        </Card>
    );
};

export default DayBookings;
