import React, { ReactNode, useMemo } from 'react';
import { Column, useTable, useGlobalFilter, useSortBy, useRowSelect, useFlexLayout, Row } from 'react-table';
import styled from '@emotion/styled';
import SortArrows from '../../../components/Table/sortArrows/SortArrows';
import { colors } from '../../../components/Shared/Style/colors';
import { L } from '../../../lib/i18n';
import GlobalFilter from '../../../components/Table/filterComponents/GlobalFilter';
import { Link } from 'react-router-dom';
import EmptyState from '../../../components/EmptyState/EmptyState';

const Container = styled.div({
    width: '100%',
    marginBottom: '2rem',
});
const StyledTabel = styled.div({
    '.thead': {
        '.tr': {
            borderBottom: `1px solid ${colors.black50}`,
            paddingBottom: '10px',
        },
    },
    '.tbody': {
        '.tr': {
            borderBottom: `1px solid ${colors.black50}`,
        },
    },
});

const FlexContainer = styled.div({
    display: 'flex',
    gap: '',
    alignItems: 'center',
});

const CellElement = styled.div({
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
});

const NoResult = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
});

const FilterWrapper = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '22px',

    '&.filter': {
        justifyContent: 'space-between',
    },
});

const getStyles = (
    props,
    style: {
        justifyContent?: string;
        alignItems?: string;
        display?: string;
    }
) => [
    props,
    {
        style: {
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            display: 'flex',
            ...style,
        },
    },
];
const headerProps = (props, { column }) => getStyles(props, column.style);

const cellProps = (props, { cell }) => getStyles(props, cell.column.style);

type Props<TData extends Record<string, unknown>> = {
    Data: TData[];
    Columns: Column<TData>[];
    filterArea?: ReactNode;
    edit?: boolean;
    search?: boolean;
};

const ArchiveTable = <TData extends Record<string, unknown>>({
    Data,
    Columns,
    filterArea,
    search = true,
}: Props<TData>) => {
    const columns = useMemo<typeof Columns>(() => Columns, [Data, Columns]);
    const data = useMemo(() => (Data ? Data : []), [Data]);
    const tableInstance = useTable<TData>(
        {
            columns,
            data,
            initialState: {
                hiddenColumns: ['year'],
            },
        },
        useGlobalFilter,
        useSortBy,
        useRowSelect,
        useFlexLayout
    );

    const { getTableProps, rows, headerGroups, prepareRow, state, setGlobalFilter } = tableInstance;

    const { globalFilter } = state;

    return (
        <Container>
            {search && (
                <FilterWrapper className={filterArea ? 'filter' : ''}>
                    {filterArea}
                    <GlobalFilter
                        placeholder={L('search_contract')}
                        filter={globalFilter}
                        setFilter={setGlobalFilter}
                    />
                </FilterWrapper>
            )}
            <StyledTabel {...getTableProps()}>
                <div className="thead">
                    {headerGroups.map((headerGroup) => {
                        return (
                            <div
                                {...headerGroup.getHeaderGroupProps({
                                    // style: { paddingRight: '15px' },
                                })}
                                className="tr"
                                key={headerGroup.getFooterGroupProps().key}
                            >
                                {headerGroup.headers.map((column) => (
                                    <div
                                        {...column.getHeaderProps(column.getSortByToggleProps(headerProps))}
                                        className="th"
                                        key={column.getHeaderProps().key}
                                    >
                                        <FlexContainer>
                                            {column.render('Header')}
                                            {column.canSort && (
                                                <SortArrows
                                                    activeSort={
                                                        column.isSorted
                                                            ? column.isSortedDesc
                                                                ? 'desc'
                                                                : 'asc'
                                                            : undefined
                                                    }
                                                />
                                            )}
                                        </FlexContainer>
                                    </div>
                                ))}
                            </div>
                        );
                    })}
                </div>
                <div className="tbody">
                    {rows.map((row) => {
                        prepareRow(row);

                        return (
                            <Link
                                {...row.getRowProps()}
                                className="tr"
                                key={row.getRowProps().key}
                                to={`/contracts/archive/${row?.original?.id}`}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <div
                                            {...cell.getCellProps(cellProps)}
                                            className="td"
                                            key={cell.getCellProps().key}
                                        >
                                            <CellElement>{cell.render('Cell')}</CellElement>
                                        </div>
                                    );
                                })}
                            </Link>
                        );
                    })}
                </div>
            </StyledTabel>
            {!rows.length && !globalFilter ? (
                <NoResult>
                    <EmptyState>
                        <EmptyState.Title>{L('no_archived_contracts')}</EmptyState.Title>
                        <EmptyState.Description>{L('no_archived_contracts_description')}</EmptyState.Description>
                    </EmptyState>
                </NoResult>
            ) : null}
            {!rows.length && globalFilter ? (
                <NoResult>
                    <EmptyState>
                        <EmptyState.Title>{L('no_contracts_found')}</EmptyState.Title>
                        <EmptyState.Description>{L('no_contracts_found_description')}</EmptyState.Description>
                    </EmptyState>
                </NoResult>
            ) : null}
        </Container>
    );
};

export default ArchiveTable;
