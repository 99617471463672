import { useQuery } from 'react-query';
import PublicSiteApi from '@app/api/public/PublicSiteApi';

export const themesArr = async () => {
    return await PublicSiteApi.listThemes();
};

const useGetThemes = () => {
    return useQuery('getThemes', themesArr);
};

export default useGetThemes;
