import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../../components/Shared/Style/colors';
import styled from '@emotion/styled';
import { CommunityMailFolderType, mail_messages } from '@app/api/models/Mail/MailModels';
import MailRowDropdown from './MailRowDropdown';
import { style } from '../../../components/Shared/Style';
import dayjs from 'dayjs';
import sv from 'dayjs/locale/sv';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import { decodeHTML } from 'entities';
import { L } from '../../../lib/i18n';
dayjs.locale(sv);
dayjs.extend(relativeTime);
dayjs.extend(isToday);

const Paragraph = styled.p(() => {
    return {
        padding: '0px',
        textDecoration: 'none',
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        '&:hover': {
            cursor: 'pointer',
        },
    };
});

const Item = styled.li({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '5%',
    borderBottom: `1px solid ${colors.inputBorder}`,
    borderRadius: 5,
    cursor: 'pointer',
    padding: '0.5rem 1rem',
    [':hover']: {
        border: `1px solid ${colors.inputBorder}`,
        boxShadow: '0px 4px 15px -10px rgba(0,0,0,0.61)',
    },
});

const ItemCol = styled.div({
    padding: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
});

const GridContainer = styled.div({
    display: 'grid',
});

const ItemColMain = styled.div({
    fontSize: style.fontSize.seta,
    width: 225,
    padding: '0 0.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    overflow: 'hidden',
    gap: '0.25rem',
    ['p']: {
        margin: 0,
    },
});

const ItemColSubject = styled(ItemColMain)({
    fontSize: style.fontSize.milli,
});

const ItemColMessage = styled.div({
    fontSize: style.fontSize.milli,
    padding: '0 0.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    overflow: 'hidden',
    gap: '0.25rem',
    ['p']: {
        margin: 0,
    },
});

const ItemP = styled.div({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
});

const BoldSpan = styled.span({
    fontWeight: 'bold',
});

const StyledH2 = styled.h2({
    fontSize: style.fontSize.seta,
    margin: 0,
    padding: 0,
});

const Nowrap = styled.p({
    whiteSpace: 'nowrap',
    fontSize: style.fontSize.milli,
});

const Mail = styled.p({
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const RowContainer = styled.div({
    display: 'flex',
});

const BoxContainer = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 5,
});

const Box = styled.div({
    width: 12,
    height: 12,
});

const getPureText = (mail: string) => {
    const regex = /(<([^>]+)>)/gi;
    return mail.replace(regex, '');
};

export default function MailRow({
    mail,
    folder,
    folders,
    handleOnCreate,
}: {
    mail: mail_messages;
    folder: CommunityMailFolderType;
    folders: CommunityMailFolderType[];
    handleOnCreate: (mailId: number, folderName: string) => void;
}) {
    const time = (timestamp: string) => {
        if (dayjs(timestamp).isValid()) {
            if (dayjs(timestamp).isToday()) {
                //Today
                return L('today') + ' ' + dayjs(timestamp).format('HH:mm');
            }
            //Not today
            return dayjs(timestamp).format('YYYY-MM-DD');
        }
        return '';
    };

    return (
        <RowContainer>
            <BoxContainer>
                {mail.read_at ? <Box /> : <Box style={{ backgroundColor: 'red', borderRadius: '50%' }} />}
            </BoxContainer>
            <Link
                to={
                    folder.type === 'draft' && !mail.reply_to_id
                        ? `/mail/new?draftid=${mail.id}`
                        : folder.type === 'draft'
                        ? `/mail/view/${mail.reply_to_id}?draftid=${mail.id}`
                        : `/mail/view/${mail.id}`
                }
                style={{ width: '100%' }}
            >
                <Item className={mail.read_at ? 'read' : 'not-read'}>
                    <GridContainer>
                        <ItemColMain>
                            {folder.type === 'draft' || folder.type === 'sent' ? (
                                !!mail.sent_to &&
                                (mail.sent_to.length > 1 ? (
                                    <Mail>
                                        {mail.sent_to[0]} +{mail.sent_to.length - 1}
                                    </Mail>
                                ) : (
                                    <Mail>{mail.sent_to[0]}</Mail>
                                ))
                            ) : mail.read_at ? (
                                mail.created_by_name ? (
                                    <StyledH2 title={mail.created_by_mail}>{mail.created_by_name}</StyledH2>
                                ) : (
                                    <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        <StyledH2>{mail.created_by_mail}</StyledH2>
                                    </div>
                                )
                            ) : (
                                <>
                                    {mail.created_by_name ? (
                                        <StyledH2 title={mail.created_by_mail}>{mail.created_by_name}</StyledH2>
                                    ) : (
                                        <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            <StyledH2>{mail.created_by_mail}</StyledH2>
                                        </div>
                                    )}
                                </>
                            )}
                        </ItemColMain>
                        <ItemColSubject>
                            <ItemP>
                                {mail.read_at ? (
                                    mail.subject ? (
                                        mail.subject
                                    ) : (
                                        `(${L('no_subject')})`
                                    )
                                ) : (
                                    <BoldSpan>{mail.subject ? mail.subject : `(${L('no_subject')})`}</BoldSpan>
                                )}
                            </ItemP>
                        </ItemColSubject>
                        <ItemColMessage>
                            <Paragraph
                                style={{ color: mail.read_at ? style.colors.textColor1 : style.colors.textColor2 }}
                            >
                                {decodeHTML(getPureText(mail.message))
                                    ? decodeHTML(getPureText(mail.message))
                                    : `${L('no_preview')}`}
                            </Paragraph>
                        </ItemColMessage>
                    </GridContainer>

                    <ItemCol onClick={(e) => e.stopPropagation()}>
                        <Nowrap> {time(mail.created_at)}</Nowrap>
                        <MailRowDropdown
                            mail={mail}
                            folder={folder}
                            folders={folders}
                            handleOnCreate={handleOnCreate}
                        />
                    </ItemCol>
                </Item>
            </Link>
        </RowContainer>
    );
}
