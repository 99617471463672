import { TicketFilter } from '@app/api/models/Tickets';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { colors } from '../../../components/Shared/Style/colors';
import useReactTable from '../../../components/Table/ReactTable/useReactTable';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import { StyleColumn } from '../../../types/ColumnType';
import { useGetTicketArchive } from '../hooks/ticketQueries';
import ArchiveTable from './ArchiveTable';
import TicketFilters from './TicketFilters';

type ArchiveTicketdata = NonNullable<ReturnType<typeof useGetTicketArchive>['data']>[0];

const StyledTicketFilters = styled(TicketFilters)({
    margin: '0px',
});

const CellContainer = styled.div({
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maring: 'auto',
    padding: '1rem 0px',
    maxWidth: '100%',
    overflow: 'hidden',
    fontSize: '0.75rem',
    gap: '0.2rem',
});

const Data = styled.p({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const ColorContainer = styled(CellContainer)(({ boxcolor }: { boxcolor: string }) => {
    return {
        borderLeft: `6px solid ${boxcolor}`,
        paddingLeft: '1rem',
    };
});

const TicketColumns: StyleColumn<ArchiveTicketdata>[] = [
    {
        Header: () => {
            return `${L('title')} & ${L('description')}`;
        },
        accessor: (archiveTicketdata) => {
            return [archiveTicketdata.title, archiveTicketdata.description.plain_text];
        },
        id: 'title',
        width: 150,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const ticket = row.original as ArchiveTicketdata;
            return (
                <ColorContainer boxcolor={colors.alert_medium}>
                    <Data>{ticket.title}</Data>
                    <Data style={{ fontSize: '0.8em' }}>{ticket.description.plain_text}</Data>
                </ColorContainer>
            );
        },
    },
    {
        Header: () => {
            return L('property');
        },
        accessor: 'property_name',
        id: 'property',
        width: 150,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const ticket = row.original as ArchiveTicketdata;
            return (
                <CellContainer>
                    <Data>{ticket.property_name}</Data>
                </CellContainer>
            );
        },
    },
    {
        Header: () => {
            return L('assignee');
        },
        accessor: 'assigned_name',
        id: 'assigned_name',
        width: 150,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const ticket = row.original as ArchiveTicketdata;
            return (
                <CellContainer>
                    <Data>{ticket.assigned_name}</Data>
                </CellContainer>
            );
        },
    },
    {
        Header: () => {
            return L('archived');
        },
        accessor: (archiveTicketdata) => {
            return dayjs(archiveTicketdata.archived_at).valueOf();
        },
        id: 'archived_at',
        width: 150,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const ticket = row.original as ArchiveTicketdata;
            return (
                <CellContainer>
                    <Data>{dayjs(ticket.archived_at).format('ddd DD MMM YYYY')}</Data>
                </CellContainer>
            );
        },
    },
];

const TicketsArchive = () => {
    const [filter, setFilter] = useState<TicketFilter>({
        assignee: undefined,
        property: undefined,
    });
    const { data: archivedTickets } = useGetTicketArchive(filter);

    const handleFilter = <T extends keyof TicketFilter>(key: T, value: TicketFilter[T]) => {
        setFilter({ ...filter, [key]: value });
    };

    const useTableProps = useReactTable({ data: archivedTickets, columns: TicketColumns });
    return (
        <CardWithDivider
            topArea={<CardTitle>{L('all_archived_tickets')}</CardTitle>}
            mainArea={
                <ArchiveTable
                    useTable={useTableProps}
                    filterArea={
                        <StyledTicketFilters
                            tickets={
                                archivedTickets?.map((item) => ({
                                    ...item,
                                })) || []
                            }
                            filter={filter}
                            handleFilter={handleFilter}
                        />
                    }
                />
            }
        />
    );
};

export default TicketsArchive;
