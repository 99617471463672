import { BookedSlot, Booking } from '@app/api/models/Bookings';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { colors } from '../../../../components/Shared/Style/colors';
import useGetUserById from '../../../../lib/hooks/useGetUserById';

const StyledCellItem = styled.button(({ booked }: { booked?: boolean }) => {
    return {
        borderRadius: '4px',
        backgroundColor: booked ? colors.tabActive : colors.white,
        color: booked ? colors.white : colors.black,
        border: booked ? `1px solid ${colors.tabActive}` : `1px solid ${colors.timeSlotBorder}`,
        textAlign: 'center',
        minHeight: '35px',
        display: 'grid',
        height: '100%',
        width: '100%',
        placeItems: 'center',
        cursor: 'pointer',
        '&:disabled': {
            cursor: 'default',
            color: colors.black80,
            border: `1px solid ${colors.timeSlotBorder}`,
            backgroundColor: colors.timeSlotBorder,
        },
    };
});

const ScrollToTag = styled.a({
    height: ' 0px',
    width: '0px',
    opacity: 0,
    cursor: 'none',
});

type Props = {
    booked?: boolean;
    time: string;
    bookedSlot?: BookedSlot | Booking;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const TimeSlotCell: FC<Props> = ({ booked, time, bookedSlot, ...rest }) => {
    const { data: user } = useGetUserById(bookedSlot?.user_id);
    return (
        <StyledCellItem booked={booked} {...rest}>
            <ScrollToTag href={time} />
            {time}
            {user && <span>{user.name || user.email}</span>}
        </StyledCellItem>
    );
};

export default TimeSlotCell;
