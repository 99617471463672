import { L } from '../../../lib/i18n';
import * as React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import ModalProvider from '../../../components/Modal/Context/ModalContext';
import PropertiesListPage from '../ListPage/PropertiesListPage';
import BigNavItem from '../../../components/BigTabItem/BigTabListItemNav';
import PageTitle from '../../../components/typography/PageTitle';
import BigTabNav from '../../../components/BigTabItem/BigTabNav';
import { PropertiesDetailPage } from '../DetailPage/PropertiesDetailPage';
import { PropertiesUserListPage } from '../DetailPage/PropertyUserListPage/PropertiesUserListPage';
import { useGetProperty } from '../hooks/propertyQueries';

export const PropertiesPage = () => {
    const { id } = useParams<{ id: string }>();
    const { data: property } = useGetProperty(+id);
    return (
        <>
            <Switch>
                <Route exact path={'/properties'}>
                    <PageTitle>{L('properties')}</PageTitle>
                </Route>
                <Route exact path={'/properties/:id'}>
                    <PageTitle>{L('properties')}</PageTitle>

                    <BigTabNav>
                        <BigNavItem exact to={'/properties'}>
                            {L('all_properties')}
                        </BigNavItem>
                        <div style={{ borderRight: 'solid 1px gray' }} />
                        <BigNavItem exact to={`/properties/${id}`}>
                            {property?.name ? property?.name : L('new_property')}
                        </BigNavItem>
                        <BigNavItem exact to={`/properties/${id}/members`}>
                            {L('members_tab')}
                        </BigNavItem>
                    </BigTabNav>
                </Route>
                <Route exact path={'/properties/:id/members'}>
                    <PageTitle>{L('properties')}</PageTitle>
                    <BigTabNav>
                        <BigNavItem exact to={'/properties'}>
                            {L('all_properties')}
                        </BigNavItem>
                        <div style={{ borderRight: 'solid 1px gray' }} />
                        <BigNavItem exact to={`/properties/${id}`}>
                            {property?.name ? property?.name : L('new_property')}
                        </BigNavItem>
                        <BigNavItem exact to={`/properties/${id}/members`}>
                            {L('members_tab')}
                        </BigNavItem>
                    </BigTabNav>
                </Route>
            </Switch>
            <Switch>
                <Route exact path={'/properties'}>
                    <BigTabNav>
                        <BigNavItem exact to={'/properties'}>
                            {L('all_properties')}
                        </BigNavItem>
                    </BigTabNav>
                    <PropertiesListPage />
                </Route>

                {/** Temporärt avstängd */}
                <Route exact path={'/properties/new'}>
                    <></>
                </Route>

                <Route exact path={'/properties/:id'}>
                    <ModalProvider>
                        <PropertiesDetailPage />
                    </ModalProvider>
                </Route>
                <Route exact path={'/properties/:id/members'}>
                    <ModalProvider>
                        <PropertiesUserListPage />
                    </ModalProvider>
                </Route>
            </Switch>
        </>
    );
};

export default PropertiesPage;
