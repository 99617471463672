import { ListingContact } from '@app/api/models/Contacts';
import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { colors } from '../../../components/Shared/Style/colors';

const ListItem = styled.li({
    display: 'grid',
    width: '100%',
    gridTemplateAreas: `"name action"
                        "mail action"`,
    gridAutoColumns: 'minmax(0, 1fr)',
    gridAutoFlow: 'column',
    padding: '0.5rem',
    borderBottom: `1px solid ${colors.divider}`,
    [':hover']: {
        cursor: 'pointer',
        backgroundColor: colors.primaryLight,
    },
});
const Name = styled.div({
    gridArea: 'name',
    padding: '0.5rem',
    fontWeight: 'bolder',
});

const Mail = styled.div({
    gridArea: 'mail',
    padding: '0.5rem',
});

const Action = styled.div({
    gridArea: 'action',
    padding: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export default function ContactItem({
    contact,
    selected,
    toggleSelected,
}: {
    contact: ListingContact;
    selected: boolean;
    toggleSelected: () => void;
}) {
    return (
        <ListItem onClick={toggleSelected}>
            <Name>{contact.name}</Name>
            <Mail>{contact.email}</Mail>
            <Action>
                <Checkbox checked={!!selected} onChange={() => toggleSelected()} />
            </Action>
        </ListItem>
    );
}
