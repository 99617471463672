import React, { ChangeEvent, useRef } from 'react';
import { small } from '../../../lib/imageSize';
import { desktop } from '../../../components/MediaQuery/breakpoints';
import useGetProfile from '../../../hooks/useGetProfile';
import useUploadProfileImage from '../hooks/useUploadProfileImage';
import useGetSignedUrlById from '../../../hooks/useGetSignedUrlById';
import styled from '@emotion/styled';
import { Avatar } from '@ourliving/ourliving-ui';

const UploadArea = styled.form({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '80px',
    [`@media screen and (min-width: ${desktop}px)`]: {
        marginTop: '2rem',
    },
});

const ClickArea = styled.label({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
});
function ProfileImage() {
    const profileQuery = useGetProfile();
    const inputFileRef = useRef<HTMLInputElement>(null);

    const { data: signedUrl } = useGetSignedUrlById(
        profileQuery?.data?.profile_image_data?.document_data?.original?.id,
        small
    );
    const { mutate: uploadProfileImage, status } = useUploadProfileImage();

    const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;
        uploadProfileImage({ file });
    };

    return (
        <UploadArea>
            <ClickArea htmlFor={'profileImage'}>
                <Avatar
                    src={signedUrl && signedUrl}
                    status={status}
                    name={profileQuery.data?.name || profileQuery.data?.email}
                />
            </ClickArea>
            <input
                ref={inputFileRef}
                style={{ display: 'none' }}
                type="file"
                id="profileImage"
                onChange={(e) => changeHandler(e)}
            />
        </UploadArea>
    );
}

export default ProfileImage;
