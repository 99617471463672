import ContentInformationApi from '@app/api/public/ContentInformationApi';
import { useQuery } from 'react-query';

export const documentGroupsQueryKeys = {
    all: ['documentGroups'],
    sortableById: (sortableId?: number) => [...documentGroupsQueryKeys.all, sortableId] as const,
};

const getDocumentGroups = async () => {
    return await ContentInformationApi.listDocumentGroups();
};

const useGetDocumentGroups = () => {
    return useQuery(documentGroupsQueryKeys.sortableById(), () => getDocumentGroups());
};

export { useGetDocumentGroups as default };
