import BoardContentEventApi from '@app/api/public/BoardContentEventApi';
import { useQuery } from 'react-query';

export const memberEventQueryKeys = {
    all: [{ scope: 'memberEvent' }] as const,
    lists: () => [{ ...memberEventQueryKeys.all[0], entity: 'list' }] as const,
    list: (type: 'draft' | 'published', page: number) => [{ ...memberEventQueryKeys.lists()[0], type, page }] as const,
    infiniteLists: () => [{ ...memberEventQueryKeys.all[0], entity: 'infiniteList' }] as const,
    infiniteList: (type: 'draft' | 'published', page: number) =>
        [{ ...memberEventQueryKeys.infiniteLists()[0], type, page }] as const,
    details: () => [{ ...memberEventQueryKeys.all[0], entity: 'detail' }] as const,
    detail: (id: number) => [{ ...memberEventQueryKeys.details()[0], id }] as const,
    participants: () => [{ ...memberEventQueryKeys.all[0], entity: 'participant' }] as const,
    participant: (id: number) => [{ ...memberEventQueryKeys.participants()[0], id }] as const,
    documents: () => [{ ...memberEventQueryKeys.all[0], entity: 'document' }] as const,
    document: (id: number) => [{ ...memberEventQueryKeys.documents()[0], id }] as const,
};

const getPageListItems = async (type: 'draft' | 'published', page: number) => {
    return await BoardContentEventApi.list(type, page);
};

const useGetContentEventList = (type: 'draft' | 'published', page: number) => {
    return useQuery({
        queryFn: ({ queryKey }) => {
            const { type } = queryKey[0];
            return getPageListItems(type, page);
        },
        queryKey: memberEventQueryKeys.list(type, page),
    });
};

const getContentEvent = (id: number) => {
    return BoardContentEventApi.get(id);
};

const useGetMemberEventById = (id: number) => {
    return useQuery({
        queryFn: ({ queryKey }) => getContentEvent(queryKey[0].id),
        queryKey: memberEventQueryKeys.detail(id),
        enabled: !!id,
    });
};

const useGetEventParticipantsById = (id: number) => {
    return useQuery({
        queryFn: ({ queryKey }) => BoardContentEventApi.listParticipants(queryKey[0].id),
        queryKey: memberEventQueryKeys.participant(id),
        enabled: !!id,
    });
};

export { useGetContentEventList, useGetMemberEventById, useGetEventParticipantsById };
