import BookingsApi from '@app/api/public/BookingsApi';
import { useQuery } from 'react-query';

export const useGetBookingsByResourceIdQueryKey = 'getBookingsByResourceId';

const useGetBookingsByResourceId = (bookingResourceId?: number, fromNow = true) => {
    return useQuery(
        [useGetBookingsByResourceIdQueryKey, bookingResourceId],
        async () => {
            if (!bookingResourceId) return [];
            return BookingsApi.listBookingsFromNow(bookingResourceId, fromNow);
        },
        {
            enabled: !!bookingResourceId,
        }
    );
};

export default useGetBookingsByResourceId;
