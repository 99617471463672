import { Contact } from '@app/api/models/Contacts';
import styled from '@emotion/styled';
import React, { ChangeEvent, useRef, useState } from 'react';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import { desktop } from '../../../components/MediaQuery/breakpoints';
import useGetSignedUrlById from '../../../hooks/useGetSignedUrlById';
import { L } from '../../../lib/i18n';
import { small } from '../../../lib/imageSize';
import { Avatar } from '@ourliving/ourliving-ui';
type Props = {
    contact: Partial<Contact>;
    setContact: React.Dispatch<React.SetStateAction<Partial<Contact>>>;
    setFile: React.Dispatch<React.SetStateAction<File>>;
};

const Inputs = styled.div({
    width: '60%',
});

const UploadArea = styled.form({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '80px',
    [`@media screen and (min-width: ${desktop}px)`]: {
        marginTop: '2rem',
    },
});

const ClickArea = styled.label({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
});

const ContactsEditArea = ({ contact, setContact, setFile }: Props) => {
    const inputFileRef = useRef<HTMLInputElement>(null);

    const { data: img } = useGetSignedUrlById(contact.profile_image_data?.original?.id, small);

    const [localImage, setlocalImage] = useState('');
    const onChange = (evt: any) => {
        setContact({ ...contact, [evt.target.name]: evt.target.value });
    };
    const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;
        const myImg = URL.createObjectURL(file);
        setlocalImage(myImg);
        setFile(file);
        setContact({ ...contact, [e.target.name]: file });
    };

    return (
        <Inputs>
            <Label title={L('profile_image')}>
                <UploadArea>
                    <ClickArea htmlFor={'contactImage'}>
                        <Avatar src={localImage ? localImage : img} name={contact?.name || contact?.email} />
                    </ClickArea>
                    <input
                        ref={inputFileRef}
                        style={{ display: 'none' }}
                        type="file"
                        id="contactImage"
                        name="profile_image_data"
                        onChange={(e) => changeHandler(e)}
                    />
                </UploadArea>
            </Label>
            <Label title={L('name')}>
                <Input placeholder={L('name')} name="name" value={contact.name || ''} onChange={onChange} />
            </Label>
            <Label title={L('contact_company')}>
                <Input
                    placeholder={L('contact_company')}
                    name="company"
                    value={contact.company || ''}
                    onChange={onChange}
                />
            </Label>
            <Label title={L('contact_role')}>
                <Input placeholder={L('contact_role')} name="title" value={contact.title || ''} onChange={onChange} />
            </Label>
            <Label title={L('phone_number')}>
                <Input
                    placeholder={L('phone_number')}
                    name="phone_number"
                    value={contact.phone_number || ''}
                    onChange={onChange}
                />
            </Label>
            <Label title={L('contact_mail')}>
                <Input
                    placeholder={L('contact_mail')}
                    name="email"
                    type="email"
                    value={contact.email || ''}
                    onChange={onChange}
                />
            </Label>

            <Label title={L('contact_city')}>
                <Input placeholder={L('contact_city')} name="city" value={contact.city || ''} onChange={onChange} />
            </Label>
            <Label title={L('contact_extra_information')}>
                <Input
                    placeholder={L('contact_extra_information')}
                    name="info"
                    value={contact.info || ''}
                    onChange={onChange}
                />
            </Label>
        </Inputs>
    );
};

export default ContactsEditArea;
