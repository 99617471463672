Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["list"] = createRpcProxy("BoardContentInformationApi", "list", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["get"] = createRpcProxy("BoardContentInformationApi", "get", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["remove"] = createRpcProxy("BoardContentInformationApi", "remove", {
  "urlPrefix": "/rpc"
});
o["updateDocumentName"] = createRpcProxy("BoardContentInformationApi", "updateDocumentName", {
  "urlPrefix": "/rpc"
});
o["updateDocumentGroup"] = createRpcProxy("BoardContentInformationApi", "updateDocumentGroup", {
  "urlPrefix": "/rpc"
});
o["removeDocumentgroup"] = createRpcProxy("BoardContentInformationApi", "removeDocumentgroup", {
  "urlPrefix": "/rpc"
});
o["deleteDocumentFromContentInformation"] = createRpcProxy("BoardContentInformationApi", "deleteDocumentFromContentInformation", {
  "urlPrefix": "/rpc"
});
o["saveDocuementGroup"] = createRpcProxy("BoardContentInformationApi", "saveDocuementGroup", {
  "urlPrefix": "/rpc"
});
o["listDocumentGroups"] = createRpcProxy("BoardContentInformationApi", "listDocumentGroups", {
  "urlPrefix": "/rpc"
});
o["saveDocument"] = createRpcProxy("BoardContentInformationApi", "saveDocument", {
  "urlPrefix": "/rpc"
});
o["listDocuments"] = createRpcProxy("BoardContentInformationApi", "listDocuments", {
  "urlPrefix": "/rpc"
});
o["resendNotifications"] = createRpcProxy("BoardContentInformationApi", "resendNotifications", {
  "urlPrefix": "/rpc"
});
o["setSortOrder"] = createRpcProxy("BoardContentInformationApi", "setSortOrder", {
  "urlPrefix": "/rpc"
});
o["createDraft"] = createRpcProxy("BoardContentInformationApi", "createDraft", {
  "urlPrefix": "/rpc"
});
o["saveDraft"] = createRpcProxy("BoardContentInformationApi", "saveDraft", {
  "urlPrefix": "/rpc"
});
o["createInformationAndPublish"] = createRpcProxy("BoardContentInformationApi", "createInformationAndPublish", {
  "urlPrefix": "/rpc"
});
o["saveAndPublish"] = createRpcProxy("BoardContentInformationApi", "saveAndPublish", {
  "urlPrefix": "/rpc"
});
o["editPublishedInformation"] = createRpcProxy("BoardContentInformationApi", "editPublishedInformation", {
  "urlPrefix": "/rpc"
});
o["publishInformation"] = createRpcProxy("BoardContentInformationApi", "publishInformation", {
  "urlPrefix": "/rpc"
});
o["setInformationAsDraft"] = createRpcProxy("BoardContentInformationApi", "setInformationAsDraft", {
  "urlPrefix": "/rpc"
});
o["removeCoverImage"] = createRpcProxy("BoardContentInformationApi", "removeCoverImage", {
  "urlPrefix": "/rpc"
});