import React from 'react';
import { style } from '../../components/Shared/Style';
import { ProfileSettings } from './Components/ProfileSettings';
import ProfileImage from './Components/ProfileImage';
import styled from '../../components/lib/styled';

const ProfileInfo = styled.div({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: `${style.margin.m}px ${style.margin.m}px ${style.margin.l}px ${style.margin.m}px`,
});

export const ProfileSettingsPage = () => {
    return (
        <>
            <ProfileImage />
            <ProfileInfo>
                <ProfileSettings />
            </ProfileInfo>
        </>
    );
};
