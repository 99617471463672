import * as React from 'react';
import { L } from '../../lib/i18n';
import PageTitle from '../../components/typography/PageTitle';
import { Redirect, Route, Switch } from 'react-router-dom';
import { MemberImportPage } from './ImportMembersPage/ImportMembersPage';
import BigTabNav from '../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../components/BigTabItem/BigTabListItemNav';
import TicketSettingsPage from './TicketSettingsPage/TicketSettingsPage';
import { SettingsDocumentgroups } from '../documents/DocumentsGroups/SettingsDocumentgroups';

export const SettingPage = () => {
    return (
        <>
            <PageTitle>{L('settings')}</PageTitle>

            <BigTabNav>
                <BigNavItem to={'/settings/import'}> {L('import_member')}</BigNavItem>
                <BigNavItem to={'/settings/ticket'}> {L('ticket_setting')}</BigNavItem>
                <BigNavItem to={'/settings/document-groups'}> {L('doocument_groups')}</BigNavItem>
            </BigTabNav>
            <Switch>
                <Route path={'/settings/ticket'}>
                    <TicketSettingsPage />
                </Route>
                <Route path={'/settings/import'}>
                    <MemberImportPage />
                </Route>
                <Route path={'/settings/document-groups'}>
                    <SettingsDocumentgroups />
                </Route>
                <Route exact path={'/settings'}>
                    <Redirect to="/settings/import" />
                </Route>
            </Switch>
        </>
    );
};
