import React, { useEffect, useRef, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Close from '../../../SliderMenu/Close/Close';
import styled from '@emotion/styled';
import { L } from '../../../../lib/i18n';
import { animated, useSpring } from '@react-spring/web';
import ArrowEnlarger from '../../../../components/Icon/arrows-maximize-2-glyph-16.svg';

type Props = {
    url: string;
    id: number;
    index: number;
    remove?: (value: number) => void;
};

const StyledClose = styled(Close)({
    fontWeight: 80,
    fontSize: '1.75rem',
    right: 5,
    top: 5,
    zIndex: 9999,
    backgroundColor: 'transparent',
});

const StyledConfirm = styled.button({
    fontWeight: 80,
    fontSize: '1rem',
    border: 'none',
    textAlign: 'center',
    pointerEvents: 'all',
    backgroundColor: 'transparent',
    padding: '5px 6px',
    cursor: 'pointer',
    color: 'white',
    zIndex: 9999,
});

const Photo = styled.div(({ url }: { url: string }) => ({
    transformOrigin: '0 0',
    borderRadius: '8px',
    backgroundImage: `url("${url}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'grey',
    cursor: 'pointer',
    paddingBottom: '100%',
    position: 'relative',
    '&:focus-visible': {
        outline: 'none',
    },
}));

const StyledContainer = animated(
    styled.div({
        position: 'absolute',
        right: 5,
        top: 5,
        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        borderRadius: '25px',
    })
);

const StyledEnlarger = styled.div({
    position: 'absolute',
    right: 5,
    bottom: 5,
    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    borderRadius: '25px',
    width: '32px',
    height: '32px',
    display: 'grid',
});

const StyledDialog = styled.dialog({
    border: '0px',
    width: '1000px',
    height: '750px',
    maxHeight: '750px',
    backgroundColor: 'black',
    padding: '0px',
});

const StyledDialogContainer = styled.div({
    height: '100%',
    display: 'grid',
    maxHeight: 'inherit',
});

const StyledImage = styled.img({
    placeSelf: 'center',
    maxWidth: '100%',
    maxHeight: 'inherit',
});

const StyledEnlargerArrow = styled(ArrowEnlarger)({
    placeSelf: 'center',
});

export const SortablePhoto = ({ id, remove, url, ...rest }: Props) => {
    const ref = useRef<HTMLButtonElement>(null);
    const dialogRef = useRef<HTMLDialogElement>(null);

    const sortable = useSortable({ id: id });
    const [confirmDelete, setConfirmDelete] = useState(false);
    const { attributes, listeners, setNodeRef, transform, transition } = sortable;
    const props = useSpring({ width: confirmDelete ? 75 : 32 });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as Node;
            if (target && ref.current && !ref.current.contains(target)) {
                setConfirmDelete(false);
            }
        };
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [ref]);

    return (
        <Photo title="Image" ref={setNodeRef} style={style} url={url} {...rest} {...attributes} {...listeners}>
            <StyledContainer style={props}>
                {confirmDelete ? (
                    remove && (
                        <StyledConfirm ref={ref} data-no-dnd="true" onClick={() => remove(id)}>
                            {L('confirm')}
                        </StyledConfirm>
                    )
                ) : (
                    <StyledClose
                        data-no-dnd="true"
                        onClose={() => {
                            setConfirmDelete(true);
                        }}
                        position={'block'}
                    />
                )}
            </StyledContainer>
            <StyledEnlarger data-no-dnd="true" onClick={() => dialogRef.current?.showModal()}>
                <StyledEnlargerArrow />
            </StyledEnlarger>
            <StyledDialog ref={dialogRef}>
                <StyledContainer>
                    <StyledClose data-no-dnd="true" position={'block'} onClose={() => dialogRef.current?.close()} />
                </StyledContainer>
                <StyledDialogContainer>
                    <StyledImage src={url} />
                </StyledDialogContainer>
            </StyledDialog>
        </Photo>
    );
};
