import { FileDetails, FolderDetails } from '@app/api/models/Folders';
import styled from '@emotion/styled';
import React, { HTMLAttributes, ReactNode } from 'react';
import useGetS3File from '../../hooks/files/useGetS3File';
import { useNavigate } from 'react-router-dom-v5-compat';

export const ListItemGridColumns = {
    gridTemplateColumns:
        '2rem 2rem minmax(15rem,1fr) minmax(9rem,15rem) minmax(9rem,15rem) minmax(5rem,10rem) minmax(2rem,4rem)',
};

const SLink = styled.a({
    gap: '1rem',
    cursor: 'pointer',
    width: '100%',
    display: 'grid',
    ...ListItemGridColumns,
    height: '3rem',
    borderRadius: '10px',
    padding: '0 0.3rem',
    zIndex: 2,
    position: 'relative',

    '*': {
        pointerEvents: 'all',
    },
});

const ItemLink = ({
    item,
    children,
    ...props
}: { item: FolderDetails | FileDetails; children: ReactNode } & HTMLAttributes<HTMLAnchorElement>) => {
    const navigate = useNavigate();

    return item.type === 'file' ? (
        <FileLink item={item} {...props}>
            {children}
        </FileLink>
    ) : (
        <SLink
            role="button"
            type="button"
            onClick={() => {
                navigate(
                    location.pathname.slice(-1) === '/'
                        ? location.pathname + item.id
                        : location.pathname + '/' + item.id,
                    {
                        state: { item },
                    }
                );
            }}
            {...props}
        >
            {children}
        </SLink>
    );
};

const FileLink = ({ item, children }: { item: FileDetails; children: ReactNode }) => {
    const { data: fileUrl } = useGetS3File(`${item.document_data.original.storage}/${item.document_data.original.id}`);

    return (
        <SLink href={fileUrl} target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>
            {children}
        </SLink>
    );
};

export default ItemLink;
