Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["list"] = createRpcProxy("PublicSiteApi", "list", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["get"] = createRpcProxy("PublicSiteApi", "get", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getByType"] = createRpcProxy("PublicSiteApi", "getByType", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["publish"] = createRpcProxy("PublicSiteApi", "publish", {
  "urlPrefix": "/rpc"
});
o["save"] = createRpcProxy("PublicSiteApi", "save", {
  "urlPrefix": "/rpc"
});
o["remove"] = createRpcProxy("PublicSiteApi", "remove", {
  "urlPrefix": "/rpc"
});
o["updateSortOrder"] = createRpcProxy("PublicSiteApi", "updateSortOrder", {
  "urlPrefix": "/rpc"
});
o["saveDocument"] = createRpcProxy("PublicSiteApi", "saveDocument", {
  "urlPrefix": "/rpc"
});
o["saveEditDocument"] = createRpcProxy("PublicSiteApi", "saveEditDocument", {
  "urlPrefix": "/rpc"
});
o["listDocuments"] = createRpcProxy("PublicSiteApi", "listDocuments", {
  "urlPrefix": "/rpc"
});
o["deleteDocument"] = createRpcProxy("PublicSiteApi", "deleteDocument", {
  "urlPrefix": "/rpc"
});
o["listThemes"] = createRpcProxy("PublicSiteApi", "listThemes", {
  "urlPrefix": "/rpc"
});
o["getThemeByProjectId"] = createRpcProxy("PublicSiteApi", "getThemeByProjectId", {
  "urlPrefix": "/rpc"
});
o["saveTheme"] = createRpcProxy("PublicSiteApi", "saveTheme", {
  "urlPrefix": "/rpc"
});
o["saveThemeSetting"] = createRpcProxy("PublicSiteApi", "saveThemeSetting", {
  "urlPrefix": "/rpc"
});
o["sendOrderMail"] = createRpcProxy("PublicSiteApi", "sendOrderMail", {
  "urlPrefix": "/rpc"
});
o["getProjectInfo"] = createRpcProxy("PublicSiteApi", "getProjectInfo", {
  "urlPrefix": "/rpc"
});