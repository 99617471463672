import { SiteTheme } from '@app/api/models/PublicSite';
import { useEffect, useState } from 'react';
import { parseFontData } from '../../lib/parserFontType';
import { colors } from '../../lib/publicColors';
import { publicFonts } from '../../lib/publicFont';
import useGetThemes from './useGetThemes';
import webfont from 'webfontloader';

const useThemeProviderState = () => {
    const { data: themeArray } = useGetThemes();
    const [theme, setTheme] = useState<SiteTheme['theme']>({
        colors,
        fonts: publicFonts,
    });

    useEffect(() => {
        if (!themeArray?.length) return;

        const themeData = themeArray.find((siteTheme) => siteTheme.selected);
        if (!themeData) return;
        setTheme({
            colors: { ...colors, ...themeData.theme.colors },
            fonts: { ...publicFonts, ...themeData.theme.fonts },
        });
    }, [themeArray]);

    useEffect(() => {
        if (!themeArray?.length) return;

        const themeData = themeArray.find((siteTheme) => siteTheme.selected);
        if (!themeData) return;
        if (!themeData?.theme?.fonts) {
            const parsedHeader = parseFontData(publicFonts.header);
            const parsedBody = parseFontData(publicFonts.body);
            webfont.load({
                google: {
                    families: [parsedHeader, parsedBody],
                },
            });
            return;
        }
        const header = themeData.theme.fonts?.header;
        const body = themeData.theme.fonts?.body;
        const parsedHeader = parseFontData(header);
        const parsedBody = parseFontData(body);
        webfont.load({
            google: {
                families: [parsedHeader, parsedBody],
            },
        });
    }, [themeArray]);

    const selectTheme = (siteTheme: SiteTheme) => {
        setTheme({
            colors: { ...colors, ...siteTheme.theme.colors },
            fonts: { ...publicFonts, ...siteTheme.theme.fonts },
        });
    };

    return { theme, selectTheme };
};

export default useThemeProviderState;
