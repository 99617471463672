import { useMutation, useQueryClient } from 'react-query';
import MaintenancesApi from '@app/api/public/MaintenancesApi';
import { getSettingsListByTypeKey } from './useGetSettingsListByType';
import { SettingsType } from '@app/api/models/Maintenances';
import toast from 'react-hot-toast';
import { L } from '../../../lib/i18n';
type Variables = {
    id?: number;
    name: string;
    color_code?: string;
};
const saveSetting = async (settingstype: SettingsType, variables: Variables) => {
    switch (settingstype) {
        case 'category': {
            return await MaintenancesApi.saveCategory(variables);
        }
        case 'status': {
            return await MaintenancesApi.saveStatus(variables);
        }
        case 'type': {
            return await MaintenancesApi.saveType(variables);
        }
        case 'unit': {
            return await MaintenancesApi.saveUnit(variables);
        }
    }
};

const useSaveSettingsListByType = (settingstype: SettingsType) => {
    const queryClient = useQueryClient();
    return useMutation((variables: Variables) => saveSetting(settingstype, variables), {
        onSuccess: () => {
            toast.success(L(`saved_${settingstype}`));
            queryClient.invalidateQueries([getSettingsListByTypeKey, settingstype]);
        },
    });
};

export default useSaveSettingsListByType;
