import React, { useState, FC } from 'react';
import { MainMenu } from '../MainMenu/MainMenu';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';
import { Header } from '../Header/Header';

const ContentWrapper = styled.div({
    ['@media screen and (min-width: 1024px)']: {
        padding: '28px 32px 32px 32px',
    },
});

const PageContainer = styled.div(() => {
    return {
        ['@media screen and (min-width: 1024px)']: {
            display: 'grid',
            gridTemplateAreas: `
            "nav content"
            `,
            gridTemplateColumns: '225px 1fr',
            minHeight: '100vh',
            background: style.colors.pageBackground,
        },
    };
});

export const Page: FC = ({ children }) => {
    return (
        <PageContainer>
            <MainMenu />
            <ContentWrapper>
                <Header />
                {children}
            </ContentWrapper>
        </PageContainer>
    );
};
