import React from 'react';
import DraggableSortGrid from '../../../components/Draggables/DraggableSortGrid/DraggableSortGrid';
import Label from '../../../components/Forms/Label';
import { Loading } from '../../../components/Loading/Loading';
import useDeleteProjectImage from '../hooks/useDeleteProjectImage';
import useGetProjectImages, { projectImagesKey } from '../hooks/useGetProjectImages';
import { useSaveProjectImages } from '../hooks/useSaveProjectImages';
import ProjectsApi from '@app/api/public/ProjectsApi';
import useDragEndDnd from '../../../hooks/useDragEndDnd';

const Images = () => {
    const { data: images } = useGetProjectImages();

    const { mutate: save } = useSaveProjectImages();

    const { mutate: remove } = useDeleteProjectImage();
    const handleDragEnd = useDragEndDnd({
        queryKey: projectImagesKey.list(),
        updateSortOrder: ProjectsApi.updateSort,
        reverseArray: true,
    });

    if (!images) return <Loading />;

    const onUpload = (files: File[]) => {
        const file = { file: files[0], name: files[0].name };
        save(file);
    };

    return (
        <>
            <Label style={{ width: '100%' }} title="Bilder" />
            <DraggableSortGrid remove={remove} onUpload={onUpload} onDragEnd={handleDragEnd} images={images ?? []} />
        </>
    );
};

export default Images;
