import * as React from 'react';
import styled from '@emotion/styled';
import { L } from '../../../lib/i18n';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Button } from '../../../components/Buttons/Button';
import { useHistory, useParams } from 'react-router-dom';
import { Loading } from '../../../components/Loading/Loading';
import { FormField } from '../../../components/Forms/FormField';
import Spinner from '../../../components/Spinner/Spinner';
import useGetBookingResourceById from '../hooks/useGetBookingResourceById';
import { useGetSignedUrlById } from '../../../hooks/useGetSignedUrlById';
import useUpdateBookingResource from '../hooks/useUpdateBookingResource';
import { useEffect, useState } from 'react';
import Input from '../../../components/Forms/Input';
import TimeSlotsTable from './components/TimeSlotsTable';
import dayjs from 'dayjs';
import Label from '../../../components/Forms/Label';
import { colors } from '../../../components/Shared/Style/colors';
import ReactSelect from '../../../components/Dropdown/ReactSelect';
import { SlotUnits } from './lib/SlotUnits';
import { BookedIntervalUnit, BookingResource } from '@app/api/models/Bookings';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import useRemoveBookingResource from '../hooks/useRemoveBookingResource';
import checkUnsavedData from '../../../lib/helpers/checkUnsavedData';

import { margin } from '../../../components/Shared/Style/margin';
import BookingDocumentsArea from './BookingDocumentsArea';
import useSetNotify from '../hooks/useSetNotify';
import StyledRadixSwitch from '../../../components/StyledSwitch/StyledRadixSwitch';
import ImageDropZone from '../../../components/ImageDropZone/ImageDropZone';
import Quill from '../../../components/Quill/Quill';
import { transformUnit } from '../lib/transformUnit';
import ConfirmationButton from '../../../components/Buttons/ConfirmationButton';
import useSetBookingAsDraft from '../hooks/useSetBookingAsDraft';
import StatusArea from '../../../components/StatusArea/StatusArea';

dayjs.extend(customParseFormat);

//#region css

const StyledCardWithDivider = styled(CardWithDivider)({
    width: 'fit-content',
});

const BlockBookingDescription = styled.p({
    margin: '0px',
    fontSize: '0.8rem',
    color: colors.textColor2,
});

const FlexWrapper = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    alignItems: 'start',

    '& > *': {
        flexBasis: '600px',
        flexGrow: 1,
    },
});

const ContentSwitch = styled(StyledRadixSwitch)({
    marginTop: '4px',
});

const HeaderContainer = styled.div({
    display: 'grid',
    gap: '5rem',
    gridTemplateColumns: '3fr 1fr',
    gridTemplateAreas: `
    "imageArea toggleArea"
    `,
});

const StyledImageDropZone = styled(ImageDropZone)({
    aspectRatio: '4/3',
});

const ButtonContainer = styled.div({
    width: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: `${margin.s}px`,
});

//#endregion

export const TimeSlotsBookingDetailPage = () => {
    const { bookingId } = useParams<{ bookingId: string }>();
    const { data: bookingResource, status: resourceStatus } = useGetBookingResourceById(+bookingId);
    const { data: signedUrl } = useGetSignedUrlById(bookingResource?.image_data.id);

    const { mutate: toggleNotify } = useSetNotify();

    const { mutate: updateResource, reset } = useUpdateBookingResource();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [short_description, setShortDescription] = useState('');

    const [start_date, setStartDate] = useState('');
    const [end_date, setEndDate] = useState('');
    const [price, setPrice] = useState<number>();
    const [max_booked_slots, setmax_booked_slots] = useState<number>();
    const [max_booked_days, setmax_booked_days] = useState<number>();
    const [max_booked_interval, setmax_booked_interval] = useState<BookingResource['max_booked_interval']>({});
    const [booking_window, setbooking_window] = useState<BookingResource['booking_window']>(undefined);
    const { mutate: removeBookingResource } = useRemoveBookingResource();
    const history = useHistory();

    const [file, setImgFile] = React.useState<File>();
    const [imgUrl, setImgUrl] = useState<string>('');

    const [confirmed, setConfirmed] = useState(false);

    useEffect(() => {
        if (!confirmed) return;

        const clearConfirmed = setTimeout(() => setConfirmed(false), 5000);

        return () => clearTimeout(clearConfirmed);
    }, [confirmed]);

    useEffect(() => {
        if (!bookingResource) return;
        setName(bookingResource.name);
        setShortDescription(bookingResource.short_description);

        setDescription(bookingResource.description);
        setPrice(bookingResource.price);
        setbooking_window(bookingResource.booking_window);
        setmax_booked_interval(bookingResource.max_booked_interval);
        setmax_booked_slots(bookingResource.max_booked_slots);
        setmax_booked_days(bookingResource.max_booked_days);
        setStartDate(bookingResource.start_date);
        setEndDate(bookingResource.end_date);
    }, [bookingResource]);

    if (resourceStatus === 'loading')
        return <CardWithDivider topArea={L('booking_slot_settings')} mainArea={<Loading />} />;
    if (!bookingResource)
        return (
            <CardWithDivider topArea={L('booking_slot_settings')} mainArea={<div>{L('no_booking_resource')}</div>} />
        );
    const removeResource = () => {
        removeBookingResource(
            { id: bookingResource.id },
            {
                onSuccess: () => {
                    history.push('/booking');
                },
            }
        );
    };

    const onSwitchNotify = () => {
        toggleNotify(bookingResource.id);
    };

    const handleCancel = () => {
        const localResource = {
            ...bookingResource,
            name,
            short_description,
            description,
            max_booked_interval,
            max_booked_days: max_booked_days || 0,
            max_booked_slots: max_booked_slots || 0,
            price: price || 0,
            booking_window,
            start_date,
            end_date,
        };
        if (
            checkUnsavedData({
                buttonText: L('continue'),
                changedData: localResource,
                originalData: bookingResource,
                fn: () => history.push('/booking'),
            })
        )
            return;
        history.push('/booking');
    };

    const onUpload = (files: File[]) => {
        setImgFile(files[0]);
        setImgUrl(URL.createObjectURL(files[0]));
    };

    const save = () => {
        if (start_date && end_date && !dayjs(start_date).isBefore(end_date)) return;

        updateResource({
            resource: {
                ...bookingResource,
                name,
                short_description,
                description,
                max_booked_interval,
                max_booked_days: max_booked_days || 0,
                max_booked_slots: max_booked_slots || 0,
                price: price || 0,
                booking_window,
                start_date,
                end_date,
            },
            file,
        });
    };

    const { mutate } = useSetBookingAsDraft();
    const moveToDraft = () => {
        mutate({ id: +bookingId });
    };

    return (
        <>
            <FlexWrapper>
                <StyledCardWithDivider
                    topArea={
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <CardTitle>{L('booking_edit')}</CardTitle>
                            <ButtonContainer>
                                <ConfirmationButton props={{ onConfirm: removeResource }} />
                                <Button onClick={moveToDraft}>{L('move_to_draft')}</Button>
                            </ButtonContainer>
                        </div>
                    }
                    mainArea={
                        <>
                            <HeaderContainer>
                                <div style={{ gridArea: 'imageArea' }}>
                                    <Label title={L('image')} />
                                    <StyledImageDropZone
                                        dropzoneOptions={{}}
                                        onDrop={onUpload}
                                        url={imgUrl || signedUrl || ''}
                                    />
                                </div>
                                <div style={{ gridArea: 'toggleArea', paddingBottom: '20px' }}>
                                    <StatusArea
                                        status={{
                                            created_by_name: bookingResource?.created_by_name,
                                            created_by_email: bookingResource?.created_by_email,
                                            created_at: bookingResource?.created_at,
                                        }}
                                        style={{ alignSelf: 'start', gridArea: 'statusArea' }}
                                    />
                                    <Label htmlFor="notify" title={`${L('notify')}`}>
                                        <ContentSwitch
                                            checked={bookingResource?.notification_is_enabled}
                                            onCheckedChange={() => onSwitchNotify()}
                                        />
                                    </Label>
                                </div>
                            </HeaderContainer>
                            <FormField title={L('name')}>
                                <Input
                                    value={name || ''}
                                    name="name"
                                    onChange={(e) => {
                                        reset();
                                        setName(e.target.value);
                                    }}
                                />
                            </FormField>
                            <FormField title={L('short_description')}>
                                <Input
                                    value={short_description || ''}
                                    name="short_description"
                                    onChange={(e) => {
                                        reset();
                                        setShortDescription(e.target.value);
                                    }}
                                />
                            </FormField>
                            <FormField title={`${L('description')}`}>
                                <Quill
                                    value={description || ''}
                                    onChange={(value) => {
                                        reset();
                                        setDescription(value);
                                    }}
                                    placeholder="Skriv något här..."
                                />
                            </FormField>
                            <div
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr',
                                    gap: '2rem',
                                }}
                            >
                                <FormField title={L('booking_slot_type')}>
                                    <Input
                                        value={L(
                                            `booking_slot_type_${bookingResource.booking_resource_slot_type.name}`
                                        )}
                                        name="type"
                                        readOnly={true}
                                    />
                                </FormField>
                                <FormField title={L('calendar_price')}>
                                    <Input
                                        value={price || ''}
                                        type={'number'}
                                        name="price"
                                        placeholder={L('free')}
                                        onChange={(e) => {
                                            if (typeof +e.target.value === 'number') {
                                                setPrice(+e.target.value);
                                            }
                                        }}
                                    />
                                </FormField>
                                <div
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr',
                                        gridColumn: '1 / -1',
                                        gap: '0px 2rem',
                                    }}
                                >
                                    <FormField
                                        title={L('max_booked_interval')}
                                        style={{ margin: '0px', gridColumn: '1 / -1' }}
                                    />
                                    <BlockBookingDescription style={{ margin: '0px', gridColumn: '1 / -1' }}>
                                        {L('blocked_booking_description')}
                                    </BlockBookingDescription>
                                    <FormField title={L('booking_unit')}>
                                        <ReactSelect
                                            value={
                                                (max_booked_interval?.unit && {
                                                    label: L(max_booked_interval.unit),
                                                    value: max_booked_interval.unit,
                                                }) || { label: L('no_cap'), value: '' }
                                            }
                                            onChange={(e) => {
                                                const unit = e?.value as BookedIntervalUnit;
                                                setmax_booked_interval((prev) => ({
                                                    unit: unit || prev?.unit,
                                                    quantity: prev?.quantity,
                                                }));
                                            }}
                                            options={SlotUnits.map((unit) => ({
                                                label: L(unit),
                                                value: unit,
                                            }))}
                                        />
                                    </FormField>
                                    <FormField title={L('max_booked_days')}>
                                        <Input
                                            value={max_booked_days || ''}
                                            type={'number'}
                                            name="max_booked_days"
                                            placeholder={L('no_cap')}
                                            min={0}
                                            onChange={(e) => {
                                                const value = +e.target.value;
                                                if (typeof value !== 'number') return setmax_booked_days(0);
                                                if (value < 0) {
                                                    setmax_booked_days(0);
                                                    return;
                                                }
                                                setmax_booked_days(value);
                                            }}
                                        />
                                    </FormField>
                                    <div />
                                    <FormField title={L('max_booked_slots')}>
                                        <Input
                                            value={max_booked_slots || ''}
                                            type={'number'}
                                            name="max_booked_slots"
                                            placeholder={L('no_cap')}
                                            min={0}
                                            onChange={(e) => {
                                                const value = +e.target.value;
                                                if (typeof value !== 'number') return setmax_booked_slots(0);
                                                if (value < 0) {
                                                    setmax_booked_slots(0);
                                                    return;
                                                }
                                                setmax_booked_slots(value);
                                            }}
                                        />
                                    </FormField>
                                    <FormField title={`${L('max_date_bookings')}`}>
                                        <ReactSelect
                                            isClearable
                                            value={
                                                (booking_window?.unit && {
                                                    label: L(booking_window.unit),
                                                    value: booking_window.unit,
                                                }) ||
                                                undefined
                                            }
                                            onChange={(e, actionType) => {
                                                const unit = e?.value as BookedIntervalUnit;
                                                actionType.action === 'clear' &&
                                                    setbooking_window((prev) => ({
                                                        unit: undefined,
                                                        quantity: prev?.quantity,
                                                    }));
                                                setbooking_window((prev) => ({
                                                    unit: unit || prev?.unit,
                                                    quantity: prev?.quantity,
                                                }));
                                            }}
                                            options={SlotUnits.map((unit) => ({
                                                label: unit,
                                                value: unit,
                                            }))}
                                            placeholder={L('no_cap')}
                                        />
                                    </FormField>

                                    <FormField
                                        title={`${L('amount')} ${L(`${transformUnit(booking_window?.unit || '')}`)}`}
                                    >
                                        <Input
                                            value={booking_window?.quantity || ''}
                                            type={'number'}
                                            name="booking_window"
                                            placeholder={L('no_cap')}
                                            min={0}
                                            onChange={(e) => {
                                                const value = +e.target.value;
                                                if (typeof value !== 'number')
                                                    return setbooking_window({ quantity: 0 });
                                                if (value < 0) {
                                                    setbooking_window({ quantity: 0 });
                                                    return;
                                                }

                                                setbooking_window((prev) => ({
                                                    quantity: value,
                                                    unit: prev?.unit,
                                                }));
                                            }}
                                        />
                                    </FormField>
                                    <FormField title={L('start_date')}>
                                        <Input
                                            type="date"
                                            value={start_date ? dayjs(start_date).format('YYYY-MM-DD') : ''}
                                            onChange={(e) => setStartDate(e.target.value)}
                                            name={'start_date'}
                                        />
                                    </FormField>
                                    <FormField title={L('end_date')}>
                                        <Input
                                            type="date"
                                            value={end_date ? dayjs(end_date).format('YYYY-MM-DD') : ''}
                                            onChange={(e) => setEndDate(e.target.value)}
                                            name={'end_date'}
                                        />
                                    </FormField>
                                </div>
                            </div>
                            <ButtonContainer style={{ marginTop: '1rem' }}>
                                <Button onClick={handleCancel} variant={'primary'}>
                                    {L('cancel')}
                                </Button>
                                <Button
                                    style={{ display: 'flex', justifyContent: 'center', marginLeft: margin.s }}
                                    onClick={save}
                                    variant={'selectedMenu'}
                                >
                                    {L('save')}
                                </Button>
                            </ButtonContainer>
                        </>
                    }
                />

                <BookingDocumentsArea />
            </FlexWrapper>
            <TimeSlotsTable />
        </>
    );
};
