import { useQuery } from 'react-query';
import ProjectApi from '../../../../api/public/ProjectsApi';

export const invoiceQueryKeys = {
    all: ['invoiceInfo'],
    list: () => [...invoiceQueryKeys.all, 'list'],
};

const getInvoiceInformation = async () => {
    return ProjectApi.getInvoiceInfromation();
};
const useGetInvoiceInformation = () => {
    return useQuery(invoiceQueryKeys.list(), () => getInvoiceInformation());
};

export default useGetInvoiceInformation;
