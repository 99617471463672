import styled from '@emotion/styled';
import React from 'react';
import { style } from '../../../../components/Shared/Style';
import { colors } from '../../../../components/Shared/Style/colors';
import Attachment from './Attachment';

const StyledGrid = styled.div({
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill,minmax(150px,1fr))`,
    gap: 10,
    marginTop: style.margin.m,
});

const AttachmentArea = ({
    children,
    attachments,
    deleteDocument,
}: {
    attachments: string[] | undefined;
    children?: any[];
    deleteDocument?: (s3key: string) => void;
}) => {
    const Attachments = attachments?.map((attachment, index) => {
        return (
            <div
                key={index}
                style={{
                    display: 'flex',
                    border: `1px solid ${colors.inputBorder}`,
                    borderRadius: '8px',
                    height: '80',
                }}
            >
                <Attachment id={attachment} deleteDocument={deleteDocument} />
            </div>
        );
    });

    return (
        <StyledGrid>
            {Attachments ? Attachments : null}
            {children}
        </StyledGrid>
    );
};

export default AttachmentArea;
