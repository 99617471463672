import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Loading } from '../../../components/Loading/Loading';
import { style } from '../../../components/Shared/Style';
import { colors } from '../../../components/Shared/Style/colors';
import { L } from '../../../lib/i18n';
import ExistingMemberForm from '../Form/ExistingMember';
import NewMember from '../Form/NewMember';
import { useGetNonBoardMemberList } from '../hooks/useGetBoardMembers';
import useGetSubRoles, { useGetRoles } from '../hooks/useGetRoles';
import useCreateBoardMember from '../hooks/useCreateBoardMember';
import useSaveNewBoardMember from '../hooks/useSaveBoardMember';
import useGetProjectInfo from '../../../hooks/useGetProjectInfo';

const DropDownLabel = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: style.fontSize.milli,
});

const Span = styled.div(({ theme }) => ({
    fontSize: style.fontSize.seta,
    display: 'inline-block',
    fontWeight: style.fontWeight.bold,
    color: theme.colors.secondary,
    '&.active': {
        color: theme.colors.primary,
    },
    '&:hover': {
        cursor: 'pointer',
    },
}));

const FormWrapper = styled.div({
    width: '500px',
    placeSelf: 'center',
    backgroundColor: colors.white,
    padding: '2rem',
    minHeight: '600px',
    position: 'relative',
    borderRadius: style.roundCornerSize.small,
});

const FlexWrapper = styled.div({
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    marginTop: style.margin.ml,
});

const SpanDivider = styled.div({
    borderRight: '1px solid grey',
});

const FormTitle = styled.div({
    fontSize: '1.2rem',
    fontWeight: 'bold',
});

const NewBoardMemberForm = () => {
    const { mutate: save, status: saveStatus, reset: resetSave } = useSaveNewBoardMember();
    const { mutate: create, status: createStatus, reset: resetCreate } = useCreateBoardMember();
    const { data: member } = useGetNonBoardMemberList();
    const roles = useGetRoles();
    const { data: subRoles } = useGetSubRoles();
    const { data: project } = useGetProjectInfo();

    const [view, setView] = useState<'existingMember' | 'newMember'>('existingMember');

    const options = member?.map((member) => ({
        value: [member.user_name, member.user_email, member.user_id, member.property_name],
        label: (
            <DropDownLabel>
                <span>{member.user_email}</span>
                {<span style={{ alignSelf: 'flex-end' }}>{member.property_name}</span>}
            </DropDownLabel>
        ),
    }));

    if (!member || !subRoles) return <Loading />;

    return (
        <>
            <FormWrapper
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <FormTitle>
                    {L('new')} {L('board_member_content')}
                </FormTitle>
                <FlexWrapper>
                    <Span
                        className={view === 'existingMember' ? 'active' : ''}
                        onClick={() => {
                            resetSave();
                            setView('existingMember');
                        }}
                    >
                        Befintlig medlem
                    </Span>
                    <SpanDivider />
                    <Span
                        className={view === 'newMember' ? 'active' : ''}
                        onClick={() => {
                            resetCreate();
                            setView('newMember');
                        }}
                    >
                        Ny medlem
                    </Span>
                </FlexWrapper>
                {view === 'existingMember' && (
                    <ExistingMemberForm
                        saveBoardMember={save}
                        options={options}
                        roles={roles}
                        subRoles={subRoles}
                        status={saveStatus}
                        resetSave={resetSave}
                        projectType={project?.project_type}
                    />
                )}
                {view === 'newMember' && (
                    <NewMember
                        createBoardMember={create}
                        roles={roles}
                        subRoles={subRoles}
                        status={createStatus}
                        resetCreate={resetCreate}
                        projectType={project?.project_type}
                    />
                )}
            </FormWrapper>
        </>
    );
};

export default NewBoardMemberForm;
