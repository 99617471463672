import React, { useState } from 'react';
import styled from '@emotion/styled';
import Input from '../../../components/Forms/Input';
import { Button } from '../../../components/Buttons/Button';
import { L } from '../../../lib/i18n';
import { isEqual } from 'lodash';

import { CommunityMailFolderType } from '@app/api/models/Mail/MailModels';
import { useRemoveFolder, useUpdateFolder } from '../hooks/mailQueries';

const Save = styled(Button)({
    height: '100%',
    margin: '0px',
});

const Content = styled('div')({
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    justifyItems: 'space-between',
});

const RemoveButton = styled(Button)({
    height: '100%',
});

type Props = {
    item: CommunityMailFolderType;
};

const FolderItem = ({ item, ...rest }: Props) => {
    const [name, setName] = useState(item.name || '');
    const [confirmRemove, setConfirmRemove] = useState(false);
    const { mutate: remove } = useRemoveFolder();
    const { mutate, status } = useUpdateFolder();

    const edited = !isEqual({ name: item.name }, { name });

    const handleSave = () => {
        if (!edited) return;
        mutate({ id: item.id, name });
    };

    const removeButton = confirmRemove ? (
        <RemoveButton onClick={() => remove({ id: item.id })} variant="delete">
            {L('remove')}
        </RemoveButton>
    ) : (
        <RemoveButton onClick={() => setConfirmRemove(true)}>{L('remove')}</RemoveButton>
    );
    return (
        <Content {...rest}>
            <Input onChange={(e) => setName(e.target.value)} value={name} />
            <div>
                {edited ? (
                    <Save variant="selectedMenu" onClick={handleSave} disabled={status === 'loading'}>
                        {status === 'loading' ? L('saving') : L(`save`)}
                    </Save>
                ) : (
                    removeButton
                )}
            </div>
        </Content>
    );
};

export default FolderItem;
