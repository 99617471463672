import BoardContentNewsApi from '@app/api/public/BoardContentNewsApi';
import { useMutation, useQueryClient } from 'react-query';
import { boardNewsQueryKeys } from './boardNewsQueries';

type Variables = { id: number };

const useSetNewsAsDraft = () => {
    const client = useQueryClient();
    return useMutation(
        (varables: Variables) => {
            return BoardContentNewsApi.setNewsAsDraft(varables.id);
        },
        {
            onSettled: (data, error, varables) => {
                client.invalidateQueries(boardNewsQueryKeys.lists());
                client.invalidateQueries(boardNewsQueryKeys.detail(varables.id));
            },
        }
    );
};

export default useSetNewsAsDraft;
