import { MailStatus, MemberDetail } from '@app/api/models/Members';
import React from 'react';
import Input from '../../../../components/Forms/Input';
import Label from '../../../../components/Forms/Label';
import { L } from '../../../../lib/i18n';
import useGetInviteMailStatus from '../../hooks/useInviteMailStatus';

type Props = {
    memberId: MemberDetail['id'];
};
const InviteMailStatus = ({ memberId }: Props) => {
    const emailStatus = useGetInviteMailStatus(memberId);

    if (!emailStatus) return null;

    return (
        <Label title={L('invite_email_status')}>
            <Input readOnly defaultValue={L(`email_${emailStatus}`)} />
        </Label>
    );
};

export default InviteMailStatus;
