import styled from '@emotion/styled';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import { BookingResource } from '@app/api/models/Bookings';
import { getBookingResourcesQueryKey } from '../hooks/useGetBookingResources';
import { style } from '../../../components/Shared/Style';
import { tableHeader, title, tooltip } from '../../../components/Shared/Style/typography';
import useDragEnd from '../../../hooks/useDragEnd';
import { Icon } from '../../../components/Icon/Icon';
import { L } from '../../../lib/i18n';
import BookingsApi from '@app/api/public/BookingsApi';

const List = styled.ul({
    display: 'grid',
    gridTemplateColumns: '1fr',
    listStyle: 'none',
    margin: '0px',
    padding: '0px',
    alignContent: 'start',
});

const templateColumns = '0.04fr 0.7fr 0.2fr 0.3fr' as const;

const Headers = styled.div(() => ({
    margin: '0px',
    display: 'grid',
    gridTemplateColumns: templateColumns,
    columnGap: '2rem',
    alignItems: 'baseline',
    padding: '0px 0.5rem',
}));

const FeedListItem = styled(Link)({
    borderBottom: `1px solid ${style.colors.divider}`,
    padding: '1rem 0.5rem',
    display: 'grid',
    gap: '2rem',
    alignItems: 'center',
    gridTemplateColumns: templateColumns,
    '&:first-of-type': {
        borderTop: `1px solid ${style.colors.divider}`,
    },
});

const IconWrapper = styled.span({
    display: 'flex',
    alignItems: 'center',
    '.st0': { fill: '#AFAFAF' },
});

const ListTitle = styled.span({
    ...title,
});

const ListTimeStamp = styled.span({
    ...tooltip,
});

const HeaderText = styled.span({
    ...tableHeader,
});
type Props = {
    bookingList: BookingResource[];
    isDragDisabled: boolean;
};

const BookingItemList = ({ bookingList, isDragDisabled }: Props) => {
    const { mutate: dragEnd } = useDragEnd({
        dragableItems: bookingList,
        queryKey: getBookingResourcesQueryKey.all,
        updateSortOrder: BookingsApi.setSortOrder,
    });

    return (
        <DragDropContext onDragEnd={(result, provided) => dragEnd({ result, provided })}>
            <Droppable droppableId="linkItems" direction="vertical">
                {(provided) => (
                    <List className="linkItems" {...provided.droppableProps} ref={provided.innerRef}>
                        <Headers>
                            <div />
                            <HeaderText>{L('title')}</HeaderText>
                            <HeaderText>{L('type')}</HeaderText>
                            <HeaderText>{L('incoming_bookings')}</HeaderText>
                        </Headers>
                        {bookingList &&
                            bookingList.map((pageListItem, index) => (
                                <Draggable
                                    isDragDisabled={isDragDisabled}
                                    key={pageListItem.id + pageListItem.name}
                                    draggableId={pageListItem.id + pageListItem.name}
                                    index={index}
                                >
                                    {(provided) => (
                                        <FeedListItem
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            to={`/booking/${pageListItem.id}`}
                                        >
                                            <IconWrapper>
                                                <Icon.VerticalMoreIcon
                                                    size="small"
                                                    style={{
                                                        justifySelf: 'left',
                                                        visibility: isDragDisabled ? 'hidden' : 'visible',
                                                    }}
                                                />
                                            </IconWrapper>
                                            <ListTitle>{pageListItem.name}</ListTitle>
                                            <ListTimeStamp>
                                                {L(pageListItem.booking_resource_slot_type.name)}
                                            </ListTimeStamp>
                                            <ListTimeStamp>
                                                {pageListItem.bookings_from_now ? pageListItem.bookings_from_now : 0}
                                            </ListTimeStamp>
                                        </FeedListItem>
                                    )}
                                </Draggable>
                            ))}
                        {provided.placeholder}
                    </List>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default BookingItemList;
