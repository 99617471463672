import * as React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button } from '../../../components/Buttons/Button';
import { Loading } from '../../../components/Loading/Loading';
import { useEffect, useState } from 'react';
import Label from '../../../components/Forms/Label';
import { margin } from '../../../components/Shared/Style/margin';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import checkUnsavedData from '../../../lib/helpers/checkUnsavedData';
import Input from '../../../components/Forms/Input';
import { Property } from '@app/api/models/Property';
import useSaveProperty from '../hooks/useSaveProperty';
import toast from 'react-hot-toast';
import findAndReplaceNestedObjects from '../../../lib/helpers/findAndReplaceNestedObjects';
import { TextAreaInput } from '../../../components/Forms/TextArea';
import Modal from '../../../components/Modal/Modal';
import TransferProperty from './components/TransferProperty';
import { useModal } from '../../../components/Modal/Context/ModalContext';
import useCancelPropertyTransfer from '../hooks/useCancelPropertyTransfer';
import dayjs from 'dayjs';
import Swap from '../../../components/Icon/themed/Swap';
import { useGetPropertiesList, useGetProperty } from '../hooks/propertyQueries';

//#region css

const GridContainer = styled.div({
    display: 'grid',
    gap: '10rem',
    margin: '1rem 0',
    '@media screen and (min-width: 1100px)': {
        gridTemplateColumns: '1fr 1fr',
    },
});

const TextContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '75%',
});

const ButtonContainer = styled.div({
    width: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
});

const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const StyledDiv = styled.div({
    gap: '1ch',
    display: 'inherit',
    alignItems: 'center',
});
//#endregion

export const PropertiesDetailPage = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const { ToggleIsModalOpen } = useModal();
    const { data: property } = useGetProperty(+id);
    const { mutate: saveProperty, data: propertyId } = useSaveProperty();
    const { mutate: cancel } = useCancelPropertyTransfer();
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [propertyState, setPropertyState] = useState<Partial<Property>>({
        name: '',
        property_type: 0,
        attr: {
            city: '',
            floor: '',
            house: '',
            rooms: '',
            floors: '',
            parish: '',
            street: '',
            commune: '',
            country: '',
            heating: '',
            bathrooms: '',
            plot_area: '',
            stairwell: '',
            built_year: '',
            postalcode: '',
            access_date: '',
            description: '',
            monthly_fee: '',
            assess_value: '',
            property_area: '',
            property_term: '',
            property_number: '',
            apartment_number: '',
            percentage_of_community: '',
        },
        created_at: '',
        updated_at: '',
        user_id: 0,
        project_id: 0,
        published_at: '',
        discarded_at: '',
        transfer_date: '',
        transfer_owners: '',
    });

    const { data: properties } = useGetPropertiesList();
    const users = properties?.filter((property) => property.id === +id).map((property) => property.users)[0] || [];
    useEffect(() => {
        if (!property) return;

        setPropertyState({ ...property });
    }, [property]);

    //Used for when creating new properties to be able to return to the same page with an ID
    useEffect(() => {
        if (!propertyId) return;

        history.push(`/properties/${propertyId}`);
    }, [propertyId]);

    const save = () => {
        saveProperty(
            {
                ...propertyState,
            },
            {
                onSuccess: () => {
                    toast.success(`${L('property')} ${propertyState.name} ${L('saved')}`);
                },
            }
        );
    };

    const onSwitch = () => {
        if (!property) {
            return <Loading />;
        }
        const localResource = {
            ...propertyState,
        };
        if (
            checkUnsavedData({
                buttonText: L('continue'),
                changedData: localResource,
                originalData: property,
                fn: () => history.push('/properties'),
            })
        )
            return;
        history.push('/properties');
    };

    const onChange = (value: string, name: string) => {
        const propertyStateCopy = { ...propertyState, attr: { ...propertyState.attr } };
        findAndReplaceNestedObjects(name, value, propertyStateCopy);
        setPropertyState(propertyStateCopy);
    };

    const main = (
        <>
            <GridContainer>
                <TextContainer>
                    <Label title={L('property_type')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.property_type || ''}
                            name="property_type"
                        />
                    </Label>
                    <Label title={L('property_name')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.name || ''}
                            name="name"
                        />
                    </Label>
                    <Label title={L('property_description')}>
                        <TextAreaInput
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.attr.description || ''}
                            name="attr.description"
                        />
                    </Label>
                    <Label title={L('property_number')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.attr.property_number || ''}
                            name="attr.property_number"
                        />
                    </Label>
                    <Label title={L('apartment_number')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.attr.apartment_number || ''}
                            name="attr.apartment_number"
                        />
                    </Label>
                    <Label title={L('property_street')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.attr.street || ''}
                            name="attr.street"
                        />
                    </Label>
                    <Label title={L('property_postal_code')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.attr.postalcode || ''}
                            name="attr.postalcode"
                        />
                    </Label>
                    <Label title={L('property_postal_city')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.attr.city || ''}
                            name="attr.city"
                        />
                    </Label>
                    <Label title={L('country')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.attr.country || ''}
                            name="attr.country"
                        />
                    </Label>
                    <Label title={L('commune')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.attr.commune || ''}
                            name="attr.commune"
                        />
                    </Label>
                    <Label title={L('property_parish')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.attr.parish || ''}
                            name="attr.parish"
                        />
                    </Label>
                    <Label title={L('property_designation')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.attr.property_term || ''}
                            name="attr.property_term"
                        />
                    </Label>
                    <Label title={L('property_area')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.attr.property_area || ''}
                            name="attr.property_area"
                        />
                    </Label>
                </TextContainer>

                <TextContainer>
                    <Label title={L('property_plot_area')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.attr.plot_area || ''}
                            name="attr.plot_area"
                        />
                    </Label>
                    <Label title={L('property_built_year')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.attr.built_year || ''}
                            name="attr.built_year"
                        />
                    </Label>
                    <Label title={L('property_rooms')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.attr.rooms || ''}
                            name="attr.rooms"
                        />
                    </Label>
                    <Label title={L('property_floors')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.attr.floors || ''}
                            name="attr.floors"
                        />
                    </Label>
                    <Label title={L('property_wc')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.attr.bathrooms || ''}
                            name="attr.bathrooms"
                        />
                    </Label>
                    <Label title={L('property_assessed_value')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.attr.assess_value || ''}
                            name="attr.assess_value"
                        />
                    </Label>
                    <Label title={L('property_heating')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.attr.heating || ''}
                            name="attr.heating"
                        />
                    </Label>
                    <Label title={L('property_stairwell')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.attr.stairwell || ''}
                            name="attr.stairwell"
                        />
                    </Label>
                    <Label title={L('property_house')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.attr.house || ''}
                            name="attr.house"
                        />
                    </Label>
                    <Label title={L('property_floor')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.attr.floor || ''}
                            name="attr.floor"
                        />
                    </Label>
                    <Label title={L('property_proportion')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.attr.percentage_of_community || ''}
                            name="attr.percentage_of_community"
                        />
                    </Label>
                    <Label title={L('property_monthly')}>
                        <Input
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={property?.attr.monthly_fee || ''}
                            name="attr.monthly_fee"
                        />
                    </Label>
                    <Label title={L('property_access_date')}>
                        <Input
                            placeholder="YYYY-MM-DD"
                            onChange={(e) => onChange(e.target.value, e.target.name)}
                            defaultValue={
                                property?.attr?.access_date
                                    ? dayjs(property?.attr.access_date).format('YYYY-MM-DD')
                                    : ''
                            }
                            name="attr.access_date"
                        />
                    </Label>
                </TextContainer>
            </GridContainer>
            <ButtonContainer>
                <Button onClick={onSwitch} style={{ marginRight: margin.s }} variant={'primary'}>
                    {L('cancel')}
                </Button>
                <Button onClick={save} style={{ marginRight: '0px' }} variant={'filledPrimary'}>
                    {L('save')}
                </Button>
            </ButtonContainer>
        </>
    );
    return (
        <>
            <Modal>
                <TransferProperty id={property?.id} />
            </Modal>
            <CardWithDivider
                topArea={
                    <Wrapper>
                        <CardTitle>
                            {L('property_description')} {property?.name}
                        </CardTitle>
                        {property?.transfer_date ? (
                            <StyledDiv>
                                <Swap />
                                <span>{dayjs(property?.transfer_date).format('YYYY-MM-DD')}</span>
                                {confirmDelete ? (
                                    <Button onClick={() => cancel()} variant={'delete'}>
                                        {L('confirm')}
                                    </Button>
                                ) : (
                                    <Button onClick={() => setConfirmDelete(true)} variant={'primary'}>
                                        {L('cancel_property_transfer')}
                                    </Button>
                                )}
                            </StyledDiv>
                        ) : (
                            <Button disabled={users?.length <= 0} onClick={() => ToggleIsModalOpen()}>
                                {L('change_property_owner')}
                            </Button>
                        )}
                    </Wrapper>
                }
                mainArea={main}
            />
        </>
    );
};
