import { useMutation, useQueryClient } from 'react-query';
import DocumentsApi from '@app/api/public/DocumentsApi';
import toast from 'react-hot-toast';
import { folderKeys } from '../../queryKeys';
import { L } from '../../../../lib/i18n';

const useDeleteFolder = (folderName?: string) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: DocumentsApi.deleteFolder,
        onError: (err) => {
            const errorMessage = typeof err === 'string' ? err : new Error(L('reset_error')).message;
            toast.error(`${L(errorMessage)}`);
        },
        onSettled: () => {
            queryClient.invalidateQueries([folderKeys]);
        },
        onSuccess: () => {
            console.log('SUCCESS!');
            toast.success(L('folder_deleted') + ': `' + folderName + '`');
        },
    });
};

export default useDeleteFolder;
