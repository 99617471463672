import React from 'react';
import { Button } from '@ourliving/ourliving-ui';
import { L } from '../../../lib/i18n';
import styled from '@emotion/styled';

const Title = styled.p({
    fontSize: '1.2rem',
    textAlign: 'center',
    margin: '0.5rem',
});

const WarningText = styled.p({
    textAlign: 'center',
    marginTop: '0',
    marginBottom: '2rem',
});

const ButtonContainer = styled.div({
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%',
});

type DeleteDialogProps = {
    isLoading: boolean;
    onDelete: () => void;
    onCancel: () => void;
    title: string;
    warningMessage?: string;
};

const DeleteDialog = ({ isLoading, onDelete, onCancel, title, warningMessage }: DeleteDialogProps) => {
    return (
        <>
            <Title>{title}</Title>
            <WarningText>{warningMessage}</WarningText>

            <ButtonContainer>
                <Button variant="secondary" onClick={() => onCancel()}>
                    {L('cancel')}
                </Button>
                <Button variant="danger" onClick={() => onDelete()} isLoading={isLoading} disabled={isLoading}>
                    {L('delete')}
                </Button>
            </ButtonContainer>
        </>
    );
};

export default DeleteDialog;
