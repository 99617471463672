import React from 'react';
import ModalWrapper from '../../../../components/Modal/ModalWrapper';
import CardTitle from '../../../../components/typography/CardTitle';
import { L } from '../../../../lib/i18n';

const ServicePartnerModal = ({
    children,
    ...rest
}: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <ModalWrapper
            {...rest}
            topArea={<CardTitle>{`${L('createNewSevicePartner')}`}</CardTitle>}
            mainArea={children}
        />
    );
};

export default ServicePartnerModal;
