import React, { FC, useEffect, useState } from 'react';
import InputSearch from '../../../components/Forms/InputSearch';
import Label from '../../../components/Forms/Label';
import { L } from '../../../lib/i18n';
import useGetGoogleApiFonts from './hooks/useGetGoogleApiFonts';

type Props = {
    startValue: string;
    name: string;
    error?: string;
    handleTypographyInput: (e: EventTarget & HTMLInputElement) => void;
};
const FontInput: FC<Props & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>> = ({
    startValue,
    name,
    error,
    handleTypographyInput,
    ...rest
}) => {
    const { data, isLoading } = useGetGoogleApiFonts();
    const [fonts, setFonts] = useState<string[]>([]);
    useEffect(() => {
        if (!data) return;
        const fontArray = data.items.map((item) => item.family);
        setFonts(fontArray);
    }, [data]);
    console.log('isLoading', isLoading);
    return (
        <Label title={name + ` ${isLoading ? L('loading') : ''}`} error={error} {...rest}>
            <InputSearch
                getEventOnChange={handleTypographyInput}
                name={name}
                startValue={startValue}
                inputArray={fonts}
            />
        </Label>
    );
};

export default FontInput;
