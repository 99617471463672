import { ContentInformationItem } from '@app/api/models/ContentInformation/ContentInformationModels';
import BoardContentInformationApi from '@app/api/public/BoardContentInformationApi';
import { useMutation, useQueryClient } from 'react-query';
import { boardInformationQueryKeys } from './boardInformationQueries';

type Variables = { id: ContentInformationItem['id'] };

const useRemoveInformation = () => {
    const client = useQueryClient();
    return useMutation(
        (varables: Variables) => {
            return BoardContentInformationApi.remove(varables.id);
        },
        {
            onSettled: () => {
                client.invalidateQueries(boardInformationQueryKeys.all);
            },
        }
    );
};

export default useRemoveInformation;
