import * as React from 'react';
import { L } from '../../../lib/i18n';
import styled from '@emotion/styled';
import { ProjectDetail } from '@app/api/public/ProjectsApi';
import Label from '../../../components/Forms/Label';
import { formatDateIsoDayjs } from '@app/shared/lib/formatting';
import Input from '../../../components/Forms/Input';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import useGetProjectInfo, { useUpdateProjectEmail } from '../../../hooks/useGetProjectInfo';
import { Button } from '../../../components/Buttons/Button';

type Props = {
    project: ProjectDetail;
};

enum ProjectTypes {
    Housing_Society = 0,
    Appartment_Block = 1,
    Office = 2,
    Small_House = 3,
}

const StyledLable = styled(Label)({
    '& div': {
        marginTop: '0px',
    },
});

const GridWrapper = styled.div({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '2rem',
    rowGap: '1.2rem',
});

const projectMailschema = z.object({
    contact_email: z.union([z.string().email({ message: 'invalid_email' }), z.literal('')]),
});

type ProjectMailschema = z.infer<typeof projectMailschema>;

export const ProjectInfoArea = ({ project }: Props) => {
    let projectType = '';
    switch (+project.project_type) {
        case ProjectTypes.Housing_Society: {
            projectType = 'Bostadsrättsförening';
            break;
        }
        case ProjectTypes.Appartment_Block: {
            projectType = 'Hyresfastighet';
            break;
        }
        case ProjectTypes.Office: {
            projectType = 'Kontor';
            break;
        }
        case ProjectTypes.Small_House: {
            projectType = 'Småhus';
            break;
        }
    }
    const { data } = useGetProjectInfo();
    const { mutate, isLoading } = useUpdateProjectEmail();

    const { register, handleSubmit, formState } = useForm<ProjectMailschema>({
        resolver: zodResolver(projectMailschema),
        values: {
            contact_email: data?.contact_email ?? '',
        },
    });

    return (
        <form
            onSubmit={handleSubmit(({ contact_email }) => {
                mutate(contact_email);
            })}
        >
            <GridWrapper>
                <StyledLable title={L('housing_soceity')}>
                    <Input name="name" value={project?.name || ''} disabled={true} />
                </StyledLable>
                <StyledLable title={L('street_name')}>
                    <Input name="address" value={project?.address || ''} disabled={true} />
                </StyledLable>

                <StyledLable title={L('city')}>
                    <Input name="city" value={project?.city || ''} disabled={true} />
                </StyledLable>

                <StyledLable title={L('postalCode')}>
                    <Input name="zip" value={project?.zip || ''} disabled={true} />
                </StyledLable>

                <StyledLable title={L('commune')}>
                    <Input name="commune" value={project?.commune || ''} disabled={true} />
                </StyledLable>
                <StyledLable title={L('project_type')}>
                    <Input name="project_type" value={projectType} disabled={true} />
                </StyledLable>
                <StyledLable title={L('access_date')}>
                    <Input
                        name="access_date"
                        value={(project.access_date && formatDateIsoDayjs(project?.access_date)) || ''}
                        disabled={true}
                    />
                </StyledLable>
                <StyledLable title={L('property_count')}>
                    <Input name="property_count" value={project.property_count} disabled={true} />
                </StyledLable>

                <StyledLable
                    title={L('contact_email')}
                    error={formState.errors.contact_email?.message && L(formState.errors.contact_email.message)}
                >
                    <Input {...register('contact_email')} type="email" />
                </StyledLable>
                <Button
                    disabled={isLoading}
                    style={{
                        alignSelf: 'end',
                    }}
                >
                    {L('save')}
                </Button>
            </GridWrapper>
        </form>
    );
};
