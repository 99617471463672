import { Alert, AlterPriorityType } from '@app/api/public/AlertsApi';
import styled from '@emotion/styled';
import React from 'react';
import { useState } from 'react';
import { Button } from '../../../components/Buttons/Button';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import { TextAreaInput } from '../../../components/Forms/TextArea';
import Spinner from '../../../components/Spinner/Spinner';
import { L } from '../../../lib/i18n';
import useGetProperties from '../../../hooks/useGetProperties';
import AlertCard from '../components/AlertCard';
import MultiSelect from '../../../components/Dropdown/MultiSelect';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import useEditAlert, { EditAlertType } from './useEditAlert';
import { useEffect } from 'react';
import { useGetPropertyNames } from '../hooks/alertsQuery';

const Container = styled.div({
    display: 'grid',
    gridTemplateColumns: '350px 1fr',
    gap: '2rem',
});
const Title = styled.h2({
    fontSize: '1rem',
    marginBottom: '1rem',
});

const StyledForm = styled.form({
    display: 'grid',
    gridTemplateAreas: `
    "title title"
    "properties priority"
    "description description"
    "from to"
    "button button"
    `,
    gap: '0px 1rem',
});

const SaveButton = styled(Button)({
    gridArea: 'button',
    justifySelf: 'end',
    marginTop: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
});

const priorities: AlterPriorityType[] = ['low', 'medium', 'high'];

type Props = {
    alert: Alert;
};
const EditAlert = ({ alert }: Props) => {
    const [editAlert, setEditAlert] = useState<EditAlertType>({
        title: alert.title,
        priority: alert.priority,
        description: alert.description,
        publish_from_date: dayjs(alert.publish_from_date).format('YYYY-MM-DDThh:mm'),
        publish_to_date: dayjs(alert.publish_to_date).format('YYYY-MM-DDThh:mm'),
    });

    const [selectedProperties, setSeclectedProperties] = useState<{ value: number; label: string }[]>([]);

    const handleChange = (key: keyof EditAlertType, value: string) => {
        setEditAlert((prev) => ({ ...prev, [key]: value }));
    };

    const handlePropertyChange = (values: { value: number; label: string }[]) => {
        setSeclectedProperties(values);
    };
    const { data: properies } = useGetProperties();
    const { mutate: saveAlert, status } = useEditAlert();
    const { data: selectedPropertiesData } = useGetPropertyNames(alert.id);

    const history = useHistory();

    useEffect(() => {
        if (!selectedPropertiesData) return;
        setSeclectedProperties(
            selectedPropertiesData.map((property) => ({ value: property.propertyid, label: property.name }))
        );
    }, [selectedPropertiesData]);

    const handleSave = () => {
        const propertyIds = selectedProperties.length
            ? selectedProperties.map((property) => property.value)
            : properies?.map((property) => property.id);
        if (!propertyIds?.length) return toast.error(L('no_properies_selected'));
        saveAlert(
            {
                ...editAlert,
                id: alert.id,
                propertyIds,
            },
            {
                onSuccess: () => {
                    history.push('/alerts');
                },
            }
        );
    };

    const propteryOptions = properies
        ? [...properies?.map((property) => ({ value: property.id, label: property.name }))]
        : [];

    const propertyNames = selectedProperties.length
        ? selectedProperties?.map((property) => property.label)
        : properies?.map((property) => property.name);

    return (
        <Container>
            <div>
                <Title>{L('alert_preview')}</Title>
                <AlertCard
                    description={editAlert.description}
                    priority={editAlert.priority}
                    publish_from_date={editAlert.publish_from_date}
                    publish_to_date={editAlert.publish_to_date}
                    title={editAlert.title}
                    propertyNames={propertyNames || []}
                />
            </div>

            <div>
                <Title>{L('alert_edit')}</Title>
                <StyledForm>
                    <Label style={{ gridArea: 'title' }} title={L('title')}>
                        <Input value={editAlert.title} onChange={(e) => handleChange('title', e.target.value)} />
                    </Label>
                    <Label style={{ gridArea: 'description' }} title={L('information')}>
                        <TextAreaInput
                            value={editAlert.description}
                            onChange={(e) => handleChange('description', e.target.value)}
                        />
                    </Label>
                    <Label style={{ gridArea: 'priority' }} title={L('priority')}>
                        <Dropdown
                            value={editAlert.priority}
                            onChange={(value) => handleChange('priority', value)}
                            options={priorities.map((prio) => ({ value: prio, label: L(prio) }))}
                        />
                    </Label>
                    <Label style={{ gridArea: 'properties' }} title={L('properties')}>
                        <MultiSelect
                            value={selectedProperties}
                            onChange={(value: { value: number; label: string }[]) => {
                                console.log(value);
                                handlePropertyChange(value);
                            }}
                            placeholder={
                                selectedProperties.length === 0 || selectedProperties.length === properies?.length
                                    ? L('all_properies_selected')
                                    : `${selectedProperties?.[0].label} ${
                                          selectedProperties.length - 1 > 0 ? `+${selectedProperties.length - 1}` : ''
                                      }`
                            }
                            isMulti={true}
                            options={propteryOptions}
                        />
                    </Label>
                    <Label style={{ gridArea: 'from' }} title={L('publish_from')}>
                        <Input
                            value={editAlert.publish_from_date}
                            type={'datetime-local'}
                            onChange={(e) => handleChange('publish_from_date', e.target.value)}
                        />
                    </Label>
                    <Label style={{ gridArea: 'to' }} title={L('publish_to')}>
                        <Input
                            value={editAlert.publish_to_date}
                            type={'datetime-local'}
                            onChange={(e) => handleChange('publish_to_date', e.target.value)}
                        />
                    </Label>

                    <SaveButton onClick={handleSave} disabled={status === 'loading'}>
                        {status === 'loading' && <Spinner />}
                        {status !== 'loading' && L('save')}
                    </SaveButton>
                </StyledForm>
            </div>
        </Container>
    );
};

export default EditAlert;
