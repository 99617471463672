type FolderRoles = 'received' | 'draft' | 'sent' | 'trash';
export const getMailKeys = {
    all: [{ scope: 'mail' }] as const,
    lists: () => [{ ...getMailKeys.all[0], entity: 'list' }] as const,
    list: (searchValue: string, folderId: number, page: number) =>
        [{ ...getMailKeys.lists()[0], searchValue, folderId, page }] as const,
    counts: () => [{ ...getMailKeys.all[0], entitiy: 'count' }] as const,
    count: (folderId: number) => [{ ...getMailKeys.counts()[0], folderId }] as const,
    getByIds: () => [{ ...getMailKeys.all[0], entity: 'getbyid' }] as const,
    getById: (id: number) => [{ ...getMailKeys.getByIds()[0], id }] as const,
    listFolders: () => [{ ...getMailKeys.all[0], entity: 'listFolders' }] as const,
    listFolder: (roles?: FolderRoles[]) => [{ ...getMailKeys.listFolders()[0], roles }] as const,
    folderByIds: () => [{ ...getMailKeys.all[0], entity: 'folderNameByIds' }] as const,
    folderById: (folderId: number) => [{ ...getMailKeys.folderByIds()[0], folderId }] as const,
};
