import React from 'react';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';

const ThemedArticle = styled.article<{ isHighlighted: boolean }>(({ theme, isHighlighted }) => {
    return {
        marginTop: '2.25rem',
        background: theme?.colors?.white,
        padding: `2rem 1rem 2rem 1rem`,
        borderRadius: style.margin.s,
        borderColor: isHighlighted ? theme?.colors?.primary : theme.colors.black30,
        borderStyle: 'solid',
        borderWidth: '2px',
        '&:first-of-type': {
            marginTop: '0px',
        },
    };
});

const Article = ({ isHighlighted, ...rest }) => {
    return <ThemedArticle isHighlighted={isHighlighted} {...rest} />;
};
export default Article;
