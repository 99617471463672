import * as React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Header } from '../../components/Layout/Header';
import { L } from '../../lib/i18n';
import BigTabNav from '../../components/BigTabItem/BigTabNav';
import styled from '@emotion/styled';
import MailsPage from './pages/MailsPage';
import MailPage from './pages/MailPage';
import AddMailPage from './pages/AddMailPage';
import { colors } from '../../components/Shared/Style/colors';
import { useGetFolders, useGetUnreadMailCount, useInitializeMaps } from './hooks/mailQueries';
import BigNavItem from './components/BigTabListItemNav';
// import { useGetProject } from './hooks/projectQueries';
import useGetProjectInfo from '../../hooks/useGetProjectInfo';
import Spinner from '../../components/Spinner/Spinner';
import EmptyState from '../../components/EmptyState/EmptyState';

const Menu = styled.div({
    display: 'flex',
    flexFlow: 'column nowrap',
    padding: '0 0.5rem',
    '@media screen and (max-width: 800px)': {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexFlow: 'column wrap',
        ['a']: {
            fontSize: '1em',
        },
    },

    '@media screen and (max-width: 500px)': {
        overflowX: 'scroll',
        ['a']: {
            fontSize: '0.9em',
        },
    },
});

const CountContainer = styled.div({
    marginLeft: 10,
    display: 'flex',
    paddingRight: '1rem',
    paddingLeft: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '20px',
    backgroundColor: colors.primary,
    color: 'white',
    textAlign: 'center',
});

const FlexContainer = styled.div({
    display: 'flex',
});

const Line = styled.div({
    borderRight: 'solid 1px gray',
});

export const Routes = () => {
    const { data: unreadCount } = useGetUnreadMailCount(0);
    const { data: folders, isLoading: isLoadingFolders } = useGetFolders();
    const { data: project, isLoading } = useGetProjectInfo();
    const mail = useRouteMatch<{ id: string }>({ path: `/mail/view/:id` });
    const { mutate: initializeMaps, status } = useInitializeMaps();

    const addmail = useRouteMatch({
        path: `/mail/new`,
    });
    const addmail_draft = useRouteMatch({
        path: `/mail/new/:id`,
    });

    const openMail = () => {
        window.location.href = `mailto:support@ourliving.se?subject=${L('add_mail_to')} ${project?.name}`;
    };

    if (isLoading || isLoadingFolders) {
        return (
            <>
                <Header title={L('mail')} />
                <EmptyState>
                    <EmptyState.Title>
                        <Spinner />
                    </EmptyState.Title>
                </EmptyState>
            </>
        );
    }

    if (!project?.ourlivingmail_username || project?.ourlivingmail_username.length < 2) {
        return (
            <>
                <Header title={L('mail')} />

                <EmptyState>
                    <EmptyState.Title>{L('no_mail_set')}</EmptyState.Title>
                    <EmptyState.Description>{L('no_mail_set_desc')}</EmptyState.Description>

                    <EmptyState.Button onClick={openMail} style={{ margin: 'auto' }}>
                        support@ourliving.se
                    </EmptyState.Button>
                </EmptyState>
            </>
        );
    }

    if (!folders || folders.length < 3) {
        return (
            <>
                <Header title={L('mail')} />
                <EmptyState>
                    {L('welcome_to_mail_function')}
                    <EmptyState.Description>{L('mail_function_is_now_ready_to_be_used')}</EmptyState.Description>
                    <EmptyState.Button
                        disabled={status === 'loading'}
                        style={{ margin: 'auto' }}
                        onClick={() => initializeMaps()}
                    >
                        {L('get_started')}
                    </EmptyState.Button>
                </EmptyState>
            </>
        );
    }
    return (
        <>
            <Header title={L('mail')} />

            <Menu>
                <BigTabNav>
                    <BigNavItem exact to={`/mail`}>
                        <FlexContainer>
                            {L('inbox')} {Number(unreadCount) > 0 && <CountContainer>{unreadCount}</CountContainer>}
                        </FlexContainer>
                    </BigNavItem>
                    {folders?.map((folder) => (
                        <BigNavItem exact to={`/mail/folder/${folder.id}`} key={folder.id}>
                            {folder.editable ? folder.name : L(folder.name)}
                        </BigNavItem>
                    ))}
                    {addmail || addmail_draft ? (
                        <>
                            <Line />
                            <BigNavItem to={`/mail/new`}>{L('add_mail')}</BigNavItem>
                        </>
                    ) : mail ? (
                        <>
                            <Line />
                            <BigNavItem exact to={`/mail/view/${mail.params.id}`}>
                                {L('view_mail')}
                            </BigNavItem>
                        </>
                    ) : null}
                </BigTabNav>
            </Menu>
            <Switch>
                <Route exact path="/mail/new">
                    <AddMailPage />
                </Route>
                <Route exact path="/mail/view/:id">
                    <MailPage />
                </Route>
                <Route exact path="/mail">
                    <MailsPage />
                </Route>
                <Route exact path="/mail/folder/:id">
                    <MailsPage />
                </Route>
            </Switch>
        </>
    );
};
