import React, { useState, useEffect } from 'react';
import reactCSS from 'reactcss';
import { TwitterPicker } from 'react-color';
import randomColor from 'randomcolor';
import { color } from '@storybook/theming';

type Props = {
    getValue: (value: string) => void;
};

const ColorPicker = ({ getValue }: Props) => {
    const color123 = randomColor<string>({
        luminosity: 'random',
        format: 'rgb',
    });

    const [r, g, b] = color123.match(/\d+/g);

    const [state, setState] = useState({
        displayColorPicker: false,
        color: {
            r,
            g,
            b,
        },
    });

    useEffect(() => {
        getValue(`rgb(${state.color.r}, ${state.color.g}, ${state.color.b})`);
    }, [state]);

    const handleClick = () => {
        const copyState = { ...state };
        setState({ ...copyState, displayColorPicker: !copyState.displayColorPicker });
    };

    const handleClose = () => {
        const copyState = { ...state };
        setState({ ...copyState, displayColorPicker: false });
    };

    const handleChange = (color: { rgb: { r: string; g: string; b: string } }) => {
        const copyState = { ...state };
        setState({ ...copyState, color: color.rgb });
    };

    const styles = reactCSS({
        default: {
            color: {
                width: '280px',
                height: '20px',
                borderRadius: '2px',
                background: `rgb(${state.color.r}, ${state.color.g}, ${state.color.b})`,
            },
            swatch: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                width: '300px',
                border: '1px solid gray',
                height: '40px',
                background: '#fff',
                borderRadius: '4px',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });
    return (
        <div>
            <div style={styles.swatch} onClick={handleClick}>
                <div style={styles.color} />
            </div>
            {state.displayColorPicker ? (
                <div style={styles.popover}>
                    <div style={styles.cover} onClick={handleClose} />
                    <TwitterPicker color={state.color} onChange={handleChange} />
                </div>
            ) : null}
        </div>
    );
};

export default ColorPicker;
