import { useQuery } from 'react-query';
import MaintenancesApi from '@app/api/public/MaintenancesApi';

export const getMaintenanceQueryKey = 'getMaintenance';

const getMaintenance = async (id?: string) => {
    if(!id) return
    const r = await MaintenancesApi.id(+id);
    return r;
};

const useGetMaintenance = (id?: string) => {
    return useQuery([getMaintenanceQueryKey, { id }], () => getMaintenance(id), {enabled: !!id});
};

export default useGetMaintenance;
