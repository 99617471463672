import * as React from 'react';
import { useObserver } from 'mobx-react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button } from '../../../components/Buttons/Button';
import { Loading } from '../../../components/Loading/Loading';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { useEffect, useState } from 'react';
import Label from '../../../components/Forms/Label';
import { useGetBoardMemberById } from '../hooks/useGetBoardMembers';
import useGetSubRoles, { useGetRoles } from '../hooks/useGetRoles';
import useSaveBoardMember from '../hooks/useSaveBoardMember';
import toast from 'react-hot-toast';
import { InviteBoardMember } from '@app/api/models/BoardMembers/BoardMembersModels';
import { margin } from '../../../components/Shared/Style/margin';
import useRemoveNewBoardMember from '../hooks/useRemoveBoardMember';
import useInviteBoardMember from '../hooks/useInviteBoardMember';

import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import checkUnsavedData from '../../../lib/helpers/checkUnsavedData';
import { formatDateIsoDayjs } from '@app/shared/lib/formatting';
import Input from '../../../components/Forms/Input';
import useGetPropertiesForMember from '../../members/hooks/useGetPropertiesForMember';
import { Checkbox } from '../../../components/Checkbox/Checkbox';

//#region css

const GridContainer = styled.div({
    display: 'grid',
    gap: '10rem',
    margin: '1rem 0',
    '@media screen and (min-width: 1100px)': {
        gridTemplateColumns: '2.5fr 1fr',
    },
});

const StyledCard = styled(CardWithDivider)({
    placeSelf: 'start',
    width: '100%',
});

const Span = styled.span({
    display: 'block',
    lineHeight: '1.5em',
});

const TextContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '60%',
});

const ContactInfo = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'right',
    fontSize: '12px',
});

const ButtonContainer = styled.div({
    width: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
});

const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

//#endregion

export const BoardMembersDetailPage = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const { data: boardMember } = useGetBoardMemberById(+id);
    const { data: properties } = useGetPropertiesForMember(boardMember?.user_id);
    const { mutate: saveBoardMember } = useSaveBoardMember();
    const { mutate: removeBoardMember } = useRemoveNewBoardMember();
    const { mutate: inviteBoardMember } = useInviteBoardMember();

    const roles = useGetRoles();
    const { data: subRoles } = useGetSubRoles();

    const [boardMemberState, setBoardMemberState] = useState<InviteBoardMember>({
        email: '',
        sub_role: '',
        role: '',
        display_incontacts: false,
    });
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        if (!boardMember) return;
        setBoardMemberState({ ...boardMember, role: boardMember?.role });
        setBoardMemberState({ ...boardMember, sub_role: boardMember?.sub_role });
    }, [boardMember]);

    const onChange = (value: string, name: string) => {
        setBoardMemberState({ ...boardMemberState, [name]: value });
    };

    const checkboxOnChange = (value: boolean, name: string) => {
        setBoardMemberState({ ...boardMemberState, [name]: value });
    };
    const save = () => {
        if (!boardMember) return;
        saveBoardMember(
            {
                email: boardMemberState.email,
                sub_role: boardMemberState.sub_role,
                role: boardMemberState.role,
                user_id: boardMember.user_id,
                display_incontacts: boardMemberState.display_incontacts,
            },
            {
                onSuccess: () => {
                    toast(() => `✅ ${L('member')} ${boardMemberState.email} ${L('saved')}`);
                },
            }
        );
    };

    const remove = () => {
        if (!boardMember) return;
        removeBoardMember(boardMember.id, {
            onSuccess: () => {
                toast(() => `✅ ${L('member')} ${boardMemberState.email} ${L('removed')}`);
                history.push('/boardmembers');
            },
        });
    };

    const invite = () => {
        if (!boardMember) return;
        inviteBoardMember(boardMember.id, {
            onSuccess: () => {
                toast(() => `✅ ${L('member')} ${boardMemberState.email} ${L('invited')}`);
            },
        });
    };

    const onSwitch = () => {
        if (!boardMember) {
            return <Loading />;
        }
        const localResource = {
            ...boardMemberState,
        };

        if (
            checkUnsavedData({
                buttonText: L('continue'),
                changedData: localResource,
                originalData: boardMember,
                fn: () => history.push('/boardmembers'),
            })
        )
            return;
        history.push('/boardmembers');
    };

    return useObserver(() => {
        if (!boardMember) {
            return <Loading />;
        }

        const main = (
            <>
                <GridContainer>
                    <TextContainer>
                        <Label title={L('boardmember_email')}>
                            <Input
                                disabled
                                defaultValue={boardMember.email || ''}
                                name="email"
                                onChange={(e) => onChange(e.target.value, e.target.name)}
                            />
                        </Label>
                        <Label title={L('phone_number')}>
                            <Input disabled defaultValue={boardMember.phone_number || ''} name="phone_number" />
                        </Label>
                        <Label title={L('boardmember_role')}>
                            <Dropdown
                                placeholder={L('boardmember_role')}
                                name={'role'}
                                options={roles}
                                value={boardMemberState.role}
                                onChange={(value, name) => {
                                    onChange(value, name);
                                }}
                            />
                        </Label>
                        <Label title={L('boardmember_subrole')}>
                            <Dropdown
                                placeholder={L('boardmember_subrole')}
                                name={'sub_role'}
                                options={
                                    subRoles?.map((subRole) => {
                                        return {
                                            value: subRole,
                                            label: L(`${subRole}`),
                                        };
                                    }) || []
                                }
                                value={boardMemberState.sub_role}
                                onChange={(value, name) => {
                                    onChange(value, name);
                                }}
                            />
                        </Label>
                        <Label title={L('boardmember_contact')}>
                            <Checkbox
                                checked={boardMemberState.display_incontacts || false}
                                onChange={(e) => checkboxOnChange(e.target.checked, 'display_incontacts')}
                                name="contact"
                            />
                        </Label>
                    </TextContainer>

                    <ContactInfo>
                        <Label title={L('last_login')}>
                            {boardMember.last_sign_in_at && formatDateIsoDayjs(boardMember.last_sign_in_at)}
                        </Label>
                        <Label title={L('confirmation_sent_at')}>
                            {boardMember.board_invited_at && formatDateIsoDayjs(boardMember.board_invited_at)}
                        </Label>
                        <Label title={L('confirmed_at')}>
                            {boardMember.confirmed_at && formatDateIsoDayjs(boardMember.confirmed_at)}
                        </Label>

                        <Label title={L('properties')}>
                            {properties && properties.map((property) => <Span key={property.id}>{property.name}</Span>)}
                        </Label>
                    </ContactInfo>
                </GridContainer>
                <ButtonContainer>
                    <Button onClick={onSwitch} style={{ marginRight: margin.s }} variant={'primary'}>
                        {L('cancel')}
                    </Button>
                    <Button onClick={save} style={{ marginRight: '0px' }} variant={'filledPrimary'}>
                        {L('save')}
                    </Button>
                </ButtonContainer>
            </>
        );

        return (
            <>
                <StyledCard
                    topArea={
                        <Wrapper>
                            <CardTitle>
                                {L('boardmember_description')} {boardMember.name}
                            </CardTitle>
                            <ButtonContainer>
                                {confirmDelete ? (
                                    <Button style={{ marginRight: margin.s }} onClick={remove} variant={'delete'}>
                                        {L('confirm')}
                                    </Button>
                                ) : (
                                    <Button
                                        style={{ marginRight: margin.s }}
                                        onClick={() => setConfirmDelete(true)}
                                        variant={'primary'}
                                    >
                                        {L('delete')}
                                    </Button>
                                )}
                                <Button variant="primary" onClick={() => invite()}>
                                    Bjud in
                                </Button>
                            </ButtonContainer>
                        </Wrapper>
                    }
                    mainArea={main}
                />
            </>
        );
    });
};
