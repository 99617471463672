import styled from '@emotion/styled';
import React from 'react';
import toast from 'react-hot-toast';
import PushNotificationsSettings from '../../areas/profile/Components/PushNotificationsSettings';
import { requestPermission } from '../../firebase';
import { L } from '../../lib/i18n';
import { Button } from '../Buttons/Button';
import H2 from '../typography/H2';

const PermissionWrapper = styled.div({});
const ButtonWrapper = styled.div({
    display: 'flex',
    gap: '1rem',
    justifyContent: 'end',
    marginTop: '2rem',
});

const StyledH2 = styled(H2)(({ theme }) => ({
    margin: '1rem 0 0.25rem 0',
    fontSize: '1rem',
}));

const PushPermission = ({ toastId }: { toastId: string }) => {
    const onAccept = () => {
        requestPermission();
        toast.dismiss(toastId);
    };
    const onDeny = () => {
        toast.dismiss(toastId);
    };
    return (
        <PermissionWrapper>
            <StyledH2>{L('allow_notificationMessage')}</StyledH2>

            <PushNotificationsSettings />

            <ButtonWrapper>
                <Button variant={'secondary'} style={{ margin: '0px' }} onClick={() => onDeny()}>
                    {L('deny')}
                </Button>
                <Button variant={'primary'} onClick={() => onAccept()}>
                    {L('accept')}
                </Button>
            </ButtonWrapper>
        </PermissionWrapper>
    );
};

export default PushPermission;
