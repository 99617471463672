import { configure, RpcRequestInit } from 'koa-rpc-call/browser';

const appVersion = HEROKU_RELEASE_VERSION || GIT_SHA || 'develop';

async function onRequest(info: RpcRequestInit, finish: (i: RpcRequestInit) => Promise<any>) {
    if (!info.headers) info.headers = {};
    const refreshed = window.sessionStorage.getItem(`retry-${appVersion}-refreshed`);
    info.headers['Authorization'] = localStorage.TOKEN ? `Basic ${localStorage.TOKEN}` : '';
    info.headers['x-app-version'] = appVersion;
    refreshed && (info.headers['x-app-version-retry'] = refreshed);

    try {
        return await finish(info);
    } catch (ex) {
        if ((ex as RTCError) && ex?.response?.status === 401) {
            delete localStorage.TOKEN;
            console.log('restart 401');
            location.reload();
        }

        const hasRefreshed = JSON.parse(window.sessionStorage.getItem(`retry-${appVersion}-refreshed`) || 'false');
        if (ex?.response?.status === 418 && !hasRefreshed) {
            window.sessionStorage.setItem(`retry-${appVersion}-refreshed`, 'true'); // we are now going to refresh
            console.log('restart 418, appVersion: ', appVersion);
            if (Boolean(refreshed)) {
                location.reload();
            }
        }
    }
}

configure({ onRequest });
