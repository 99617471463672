import React, { useEffect, useState } from 'react';
import { L } from '../../../lib/i18n';
import { style } from '../../../components/Shared/Style';
import { ChangePasswordProfilePage } from './ChangePasswordProfilePage';
import useGetProfile from '../../../hooks/useGetProfile';
import { Loading } from '../../../components/Loading/Loading';
import useSaveProfile, { profileFactory } from '../hooks/useSaveProfile';
import isEqual from 'lodash/isEqual';
import { Button } from '../../../components/Buttons/Button';
import Spinner from '../../../components/Spinner/Spinner';
import toast from 'react-hot-toast';
import styled from '../../../components/lib/styled';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import { ProfileDetail } from '@app/api/public/ProfilesApi';

export type EditProfileDetail = Partial<
    {
        addressline1: string;
        addressline2: string;
        postal_code: string;
        city: string;
        country: string;
    } & Omit<ProfileDetail, 'address' | 'updated_at' | 'created_at'>
>;

const Forms = styled.form({
    width: '100%',
    display: 'grid',
    alignSelf: 'center',

    paddingTop: style.margin.m,
    paddingBottom: style.margin.l,
    [`@media screen and (min-width: ${650}px)`]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: `0px clamp(1rem, 2.5vw, 1.5rem)`,
    },
});
const Save = styled.div({
    gridColumn: '1 / -1',
    placeSelf: 'end',
    paddingTop: '1rem',
    [`@media screen and (min-width: ${650}px)`]: {
        paddingTop: '0px',
    },
});

export const ProfileSettings = () => {
    const profileQuery = useGetProfile();

    const [editedProfile, setEditedProfile] = useState<Partial<EditProfileDetail>>({});
    const { mutate: saveProfile, status } = useSaveProfile();

    useEffect(() => {
        if (!profileQuery.data) return;
        const data = profileQuery.data;
        setEditedProfile({
            name: data.name,
            phone_number: data.phone_number,
            id: data.id,
            email: data.email,
            ...data.address,
        });
    }, [profileQuery.data]);

    useEffect(() => {
        if (!profileQuery.data) return;
        if (isEqual(profileQuery.data, editedProfile)) return;
        if (!editedProfile) return;
    }, [editedProfile]);

    const onProfileChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = evt.target;
        const { value } = evt.target;
        setEditedProfile((prev) => ({ ...prev, [name]: value }));
    };

    const onSave = (e) => {
        e.preventDefault();
        saveProfile(profileFactory(editedProfile), {
            onSuccess: () => {
                toast.success('Profil sparad');
            },
        });
    };

    if (profileQuery.status === 'loading') return <Loading />;
    if (!editedProfile) return null;
    const profile = editedProfile;
    return (
        <>
            <Forms>
                <Label title={L('name')}>
                    <Input name="name" value={profile?.name || ''} onChange={onProfileChange} />
                </Label>

                <Label title={L('addressline1')}>
                    <Input name="addressline1" value={profile?.addressline1 || ''} onChange={onProfileChange} />
                </Label>
                <Label title={L('addressline2')}>
                    <Input name="addressline2" value={profile?.addressline2 || ''} onChange={onProfileChange} />
                </Label>

                <Label title={L('postal_code')}>
                    <Input name="postal_code" value={profile?.postal_code || ''} onChange={onProfileChange} />
                </Label>
                <Label title={L('profile_city')}>
                    <Input name="city" value={profile?.city || ''} onChange={onProfileChange} />
                </Label>

                <Label title={L('country')}>
                    <Input name="country" value={profile?.country || ''} onChange={onProfileChange} />
                </Label>

                <Label title={L('email')}>
                    <Input type={'email'} name="email" value={profile?.email || ''} readOnly={true} />
                </Label>
                <Label title={L('phone_number')}>
                    <Input
                        type={'tel'}
                        name="phone_number"
                        value={profile?.phone_number || ''}
                        onChange={onProfileChange}
                    />
                </Label>
                <Save>
                    <Button
                        style={{ marginTop: '12px', display: 'flex', justifyContent: 'center' }}
                        variant="selectedMenu"
                        onClick={onSave}
                    >
                        {status === 'loading' ? <Spinner /> : L('save')}
                    </Button>
                </Save>
            </Forms>
            <ChangePasswordProfilePage />
        </>
    );
};
