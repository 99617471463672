import React, { FC } from 'react';
import parse, { attributesToProps, HTMLReactParserOptions, Element, domToReact } from 'html-react-parser';
import styled from '@emotion/styled';

const OL = styled.ol(({ theme }) => ({
    //
    color: theme.colors.textColor2,
    listStyleType: 'decimal',
    paddingLeft: '2rem',
}));
const UL = styled.ul(({ theme }) => ({
    color: theme.colors.textColor2,
    listStyleType: 'disc',
    paddingLeft: '2rem',
    //
}));

const LI = styled.li(({ theme }) => ({
    color: theme.colors.textColor2,
    listStyleType: 'inherit',
    //
}));

const Img = styled.img(({ theme }) => ({
    maxWidth: '100%',
}));

const H1 = styled.h1(({ theme }) => {
    /* Header 1 */
    return {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '2rem',
        lineHeight: '100%',
        marginTop: '0px',

        letterSpacing: '0.41px',

        /* Text color 2 */
        color: theme.colors.textColor2,
    };
});

const H2 = styled.h2(({ theme }) => {
    return {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '1.5rem',
        lineHeight: '100%',
        marginTop: '0px',
        letterSpacing: '0.41px',
        color: theme.colors.textColor2,
    };
});

const H3 = styled.h3(({ theme }) => {
    return {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1rem',
        lineHeight: '19px',
        color: theme.colors.textColor2,
    };
});

const P = styled.p(({ theme }) => {
    return {
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: '1rem',
        lineHeight: '150%',
        margin: '0px',
        color: theme.colors.textColor2,
    };
});

const options: HTMLReactParserOptions = {
    replace: (domNode) => {
        if (domNode instanceof Element && domNode.attribs) {
            const props = attributesToProps(domNode.attribs);
            const jsx = domToReact(domNode.children, options);
            if (domNode.name === 'h1') return <H1 {...props}>{jsx}</H1>;
            if (domNode.name === 'h2') return <H2 {...props}>{jsx}</H2>;
            if (domNode.name === 'h3') return <H3 {...props}>{jsx}</H3>;
            if (domNode.name === 'p') {
                return <P {...props}>{jsx}</P>;
            }
            if (domNode.name === 'ol') return <OL {...props}>{jsx}</OL>;
            if (domNode.name === 'ul') return <UL {...props}>{jsx}</UL>;
            if (domNode.name === 'li') return <LI {...props}>{jsx}</LI>;
            if (domNode.name === 'img') {
                return <Img {...props} />;
            }
            if (domNode.name === 'span') return <>{jsx}</>;
        }
    },
};

export const RawHtml = ({ content }: { content: string }) => {
    return <>{parse(content, options)}</>;
};
