import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import BigTabNav from '../../components/BigTabItem/BigTabNav';
import PageTitle from '../../components/typography/PageTitle';
import { L } from '../../lib/i18n';
import EditTicket from './components/EditTicket';
import TicketListItem from './components/TickeListtItem';
import { useGetTickets } from './hooks/ticketQueries';
import BigNavItem from '../../components/BigTabItem/BigTabListItemNav';
import { Separator } from '../../components/Separator/Separator';
import TicketsArchive from './components/TicketsArchive';
import CustomerCommunication from './components/CustomerCommunication';
import Notes from './components/Notes';
import Log from './components/Log';
import { TicketFilter } from '@app/api/models/Tickets';

import TicketFilters from './components/TicketFilters';
import HandleInitialTicketData from './components/HandleInitialTicketData';
import { useTicketSettingQuery } from '../settings/hooks/TicketSettingsQueries';
import { Loading } from '../../components/Loading/Loading';
import P from '../../components/typography/P';

const PageWrapper = styled.div({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: '2rem',
    alignItems: 'start',
});

const TicketListWrapper = styled.div({
    width: '340px',
    display: 'grid',
    gap: '1rem',
});

const TicketsPage = () => {
    const [filter, setFilter] = useState<TicketFilter>({
        assignee: undefined,
        property: undefined,
    });
    const { data: tickets } = useGetTickets(filter, true);

    const handleFilter = <T extends keyof TicketFilter>(key: T, value: TicketFilter[T]) => {
        setFilter({ ...filter, [key]: value });
    };

    const { data: ticketEmailSettings, status } = useTicketSettingQuery();

    if (status === 'loading') {
        return (
            <>
                <PageTitle>{L('tickets')}</PageTitle>
                <Loading />
            </>
        );
    }

    if (status === 'success' && !ticketEmailSettings?.transferred_at) {
        return (
            <>
                <PageTitle>{L('tickets')}</PageTitle>
                <P>{L('system_tickets_transferred_at_info')}</P>
            </>
        );
    }
    if (status === 'success' && ticketEmailSettings?.service_email) {
        return (
            <>
                <PageTitle>{L('tickets')}</PageTitle>
                <P>{L('system_tickets_deactivated_info')}</P>
            </>
        );
    }
    return (
        <>
            <PageTitle>{L('tickets')}</PageTitle>
            <BigTabNav>
                <BigNavItem exact to={'/tickets'}>
                    {L('new_ticket')}
                </BigNavItem>
                <BigNavItem exact to={'/tickets/archive'}>
                    {L('archive')}
                </BigNavItem>
                <Switch>
                    <Route
                        path={'/tickets/archive/:id'}
                        render={({ match }) => {
                            const id = match.params.id;
                            return (
                                <>
                                    <Separator decorative orientation={'vertical'} />
                                    <BigNavItem exact to={`/tickets/archive/${id}`}>
                                        {L('customer_communication')}
                                    </BigNavItem>
                                    <BigNavItem exact to={`/tickets/archive/${id}/edit`}>
                                        {L('detail_archived_ticket')}
                                    </BigNavItem>
                                    <BigNavItem exact to={`/tickets/archive/${id}/notes`}>
                                        {L('notes')}
                                    </BigNavItem>
                                    <BigNavItem exact to={`/tickets/archive/${id}/log`}>
                                        {L('log')}
                                    </BigNavItem>
                                </>
                            );
                        }}
                    />
                    <Route path={'/tickets/archive'} render={() => null} />
                    <Route
                        path={'/tickets/:id'}
                        render={({ match }) => {
                            const id = match.params.id;
                            return (
                                <>
                                    <Separator decorative orientation={'vertical'} />
                                    <BigNavItem exact to={`/tickets/${id}`}>
                                        {L('customer_communication')}
                                    </BigNavItem>
                                    <BigNavItem exact to={`/tickets/${id}/edit`}>
                                        {L('edit_ticket')}
                                    </BigNavItem>
                                    <BigNavItem exact to={`/tickets/${id}/notes`}>
                                        {L('notes')}
                                    </BigNavItem>
                                    <BigNavItem exact to={`/tickets/${id}/log`}>
                                        {L('log')}
                                    </BigNavItem>
                                </>
                            );
                        }}
                    />
                </Switch>
            </BigTabNav>

            <Switch>
                <Route path={'/tickets/archive/:id'}>
                    <Switch>
                        <Route path="/tickets/archive/:id/log">
                            <Log />
                        </Route>
                        <Route path="/tickets/archive/:id/notes">
                            <Notes readOnly />
                        </Route>
                        <Route path="/tickets/archive/:id/edit">
                            <HandleInitialTicketData readOnly />
                        </Route>
                        <Route path="/tickets/archive/:id">
                            <CustomerCommunication readOnly />
                        </Route>
                    </Switch>
                </Route>
                <Route path={'/tickets/archive'}>
                    <TicketsArchive />
                </Route>
                <Route path={'/tickets'}>
                    <TicketFilters
                        tickets={
                            tickets?.map((item) => ({
                                ...item,
                            })) || []
                        }
                        handleFilter={handleFilter}
                        filter={filter}
                    />
                    <PageWrapper>
                        <TicketListWrapper>
                            {tickets?.map((ticket) => (
                                <TicketListItem key={ticket.id} {...ticket} />
                            ))}
                        </TicketListWrapper>
                        <Switch>
                            <Route path="/tickets/:id/log">
                                <Log />
                            </Route>
                            <Route path="/tickets/:id/notes">
                                <Notes />
                            </Route>
                            <Route path="/tickets/:id/edit">
                                {/* Uses HandleInitialTicketData to unmount EditTicket */}
                                <HandleInitialTicketData />
                            </Route>
                            <Route path="/tickets/:id">
                                <CustomerCommunication />
                            </Route>
                            <Route exact path="/tickets">
                                <EditTicket />
                            </Route>
                        </Switch>
                    </PageWrapper>
                </Route>
            </Switch>
        </>
    );
};

export default TicketsPage;
