import * as React from 'react';
import { useModal } from '../../../components/Modal/Context/ModalContext';
import Modal from '../../../components/Modal/Modal';
import NewMemberForm from '../NewMember/NewMemberForm';
import MembersTablePage from './MembersTablePage';

export const MembersListPage = () => {
    const { ToggleIsModalOpen } = useModal();

    return (
        <>
            <MembersTablePage ToggleIsModalOpen={ToggleIsModalOpen} />

            <Modal>
                <NewMemberForm />
            </Modal>
        </>
    );
};

export default MembersListPage;
