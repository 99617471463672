import { useQuery } from 'react-query';
import ProjectSettingsApi from '@app/api/public/ProjectSettingsApi';

const getPreviewPagesSettings = async () => {
    return ProjectSettingsApi.getCommunityFeaturePreview();
};
const useGetPreviewPagesSettings = () => {
    return useQuery(['previewPages'], () => getPreviewPagesSettings());
};

export default useGetPreviewPagesSettings;
