import React from 'react';
import styled from '@emotion/styled';
import { StyleColumn } from '../../../types/ColumnType';
import { Link } from 'react-router-dom';
import { style } from '../../../components/Shared/Style';
import dayjs from 'dayjs';
import sv from 'dayjs/locale/sv';
import { colors } from '../../../components/Shared/Style/colors';
import { margin } from '../../../components/Shared/Style/margin';
import { Property, PropertyList } from '@app/api/models/Property';
import { User } from '@app/api/models/Users';
import { L } from '../../../lib/i18n';
import Swap from '../../../components/Icon/themed/Swap';
import { formatDateIsoDayjs } from '@app/shared/lib/formatting';
dayjs.locale(sv);

const Span = styled.span({
    textAlign: 'left',
    fontSize: '14px',
});

const Properties = styled.div({
    fontSize: style.fontSize.milli,
    textAlign: 'left',
    gap: '0.5rem',
    'span:not(:last-child):after': {
        content: `","`,
    },
});

const StyledNavLink = styled(Link)({
    textDecoration: 'none',
    color: 'inherit',
    display: 'block',
    width: '100%',
    height: '100%',
});

const StyledP = styled.p({
    color: colors.black80,
    marginTop: margin.s,
    fontSize: '12px',
});

const Center = styled.div({
    gap: '1ch',
    display: 'flex',
    width: '100%',
});

const Wrapper = styled.div({});

const UserWrapper = styled.div({});

const AccountConfirmationSpan = styled(Span)(({ statusColor }: { statusColor: string }) => {
    return {
        backgroundColor: statusColor,
        width: '3rem',
        border: '1px solid',
        padding: '4px 8px 4px 8px',
        textAlign: 'center',
        borderRadius: '15px',
        color: 'white',
    };
});

export const COLUMNS: StyleColumn<PropertyList>[] = [
    {
        Header: () => L('apartment_number'),
        id: 'apartment_number',
        accessor: (value) => value.attr.apartment_number,
        width: 100,
        Cell: ({ row }) => {
            const property = row.original;
            return (
                <StyledNavLink to={`/properties/${property.id}`}>
                    <Span>{property.attr.apartment_number}</Span>
                </StyledNavLink>
            );
        },
    },
    {
        Header: () => L('address'),
        id: 'address',
        accessor: (value) => value.attr?.street,
        width: 100,
        Cell: ({ row }) => {
            const property = row.original;
            return (
                <StyledNavLink to={`/properties/${property.id}`}>
                    <Properties>
                        <Span key={property.attr?.street}>{property.attr?.street}</Span>
                    </Properties>
                </StyledNavLink>
            );
        },
    },

    {
        Header: () => L('property_access_date'),
        id: 'property_access_date',
        accessor: (originalRow: Property) => {
            return dayjs(originalRow.attr?.access_date).valueOf();
        },
        width: 75,
        Cell: ({ row }) => {
            const property = row.original;
            return property.attr?.access_date ? (
                <Span>{dayjs(property.attr?.access_date).format('YYYY-MM-DD')}</Span>
            ) : (
                <Span>{L('date_missing')}</Span>
            );
        },
    },
    {
        Header: () => L('confirmed_at'),
        id: 'confirmed_at',
        accessor: (value) => value.users?.map((user) => user.confirmed_at),
        width: 75,
        Cell: ({ row }) => {
            const property = row.original as PropertyList;
            let confirmedMembers = 0;
            property.users?.map((user: User) => {
                user?.confirmed_at ? confirmedMembers++ : '';
            });
            if (property?.users === null) {
                return (
                    <Center>
                        <AccountConfirmationSpan statusColor={colors.black50}>0/0</AccountConfirmationSpan>
                    </Center>
                );
            }
            if (!confirmedMembers)
                return (
                    <Center>
                        <AccountConfirmationSpan statusColor="#F12B2C">
                            0/{property.users?.length || 0}
                        </AccountConfirmationSpan>
                    </Center>
                );
            else if (confirmedMembers === property.users?.length)
                return (
                    <Center>
                        <AccountConfirmationSpan statusColor="#469253">
                            {confirmedMembers}/{property.users?.length}
                        </AccountConfirmationSpan>
                    </Center>
                );
            else
                return (
                    <Center>
                        <AccountConfirmationSpan statusColor="#FEC400">
                            {confirmedMembers}/{property.users?.length}
                        </AccountConfirmationSpan>
                    </Center>
                );
        },
    },
    {
        Header: () => L('property_member_name'),
        id: 'property_member_name',
        accessor: (value) => value.users?.flatMap((user) => [user.name, user.email]),
        width: 100,
        Cell: ({ row }) => {
            const property = row.original;
            return (
                <Wrapper>
                    {property.users?.map((user: User) => {
                        return (
                            <UserWrapper key={user.id}>
                                <Span>{user.name}</Span>
                                <StyledP>{user.email}</StyledP>
                            </UserWrapper>
                        );
                    })}
                </Wrapper>
            );
        },
    },
    {
        Header: () => L('published_property'),
        id: 'published_property',
        accessor: (value) => value.published_at,
        width: 70,
        Cell: ({ row }) => {
            const property = row.original;
            return <Center>{property.published_at && <span>{formatDateIsoDayjs(property.published_at)}</span>}</Center>;
        },
    },
    {
        Header: () => L('ongoing_property_transfer'),
        id: 'ongoing_property_transfer',
        accessor: (value) => value.transfer_date,
        width: 70,
        Cell: ({ row }) => {
            const property = row.original;
            return (
                <Center>
                    {property.transfer_date && (
                        <>
                            <Swap /> <span>{dayjs(property.transfer_date).format('YYYY-MM-DD')}</span>
                        </>
                    )}
                </Center>
            );
        },
    },
];

export const COLUMNSMOBILE: StyleColumn<Property>[] = [
    {
        Header: () => L('property_name'),
        id: 'property_name',
        accessor: (value) => value.name,
        width: 150,
        Cell: ({ row }) => {
            const property = row.original;
            return (
                <StyledNavLink to={`/properties/${property.id}`}>
                    <Span>{property.name}</Span>
                </StyledNavLink>
            );
        },
    },
    {
        Header: () => L('address'),
        id: 'address',
        accessor: (value) => (value.attr?.street, value.attr?.street),
        Cell: ({ row }) => {
            const property = row.original;
            return (
                <Properties>
                    <Span key={property.attr?.street}>{property.attr?.street}</Span>
                </Properties>
            );
        },
    },
];
