import React from 'react';
import styled from '@emotion/styled';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useDragEnd from '../hooks/useDragEnd';
import useGetPageListItems from '../hooks/useGetPageListItems';
import AddPageButton from './AddPageButton';
import NavItem from './NavItem';
import StaticHomePageMenuItem from '../../../areas/public-site/components/StaticHomePageMenuItem';
import StaticContactMenuItem from '../../../areas/public-site/components/StaticContactMenuItem';

const NavListWrapper = styled.div({
    width: '100%',
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'column',
});
const NavList = styled.ul({
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    margin: '0px',
    padding: '0px',
});

const PageItem = () => {
    const { mutate: dragEnd } = useDragEnd();
    const { data: pageListItems } = useGetPageListItems();

    if (!pageListItems) return null;
    return (
        <NavListWrapper>
            <NavList>
                <StaticHomePageMenuItem />
            </NavList>
            <DragDropContext onDragEnd={(result, provided) => dragEnd({ result, provided })}>
                <Droppable droppableId="linkItems">
                    {(provided) => (
                        <NavList className="linkItems" {...provided.droppableProps} ref={provided.innerRef}>
                            {pageListItems &&
                                pageListItems.map((pageListItem, index) => (
                                    <Draggable
                                        key={pageListItem.id + pageListItem.title}
                                        draggableId={pageListItem.id + pageListItem.title}
                                        index={index}
                                    >
                                        {(provided) => (
                                            <NavItem
                                                publicSiteInformationItem={pageListItem}
                                                innerRef={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            />
                                        )}
                                    </Draggable>
                                ))}
                            {provided.placeholder}
                        </NavList>
                    )}
                </Droppable>
            </DragDropContext>
            <NavList>
                <StaticContactMenuItem />
            </NavList>
        </NavListWrapper>
    );
};

export default PageItem;
