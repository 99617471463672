import styled from '@emotion/styled';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Button } from '../../../components/Buttons/Button';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import { desktop } from '../../../components/MediaQuery/breakpoints';
import { colors } from '../../../components/Shared/Style/colors';
import Spinner from '../../../components/Spinner/Spinner';
import H1 from '../../../components/typography/H1';
import { L } from '../../../lib/i18n';
import useSaveContact from '../hooks/useSaveContact';
import Dialog from '../../../components/Dialog/Dialog';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Close } from '@radix-ui/react-dialog';
import { useHistory } from 'react-router-dom';
import triggerDirtyFieldsToats from '../../../components/Forms/DirtyFieldsToast';
import { Avatar } from '@ourliving/ourliving-ui';

const ButtonWraper = styled.div({
    padding: '1rem 0px 0px 0px',
    display: 'flex',
    width: '100%',
    gap: '1rem',
    justifyContent: 'flex-end',
});

const Form = styled.form({});

const UploadArea = styled.div({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '80px',
    [`@media screen and (min-width: ${desktop}px)`]: {
        marginTop: '2rem',
    },
});

const ClickArea = styled.label({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
});

const ContactSchema = z.object({
    name: z.string().min(1),
    city: z.string().optional(),
    company: z.string().optional(),
    phone_number: z.string().optional(),
    email: z.string().email().optional().or(z.literal('')),
    title: z.string().optional(),
    info: z.string().optional(),
    file: z.instanceof(File).optional(),
});

const NewContactForm = () => {
    const { mutate, status } = useSaveContact();
    const history = useHistory();

    const [localImage, setlocalImage] = useState('');

    const {
        formState: { isValid, isDirty },
        register,
        handleSubmit,
        getValues,
        control,
        reset,
    } = useForm<z.infer<typeof ContactSchema>>({
        resolver: zodResolver(ContactSchema),
        defaultValues: {
            city: '',
            company: '',
            email: '',
            file: undefined,
            info: '',
            name: '',
            phone_number: '',
            title: '',
        },
    });

    return (
        <>
            <Dialog
                open={true}
                content={{
                    style: {
                        width: '400px',
                        padding: '2rem',
                        backgroundColor: colors.white,
                    },
                }}
                onOpenChange={(open) => {
                    if (isDirty) {
                        return triggerDirtyFieldsToats({
                            continueButtonText: L('continue_without_saving'),
                            continueFn: () => {
                                if (!open) history.push('/contacts');
                            },
                        });
                    }
                }}
            >
                <Form
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    onSubmit={handleSubmit((formValue) => {
                        mutate(
                            { ...formValue },
                            {
                                onSuccess: () => {
                                    toast.success(`${L('contact')} ${formValue.name} ${L('saved')}`);
                                    reset();
                                    history.push('/contacts');
                                },
                            }
                        );
                    })}
                >
                    <H1>{`${L('new')} ${L('contact')}`}</H1>
                    <Label title={L('profile_image')}>
                        <UploadArea>
                            <ClickArea>
                                <Avatar
                                    status={status}
                                    src={localImage && localImage}
                                    name={getValues('name') || getValues('email')}
                                />

                                <Controller
                                    name="file"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            name={field.name}
                                            ref={field.ref}
                                            onBlur={field.onBlur}
                                            onChange={(e) => {
                                                const file = e.target.files?.[0];
                                                if (file) setlocalImage(URL.createObjectURL(file));
                                                return field.onChange(file);
                                            }}
                                            style={{ display: 'none' }}
                                            type="file"
                                        />
                                    )}
                                />
                            </ClickArea>
                        </UploadArea>
                    </Label>
                    <Label title={L('name')}>
                        <Input
                            {...register('name', {
                                required: true,
                            })}
                            placeholder={L('name')}
                        />
                    </Label>
                    <Label title={L('contact_company')}>
                        <Input {...register('company')} placeholder={L('contact_company')} />
                    </Label>
                    <Label title={L('contact_role')}>
                        <Input {...register('title')} placeholder={L('contact_role')} />
                    </Label>
                    <Label title={L('phone_number')}>
                        <Input {...register('phone_number')} placeholder={L('phone_number')} />
                    </Label>

                    <Label title={L('contact_mail')}>
                        <Input {...register('email')} placeholder={L('contact_mail')} />
                    </Label>

                    <Label title={L('contact_city')}>
                        <Input {...register('city')} placeholder={L('contact_city')} />
                    </Label>
                    <Label title={L('contact_extra_information')}>
                        <Input {...register('info')} placeholder={L('contact_extra_information')} />
                    </Label>

                    <ButtonWraper>
                        <Close asChild>
                            <Button type="button" variant="primary">
                                {L('cancel')}
                            </Button>
                        </Close>

                        {status === 'idle' && (
                            <Button disabled={!isValid} type={'submit'} variant="filledPrimary">
                                {L('save')}
                            </Button>
                        )}
                        {status === 'loading' && (
                            <Button variant="filledPrimary" style={{ display: 'flex', justifyContent: 'center' }}>
                                <Spinner />
                            </Button>
                        )}
                        {status === 'error' && (
                            <Button type={'submit'} variant="filledPrimary">
                                {L('error')}
                            </Button>
                        )}
                        {status === 'success' && (
                            <Button type={'submit'} variant="filledPrimary">
                                {L('saved')}
                            </Button>
                        )}
                    </ButtonWraper>
                </Form>
            </Dialog>
        </>
    );
};

export default NewContactForm;
