import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { TableInstance } from 'react-table';
import { style } from '../../../../components/Shared/Style';
import SortArrows from '../../../../components/Table/sortArrows/SortArrows';
import GlobalFilter from '../../../../components/Table/filterComponents/GlobalFilter';
import { ListingMember } from '@app/api/public/MembersApi';

const Styles = styled.div(({ theme }) => ({
    /* These styles are suggested for the table fill all available space in its containing element */
    // display: 'block',

    '& .table': {
        borderSpacing: 0,
        '.th': {
            color: theme.colors.secondary,
            padding: `0px 16px ${style.margin.m}px 16px`,
            fontSize: style.fontSize.epsilon,

            /* These styles are required for a scrollable body to align with the header properly */
            overflowY: 'auto',
            overflowX: 'hidden',
        },

        '.td': {
            borderTop: `1px solid ${theme.colors.black50}`,
            fontSize: style.fontSize.centi,
            padding: '16px 16px',
            overflowX: 'hidden',
        },

        '.tbody': {
            /* These styles are required for a scrollable table body */
            overflowY: 'hidden',
            overflowX: 'hidden',
        },

        '.th, .td': {
            margin: 0,
            /* In this example we use an absolutely position resizer,
       so this is required. */

            position: 'relative',
            ':last-child': {
                paddingRight: '0px',
            },
            ':first-of-type': {
                width: '0px !important',
                flex: '50 0 auto !important',
                paddingLeft: '0px !important',
            },
        },
        '.td .tr div:first-of-type': {
            paddingLeft: '0px',
        },
    },
}));

const getStyles = (
    props,
    style: {
        justifyContent?: string;
        alignItems?: string;
        display?: string;
    }
) => [
    props,
    {
        style: {
            justifyContent: 'flex-start',
            alignItems: 'center',
            display: 'flex',
            ...style,
        },
    },
];
const headerProps = (props, { column }) => getStyles(props, column.align);

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

export type Props = {
    filterArea?: ReactNode;
    useTable: TableInstance<ListingMember>;
};

const FlexContainer = styled.div({
    display: 'flex',
    gap: '',
    alignItems: 'center',
});

const TableRowSelection = ({ filterArea, useTable }: Props & React.TableHTMLAttributes<HTMLTableElement>) => {
    const { getTableProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = useTable;

    return (
        <Styles>
            <div
                style={{
                    justifyContent: filterArea ? 'space-between' : 'end',
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '100%',
                    paddingBottom: '2rem',
                }}
            >
                {filterArea}
                <div style={{marginTop: '1rem'}}>
                    <GlobalFilter placeholder="Sök" filter={state.globalFilter} setFilter={setGlobalFilter} />
                </div>
            </div>
            <div {...getTableProps()} className="table">
                <div>
                    {headerGroups.map((headerGroup) => (
                        <div
                            {...headerGroup.getHeaderGroupProps({
                                // style: { paddingRight: '15px' },
                            })}
                            className="tr"
                            key={headerGroup.getFooterGroupProps().key}
                        >
                            {headerGroup.headers.map((column) => (
                                <div
                                    {...column.getHeaderProps(column.getSortByToggleProps(headerProps))}
                                    className="th"
                                    key={column.getHeaderProps().key}
                                >
                                    <FlexContainer>
                                        {column.render('Header')}
                                        {column.canSort && (
                                            <SortArrows
                                                activeSort={
                                                    column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : undefined
                                                }
                                            />
                                        )}
                                    </FlexContainer>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="tbody">
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <div {...row.getRowProps()} className="tr" key={row.getRowProps().key}>
                                {row.cells.map((cell) => {
                                    return (
                                        <div
                                            {...cell.getCellProps(cellProps)}
                                            className="td"
                                            key={cell.getCellProps().key}
                                        >
                                            {cell.render('Cell')}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </Styles>
    );
};

export default TableRowSelection;
