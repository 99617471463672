import { useQuery, useMutation, useQueryClient } from 'react-query';
import PushNotificationApi, { PushNotificationSetting } from '@app/api/public/PushNotificationApi';
import { L } from '../../../lib/i18n';

const notificationsSettingsKeys = {
    all: [{ scope: 'notificationSettings' }] as const,
    email: () => [{ ...notificationsSettingsKeys.all[0], entity: 'email' }] as const,
    notification: () => [{ ...notificationsSettingsKeys.all[0], entity: 'notification' }] as const,
};

const listSettings = async () => {
    const settings = await PushNotificationApi.listSettings();
    settings?.sort((a, b) => {
        const aType = L(`push_notification_settings_${a.type}`);
        const bType = L(`push_notification_settings_${b.type}`);
        if (aType > bType) return 1;
        if (aType < bType) return -1;
        return 0;
    });
    return settings;
};
export const useListSettings = () => useQuery([...notificationsSettingsKeys.notification()], listSettings);

type SettinsKey = 'email' | 'notification';
const switchSetting = ({ id, value, key }: { id: string; value: boolean; key: SettinsKey }) => {
    return PushNotificationApi.saveSetting(id, !value);
};

export const useSwitchNotificationSetting = () => {
    const queryClient = useQueryClient();
    return useMutation(switchSetting, {
        onMutate: (variables) => {
            queryClient.cancelQueries([...notificationsSettingsKeys[variables.key]()]);
            const data = queryClient.getQueryData<PushNotificationSetting[]>([
                ...notificationsSettingsKeys[variables.key](),
            ]);
            if (!data) return;

            const optimisticData = [...data];
            const setting = optimisticData.find((setting) => setting.setting_id === variables.id);
            if (!setting) return;
            setting.value = !setting.value;
            queryClient.setQueryData([...notificationsSettingsKeys[variables.key]()], optimisticData);
        },
        onSettled: (_data, _errors, variables) => {
            queryClient.refetchQueries([...notificationsSettingsKeys[variables.key]()]);
        },
    });
};
