import { useQuery } from 'react-query';
import HelpApi, { HelpItem } from '@app/api/public/HelpApi';
import { helpItems } from './useGetHelpListItems';
import { useParams } from 'react-router-dom';

const getHelpListItemById = async (id: HelpItem['id']) => {
    const r = await HelpApi.getById(id);
    return r;
};

const useGetHelpListItemById = () => {
    const { id } = useParams<{ id: string }>();
    return useQuery([...helpItems.id(id)], () => getHelpListItemById(id));
};

export default useGetHelpListItemById;
