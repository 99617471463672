import BookingsApi from '@app/api/public/BookingsApi';
import { useMutation, useQueryClient } from 'react-query';
import { getBookingResourcesQueryKey } from './useGetBookingResources';

type Variables = { id: number };

const useSetBookingAsDraft = () => {
    const client = useQueryClient();
    return useMutation(
        (varables: Variables) => {
            return BookingsApi.setBookingAsDraft(varables.id);
        },
        {
            onSettled: (_data, _error, variables) => {
                client.invalidateQueries(getBookingResourcesQueryKey.lists());
                client.invalidateQueries(getBookingResourcesQueryKey.id(variables.id));
            },
        }
    );
};

export default useSetBookingAsDraft;
