Object.defineProperty(exports, "__esModule", {
  value: true
});

var {
  createRpcProxy
} = require("/home/runner/work/ourliving-community/ourliving-community/node_modules/koa-rpc-call/browser");

var o = exports['default'] = {};
o["id"] = createRpcProxy("MaintenancesApi", "id", {
  "urlPrefix": "/rpc"
});
o["list"] = createRpcProxy("MaintenancesApi", "list", {
  "urlPrefix": "/rpc"
});
o["saveCategory"] = createRpcProxy("MaintenancesApi", "saveCategory", {
  "urlPrefix": "/rpc"
});
o["saveStatus"] = createRpcProxy("MaintenancesApi", "saveStatus", {
  "urlPrefix": "/rpc"
});
o["saveUnit"] = createRpcProxy("MaintenancesApi", "saveUnit", {
  "urlPrefix": "/rpc"
});
o["saveType"] = createRpcProxy("MaintenancesApi", "saveType", {
  "urlPrefix": "/rpc"
});
o["listAnalyticPlannedCompletedCostByYear"] = createRpcProxy("MaintenancesApi", "listAnalyticPlannedCompletedCostByYear", {
  "urlPrefix": "/rpc"
});
o["getSettingListByType"] = createRpcProxy("MaintenancesApi", "getSettingListByType", {
  "urlPrefix": "/rpc"
});
o["listCategories"] = createRpcProxy("MaintenancesApi", "listCategories", {
  "urlPrefix": "/rpc"
});
o["deleteSettingByType"] = createRpcProxy("MaintenancesApi", "deleteSettingByType", {
  "urlPrefix": "/rpc"
});
o["save"] = createRpcProxy("MaintenancesApi", "save", {
  "urlPrefix": "/rpc"
});
o["delete"] = createRpcProxy("MaintenancesApi", "delete", {
  "urlPrefix": "/rpc"
});
o["saveDocument"] = createRpcProxy("MaintenancesApi", "saveDocument", {
  "urlPrefix": "/rpc"
});
o["updateDocumentName"] = createRpcProxy("MaintenancesApi", "updateDocumentName", {
  "urlPrefix": "/rpc"
});
o["listDocuments"] = createRpcProxy("MaintenancesApi", "listDocuments", {
  "urlPrefix": "/rpc"
});
o["deleteDocumentFromMaintenance"] = createRpcProxy("MaintenancesApi", "deleteDocumentFromMaintenance", {
  "urlPrefix": "/rpc"
});
o["listServicePartners"] = createRpcProxy("MaintenancesApi", "listServicePartners", {
  "urlPrefix": "/rpc"
});