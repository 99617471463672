import { useTimeSlotCalendar } from '../context/useTimeSlots';
import useGetBookingResourceById from './useGetBookingResourceById';
import useGetUserBookings from './useGetUserBookings';

const useGetUserBookedItemsForCell = ({
    cellId,
    userId,
    bookingId,
}: {
    cellId: number;
    userId: string;
    bookingId: number;
}) => {
    const { dayjsState } = useTimeSlotCalendar();
    const { data: resourceData } = useGetBookingResourceById(+bookingId);

    const { data: userBookings } = useGetUserBookings({ resourceId: +bookingId, userId });

    const bookedItems = userBookings
        ?.filter(
            (booking) =>
                +booking.booking_slot_id === +cellId && booking.booking_date === dayjsState.format('YYYY-MM-DD')
        )
        .map((item) => {
            const resourceItem = resourceData?.booking_resource_items.find(
                (resourceItem) => +resourceItem.id === +item.booking_resource_item_id
            );
            const name = resourceItem?.name;
            return {
                ...item,
                name: name || '',
            };
        });

    return bookedItems;
};

export default useGetUserBookedItemsForCell;
