import React from 'react';
import { Route } from 'react-router-dom';
import BoardNewsPage from './BoardNewsPage';

export const routes = [
    <Route key="1" path="/board-news/:id" component={BoardNewsPage} />,
    <Route key="0" path="/board-news" component={BoardNewsPage} />,
];

export const navigation = { name: 'board_content_news', link: '/board-news', icon: 'News', parent: 'BoardMembers' };
