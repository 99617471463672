import { L } from '../../../lib/i18n';
import PropertiesApi from '@app/api/public/PropertiesApi';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { propertiesQueryKeys } from './propertyQueries';

const cancelPropertyTransfer = (id: number) => {
    return PropertiesApi.setTransferComplete(id);
};

const useCancelPropertyTransfer = () => {
    const client = useQueryClient();
    const { id } = useParams<{ id: string }>();
    return useMutation([propertiesQueryKeys.detail(+id)], () => cancelPropertyTransfer(+id), {
        onSuccess: () => {
            toast.success(L('cancel_property_transfer_success'));
        },
        onSettled: () => {
            client.invalidateQueries([propertiesQueryKeys.detail(+id)]);
        },
        onError: () => {
            toast.error(L('cancel_property_transfer_error'));
        },
    });
};

export default useCancelPropertyTransfer;
