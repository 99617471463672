import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { BlogPage } from './BlogPage';

export const routes = [
    <Route key="1" exact path="/">
        <Redirect to="/blog" />
    </Route>,
    <Route key="0" path="/blog" component={BlogPage} />,
];

export const navigation = { name: `ourliving_news`, link: '/blog', icon: 'Contact' };
