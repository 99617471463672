import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button } from '../../../components/Buttons/Button';
import useGetSettingsListByType from '../hooks/useGetSettingsListByType';
import { L } from '../../../lib/i18n';
import useSaveSettingsListByType from '../hooks/useSaveSettingsListByType';
import CategorySettingItem from './CategorySettingItem';
import { SettingsType } from '@app/api/models/Maintenances';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { roundCornerSize } from '../../../components/Shared/Style/box';
import { colors } from '../../../components/Shared/Style/colors';
import CardTitle from '../../../components/typography/CardTitle';
import BoxColorPicker from '../../../components/BoxColorPicker/BoxColorPicker';

const ModalWrapper = styled(CardWithDivider)({
    maxWidth: '500px',
    maxHeight: '80vh',
    placeSelf: 'center',
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: colors.background,
    borderRadius: roundCornerSize.medium,
});
const Form = styled.form({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
});
const H1 = styled.h1({
    fontSize: '2rem',
    fontWeight: 100,
});

const AddButton = styled(Button)({
    marginTop: '0.5rem',
});
const ItemContainer = styled.div({
    display: 'grid',
    gap: '1rem',
});

const GridWrapper = styled.div({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'min-content auto min-content',
    '&.type': {
        gridTemplateColumns: 'auto min-content',
    },
    gap: '1rem',
});

const StyledLabel = styled(Label)({
    width: '100%',
});
const SettingsByType = ({ initialValue, settingsType }: { initialValue?: string; settingsType: SettingsType }) => {
    const [name, setName] = useState(initialValue || '');
    const [color, setColor] = useState('');
    const { data: settingsList } = useGetSettingsListByType(settingsType);
    const { mutate: save } = useSaveSettingsListByType(settingsType);

    return (
        <ModalWrapper
            onClick={(e) => e.stopPropagation()}
            topArea={<CardTitle>{`Inställningar för ${L(settingsType)}`}</CardTitle>}
            mainArea={
                <>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            save({ name, color_code: color });
                            setName('');
                        }}
                    >
                        <StyledLabel title={L(`create_new_${settingsType}`)}>
                            <GridWrapper className={settingsType}>
                                {settingsType !== 'type' && <BoxColorPicker getValue={(value) => setColor(value)} />}
                                <Input
                                    required={true}
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    placeholder={L(`label_${settingsType}`)}
                                />
                                <AddButton
                                    type="submit"
                                    variant="selectedMenu"
                                    style={{ margin: '0px', justifySelf: 'start' }}
                                >
                                    {L(`create`)}
                                </AddButton>
                            </GridWrapper>
                        </StyledLabel>
                    </Form>

                    <Label title={L(`all_${settingsType}`)} />
                    <ItemContainer>
                        {settingsList?.map((item) => {
                            return <CategorySettingItem key={item.id} item={item} settingsType={settingsType} />;
                        })}
                    </ItemContainer>
                </>
            }
        />
    );
};

export default SettingsByType;
