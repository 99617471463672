import { useQuery } from 'react-query';
import UsersApi from '@app/api/public/UsersApi';

export const getUserByIdQueryKey = 'useGetUserById';
const useGetUserById = (id?: number) => {
    return useQuery(
        [getUserByIdQueryKey, id],
        () => {
            if (!id) return;
            return UsersApi.getUserById(id)
        },
        {
            enabled: !!id,
        }
    );
};

export default useGetUserById;
