import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React, { FC, useEffect, useRef } from 'react';
import { useTimeSlotCalendar } from '../../context/useTimeSlots';

dayjs.extend(customParseFormat);

type Props = {
    starttime: string;
};

const ScrollCell: FC<Props> = ({ starttime, children }) => {
    const ref = useRef<HTMLDivElement>(null);
    const { dayjsState } = useTimeSlotCalendar();

    useEffect(() => {
        if (dayjsState.format('YYYY-MM-DD HH') === dayjs(starttime, 'HH:mm').format('YYYY-MM-DD HH')) {
            ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
    }, [ref, starttime]);
    return <div ref={ref}>{children}</div>;
};

export default ScrollCell;
