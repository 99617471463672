import React from 'react';
import { Route } from 'react-router-dom';
import MemberNewsPage from './MemberNewsPage';

export const routes = [
    <Route key="1" path="/news/:id" component={MemberNewsPage} />,
    <Route key="0" path="/news" component={MemberNewsPage} />,
];

export const navigation = { name: 'member_content_news', link: '/news', icon: 'News', parent: 'Members' };
