import { useMutation, useQueryClient } from 'react-query';
import DocumentsApi from '@app/api/public/DocumentsApi';
import toast from 'react-hot-toast';
import { folderKeys } from '../../queryKeys';
import { L } from '../../../../lib/i18n';
import { UpdateFolderDetails } from '@app/api/models/Folders';

const useUpdateFolder = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: UpdateFolderDetails) => {
            const res = await DocumentsApi.updateFolder(variables);

            if (res.status === 'error') {
                throw new Error(res.message).message;
            }

            return res;
        },
        onError: (err) => {
            const errorMessage = typeof err === 'string' ? err : new Error(L('reset_error')).message;
            toast.error(`${L(errorMessage)}`);
        },
        onSettled: () => {
            queryClient.invalidateQueries([folderKeys]);
        },
        onSuccess: (data) => {
            data.message && toast.success(data.message);
        },
    });
};

export default useUpdateFolder;
