import ProjectSettingsApi from '@app/api/public/ProjectSettingsApi';
import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export const TicketSettingsQueryKey = {
    all: ['ticketAppStatus'],
};

export const useSetTicketAppStatus = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (enabled: boolean) => {
            return ProjectSettingsApi.setTicketEnabled(enabled);
        },

        onSuccess: () => {
            toast.success('App status uppdaterad');
            queryClient.invalidateQueries(TicketSettingsQueryKey.all);
        },
    });
};
