import { useQueryClient, useMutation } from 'react-query';
import PublicSiteApi from '@app/api/public/PublicSiteApi';
import { getPublicSiteDocumentsList } from '../../../areas/public-site/hooks/useGetDocumentsById';

type Variables = { documentId: string; queryId: string };

export const deleteDocument = async (id: number) => {
    return await PublicSiteApi.deleteDocument(id);
};

const useDeleteDocument = () => {
    const queryClient = useQueryClient();
    return useMutation((variables: Variables) => deleteDocument(+variables.documentId), {
        onSettled: (_data, _error, variables) => {
            queryClient.invalidateQueries(getPublicSiteDocumentsList.list(+variables.queryId));
        },
    });
};

export default useDeleteDocument;
