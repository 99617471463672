import React from 'react';
import styled from '@emotion/styled';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData } from 'chart.js';
import { Pie } from 'react-chartjs-2';

const Container = styled.div({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    border: '1px solid black',
    padding: '3rem',
    borderRadius: '0 0 4px 4px',
});

const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
});
const Header = styled.div({
    backgroundColor: '#ebebeb',
    width: '100%',
    borderRadius: '4px 4px 0 0',
    fontSize: '1.17em',
    fontWeight: 'bold',
    padding: '16px',
});
const Price = styled.div({
    paddingLeft: '1rem',
    border: '1px solid black',
    width: '100%',
    borderRadius: '0 0 4px 4px',
    marginBottom: '2rem',
});
const H1 = styled.h1({
    fontSize: '2rem',
});

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom' as const,
        },
    },
};

type Props = {
    data?: ChartData<'pie', number[], string>;
    headerPrice: string;
    price: string;
    headerPie: string;
};
const PieGraph = ({ data, headerPie, headerPrice, price }: Props) => {
    return (
        <Wrapper>
            <Header>{headerPrice}</Header>
            <Price>
                <H1>{price}</H1>
            </Price>
            {data && (
                <>
                    <Header>{headerPie}</Header>
                    <Container>
                        <div style={{ position: 'relative' }}>
                            <Pie data={data} options={options} />
                        </div>
                    </Container>
                </>
            )}
        </Wrapper>
    );
};

export default PieGraph;
