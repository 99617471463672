import React, { FC } from 'react';
import { style } from '../Shared/Style';
import styled from '@emotion/styled';
import { L } from '../../lib/i18n';

type Props = {
    title: string;
    error?: string;
    required?: boolean;
    errorVariant?: 'nonAbsolute';
    titleVariant?: 'noWhiteSpaceWrap';
} & React.LabelHTMLAttributes<HTMLLabelElement>;

const Field = styled.label({
    display: 'block',
});

const Title = styled.div(({ theme }) => ({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: theme.colors.secondary,
    marginBottom: style.margin.s,
    marginTop: style.margin.m,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    '&.noWhiteSpaceWrap': {
        columnGap: '0.5rem',
        whiteSpace: 'nowrap',
    },
}));

const Description = styled.div({});

const Errors = styled.span({
    color: 'red',
    position: 'absolute',
    right: 0,
    top: '-16px',
    '&.nonAbsolute': {
        position: 'static',
        right: 0,
        top: 0,
    },
});

const Label: FC<Props & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>> = (
    p
) => {
    const errorText = p.error;
    const hasError = errorText !== undefined;

    return (
        <Field {...p}>
            <Title className={`title ${p.titleVariant}`}>
                <span>{`${p.title}${p.required ? '*' : ''}`}</span>
                {hasError && <Errors className={p.errorVariant}>{errorText}</Errors>}
            </Title>
            <Description className="description">{p.children}</Description>
        </Field>
    );
};

export default Label;
