import { DocumentDetail } from '@app/api/models/Documents';
import ProjectsApi from '@app/api/public/ProjectsApi';
import { useMutation, useQueryClient } from 'react-query';
import { projectImagesKey } from './useGetProjectImages';

const useDeleteProjectImage = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (id: DocumentDetail['id']) => {
            return ProjectsApi.deleteDocument(id);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(projectImagesKey.all);
            },
        }
    );
};

export default useDeleteProjectImage;
