import { useMutation, useQueryClient } from 'react-query';
import MaintenancesApi from '@app/api/public/MaintenancesApi';
import { useHistory } from 'react-router-dom';
import { getMaintenanceQueryKey } from './useGetMaintenance';

const deleteMaintenance = async (id: number) => {
    return MaintenancesApi.delete(+id);
};

const useDeleteMaintenance = () => {
    const queryClient = useQueryClient();
    const history = useHistory();
    return useMutation((maintenance: { id: number }) => deleteMaintenance(maintenance.id), {
        onSuccess: () => {
            queryClient.invalidateQueries(getMaintenanceQueryKey);
            history.push('/maintenance');
        },
    });
};

export default useDeleteMaintenance;
