import { useQuery } from 'react-query';
import PublicSiteApi from '@app/api/public/PublicSiteApi';

export const getPublicSiteDocumentsList = {
    all: 'getPublicSiteDocumentsList',
    list: (id?: number) => [getPublicSiteDocumentsList.all, id],
};

const useGetDocumentsById = (id?: number) => {
    return useQuery(
        getPublicSiteDocumentsList.list(id),
        async () => {
            if (!id) return;
            return await PublicSiteApi.listDocuments(+id);
        },
        {
            enabled: !!id,
        }
    );
};

export default useGetDocumentsById;
